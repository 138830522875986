import {
    ArrowDropDownRounded,
    CalendarTodayRounded,
    EastRounded,
    WestRounded,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers-pro";

import { COLORS } from "src/theme/stylesheet";

export const Styled_DatePicker = styled(DatePicker)(() => ({
    "& .MuiOutlinedInput-root": {
        "&.Mui-error": {
            boxShadow: "0px 1px 2px rgba(254, 104, 95, 0.15)",
            color: COLORS.inputAlert,
            border: `solid 1px ${COLORS.inputAlert}`,
            "&:hover, &.Mui-focused": {
                border: `solid 1px ${COLORS.inputAlert}`,
            },
        },
    },
    "& .MuiIconButton-edgeEnd": {
        marginRight: 0,
    },
})) as typeof DatePicker;

export const openPickerIcon = styled(CalendarTodayRounded)({
    color: `${COLORS.moon800}`,
    opacity: "100%",
    fontSize: "21px",
});
export const leftArrowIcon = styled(WestRounded)({
    color: `${COLORS.moon800}`,
    opacity: "100%",
    fontSize: "21px",
});
export const rightArrowIcon = styled(EastRounded)({
    color: `${COLORS.moon800}`,
    opacity: "100%",
    fontSize: "21px",
});
export const switchViewIcon = styled(ArrowDropDownRounded)({
    color: `${COLORS.moon800}`,
    opacity: "100%",
    fontSize: "28px",
});
