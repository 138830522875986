import Konva from "konva";

import { Type_point } from "src/components/Components_Common/canvas/types";

export const flowShapeMilestoneFunction = (
    pt: Type_point,
    width: number,
    height: number,
    context: Konva.Context,
    shape?: Konva.Shape,
): void => {
    const x: number = pt.x;
    const y: number = pt.y;

    context.beginPath();
    context.moveTo(x, y + height / 2);
    context.lineTo(x + width / 2, y);
    context.lineTo(x + width, y + height / 2);
    context.lineTo(x + width / 2, y + height);
    context.closePath();

    shape && context.fillStrokeShape(shape);
};
