import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Layout_NotLoggedComponent } from "./Layout_NotLogged.component";

export const Layout_NotLoggedContainer = () => {
    const [services] = useState([]);
    const { pathname } = useLocation();

    const servicesSideMenu = services?.filter(({ link }) =>
        pathname.includes(link),
    )[0];

    return (
        <Layout_NotLoggedComponent
            services={services}
            servicesSideMenu={servicesSideMenu}
        >
            <Outlet />
        </Layout_NotLoggedComponent>
    );
};
