import { Autocomplete, AutocompleteRenderInputParams } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

import { Type_RequestConfig } from "src/api/fetch";
import { Type_Filter } from "src/api/filters";
import { useSelectListProjectUsers } from "src/api/tms-projects/projectUsers";
import { Type_selectList_projectUser } from "src/api/tms-projects/projectUsers/types";
import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";

type Type_Props_AutocompleteUsers = {
    name: string;
    label: string;
    projectId?: number;
    subProjectId?: number | null;
    enabled?: boolean | null;
};

/**
 * @deprecated
 * @param name
 * @param label
 * @param projectId
 * @param subProjectId
 * @param enabled
 * @constructor
 */
export const AutocompleteUsers = ({
    name,
    label,
    projectId,
    subProjectId,
    enabled,
}: Type_Props_AutocompleteUsers) => {
    const formik: any = useFormikContext();
    const [users, setUsers] = useState<Type_selectList_projectUser[]>([]);

    const requestConfig: Type_RequestConfig = { projectId: projectId };
    if (subProjectId) requestConfig.subProjectId = subProjectId;

    const filters: Type_Filter = {};
    typeof enabled == "boolean" && (filters.enabled = enabled);

    const { isFetching: isFetchingUsers, data } =
        useSelectListProjectUsers() || {};

    useEffect(() => {
        if (data) {
            setUsers(data);
        }
    }, [data]);

    return (
        <Autocomplete
            data-testid={`autocomplete-users-${name}`}
            id={`autocomplete-users-${name}`}
            multiple
            loading={isFetchingUsers}
            onChange={(e, value) => formik.setFieldValue(name, value)}
            value={formik.values[name] || []}
            options={users || []}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, selectedValues): boolean =>
                option?.id === selectedValues.id
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TMC_TextField
                    {...params}
                    name={name}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        "data-testid": `autocomplete-users-${name}-input`,
                    }}
                />
            )}
            renderOption={(props: object, option: any) => (
                <li
                    {...props}
                    data-testid={`autocomplete-users-${name}-input-${option.name}`}
                >
                    {option.name}
                </li>
            )}
            componentsProps={{
                clearIndicator: {
                    // @ts-expect-error data-testid are not on IconButtonProps
                    "data-testid": `autocomplete-users-clearButton-${name}`,
                },
            }}
        />
    );
};
