import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo } from "react";
import * as Yup from "yup";

import { useSelectListAreaTypes } from "src/api/tms-commons/areaTypes";
import {
    mutationCreateProjectType,
    mutationUpdateProjectType,
    useShowProjectType,
} from "src/api/tms-commons/projectTypes";
import { Type_show_projectType } from "src/api/tms-commons/projectTypes/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { DrawerSticky } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { CheckboxGroup } from "src/components/Components_Common/forms/reactHookFormComponents/CheckboxGroup/CheckboxGroup";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_ProjectTypeForm = {
    onClose: () => void;
    projectTypeIdToUpdate?: number | undefined;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_ProjectTypesForm = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.lazy(() => inputMultiLanguagesSchema()),
        areaTypes: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
    });
});

export const ProjectTypesForm = ({
    onClose,
    projectTypeIdToUpdate,
    setIsLoading,
    action,
}: Type_Props_ProjectTypeForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectTypes",
    );

    const { mutateAsync: mutateCreate } = mutationCreateProjectType() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateProjectType();

    const { isFetching: isFetchingAreaTypes, data: areaTypes } =
        useSelectListAreaTypes();

    const { data, isFetching: isFetchingProjectType } = useShowProjectType(
        projectTypeIdToUpdate as number,
    );

    const form = useTmcForm<Type_show_projectType>({
        defaultValues: {
            id: undefined,
            names: {},
            areaTypes: [],
        },
        values: data,
        resolver: yupResolver<any>(Schema_ProjectTypesForm),
        onClose,
        setIsLoading,
        action,
    });

    const handleSubmit = async (values: any) => {
        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_projectType);
            } else {
                await mutateCreate(values as Type_show_projectType);
            }

            // Fermeture du drawer
            onClose();
        } catch (_e: any) {
            console.error("Error when creating or updating project type");
        }
    };

    const isFetching = useMemo(
        () => isFetchingProjectType || isFetchingAreaTypes,
        [isFetchingProjectType, isFetchingAreaTypes],
    );

    return (
        <TmcFormProvider form={form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"projectTypes"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <>
                        <InputMultiLanguages
                            name="names"
                            label={fmt(`ProjectTypeName`)}
                        />
                        <Box width={"100%"}>
                            <DrawerSticky variant="h3">
                                {fmt(`SelectMultipleAreaTypes`)}
                            </DrawerSticky>
                            <CheckboxGroup
                                name={"areaTypes"}
                                options={areaTypes || []}
                            />
                        </Box>
                    </>
                )}
            </form>
        </TmcFormProvider>
    );
};
