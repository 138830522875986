import { styled } from "@mui/material/styles";

import { DrawerTab } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";

export const Styled_DrawerTab = styled(DrawerTab)(({ theme }) => ({
    margin: theme.spacing(0, 6, 0, 0),
    padding: theme.spacing(2, 0),
    minHeight: 24,
    textTransform: "none",
}));
