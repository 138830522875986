import { Stack } from "@mui/material";
import {
    GridToolbarContainer,
    GridToolbarProps,
    GridToolbarQuickFilter,
    ToolbarPropsOverrides,
} from "@mui/x-data-grid-premium";
import { forwardRef, Fragment } from "react";

import { LoadingOrNoData } from "src/components/Components_Common";
import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { Icon as TMC_Icon } from "src/components/Components_Common/Icon/Icon";
import { Type_DataGrid_Props } from "src/components/Components_Teamoty/DataGridVersion/DataGridVersion.container";
import { Styled_DataGrid } from "src/components/Components_Teamoty/DataGridVersion/DataGridVersion.style";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

type Type_EditToolbar_Props = GridToolbarProps &
    ToolbarPropsOverrides & {
        name: string;
        handleNewRow: () => void;
        newButtonText?: string;
        canAddNewRow?: boolean;
    };

export const EditToolbar = (
    props: GridToolbarProps & ToolbarPropsOverrides,
) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Table");
    const { handleNewRow, name, canAddNewRow = false, newButtonText } = props;

    return (
        <GridToolbarContainer sx={{ my: 3, ml: 2, width: "100%" }}>
            <Stack
                display={"flex"}
                width={"100%"}
                direction={"row"}
                justifyContent={"space-between"}
            >
                <GridToolbarQuickFilter
                    data-testid={`search-filter-${name}`}
                    placeholder={fmt(`Action.SearchPlaceHolder`)}
                    variant="outlined"
                />
                {canAddNewRow && (
                    <TMC_Button
                        data-testid={`DataGrid-${name}-add-button`}
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: COLORS.electricBlue }}
                        startIcon={<TMC_Icon variant="solid" icon="plus" />}
                        onClick={handleNewRow}
                    >
                        {newButtonText ?? fmt(`Action.New`)}
                    </TMC_Button>
                )}
            </Stack>
        </GridToolbarContainer>
    );
};
const ToolbarWrapper = (props: GridToolbarProps & ToolbarPropsOverrides) => {
    const { name, handleNewRow, canAddNewRow, newButtonText } =
        props as Type_EditToolbar_Props;

    return (
        <EditToolbar
            name={name}
            handleNewRow={handleNewRow}
            canAddNewRow={canAddNewRow}
            newButtonText={newButtonText}
        />
    );
};

export const DataGridVersionComponent = ({
    isFetching,
    rows,
    columns,
    handleAddNewRow = () => {},
    ...restProps
}: Type_DataGrid_Props) => {
    return (
        <Styled_DataGrid
            {...restProps}
            loading={isFetching}
            rowSelection={false}
            sx={{ "--DataGrid-overlayHeight": "320px" }}
            slots={{
                columnHeaders: forwardRef(function ColumnHeaders(_props, _ref) {
                    return <Fragment></Fragment>;
                }),

                toolbar: ToolbarWrapper,
                noRowsOverlay: () => (
                    <LoadingOrNoData
                        isFetching={isFetching || false}
                        dataName={restProps.name}
                        action={() => handleAddNewRow()}
                        dataTestIdRef={"versions"}
                    />
                ),
                noResultsOverlay: () => (
                    <LoadingOrNoData
                        isFetching={isFetching || false}
                        dataName={restProps.name}
                        noDataFound
                        dataTestIdRef={"versions"}
                    />
                ),
            }}
            slotProps={{
                toolbar: {
                    name: restProps.name,
                    handleNewRow: handleAddNewRow,
                    canAddNewRow: restProps.canAddNewRow,
                    newButtonText: restProps.newButtonText,
                },
            }}
            hideFooter
            rows={rows}
            columns={columns}
        />
    );
};
