import { Box } from "@mui/material";
import * as React from "react";

import IconSelect, {
    ItemType,
} from "src/components/Components_Common/forms/IconSelect/IconSelect";

export const IconSelectDemo = () => {
    const taskTypeList: ItemType[] = [
        {
            label: "Work",
            slug: "work",
            value: "work",
            icon: {
                variant: "kit",
                icon: "light-tds-task-work",
            },
        },
        {
            label: "Study",
            slug: "study",
            value: "study",
            icon: {
                variant: "kit",
                icon: "light-tds-task-study",
            },
        },
        { divider: true },
        {
            label: "Waiting",
            slug: "waiting",
            value: "waiting",
            icon: {
                variant: "kit",
                icon: "light-tds-task-waiting",
            },
        },
        {
            label: "Delivery",
            slug: "delivery",
            value: "delivery",
            icon: {
                variant: "kit",
                icon: "light-tds-task-delivery",
            },
        },
        { divider: true },
        {
            label: "Milestone",
            slug: "milestone",
            value: "milestone",
            icon: {
                variant: "kit",
                icon: "light-tds-task-milestone",
            },
        },
        {
            label: "Technical",
            slug: "technical",
            value: "technical",
            icon: {
                variant: "kit",
                icon: "light-tds-task-technical",
            },
        },
    ];

    return (
        <Box>
            <IconSelect name={"selectIcon"} items={taskTypeList} />
        </Box>
    );
};
