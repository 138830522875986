import { Typography } from "@mui/material";
import {
    GridRenderCellParams,
    GridRowId,
    GridRowModel,
} from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_licenseType } from "src/api/tms-commons/licenseTypes/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { LicenseTypeDrawer } from "src/drawers/licensesTypes/LicenseTypeDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteLicenseType } from "./Page_LicenseTypes.container";

export type Type_Props_Page_LicenseTypesComponent = {
    licenseTypes: Type_index_licenseType[];
    deleteLicenseType: (id: number) => void;
    changeStatus: (licenseType: Type_index_licenseType) => void;
    modalDeleteLicenseType: Type_modalDeleteLicenseType;
    setModalDeleteLicenseType: Dispatch<
        SetStateAction<Type_modalDeleteLicenseType>
    >;
    isFetching: boolean;
};

export const Page_LicenseTypesComponent = ({
    licenseTypes,
    deleteLicenseType,
    modalDeleteLicenseType,
    setModalDeleteLicenseType,
    changeStatus,
    isFetching,
}: Type_Props_Page_LicenseTypesComponent) => {
    const [selectedLicenseType, setSelectedLicenseType] = useState<
        number | null
    >(null);
    const [openLicenseTypeDrawer, setOpenLicenseTypeDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.LicenseTypes",
    );
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "licenseTypes";

    const handleLicenseTypeCreate = () => {
        setSelectedLicenseType(null);
        setAction("create");
        setOpenLicenseTypeDrawer(true);
    };

    const handleLicenseTypeEdit = (licenseTypeId: number) => {
        setSelectedLicenseType(licenseTypeId);
        setAction("update");
        setOpenLicenseTypeDrawer(true);
    };

    const handleDuplicateLicenseType = (licenseTypeId: number) => {
        setSelectedLicenseType(licenseTypeId);
        setAction("duplicate");
        setOpenLicenseTypeDrawer(true);
    };

    const handleCloseLicenseTypeDrawer = () => {
        setOpenLicenseTypeDrawer(false);
    };

    const callbackEdit = (row: GridRowModel) => {
        handleLicenseTypeEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) =>
        handleDuplicateLicenseType(row.id);

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_licenseType);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteLicenseType({
            isOpen: true,
            props: row as Type_index_licenseType,
        });
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
        },
        {
            field: "licenseTypeName",
            headerName: fmtTableColumn("Type"),
        },
        {
            field: "duration",
            headerName: fmtTableColumn("Duration"),
            renderCell: (
                params: GridRenderCellParams<Type_index_licenseType>,
            ) => {
                return (
                    <Typography component={"span"} whiteSpace={"nowrap"}>
                        {fmt(params.row.duration > 1 ? "Months" : "Month", {
                            values: { month: params.row.duration },
                        })}
                    </Typography>
                );
            },
        },
        {
            field: "roleTypeNames",
            headerName: fmtTableColumn("RoleType"),
        },
    ];

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteLicenseType({ isOpen: false });
                deleteLicenseType(modalDeleteLicenseType.props?.id as number);
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageLicenseTypes-AddOne-btn"
                    onClick={handleLicenseTypeCreate}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={licenseTypes}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: handleLicenseTypeCreate,
                }}
                onClick={(id: GridRowId) => {
                    handleLicenseTypeEdit(id as number);
                }}
                page={page}
                height={GRID_HEIGHT}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
            />
            <LicenseTypeDrawer
                open={openLicenseTypeDrawer}
                onClose={handleCloseLicenseTypeDrawer}
                numberId={selectedLicenseType}
                action={action}
            />
            <ModalDelete
                open={modalDeleteLicenseType.isOpen}
                onClose={() => setModalDeleteLicenseType({ isOpen: false })}
                actions={actions}
                item={fmt("DataName")}
                validationString={modalDeleteLicenseType?.props?.name as string}
            />
        </>
    );
};
