import { Stack } from "@mui/material";
import { useWatch } from "react-hook-form";
import * as Yup from "yup";

import { RadioGroup } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { SelectResourceType } from "src/components/Components_Teamoty/forms/select/SelectResourceType";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const slideOutFormSchema = Yup.object().shape({
    "slideOutResourceType.id": Yup.number().nullable(),
    slideOutMovementType: Yup.number().when("slideOutResourceType.id", {
        is: (slideOutResourceTypeId: any) => !!slideOutResourceTypeId,
        then: (schema) => {
            return schema.required(FORM_ERR_FMT.REQUIRED);
        },
        otherwise: (schema) => schema.nullable(),
    }),
});

type Type_Props_SlideOut = Type_ReadonlyComponent;

export const SlideOut = ({ readonly }: Type_Props_SlideOut) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.SlideOut",
    );

    const slideOutResourceType = useWatch({ name: "slideOutResourceType.id" });

    return (
        <Stack flexDirection={"column"}>
            <SelectResourceType
                name={"slideOutResourceType.id"}
                label={fmt("SelectLabel")}
                data-testid="Form-RatioTask-slideOut"
                placeholder={fmt("SelectPlaceholder")}
                readonly={readonly}
            />
            {slideOutResourceType && (
                <RadioGroup
                    name="slideOutMovementType"
                    options={[
                        { value: 1, label: fmt("Use") },
                        { value: 2, label: fmt("Release") },
                    ]}
                    readonly={readonly}
                />
            )}
        </Stack>
    );
};
