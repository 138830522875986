import { typeMilestones } from "@cimba-digital-construction-solution/utils/dist/decoder/const";

import { verticalOffsetFactorPlanningTask } from "src/components/Components_Teamoty/Planning/Planning.const";
import { Type_Props_getHeight } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

export const getHeight = ({
    task,
    heightArea,
}: Type_Props_getHeight): number => {
    const verticalOffset: number =
        heightArea / verticalOffsetFactorPlanningTask;

    return typeMilestones.includes(task.type)
        ? Math.round(heightArea - verticalOffset * 2)
        : Math.round(heightArea - verticalOffset * 2);
};
