export enum Enum_typeCircleFlow {
    Start = 1,
    Finish = 2,
}

export enum Enum_typeFlowLink {
    SS = 1,
    FS = 2,
    SF = 3,
    FF = 4,
}

export enum Enum_typeFlowTask {
    Work = 1,
    Study = 2,
    Waiting = 3,
    Milestone = 4,
    Delivery = 5,
    Technical = 6,
}

// attention de garder l'ordre avec Enum_typeFlowTask
export const Conv_typeFlowTask: string[] = [
    "none",
    "work",
    "study",
    "waiting",
    "milestone",
    "delivery",
    "technical",
];
