import { Form, FormikProvider, FormikValues, useFormik } from "formik";
import * as React from "react";
import { useState } from "react";
import * as Yup from "yup";

import { ColorPicker, TMC_Button } from "src/components";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";

export const ColorPickerFormikDemo = () => {
    const colorPicker_ValidationSchema = Yup.object().shape({
        myColor: Yup.string().trim().required(FORM_ERR_FMT.REQUIRED),
    });

    const [data] = useState({
        myColor: "",
    });

    const handleSubmit = async (values: FormikValues) => {
        console.log(values);
    };

    const formik = useFormik({
        initialValues: data,
        validationSchema: colorPicker_ValidationSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });
    return (
        <div>
            <FormikProvider value={formik}>
                <Form style={{ padding: 8 }}>
                    <ColorPicker name="myColor" label={"Label"} />
                    <TMC_Button
                        type="submit"
                        variant="contained"
                        sx={{ marginBlockStart: 2 }}
                    >
                        Submit
                    </TMC_Button>
                </Form>
            </FormikProvider>
        </div>
    );
};
