import React, { ReactNode } from "react";

import { Styled_StackMainAlert } from "src/components/Components_Common/Alert/Alert.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { COLORS } from "src/theme/stylesheet";

export type Type_AlertContainerProps = {
    children: ReactNode;
    status: "info" | "warning" | "error" | "success";
    isOpen?: boolean;
};

/**
 * @deprecated
 */
export const Alert = ({
    isOpen = true,
    children,
    status,
}: Type_AlertContainerProps) => {
    const statusConf = {
        info: {
            variant: "regular",
            icon: "circle-info",
            color: COLORS.blue700,
            textColor: COLORS.blue900,
        },
        warning: {
            variant: "solid",
            icon: "triangle-exclamation",
            color: COLORS.orange500,
            textColor: COLORS.orange500,
        },
        error: {
            variant: "solid",
            icon: "circle-exclamation",
            color: COLORS.red700,
            textColor: COLORS.red700,
        },
        success: {
            variant: "regular",
            icon: "circle-check",
            color: COLORS.green700,
            textColor: COLORS.green700,
        },
    }[status];

    if (isOpen)
        return (
            <Styled_StackMainAlert ownerState={{ status }}>
                <Icon
                    variant={statusConf.variant}
                    icon={statusConf.icon}
                    sx={{ color: statusConf.color }}
                />
                {children}
            </Styled_StackMainAlert>
        );
    return null;
};
