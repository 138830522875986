import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";

import CGU from "src/assets/documents/Teamoty_CGU.pdf";
import { TMC_Button } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Styled_StackCGU } from "src/pages/Pages_Common/Page_CGU/Page_CGU.style";
import { COLORS } from "src/theme/stylesheet";
const cguUrl = new URL("src/assets/documents/Teamoty_CGU.pdf", import.meta.url)
    .href;

export type Page_CGUComponentPropsType = {
    approveCGU: () => void;
    checked: boolean;
    handleCheck: () => void;
    isLoading: boolean;
};

export const Page_CGUComponent = ({
    approveCGU,
    checked,
    handleCheck,
    isLoading,
}: Page_CGUComponentPropsType) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("CGU");
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = CGU;
        link.download = "Teamoty_CGU.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Stack gap={6} sx={{ margin: "auto", p: 4 }}>
            <Styled_StackCGU data-testid="CGUPage-PDF">
                <object data={cguUrl} type="application/pdf" height="100%">
                    <p>
                        Unable to display PDF file.{" "}
                        <a href={cguUrl}>Download</a> instead.
                    </p>
                </object>
            </Styled_StackCGU>
            <TMC_Button
                data-testid="CGUPage-DownloadCGU"
                variant={"text"}
                sx={{
                    color: COLORS.blue700,
                    width: "fit-content",
                    alignSelf: "center",
                }}
                startIcon={<Icon variant="light" icon="laptop-arrow-down" />}
                onClick={handleDownload}
            >
                <Typography variant="body3Underline" color={COLORS.blue700}>
                    {fmt("DownloadPDF")}
                </Typography>
            </TMC_Button>
            <FormGroup>
                <FormControlLabel
                    data-testid={`CGUPage-Checkbox-CheckCGU`}
                    control={<Checkbox />}
                    label={fmt("Approve")}
                    checked={checked}
                    onChange={handleCheck}
                    disabled={isLoading}
                />
            </FormGroup>
            <TMC_Button
                data-testid={`CGUPage-submit`}
                onClick={approveCGU}
                variant="contained"
                disabled={!checked || isLoading}
                sx={{ width: "fit-content", alignSelf: "end" }}
                endIcon={<Icon variant="solid" icon="arrow-right" />}
            >
                {fmtCta("Accept")}
            </TMC_Button>
        </Stack>
    );
};
