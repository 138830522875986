import {
    FormHelperTextProps,
    FormHelperText as MuiFormHelperText,
} from "@mui/material";
import React from "react";

import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";

type FormHelperTextPropsType = Omit<FormHelperTextProps, "helperText"> & {
    helperText: string;
    isRtlLang?: boolean;
    inputName: string;
};

export const FormHelperText = ({
    helperText,
    isRtlLang,
    inputName,
    ...restProps
}: FormHelperTextPropsType) => {
    return (
        <MuiFormHelperText {...restProps}>
            <FormHelperMessage
                message={helperText}
                isRtlLang={isRtlLang}
                inputName={inputName}
            />
        </MuiFormHelperText>
    );
};
