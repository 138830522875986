import React, { useState } from "react";

import {
    mutationCreateTaskAreaNote,
    mutationDeleteTaskAreaNote,
    mutationUpdateTaskAreaNote,
    useIndexTaskAreaNotes,
} from "src/api/tms-scheduling/taskAreaNotes";
import { Type_index_taskAreaNote } from "src/api/tms-scheduling/taskAreaNotes/types";
import { useUser } from "src/contexts/user";
import {
    NotesForm,
    Type_permissionsNote,
    Type_Props_NotesForm,
} from "src/forms/notes/NotesForm";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_TaskAreaNotes = {
    taskId?: number;
    areaId?: number;
    taskAreaId?: number;
};

export const TaskAreaNotes = ({
    taskId,
    areaId,
    taskAreaId: id,
}: Type_Props_TaskAreaNotes) => {
    const { closePaper } = useContextualDrawer();
    const { hasPermission } = useUser();

    ///////////////////////////////////////
    ///            State                ///
    ///////////////////////////////////////
    // if taskAreaId is not defined, we need to update it after creating the first note
    const [taskAreaId, setTaskAreaId] = useState<number | undefined>(id);

    ///////////////////////////////////////
    ///            Query                ///
    ///////////////////////////////////////

    const { isFetching, data: taskAreaNotes } =
        useIndexTaskAreaNotes({
            taskId: taskId,
            areaId: areaId,
            taskAreaId: taskAreaId,
        }) || {};

    ///////////////////////////////////////
    ///           Mutations             ///
    ///////////////////////////////////////

    const { mutateAsync: mutateCreateNote } =
        // we are always sending the 3 properties, the back creates a new taskArea if taskAreaId is null.
        mutationCreateTaskAreaNote(
            {
                taskId: taskId,
                areaId: areaId,
                taskAreaId: taskAreaId,
            },
            (taskAreaId: number) => setTaskAreaId(taskAreaId),
        ) || {};
    const { mutateAsync: mutateUpdateNote } =
        mutationUpdateTaskAreaNote() || {};
    const { mutateAsync: mutateDeleteNote } =
        mutationDeleteTaskAreaNote({
            taskId: taskId,
            areaId: areaId,
            taskAreaId: taskAreaId,
        }) || {};

    ///////////////////////////////////////
    ///         Permissions             ///
    ///////////////////////////////////////

    const permissions: Type_permissionsNote = {
        canShow: hasPermission("taskAreaNotes_index"),
        canDelete: hasPermission("taskAreaNotes_delete"),
        canEdit: hasPermission("taskAreaNotes_create"),
    };

    return (
        <TaskAreaNotesForm
            apiRef="TaskAreaNotes"
            notes={taskAreaNotes}
            isFetching={isFetching}
            mutateCreateNote={mutateCreateNote}
            mutateUpdateNote={mutateUpdateNote}
            mutateDeleteNote={mutateDeleteNote}
            permissions={permissions}
            onClose={() => closePaper("taskAreaNotes")}
            sendEventCount={"updateNotesCount"}
        />
    );
};

// this function formats the data taskAreaNotes correctly
const TaskAreaNotesForm = ({
    ...props
}: Type_Props_NotesForm<Type_index_taskAreaNote>) => {
    return <NotesForm {...props} />;
};
