import { Box, styled } from "@mui/material";

import { COLORS } from "src/theme/stylesheet";

export const ToolbarDrawer = styled(Box)(() => ({
    position: "absolute",
    height: "100%",
    width: 430,
    left: "100%",
    top: 0,
    backgroundColor: COLORS.white,
}));
