import { DrawerProps } from "@mui/material";
import { CSSProperties } from "react";

import { DrawerComponent } from "./Drawer.component";

export type DrawerContainerPropsType = {
    options: DrawerProps;
    children?: JSX.Element | null;
    style?: CSSProperties;
};

/**
 * @deprecated use src/components/Components_Common/DrawerGeneric
 */
export const DrawerContainer = (props: DrawerContainerPropsType) => {
    return <DrawerComponent {...props} />;
};
