import {
    Type_category,
    Type_project,
    Type_subproject,
} from "src/configurations/permissionsSelect.configuration";

export const toggleStringFromArray = (arr: string[], strs: string[]) => {
    const resultArray = arr.filter((item) => !strs.includes(item));

    for (const str of strs) {
        if (!arr.includes(str)) {
            resultArray.push(str);
        }
    }
    return resultArray;
};

export const handlePermissions = (arr: string[], strs: string[]) => {
    const resultArray = arr.filter((item) => !strs.includes(item));

    if (resultArray.length === arr.length) {
        for (const str of strs) {
            if (!arr.includes(str)) {
                resultArray.push(str);
            }
        }
    }
    return resultArray;
};

export const areAllElementsInArray = (
    sourceArray: string[],
    targetArray: string[],
): boolean => {
    return targetArray.every((element: string) =>
        sourceArray.includes(element),
    );
};

export const countSelectedIndexByCategory = (
    categories: Type_category[],
    permissions: string[],
) => {
    let count = 0;

    categories.forEach((item) => {
        let found = item.permissions.some((permission) =>
            permissions.includes(permission),
        );

        item.categories.forEach((category) => {
            const categoryFound = category.permissions.some((permission) =>
                permissions.includes(permission),
            );
            if (categoryFound) {
                found = true;
            }
        });

        if (found) count++;
    });

    return count;
};

export const countSelectedIndex = (
    project: Type_project,
    permissions: string[],
) => {
    let nbr: number = 0;

    project.categories.map((subproject: Type_subproject) => {
        nbr += countSelectedIndexByCategory(subproject.categories, permissions);
    });

    return nbr;
};

export const countSearchMatch = (project: Type_project) => {
    let nbr: number = 0;

    project.categories.map((subproject: Type_subproject) => {
        nbr += subproject.categories.length;
    });

    return nbr;
};
