import { Components } from "@mui/material";

import { COLORS, FONT_FAMILY } from "src/theme/stylesheet";

export const MuiLinkTheme: Components["MuiLink"] = {
    styleOverrides: {
        root: {
            fontWeight: 400,
            lineHeight: "24px",
            fontSize: 14,
            textDecoration: "underline",
            color: COLORS.darkBlue,
            textTransform: "inherit",
            fontFamily: FONT_FAMILY,
        },
    },
};
