import { Components } from "@mui/material";

export const MuiFormHelperTextTheme: Components["MuiFormHelperText"] = {
    styleOverrides: {
        contained: ({ theme }: any) => ({
            margin: 0,
            marginTop: theme.spacing(1),
        }),
    },
};
