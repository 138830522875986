import {
    Type_index_language,
    Type_prj_index_language,
} from "src/api/tms-projects/languages/types";
import {
    languagesList,
    Message,
    Type_CountryCode,
    Type_LanguagesList,
} from "src/assets/translations";

export const formatterIndexLanguages = (
    languages: Type_prj_index_language[],
): Type_index_language[] => {
    return languages.map((language: Type_prj_index_language) => {
        return formatterIndexLanguage(language);
    });
};

export const formatterIndexLanguage = (
    language: Type_prj_index_language,
): Type_index_language => {
    const lang = languagesList[language.locale].toString();

    return {
        locale: language.locale,
        label: lang,
        labelComplete: lang + " (" + language.locale + ")",
        default: language.default,
    };
};

export const formatterLanguages = (
    languages: Type_prj_index_language[],
): Type_LanguagesList => {
    const projectLanguages = languages?.map((language: any) => language.locale);
    return Object.fromEntries(
        Object.entries(languagesList).filter(([key]: [string, Message]) =>
            projectLanguages.includes(key),
        ),
    );
};

export const formatterLocaleProjectLanguages = (
    projectLanguages: Type_prj_index_language[],
): Type_CountryCode[] => {
    return projectLanguages.map(
        (language: Type_prj_index_language) => language.locale,
    );
};
