import { InputAdornment } from "@mui/material";
import * as React from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    TextField,
    Type_Props_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Type_DataTestIdComponent } from "src/components/Components_Common/types";

export type Type_Props_TextFieldPassword = Type_DataTestIdComponent &
    Omit<Type_Props_TextField, "type">;

export const TextFieldPassword = ({
    dataTestId,
    ...props
}: Type_Props_TextFieldPassword) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
    };

    return (
        <TextField
            {...props}
            type={showPassword ? "text" : "password"}
            inputProps={{
                // Be careful, InputProps !== inputProps, we should have both.
                "data-testid": dataTestId,
                ...props.inputProps,
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="small"
                        >
                            <Icon
                                variant="solid"
                                icon={showPassword ? "eye-slash" : "eye"}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
                ...props.InputProps,
            }}
        />
    );
};
