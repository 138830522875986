import {
    canvastoDataURL,
    dataURLtoImage,
    EImageType,
    imagetoCanvas,
} from "image-conversion";

export const FileImage = async (fileUrl: string | ArrayBuffer) => {
    console.error("Imported file should be a png.");

    const image: HTMLImageElement = await dataURLtoImage(fileUrl.toString());
    const canvas: HTMLCanvasElement = await imagetoCanvas(image);
    return await canvastoDataURL(canvas, 1, EImageType.PNG);
};
