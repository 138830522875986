import { DrawerProps } from "@mui/material";
import React, { useMemo, useState } from "react";

import {
    mutationUpdateVersion,
    useDeleteVersion,
    useIndexVersions,
} from "src/api/tms-projects/versions";
import { Type_index_version } from "src/api/tms-projects/versions/types";
import { useDrawer } from "src/contexts/drawer";
import { useProject } from "src/contexts/project";

import { Page_VersionsComponent } from "./Page_Versions.component";
import { Version_DrawerContentContainer as VersionDrawer } from "./Version_DrawerContent/Version_DrawerContent.container";
import { Version_Duplicate_DrawerContentContainer as VersionDuplicateDrawer } from "./Version_Duplicate_DrawerContent/Version_Duplicate_DrawerContent.container";
import { Version_Notes_DrawerContentContainer as VersionNotesDrawer } from "./Version_Notes_DrawerContent/Version_Notes_DrawerContent.container";

export const Page_VersionsContainer = () => {
    const { isLoading: isProjectLoading } = useProject();

    // get context for project and subproject

    const [versions, setVersions] = useState<Type_index_version[]>([]);

    const addNewVersion = (version: Type_index_version) => {
        setVersions((prevVersions) => {
            return [...prevVersions, version];
        });
    };

    const updateVersion = (updatedVersion: Type_index_version) => {
        setVersions((prevVersions) => {
            const updatedVersions = prevVersions.map((v) => {
                if (v.id === updatedVersion.id) {
                    return updatedVersion;
                }
                return v;
            });
            return [...updatedVersions];
        });
    };

    const removeVersion = async (id: number) => {
        setVersions(
            versions.filter((version: any): boolean => version.id != id),
        );
    };

    const { isFetching: isFetchingVersions } =
        useIndexVersions(setVersions) || {};

    const { mutateAsync: mutateDeleteVersion, isLoading: isDeletingVersion } =
        useDeleteVersion(removeVersion) || {};

    const { mutateAsync: mutateUpdate, isLoading: isUpdatingVersion } =
        mutationUpdateVersion(updateVersion) || {};

    /* DRAWER */
    const { setDrawer, closeDrawer } = useDrawer();

    const drawerOptions: DrawerProps = {
        anchor: "right",
        ModalProps: {
            BackdropProps: {
                onClick: () => null,
            },
        },
        elevation: 2,
    };

    const openDrawer = (action: "update" | "create", id?: number | undefined) =>
        setDrawer(
            <VersionDrawer
                onClose={closeDrawer}
                onSubmit={action === "update" ? updateVersion : addNewVersion}
                versionIdToUpdate={id}
                action={action}
            />,
            drawerOptions,
        );

    const openNotesDrawer = (versionId: number) =>
        setDrawer(
            <VersionNotesDrawer onClose={closeDrawer} versionId={versionId} />,
            drawerOptions,
        );

    const openDuplicateDrawer = (id?: number | undefined) =>
        setDrawer(
            <VersionDuplicateDrawer
                onClose={closeDrawer}
                onSubmit={updateVersion}
                versionIdToUpdate={id}
            />,
            drawerOptions,
        );

    const isLoading = useMemo(
        () =>
            isFetchingVersions ||
            isUpdatingVersion ||
            isDeletingVersion ||
            isProjectLoading,
        [
            isFetchingVersions,
            isUpdatingVersion,
            isDeletingVersion,
            isProjectLoading,
        ],
    );

    return (
        <Page_VersionsComponent
            versions={versions}
            isFetching={isLoading}
            openDrawer={openDrawer}
            openNotesDrawer={openNotesDrawer}
            openDuplicateDrawer={openDuplicateDrawer}
            deleteVersion={mutateDeleteVersion}
            updateVersion={mutateUpdate}
        />
    );
};
