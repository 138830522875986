import React from "react";
import { Line } from "react-konva";

import {
    heightFlowShape,
    propsFlowStrokeColor,
    propsFlowStrokeWidth,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { Type_flowTask } from "src/components/Components_Teamoty/Flow/Flow.type";
import {
    propsTaskFlow,
    Type_props_components_shape_flow,
} from "src/components/Components_Teamoty/Flow/Shape/FlowShape.const";
import { FlowShapeTextProps } from "src/components/Components_Teamoty/Flow/Shape/FlowShapeTextProps";

export const getTaskProps = (
    typeShape: Type_props_components_shape_flow,
    width: number,
    task: Type_flowTask,
): [Array<React.JSX.Element>, Array<React.JSX.Element>] => {
    const height: number = heightFlowShape;

    const { withDuration, withAreaQuantity, withTeam } =
        propsTaskFlow[typeShape];

    let countProps: number = 0;
    countProps += withDuration ? 1 : 0;
    countProps += withAreaQuantity ? 1 : 0;
    countProps += withTeam ? 1 : 0;

    const widthProps: number = countProps ? width / countProps : 0;
    let offsetProps: number = 0;

    const props: Array<React.JSX.Element> = [];
    const lines: Array<React.JSX.Element> = [];

    if (withDuration) {
        task.duration &&
            props.push(
                <FlowShapeTextProps
                    key={"durationText"}
                    width={widthProps}
                    text={`${task.duration.toString()}d`}
                    offsetX={offsetProps}
                    x={task.pt.x}
                    y={task.pt.y + height / 2}
                />,
            );
        offsetProps -= widthProps;
    }

    if (withAreaQuantity) {
        if (props.length)
            lines.push(
                <Line
                    key={"areaQuantityLine"}
                    points={[
                        task.pt.x - offsetProps,
                        task.pt.y + height / 2,
                        task.pt.x - offsetProps,
                        task.pt.y + height,
                    ]}
                    stroke={propsFlowStrokeColor}
                    strokeWidth={propsFlowStrokeWidth}
                    listening={false}
                />,
            );

        task.areaQuantity &&
            props.push(
                <FlowShapeTextProps
                    key={"areaQuantityText"}
                    width={widthProps}
                    text={`${task.areaQuantity.toString()}u`}
                    offsetX={offsetProps}
                    x={task.pt.x}
                    y={task.pt.y + height / 2}
                />,
            );
        offsetProps -= widthProps;
    }

    if (withTeam) {
        if (props.length)
            lines.push(
                <Line
                    key={"teamLine"}
                    points={[
                        task.pt.x - offsetProps,
                        task.pt.y + height / 2,
                        task.pt.x - offsetProps,
                        task.pt.y + height,
                    ]}
                    stroke={propsFlowStrokeColor}
                    strokeWidth={propsFlowStrokeWidth}
                    listening={false}
                />,
            );

        task.team &&
            props.push(
                <FlowShapeTextProps
                    key={"teamText"}
                    width={widthProps}
                    text={`${task.team.toString()}t`}
                    offsetX={offsetProps}
                    x={task.pt.x}
                    y={task.pt.y + height / 2}
                />,
            );
    }

    return [props, lines];
};
