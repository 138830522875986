import {
    Card,
    CardMedia,
    CardMediaProps,
    CardProps,
    Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon } from "src/components/Components_Common/Icon/Icon";

export const Styled_WorkspaceContainer = styled(Stack)(({ theme }) => ({
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(10),
    width: "fit-content",
}));

export const Styled_WorkspaceCardIcon = styled(Icon)(({ theme }: any) => ({
    color: theme.palette.card.selected,
    top: theme.spacing(3),
    right: theme.spacing(3),
    position: "absolute",
    zIndex: 1,
}));

interface Type_Props_StyledWorkspaceCard extends CardProps {
    ownerState?: {
        selected?: boolean;
    };
}

export const Styled_WorkspaceCard = styled(
    Card,
)<Type_Props_StyledWorkspaceCard>(({ theme, ownerState }: any) => ({
    height: "332px",
    maxWidth: "345px",
    ...(ownerState?.selected && {
        outline: `2px solid ${theme.palette.card.selected}`,
        position: "relative",
    }),
    ".MuiList-root": {
        padding: 0,
        listStyle: "inside",
        listStyleType: "disc",
        li: {
            marker: {
                margin: 0,
            },
            paddingLeft: theme.spacing(2),
            ...theme.typography.body2,
        },
    },
}));

export const Styled_WorkspaceCardMedia = styled(CardMedia)<CardMediaProps>(
    () => ({
        height: "160px",
    }),
);
