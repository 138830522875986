import {
    Type_default_workspace,
    Type_prj_post_workspace,
    Type_prj_show_workspace,
    Type_show_workspace,
} from "src/api/tms-projects/workspacesAndViews/workspaces/types";

export const formatterCreateWorkspace = (
    workspace: Type_default_workspace,
): Type_prj_post_workspace => {
    return {
        name: workspace.name,
        description: workspace.description,
        type: workspace.type,
        current: workspace.current,
    };
};

export const formatterShowWorkspace = (
    workspace: Type_prj_show_workspace,
): Type_show_workspace => {
    return {
        id: workspace.id,
        name: workspace.name,
        description: workspace.description,
        type: workspace.type,
        enabled: workspace.enabled,
        current: workspace.current,
        user: workspace.user,
        views: workspace.views,
    };
};
