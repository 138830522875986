export enum MetadataUserKeys {
    INDEX = "indexMetadataUsers",
    SHOW = "showMetadataUser",
}

export enum RoleKeys {
    INDEX = "indexRoles",
    SHOW = "showRole",
    SELECT_LIST = "selectListRoles",
}

export enum UserKeys {
    INDEX = "indexUsers",
    SHOW = "showUser",
}
