import { GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_unit } from "src/api/tms-commons/units/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { UnitDrawer } from "src/drawers/units/UnitDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteUnit } from "./Page_Units.container";

export type Type_Props_Page_UnitsComponent = {
    units: Type_index_unit[];
    removeUnit: (id: number) => void;
    changeStatus: (unit: Type_index_unit) => void;
    modalDeleteUnit: Type_modalDeleteUnit;
    setModalDeleteUnit: Dispatch<SetStateAction<Type_modalDeleteUnit>>;
    isFetching: boolean;
};

export const Page_UnitsComponent = ({
    units,
    removeUnit,
    changeStatus,
    modalDeleteUnit,
    setModalDeleteUnit,
    isFetching,
}: Type_Props_Page_UnitsComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Teamoty.Units");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "units";

    const [selectedUnit, setSelectedUnit] = useState<number | undefined>(
        undefined,
    );
    const [openUnitDrawer, setOpenUnitDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleUnitCreate = () => {
        setSelectedUnit(undefined);
        setAction("create");
        setOpenUnitDrawer(true);
    };

    const handleUnitEdit = (unitId: number) => {
        setSelectedUnit(unitId);
        setAction("update");
        setOpenUnitDrawer(true);
    };

    const handleDuplicateUnit = (unitId: number) => {
        setSelectedUnit(unitId);
        setAction("duplicate");
        setOpenUnitDrawer(true);
    };

    const handleCloseUnitDrawer = () => {
        setOpenUnitDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
        },
        {
            field: "symbol",
            headerName: fmtTableColumn("Symbol"),
        },
        {
            field: "unitTypeName",
            headerName: fmtTableColumn("UnitTypeName"),
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleUnitEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) => {
        handleDuplicateUnit(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_unit);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteUnit({
            isOpen: true,
            props: row as Type_index_unit,
        });
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteUnit({ isOpen: false });
                removeUnit(modalDeleteUnit?.props?.id as number);
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageUnits-AddOne-btn"
                    onClick={handleUnitCreate}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={units}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: handleUnitCreate,
                }}
                onClick={(id: GridRowId) => {
                    handleUnitEdit(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <ModalDelete
                open={modalDeleteUnit.isOpen}
                onClose={() => setModalDeleteUnit({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteUnit?.props?.name as string}
                actions={actions}
            />
            <UnitDrawer
                open={openUnitDrawer}
                onClose={handleCloseUnitDrawer}
                unitIdToUpdate={selectedUnit}
                action={action}
                page={page}
            />
        </>
    );
};
