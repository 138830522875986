import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { TimeModelForm } from "src/forms/timeModels/TimeModelForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_TimeModelsDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    timeModelIdToUpdate: number | null;
    action: Type_action;
    page: string;
};

export const TimeModelsDrawer = ({
    open,
    onClose,
    timeModelIdToUpdate,
    action,
    page,
}: Type_Props_TimeModelsDrawer) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.TimeModels");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`DrawerTimeModels-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            page={page}
            formId={page}
            isFormLoading={isLoading}
        >
            <TimeModelForm
                action={action}
                onClose={onClose}
                setIsLoading={setIsLoading}
                timeModelIdToUpdate={timeModelIdToUpdate}
            ></TimeModelForm>
        </DrawerGeneric>
    );
};
