import { GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_role } from "src/api/tms-users/rolesAndPermissions/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { RoleDrawer } from "src/drawers/roles/RoleDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteRole } from "src/pages/Pages_Teamoty/Configuration/Page_Roles/Page_Roles.container";

export type Type_Props_PageRoles = {
    roles: Type_index_role[];
    removeRole: (id: number) => void;
    changeStatus: (id: number, roleData: Type_index_role) => void;
    modalDeleteRole: Type_modalDeleteRole;
    setModalDeleteRole: Dispatch<SetStateAction<Type_modalDeleteRole>>;
    isFetching: boolean;
};

export const Page_RolesComponent = ({
    roles,
    removeRole,
    changeStatus,
    modalDeleteRole,
    setModalDeleteRole,
    isFetching,
}: Type_Props_PageRoles) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Teamoty.Roles");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "roles";

    const [selectedRole, setSelectedRole] = useState<number | null>(null);
    const [openRoleDrawer, setOpenRoleDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleRoleCreate = () => {
        setSelectedRole(null);
        setAction("create");
        setOpenRoleDrawer(true);
    };

    const handleRoleEdit = (roleId: number) => {
        setSelectedRole(roleId);
        setAction("update");
        setOpenRoleDrawer(true);
    };

    const handleDuplicateRole = (roleId: number) => {
        setSelectedRole(roleId);
        setAction("duplicate");
        setOpenRoleDrawer(true);
    };

    const handleCloseRoleDrawer = () => {
        setOpenRoleDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            sortable: false,
        },
        {
            field: "roleTypeName",
            headerName: fmtTableColumn("RoleTypeName"),
            sortable: false,
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleRoleEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) => {
        handleDuplicateRole(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row.id, row as Type_index_role);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteRole({
            isOpen: true,
            props: row as Type_index_role,
        });
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageRoleTypes-AddOne-btn"
                    onClick={() => handleRoleCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={roles}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleRoleCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleRoleEdit(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <ModalDelete
                open={modalDeleteRole.isOpen}
                onClose={() => setModalDeleteRole({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteRole?.props?.name as string}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteRole({ isOpen: false });
                            removeRole(modalDeleteRole?.props?.id as number);
                        },
                    },
                }}
            />

            <RoleDrawer
                open={openRoleDrawer}
                onClose={handleCloseRoleDrawer}
                roleIdToUpdate={selectedRole}
                action={action}
                page={page}
            />
        </>
    );
};
