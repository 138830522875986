import {
    Type_Map_planningCompany,
    Type_Map_planningSubTrades,
    Type_Map_planningWorkforce,
    Type_Set_planningWorkforceCompanies,
    Type_Set_planningWorkforceSubTrades,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";

import { Type_Props_PlanningMonthsDays } from "src/components/Components_Teamoty/Planning/Dates/PlanningDate.type";
import { Type_PlanningDrawer } from "src/components/Components_Teamoty/Planning/Drawer/PlanningDrawer.type";
import {
    Type_Callback_PlanningDrawerResizer,
    Type_Callback_PlanningDrawerTitle,
} from "src/components/Components_Teamoty/Planning/Drawer/PlanningDrawerTitle.type";

export type Type_Props_PlanningWorkforceBase = Type_PlanningDrawer & {
    dates: Type_Props_PlanningMonthsDays;
    widthDate: number;
    workforce: Type_Map_planningWorkforce;
    onClose: Type_Callback_PlanningDrawerTitle;
    onResize: Type_Callback_PlanningDrawerResizer;
};

export type Type_Props_PlanningWorkforceCurve =
    Type_Props_PlanningWorkforceBase & {
        workforceSubTrades: Type_Set_planningWorkforceSubTrades;
        subTrades: Type_Map_planningSubTrades;
        workforceCompanies: Type_Set_planningWorkforceCompanies;
        companies: Type_Map_planningCompany;
    };

export enum Enum_typeLabel {
    none,
    circle = 1,
    rect = 2,
}

export type Type_State_PlanningWorkforceCurve = {
    show: boolean;
    x: number;
    y: number;
    quantity: string;
    type: Enum_typeLabel;
};

export type Type_Map_planningQuantities = Map<number, number[]>;

export type Type_Props_PlanningWorkforceSubTradesTab =
    Type_Props_PlanningWorkforceBase & {
        workforceSubTrades: Type_Set_planningWorkforceSubTrades;
        subTrades: Type_Map_planningSubTrades;
    };

export type Type_Props_PlanningWorkforceCompaniesTab =
    Type_Props_PlanningWorkforceBase & {
        workforceCompanies: Type_Set_planningWorkforceCompanies;
        companies: Type_Map_planningCompany;
    };

export enum Enum_typeChart {
    none,
    withWorkforceCurve,
    withWorkforceCompaniesTab,
    withWorkforceSubTradesTab,
}

export type Type_PlanningWorkforceButton = {
    typeChart: Enum_typeChart;
    pictogram: string;
    width: number;
    height: number;
    titleKey: string;
};

export type Type_Props_PlanningWorkforceButton = {
    x: number;
    y: number;
    width: number;
    height: number;
    title: string;
    onChange: () => void;
    selected: boolean;
    pictogram: string;
};

export type Type_Props_PlanningWorkforceButtons = {
    x: number;
    y: number;
    buttons: Array<Type_PlanningWorkforceButton>;
    currentChart: Enum_typeChart;
    changeChart: (chart: Enum_typeChart) => void;
};
