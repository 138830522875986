import Highcharts from "highcharts";
import { default as HighchartsReact } from "highcharts-react-official";
import React from "react";

const data = [
    {
        name: "Completed",
        y: 23.9,
        color: "#4D9344",
    },
    {
        name: "In progress",
        y: 12.6,
        color: "#459CEE",
    },
    {
        name: "To do",
        y: 37.0,
        color: "#CF4233",
    },
    {
        name: "Delay",
        y: 26.4,
        color: "#E37920",
    },
];

const options = {
    chart: {
        type: "pie",
    },
    title: {
        text: "All tasks",
    },
    subtitle: {
        text: `Total<br/><strong>12 Tasks</strong>`,
        align: "center",
        verticalAlign: "middle",
        y: 0,
        style: {
            fontSize: "16px",
            color: "#000",
            fontWeight: "normal",
        },
    },
    tooltip: {
        pointFormat: "{point.name}: <b>{point.percentage:.0f}%</b>",
    },
    plotOptions: {
        series: {
            allowPointSelect: true,
            cursor: "pointer",
            borderRadius: 8,
            dataLabels: { enabled: false },
            showInLegend: true,
        },
    },
    series: [
        {
            name: "Tasks",
            colorByPoint: true,
            innerSize: "75%",
            data: data,
        },
    ],
};

export const HighCharts = () => {
    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    );
};
