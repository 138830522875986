import { useMutation, useQuery, useQueryClient } from "react-query";

import { SequenceFolderKeys, SequenceKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateSequence,
    formatterDuplicateSequence,
    formatterIndexSequence,
    formatterIndexSequences,
    formatterShowSequence,
    formatterUpdateSequence,
} from "src/api/tms-scheduling/sequences/formatters";
import {
    createSequence,
    deleteSequence,
    duplicateSequence,
    indexSequences,
    showSequence,
    statusSequence,
    updateSequence,
} from "src/api/tms-scheduling/sequences/services";
import {
    Type_index_sequence,
    Type_post_dup_sequence,
    Type_post_sequence,
    Type_put_sequence,
    Type_sch_index_sequence,
    Type_sch_put_sequence,
    Type_sch_show_sequence,
    Type_show_sequence,
} from "src/api/tms-scheduling/sequences/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { Type_event_sequence, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexSequences = (uniqueKey: string = "") => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [SequenceKeys.INDEX, requestConfig, uniqueKey],
        queryFn: () => indexSequences(requestConfig),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexSequences(
                data?.data?.data as Type_sch_index_sequence[],
            );
        },
    });
};

export const mutationCreateSequence = (withInit: boolean = false) => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders.Sequence",
    );
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newSequence: Type_post_sequence) =>
            createSequence(formatterCreateSequence(newSequence), {
                ...requestConfig,
                withInit: withInit,
            }),
        onSuccess: async (data: any): Promise<any> => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateSequence");
            } else {
                addSuccess({
                    description: fmt("ToastSuccessCreate"),
                });
            }
        },
        onError: (err: any): any => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            console.error("ERROR mutationCreateSequence", err);
            return err;
        },
    });
};

export const useShowSequence = (sequenceId: number, uniqueKey: string = "") => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [SequenceKeys.SHOW, requestConfig, sequenceId, uniqueKey],
        queryFn: () => showSequence(requestConfig, sequenceId),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data): Type_show_sequence => {
            return formatterShowSequence(
                data?.data?.data as Type_sch_show_sequence,
            );
        },
    });
};

export const mutationUpdateSequence = (callback?: any) => {
    const { sendEvent } = useChannel({});
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (newSequence: Type_put_sequence) => {
            const formattedSequenceDataForUpdate: Type_sch_put_sequence =
                formatterUpdateSequence(newSequence);

            return updateSequence(
                formattedSequenceDataForUpdate,
                requestConfig,
            );
        },
        onSuccess: (data: any) => {
            if (callback) callback(formatterIndexSequence(data.data.data));
            sendEvent("updateSequence", data.data.data as Type_event_sequence);
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationDuplicateSequence = (
    id: number, // sequence Id to duplicate
    callback?: (p: Type_index_sequence) => void,
) => {
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders.Sequence.DuplicateDialog",
    );
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const { add } = useToast();

    return useMutation({
        mutationFn: (data: Type_post_dup_sequence) =>
            duplicateSequence(
                formatterDuplicateSequence(data),
                id,
                requestConfig,
            ),
        onSuccess: (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationDuplicateSequence");
            }
            callback?.(formatterIndexSequence(data.data.data));
            add({
                description: fmt("ToastSuccess"),
                type: "success",
            });
            sendEvent("duplicateSequence");
        },
        onError: (err: any) => {
            add({
                description: fmtError("GenericErrorScheduling"),
                type: "warning",
            });
            return err;
        },
    });
};

export const mutationStatusSequence = (callback?: any) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { sendEvent } = useChannel({});

    return useMutation({
        mutationFn: (sequence: Type_sch_put_sequence) => {
            return statusSequence(sequence, requestConfig);
        },
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationStatusSequence");
            }
            await queryClient.invalidateQueries([
                SequenceFolderKeys.TREE,
                requestConfig,
            ]);
            if (callback) callback(formatterIndexSequence(data.data.data));
            sendEvent("updateSequence", data.data.data as Type_event_sequence);
        },
    });
};

export const mutationDeleteSequence = (id: number) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { sendEvent } = useChannel({});

    return useMutation({
        mutationFn: (id: number) => {
            return deleteSequence(id, requestConfig);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries([
                SequenceFolderKeys.TREE,
                requestConfig,
            ]);
            sendEvent("deleteSequence", { id: id });
        },
    });
};
