import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { PriorityOfConstraintKeys } from "src/api/tms-commons/keys";
import {
    formatterIndexPriorityOfConstraint,
    formatterIndexPriorityOfConstraints,
    formatterPostPriorityOfConstraint,
    formatterShowPriorityOfConstraint,
    formatterStatusPriorityOfConstraint,
    formatterUpdatePriorityOfConstraint,
} from "src/api/tms-commons/priorityOfConstraints/formatters";
import {
    createPriorityOfConstraint,
    deletePriorityOfConstraint,
    indexPriorityOfConstraints,
    showPriorityOfConstraint,
    updatePriorityOfConstraint,
} from "src/api/tms-commons/priorityOfConstraints/services";
import {
    Type_index_priorityOfConstraint,
    Type_post_priorityOfConstraint,
    Type_put_priorityOfConstraint,
    Type_show_priorityOfConstraint,
} from "src/api/tms-commons/priorityOfConstraints/types";
import { NO_STALE_TIME, Type_stale_times } from "src/configurations/app";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexPriorityOfConstraints = (
    staleTime: Type_stale_times = NO_STALE_TIME,
) => {
    return useQuery({
        queryKey: [PriorityOfConstraintKeys.INDEX],
        queryFn: ({ signal }) => indexPriorityOfConstraints(signal),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useIndexPriorityOfConstraints",
                );
            }

            return formatterIndexPriorityOfConstraints(data?.data?.data);
        },
        onError: (err) => {
            return err;
        },
        staleTime: staleTime,
    });
};

export const useIndexDefaultPriority = (staleTime = 0) => {
    return useQuery(
        [PriorityOfConstraintKeys.DEFAULT_PRIORITY],
        ({ signal }) => indexPriorityOfConstraints(signal),
        {
            onError: (err) => {
                console.log(err);
            },
            select: (res) => {
                if (!res?.data?.data?.length)
                    throw new Error(
                        "Wrong format data: useIndexDefaultPriority",
                    );
                return formatterIndexPriorityOfConstraints(res?.data?.data)[0]
                    .id;
            },
            enabled: true,
            refetchOnWindowFocus: false,
            staleTime,
        },
    );
};

export const useShowPriorityOfConstraint = (id: number) => {
    return useQuery(
        [PriorityOfConstraintKeys.SHOW, id],
        () => showPriorityOfConstraint(id),
        {
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error(
                        "Wrong format data: useShowPriorityOfConstraint",
                    );
                }

                return formatterShowPriorityOfConstraint(data.data.data);
            },
            onError: (err) => {
                return err;
            },
            enabled: !!id,
            refetchOnWindowFocus: false,
        },
    );
};

export const mutationCreatePriorityOfConstraint = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PriorityOfConstraints",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newPriorityOfConstraint: Type_post_priorityOfConstraint) =>
            createPriorityOfConstraint(
                formatterPostPriorityOfConstraint(newPriorityOfConstraint),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreatePriorityOfConstraint",
                );
            }
            const priorityOfConstraint: Type_index_priorityOfConstraint =
                formatterIndexPriorityOfConstraint(data.data.data);

            await queryClient.invalidateQueries([
                PriorityOfConstraintKeys.INDEX,
            ]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        priority: priorityOfConstraint.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdatePriorityOfConstraint = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PriorityOfConstraints",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (priorityOfConstraint: Type_show_priorityOfConstraint) =>
            updatePriorityOfConstraint(
                priorityOfConstraint.id,
                formatterUpdatePriorityOfConstraint(priorityOfConstraint),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdatePriorityOfConstraint",
                );
            }

            const priorityOfConstraint: Type_index_priorityOfConstraint =
                formatterIndexPriorityOfConstraint(data.data.data);

            await queryClient.invalidateQueries([
                PriorityOfConstraintKeys.INDEX,
            ]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        priority: priorityOfConstraint.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusPriorityOfConstraint = () => {
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (newStatus: Type_put_priorityOfConstraint) => {
            return updatePriorityOfConstraint(
                newStatus.id,
                formatterStatusPriorityOfConstraint(newStatus),
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusPriorityOfConstraint",
                );
            }

            await queryClient.invalidateQueries([
                PriorityOfConstraintKeys.INDEX,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeletePriorityOfConstraint = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PriorityOfConstraints",
    );
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => deletePriorityOfConstraint(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationDeletePriorityOfConstraint",
                );
            }
            await queryClient.invalidateQueries({
                queryKey: [PriorityOfConstraintKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
