import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import {
    TextFieldPassword as TextFieldPasswordComponent,
    Type_Props_TextFieldPassword as Type_Props_TextFieldPasswordComponent,
} from "src/components/Components_Common/forms/TextFieldPassword/TextFieldPassword";
import { Type_DataTestIdComponent } from "src/components/Components_Common/types";

type Type_Props_TextFieldPassword = Type_Props_TextFieldPasswordComponent &
    Type_DataTestIdComponent & {
        name: string;
    };

export const TextFieldPassword = ({
    name,
    label,
    ...props
}: Type_Props_TextFieldPassword) => {
    const { formState, control } = useFormContext();

    return (
        <React.Fragment>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref, ...field } }) => (
                    <TextFieldPasswordComponent
                        {...props}
                        {...field}
                        label={label}
                        error={Boolean(formState.errors[name])}
                        inputRef={ref}
                    />
                )}
            />
            {formState.errors[name] && (
                <TMC_FormHelperText
                    inputName={name}
                    helperText={formState?.errors[name]?.message as string}
                />
            )}
        </React.Fragment>
    );
};
