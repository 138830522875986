import React, { createContext, useContext, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import {
    URL_LOGISTICS,
    URL_MY_TEAMOTY,
    URL_RECYCLE,
    URL_SCHEDULING,
} from "src/router";

export const enum apps {
    MY_TEAMOTY = "myTeamoty",
    SCHEDULING = "scheduling",
    LOGISTICS = "logistics",
    RECYCLE = "recycle",
    NONE = "",
}

type Type_switcher = {
    selectedApp: apps;
    quitApp: () => void;
    switchTo: (app: apps) => void;
    getCurrentApp: () => string;
};

const initialSwitcherState: Type_switcher = {
    selectedApp: apps.NONE,
    switchTo: () => {},
    quitApp: () => {},
    getCurrentApp: () => "",
};

export const useAppSwitcher = (): Type_switcher =>
    useContext(AppSwitcherContext);

type Type_switcherProvider = {
    children: JSX.Element;
};

const AppSwitcherContext = createContext(initialSwitcherState);

export const AppSwitcherProvider = ({ children }: Type_switcherProvider) => {
    const [selectedApp, setSelectedApp] = useState<apps>(apps.NONE);
    const navigate = useNavigate();
    const urlParams = useParams();

    const quitApp = () => {
        setSelectedApp(apps.NONE);
    };

    const switchTo = (app: apps) => {
        setSelectedApp(app);
        let appUrl;
        switch (app) {
            case apps.MY_TEAMOTY:
                appUrl = URL_MY_TEAMOTY;
                break;
            case apps.SCHEDULING:
                appUrl = `${URL_SCHEDULING}`;
                break;
            case apps.LOGISTICS:
                appUrl = `${URL_LOGISTICS}`;
                break;
            case apps.RECYCLE:
                appUrl = URL_RECYCLE;
                break;
            default:
                appUrl = URL_MY_TEAMOTY;
                break;
        }

        navigate(
            generatePath(appUrl, {
                ...urlParams,
            }),
        );
    };

    const getCurrentApp = () => selectedApp;

    const switcherState: Type_switcher = {
        selectedApp,
        quitApp,
        switchTo,
        getCurrentApp,
    };

    return (
        <AppSwitcherContext.Provider value={switcherState}>
            {children}
        </AppSwitcherContext.Provider>
    );
};
