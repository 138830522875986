import { Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { ColorPickerIcon } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPickerIcon";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { HeaderToolbar } from "src/components/Components_Teamoty/contextualDrawers/HeaderToolbar/HeaderToolbar";
import { useLanguage } from "src/contexts/languages";
import { Styled_HeaderInput } from "src/layouts/Layout_ContextualDrawer/ContextualDrawer.style";
import {
    Header,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";

type Type_Props_SequenceHeader = {
    notesCount?: number;
};

export const SequenceHeader = ({ notesCount }: Type_Props_SequenceHeader) => {
    const { languages } = useLanguage();
    const { control, register, getValues } = useFormContext();
    const { closePaper } = useContextualDrawer();
    const sequenceColor = useWatch({
        name: "color",
        defaultValue: null,
        control: control,
    });

    const headerBackgroundColor = useMemo(() => {
        return sequenceColor;
    }, [sequenceColor]);

    const localeToShortLocale = (locale: string) =>
        locale.slice(0, 2).toUpperCase();

    return (
        <Header
            sx={{
                backgroundColor: headerBackgroundColor,
                color: invertColor(headerBackgroundColor || "#FFF"),
                boxShadow: `0px -1px 0px 0px rgba(22, 50, 95, 0.18) inset`,
            }}
        >
            <HeaderToolbar
                dataTestIdRef="Sequence-Header"
                notes={"sequenceNotes"}
                sequenceId={getValues("id")}
                notesCount={notesCount}
                onClose={() => closePaper("sequence")}
            />
            <Stack
                direction={"row"}
                alignItems={"start"}
                sx={{ paddingLeft: 2 }}
            >
                <Stack flexGrow={1}>
                    {languages.map((language, index) => (
                        <Grid container key={language} rowSpacing={0.5}>
                            <Grid item xs={1}>
                                {index === 0 ? ( // TODO should be refactored
                                    <Stack
                                        justifyContent={"center"}
                                        alignItems={"end"}
                                    >
                                        <Icon
                                            variant={"kit"}
                                            icon={"light-tds-sequence"}
                                            fontSize={"large"}
                                        />
                                    </Stack>
                                ) : (
                                    <Typography
                                        variant={"body3"}
                                        paddingInlineStart={3}
                                    >
                                        {localeToShortLocale(language)}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={11} paddingInlineStart={2}>
                                <Styled_HeaderInput
                                    {...register(`names.${language}`)}
                                    data-testid={`sequence-drawer-input-name-${language}`}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Stack>
                <ColorPickerIcon
                    name="color"
                    data-testid={"sequence-drawer-colorPicker"}
                />
            </Stack>
        </Header>
    );
};
