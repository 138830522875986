import {
    Type_com_index_metadataProject,
    Type_com_post_metadataProject,
    Type_com_put_metadataProject,
    Type_com_show_metadataProject,
    Type_index_metadataProject,
    Type_metadataProjects_For_Form,
    Type_post_metadataProject,
    Type_put_metadataProject,
    Type_show_metadataProject,
} from "src/api/tms-commons/metadata/types";
import { metadataWithValues } from "src/configurations/metadataTypes";

export const formatterIndexMetadataProject = (
    metadataProjects: Type_com_index_metadataProject[],
): Type_index_metadataProject[] => {
    if (!metadataProjects) return [];
    return metadataProjects.map(
        (metadataProject: Type_com_index_metadataProject) =>
            formatterIndexMetadatumProject(metadataProject),
    );
};

export const formatterIndexMetadatumProject = (
    metadataProject: Type_com_index_metadataProject,
) => {
    return {
        id: metadataProject.id,
        name: metadataProject.name,
        key: metadataProject.key,
        metadataType: capitalized_metadataType(metadataProject.metadataType),
        values: metadataProject.values,
        enabled: metadataProject.enabled,
        sort: metadataProject.sort,
        forFilter: metadataProject.forFilter,
    };
};

export const formatterIndexMetadataProjectsForForm = (
    metadataProjects: Type_com_index_metadataProject[],
): Type_metadataProjects_For_Form[] => {
    if (!metadataProjects) return [];
    return metadataProjects.map(
        (metadataProjects: Type_com_index_metadataProject) => ({
            id: metadataProjects.id,
            name: metadataProjects.key,
            label: metadataProjects.name,
            metadataType: metadataProjects.metadataType,
            values: metadataProjects.values,
            enabled: metadataProjects.enabled,
            sort: metadataProjects.sort,
            forFilter: metadataProjects.forFilter,
        }),
    );
};

export const formatterCreateMetadataProject = (
    metadataProject: Type_post_metadataProject,
): Type_com_post_metadataProject => {
    return {
        names: metadataProject.names,
        key: metadataProject.key,
        metadataType: metadataProject?.metadataType,
        forFilter: metadataProject.forFilter,
        sort: metadataProject.sort,
        values: metadataWithValues.includes(metadataProject?.metadataType)
            ? metadataProject.values
            : [],
    };
};

export const formatterUpdateMetadataProject = (
    metadataProject: Type_show_metadataProject,
): Type_com_put_metadataProject => {
    return {
        names: metadataProject.names,
        key: metadataProject.key,
        metadataType: metadataProject.metadataType,
        forFilter: metadataProject.forFilter,
        sort: metadataProject.sort,
        values:
            metadataProject.metadataType &&
            metadataWithValues.includes(metadataProject.metadataType)
                ? metadataProject.values
                : [],
    };
};

export const formatterShowMetadataProject = (
    metadataProject: Type_com_show_metadataProject,
): Type_show_metadataProject => {
    return {
        id: metadataProject.id,
        names: metadataProject.names,
        key: metadataProject.key,
        metadataType: metadataProject.metadataType,
        forFilter: metadataProject.forFilter,
        values: metadataProject.values,
        sort: metadataProject.sort,
    };
};

export const formatterStatusMetadataProject = (
    metadataProject: Type_put_metadataProject,
): Type_com_put_metadataProject => {
    return {
        enabled: metadataProject.enabled,
    };
};

export const capitalized_metadataType = (metadataType: string) => {
    return metadataType.charAt(0).toUpperCase() + metadataType.slice(1);
};
