import { get, GetReturnType } from "src/api/fetch";
import { ToolsPath, Url } from "src/api/paths";

export const selectListTimelines = (): GetReturnType => {
    return get(`${Url.PRINT}${ToolsPath.TIMELINES}`);
};

export const selectListPapers = (): GetReturnType => {
    return get(`${Url.PRINT}${ToolsPath.PAPERS}`);
};
