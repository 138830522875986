import * as React from "react";
import { useState } from "react";

import { useShowTask } from "src/api/tms-scheduling/tasks";
import { LoadingBox } from "src/components";
import { TaskForm } from "src/forms/tasks/TaskForm";
import { Type_event_message, useChannel } from "src/hooks/useChannel";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_TaskContextualDrawer = {
    onClose: () => void;
    id: number;
    readonly?: boolean;
};

export const TaskContextualDrawer = ({
    onClose,
    id: taskId,
    readonly = false,
}: Type_Props_TaskContextualDrawer) => {
    const [id, setId] = useState<number>(taskId);

    useChannel({
        eventHandler: (data: Type_event_message): void => {
            if (data.event == "updateIdTask") setId(data?.data as number);
        },
    });

    const { isFetching, data } = useShowTask(id);

    return (
        <Styled_PaperContextualLayout
            id="task"
            data-testid="TaskContextualDrawer"
        >
            {isFetching || !data ? (
                <LoadingBox />
            ) : (
                <TaskForm onClose={onClose} data={data} readonly={readonly} />
            )}
        </Styled_PaperContextualLayout>
    );
};
