import { AutocompleteRenderGetTagProps } from "@mui/material";
import React, { ComponentProps, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Type_selectList_company } from "src/api/tms-commons/companies/types";
import { formatterCompaniesFromDataPlanningCompanies } from "src/api/tms-projects/projectCompanies/formatters";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { usePlanningContext } from "src/contexts/planning";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_PlanningFilterCompanies = Omit<
    ComponentProps<typeof Autocomplete>,
    | "multiple"
    | "disableCloseOnSelect"
    | "options"
    | "getOptionLabel"
    | "isOptionEqualToValue"
    | "renderTags"
    | "componentsProps"
>;

export const PlanningFilterCompanies = ({
    name,
    label,
    ...props
}: Type_Props_PlanningFilterCompanies) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerFilters",
    );

    const { dataPlanning } = usePlanningContext();

    const { setValue, watch } = useFormContext();

    const [companiesList, setCompaniesList] = useState<
        Type_selectList_company[]
    >([]);

    useEffect(() => {
        if (dataPlanning) {
            const companies = formatterCompaniesFromDataPlanningCompanies(
                dataPlanning.companies,
            );

            setCompaniesList(companies);
        }
    }, [dataPlanning?.companies]);

    const selectedCompanies = watch(name) as Type_selectList_company[];

    // Retreive the unselected companies
    useEffect(() => {
        const selectedCompaniesIds =
            selectedCompanies?.map((company) => company.id) || []; // Get selected companies' IDs

        if (selectedCompaniesIds.length === 0) {
            setValue("notSelectedCompanies", []);
        } else {
            const notSelectedCompanies = companiesList
                .filter((company) => !selectedCompaniesIds.includes(company.id))
                .map((company) => company.id);

            setValue("notSelectedCompanies", notSelectedCompanies);
        }
    }, [companiesList, selectedCompanies]); // Trigger effect on companiesList and selectedCompanies

    return (
        <Autocomplete
            {...props}
            name={name}
            label={label}
            multiple
            disableCloseOnSelect
            options={companiesList}
            renderTags={(
                tagValue: any[],
                getTagProps: AutocompleteRenderGetTagProps,
            ) =>
                tagValue.map((option, index: number) => {
                    // Erreur console si on spread avec key dedans
                    const { key, ...tagPropsWithoutKey } = getTagProps({
                        index,
                    });

                    return (
                        <TMC_Chip
                            key={key}
                            label={option.name}
                            data-testid={`PlanningFilter-AutocompleteCompanies-${option.name.replaceAll(
                                " ",
                                "_",
                            )}`}
                            {...tagPropsWithoutKey}
                        />
                    );
                })
            }
            componentsProps={{
                clearIndicator: {
                    // @ts-expect-error data-testid are not on IconButtonProps
                    "data-testid": `planning-filter-autocomplete-companies-clearButton-${name}`,
                },
            }}
            renderInputProps={{
                variant: "outlined",
                placeholder: fmt("CompaniesPlaceholder"),
            }}
        />
    );
};
