import { Stack, Typography } from "@mui/material";
import {
    DataGridPremiumProps,
    GridCellModes,
    GridCellModesModel,
    GridCellParams,
    GridValidRowModel,
} from "@mui/x-data-grid-premium";
import React, { memo, useCallback, useState } from "react";
import { QueryObserverResult, UseMutateAsyncFunction } from "react-query";

import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { Styled_Matrix } from "src/components/Components_Common/matrix/Matrix.style";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { LinearWithValueLabel } from "./ProgressBar";

// WIDTHS AND SIZES
const LINE_HEIGHT = 32;
const ROW_HEIGHT = 42;

export const getDifferentAttributes = (
    newValue: GridValidRowModel,
    oldValue: GridValidRowModel,
): GridValidRowModel => {
    const diff = Object.keys(newValue).reduce((differences, key) => {
        if (oldValue[key] !== newValue[key]) {
            differences[key] = newValue[key];
        }
        return differences;
    }, {} as GridValidRowModel);

    // id doit toujours être présent
    if (Object.keys(diff).length) diff.id = newValue.id;

    return diff;
};

export type Type_Props_MemoizedDataGridPro = DataGridPremiumProps & {
    height?: string | number;
    submitWithCta?: boolean;
    mutateUpdate?: UseMutateAsyncFunction<any, any, any, unknown>;
    refetch?: () => Promise<QueryObserverResult<any, any>>;
    isSingleClickEditing?: boolean;
    processRowUpdateAdditonalsBehaviors?: (
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel,
    ) => GridValidRowModel;
};

const MemoizedDataGridPro = ({
    columns,
    height,
    submitWithCta = true,
    mutateUpdate,
    refetch,
    isSingleClickEditing = true,
    processRowUpdateAdditonalsBehaviors,
    ...props
}: Type_Props_MemoizedDataGridPro) => {
    //i18n
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    // states
    const [gridData, setGridData] = useState<GridValidRowModel[]>([]);
    const [progressBarValue, setProgressBarValue] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [cellModesModel, setCellModesModel] = useState({});

    // utils
    const handleSaveAll = async () => {
        try {
            setIsSubmitting(true);
            const mutations = gridData.map(async (elem) => {
                if (mutateUpdate) {
                    await mutateUpdate({ id: elem.id, data: elem });
                }
                setProgressBarValue((prev) => (prev ?? 0) + 1);
            });

            await Promise.all(mutations);
        } catch (error) {
            console.error("handleSaveAll", error);
        } finally {
            setIsSubmitting(false);
            if (refetch) {
                refetch();
            }
        }
    };

    /*
     * - Gestion de l'édition au on blur
     */

    const processRowUpdate = async (
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel,
    ) => {
        if (progressBarValue && gridData.length === progressBarValue) {
            setProgressBarValue(0);
            setGridData([]);
        }

        let diff: GridValidRowModel | null = null;

        if (processRowUpdateAdditonalsBehaviors) {
            const result = processRowUpdateAdditonalsBehaviors(newRow, oldRow);
            diff = result;
            newRow = { ...newRow, ...result };
        } else diff = getDifferentAttributes(newRow, oldRow);

        if (!Object.keys(diff).length) return newRow;

        if (submitWithCta) {
            const exist = gridData.find((elem) => elem.id === diff?.id);

            if (exist)
                setGridData((prevData) =>
                    prevData.map((row) =>
                        row.id === newRow.id ? { ...row, ...diff } : row,
                    ),
                );
            else
                setGridData((prevData) => [
                    ...prevData,
                    diff as GridValidRowModel,
                ]);
        } else if (mutateUpdate) {
            await mutateUpdate({ id: newRow.id, data: diff });
        }
        return newRow;
    };

    /*
     * - Gestion de l'édition au simple clic
     */

    // Lien : https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing
    const handleCellClick = useCallback(
        (params: GridCellParams, event: React.MouseEvent) => {
            if (!params.isEditable) {
                return;
            }
            // Vérifiez= si la touche Ctrl est maintenue.
            // On sort si maintenu pour conserver l'édtion de masse
            if (event.ctrlKey) {
                return;
            }

            // Ignore portal
            if (
                (event.target as any).nodeType === 1 &&
                !event.currentTarget.contains(event.target as Element)
            ) {
                return;
            }

            type CellModesModel = {
                [key: string]: {
                    [field: string]: { mode: GridCellModes };
                };
            };

            setCellModesModel((prevModel: CellModesModel) => {
                return {
                    ...Object.keys(prevModel).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: Object.keys(prevModel[id]).reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field]: { mode: GridCellModes.View },
                                }),
                                {},
                            ),
                        }),
                        {},
                    ),
                    [params.id]: {
                        ...Object.keys(prevModel[params.id] || {}).reduce(
                            (acc, field) => ({
                                ...acc,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {},
                        ),
                        [params.field]: { mode: GridCellModes.Edit },
                    },
                };
            });
        },
        [],
    );

    const handleCellModesModelChange = useCallback(
        (newModel: GridCellModesModel) => {
            setCellModesModel(newModel);
        },
        [],
    );

    return (
        <Stack
            flexGrow={1}
            height={height ?? "auto"}
            data-testid={`Matrix-Container`}
            width="100%"
            sx={{
                overflowY: "hidden",
                overflowX: "auto",
            }}
            justifyContent="space-between"
        >
            <Styled_Matrix
                {...props}
                processRowUpdate={props.processRowUpdate ?? processRowUpdate}
                cellSelection
                // Récupération de la valeur brut du champ et non celle du visuelle
                ignoreValueFormatterDuringExport
                columns={columns}
                getRowHeight={() => ROW_HEIGHT}
                columnHeaderHeight={LINE_HEIGHT}
                // Début - Permet la gestion de l'édition en un clic
                {...(isSingleClickEditing
                    ? {
                          cellModesModel: cellModesModel,
                          onCellModesModelChange: handleCellModesModelChange,
                          onCellClick: handleCellClick,
                      }
                    : {})}
                // - Fin
                hideFooter={true}
                disableColumnMenu
                disableColumnResize={false}
                slots={{
                    ...props.slots,
                }}
                // TODO: trouver une solution pour laisser les data-testid, sans nuir au singleClickEdition
                /*slots={{
                    cell: (props) => (
                        <GridCell
                            {...props}
                            data-testid={`cell-${props.rowId}-${props.column.field}`}
                        />
                    ),
                    row: (props) => (
                        <GridRow
                            {...props}
                            data-testid={`row-${props.rowId}`}
                        />
                    ),
                }}*/
            />
            {submitWithCta && (
                <Stack
                    flexWrap="nowrap"
                    flexDirection="row"
                    alignItems="center"
                >
                    <LinearWithValueLabel
                        step={gridData.length}
                        value={progressBarValue}
                    />
                    <TMC_Button
                        type="button"
                        variant="contained"
                        onClick={handleSaveAll}
                        style={{ height: "auto" }}
                        disabled={
                            !!(
                                isSubmitting ||
                                !gridData.length ||
                                (progressBarValue &&
                                    gridData.length === progressBarValue)
                            )
                        }
                    >
                        <Typography>{fmtActions("Save")}</Typography>
                    </TMC_Button>
                </Stack>
            )}
        </Stack>
    );
};

export const Matrix = memo(MemoizedDataGridPro);
