import { ChipProps } from "@mui/material";
import { ReactNode } from "react";

import { Styled_ChipDigit } from "./Digit.style";

type Type_DigitComponentProps = {
    value: ReactNode;
    isFocus: boolean;
};

export const Digit = ({
    value,
    isFocus,
    ...props
}: Type_DigitComponentProps & ChipProps) => {
    return (
        <Styled_ChipDigit {...props} label={value} ownerState={{ isFocus }} />
    );
};
