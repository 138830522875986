// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_SmallLink = styled(Link)(() => ({
    fontSize: 12,
    lineHeight: "16px",
}));

export const Styled_Link = styled(Link)(() => ({
    cursor: "pointer",
}));
