import Konva from "konva";
import React from "react";
import { Text } from "react-konva";

import {
    fontFlowFamily,
    fontFlowVariant,
    heightFlowShape,
    paddingFlowText,
} from "src/components/Components_Teamoty/Flow/Flow.const";

export const FlowShapeTextProps = (props: Konva.TextConfig) => {
    const padding = paddingFlowText;
    const height = heightFlowShape / 2;

    return (
        <Text
            x={props.x}
            y={props.y}
            text={props.text}
            offsetX={props.offsetX}
            width={props.width}
            height={height}
            padding={padding}
            ellipsis={true}
            listening={false}
            fontFamily={fontFlowFamily}
            fontVariant={fontFlowVariant}
            fontSize={10}
            wrap="none"
            fill="black"
            verticalAlign="middle"
            align="center"
        />
    );
};
