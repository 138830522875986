import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { PunchlistItemCauseKeys } from "src/api/tms-commons/keys";
import {
    formatterIndexPunchlistItemCause,
    formatterIndexPunchlistItemCauses,
    formatterPostPunchlistItemCause,
    formatterPutPunchlistItemCause,
    formatterSelectListPunchlistItemCauses,
    formatterShowPunchlistItemCause,
} from "src/api/tms-commons/punchlistItemCauses/formatters";
import {
    createPunchlistItemCause,
    deletePunchlistItemCause,
    indexPunchlistItemCauses,
    selectPunchlistItemCauses,
    showPunchlistItemCause,
    statusPunchlistItemCause,
    updatePunchlistItemCause,
} from "src/api/tms-commons/punchlistItemCauses/services";
import {
    Type_post_punchlistItemCause,
    Type_put_punchlistItemCause,
} from "src/api/tms-commons/punchlistItemCauses/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexPunchlistItemCauses = () => {
    return useQuery({
        queryKey: [PunchlistItemCauseKeys.INDEX],
        queryFn: indexPunchlistItemCauses,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowPunchlistItemCause");
            }

            return formatterIndexPunchlistItemCauses(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useShowPunchlistItemCause = (id: number) => {
    return useQuery(
        [PunchlistItemCauseKeys.SHOW, id],
        () => showPunchlistItemCause(id),
        {
            onError: (err) => {
                return err;
            },
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error(
                        "Wrong format data: useShowPunchlistItemCause",
                    );
                }

                return formatterShowPunchlistItemCause(data.data.data);
            },
            enabled: !!id,
            refetchOnWindowFocus: false,
        },
    );
};

export const mutationStatusPunchlistItemCause = () => {
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newStatus: Type_put_punchlistItemCause) => {
            return statusPunchlistItemCause(
                formatterPutPunchlistItemCause(newStatus),
                newStatus.id as number,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusPunchlistItemCause",
                );
            }

            await queryClient.invalidateQueries({
                queryKey: [PunchlistItemCauseKeys.INDEX],
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCreatePunchlistItemCause = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PunchlistItemCauses",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newPunchlistItemCause: Type_post_punchlistItemCause) =>
            createPunchlistItemCause(
                formatterPostPunchlistItemCause(newPunchlistItemCause),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreatePunchlistItemCause",
                );
            }
            const punchlistItemCause = formatterIndexPunchlistItemCause(
                data.data.data,
            );

            await queryClient.invalidateQueries({
                queryKey: [PunchlistItemCauseKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        cause: punchlistItemCause.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdatePunchlistItemCause = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PunchlistItemCauses",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (punchlistItemCause: Type_put_punchlistItemCause) =>
            updatePunchlistItemCause(
                punchlistItemCause.id,
                formatterPutPunchlistItemCause(punchlistItemCause),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdatePunchlistItemCause",
                );
            }
            const punchlistItemCause = formatterIndexPunchlistItemCause(
                data.data.data,
            );

            await queryClient.invalidateQueries({
                queryKey: [PunchlistItemCauseKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        cause: punchlistItemCause.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeletePunchlistItemCause = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PunchlistItemCauses",
    );

    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => deletePunchlistItemCause(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationDeletePunchlistItemCause",
                );
            }
            await queryClient.invalidateQueries({
                queryKey: [PunchlistItemCauseKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};

export const useSelectListPunchlistItemCauses = (enabled: boolean = true) => {
    return useQuery({
        queryKey: [PunchlistItemCauseKeys.SELECT_LIST],
        queryFn: selectPunchlistItemCauses,
        refetchOnWindowFocus: false,
        enabled: enabled,
        select: (resp) => {
            return formatterSelectListPunchlistItemCauses(resp?.data?.data);
        },
    });
};
