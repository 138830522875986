import {
    Type_com_index_resourceModel,
    Type_com_post_resourceModel,
    Type_com_put_resourceModel,
    Type_com_selectList_ResourceModel,
    Type_com_show_resourceModel,
    Type_index_resourceModel,
    Type_post_resourceModel,
    Type_put_resourceModel,
    Type_select_resourceModel,
    Type_show_resourceModel,
} from "src/api/tms-commons/resourceModels/types";

export const formatterIndexResourceModel = (
    resourceModel: Type_com_index_resourceModel,
): Type_index_resourceModel => {
    return {
        id: resourceModel.id,
        name: resourceModel.name,
        resourceFamilyName: resourceModel.resourceFamily.name,
        unitName: resourceModel.unit.name,
        enabled: resourceModel.enabled,
    };
};

export const formatterIndexResourceModels = (
    resourceModels: Type_com_index_resourceModel[],
): Type_index_resourceModel[] => {
    if (!resourceModels) return [];

    return resourceModels.map((resourceModel: Type_com_index_resourceModel) => {
        return formatterIndexResourceModel(resourceModel);
    });
};

export const formatterUpdateResourceModel = (
    resourceModel: Type_show_resourceModel,
): Type_com_put_resourceModel => {
    return {
        names: resourceModel.names,
        resource_family_id: resourceModel.resourceFamily.id,
        unit_id: resourceModel.unit,
    };
};

export const formatterCreateResourceModel = (
    resourceModel: Type_post_resourceModel,
): Type_com_post_resourceModel => {
    return {
        names: resourceModel.names,
        resource_family_id: resourceModel.resourceFamily.id,
        unit_id: resourceModel.unit,
    };
};

export const formatterShowResourceModel = (
    data: Type_com_show_resourceModel,
): Type_show_resourceModel => {
    return {
        id: data.id,
        names: data.names,
        resourceFamily: data.resourceFamily,
        unit: data.unit.id,
    };
};

export const formatterSelectListResourceModel = (
    resourceModel: Type_com_selectList_ResourceModel,
): Type_select_resourceModel => {
    return {
        id: resourceModel.id,
        name: resourceModel.name,
    };
};
export const formatterSelectListResourceModels = (
    resourceModels: Type_com_selectList_ResourceModel[],
): Type_select_resourceModel[] => {
    if (!resourceModels) return [];

    return resourceModels.map(
        (resourceModel: Type_com_selectList_ResourceModel) => {
            return formatterSelectListResourceModel(resourceModel);
        },
    );
};

export const formatterStatusResourceModel = (
    resourceModel: Type_put_resourceModel,
): Type_com_put_resourceModel => {
    return {
        enabled: resourceModel.enabled,
    };
};
