import {
    Autocomplete,
    AutocompleteRenderInputParams,
    TextField,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

import { Type_Filter } from "src/api/filters";
import { useIndexProjectUsers } from "src/api/tms-projects/projectUsers";
import { Type_index_projectUser } from "src/api/tms-projects/projectUsers/types";
import { getLocalStorageItem } from "src/utils/localStorageServices";

type Type_Props_AutocompleteRoles = {
    name: string;
    label: string;
    projectId?: number;
    subProjectId?: number | null;
    enabled?: boolean | null;
};

/**
 * @deprecated use the RHF one
 * @param name
 * @param label
 * @param enabled
 * @constructor
 */
export const AutocompleteRoles = ({
    name,
    label,
    enabled,
}: Type_Props_AutocompleteRoles) => {
    const formik: any = useFormikContext();
    const [roles, setRoles] = useState<Type_index_projectUser[]>([]);

    const language = getLocalStorageItem("language");
    const filters: Type_Filter = {};
    typeof enabled == "boolean" && (filters.enabled = enabled);

    const { isFetching: isFetchingRoles } = useIndexProjectUsers() || {};

    useEffect(() => {
        if (isFetchingRoles) {
            setRoles([]);
        }
    }, [isFetchingRoles]);

    return (
        <Autocomplete
            data-testid={`autocomplete-roles-${name}`}
            id={`autocomplete-roles-${name}`}
            multiple
            loading={isFetchingRoles}
            onChange={(e, value) => formik.setFieldValue(name, value)}
            value={formik.values[name] || []}
            options={roles || []}
            getOptionLabel={(option) =>
                option.name || option.names[language] || ""
            }
            isOptionEqualToValue={(option, selectedValues): boolean =>
                option?.id === selectedValues.id
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    name={name}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        "data-testid": `autocomplete-roles-${name}-input`,
                    }}
                />
            )}
            renderOption={(props: object, option: any) => (
                <li
                    {...props}
                    data-testid={`autocomplete-roles-${name}-input-${option.name}`}
                >
                    {option.name}
                </li>
            )}
            componentsProps={{
                clearIndicator: {
                    // @ts-expect-error data-testid are not on IconButtonProps
                    "data-testid": `autocomplete-roles-clearButton-${name}`,
                },
            }}
        />
    );
};
