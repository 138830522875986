import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { SequencePath, Url } from "src/api/paths";
import {
    Type_sch_post_dup_sequence,
    Type_sch_post_sequence,
    Type_sch_put_sequence,
} from "src/api/tms-scheduling/sequences/types";

export const indexSequences = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${SequencePath.SEQUENCES}`, requestConfig);
};

export const showSequence = (
    requestConfig: Type_RequestConfig,
    sequenceId: number,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${SequencePath.SEQUENCES}/${sequenceId}`,
        requestConfig,
    );
};

export const createSequence = (
    sequence: Type_sch_post_sequence,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        sequence,
        `${Url.SCHEDULING}${SequencePath.SEQUENCES}`,
        requestConfig,
    );
};

export const duplicateSequence = (
    sequence: Type_sch_post_dup_sequence,
    id: number,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        sequence,
        `${Url.SCHEDULING}${SequencePath.SEQUENCES}/${id}/${SequencePath.DUPLICATE}`,
        requestConfig,
    );
};

export const updateSequence = (
    sequence: Type_sch_put_sequence,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        sequence,
        `${Url.SCHEDULING}${SequencePath.SEQUENCES}/${sequence.id}`,
        requestConfig,
    );
};

export const statusSequence = (
    sequence: Type_sch_put_sequence,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        sequence,
        `${Url.SCHEDULING}${SequencePath.SEQUENCES}/${sequence.id}`,
        requestConfig,
    );
};

export const deleteSequence = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${SequencePath.SEQUENCES}/${id}`,
        requestConfig,
    );
};
