///////////////////////////////
/// React Hook Form         ///
///////////////////////////////

import { UseFormProps } from "react-hook-form";

export const useFormDefaultConfig: UseFormProps<any> = {
    /**
     * When set to all, all errors from each field will be gathered.
     * @see https://react-hook-form.com/docs/useform#criteriaMode
     */
    criteriaMode: "all",
    /**
     * Setting shouldUnregister: true makes your form behave more closely to native forms.
     * @see https://react-hook-form.com/docs/useform#shouldUnregister
     */
    // shouldUnregister: true,
    // TODO find why taskform loose TaskId on PUT
    /**
     * When set to true (default), and the user submits a form that fails validation, focus is set on the first field with an error.
     * @see https://react-hook-form.com/docs/useform#shouldFocusError
     */
    shouldFocusError: false,
};

///////////////////////////////
/// React Query             ///
///////////////////////////////

export const NO_STALE_TIME = 0; // cache disabled
export const SMALL_STALE_TIME = 120000; // 2 min
export const MEDIUM_STALE_TIME = 300000; // 5 min
export const LONG_STALE_TIME = 86400000; // 1 day
export const ONE_MONTH_STALE_TIME = 2592000000; // 30 days

export type Type_stale_times =
    | typeof NO_STALE_TIME
    | typeof SMALL_STALE_TIME
    | typeof MEDIUM_STALE_TIME
    | typeof LONG_STALE_TIME
    | typeof ONE_MONTH_STALE_TIME;

///////////////////////////////
/// UseMeasure default      ///
///////////////////////////////

export const useMeasureDefaultConfig = {
    debounce: 100,
    scroll: false,
};

///////////////////////////////
/// Task                    ///
///////////////////////////////

// TODO should be rename SEPARATOR_CODE_TASK
export const separatorCodeTask: string = "-";

export const DURATION_STEP: number = 1;
export const DURATION_MIN: number = 0;
export const QUANTITY_STEP: number = 1;
export const QUANTITY_MIN: number = 1;
export const TEAM_STEP: number = 1;
export const TEAM_MIN: number = 0;
export const WORKFORCE_STEP: number = 0.1;
export const WORKFORCE_MIN: number = 0;
export const PROGRESSION_STEP: number = 25;
export const DEBOUNCE_TIME: number = 200;

///////////////////////////////
/// Select                  ///
///////////////////////////////

export const INVISIBLE_VALUE = "\u00A0";
export const SELECT_NONE_VALUE = -1;
