import React from "react";

import {
    mutationCreateTaskNote,
    mutationDeleteTaskNote,
    mutationUpdateTaskNote,
    useIndexTaskNotes,
} from "src/api/tms-scheduling/taskNotes";
import { Type_index_taskNote } from "src/api/tms-scheduling/taskNotes/types";
import { useUser } from "src/contexts/user";
import {
    NotesForm,
    Type_permissionsNote,
    Type_Props_NotesForm,
} from "src/forms/notes/NotesForm";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_TaskNotes = {
    taskId?: number;
};

export const TaskNotes = ({ taskId }: Type_Props_TaskNotes) => {
    const { closePaper } = useContextualDrawer();
    const { hasPermission } = useUser();

    ///////////////////////////////////////
    ///            Query                ///
    ///////////////////////////////////////

    const { isFetching, data: taskNotes } =
        useIndexTaskNotes({
            taskId: taskId,
        }) || {};

    ///////////////////////////////////////
    ///           Mutations             ///
    ///////////////////////////////////////

    const { mutateAsync: mutateCreateNote } =
        mutationCreateTaskNote({
            taskId: taskId,
        }) || {};
    const { mutateAsync: mutateUpdateNote } = mutationUpdateTaskNote() || {};
    const { mutateAsync: mutateDeleteNote } =
        mutationDeleteTaskNote({
            taskId: taskId,
        }) || {};

    ///////////////////////////////////////
    ///         Permissions             ///
    ///////////////////////////////////////

    const permissions: Type_permissionsNote = {
        canShow: hasPermission("taskNotes_index"),
        canDelete: hasPermission("taskNotes_delete"),
        canEdit: hasPermission("taskNotes_create"),
    };

    return (
        <TaskNotesForm
            apiRef="TaskNotes"
            notes={taskNotes}
            isFetching={isFetching}
            mutateCreateNote={mutateCreateNote}
            mutateUpdateNote={mutateUpdateNote}
            mutateDeleteNote={mutateDeleteNote}
            permissions={permissions}
            onClose={() => closePaper("taskNotes")}
            sendEventCount={"updateNotesCount"}
        />
    );
};

// this function formats the data taskNotes correctly
const TaskNotesForm = ({
    ...props
}: Type_Props_NotesForm<Type_index_taskNote>) => {
    return <NotesForm {...props} />;
};
