type ObjectDifferences<T> = {
    [key in keyof T]?: {
        oldValue: T[key] | undefined;
        newValue: T[key] | undefined;
    };
};

export const getObjectDifferences = <T>(
    obj1: T,
    obj2: T,
): ObjectDifferences<T> => {
    const differences: ObjectDifferences<T> = {};

    // Iterate through properties of obj1
    for (const key in obj1) {
        // Check if the property exists in obj2
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
            // If the property exists, compare values
            if (obj1[key] !== obj2[key]) {
                differences[key] = { oldValue: obj1[key], newValue: obj2[key] };
            }
        } else {
            // If the property doesn't exist in obj2, add it to differences
            differences[key] = { oldValue: obj1[key], newValue: undefined };
        }
    }

    // Iterate through properties of obj2 to find additional properties in obj2
    for (const key in obj2) {
        if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
            // If the property doesn't exist in obj1, add it to differences
            differences[key] = { oldValue: undefined, newValue: obj2[key] };
        }
    }

    return differences;
};

export const getNewValues = <T>(
    differences: ObjectDifferences<T>,
): Partial<T> => {
    const newValues: Partial<T> = {};
    for (const key in differences) {
        if (differences[key]?.newValue !== undefined) {
            newValues[key] = differences[key]?.newValue;
        }
    }
    return newValues;
};

export type Type_AnyObject = { [key: string]: any };

export const hasKey = (obj: Type_AnyObject, key: string): boolean => {
    return key in obj;
};
