import { Chip, ChipProps } from "@mui/material";
import * as React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { separatorCodeTask } from "src/configurations/app";
import { useTaskType } from "src/hooks/task/useTaskType";
import { invertColor } from "src/utils/colors";

type Type_Props_TaskChip = Omit<ChipProps, "color"> & {
    name: string;
    code?: string;
    type: number;
    color: string;
};

export const TaskChip = ({
    name,
    code,
    type,
    color,
    ...props
}: Type_Props_TaskChip) => {
    const { getTaskTypeIcon } = useTaskType({ iconKitVariant: "light" });

    const label = code ? `${code} ${separatorCodeTask} ${name}` : name;

    return (
        <Chip
            {...props}
            icon={<Icon {...getTaskTypeIcon(type)} color="inherit" />}
            size="small"
            label={label}
            sx={{
                backgroundColor: color,
                color: invertColor(color),
                "& .MuiChip-deleteIcon": {
                    color: "inherit",
                },
                "& .MuiChip-deleteIcon:hover": {
                    color: "inherit",
                },
            }}
        />
    );
};
