import { DrawerProps } from "@mui/material";
import React, { useState } from "react";

import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { UpdateProjectForm } from "src/forms/project/UpdateProjectForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_ProjectDrawer = DrawerProps & {
    projectIdToUpdate: number;
    open: boolean;
    onClose: () => void;
    page: string;
    tab: string;
};

export const UpdateProjectDrawer = ({
    projectIdToUpdate,
    open,
    onClose,
    page,
    tab,
}: Type_Props_ProjectDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Projects");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`Project-Details-Drawer-Title`}
            title={fmt("TitleUpdate")}
            isFormLoading={isLoading}
            formId={"Project"}
            page={page}
        >
            <UpdateProjectForm
                projectIdToUpdate={projectIdToUpdate}
                onClose={onClose}
                setIsLoading={setIsLoading}
                tab={tab}
            />
        </DrawerGeneric>
    );
};
