import { Stack, styled, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import {
    TaskLinkUniqueKeys,
    useIndexTaskLinks,
} from "src/api/tms-scheduling/taskLinks";
import { formatterLinksByDirection } from "src/api/tms-scheduling/taskLinks/formatters";
import {
    LINK_DIRECTION,
    Type_index_taskLink,
    Type_Link_By_Direction,
} from "src/api/tms-scheduling/taskLinks/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { ItemChildrenProps } from "src/components/Components_Common/accordions/Accordion";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { Link as LinkDialog } from "src/dialogs/Link";
import { Type_event_removeLink, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { TaskLink } from "./TaskLink";

const Styled_StackHead = styled(Stack)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
}));

type Type_Props_TaskLinks = ItemChildrenProps & {
    taskId: number;
    defaultTaskForAutocomplete: { id: number; name: string };
    areaId?: number;
};

export const TaskLinks = ({
    taskId,
    readonly,
    areaId,
    defaultTaskForAutocomplete,
}: Type_Props_TaskLinks) => {
    const theme = useTheme();

    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Links",
    );

    const [open, setOpen] = useState<boolean>(false);
    const [linkId, setLinkId] = useState<number | undefined>(undefined);
    const [direction, setDirection] = useState<LINK_DIRECTION>(
        LINK_DIRECTION.FROM,
    );
    const [links, setLinks] = useState<Type_Link_By_Direction>({
        FROM: [],
        TO: [],
    });

    /////////////////////
    ///  Queries      ///
    /////////////////////

    const { isFetching, data } = useIndexTaskLinks(
        {
            task_id: taskId,
        },
        !!taskId,
        TaskLinkUniqueKeys.ACCORDION,
        MEDIUM_STALE_TIME,
    );

    useEffect(() => {
        if (!isFetching && data) {
            const transformedData = formatterLinksByDirection(
                data,
                taskId,
                areaId,
            );
            setLinks(transformedData);
        }
    }, [isFetching, data]);

    ///////////////////////
    ///   Channels      ///
    ///////////////////////

    useChannel({
        eventHandler: ({ data, event }) => {
            const linkData = data as Type_index_taskLink;

            if (event === "addLink") {
                if (
                    linkData.taskTo.id === taskId ||
                    linkData.taskFrom.id === taskId
                ) {
                    if (linkData.taskTo.id === taskId) {
                        setLinks((prev) => ({
                            ...prev,
                            FROM: [...prev.FROM, linkData],
                        }));
                    } else {
                        setLinks((prev) => ({
                            ...prev,
                            TO: [...prev.TO, linkData],
                        }));
                    }
                }
            }

            if (event === "updateLink") {
                setLinks((prev) => {
                    const updatedFrom = prev.FROM.map((link) => {
                        if (linkData && link.id === linkData?.id) {
                            return {
                                ...linkData,
                                task: (linkData as Type_index_taskLink)
                                    .taskFrom,
                            };
                        }
                        return link;
                    });

                    const updatedTo = prev.TO.map((link) => {
                        if (link.id === linkData?.id) {
                            return {
                                ...linkData,
                                task: (linkData as Type_index_taskLink).taskTo,
                            };
                        }
                        return link;
                    });

                    return {
                        ...prev,
                        FROM: updatedFrom,
                        TO: updatedTo,
                    };
                });
            }

            if (event === "removeLink") {
                const removeLinkId = (data as Type_event_removeLink).id;
                setLinks((prev) => {
                    const updatedFrom = prev.FROM.filter(
                        (link) => link.id !== removeLinkId,
                    );

                    const updatedTo = prev.TO.filter(
                        (link) => link.id !== removeLinkId,
                    );

                    return {
                        ...prev,
                        FROM: updatedFrom,
                        TO: updatedTo,
                    };
                });
            }
        },
    });

    const getLinkActions = (linkType: LINK_DIRECTION) => {
        const handleClick = () => {
            setDirection(linkType);
            setLinkId(undefined);
            setOpen(true);
        };

        return (
            <IconButton
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: theme.spacing(1),
                }}
                data-action={linkType}
                data-testid={`Add-new-link-${linkType}-btn`}
                onClick={handleClick}
                color="primary"
            >
                <Icon
                    color="inherit"
                    fontSize="small"
                    icon="add"
                    variant="light"
                />
                <Typography variant="body3Medium" color={"inherit"}>
                    {fmtActions("New")}
                </Typography>
            </IconButton>
        );
    };

    return isFetching ? (
        <FullSpinner />
    ) : (
        <>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Styled_StackHead>
                        <Typography
                            variant="body3Medium"
                            color={theme.palette.text.secondary}
                        >
                            {fmt("Predecessor")}
                        </Typography>
                        {!readonly && getLinkActions(LINK_DIRECTION.FROM)}
                    </Styled_StackHead>

                    {links?.FROM.map((link) => (
                        <TaskLink
                            key={link.id}
                            type={LINK_DIRECTION.FROM}
                            link={link}
                            readonly={readonly}
                            areaId={areaId}
                            setOpen={setOpen}
                            setLinkId={setLinkId}
                            setDirection={setDirection}
                        />
                    ))}
                </Stack>
                <Stack gap={1}>
                    <Styled_StackHead>
                        <Typography
                            variant="body3Medium"
                            color={theme.palette.text.secondary}
                        >
                            {fmt("Successor")}
                        </Typography>
                        {!readonly && getLinkActions(LINK_DIRECTION.TO)}
                    </Styled_StackHead>

                    {links?.TO.map((link) => (
                        <TaskLink
                            key={link.id}
                            type={LINK_DIRECTION.TO}
                            link={link}
                            readonly={readonly}
                            areaId={areaId}
                            setOpen={setOpen}
                            setLinkId={setLinkId}
                            setDirection={setDirection}
                        />
                    ))}
                </Stack>
            </Stack>
            <LinkDialog
                open={open}
                handleClose={() => setOpen(false)}
                direction={direction}
                linkId={linkId}
                defaultTaskForAutocomplete={defaultTaskForAutocomplete}
            />
        </>
    );
};
