import { LinkBubbleMenu, RichTextEditor, TableBubbleMenu } from "mui-tiptap";
import React, { ReactElement } from "react";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { EditorBox } from "src/components/Components_Common/richText/Editor.style";
import EditorMenuControls, {
    Type_Props_EditorMenu,
} from "src/components/Components_Common/richText/EditorMenuControls";
import useExtensions from "src/components/Components_Common/richText/useExtensions";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_Editor = Type_Props_EditorMenu & {
    name: string;
    hideRichText?: () => void;
    footer?: ReactElement;
    editable?: boolean;
    content?: { [key: string]: any };
    onChange?: (...event: any[]) => void;
    dataTestIdRef?: string;
    helperText?: string;
};

export const Editor = ({
    hideRichText,
    content,
    footer,
    onChange = () => {},
    name,
    editable = true,
    ...restProps
}: Type_Props_Editor) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Form.Placeholder");
    const extensions = useExtensions({
        placeholder: fmt("RichText"),
    });

    const handleOnChange = (e: any) => {
        onChange({ ...content, text: e.editor.getHTML() });
    };

    return (
        <EditorBox
            data-testid={
                restProps.dataTestIdRef
                    ? restProps.dataTestIdRef
                    : `RichText-Editor-${name}`
            }
        >
            <RichTextEditor
                editable={editable}
                extensions={extensions}
                content={content?.text}
                renderControls={() =>
                    editable ? (
                        <EditorMenuControls
                            {...restProps}
                            hideRichText={hideRichText}
                        />
                    ) : null
                }
                onUpdate={handleOnChange}
                RichTextFieldProps={{
                    variant: editable ? "outlined" : "standard",
                }}
            >
                {() => (
                    <>
                        <LinkBubbleMenu />
                        <TableBubbleMenu />
                    </>
                )}
            </RichTextEditor>
            {restProps.helperText && (
                <TMC_FormHelperText
                    helperText={restProps.helperText}
                    inputName={name}
                />
            )}
            {footer}
        </EditorBox>
    );
};
