import React from "react";
import { Group, Text } from "react-konva";

import { usePlanningTheme } from "src/components/Components_Teamoty/Planning/Planning.const";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_Props_PlanningDate } from "./PlanningDate.type";

export const PlanningQuarter = ({
    height,
    width,
    date,
}: Type_Props_PlanningDate) => {
    const theme = usePlanningTheme();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Planning");
    return (
        <Group x={date.pos}>
            <Text
                text={fmt("Q", {
                    values: {
                        quarter: date.quarter,
                    },
                })}
                fontSize={12}
                width={width}
                height={height}
                padding={3}
                align="left"
                verticalAlign="middle"
                fill={theme.colorPlanningDate}
            />
        </Group>
    );
};
