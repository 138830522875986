import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ResourceForm } from "src/forms/resource/ResourceForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_ResourceDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    resourceIdToUpdate: number | null;
    action: Type_action;
    page: string;
    newResourceName?: string;
};

export const ResourceDrawer = ({
    open,
    onClose,
    resourceIdToUpdate,
    action,
    page,
    newResourceName,
}: Type_Props_ResourceDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Resource");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`Resource-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"resource"}
            page={page}
        >
            <ResourceForm
                onClose={onClose}
                resourceIdToUpdate={resourceIdToUpdate}
                action={action}
                setIsLoading={setIsLoading}
                newResourceName={newResourceName}
            />
        </DrawerGeneric>
    );
};
