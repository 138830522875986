import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_Stack_buttonsGroup = styled(Stack)(() => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignSelf: "center",
    visibility: "hidden",
}));
