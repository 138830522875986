import { Stack, useTheme } from "@mui/material";
import {
    isTouchDevice,
    MenuButtonBlockquote,
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonCode,
    MenuButtonCodeBlock,
    MenuButtonEditLink,
    MenuButtonHighlightColor,
    MenuButtonHorizontalRule,
    MenuButtonIndent,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuButtonRedo,
    MenuButtonRemoveFormatting,
    MenuButtonStrikethrough,
    MenuButtonTaskList,
    MenuButtonTextColor,
    MenuButtonUnderline,
    MenuButtonUndo,
    MenuButtonUnindent,
    MenuControlsContainer,
    MenuSelectFontFamily,
    MenuSelectFontSize,
    MenuSelectHeading,
    MenuSelectTextAlign,
} from "mui-tiptap";
import React from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

export type Type_Props_EditorMenu = {
    hideRichText?: () => void;
    fontFamily?: boolean;
    selectHeading?: boolean;
    fontSize?: boolean;
    buttonBold?: boolean;
    buttonItalic?: boolean;
    buttonUnderline?: boolean;
    buttonStrikethrough?: boolean;
    buttonTextColor?: boolean;
    buttonHighlightColor?: boolean;
    buttonEditLink?: boolean;
    textAlign?: boolean;
    buttonOrderedList?: boolean;
    buttonBulletedList?: boolean;
    buttonTaskList?: boolean;
    buttonBlockquote?: boolean;
    buttonCode?: boolean;
    buttonCodeBlock?: boolean;
    buttonHorizontalRule?: boolean;
    buttonRemoveFormatting?: boolean;
    buttonHistory?: boolean;
};

export default function EditorMenuControls(props: Type_Props_EditorMenu) {
    const theme = useTheme();
    return (
        <MenuControlsContainer>
            <Stack
                width={"100%"}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="row">
                    {props.fontFamily && (
                        <MenuSelectFontFamily
                            options={[
                                {
                                    label: "Comic Sans",
                                    value: "Comic Sans MS, Comic Sans",
                                },
                                { label: "Cursive", value: "cursive" },
                                { label: "Monospace", value: "monospace" },
                                { label: "Serif", value: "serif" },
                            ]}
                        />
                    )}

                    {props.selectHeading && <MenuSelectHeading />}

                    {props.fontSize && <MenuSelectFontSize />}

                    {props.buttonBold && <MenuButtonBold />}

                    {props.buttonItalic && <MenuButtonItalic />}

                    {props.buttonUnderline && <MenuButtonUnderline />}
                    {props.buttonStrikethrough && <MenuButtonStrikethrough />}

                    {props.buttonTextColor && (
                        <MenuButtonTextColor
                            defaultTextColor={theme.palette.text.primary}
                            swatchColors={[
                                { value: "#000000", label: "Black" },
                                { value: "#ffffff", label: "White" },
                                { value: "#888888", label: "Grey" },
                                { value: "#ff0000", label: "Red" },
                                { value: "#ff9900", label: "Orange" },
                                { value: "#ffff00", label: "Yellow" },
                                { value: "#00d000", label: "Green" },
                                { value: "#0000ff", label: "Blue" },
                            ]}
                        />
                    )}

                    {props.buttonHighlightColor && (
                        <MenuButtonHighlightColor
                            swatchColors={[
                                { value: "#595959", label: "Dark grey" },
                                { value: "#dddddd", label: "Light grey" },
                                { value: "#ffa6a6", label: "Light red" },
                                { value: "#ffd699", label: "Light orange" },
                                // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
                                { value: "#ffff00", label: "Yellow" },
                                { value: "#99cc99", label: "Light green" },
                                { value: "#90c6ff", label: "Light blue" },
                                { value: "#8085e9", label: "Light purple" },
                            ]}
                        />
                    )}

                    {props.textAlign && <MenuSelectTextAlign />}

                    {props.buttonOrderedList && <MenuButtonOrderedList />}

                    {props.buttonBulletedList && <MenuButtonBulletedList />}

                    {props.buttonTaskList && <MenuButtonTaskList />}

                    {props.buttonEditLink && <MenuButtonEditLink />}
                    {/* On touch devices, we'll show indent/unindent buttons, since they're
unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
buttons probably aren't necessary for keyboard users and would add extra
clutter. */}
                    {isTouchDevice() && (
                        <>
                            <MenuButtonIndent />

                            <MenuButtonUnindent />
                        </>
                    )}

                    {props.buttonBlockquote && <MenuButtonBlockquote />}

                    {props.buttonCode && <MenuButtonCode />}

                    {props.buttonCodeBlock && <MenuButtonCodeBlock />}

                    {props.buttonHorizontalRule && <MenuButtonHorizontalRule />}
                    {props.buttonRemoveFormatting && (
                        <MenuButtonRemoveFormatting />
                    )}

                    {props.buttonHistory && (
                        <>
                            <MenuButtonUndo />
                            <MenuButtonRedo />
                        </>
                    )}
                </Stack>
                {props.hideRichText && (
                    <Stack direction="row" justifyContent="end">
                        <IconButton
                            size="small"
                            sx={{ cursor: "pointer" }}
                            color="secondary"
                            onClick={props.hideRichText}
                        >
                            <Icon
                                variant="solid"
                                icon="trash"
                                fontSize={"small"}
                            />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
        </MenuControlsContainer>
    );
}
