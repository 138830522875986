import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { DrawingPath, Url } from "src/api/paths";
import {
    Type_prj_post_drawing,
    Type_prj_put_drawing,
} from "src/api/tms-projects/drawing/types";

export const indexDrawings = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${DrawingPath.DRAWINGS}`, requestConfig);
};

export const createDrawing = (
    data: Type_prj_post_drawing,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.PROJECTS}${DrawingPath.DRAWINGS}`, requestConfig);
};

export const showDrawing = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${DrawingPath.DRAWINGS}/${id}`, requestConfig);
};

export const updateDrawing = (
    data: { id: number; data: Type_prj_put_drawing },
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data.data,
        `${Url.PROJECTS}${DrawingPath.DRAWINGS}/${data.id}`,
        requestConfig,
    );
};

export const deleteDrawing = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.PROJECTS}${DrawingPath.DRAWINGS}/${id}`,
        requestConfig,
    );
};

export const statusDrawing = (
    data: any,
    id: number,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${DrawingPath.DRAWINGS}/${id}`,
        requestConfig,
    );
};

export const imageDrawing = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${DrawingPath.DRAWINGS}/image`, requestConfig);
};

export const selectListDrawings = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${DrawingPath.DRAWINGS}/select-list`,
        requestConfig,
    );
};
