import Konva from "konva";
import React, { RefObject, useEffect, useRef } from "react";
import { Group, Rect, Text } from "react-konva";

import { usePlanningTheme } from "src/components/Components_Teamoty/Planning/Planning.const";

import { Type_Props_PlanningDate } from "./PlanningDate.type";

export const PlanningMonth = ({
    height,
    width,
    date,
    handleContextMenuDates = () => {},
}: Type_Props_PlanningDate) => {
    const theme = usePlanningTheme();

    const refYear: RefObject<Konva.Text> = useRef<Konva.Text>(null);
    const refMonth: RefObject<Konva.Text> = useRef<Konva.Text>(null);

    useEffect(() => {
        if (refYear.current && refMonth.current) {
            const { width: monthWidth } = refMonth.current.getClientRect();
            refYear.current.x(monthWidth + 3);
        }
    }, []);

    return (
        <Group x={date.pos} onContextMenu={(e) => handleContextMenuDates(e)}>
            <Rect width={width * date.count} height={height} fill="white" />
            <Text
                ref={refMonth}
                text={date.monthNarrowText}
                fontSize={12}
                height={height}
                offsetX={-3}
                offsetY={4}
                verticalAlign="bottom"
                fill={theme.colorPlanningDate}
            />
            <Text
                ref={refYear}
                text={date.year.toString()}
                fontSize={14}
                height={height}
                offsetX={-3}
                offsetY={3}
                verticalAlign="bottom"
                fill={theme.colorPlanningDateYear}
            />
        </Group>
    );
};
