import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { ProjectPath, Url } from "src/api/paths";
import {
    Type_com_post_project,
    Type_com_put_project,
} from "src/api/tms-commons/projects/types";

export const indexProjects = (): GetReturnType => {
    return get(`${Url.COMMONS}${ProjectPath.PROJECTS}`);
};

export const showProject = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${ProjectPath.PROJECTS}/${id}`);
};

export const getProjectBySlug = (slug: string): GetReturnType => {
    return get(`${Url.COMMONS}${ProjectPath.SHOW}/${slug}`);
};

export const createProject = (data: Type_com_post_project): PostReturnType => {
    return post(data, `${Url.COMMONS}${ProjectPath.PROJECTS}`);
};

export const updateProject = (
    data: Type_com_put_project,
    id: number,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${ProjectPath.PROJECTS}/${id}`);
};

export const changeProjectStatus = (
    data: Type_com_put_project,
    id: number,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${ProjectPath.PROJECTS}/${id}`);
};

export const removeProject = (id: number): RemoveReturnType => {
    return remove(`${Url.COMMONS}${ProjectPath.PROJECTS}/${id}`);
};

export const checkProjectNameIsAvailable = (
    data: FormikValues,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${ProjectPath.IS_NAME_AVAILABLE}`);
};
