import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    InputLabel,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import {
    TMC_Button,
    TMC_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants";
import {
    actionsModalType,
    headerModalType,
    ModalContainerType,
} from "src/components/Components_Common/ConfirmDeleteModal/ConfirmDeleteModalContainer";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const ConfirmDeleteModalComponent = ({
    header,
    children,
    actions,
    form,
    ...restProps
}: ModalContainerType & DialogProps) => {
    const [validationFieldValue, setValidationFieldValue] =
        useState<string>("");
    useEffect(() => {
        if (!restProps.open) setValidationFieldValue("");
    }, [restProps.open]);
    return (
        <Dialog {...restProps}>
            {<HeaderModal {...header} onClose={header?.onClose} />}
            <DialogContent>
                {children}
                {form && (
                    <Form
                        validationString={form?.validationString}
                        validationFieldValue={validationFieldValue}
                        setValidationFieldValue={setValidationFieldValue}
                    />
                )}
            </DialogContent>
            {actions && (
                <ActionsModal
                    validationString={form?.validationString}
                    validationFieldValue={validationFieldValue}
                    {...actions}
                />
            )}
        </Dialog>
    );
};

export const HeaderModal = ({ title, onClose }: headerModalType) => {
    return (
        <DialogTitle data-testid={`ModalDelete-Title`}>
            {title}
            {onClose ? (
                <IconButton size="small" onClick={onClose}>
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

type Type_FormModal = {
    validationFieldValue: string;
    setValidationFieldValue: React.Dispatch<React.SetStateAction<string>>;
    validationString?: string;
};

const Form = ({
    validationString,
    validationFieldValue,
    setValidationFieldValue,
}: Type_FormModal) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Modal.ConfirmDelete",
    );
    return (
        <Box sx={{ paddingTop: 4 }}>
            <InputLabel>
                <Typography variant="body1">
                    {fmt("Content", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            slug: validationString,
                        },
                    })}
                </Typography>
            </InputLabel>
            <TMC_TextField
                data-testid={`ModalDelete-TextField`}
                placeholder={validationString}
                value={validationFieldValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setValidationFieldValue(event.target.value);
                }}
            />
        </Box>
    );
};

const ActionsModal = ({
    onSubmit,
    validationString,
    validationFieldValue,
}: actionsModalType & Omit<Type_FormModal, "setValidationFieldValue">) => {
    return (
        <DialogActions>
            {onSubmit && (
                <TMC_Button
                    data-testid={`ModalDelete-SubmitBtn}`}
                    variant="contained"
                    onClick={
                        validationString?.length
                            ? validationString === validationFieldValue
                                ? onSubmit.onClick
                                : () => console.log("error")
                            : onSubmit.onClick
                    }
                    disabled={
                        validationString?.length
                            ? validationString != validationFieldValue
                            : onSubmit.disabled || onSubmit.isLoading
                    }
                >
                    {onSubmit.content}
                </TMC_Button>
            )}
        </DialogActions>
    );
};
