import {
    Type_index_resourceTasks,
    Type_sch_index_resourceTasks,
} from "./types";

export const formatterIndexResourceTask = (
    data: Type_sch_index_resourceTasks[],
): Type_index_resourceTasks[] => {
    const resourceTypesMap: { [key: number]: Type_index_resourceTasks } = {};

    data.forEach((item) => {
        const {
            resource: {
                resourceType: {
                    id: resourceTypeId,
                    name: resourceTypeName,
                    resourceModel: { unit },
                },
            },
        } = item;

        const resource = item.resource;

        if (!resourceTypesMap[resourceTypeId]) {
            resourceTypesMap[resourceTypeId] = {
                id: resourceTypeId,
                unit: unit,
                name: resourceTypeName,
                resources: [],
            };
        }

        resourceTypesMap[resourceTypeId].resources.push({
            id: resource.id,
            resourceTaskId: item.id,
            name: resource.name,
            quantity: item.quantity,
        });
    });

    return sortResourceByName(Object.values(resourceTypesMap));
};

export const formatterPostToIndex = (
    resourcesTasks: Type_index_resourceTasks[],
    resourceToInject: Type_sch_index_resourceTasks,
): Type_index_resourceTasks[] => {
    const resourceType = resourceToInject.resource.resourceType;
    const resource = {
        id: resourceToInject.resource.id,
        resourceTaskId: resourceToInject.id,
        quantity: resourceToInject.quantity,
        name: resourceToInject.resource.name,
    };

    const matchingResourceIndex = resourcesTasks.findIndex(
        (resource) => resource.id === resourceType.id,
    );

    if (matchingResourceIndex !== -1) {
        resourcesTasks[matchingResourceIndex].resources.push(resource);
    } else {
        const newResourceType = {
            id: resourceType.id,
            unit: resourceType.resourceModel.unit,
            name: resourceType.name,
            resources: [resource],
        };
        resourcesTasks.push(newResourceType);
    }

    return sortResourceByName(resourcesTasks);
};

export const sortResourceByName = (
    resourceTypesMap: Type_index_resourceTasks[],
) => {
    // Convert the map to an array and sort by resourceTypeName
    const sortedResourceTypes = Object.values(resourceTypesMap).sort((a, b) =>
        a.name.localeCompare(b.name),
    );

    // Sort the resources array within each resource type by resource name
    sortedResourceTypes.forEach((resourceType) => {
        resourceType.resources.sort((a, b) => a.name.localeCompare(b.name));
    });

    return sortedResourceTypes;
};
