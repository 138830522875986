import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as React from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateLanguage,
    mutationDeleteLanguage,
    mutationUpdateLanguage,
    useShowLanguages,
} from "src/api/tms-projects/languages";
import { formatterLocaleProjectLanguages } from "src/api/tms-projects/languages/formatters";
import { indexLanguages } from "src/api/tms-projects/languages/services";
import {
    Type_edit_languages,
    Type_edit_languagesOptions,
    Type_prj_index_language,
    Type_prj_post_language,
} from "src/api/tms-projects/languages/types";
import { Type_selectList } from "src/api/types";
import { languagesList } from "src/assets/translations";
import { LoadingBox } from "src/components";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { RadioGroup } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useLanguage } from "src/contexts/languages";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_Languages = Yup.lazy(() => {
    return Yup.object().shape({
        languages: Yup.array().min(1).required(FORM_ERR_FMT.REQUIRED),
        defaultLanguage: Yup.string()
            .trim()
            .required(FORM_ERR_FMT.REQUIRED)
            .test("is-valid-language", FORM_ERR_FMT.REQUIRED, function (value) {
                return this.parent.languages.find(
                    (elem: Type_edit_languagesOptions) => {
                        // depending on whether the default language has just been created or not, we don't compare the same property
                        return elem.id == value || elem.value == value;
                    },
                );
            }),
    });
});

type Type_Props_ProjectLanguagesForm = {
    onClose: () => void;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    isLoading: boolean;
};

export const ProjectLanguagesForm = ({
    onClose,
    setIsLoading,
    isLoading,
}: Type_Props_ProjectLanguagesForm) => {
    const [fetchedProjectLanguages, setFetchedProjectLanguages] = useState<
        Type_edit_languages[]
    >([]);
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectLanguages",
    );
    const { formatMessageWithPartialKey: fmtLanguages } = useCoreIntl(
        "Teamoty.Configuration.Labels",
    );

    const { addSuccess } = useToast();
    const { requestConfig } = useProject();
    const { setLanguages } = useLanguage();

    const formatterEditLanguages = (
        projectLanguages: Type_prj_index_language[],
    ): Type_edit_languages[] => {
        return projectLanguages.map((lang) => ({
            label: fmtLanguages(languagesList[lang.locale] as string),
            value: lang.locale,
            default: lang.default,
        }));
    };

    const { switchLanguage, language } = useLanguage();

    const { mutateAsync: mutateCreate } = mutationCreateLanguage() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateLanguage() || {};
    const { mutateAsync: mutateDelete } = mutationDeleteLanguage() || {};

    const { isFetching, data } = useShowLanguages() || {};

    type Type_languages = {
        id: string;
        name: string;
    };

    const deleteLanguagesIfFind = async (values: { [key: string]: any }) => {
        const selectedLangs: Type_languages[] = values.languages!;

        if (selectedLangs && data) {
            const localesNotInLanguages = data.filter((loc) => {
                return !selectedLangs.some((lang) => lang.id === loc.locale);
            });
            // Loop on the existing languages to delete when not found in new values
            for (const language of localesNotInLanguages) {
                await mutateDelete(language.locale);

                // TODO should be refactored
                // setContextState((prev) => ({
                //     ...prev,
                //     languages: [
                //         ...prev.languages.filter(
                //             (item) => item.id !== language.id,
                //         ),
                //     ],
                // }));
            }
        }
    };

    const addNewLanguagesIfFind = async (values: { [key: string]: any }) => {
        const selectedLangs: Type_languages[] = [...values.languages!];

        if (data && values?.languages) {
            const newLangs = selectedLangs.filter((lang) => {
                return !data.some((loc) => loc.locale === lang.id);
            });
            for (const newLang of newLangs) {
                const createLanguage: Type_prj_post_language = {
                    locale: newLang.id as string,
                    default: values.defaultLanguage === newLang.id,
                };

                const { data } = await mutateCreate(createLanguage);

                if (data?.data) {
                    // TODO should be refactored
                    // setContextState((prev) => ({
                    //     ...prev,
                    //     languages: [
                    //         ...prev.languages,
                    //         {
                    //             ...formatterIndexLanguage(data.data),
                    //         },
                    //     ],
                    // }));
                }
            }
        }
    };

    const updateDefaultLanguage = async (values: { [key: string]: any }) => {
        const defaultLanguage = values.defaultLanguage;

        if (!data) return;

        const oldSelectLanguageIsDefault = data.find(
            (selectLang) =>
                !selectLang.default && selectLang.locale === defaultLanguage,
        );

        if (oldSelectLanguageIsDefault) {
            const { data: dataUpdate } = await mutateUpdate({
                default: true,
                locale: oldSelectLanguageIsDefault.locale,
            });
            if (dataUpdate?.data) {
                // TODO should be refactored
                // setContextState((prev) => ({
                //     ...prev,
                //     languages: [
                //         ...prev.languages,
                //         {
                //             ...formatterIndexLanguage(dataUpdate.data),
                //         },
                //     ],
                // }));
            }
        }

        const oldDefaultLanguage = data.find(
            (selectLang) => selectLang.default,
        );

        if (
            oldDefaultLanguage &&
            oldDefaultLanguage?.locale !== defaultLanguage
        ) {
            const { data: dataUpdate } = await mutateUpdate({
                default: false,
                locale: oldDefaultLanguage.locale,
            });
            if (dataUpdate?.data) {
                // TODO should be refactored
                // setContextState((prev) => ({
                //     ...prev,
                //     languages: [
                //         ...prev.languages,
                //         {
                //             ...formatterIndexLanguage(dataUpdate.data),
                //         },
                //     ],
                // }));
            }
        }
    };

    const handleSubmit = async (
        values: { [key: string]: any },
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        setIsLoading(true);

        try {
            await addNewLanguagesIfFind(values);
            await updateDefaultLanguage(values);
            await deleteLanguagesIfFind(values);

            // Si la langue en cours ne fait pas partie de celles sélectionnées, on redirige
            if (
                !values.languages.find(
                    (lang: { [key: string]: any }) => lang.id === language,
                )
            ) {
                switchLanguage(values.defaultLanguage);
            }

            addSuccess({
                description: fmt("ToastSuccess"),
            });

            const projectLanguages = await indexLanguages(requestConfig);
            setLanguages(
                formatterLocaleProjectLanguages(
                    projectLanguages?.data?.data as Type_prj_index_language[],
                ),
            );

            // Fermeture du drawer
            onClose();
        } catch (_e: any) {
            console.error("Error when creating or updating project languages");
        }

        setIsLoading(false);
    };

    const form = useForm({
        defaultValues: {
            projectLanguages: [],
            defaultLanguage: null,
        },
        values: {
            defaultLanguage: data?.find((l) => l?.default)?.locale ?? null,
            languages: data?.map(
                (lang): Type_languages => ({
                    id: lang.locale,
                    name: fmtLanguages(languagesList[lang.locale] as string),
                }),
            ),
        },
        resolver: yupResolver<any>(Schema_Languages),
    });

    const itemsLanguage: Type_selectList[] = Object.entries(languagesList).map(
        ([key, name]) => ({
            id: String(key),
            name: fmtLanguages(name as string),
        }),
    );

    useEffect(() => {
        if (data) {
            setFetchedProjectLanguages(formatterEditLanguages(data));
        }
    }, [data]);

    // Abonnement pour modifier les langues disponibles en tant que langues par défaut
    useEffect(() => {
        const formLanguages: Type_languages[] = form.getValues("languages");
        const formDefaultLanguage = form.getValues("defaultLanguage");

        if (!formLanguages) return;

        // On enleve de la langue par défaut si elle est supprimée de la liste
        if (!formLanguages?.find((lang) => lang.id === formDefaultLanguage))
            form.setValue("defaultLanguage", null);
        setFetchedProjectLanguages((_old) =>
            formLanguages.map((lang) => ({
                label: lang.name,
                value: lang.id,
                default: formDefaultLanguage === lang.id,
            })),
        );
    }, [useWatch({ control: form.control, name: "languages" })]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"projectLanguages"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    data &&
                    !isLoading && (
                        <Stack gap={6}>
                            <Stack>
                                <Typography variant="h3">
                                    {fmt(`SelectLanguage`)}
                                </Typography>
                                <Autocomplete
                                    label={fmt("Label")}
                                    data-testid="EditProject-DrawerContent-TabLanguages-languages"
                                    id="languages"
                                    name="languages"
                                    multiple
                                    options={itemsLanguage}
                                    renderInputProps={{
                                        variant: "outlined",
                                    }}
                                />
                            </Stack>
                            <RadioGroup
                                label={fmt("DefaultLanguage")}
                                name={"defaultLanguage"}
                                options={fetchedProjectLanguages.map(
                                    (lang) => ({
                                        value: lang.value,
                                        label: lang.label,
                                    }),
                                )}
                            />
                        </Stack>
                    )
                )}
            </form>
        </FormProvider>
    );
};
