import { useMutation, useQuery } from "react-query";

import { ApiResponse } from "src/api/fetch";
import { ProductTypeKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexProductType,
    formatterIndexProductTypes,
    formatterSelectListProductTypes,
    formatterShowProductType,
} from "src/api/tms-projects/productTypes/formatters";
import {
    createProductType,
    indexProductTypes,
    selectListProductTypes,
    showProductType,
    updateProductType,
} from "src/api/tms-projects/productTypes/services";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import {
    Type_index_productType,
    Type_prj_index_productType,
    Type_prj_post_productType,
    Type_prj_put_productType,
    Type_prj_select_productType,
    Type_prj_show_productType,
    Type_select_productType,
    Type_show_productType,
} from "./types";

export const useIndexProductTypes = () => {
    const { requestConfig } = useProject();
    return useQuery<
        ApiResponse<Type_prj_index_productType[]>,
        unknown,
        Type_index_productType[]
    >({
        queryKey: [ProductTypeKeys.INDEX, requestConfig],
        queryFn: () => indexProductTypes(requestConfig),
        refetchOnWindowFocus: false,
        select: (res): Type_index_productType[] => {
            return formatterIndexProductTypes(res.data.data);
        },
        enabled: !!requestConfig.projectId,
    });
};

export const mutationCreateProductType = (callback?: any) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_prj_post_productType) => {
            return createProductType(data, requestConfig);
        },
        onSuccess: (res): void => {
            callback(formatterIndexProductType(res.data.data));
        },
        onError: (err: any): void => {
            addWarning({ description: fmt("GenericError", {}) });
            return err;
        },
    });
};

export const mutationUpdateProductType = (callback?: any) => {
    const { requestConfig } = useProject();
    const { addWarning, addSuccess } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.ProductTypes",
    );
    const language = getLocalStorageItem("language");

    return useMutation({
        mutationFn: (productTypeToUpdate: Type_prj_put_productType) => {
            return updateProductType(productTypeToUpdate, requestConfig);
        },
        onSuccess: (res) => {
            callback(formatterIndexProductType(res.data.data));
            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        productType: res.data.data.names[language],
                    },
                }),
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateProductType", err);
            addWarning({ description: fmtErrors("GenericError", {}) });

            return err;
        },
    });
};

export const useShowProductType = (productTypeIdToUpdate: number) => {
    const { requestConfig, isLoading: isLoadingRequestConfig } = useProject();
    return useQuery<
        ApiResponse<Type_prj_show_productType>,
        unknown,
        Type_show_productType
    >(
        [ProductTypeKeys.SHOW, productTypeIdToUpdate, requestConfig],
        () => showProductType(productTypeIdToUpdate, requestConfig),
        {
            select: (res): Type_show_productType => {
                return formatterShowProductType(res.data.data);
            },
            onError: (err) => {
                console.error(err);
            },
            enabled: !!productTypeIdToUpdate && !isLoadingRequestConfig,
            refetchOnWindowFocus: false,
        },
    );
};

export const useSelectListProductTypes = (
    enabled?: boolean,
    uniqueId?: string,
) => {
    const { requestConfig: req } = useProject();
    return useQuery<
        ApiResponse<Type_prj_select_productType[]>,
        unknown,
        Type_select_productType[]
    >({
        queryKey: [ProductTypeKeys.SELECT_LIST, req, uniqueId],
        queryFn: () => selectListProductTypes(req),
        refetchOnWindowFocus: false,
        select: (res): Type_select_productType[] => {
            return formatterSelectListProductTypes(res.data.data);
        },
        enabled: !!req.projectId && (enabled ?? true),
    });
};
