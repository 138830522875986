import { Alert, Stack, StackProps, styled } from "@mui/material";

export const Register_Container = styled(Stack)(() => ({
    display: "flex",
    flexDirection: "column",
    width: "400px",
    height: "84%",
}));

interface Type_Register_Footer extends StackProps {
    ownerState?: {
        isConfirmEmail?: boolean;
    };
}

export const Register_Footer = styled(Stack)<Type_Register_Footer>(
    ({ theme, ownerState }) => ({
        padding: theme.spacing(4, 0),
        flexWrap: "nowrap",
        flexDirection: "row",
        flexShrink: 0,
        width: "100%",
        ...(ownerState?.isConfirmEmail
            ? {
                  justifyContent: "start",
              }
            : {
                  justifyContent: "end",
              }),
    }),
);

export const Register_Alert = styled(Alert)(({ theme }) => ({
    color: theme.palette.info.light,
    flexDirection: "column",
    "&.MuiAlert-message": { p: 0 },
    "& .MuiAlert-icon": { color: theme.palette.info.light },
}));
