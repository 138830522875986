import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_Filter } from "src/api/filters";
import { MetadataProjectKeys } from "src/api/tms-commons/keys";
import {
    formatterCreateMetadataProject,
    formatterIndexMetadataProject,
    formatterIndexMetadataProjectsForForm,
    formatterIndexMetadatumProject,
    formatterShowMetadataProject,
    formatterStatusMetadataProject,
    formatterUpdateMetadataProject,
} from "src/api/tms-commons/metadata/formatters";
import {
    createMetadataProjects,
    deleteMetadataProject,
    indexMetadataProjects,
    showMetadataProject,
    statusMetadataProject,
    updateMetadataProject,
} from "src/api/tms-commons/metadata/services";
import {
    Type_com_index_metadataProject,
    Type_post_metadataProject,
    Type_put_metadataProject,
    Type_show_metadataProject,
} from "src/api/tms-commons/metadata/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexMetadataProjects = () => {
    return useQuery({
        queryKey: [MetadataProjectKeys.INDEX],
        queryFn: () => indexMetadataProjects(),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexMetadataProjects");
            }

            return formatterIndexMetadataProject(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useShowMetadataProject = (id: number) => {
    return useQuery(
        [MetadataProjectKeys.SHOW, id],
        () => showMetadataProject(id),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error(
                        "Wrong format data: useShowMetadataProject",
                    );
                }

                return formatterShowMetadataProject(data?.data?.data);
            },
            onError: (err) => {
                return err;
            },
        },
    );
};

export const useIndexMetadataProjectsForForm = (filters: Type_Filter = {}) => {
    return useQuery({
        queryKey: [MetadataProjectKeys.INDEX, filters],
        queryFn: () => indexMetadataProjects(filters),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useIndexMetadataProjectsForForm",
                );
            }
            return formatterIndexMetadataProjectsForForm(
                data?.data?.data as Type_com_index_metadataProject[],
            );
        },
        onError: (err) => {
            return err;
        },
    });
};

export const mutationCreateMetadataProject = (
    onError?: (errors: any) => void,
) => {
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.MetadataProject",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (newMetadataProjects: Type_post_metadataProject) =>
            createMetadataProjects(
                formatterCreateMetadataProject(newMetadataProjects),
            ),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateMetadataProject",
                );
            }
            const metadataProject = formatterIndexMetadatumProject(
                data.data.data,
            );

            await queryClient.invalidateQueries([MetadataProjectKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        metadata: metadataProject.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            if (err?.data?.errors && onError) {
                onError(err.data.errors);
            } else {
                addWarning({
                    description: fmtErr("GenericError"),
                });
            }
            return err;
        },
    });
};

export const mutationUpdateMetadataProject = () => {
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.MetadataProject",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (metadataProjectToUpdate: Type_show_metadataProject) =>
            updateMetadataProject(
                metadataProjectToUpdate.id,
                formatterUpdateMetadataProject(metadataProjectToUpdate),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateMetadataProject",
                );
            }

            const metadataProject = formatterIndexMetadatumProject(
                data.data.data,
            );

            await queryClient.invalidateQueries({
                queryKey: [MetadataProjectKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        metadata: metadataProject.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusMetadataProject = () => {
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (newStatus: Type_put_metadataProject) => {
            return statusMetadataProject(
                formatterStatusMetadataProject(newStatus),
                newStatus.id as number,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusMetadataProject",
                );
            }

            await queryClient.invalidateQueries({
                queryKey: [MetadataProjectKeys.INDEX],
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteMetadataProject = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.MetadataProject",
    );
    const { addSuccess, addWarning } = useToast();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteMetadataProject(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationDeleteMetadataProject",
                );
            }
            await queryClient.invalidateQueries({
                queryKey: [MetadataProjectKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
