// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "formik";

import { COLORS } from "src/theme/stylesheet";

export const Styled_MainStack = styled(Stack)(() => ({
    height: "100%",
    overflow: "hidden", // TODO maybe should be remove
    flexWrap: "nowrap",
    flexDirection: "column",
}));

export const Styled_FormDrawer = styled(Form)(() => ({
    flexGrow: 1,
    display: "flex",
    overflow: "hidden",
    flexWrap: "nowrap",
    flexDirection: "column",
    height: "100%",
}));

export const Styled_StackContentDrawer = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(6, 10, 6, 10),
    overflow: "auto",
    flexDirection: "column",
    flexGrow: 1,
}));

export const Styled_StackActionsDrawer = styled(Stack)(({ theme }: any) => ({
    padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flexDirection: "row",
}));

export const Styled_StackActionsDrawerReverse = styled(
    Styled_StackActionsDrawer,
)(() => ({
    flexDirection: "row-reverse",
}));

export const Styled_StackLikeTable = styled(Stack)(({ theme }: any) => ({
    marginTop: theme.spacing(4),
    border: `1px solid ${COLORS.moon300}`,
    borderRadius: 4,
    padding: theme.spacing(2),
}));

export const Styled_StackLikeTableCell = styled(Stack)(({ theme }: any) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    borderTop: `1px solid ${COLORS.moon300}`,
}));

export const Styled_StackActionDrawer = styled(Stack)(({ theme }: any) => ({
    padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
    flexWrap: "nowrap",
    justifyContent: "end",
    flexDirection: "row",
}));
