import { ResourceTaskForm } from "src/forms/resources/ResourceTaskForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_ResourceTaskContextualDrawer = {
    onClose: () => void;
    id: number;
    focus: boolean;
};

export const ResourceTaskContextualDrawer = ({
    onClose,
    id,
    focus = false,
}: Type_Props_ResourceTaskContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout id="resource">
            <ResourceTaskForm onClose={onClose} id={id} focus={focus} />
        </Styled_PaperContextualLayout>
    );
};
