import { FormikValues } from "formik";
import { useMutation, useQuery } from "react-query";

import { VersionNoteKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexVersionNotes,
    formatterVersionNote,
} from "src/api/tms-projects/notes/formatters";
import {
    createVersionNote,
    deleteVersionNote,
    indexVersionNotes,
    selectListVersionNotes,
    showVersionNote,
    updateVersionNote,
} from "src/api/tms-projects/notes/services";
import { Type_prj_index_version_note } from "src/api/tms-projects/notes/types";
import { useProject } from "src/contexts/project";

export const useIndexVersionNotes = (callback: any) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [VersionNoteKeys.INDEX, requestConfig],
        queryFn: () => indexVersionNotes(requestConfig),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexVersionNotes");
            }
            const notes: Type_prj_index_version_note[] = data?.data?.data;
            callback(formatterIndexVersionNotes(notes));
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowVersionNote = (callback: any, id: number) => {
    const { requestConfig } = useProject();
    return useQuery(
        [VersionNoteKeys.SHOW, id],
        () => showVersionNote(id, requestConfig),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                const note: Type_prj_index_version_note = res.data.data;
                callback(formatterVersionNote(note));
            },
            onError: (err) => {
                console.error(err);
                return err;
            },
        },
    );
};

export const mutationCreateVersionNote = (callback: any) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (note: FormikValues) =>
            createVersionNote(
                note as Type_prj_index_version_note,
                requestConfig,
            ),
        onSuccess: (data: any, _newNote: FormikValues) => {
            if (data.success) {
                const note: Type_prj_index_version_note = data.data.data;
                callback(formatterVersionNote(note));
            }
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const mutationUpdateVersionNote = (callback: any) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (noteToUpdate: any) =>
            updateVersionNote(noteToUpdate, requestConfig),
        onSuccess: async (_response) => {
            const noteUpdated: Type_prj_index_version_note =
                _response.data.data;
            callback(formatterVersionNote(noteUpdated));
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useDeleteVersionNote = (callback: (id: number) => void) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) => deleteVersionNote(id, requestConfig),
        onSuccess: (_data, id) => {
            callback(id);
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useSelectListVersionNotes = (callback: any) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [VersionNoteKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListVersionNotes(requestConfig),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data.success && data?.data?.data) {
                callback(data?.data?.data);
            }
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};
