import { ChipProps, Chip as MuiChip } from "@mui/material";
import * as React from "react";

import { invertColor } from "src/utils/colors";

type Type_Props_TMC_Chip = ChipProps & {
    backgroundColor?: string;
    colorText?: string;
    withShadow?: boolean;
    withBorder?: boolean;
    isTag?: boolean;
    height?: number;
};

export const TMC_Chip = ({
    backgroundColor,
    colorText,
    withShadow = false,
    withBorder = false,
    isTag,
    height,
    sx,
    ...props
}: Type_Props_TMC_Chip) => {
    return (
        <MuiChip
            {...props}
            sx={(theme: any) => ({
                ...sx,
                ...(props.color
                    ? {}
                    : {
                          backgroundColor: backgroundColor
                              ? backgroundColor
                              : theme.palette.badge.default,
                          color: colorText
                              ? colorText
                              : invertColor(backgroundColor || "#FFF"),
                      }),
                boxShadow: withShadow ? theme.boxShadow.chip : "none",
                border: withBorder ? theme.border.alpha : 0,
                margin: isTag ? theme.spacing(1) : 0,
                height,
                ...theme.typography.body3,
            })}
        />
    );
};
