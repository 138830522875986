import { ModalProps } from "@mui/material";
import React from "react";

import { HeaderComponent } from "./Header.component";

export type Type_drawerTab = {
    label: string;
    value: string;
};

export type Type_Props_Drawer_Header = {
    title: string;
    onClose: ModalProps["onClose"];
    description?: string;
    currentStep?: number;
    maxStep?: number;
    setTabValue?: any;
    drawerTabs?: Type_drawerTab[];
    handleChange?: (event: React.SyntheticEvent, newValue: string) => void;
    dataTestIdTitle?: string;
};

export const HeaderContainer = ({
    title,
    onClose,
    description,
    currentStep,
    maxStep,
    setTabValue,
    drawerTabs,
    dataTestIdTitle,
}: Type_Props_Drawer_Header) => {
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <HeaderComponent
            title={title}
            onClose={onClose}
            description={description}
            currentStep={currentStep}
            maxStep={maxStep}
            drawerTabs={drawerTabs}
            handleChange={handleChange}
            dataTestIdTitle={dataTestIdTitle ? dataTestIdTitle : "Drawer-Title"}
        />
    );
};
