import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import * as Yup from "yup";

import { useSelectListResourceFamilies } from "src/api/tms-commons/resourceFamilies";
import { Type_index_resourceFamily } from "src/api/tms-commons/resourceFamilies/types";
import {
    mutationCreateResourceModel,
    mutationUpdateResourceModel,
    useShowResourceModel,
} from "src/api/tms-commons/resourceModels";
import {
    Type_post_resourceModel,
    Type_show_resourceModel,
} from "src/api/tms-commons/resourceModels/types";
import { useSelectListUnits } from "src/api/tms-commons/units";
import { Type_selectList } from "src/api/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import {
    formatSelectListToSelectOptionItem,
    Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { ResourceFamilyDrawer } from "src/drawers/resourceFamily/ResourceFamilyDrawer";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

type Type_Props_ResourceModelForm = {
    onClose: () => void;
    resourceModelIdToUpdate: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_ResourceModel = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.lazy(() => inputMultiLanguagesSchema()),
        resourceFamily: Yup.object().required(FORM_ERR_FMT.REQUIRED),
        unit: Yup.number().required(FORM_ERR_FMT.REQUIRED),
    });
});

export const ResourceModelForm = ({
    onClose,
    resourceModelIdToUpdate,
    action,
    setIsLoading,
}: Type_Props_ResourceModelForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceModel",
    );
    const { mutateAsync: mutateCreate } = mutationCreateResourceModel() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateResourceModel();

    const { isFetching: isFetchingResourceModel, data } = useShowResourceModel(
        resourceModelIdToUpdate as number,
    );

    const { isFetching: isFetchingResourceFamilies, data: resourceFamilies } =
        useSelectListResourceFamilies();

    const { isFetching: isFetchingUnits, data: units } = useSelectListUnits();

    const [openResourceTypeDrawer, setOpenDrawerResourceType] = useState(false);
    const [resourceTypeValue, setResourceTypeValue] = useState<string>("");

    const page = "resourceTypes";

    const language = getLocalStorageItem("language");

    const handleCloseDrawer = () => {
        setOpenDrawerResourceType(false);
    };

    const addNewResourceFamilyToResourceFamilyList = (
        resourceFamily: Type_index_resourceFamily,
    ) => {
        if (resourceFamily) {
            form.setValue("resourceFamily", resourceFamily, {
                shouldValidate: true,
                shouldDirty: true,
            });
        }
    };

    const form = useTmcForm<Type_show_resourceModel | Type_post_resourceModel>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            names: {},
        },
        values: data,
        resolver: yupResolver<any>(Schema_ResourceModel),
        onClose,
        setIsLoading,
        action,
    });

    const handleSubmit = async (
        values: Type_show_resourceModel | Type_post_resourceModel,
    ) => {
        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_resourceModel);
            } else {
                await mutateCreate(values as Type_post_resourceModel);
            }

            // Fermeture du drawer
            onClose();
        } catch (_e: any) {
            console.error("Error when creating or updating resource model");
        }
    };

    const isFetching = useMemo(
        () =>
            !!(resourceModelIdToUpdate && isFetchingResourceModel) ||
            isFetchingResourceFamilies ||
            isFetchingUnits,
        [
            resourceModelIdToUpdate,
            isFetchingResourceModel,
            isFetchingResourceFamilies,
            isFetchingUnits,
        ],
    );

    return (
        <TmcFormProvider form={form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"resourceModel"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <Box width={"100%"}>
                            <AutocompleteFreeSoloDialog
                                label={fmt("ResourceFamily")}
                                isFetching={isFetching}
                                multiple={false}
                                options={resourceFamilies || []}
                                name="resourceFamily"
                                onAddNewValue={(value) => {
                                    setResourceTypeValue(value?.name || "");
                                    setOpenDrawerResourceType(true);
                                }}
                                renderInputProps={{ variant: "outlined" }}
                                data-testid="ResourceModel-DrawerContent-resourceFamily"
                            />
                            <InputMultiLanguages
                                name="names"
                                label={fmt(`Name`)}
                            />
                        </Box>
                        <Select
                            name="unit"
                            options={
                                units
                                    ? formatSelectListToSelectOptionItem(
                                          units as Type_selectList[],
                                      )
                                    : []
                            }
                            label={fmt("Unit")}
                            variant={"outlined"}
                            data-testid="ResourceModel-DrawerContent-unit"
                        />
                    </Stack>
                )}
            </form>
            <ResourceFamilyDrawer
                open={openResourceTypeDrawer}
                onClose={handleCloseDrawer}
                names={{ [language]: resourceTypeValue }}
                page={page}
                action={"create"}
                resourceFamilyIdToUpdate={null}
                onFormSubmit={addNewResourceFamilyToResourceFamilyList}
            />
        </TmcFormProvider>
    );
};
