import { ChipTypeMap } from "@mui/material/Chip";
import { useState } from "react";
import { useWatch } from "react-hook-form";

import { mutationCreateTag, useSelectTags } from "src/api/tms-scheduling/tags";
import {
    Type_sch_post_tag,
    Type_select_tag,
} from "src/api/tms-scheduling/tags/types";
import {
    renderOptionDefault,
    renderTagsDefault,
} from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import {
    AutocompleteFreeSolo,
    Type_Props_AutocompleteFreeSolo,
} from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSolo/AutocompleteFreeSolo";

export type Type_Props_AutocompleteFreeSoloTags<
    T extends string | Type_select_tag,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    Type_Props_AutocompleteFreeSolo<
        T,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
    >,
    | "options"
    | "isFetching"
    | "renderOption"
    | "renderTags"
    | "getOptionDisabled"
    | "filterOptions"
>;

export const AutocompleteFreeSoloTags = ({
    name,
    renderInputProps,
    ...props
}: Type_Props_AutocompleteFreeSoloTags<
    string | Type_select_tag,
    true,
    false,
    true
>) => {
    ///////////////////////////////////////
    ///             State               ///
    ///////////////////////////////////////

    const [enabledFetch, setEnabledFetch] = useState<boolean>(false);

    ///////////////////////////////////////
    ///             Queries             ///
    ///////////////////////////////////////

    const { data: tagsFetched = [], isFetching } = useSelectTags(enabledFetch);
    const { mutateAsync: createTag } = mutationCreateTag();

    const addANewTag = async (newTagName: string) => {
        const tag: Type_sch_post_tag = {
            name: newTagName,
            enabled: true,
        };

        const { data: resp } = await createTag(tag);
        return resp;
    };

    ///////////////////////////////////////
    ///             Utils              ///
    ///////////////////////////////////////

    // Transformation du dernier élément, si en string, en élément objet
    const transformLastElement = async (array: any[]) => {
        if (array.length === 0) {
            return array;
        }

        const lastElement = array[array.length - 1];

        if (typeof lastElement === "string") {
            // Vérification de si l'élément existe déjà
            if (
                array.filter(
                    (elem: { name: string; id: number } | string) =>
                        typeof elem !== "string" && elem?.name === lastElement,
                )?.length ||
                tagsFetched.filter((elem) => elem?.name === lastElement)?.length
            ) {
                array.pop();
                return array;
            }
            const resp = await addANewTag(lastElement);

            array[array.length - 1] = resp.data;
        }
        return array;
    };

    const watchedValue = useWatch({ name });

    return (
        <AutocompleteFreeSolo
            options={tagsFetched}
            onOpen={() => setEnabledFetch(true)}
            onClose={() => setEnabledFetch(false)}
            name={name}
            loading={isFetching}
            renderInputProps={renderInputProps}
            /**
             *  @formatterValue {function} - Permet de formatter le OnChange lorsque les options sont des objets.
             *  @Params value - Contient un array d'objet et dont le dernier élément est une string si en freeSolo.
             */
            formatterValue={async (value: Type_select_tag[]) => {
                return await transformLastElement(value);
            }}
            renderOption={renderOptionDefault}
            renderTags={renderTagsDefault}
            getOptionDisabled={(option) => {
                // A partir du useWatch on rend disabled les options sélectionnées
                return !!watchedValue.filter(
                    (elem: Type_select_tag) =>
                        elem?.id === (option as Type_select_tag)?.id,
                )?.length;
            }}
            filterOptions={(
                options,
                { inputValue }: { inputValue: string },
            ) => {
                return options.filter((option) =>
                    (option as Type_select_tag)?.name.includes(inputValue),
                );
            }}
            disableCloseOnSelect
            sx={{
                maxWidth: "100%",
            }}
            {...props}
        />
    );
};
