import { Type_Props_IconSelect } from "src/components/Components_Common/forms/IconSelect/IconSelect";
import { IconSelect } from "src/components/Components_Common/forms/reactHookFormComponents/IconSelect/IconSelect";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { useTaskType } from "src/hooks/task/useTaskType";

type Type_Props_IconSelectTaskType = Omit<Type_Props_IconSelect, "items"> &
    Type_ReadonlyComponent;

export const IconSelectTaskType = ({
    variant,
    readonly = false,
    ...props
}: Type_Props_IconSelectTaskType) => {
    const { taskTypeList } = useTaskType({ iconKitVariant: "solid" });

    return (
        <IconSelect
            items={taskTypeList}
            variant={variant || "standard"}
            {...props}
            disabled={readonly || props.disabled}
        />
    );
};
