import {
    Box,
    Divider,
    ListItemText,
    MenuList,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

import { ProjectPath, Url } from "src/api/paths";
import { getImage } from "src/api/storage";
import { useSelectListSubProjects } from "src/api/tms-projects/subProjects";
import { Type_selectList_subProject } from "src/api/tms-projects/subProjects/types";
import { TMC_Button } from "src/components/Components_Common";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { URL_BASE, URL_TEAMOTY_PROJECT } from "src/router";
import { COLORS } from "src/theme/stylesheet";
import { displayFormattedDate } from "src/utils/date";

import {
    Styled_MenuItem,
    Styled_PopoverProjectMenu,
    Styled_SubProjectMenuImage,
    Styled_SubProjectMenuImageBlur,
} from "./ProjectMenu.style";

type Type_Props_ProjectMenu = {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
};
export const ProjectMenu = memo(function ProjectMenu({
    anchorEl,
    open,
    handleClose,
}: Type_Props_ProjectMenu) {
    // STATES
    const [subProjects, setSubProjects] = useState<
        Type_selectList_subProject[]
    >([]);
    const [imgProject, setImgProject] = useState("");

    // VARIABLES
    const { project, requestConfig } = useProject();
    const navigate = useNavigate();
    const urlParams = useParams();
    const location = useLocation();
    const basePath = generatePath(`${URL_BASE}`, { ...urlParams });
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("TopBar.Project");

    // QUERY

    const { isFetching, data } = useSelectListSubProjects();

    useEffect(() => {
        data && setSubProjects(data);
    }, [requestConfig.projectId, data]);

    // MEMOIZED VALUES
    const navigateToSubProject = useCallback(
        (id: number | undefined) => {
            if (id && project?.slug) {
                const url = `${URL_TEAMOTY_PROJECT}/:subProjectId/myteamoty/workspace`;
                navigate(
                    generatePath(url, {
                        ...urlParams,
                        projectSlug: project.slug,
                        subProjectId: String(id),
                    }),
                );
                handleClose();
            }
        },
        [navigate, urlParams, project?.slug],
    );

    const navigateToProjects = useCallback(() => {
        navigate(generatePath(`${URL_BASE}/projects`, { ...urlParams }));
        handleClose();
    }, [navigate, urlParams]);

    const isSelectedSubProject = useCallback(
        (id: number) => {
            if (id && project) {
                return location.pathname.includes(
                    `/projects/${project.slug}/${id}/`,
                );
            }
            return false;
        },
        [project?.name, location.pathname],
    );

    const subProjectsItems = useMemo(
        () =>
            subProjects.map((subProject) => ({
                key: String(subProject.id),
                label: subProject.name,
                action: () => navigateToSubProject(subProject.id),
                selected: isSelectedSubProject(subProject.id),
                disabled: !subProject.enabled,
            })),
        [subProjects, navigateToSubProject, isSelectedSubProject],
    );

    const navigateToProjectSettings = useCallback(() => {
        if (urlParams.projectSlug && project?.slug) {
            navigate(
                `${basePath}/projects/${project.slug}/settings/subprojects`,
            );
            handleClose();
        }
    }, [navigate, urlParams.projectSlug, project?.slug, basePath]);

    useEffect(() => {
        if (project?.id) {
            const fetchImg = async () => {
                const res = await getImage(
                    project.id,
                    Url.COMMONS,
                    ProjectPath.PROJECTS,
                    "thumbnail",
                    project.thumbnail as string,
                );
                const imageObjectURL = URL.createObjectURL(res.data);
                setImgProject(imageObjectURL);
            };

            fetchImg();

            return () => {
                URL.revokeObjectURL(imgProject);
            };
        }
    }, [project?.id]);

    return (
        <Styled_PopoverProjectMenu
            anchorEl={anchorEl}
            id="project-menu"
            open={open}
            onClose={handleClose}
            transformOrigin={{
                horizontal: "right",
                vertical: "top",
            }}
            anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
            }}
        >
            <>
                <Box paddingBottom={2}>
                    <Box height={148} position={"relative"}>
                        <Box textAlign={"right"} paddingX={2}>
                            <TMC_Button
                                onClick={navigateToProjects}
                                data-testid={`ProjectMenu-quitProject-btn`}
                                variant="text"
                                startIcon={
                                    <Icon
                                        variant="solid"
                                        icon="person-to-portal"
                                        fontSize={"small"}
                                    />
                                }
                                size={"small"}
                                sx={{
                                    color: COLORS.moonAlpha500,
                                }}
                            >
                                {fmt("QuitProject")}
                            </TMC_Button>
                        </Box>

                        {!imgProject ? (
                            <Skeleton
                                variant="rectangular"
                                width={128}
                                height={92}
                                sx={{
                                    marginX: 4,
                                    position: "absolute",
                                    bottom: "8px",
                                }}
                            />
                        ) : (
                            <Box paddingX={4}>
                                {" "}
                                <Styled_SubProjectMenuImageBlur
                                    sx={{
                                        backgroundImage: `url(${imgProject})`,
                                    }}
                                ></Styled_SubProjectMenuImageBlur>
                                <Styled_SubProjectMenuImage
                                    sx={{
                                        backgroundImage: `url(${imgProject})`,
                                    }}
                                ></Styled_SubProjectMenuImage>
                            </Box>
                        )}
                    </Box>
                    <Typography
                        paddingX={4}
                        sx={{
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                        data-testid={`ProjectMenu-projectName`}
                    >
                        {project?.name}
                    </Typography>
                    <Stack
                        flexDirection="row"
                        flexWrap="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                        paddingX={4}
                    >
                        <Typography
                            variant={"body3Medium"}
                            data-testid={`ProjectMenu-startDate`}
                        >
                            {fmt("StartedOn")}{" "}
                            {displayFormattedDate(project?.startDate)}
                        </Typography>
                        <TMC_Button
                            onClick={navigateToProjectSettings}
                            data-testid="ProjectMenu-navigateToProjectSettings"
                            variant="text"
                            startIcon={
                                <Icon
                                    variant="solid"
                                    icon="gear"
                                    fontSize={"small"}
                                />
                            }
                            size={"small"}
                            sx={{
                                color: COLORS.moonAlpha500,
                            }}
                        >
                            {fmt("Settings")}
                        </TMC_Button>
                    </Stack>
                </Box>
                <Divider />
                <Stack
                    px={4}
                    py={1}
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography variant={"body3Medium"}>
                        {fmt("SubProject")}
                    </Typography>
                    {!subProjects.length && isFetching && (
                        <Icon
                            data-testid={`LoadingBox-menu`}
                            variant="solid"
                            icon="spinner-third"
                            spin
                            fontSize={"small"}
                        />
                    )}
                </Stack>
                <MenuList sx={{ padding: 0 }}>
                    {subProjectsItems.map((item) => (
                        <Styled_MenuItem
                            key={item.key}
                            onClick={item?.action}
                            disabled={item.disabled}
                            selected={item.selected}
                        >
                            <ListItemText>
                                <Typography
                                    sx={{
                                        textWrap: "wrap",
                                    }}
                                    variant="body1"
                                    data-testid={`menuItem-subProject-${item.key}`}
                                >
                                    {item.label}
                                </Typography>
                            </ListItemText>
                        </Styled_MenuItem>
                    ))}
                </MenuList>
            </>
        </Styled_PopoverProjectMenu>
    );
});
