import { Stack } from "@mui/material";

import { Type_selectList_trade } from "src/api/tms-projects/trades/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";

type Type_TradesListCellProps = {
    trades: Type_selectList_trade[];
};

export const TradesListCell = ({ trades }: Type_TradesListCellProps) => {
    return (
        <Stack
            direction={"row"}
            gap={1}
            display={"inline-flex"}
            flexWrap={"wrap"}
        >
            {trades &&
                trades.map((item, index) => {
                    return (
                        <TMC_Chip
                            label={`${item.code.toUpperCase()} - ${item.name}`}
                            key={index}
                            data-testid={`TradesListCell-${item.name.replaceAll(
                                " ",
                                "_",
                            )}`}
                            backgroundColor={item.color}
                        />
                    );
                })}
        </Stack>
    );
};
