import { useState } from "react";

import {
    mutationDeleteLicenseType,
    mutationStatusLicenseType,
    useIndexLicenseTypes,
} from "src/api/tms-commons/licenseTypes";
import {
    Type_index_licenseType,
    Type_put_licenseType,
} from "src/api/tms-commons/licenseTypes/types";

import { Page_LicenseTypesComponent } from "./Page_LicenseTypes.component";

export type Type_modalDeleteLicenseType = {
    isOpen: boolean;
    props?: Type_index_licenseType;
};

export const Page_LicenseTypesContainer = () => {
    const [modalDeleteLicenseType, setModalDeleteLicenseType] =
        useState<Type_modalDeleteLicenseType>({
            isOpen: false,
        });

    const { isFetching, data: licenseTypes } = useIndexLicenseTypes() || {};

    const { mutate: mutateLicenseTypeStatus } =
        mutationStatusLicenseType() || {};

    const { mutate: mutateLicenseTypeDelete } =
        mutationDeleteLicenseType() || {};

    const removeLicenseType = (id: number) => {
        mutateLicenseTypeDelete(id);
    };

    const changeStatus = (licenseType: Type_index_licenseType) => {
        mutateLicenseTypeStatus({
            id: licenseType.id,
            enabled: !licenseType.enabled,
        } as Type_put_licenseType);
    };

    return (
        <Page_LicenseTypesComponent
            isFetching={isFetching}
            deleteLicenseType={removeLicenseType}
            licenseTypes={licenseTypes || []}
            changeStatus={changeStatus}
            modalDeleteLicenseType={modalDeleteLicenseType}
            setModalDeleteLicenseType={setModalDeleteLicenseType}
        />
    );
};
