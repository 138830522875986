import { DrawerProps } from "@mui/material";
import * as React from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { LicenseForm } from "src/forms/licenses/LicenseForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_LicenseDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    numberId: number | null;
    action: Type_action;
    projectId: number;
};

export const LicenseDrawer = ({
    open,
    onClose,
    numberId,
    action,
    projectId,
}: Type_Props_LicenseDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Licenses");

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`Licenses-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            formId={"licenses"}
            isFormLoading={false}
            page="licenses"
        >
            <LicenseForm
                onClose={onClose}
                updateId={numberId === null ? undefined : numberId}
                action={action}
                projectId={projectId}
            />
        </DrawerGeneric>
    );
};
