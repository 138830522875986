import { FormControl, Stack } from "@mui/material";
import { TextFieldVariants } from "@mui/material/TextField/TextField";
import { DatePickerProps } from "@mui/x-date-pickers-pro";

import {
    TMC_FormHelperText,
    TMC_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants/index";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

import {
    leftArrowIcon,
    openPickerIcon,
    rightArrowIcon,
    Styled_DatePicker,
    switchViewIcon,
} from "./DatePicker.style";

export type Type_Props_DatePickerProps = Type_ReadonlyComponent & {
    name?: string;
    variant?: TextFieldVariants;
    helperText?: string;
    error?: boolean;
};
export const DatePicker = ({
    helperText,
    name,
    label,
    variant,
    error,
    readonly,
    ...restProps
}: Type_Props_DatePickerProps & DatePickerProps<any>) => {
    return (
        <Stack
            flexDirection="column"
            data-testid={`DatePicker-${name}-container`}
            flex={1}
        >
            <FormControl>
                <Styled_DatePicker
                    slots={{
                        openPickerIcon: openPickerIcon,
                        leftArrowIcon: leftArrowIcon,
                        rightArrowIcon: rightArrowIcon,
                        switchViewIcon: switchViewIcon,
                        textField: TMC_TextField,
                    }}
                    slotProps={{
                        textField: {
                            inputProps: {
                                "data-testid": `DatePicker-${name}`,
                            },
                            label: label,
                            variant: variant,
                        },
                    }}
                    disabled={readonly || restProps.disabled}
                    {...restProps}
                />
            </FormControl>
            {helperText && error && (
                <TMC_FormHelperText
                    helperText={helperText as string}
                    inputName={name as string}
                />
            )}
        </Stack>
    );
};
