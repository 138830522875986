import React, { useState } from "react";
import useMeasure from "react-use-measure";

import { LoadingBox } from "src/components/Components_Common";
import { Blank } from "src/components/Components_Teamoty/Planning/Blank/Blank";
import { Planning } from "src/components/Components_Teamoty/Planning/Planning";
import { SequencePertDrawer } from "src/components/Components_Teamoty/SequencePert/SequencePertDrawer";
import { useMeasureDefaultConfig } from "src/configurations/app";
import { PlanningProvider, PlanningTypes } from "src/contexts/planning";
import { Type_event_pert, useChannel } from "src/hooks/useChannel";
import { Layout_ContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Layout_ContextualDrawer";
import { Layout_Resizable } from "src/layouts/Layout_Resizable/Layout_Resizable";
import { ParentLayout } from "src/layouts/Layout_Resizable/Layout_Resizable.style";

import { PlanningToolbar } from "./PlanningToolbar";

export type Type_State_IsLoading = {
    loading: boolean;
    withPlanning: boolean;
};

export const ViewPlanning = () => {
    const [centerRef, centerBounds] = useMeasure(useMeasureDefaultConfig);
    const [bottomRef, bottomBounds] = useMeasure(useMeasureDefaultConfig);

    ////////////////////////////////////////////
    // ---- STATES                          ----
    ////////////////////////////////////////////

    const [isLoading, setIsLoading] = useState<Type_State_IsLoading>({
        loading: true,
        withPlanning: false,
    });

    const [showPertDiagram, setShowPertDiagram] = useState(false);
    const [sequenceId, setSequenceId] = useState<number | undefined>(undefined);
    const [sequenceName, setSequenceName] = useState<string | undefined>(
        undefined,
    );

    useChannel({
        eventHandler: ({ event, data }) => {
            if (event === "openPert") {
                setShowPertDiagram((data as Type_event_pert).open);
                setSequenceId((data as Type_event_pert).id);
                setSequenceName((data as Type_event_pert).label);
            }
        },
    });

    return (
        <PlanningProvider type={PlanningTypes.TIME_LOCATION}>
            <ParentLayout data-testid={"Project_View_Planning"}>
                {isLoading.withPlanning && <PlanningToolbar />}
                <Layout_Resizable
                    rightPanel={<Layout_ContextualDrawer />}
                    bottomPanel={
                        showPertDiagram ? (
                            <SequencePertDrawer
                                sequenceId={sequenceId as number}
                                sequenceName={sequenceName as string}
                                setShowPertDiagram={setShowPertDiagram}
                                bounds={bottomBounds}
                            />
                        ) : undefined
                    }
                    centerRef={centerRef}
                    bottomRef={bottomRef}
                >
                    {isLoading.loading && <LoadingBox />}
                    <Planning
                        bounds={centerBounds}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />

                    {!isLoading.loading && !isLoading.withPlanning && <Blank />}
                </Layout_Resizable>
            </ParentLayout>
        </PlanningProvider>
    );
};
