import { Grid, Stack, Typography } from "@mui/material";
import React, { memo, ReactNode } from "react";

type Type_Props_SettingsRowLine = {
    title: string;
    dataTestId?: string;
    value?: ReactNode;
    children?: ReactNode;
};

export const SettingsRowLine = memo(
    ({
        title,
        dataTestId = "SettingsRowLine",
        value = null,
        children = null,
    }: Type_Props_SettingsRowLine) => {
        return (
            <>
                <Grid item xs={4} md={3}>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        data-testid={`${dataTestId}-label`}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={8} md={9}>
                    <Stack direction="row" spacing={2} alignItems="baseline">
                        {value !== null ? (
                            <Typography
                                variant="body1"
                                data-testid={`${dataTestId}`}
                            >
                                {value}
                            </Typography>
                        ) : children ? (
                            children
                        ) : (
                            "-"
                        )}
                    </Stack>
                </Grid>
            </>
        );
    },
);

SettingsRowLine.displayName = "SettingsRowLine";
