import {
    Type_index_resourcePeriod,
    Type_post_resourcePeriod,
    Type_prj_index_resourcePeriod,
    Type_prj_post_resourcePeriod,
    Type_prj_put_resourcePeriod,
    Type_put_resourcePeriod,
} from "src/api/tms-projects/resourcePeriods/types";
import {
    displayFormattedDateTime,
    formatDateForBack,
    formatDateTimeForFront,
    formatTimeForBack,
} from "src/utils/date";

export const formatterIndexResourcePeriod = (
    resourcePeriod: Type_prj_index_resourcePeriod,
): Type_index_resourcePeriod => {
    return {
        id: resourcePeriod.id,
        resource_id: resourcePeriod.resource_id,
        startDateTimeLocal: displayFormattedDateTime(
            formatDateTimeForFront(
                `${resourcePeriod.startDate} ${resourcePeriod.startTime ?? "00:00"}`,
            ),
        ),
        endDateTimeLocal: displayFormattedDateTime(
            formatDateTimeForFront(
                `${resourcePeriod.endDate} ${resourcePeriod.endTime ?? "00:00"}`,
            ),
        ),
    };
};

export const formatterIndexResourcePeriods = (
    resourcePeriods: Type_prj_index_resourcePeriod[],
): Type_index_resourcePeriod[] => {
    return resourcePeriods.map(
        (resourcePeriod: Type_prj_index_resourcePeriod) =>
            formatterIndexResourcePeriod(resourcePeriod),
    );
};

export const formatterCreateResourcePeriod = (
    resourcePeriod: Type_post_resourcePeriod,
): Type_prj_post_resourcePeriod => {
    return {
        resource_id: resourcePeriod.id,
        startDate: formatDateForBack(resourcePeriod.period[0])!,
        endDate: formatDateForBack(resourcePeriod.period[1])!,
        startTime: formatTimeForBack(resourcePeriod.period[0]),
        endTime: formatTimeForBack(resourcePeriod.period[1]),
    };
};

export const formatterUpdateResourcePeriod = (
    resourcePeriod: Type_put_resourcePeriod,
): Type_prj_put_resourcePeriod => {
    return {
        id: resourcePeriod.periodId,
        resource_id: resourcePeriod.id,
        startDate: formatDateForBack(resourcePeriod.period[0]),
        endDate: formatDateForBack(resourcePeriod.period[1]),
        startTime: formatTimeForBack(resourcePeriod.period[0]),
        endTime: formatTimeForBack(resourcePeriod.period[1]),
    };
};
