import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

import { DrawingPath, Url } from "src/api/paths";
import { getImageQuery } from "src/api/storage";
import {
    formatterIndexDrawing,
    formatterIndexDrawings,
    formatterSelectListDrawings,
    formatterShowDrawing,
} from "src/api/tms-projects/drawing/formatters";
import {
    createDrawing,
    deleteDrawing,
    indexDrawings,
    selectListDrawings,
    showDrawing,
    statusDrawing,
    updateDrawing,
} from "src/api/tms-projects/drawing/services";
import {
    Type_index_drawing,
    Type_prj_index_drawing,
    Type_prj_post_drawing,
    Type_prj_put_drawing,
    Type_prj_show_drawing,
    Type_show_drawing,
} from "src/api/tms-projects/drawing/types";
import { DrawingKeys } from "src/api/tms-projects/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getDate } from "src/utils/date";

export const useIndexDrawings = () => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [DrawingKeys.INDEX, requestConfig],
        queryFn: () => indexDrawings(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexDrawings");
            }

            return formatterIndexDrawings(
                data.data.data as Type_prj_index_drawing[],
            );
        },
        enabled: !!requestConfig.projectId,
    });
};

export const useShowDrawing = (id: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [DrawingKeys.SHOW, id, requestConfig],
        queryFn: () => showDrawing(id, requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexDrawings");
            }

            return formatterShowDrawing(
                data.data.data as Type_prj_show_drawing,
            );
        },
        enabled: !!id && !!requestConfig.projectId,
    });
};

export const useImageDrawing = (
    drawing: Type_show_drawing | null,
): UseQueryResult => {
    const { requestConfig } = useProject();

    const cachedName = drawing?.updatedAt
        ? `drawing_${drawing.updatedAt.unix()}`
        : `drawing_${getDate().unix()}`;

    return getImageQuery(
        drawing?.id as number,
        Url.PROJECTS,
        DrawingPath.DRAWINGS,
        "image",
        cachedName,
        !!drawing,
        requestConfig,
    );
};

export const mutationCreateDrawing = () => {
    const { formatMessageWithPartialKey: error } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (newDrawing: Type_prj_post_drawing) =>
            createDrawing({ ...newDrawing }, requestConfig),
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexDrawings");
            }
            const drawing = formatterIndexDrawing(data.data.data);

            console.log("mutationCreateDrawing success", drawing);
        },
        onError: (err: any) => {
            addWarning({
                description: error("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateDrawing = (drawingId: number) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (drawingData: Type_prj_put_drawing) =>
            updateDrawing(
                {
                    id: drawingId,
                    data: drawingData,
                },
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexDrawings");
            }
            const id = data.data.data.id;

            await queryClient.invalidateQueries({
                queryKey: [DrawingKeys.SHOW, id, requestConfig],
            });
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationDeleteDrawing = () => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) => deleteDrawing(id, requestConfig),
    });
};

export const mutationStatusDrawing = (callback: any) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (newStatus: Omit<Type_index_drawing, "name">) => {
            return statusDrawing(
                {
                    enabled: newStatus.enabled as boolean,
                },
                newStatus.id as number,
                requestConfig,
            );
        },
        onSuccess: (data): void => {
            // TODO
            callback(
                formatterIndexDrawing(data.data.data as Type_prj_index_drawing),
            );
        },
    });
};

export const useSelectListDrawings = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [DrawingKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListDrawings(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListDrawings");
            }

            return formatterSelectListDrawings(data.data.data);
        },
        enabled: !!requestConfig.projectId,
    });
};
