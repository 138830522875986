import Konva from "konva";
import React, { useCallback } from "react";
import { Stage } from "react-konva";

import { Type_point } from "src/components/Components_Common/canvas/types";

import { Type_Props_FlowStage } from "./FlowStage.type";

export const FlowStage = ({
    children,
    bounds,
    stageFlow,
    changeStageFlow,
    changeSelected,
    dblClick,
    refStage,
}: Type_Props_FlowStage) => {
    const handleWheel = useCallback((e: Konva.KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();

        const scaleBy = 1.1;
        const stage: Konva.Stage | null = e.target.getStage();
        if (stage) {
            const oldScale = stage.scaleX();

            const position = stage.getPointerPosition();
            if (position) {
                const mousePointTo: Type_point = {
                    x: position.x / oldScale - stage.x() / oldScale,
                    y: position.y / oldScale - stage.y() / oldScale,
                };

                const newScale: number =
                    e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

                if (newScale > 2) return;
                if (newScale < 0.5) return;

                changeStageFlow({
                    scale: newScale,
                    x: -(mousePointTo.x - position.x / newScale) * newScale,
                    y: -(mousePointTo.y - position.y / newScale) * newScale,
                });
            }
        }
    }, []);

    return (
        <Stage
            ref={refStage}
            width={bounds.width}
            height={bounds.height}
            onWheel={handleWheel}
            draggable={true}
            scaleX={stageFlow.scale}
            scaleY={stageFlow.scale}
            x={stageFlow.x}
            y={stageFlow.y}
            offsetX={0.5}
            offsetY={0.5}
            onClick={(e) => {
                e.cancelBubble = true;
                changeSelected({ selected: false });
            }}
            onDblClick={(e) => {
                e.cancelBubble = true;
                dblClick();
            }}
            onDragMove={(e: Konva.KonvaEventObject<DragEvent>) => {
                changeStageFlow({
                    x: e.target.x(),
                    y: e.target.y(),
                });
            }}
        >
            {children}
        </Stage>
    );
};
