import { Grid, Typography } from "@mui/material";
import React, { memo } from "react";

import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_SettingsRowTitle = {
    title: string;
    variant?: "h1" | "body1Medium";
    dataTestId?: string;
    action?: string;
    handleClick: () => void;
};

export const SettingsRowTitle = memo(
    ({
        title,
        variant = "body1Medium",
        dataTestId = "SettingsRowLine",
        action = "Edit",
        handleClick = () => {},
    }: Type_Props_SettingsRowTitle) => {
        const { formatMessageWithPartialKey: fmtAction } =
            useCoreIntl("Actions");

        return (
            <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    data-testid={`${dataTestId}-title`}
                    variant={variant}
                >
                    {title}
                </Typography>
                <TMC_Button
                    variant="text"
                    data-testid={`${dataTestId}-btn`}
                    onClick={handleClick}
                    sx={{
                        minWidth: "auto",
                    }}
                >
                    {fmtAction(action)}
                </TMC_Button>
            </Grid>
        );
    },
);

SettingsRowTitle.displayName = "SettingsRowTitle";
