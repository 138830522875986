import { Stack, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { SubProjectKeys } from "src/api/tms-projects/keys";
import { Type_index_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { TopBarLeft_IconButton } from "src/components/Components_Teamoty/TopBar/stylesheet";
import { useProject } from "src/contexts/project";
import { useWorkspaceViewContext } from "src/contexts/workspaces";
import { ViewSelection } from "src/dialogs/ViewSelection";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_ViewsTabs,
    Styled_ViewTab,
} from "src/layouts/Layout_Workspace/stylesheet";

export const ViewsTabs = () => {
    // Providers
    const {
        currentView,
        currentWorkspace,
        handleChangeTab,
        setCurrentView,
        views,
    } = useWorkspaceViewContext();
    const { requestConfig } = useProject();

    if (!currentView || !views) {
        return null;
    }

    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Project.Views");
    const { formatMessageWithPartialKey: fmtWorkspace } =
        useCoreIntl("Workspace");

    const queryClient = useQueryClient();

    // Router
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const currentTab = useMemo(() => {
        return currentView ? currentView.id : views[0].id;
    }, [currentView, views]);

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        handleChangeTab(newValue);
    };

    // Check if the currentTab matches any of the view ids
    const validTabIds = views?.map((view) => view.id);
    const validTab = validTabIds.includes(currentTab)
        ? currentTab
        : validTabIds[0] ?? null;

    const addNewView = async (newView: Type_index_view) => {
        setCurrentView(newView);
        await queryClient.invalidateQueries({
            queryKey: [
                SubProjectKeys.CURRENT_WORKSPACE,
                {
                    projectId: requestConfig.projectId,
                    subProjectId: requestConfig.subProjectId,
                },
            ],
        });

        const workspaceUrl = "/workspace";
        const tabLocation = pathname.split(workspaceUrl)[0];
        navigate(`${tabLocation}${workspaceUrl}/${newView.id}`);
    };

    return (
        <>
            <Stack direction="row">
                <Styled_ViewsTabs
                    value={validTab}
                    onChange={handleChange}
                    variant="scrollable"
                >
                    {views?.map((view: Type_index_view) => (
                        <Styled_ViewTab
                            data-testid={`Workspace-id-${currentWorkspace?.id}-View-id-${view.id}`}
                            key={view.id}
                            disableRipple={true}
                            label={fmtWorkspace(view.name + ".Name")}
                            value={view.id}
                            to={`../${view.id}`}
                            component={Link}
                        />
                    ))}
                </Styled_ViewsTabs>
                <Tooltip title={fmt("AddViewTooltip")}>
                    <TopBarLeft_IconButton
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleOpen}
                        data-testid="ViewsList-AddNew-btn"
                        aria-controls={open ? "views-menu" : undefined}
                        aria-expanded={open}
                        sx={{
                            boxShadow:
                                "inset 1px 0px 0px 0px rgba(7, 8, 8, 0.95), inset -1px 0px 0px 0px rgba(7, 8, 8, 0.95)",
                        }}
                    >
                        <Icon
                            variant="light"
                            icon="plus"
                            sx={{ width: "24px" }}
                        />
                    </TopBarLeft_IconButton>
                </Tooltip>
            </Stack>

            <ViewSelection
                handleClose={handleClose}
                open={open}
                addNewView={addNewView}
            />
        </>
    );
};
