import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";
import { DropEvent, DropzoneOptions, useDropzone } from "react-dropzone";

import { AcceptedFormat } from "src/components/Components_Common/forms/InputFile";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { TMC_Button } from "src/components/Components_Common/index";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

const Styled_Box = styled(Box)(({ theme }: any) => ({
    display: "flex",
    minWidth: 260,
    padding: theme.spacing(6),
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),

    borderRadius: theme.shape.borderRadiusLarge,
    border: `1px dashed ${COLORS.grey}`,
    background: COLORS.moon50,

    "&.acceptStyle": {
        border: `2px dashed ${theme.palette.success.main}`,
    },
    "&.rejectStyle": {
        border: `2px dashed ${theme.palette.error.main}`,
    },
}));

type Type_DropzoneProps = {
    onDropAccepted: (files: Array<File>, event: DropEvent | Event) => void;
    options?: DropzoneOptions;
    acceptFormats: AcceptedFormat[]; // Use the union type
};

export const Dropzone = ({
    onDropAccepted,
    options,
    acceptFormats,
    ...inputProps
}: Type_DropzoneProps): React.ReactElement => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Components.Dropzone",
    );

    const { getRootProps, getInputProps, open, isDragReject, isDragAccept } =
        useDropzone({
            noDragEventsBubbling: true, // if we have multiple dropzone
            noClick: true, // prevent open two dialogs when click on button
            onDropAccepted,
            ...options,
        });

    const className = useMemo(() => {
        const classNames = [];

        if (isDragAccept) classNames.push("acceptStyle");
        if (isDragReject) classNames.push("rejectStyle");

        return classNames.join(" ");
    }, [isDragAccept, isDragReject]);

    return (
        <>
            <Styled_Box {...getRootProps({ className })}>
                <Icon
                    variant="light"
                    icon="cloud-arrow-up"
                    fontSize="large"
                    sx={{ color: COLORS.moon500 }}
                />
                <input {...getInputProps(inputProps)} />

                <Typography variant="body1">{fmt("DropHere")}</Typography>

                <TMC_Button
                    variant="text"
                    onClick={open}
                    data-testid={"Dropzone-button-cta"}
                >
                    {fmt("ImportFileType", {
                        values: { fileTypes: `${acceptFormats}` },
                    })}
                </TMC_Button>

                <Box>
                    {isDragReject && (
                        <Typography color={(theme) => theme.palette.error.main}>
                            {fmt("FileNotSupported")}
                        </Typography>
                    )}
                </Box>
            </Styled_Box>
        </>
    );
};
