// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { InputAdornment, InputAdornmentProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

interface Styled_InputAdornmentTextFielWithUnitProps
    extends InputAdornmentProps {
    ownerState?: {
        error?: boolean;
        disabled?: boolean;
        focused?: boolean;
    };
}

// Utilisé pour le TextField (TMC_TextFieldWithUnit)
export const Styled_InputAdornmentTextFielWithUnit = styled(
    InputAdornment,
)<Styled_InputAdornmentTextFielWithUnitProps>(({ ownerState }) => ({
    "& p": {
        // FOCUS
        ...(ownerState?.focused && {
            color: COLORS.darkGrey,
        }),
        // ERROR
        ...(ownerState?.error && {
            color: COLORS.darkGrey,
        }),
    },
}));

interface Styled_InputAdornmentTextFieldWithBlockProps
    extends InputAdornmentProps {
    ownerState?: {
        error?: boolean;
        disabled?: boolean;
        focused?: boolean;
    };
}

export const Styled_InputAdornmentTextFielWithBlock = styled(
    InputAdornment,
)<Styled_InputAdornmentTextFieldWithBlockProps>(({ ownerState }) => ({
    borderRight: `1px solid ${COLORS.moon700}`,
    paddingRight: "8px",
    height: "100%",
    maxHeight: "100%",
    margin: 0,
    "& p": {
        color: COLORS.darkGrey,
        ...(ownerState?.disabled && {
            color: COLORS.grayPlaceholder,
        }),
    },
}));
