import React, { createContext, useContext, useMemo, useState } from "react";

import { Type_get_me } from "src/api/tms-gateway/auth/types";

type Type_permission = "create" | "delete" | "update";

type userType = {
    user: Type_get_me | null;
    setUser: (user: Type_get_me) => void;
    hasPermission: (permission: string | string[]) => boolean;
    getAcronym: () => string;
    getFullName: () => string;
    checkPermission: (item: string, action: Type_permission) => boolean;
};

const initialUserState: userType = {
    user: null,
    setUser: () => {},
    hasPermission: () => false,
    getAcronym: () => "",
    getFullName: () => "",
    checkPermission: () => false,
};

const UserContext = createContext(initialUserState);

export const useUser = () => useContext(UserContext);

type UserProviderType = {
    children: JSX.Element;
};

export const UserProvider = ({ children }: UserProviderType) => {
    const [user, setUser] = useState(initialUserState);
    const userFunc = useMemo(() => {
        return {
            setUser: (newUser: Type_get_me): void => {
                setUser((user: userType) => ({
                    ...user,
                    user: newUser,
                }));
            },
            hasPermission: (permission: string | string[]): boolean => {
                if (typeof permission === "string")
                    return user?.user?.permissions?.includes(
                        permission,
                    ) as boolean;
                if (permission)
                    for (let i = 0; i < permission.length; i++) {
                        if (user?.user?.permissions?.includes(permission[i]))
                            return true;
                    }
                return false;
            },
            getAcronym: (): string => {
                const { firstName, lastName } = user?.user || {};
                const acronym = [firstName, lastName]
                    .filter(Boolean) // Filtre les valeurs non définies ou nulles
                    .map((name) => (name as string)[0])
                    .join(" ");
                return acronym;
            },
            getFullName: (): string => {
                return `${user.user?.firstName || ""} ${
                    user.user?.lastName || ""
                }`;
            },
            checkPermission: (
                page: string,
                action: Type_permission,
            ): boolean => {
                const name = `${page}_${action}`;
                return !!user?.user?.permissions?.includes(name);
            },
        };
    }, [user.user]);

    const userState: typeof initialUserState = useMemo(() => {
        return {
            ...user,
            ...userFunc,
        };
    }, [user]);

    return (
        <UserContext.Provider value={userState}>
            {children}
        </UserContext.Provider>
    );
};
