import { Stack, Typography } from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ColorChip } from "src/components/Components_Teamoty/chips/ColorChip";

type Type_Props_AreaCell = {
    row: GridRowModel;
    mainName?: string;
};

export const AreaCell = ({ row }: Type_Props_AreaCell) => {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flex={1}
            paddingRight={1}
        >
            <Stack direction={"row"} gap={1} alignItems={"center"}>
                <ColorChip
                    data-testid={`AreaCell-color-${row.id}`}
                    data-testid-color={row.color}
                    backgroundColor={row.enabled ? row.color : "common.white"}
                    sx={(theme) => ({
                        border: theme.border.alpha,
                    })}
                />
                {(!row.show || row.hasParentHidden) && (
                    <Icon
                        icon="eye-slash"
                        variant="light"
                        fontSize={"small"}
                        sx={{ color: "text.secondary" }}
                    />
                )}{" "}
                <Typography
                    variant={"body1Medium"}
                    color={
                        !row.enabled
                            ? "text.disabled"
                            : !row.show || row.hasParentHidden
                              ? "text.secondary"
                              : "text.primary"
                    }
                >
                    {row.name}
                </Typography>
            </Stack>
        </Stack>
    );
};
