import Konva from "konva";

import { strokeOffsetPlanningTask } from "src/components/Components_Teamoty/Planning/Planning.const";

/**
 * Dessine une ligne horizontale en bas d'une forme.
 *
 * @param width - La largeur de la forme.
 * @param height - La hauteur de la forme.
 * @param context - Contexte de dessin Konva.
 * @param shape - Forme Konva à dessiner.
 */

export const planningShapeWaitingFunction = (
    width: number,
    height: number,
    context: Konva.Context,
    shape?: Konva.Shape,
): void => {
    context.beginPath();
    context.rect(
        strokeOffsetPlanningTask,
        strokeOffsetPlanningTask,
        width - 2 * strokeOffsetPlanningTask,
        height - 2 * strokeOffsetPlanningTask,
    );
    context.closePath();

    shape && context.fillStrokeShape(shape);
};
