import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

import { ProjectPath, Url } from "src/api/paths";
import { getImageQuery } from "src/api/storage";
import { FavouriteProjectKeys, ProjectKeys } from "src/api/tms-commons/keys";
import {
    formatterArchiveProject,
    formatterCreateProject,
    formatterIndexProjects,
    formatterShowProject,
    formatterStatusProject,
    formatterUpdateProject,
} from "src/api/tms-commons/projects/formatters";
import {
    changeProjectStatus,
    createProject,
    getProjectBySlug,
    indexProjects,
    removeProject,
    showProject,
    updateProject,
} from "src/api/tms-commons/projects/services";
import {
    Type_post_project,
    Type_put_project,
    Type_show_project,
} from "src/api/tms-commons/projects/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexProjects = () => {
    return useQuery({
        queryKey: [ProjectKeys.INDEX],
        queryFn: indexProjects,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexProjects");
            }
            return formatterIndexProjects(data.data.data);
        },
    });
};

export const mutationCreateProject = () => {
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Projects");

    return useMutation({
        mutationFn: (project: Type_post_project) =>
            createProject(formatterCreateProject(project)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateProject");
            }

            await Promise.all([
                queryClient.invalidateQueries([ProjectKeys.INDEX]),
                queryClient.invalidateQueries([FavouriteProjectKeys.INDEX]),
            ]);

            addSuccess({
                description: fmt("ToastSuccessCreate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        project: data.data.data.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({ description: fmtErr("GenericError") });
            return err;
        },
    });
};

export const mutationUpdateProject = () => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Projects");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (projectToUpdate: Type_show_project) =>
            updateProject(
                formatterUpdateProject(projectToUpdate),
                projectToUpdate.id,
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateProject");
            }

            await Promise.all([
                queryClient.invalidateQueries([ProjectKeys.SLUG]),
                queryClient.invalidateQueries([ProjectKeys.SHOW]),
                queryClient.invalidateQueries([ProjectKeys.INDEX]),
                queryClient.invalidateQueries([FavouriteProjectKeys.INDEX]),
            ]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        project: data.data.data.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            if (err.data.errors.name.includes("unique")) {
                addWarning({
                    description: fmtErr("UniqueName"),
                });
            } else {
                addWarning({
                    description: fmtErr("GenericError"),
                });
            }
        },
    });
};

export const mutationStatusProject = () => {
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: Type_put_project) => {
            return changeProjectStatus(
                formatterStatusProject(newStatus),
                newStatus.id,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusProject");
            }
            await Promise.all([
                queryClient.invalidateQueries([ProjectKeys.INDEX]),
                queryClient.invalidateQueries([FavouriteProjectKeys.INDEX]),
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationArchiveProject = () => {
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: Type_put_project) => {
            return updateProject(
                formatterArchiveProject(newStatus),
                newStatus.id,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationArchiveProject");
            }

            await queryClient.invalidateQueries([ProjectKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationRemoveProject = () => {
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (id: number) => removeProject(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationRemoveProject");
            }

            await queryClient.invalidateQueries([ProjectKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useProjectCover = (
    project: Type_show_project | null,
): UseQueryResult => {
    const cachedName = project?.id ? `project_cover_${project.id}` : "";

    return getImageQuery(
        project?.id as number,
        Url.COMMONS,
        ProjectPath.PROJECTS,
        "cover",
        cachedName,
        !!project,
    );
};

export const useShowProject = (projectId?: number) => {
    return useQuery(
        [ProjectKeys.SHOW, projectId],
        () => showProject(projectId as number),
        {
            enabled: !!projectId,
            refetchOnWindowFocus: false,
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Wrong format data: useShowProjectForEdit");
                }

                return formatterShowProject(data.data.data);
            },
            onError: (err) => {
                return err;
            },
        },
    );
};

export const useShowProjectBySlug = (slug?: string) => {
    return useQuery({
        queryKey: [ProjectKeys.SLUG, slug?.toLocaleLowerCase() || ""],
        queryFn: () => getProjectBySlug(slug?.toLocaleLowerCase() || ""),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useShowProjectBySlug");
            }
            return formatterShowProject(data.data.data);
        },
        enabled: !!slug && slug.length > 0,
    });
};
