import { Box, FormControl, Slider, Stack, Typography } from "@mui/material";
import { SliderProps } from "@mui/material/Slider/Slider";
import * as React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";

type Type_Props_TextSizeSlider = SliderProps & {
    label: string;
};

export const TextSizeSlider = ({
    label,
    ...props
}: Type_Props_TextSizeSlider) => {
    return (
        <FormControl fullWidth>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant={"body1"}>{label}</Typography>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Icon
                        variant="solid"
                        icon="text"
                        fontSize="small"
                        sx={{ ml: 2 }}
                    />
                    <Slider
                        defaultValue={4}
                        step={1}
                        min={1}
                        max={7}
                        valueLabelDisplay="auto"
                        id={"textSize"}
                        sx={{ width: 250 }}
                        marks
                        {...props}
                    />
                    <Icon variant="solid" icon="text" fontSize="large" />
                </Stack>
            </Box>
        </FormControl>
    );
};
