import TabList from "@mui/lab/TabList";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import { GridProps } from "@mui/system";

import { COLORS } from "src/theme/stylesheet";

interface Styled_HeaderProps extends GridProps {
    ownerState?: {
        withTabs?: boolean;
    };
}

export const Styled_Header = styled(Grid)<Styled_HeaderProps>(
    ({ theme, ownerState }: any) => ({
        // backgroundColor: COLORS.gray100,
        // borderColor: COLORS.gray400,
        padding: ownerState?.withTabs
            ? theme.spacing(10, 10, 0, 10)
            : theme.spacing(8, 4, 0, 4),
        flexDirection: "column",
        // borderBottomWidth: 1,
    }),
);

export const Styled_TabList = styled(TabList)(() => ({
    span: {
        "&.MuiTabs-indicator": {
            backgroundColor: `${COLORS.blue600}`,
        },
    },
}));

export const Styled_HeaderTypography = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
}));

export const Styled_Tab = styled(Tab)(({ theme }: any) => ({
    minWidth: "0px",
    padding: "0px 0px",
    marginRight: theme.spacing(6),
    color: `${COLORS.moon900}`,
    "&.Mui-selected": {
        color: `${COLORS.blue600}`,
    },
}));
