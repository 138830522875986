import { typeMilestones } from "@cimba-digital-construction-solution/utils/dist/decoder/const";
import React from "react";
import { Group, Rect } from "react-konva";

import { colorFlowSelected } from "src/components/Components_Teamoty/Flow/Flow.const";
import { strokeWidthPlanningTaskSelected } from "src/components/Components_Teamoty/Planning/Planning.const";
import { Type_Props_PlanningShapeSelected } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

import { PlanningShapeGrip } from "./PlanningShapeGrip";

export const PlanningShapeSelected = ({
    x,
    y,

    width,
    height,

    task,

    selected,
    setSelected,

    getEndDate,
}: Type_Props_PlanningShapeSelected) => {
    return (
        <Group>
            <Rect
                x={x}
                y={y}
                width={width}
                height={height}
                stroke={colorFlowSelected}
                strokeWidth={strokeWidthPlanningTaskSelected}
                listening={false}
            />

            {/* Si la tache n'est pas un milestone, on affiche le grip */}
            {!typeMilestones.includes(selected.taskType) &&
                selected.link == undefined && (
                    <PlanningShapeGrip
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        task={task}
                        selected={selected}
                        setSelected={setSelected}
                        getEndDate={getEndDate}
                    />
                )}
        </Group>
    );
};
