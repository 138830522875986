import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { changePassword } from "src/api/tms-users/auth/services";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";

import { Page_ChangePasswordComponent } from "./Page_ChangePassword.component";

export type Type_changePasswordState = {
    password: string;
    passwordConfirm: string;
};

export type Type_requirements = {
    MinimumLength: boolean;
    Alphanumeric: boolean;
    MustBeSame: any;
};

const schemaChangePassword = Yup.object().shape({
    password: Yup.string()
        .trim()
        .required(FORM_ERR_FMT.REQUIRED)
        .min(16)
        .matches(/[0-9]/)
        .matches(/[a-zA-Z]/),
    passwordConfirm: Yup.string()
        .trim()
        .required(FORM_ERR_FMT.REQUIRED)
        .test("is-same", (value, context) => value === context.parent.password),
});

export const Page_ChangePasswordContainer = () => {
    const [params] = useSearchParams();
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const initialState: Type_changePasswordState = {
        password: "",
        passwordConfirm: "",
    };
    const [requirements, setRequirements] = useState<Type_requirements>({
        MinimumLength: false,
        Alphanumeric: false,
        MustBeSame: false,
    });
    const [isRequirementsComplete, setIsRequirementsComplete] =
        useState<boolean>(false);

    const handleSubmit = (values: Type_changePasswordState) => {
        void changePassword(
            values?.password,
            params.get("token") || "",
            params.get("email") || "",
        );
        setPasswordChanged(true);
    };

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: schemaChangePassword,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        setRequirements({
            MinimumLength: formik.values.password.length >= 16,
            Alphanumeric: /.*[a-zA-Z].*\d.*|.*\d.*[a-zA-Z].*/.test(
                formik.values.password,
            ),
            MustBeSame:
                formik.values.password === formik.values.passwordConfirm,
        });
    }, [formik.values]);

    useEffect(() => {
        setIsRequirementsComplete(
            requirements.Alphanumeric &&
                requirements.MinimumLength &&
                requirements.MustBeSame,
        );
    }, [requirements]);

    return (
        <Page_ChangePasswordComponent
            passwordChanged={passwordChanged}
            formik={formik}
            requirements={requirements}
            isRequirementsComplete={isRequirementsComplete}
        />
    );
};
