import { DatePickerProps } from "@mui/x-date-pickers-pro";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_DateTimeRangePicker } from "src/components/Components_Common/_MuiComponentsVariants";
import { Type_Props_DateTimeRangePickerProps } from "src/components/Components_Common/_MuiComponentsVariants/DateTimeRangePicker/DateTimeRangePicker";

type Type_Props_DateTimeRangePicker = Type_Props_DateTimeRangePickerProps &
    DatePickerProps<any> & {
        name: string;
    };

export const DateTimeRangePicker = ({
    name,
    ...restProps
}: Type_Props_DateTimeRangePicker) => {
    const { formState, control } = useFormContext(); // retrieve those props

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, ...field } }) => (
                <TMC_DateTimeRangePicker
                    {...restProps}
                    value={value ?? []}
                    {...field}
                    error={!!formState.errors[name]}
                    helperText={formState.errors[name]?.message as string}
                />
            )}
        />
    );
};
