import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { useDaysContext } from "src/components/Components_Common/Days/useDaysContext";
import { COLORS } from "src/theme/stylesheet";

export type Type_Props_DaysCheckbox = {
    direction?: "row" | "column";
    initialValue?: number;
    valueCallback?: (value: number) => void;
    dataTestIdPrefix: string;
    name: string;
};

export const DaysCheckbox = ({
    direction = "column",
    initialValue,
    valueCallback,
    dataTestIdPrefix,
    name,
}: Type_Props_DaysCheckbox) => {
    const { days, handleDayToggle, value, setValue } = useDaysContext({
        valueCallback,
        initialValue,
    });
    const { formState } = useFormContext() || {};

    useEffect(() => {
        // Update context if props initialValue change on form load data
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);

    return (
        <Box>
            <Stack direction={direction} sx={{ width: "70px" }}>
                {days.map(({ key, label, binary }) => (
                    <FormControlLabel
                        data-testid={`FormControl-${key}`}
                        key={key}
                        label={label}
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                color: formState?.errors[name]
                                    ? COLORS.warning
                                    : "",
                            },
                        }}
                        control={
                            <Checkbox
                                inputProps={{ "aria-label": label }}
                                value={binary}
                                checked={Boolean(value & binary)}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => handleDayToggle(binary, e.target.checked)}
                                data-testid={`${dataTestIdPrefix}-checkbox-${key}`}
                                sx={{
                                    color: formState?.errors[name]
                                        ? COLORS.warning
                                        : "",
                                }}
                            />
                        }
                    />
                ))}
            </Stack>
        </Box>
    );
};
