import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { AreaPath, Url } from "src/api/paths";
import {
    Type_com_post_areaType,
    Type_com_put_areaType,
} from "src/api/tms-commons/areaTypes/types";

export const indexAreaTypes = (): GetReturnType => {
    return get(`${Url.COMMONS}${AreaPath.AREA_TYPES}`);
};

export const selectListAreaTypes = (): GetReturnType => {
    return get(`${Url.COMMONS}${AreaPath.SELECT_LIST}`);
};

export const createAreaType = (
    data: Type_com_post_areaType,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${AreaPath.AREA_TYPES}`);
};

export const showAreaType = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${AreaPath.AREA_TYPES}/${id}`);
};

export const updateAreaType = (data: Type_com_put_areaType): PutReturnType => {
    return put(data, `${Url.COMMONS}${AreaPath.AREA_TYPES}/${data.id}`);
};

export const deleteAreaType = (id: number): RemoveReturnType => {
    return remove(`${Url.COMMONS}${AreaPath.AREA_TYPES}/${id}`);
};

export const statusAreaType = (data: Type_com_put_areaType): PutReturnType => {
    return put(data, `${Url.COMMONS}${AreaPath.AREA_TYPES}/${data.id}`);
};
