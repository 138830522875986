import {
    Type_com_index_resourceFamily,
    Type_com_post_resourceFamily,
    Type_com_put_resourceFamily,
    Type_com_selectList_resourceFamily,
    Type_com_show_resourceFamily,
    Type_index_resourceFamily,
    Type_post_resourceFamily,
    Type_put_resourceFamily,
    Type_selectList_resourceFamily,
    Type_show_resourceFamily,
} from "src/api/tms-commons/resourceFamilies/types";

export const formatterIndexResourceFamily = (
    data: Type_com_index_resourceFamily,
): Type_index_resourceFamily => {
    return {
        id: data.id,
        name: data.name,
        enabled: data.enabled,
    };
};

export const formatterIndexResourceFamilies = (
    resourceFamilies: Type_com_index_resourceFamily[],
): Type_index_resourceFamily[] => {
    if (!resourceFamilies) return [];

    return resourceFamilies.map(
        (resourceFamily: Type_com_index_resourceFamily) =>
            formatterIndexResourceFamily(resourceFamily),
    );
};

export const formatterShowResourceFamily = (
    data: Type_com_show_resourceFamily,
): Type_show_resourceFamily => {
    return {
        id: data.id,
        names: data.names,
    };
};

export const formatterUpdateResourceFamily = (
    data: Type_show_resourceFamily,
): Type_com_put_resourceFamily => {
    return {
        names: data.names,
    };
};

export const formatterPostResourceFamily = (
    data: Type_post_resourceFamily,
): Type_com_post_resourceFamily => {
    return {
        names: data.names,
    };
};

export const formatterStatusResourceFamily = (
    data: Type_put_resourceFamily,
): Type_com_put_resourceFamily => {
    return {
        enabled: data.enabled,
    };
};

export const formatterSelectListResourceFamilies = (
    resourceFamilies: Type_com_selectList_resourceFamily[],
): Type_selectList_resourceFamily[] => {
    if (!resourceFamilies) return [];

    return resourceFamilies.map(
        (resourceFamily: Type_com_index_resourceFamily) => {
            return {
                id: resourceFamily.id,
                name: resourceFamily.name,
                enabled: resourceFamily.enabled,
            };
        },
    );
};
