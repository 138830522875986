import Konva from "konva";

import { sizePlanningTaskSummaryTip } from "src/components/Components_Teamoty/Planning/Planning.const";

/**
 * Dessine une ligne horizontale avec des triangles aux extrémités.
 *
 * @param width - La largeur de la forme.
 * @param height - La hauteur de la forme.
 * @param context - Contexte de dessin Konva.
 * @param shape - Forme Konva à dessiner.
 */

export const planningShapeSummaryFunction = (
    width: number,
    height: number,
    context: Konva.Context,
    shape?: Konva.Shape,
): void => {
    const verticalOffset: number = height / 2;

    const tipSize: number = Math.round(height / sizePlanningTaskSummaryTip);

    if (width) {
        width = Math.round(width);
    }

    context.beginPath();

    context.moveTo(verticalOffset, 0);

    context.lineTo(tipSize, 0);
    context.lineTo(0, tipSize);
    context.lineTo(0, -1);
    context.lineTo(width, -1);
    context.lineTo(width, tipSize);
    context.lineTo(width - tipSize, 0);

    context.closePath();

    context.fill("nonzero");

    shape && context.fillStrokeShape(shape);
};
