export type SimpleInputCancelPropsType = {
    label: string;
    props?: any;
    onClick?: any;
};

export const SimpleInputCancel = (props: SimpleInputCancelPropsType) => {
    return (
        <button
            onClick={props.onClick}
            {...props}
            className={`bg-teamoty-secondary text-white px-4 py-2 rounded-md shadow-md`}
        >
            {props.label}
        </button>
    );
};
