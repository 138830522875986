import React from "react";

import { Type_RequestConfig } from "src/api/fetch";
import { useSelectListProjectUsers } from "src/api/tms-projects/projectUsers";
import { formatterAutoCompleteUser } from "src/api/tms-projects/projectUsers/formatters";
import { Type_selectList_projectUser } from "src/api/tms-projects/projectUsers/types";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteUsers = {
    name: string;
    label: string;
    projectId?: number;
    subProjectId?: number | null;
};

export const AutocompleteUsers = ({
    name,
    label,
    projectId,
    subProjectId,
}: Type_Props_AutocompleteUsers) => {
    const requestConfig: Type_RequestConfig = { projectId: projectId };

    if (subProjectId) requestConfig.subProjectId = subProjectId;

    const { isFetching, data } = useSelectListProjectUsers() || {};

    return (
        <Autocomplete
            name={name}
            label={label}
            isFetching={isFetching}
            multiple
            options={
                (data &&
                    formatterAutoCompleteUser(
                        data as Type_selectList_projectUser[],
                    )) ||
                []
            }
        />
    );
};
