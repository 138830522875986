import { usePlanningTheme } from "src/components/Components_Teamoty/Planning/Planning.const";
import { Type_planningShapeColorFunction } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

export const planningShapeMilestoneColorFunction = (
    color: string,
    isDone: boolean = false,
): Type_planningShapeColorFunction => {
    const theme = usePlanningTheme();

    if (isDone) {
        return {
            fill: theme.strokePlanningTaskDone,
            stroke: theme.strokePlanningTaskDone,
            textColor: theme.strokePlanningTaskDoneText,
            progress: theme.strokePlanningTaskDone,
        };
    }
    return {
        fill: color,
        stroke: theme.strokePlanningMilestone,
        textColor: theme.fontColorPlanningTask,
    };
};
