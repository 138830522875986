import { IntlShape, useIntl } from "react-intl";

type CoreIntlShape = Omit<IntlShape, "formatDate" | "formatTime"> & {
    formatMessageWithFullKey: (
        fullKey: string | null | undefined,
        config?: {
            defaultMessage?: string;
            values?: {
                [key: string]:
                    | string
                    | number
                    | boolean
                    | null
                    | undefined
                    | ((chunks: string) => any);
            };
        },
    ) => string;
    formatMessageWithPartialKey: (
        partialKey: string | null | undefined,
        config?: {
            defaultMessage?: string;
            values?: {
                [key: string]:
                    | string
                    | number
                    | boolean
                    | null
                    | undefined
                    | ((chunks: string) => any);
            };
        },
    ) => string;
    formatDate: (
        timestampDateTimeToFormat: number | undefined,
    ) => string | undefined;
    formatTime: (
        timestampDateTimeToFormat: number | undefined,
    ) => string | undefined;
    formatDateTime: (
        timestampDateTimeToFormat: number | undefined,
    ) => string | undefined;
};

export const useCoreIntl = (rootKey?: string) => {
    const intl = useIntl() as CoreIntlShape;

    const formatMessageWithFullKey = (
        fullKey: string | null | undefined,
        config?: {
            defaultMessage?: string;
            values?: {
                [key: string]:
                    | string
                    | number
                    | boolean
                    | null
                    | undefined
                    | ((chunks: string) => any);
            };
        },
    ) => {
        if (fullKey === "-") {
            return fullKey;
        }
        return intl.formatMessage(
            {
                id:
                    typeof fullKey === "string" && fullKey !== ""
                        ? fullKey
                        : " ",
                defaultMessage: config?.defaultMessage,
            },
            config?.values,
        );
    };

    const formatMessageWithPartialKey = (
        partialKey: string | null | undefined,
        config?: {
            defaultMessage?: string;
            values?: {
                [key: string]:
                    | string
                    | number
                    | boolean
                    | null
                    | undefined
                    | ((chunks: string) => any);
            };
        },
    ) => {
        if (partialKey === "-") {
            return partialKey;
        }
        return intl.formatMessage(
            {
                id: `${rootKey}.${partialKey}`,
                defaultMessage: config?.defaultMessage,
            },
            config?.values,
        );
    };

    const formatDate = (
        timestampDateTimeToFormat: number | undefined,
    ): string | undefined => {
        return timestampDateTimeToFormat
            ? new Date(timestampDateTimeToFormat).toLocaleDateString(
                  intl.locale,
              )
            : undefined;
    };

    const formatTime = (
        timestampDateTimeToFormat: number | undefined,
    ): string | undefined => {
        return timestampDateTimeToFormat
            ? new Date(timestampDateTimeToFormat).toLocaleTimeString(
                  intl.locale,
              )
            : undefined;
    };

    const formatDateTime = (
        timestampDateTimeToFormat: number | undefined,
    ): string | undefined => {
        return timestampDateTimeToFormat
            ? `${intl.formatDate(timestampDateTimeToFormat)} ${intl.formatTime(
                  timestampDateTimeToFormat,
              )}`
            : undefined;
    };

    intl.formatMessageWithFullKey = formatMessageWithFullKey;
    intl.formatMessageWithPartialKey = formatMessageWithPartialKey;
    intl.formatDate = formatDate;
    intl.formatTime = formatTime;
    intl.formatDateTime = formatDateTime;

    return intl;
};
