import * as React from "react";
import { ComponentProps } from "react";

import { useSelectListRoles } from "src/api/tms-users/rolesAndPermissions";
import { Type_selectList } from "src/api/types";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteRoles = Omit<
    ComponentProps<typeof Autocomplete>,
    "options" | "isFetching"
> & {
    name: string;
    label: string;
    multiple?: boolean;
};

export const AutocompleteRoles = ({
    name,
    label,
    multiple,
    ...props
}: Type_Props_AutocompleteRoles) => {
    const { data, isFetching } = useSelectListRoles() || {};

    return (
        <Autocomplete
            name={name}
            label={label}
            multiple={multiple}
            defaultValue={multiple ? [] : null}
            options={data as Type_selectList[]}
            isFetching={isFetching}
            {...props}
        />
    );
};
