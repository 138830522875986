import * as React from "react";
import {
    createRef,
    LegacyRef,
    ReactElement,
    ReactNode,
    useEffect,
    useState,
} from "react";
import useMeasure from "react-use-measure";

import { useMeasureDefaultConfig } from "src/configurations/app";
import {
    Styled_Bottom,
    Styled_BottomPanel,
    Styled_Center,
    Styled_ContainerX,
    Styled_ContainerY,
    Styled_HorizontalDivider,
    Styled_HorizontalDividerHitBox,
    Styled_Right,
} from "src/layouts/Layout_Resizable/Layout_Resizable.style";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

//////////////////////////////////
/// Config                     ///
//////////////////////////////////

const headerHeight = 88;
const minPlanningHeight = 60;
const MIN_HEIGHT = 120;
const MAX_HEIGHT = window.innerHeight - headerHeight + minPlanningHeight;

//////////////////////////////////

type Type_Props_LayoutResizable = {
    children: ReactNode;
    rightPanel?: ReactElement;
    bottomPanel?: ReactElement;
    centerRef?: LegacyRef<HTMLDivElement> | undefined;
    bottomRef?: LegacyRef<HTMLDivElement> | undefined;
};

export const Layout_Resizable = ({
    children,
    rightPanel,
    bottomPanel,
    centerRef,
    bottomRef,
    ...props
}: Type_Props_LayoutResizable) => {
    const localStorageKey: string = `previousPertDrawerHeight`;

    const defaultHeight: number =
        getLocalStorageItem(localStorageKey) ??
        (window.innerHeight - headerHeight) / 2;

    // Refs
    const splitYPaneRef = createRef<HTMLDivElement>();
    const [rightRef, rightBounds] = useMeasure(useMeasureDefaultConfig);

    // Container sizes
    const [bottomHeight, setBottomHeight] = useState<number>(defaultHeight);

    // Dividers positions

    // Mouse
    const [mouseYPosition, setMouseYPosition] = useState<undefined | number>(
        undefined,
    );
    const [dragging, setDragging] = useState(false);

    // Mouse events

    const onMove = (clientY: number) => {
        if (dragging) {
            onMoveBottomDivider(clientY);
        }
    };

    const onMoveBottomDivider = (clientY: number) => {
        if (dragging) {
            if (bottomHeight && mouseYPosition) {
                const newHeight = Math.min(
                    MAX_HEIGHT,
                    Math.max(
                        bottomHeight - clientY + mouseYPosition,
                        MIN_HEIGHT,
                    ),
                );

                setMouseYPosition(clientY);

                if (newHeight < MIN_HEIGHT) {
                    setBottomHeight(MIN_HEIGHT);
                    return;
                }

                if (newHeight > MAX_HEIGHT) {
                    setBottomHeight(MAX_HEIGHT);
                    return;
                }

                // Si l'on dépasse la largeur du composant parent, on arrête le déplacement
                if (splitYPaneRef.current) {
                    const splitPaneHeigth = splitYPaneRef.current.clientHeight;

                    if (newHeight > splitPaneHeigth - MIN_HEIGHT) {
                        setBottomHeight(splitPaneHeigth - MIN_HEIGHT);
                        return;
                    }
                }

                setBottomHeight(newHeight);
            }
        }
    };

    const onMouseDown = (e: React.MouseEvent) => {
        setMouseYPosition(e.clientY);
        setDragging(true);
    };

    const onMouseMove = (e: MouseEvent) => {
        if (dragging) {
            e.preventDefault();
            e.stopPropagation();
            onMove(e.clientY);
        }
    };

    const onMouseUp = () => {
        setMouseYPosition(undefined);
        setDragging(false);
    };

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);

        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };
    });

    useEffect((): void => {
        setLocalStorageItem(localStorageKey, bottomHeight);
    }, [bottomHeight]);

    return (
        <Styled_ContainerX data-testid="Layout_Resizable_ContainerX">
            <Styled_ContainerY
                ref={splitYPaneRef}
                data-testid="Layout_Resizable_ContainerY"
                style={{
                    width: `calc(100% - ${rightBounds?.width}px)`,
                }}
            >
                <Styled_Center
                    {...props}
                    ref={centerRef}
                    data-testid="Layout_Resizable_Center"
                >
                    {children}
                </Styled_Center>
                {bottomPanel && (
                    <Styled_Bottom
                        ref={bottomRef}
                        data-testid="Layout_Resizable_Bottom"
                    >
                        <Styled_HorizontalDividerHitBox
                            onMouseDown={(e) => onMouseDown(e)}
                            data-testid="HorizontalDividerHitBox"
                        >
                            <Styled_HorizontalDivider data-testid="HorizontalDivider" />
                        </Styled_HorizontalDividerHitBox>
                        <Styled_BottomPanel
                            height={bottomHeight}
                            setHeight={setBottomHeight}
                            data-testid="Layout_Resizable_BottomPanel"
                        >
                            {bottomPanel}
                        </Styled_BottomPanel>
                    </Styled_Bottom>
                )}
            </Styled_ContainerY>

            {rightPanel && (
                <Styled_Right
                    data-testid="Layout_Resizable_Right"
                    ref={rightRef}
                >
                    {rightPanel}
                </Styled_Right>
            )}
        </Styled_ContainerX>
    );
};
