import React, { useEffect, useState } from "react";

import { useSelectListTimeModels } from "src/api/tms-commons/timeModels";
import { mutationUpdateDefaultSettings } from "src/api/tms-projects/subProjects";
import { Type_put_default_subProject } from "src/api/tms-projects/subProjects/types";
import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import {
    formatSelectListToSelectOptionItem,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { DefaultSettingsForm } from "src/forms/defaultSettings/DefaultSettingsForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_DefaultSettingsDrawer = {
    formId: string;
    page: string;
    onClose: any;
    open: boolean;
    step: "sequence" | "version";
};

export const DefaultSettingsDrawer = ({
    formId,
    page,
    onClose,
    open,
    step,
}: Type_Props_DefaultSettingsDrawer) => {
    const [timeModelsOptions, setTimeModelsOptions] = useState<
        Type_SelectOptionItem[]
    >([]);

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.DefaultSettings.Header.Titles",
    );

    const { mutateAsync: updateSettingsMutation, isLoading: isUpdating } =
        mutationUpdateDefaultSettings();

    const { isFetching: isFetchingTimeModel, data: timeModels } =
        useSelectListTimeModels() || {};

    useEffect(() => {
        if (timeModels?.length) {
            setTimeModelsOptions(
                formatSelectListToSelectOptionItem(timeModels),
            );
        }
    }, [timeModels]);

    const handleSubmit = async (values: Type_put_default_subProject) => {
        try {
            await updateSettingsMutation(values);
            onClose();
        } catch (error) {
            console.error("Error updating settings:", error);
        }
    };

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`DefaultSettings-Drawer-Update-Title`}
            title={fmt("Update")}
            isFormLoading={isFetchingTimeModel || isUpdating}
            page={page}
            formId={formId}
        >
            <DefaultSettingsForm
                handleSubmit={handleSubmit}
                timeModels={timeModelsOptions}
                step={step}
            />
        </DrawerGeneric>
    );
};
