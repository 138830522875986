import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment, Stack, Typography } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateLicenseType,
    mutationUpdateLicenseType,
    useIndexLicenseTypesModes,
    useShowLicenseType,
} from "src/api/tms-commons/licenseTypes";
import { formatterButtonModes } from "src/api/tms-commons/licenseTypes/formatters";
import {
    Type_index_licenseTypeMode,
    Type_show_licenseType,
} from "src/api/tms-commons/licenseTypes/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { ToggleButtonGroup } from "src/components/Components_Common/forms/reactHookFormComponents/ToggleButtonGroup/ToggleButtonGroup";
import { AutocompleteRoles } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteRoles";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_LicenseTypesForm = {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
    updateId?: number | undefined;
    action: Type_action;
};

export const LicensesTypesForm = ({
    setIsLoading,
    onClose,
    updateId,
    action,
}: Type_Props_LicenseTypesForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.LicenseTypes",
    );
    const [hasMultipleRoles, setHasMultipleRoles] = useState<
        boolean | undefined
    >(undefined);

    const Schema_LicenseTypeForm = Yup.object().shape({
        names: Yup.lazy(() => inputMultiLanguagesSchema()),
        mode: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        duration: Yup.number()
            .typeError(FORM_ERR_FMT.MUST_BE_NUMBER)
            .integer(FORM_ERR_FMT.INTEGER)
            .max(1188, FORM_ERR_FMT.TOO_LONG)
            .positive(FORM_ERR_FMT.MUST_BE_POSITIF)
            .required(FORM_ERR_FMT.REQUIRED),
        roles: Yup.array().when("mode", (mode, schema) => {
            if (hasMultipleRoles) {
                return schema.min(1, FORM_ERR_FMT.REQUIRED);
            } else {
                return schema.nullable();
            }
        }),
        role: Yup.object()
            .nullable()
            .when("mode", (mode, schema: any) => {
                if (!hasMultipleRoles)
                    return schema.required(FORM_ERR_FMT.REQUIRED);
            }),
    });

    const { data, isFetching: isFetchingLicenseType } = useShowLicenseType(
        updateId as number,
    );

    const form = useTmcForm<Type_show_licenseType>({
        ...useFormDefaultConfig,
        defaultValues: {
            mode: undefined,
            duration: 1,
            names: {},
            roles: [],
        },
        values: data,
        resolver: yupResolver<any>(Schema_LicenseTypeForm),
        onClose,
        setIsLoading,
        action,
    });

    const modeWatch = useWatch({
        name: "mode",
        defaultValue: undefined,
        control: form.control,
    });

    const { data: modes, isFetching: isFetchingIndexLicenseTypesModes } =
        useIndexLicenseTypesModes() || {};
    const { mutateAsync: mutateCreate } = mutationCreateLicenseType() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateLicenseType() || {};

    const handleSubmit = async (values: any) => {
        values.roles = hasMultipleRoles
            ? values.roles.map((role: any) => role.id)
            : values.role
              ? [values.role.id]
              : [];

        try {
            if (action === "update") {
                await mutateUpdate(values);
            } else {
                await mutateCreate(values);
            }
            // Fermeture du drawer
            onClose();
        } catch (_e: any) {
            console.error("Error when creating or updating license type");
        }
    };

    const isFetching = useMemo(
        () => isFetchingIndexLicenseTypesModes || isFetchingLicenseType,
        [isFetchingIndexLicenseTypesModes, isFetchingLicenseType],
    );

    useEffect(() => {
        if (modes && modeWatch) {
            const mode = modes.find(
                (mode: Type_index_licenseTypeMode) => mode.id === modeWatch,
            );

            setHasMultipleRoles(mode?.hasMultipleRoles);
        } else if (form.getValues("mode")) {
            setHasMultipleRoles(form.getValues("hasMultipleRoles"));
        }
    }, [modeWatch]);

    return (
        <TmcFormProvider form={form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"licenseTypes"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack spacing={4}>
                        <ToggleButtonGroup
                            items={formatterButtonModes(modes || [])}
                            name="mode"
                            exclusive
                        />
                        <Typography variant="h3">
                            {fmt(`LicenseTypeName`)}
                        </Typography>
                        <InputMultiLanguages name="names" />
                        <TextField
                            variant="outlined"
                            name="duration"
                            label={fmt("Duration.Label")}
                            type="number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {fmt("Months")}
                                    </InputAdornment>
                                ),
                            }}
                            data-testid={"LicenseType-DrawerContent-duration"}
                        />
                        <AutocompleteRoles
                            label={fmt("RoleType")}
                            name="roles"
                            multiple={true}
                            renderInputProps={{ variant: "outlined" }}
                            sx={{
                                display: hasMultipleRoles ? "block" : "none",
                            }}
                        />
                        <AutocompleteRoles
                            label={fmt("RoleType")}
                            name="role"
                            multiple={false}
                            renderInputProps={{ variant: "outlined" }}
                            sx={{
                                display: !hasMultipleRoles ? "block" : "none",
                            }}
                        />
                    </Stack>
                )}
            </form>
        </TmcFormProvider>
    );
};
