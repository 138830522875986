import {
    Type_post_newPassword,
    Type_post_resetPassword,
    Type_usr_post_newPassword,
    Type_usr_post_resetPassword,
} from "src/api/tms-users/auth/types";

export const formatterResetPassword = (
    data: Type_post_resetPassword,
): Type_usr_post_resetPassword => {
    return {
        email: data.email,
    };
};

export const formatterNewPassword = (
    data: Type_post_newPassword,
): Type_usr_post_newPassword => {
    return {
        token: data.token,
        password: data.password,
    };
};
