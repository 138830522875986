// TODO move to utils
/**
 * @deprecated use enum from utils
 */
export enum orientation {
    LANDSCAPE = "landscape",
    PORTRAIT = "portrait",
}

// TODO move to utils
/**
 * @deprecated use enum from utils
 */
export enum captionPosition {
    TOP_LEFT = "topLeft",
    TOP_RIGHT = "topRight",
    BOTTOM_LEFT = "bottomLeft",
    BOTTOM_RIGHT = "bottomRight",
    SEPARATE_PAGE = "separatePage",
}

/**
 * Will be moved to fonction in Cimba-Digital-Construction-Solution/utils
 */
export const defaultPrintSettings: Type_printSettings = {
    paperFormat: "A3",
    orientation: orientation.LANDSCAPE,
    timelineType: "week",
    displayCaption: false,
    captionPosition: captionPosition.SEPARATE_PAGE,
    displayCaptionKeydates: true,
};

export type Type_printSettings = {
    paperFormat: string;
    orientation: orientation;
    timelineType: string;
    displayCaption: boolean;
    captionPosition: captionPosition;
    displayCaptionKeydates: boolean;
};

export type Type_prt_post_print = {
    size: string;
    layout: orientation;
    timelineType: string;
    withWeekend: boolean;
    withDaysOff: boolean;
    displayCaption: boolean;
    captionPosition: captionPosition;
    displayCaptionKeydates: boolean;
    callDate: string; // Date with format YYYY-MM-DDTHH:mm:ss (ISO 8601)
};

export type Type_post_print = {
    paperFormat: string;
    orientation: orientation;
    timelineType: string;
    withWeekend: boolean;
    withDaysOff: boolean;
    displayCaption: boolean;
    captionPosition: captionPosition;
    displayCaptionKeydates: boolean;
    callDate: string; // Date with format YYYY-MM-DDTHH:mm:ss (ISO 8601)
};
