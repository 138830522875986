// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { Stack, StackProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const StyledEmpty = styled(Stack)<StackProps>(({ theme }) => ({
    border: `${theme.spacing(0.3)} dashed ${COLORS.gray500}`,
    padding: theme.spacing(6),
    borderRadius: theme.shape?.borderRadiusLarge,
    height: "100%",
}));
