import { AxiosRequestConfig } from "axios";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { Url, UsersPath } from "src/api/paths";
import {
    Type_usr_post_user,
    Type_usr_put_user,
} from "src/api/tms-users/users/types";

export const indexUsers = (
    requestConfig?: Type_RequestConfig,
    configParams?: AxiosRequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.USERS}${UsersPath.USERS}`,
        requestConfig,
        null,
        signal,
        configParams,
    );
};

export const createUser = (data: Type_usr_post_user): PostReturnType => {
    return post(data, `${Url.USERS}${UsersPath.USERS}`);
};

export const checkToken = (token: string): PostReturnType => {
    return post({ token }, `${Url.USERS}${UsersPath.CHECK_TOKEN}`);
};

export const updateUser = (
    id: number,
    data: Type_usr_put_user,
): PutReturnType => {
    return put(data, `${Url.USERS}${UsersPath.USERS}/${id}`);
};

export const deleteUser = (id: number): RemoveReturnType => {
    return remove(`${Url.USERS}${UsersPath.USERS}/${id}`);
};
