import { useState } from "react";

import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { CreateProjectForm } from "src/forms/project/CreateProjectForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_CreateProjectDrawer = {
    open: boolean;
    onClose: () => void;
    page: string;
};

export const CreateProjectDrawer = ({
    open,
    onClose,
    page,
}: Type_Props_CreateProjectDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Projects");

    const [isLoading, setIsLoading] = useState(false);
    const formId = "createProject";

    return (
        <DrawerGeneric
            onClose={onClose}
            open={open}
            page={page}
            formId={formId}
            title={fmt("Title")}
            isFormLoading={isLoading}
        >
            <CreateProjectForm
                formId={formId}
                onClose={onClose}
                setIsLoading={setIsLoading}
            />
        </DrawerGeneric>
    );
};
