import { GridColDef } from "@mui/x-data-grid-premium";

import { LANGUAGE_NAME_DIVIDER } from "src/api/formatters";

type Type_Props_MultiLanguesColumn = GridColDef & {
    languages: { locale: string }[] | null;
};

export const multiLanguesColumns = ({
    field,
    headerName,
    languages,
    editable,
}: Type_Props_MultiLanguesColumn): GridColDef[] => {
    return (
        languages?.map(({ locale: language }) => {
            return {
                field: `${field}${LANGUAGE_NAME_DIVIDER}${language}`,
                headerName: `${headerName} (${language})`,
                editable: editable,
                type: "string",
                display: "flex",
                flex: 1,
            };
        }) || []
    );
};
