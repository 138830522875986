import { GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_punchlistItemCause } from "src/api/tms-commons/punchlistItemCauses/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { PunchlistItemCauseDrawer } from "src/drawers/punchlistItemCauses/PunchlistItemCauseDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeletePunchlistItemCause } from "./Page_PunchlistItemCauses.container";

export type Type_PagePunchlistItemCausesComponent = {
    punchlistItemCauses: Type_index_punchlistItemCause[];
    removePunchListItemCause: (id: number) => void;
    changeStatus: (punchlistItemCause: Type_index_punchlistItemCause) => void;
    modalDeletePunchlistItemCause: Type_modalDeletePunchlistItemCause;
    setModalDeletePunchlistItemCause: Dispatch<
        SetStateAction<Type_modalDeletePunchlistItemCause>
    >;
    isFetching: boolean;
};

export const Page_PunchlistItemCausesComponent = ({
    punchlistItemCauses,
    removePunchListItemCause,
    changeStatus,
    modalDeletePunchlistItemCause,
    setModalDeletePunchlistItemCause,
    isFetching,
}: Type_PagePunchlistItemCausesComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.PunchlistItemCauses",
    );
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "punchlistItemCauses";

    const [selectedCause, setSelectedCause] = useState<number | null>(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleCauseCreate = () => {
        setSelectedCause(null);
        setAction("create");
        setOpenDrawer(true);
    };
    const handleCauseEdit = (id: number) => {
        setSelectedCause(id);
        setAction("update");
        setOpenDrawer(true);
    };

    const handleCauseDuplicate = (id: number) => {
        setSelectedCause(id);
        setAction("duplicate");
        setOpenDrawer(true);
    };
    const handleCloseCauseDrawer = () => {
        setOpenDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Causes"),
            sortable: false,
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleCauseEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) =>
        handleCauseDuplicate(row.id);

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_punchlistItemCause);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeletePunchlistItemCause({
            isOpen: true,
            props: row as Type_index_punchlistItemCause,
        });
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeletePunchlistItemCause({ isOpen: false });
                removePunchListItemCause(
                    modalDeletePunchlistItemCause?.props?.id as number,
                );
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PagePunchlistItemCauses-AddOne-btn"
                    onClick={() => handleCauseCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={punchlistItemCauses}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleCauseCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleCauseEdit(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />
            <PunchlistItemCauseDrawer
                open={openDrawer}
                onClose={handleCloseCauseDrawer}
                id={selectedCause}
                action={action}
                page={page}
            />
            <ModalDelete
                open={modalDeletePunchlistItemCause.isOpen}
                onClose={() =>
                    setModalDeletePunchlistItemCause({ isOpen: false })
                }
                item={fmt("DataName")}
                validationString={
                    modalDeletePunchlistItemCause?.props?.name as string
                }
                actions={actions}
            />
        </>
    );
};
