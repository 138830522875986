import { Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { useIndexTaskAreaPeople } from "src/api/tms-scheduling/peopleTaskArea";
import { Type_index_peopleTaskArea } from "src/api/tms-scheduling/peopleTaskArea/types";
import { Empty, UserSmallCard } from "src/components";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { hasKey } from "src/utils/object";
import { sortData } from "src/utils/sortData";

type Type_Props_PeopleTaskAreaList = {
    peopleTaskAreas: Type_index_peopleTaskArea[];
};

const PeopleTaskList = ({ peopleTaskAreas }: Type_Props_PeopleTaskAreaList) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    const sortedPeopleTaskArea: Type_index_peopleTaskArea[] = useMemo(
        () => sortData(peopleTaskAreas, "firstName"),
        [peopleTaskAreas],
    );

    return (
        <Box display="flex" flexDirection="column" gap={1} paddingBottom={2}>
            {sortedPeopleTaskArea?.map(
                (assignee: Type_index_peopleTaskArea) => (
                    <UserSmallCard
                        id={assignee.id}
                        firstName={assignee.firstName}
                        lastName={assignee.lastName}
                        email={assignee.email}
                        key={`peopleTask_${assignee.id}`}
                        badgeLabel={
                            hasKey(assignee, "userId") || assignee?.userId
                                ? undefined
                                : fmt("Ext")
                        }
                        sx={{ paddingX: 0 }}
                    />
                ),
            )}
        </Box>
    );
};

type Type_Props_PeopleTaskArea = Type_ReadonlyComponent & {
    taskId: number;
    areaId: number;
    taskAreaId: number;
};

export const PeopleTaskArea = ({
    taskId,
    areaId,
    taskAreaId,
    readonly,
}: Type_Props_PeopleTaskArea) => {
    const [peopleTaskAreas, setPeopleTaskAreas] = useState<
        Type_index_peopleTaskArea[]
    >([]);

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    const { openPaper } = useContextualDrawer();

    const { isFetching, data } = useIndexTaskAreaPeople(
        {
            areaId: areaId,
            taskId: taskId,
            taskAreaId: taskAreaId,
        },
        MEDIUM_STALE_TIME,
    );

    useEffect(() => {
        if (data) {
            setPeopleTaskAreas(data);
        }
    }, [data]);

    const handleNewPeopleTaskArea = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        openPaper("peopleTaskArea", {
            taskId: taskId,
            taskAreaId: taskAreaId,
            areaId: areaId,
            focus: false,
        });
    };

    return isFetching ? (
        <FullSpinner />
    ) : taskAreaId && peopleTaskAreas?.length ? (
        <PeopleTaskList peopleTaskAreas={peopleTaskAreas} />
    ) : (
        <Empty
            message={fmt("EmptyPeopleTaskMessage")}
            {...(!readonly && {
                actionMessage: fmt("AssignSomeone"),
                onClick: handleNewPeopleTaskArea,
            })}
            sx={{ marginBottom: 2 }}
            dataTestIdRef="PeopleTask"
        />
    );
};
