import { Tooltip } from "@mui/material";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { TopBarRight_IconButton } from "src/components/Components_Teamoty/TopBar/stylesheet";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_LanguageMenuButton = {
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
};
export const LanguageMenuButton = ({
    handleClick,
}: Type_Props_LanguageMenuButton) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("TopBar.Language");

    return (
        <Tooltip title={fmt("Languages")}>
            <TopBarRight_IconButton
                size="small"
                aria-haspopup="true"
                color="inherit"
                onClick={handleClick}
                data-testid="LanguageMenu-open-btn"
                aria-controls={"language-menu"}
            >
                <Icon icon="globe" variant="light" />
            </TopBarRight_IconButton>
        </Tooltip>
    );
};
