import { Box, Stack } from "@mui/material";
import { Identifier } from "dnd-core";
import { memo, useState } from "react";
import type { Dispatch, FC, ReactElement, SetStateAction } from "react";
import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";

import { ReactDnD_SortableListPoc } from "src/components/Components_Common/DragAndDrop/ReactDnd_SortableList.poc";
import { COLORS } from "src/theme/stylesheet";

type Type_Data_DragItem = {
    id: number;
    name: string;
};

export type Type_DropItem = {
    id: number;
    name: string;
    uniqId: string;
};

type Type_Props_DropArea = {
    data: Type_DropItem[];
    setData: Dispatch<SetStateAction<Type_DropItem[]>>;
};

interface Interface_Props_DragItem {
    id: number;
    name: string;
    endCallback: (data: Type_Data_DragItem) => void;
}

interface DropResult {
    name: string;
}

const ItemTypes: { DRAG_ITEM: string } = {
    DRAG_ITEM: "dragItem",
};

const DragElement = ({
    id,
    name,
    endCallback,
}: Interface_Props_DragItem): ReactElement => {
    const [, drag] = useDrag(() => ({
        type: ItemTypes.DRAG_ITEM,
        item: { id, name },
        end: (
            item: Type_Data_DragItem,
            monitor: DragSourceMonitor<Type_Data_DragItem>,
        ): void => {
            const dropResult: DropResult | null =
                monitor.getDropResult<DropResult>();
            if (item && dropResult) {
                endCallback({ id: item.id, name: item.name });
            }
        },
        collect: (
            monitor,
        ): { isDragging: boolean; handlerId: Identifier | null } => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));

    return (
        <Box
            ref={drag}
            data-testid="label"
            sx={{
                border: `2px solid ${COLORS.black}`,
                cursor: "move",
                padding: 2,
            }}
        >
            {name}
        </Box>
    );
};

const DropArea = ({ data, setData }: Type_Props_DropArea) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.DRAG_ITEM,
        drop: (): { name: string } => ({ name: "DropArea" }),
        collect: (monitor): { canDrop: boolean; isOver: boolean } => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const isActive: boolean = canDrop && isOver;
    let backgroundColor: string = COLORS.gray400;
    if (isActive) {
        backgroundColor = COLORS.gray500;
    } else if (canDrop) {
        backgroundColor = COLORS.gray400;
    }

    return (
        <Box
            ref={drop}
            data-testid="DropArea"
            sx={{
                minHeight: "100%",
                height: "fit-content",
                width: "24rem",
                padding: "1rem",
                backgroundColor,
            }}
        >
            <ReactDnD_SortableListPoc data={data} setData={setData} />
        </Box>
    );
};

export const ReactDnDPoc: FC = memo(function ReactDnDPoc(): ReactElement {
    const [data, setData] = useState<Type_DropItem[]>([]);

    const handleDrop = ({ id, name }: Type_Data_DragItem): void => {
        setData((prevData: Type_DropItem[]) => [
            ...prevData,
            { id, name, uniqId: Date.now() as unknown as string },
        ]);
    };

    return (
        <Stack gap={4} direction={"row"} padding={2}>
            <Box sx={{ overflow: "hidden", clear: "both" }}>
                <DropArea data={data} setData={setData} />
            </Box>
            <Stack rowGap={2} sx={{ overflow: "hidden", clear: "both" }}>
                <DragElement id={1} name="input A" endCallback={handleDrop} />
                <DragElement id={2} name="input B" endCallback={handleDrop} />
                <DragElement id={3} name="input C" endCallback={handleDrop} />
            </Stack>
        </Stack>
    );
});
