import { Components } from "@mui/material";

export const MuiDrawerTheme: Components["MuiDrawer"] = {
    styleOverrides: {
        root: ({ theme }: any) => ({
            margin: theme.spacing(4),
        }),
        paper: ({ theme }: any) => ({
            position: "absolute",
            borderRadius: theme.shape.borderRadius,
            width: "720px",
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)",
        }),
    },
};
