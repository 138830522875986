import { BaseSyntheticEvent, Dispatch, SetStateAction, useMemo } from "react";
import {
    FieldValues,
    FormProvider,
    SubmitHandler,
    useForm,
    UseFormProps,
    UseFormReturn,
} from "react-hook-form";

import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";

interface UseTmcFormProps<T extends FieldValues> extends UseFormProps<T> {
    onClose?: () => void;
    setIsLoading?: Dispatch<SetStateAction<boolean>>;
    action?: Type_action;
}

// Attention: Utiliser TmcFormProvider, à la place de FormProvider
export const useTmcForm = <T extends FieldValues>({
    onClose,
    setIsLoading,
    action,
    ...formProps
}: UseTmcFormProps<T>) => {
    const form = useForm<T>({
        ...formProps,
    });

    const isDirty = useMemo(
        () => form.formState.isDirty,
        [form.formState.isDirty],
    );

    const handleSubmit = (onSubmit: SubmitHandler<T>) =>
        form.handleSubmit(async (values: T, e?: BaseSyntheticEvent) => {
            e?.preventDefault();

            // On doit pouvoir submit une copie d'un show sans qu'aucun champ n'ait été modifié
            // Valable uniquement pour le "duplicate"
            if (!isDirty && !(action == "duplicate")) {
                onClose?.();
                return;
            }

            setIsLoading?.(true);
            await onSubmit(values, e);
            setIsLoading?.(false);
        });

    return { ...form, handleSubmit };
};

interface TmcFormProviderProps<T extends FieldValues> {
    form: UseFormReturn<T>;
    children: React.ReactNode;
}

export const TmcFormProvider = <T extends FieldValues>({
    form,
    children,
}: TmcFormProviderProps<T>) => {
    return <FormProvider {...form}>{children}</FormProvider>;
};
