import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { SequenceNoteKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateSequenceNote,
    formatterIndexSequenceNotes,
    formatterUpdateSequenceNote,
} from "src/api/tms-scheduling/sequenceNotes/formatters";
import {
    createSequenceNote,
    deleteSequenceNote,
    indexSequenceNotes,
    updateSequenceNote,
} from "src/api/tms-scheduling/sequenceNotes/services";
import {
    Type_post_sequenceNote,
    Type_sch_index_sequenceNote,
} from "src/api/tms-scheduling/sequenceNotes/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { Type_put_note } from "src/forms/notes/NoteForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexSequenceNotes = (sequenceConfig: Type_RequestConfig) => {
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...sequenceConfig };

    return useQuery({
        queryKey: [SequenceNoteKeys.INDEX, config],
        queryFn: () => indexSequenceNotes(config),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId &&
            !!sequenceConfig.sequenceId,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexSequenceNotes");
            }
            return formatterIndexSequenceNotes(
                data.data.data as Type_sch_index_sequenceNote[],
            );
        },
    });
};

export const mutationCreateSequenceNote = (
    sequenceConfig: Type_RequestConfig,
) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const config = { ...requestConfig, ...sequenceConfig };

    return useMutation({
        mutationFn: (newSequenceNote: Type_post_sequenceNote) =>
            createSequenceNote(
                formatterCreateSequenceNote(newSequenceNote),
                config,
            ),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateSequenceNote",
                );
            }
            await queryClient.invalidateQueries([
                SequenceNoteKeys.INDEX,
                config,
            ]);
            addSuccess({
                description: fmt("ToastSuccessCreate"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateSequenceNote = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (updatedSequenceNote: Type_put_note) =>
            updateSequenceNote(
                updatedSequenceNote.id,
                formatterUpdateSequenceNote(updatedSequenceNote),
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateSequenceNote",
                );
            }
            addSuccess({
                description: fmt("ToastSuccessUpdate"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteSequenceNote = (
    sequenceConfig: Type_RequestConfig,
) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const config = { ...requestConfig, ...sequenceConfig };

    return useMutation({
        mutationFn: (sequenceNoteId: number) =>
            deleteSequenceNote(sequenceNoteId, requestConfig),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationDeleteSequenceNote",
                );
            }
            await queryClient.invalidateQueries([
                SequenceNoteKeys.INDEX,
                config,
            ]);
            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};
