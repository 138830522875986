import { Box, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useTaskType } from "src/hooks/task/useTaskType";

type Type_Props_PlanningFilterTaskType = {
    name: string;
};

export const PlanningFilterTaskType = ({
    name,
}: Type_Props_PlanningFilterTaskType) => {
    const { control } = useFormContext();
    const { taskTypeList } = useTaskType({ iconKitVariant: "light" });

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
                const handleChipClick = (taskTypeValue: string | number) => {
                    // If the task type is already selected, remove it; otherwise, add it.
                    const updatedValue = value.includes(taskTypeValue)
                        ? value.filter((v: string) => v !== taskTypeValue)
                        : [...value, taskTypeValue];
                    onChange(updatedValue);
                };

                return (
                    <Stack
                        display="flex"
                        direction={"row"}
                        flexWrap={"wrap"}
                        gap={3}
                    >
                        {taskTypeList.map((taskType) => {
                            const isSelected = value.includes(taskType.slug);

                            return (
                                <Box key={taskType.value}>
                                    <TMC_Chip
                                        data-testid={`taskType-${taskType.value}`}
                                        clickable
                                        label={taskType.label}
                                        icon={
                                            <Icon
                                                icon={taskType.icon.icon}
                                                variant={taskType.icon.variant}
                                            />
                                        }
                                        color={
                                            isSelected ? "primary" : "default"
                                        }
                                        onClick={() =>
                                            handleChipClick(taskType.slug)
                                        }
                                    />
                                </Box>
                            );
                        })}
                    </Stack>
                );
            }}
        />
    );
};
