import {
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
} from "@mui/material";
import { UseTreeItem2LabelSlotProps } from "@mui/x-tree-view/useTreeItem2/useTreeItem2.types";
import { useState } from "react";

import {
    Type_index_sequenceFolderTree_sequence,
    Type_sequenceFolderTree_item,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SequenceBadge } from "src/components/Components_Teamoty/badges/SequenceBadge";
import { Styled_TreeItem2Label } from "src/drawers/sequences/SequencesContextualDrawer/SequencesTreeItem";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_SequencesTreeItemLabelSequence = Omit<
    UseTreeItem2LabelSlotProps,
    "children"
> & {
    item: Type_index_sequenceFolderTree_sequence;
};

export const SequencesTreeItemLabelSequence = ({
    item,
    ...treeItemProps
}: Type_Props_SequencesTreeItemLabelSequence) => {
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders",
    );

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleClickMenuItem = (
        e: React.MouseEvent<HTMLLIElement>,
        item: Type_sequenceFolderTree_item,
    ) => {
        handleClose(e);
        sendEvent("openDialogFolder", {
            open: true,
            data: { name: "duplicateSequence", ...item },
        });
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleSendEvent = (
        e: React.MouseEvent<HTMLLIElement>,
        sendCmd: string,
    ) => {
        handleClose(e);
        sendEvent(sendCmd, {
            item: item,
        });
    };

    return (
        <Styled_TreeItem2Label {...treeItemProps}>
            <SequenceBadge
                sequence={item as Type_index_sequenceFolderTree_sequence}
            />
            <Box className={"buttonGroup"}>
                <IconButton
                    data-testid="sequence-more-btn"
                    aria-describedby={id}
                    onClick={(e) => handleClick(e)}
                    name={"moreOptionsSequenceBtn"}
                    type="button"
                    size="small"
                    title={fmt("Sequence.MoreOptions")}
                >
                    <Icon
                        variant="light"
                        icon="ellipsis-vertical"
                        fontSize="small"
                    />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuList
                        sx={(theme) => ({
                            minWidth: "320px",
                            color: theme.palette.text.primary,
                        })}
                    >
                        {item.enabled && (
                            <MenuItem
                                onClick={(e) => handleClickMenuItem(e, item)}
                                data-testid="sequences-more-copy-btn"
                            >
                                <ListItemIcon>
                                    <Icon
                                        variant="light"
                                        icon="copy"
                                        data-testid="sequences-more-copy-icon"
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    {fmt("Sequence.More.Duplicate")}
                                </ListItemText>
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={(e) =>
                                handleSendEvent(e, "changeStatusSequence")
                            }
                            data-testid="sequences-more-disable-btn"
                        >
                            <ListItemIcon>
                                <Icon
                                    variant="light"
                                    icon="circle-dashed"
                                    data-testid="sequences-more-disable-icon"
                                />
                            </ListItemIcon>
                            <ListItemText>
                                {fmt(
                                    item.enabled
                                        ? "Sequence.More.Disable"
                                        : "Sequence.More.Enable",
                                )}
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={(e) =>
                                handleSendEvent(e, "openModalMatrixSequence")
                            }
                            data-testid="sequences-more-matrix-btn"
                        >
                            <ListItemIcon>
                                <Icon
                                    variant="light"
                                    icon="table"
                                    data-testid="sequences-more-matrix-icon"
                                />
                            </ListItemIcon>
                            <ListItemText>
                                {fmt("Sequence.More.BulkTaskEdit")}
                            </ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={(e) =>
                                handleSendEvent(e, "openModalDeleteSequence")
                            }
                            data-testid="sequences-more-delete-btn"
                            sx={(theme) => ({
                                color: theme.palette.error.main,
                            })}
                        >
                            <ListItemIcon>
                                <Icon
                                    variant="light"
                                    icon="trash"
                                    data-testid="sequences-more-delete-icon"
                                />
                            </ListItemIcon>
                            <ListItemText>
                                {fmt("Sequence.More.Delete")}
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Popover>
            </Box>
        </Styled_TreeItem2Label>
    );
};
