import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    AutocompleteValue,
    Chip,
    Skeleton,
} from "@mui/material";
import { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import { ChipTypeMap } from "@mui/material/Chip";
import * as React from "react";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { countryCodeLanguageRtl } from "src/assets/translations";
import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export type Type_Props_AutocompleteFreeSolo<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    "renderInput"
> &
    Type_ReadonlyComponent & {
        isFetching?: boolean;
        name: string;
        label?: string;
        renderInputProps?: any;
        formatterValue?: (value: any[]) => Promise<any[]>;
    };

const renderOptionDefault = (props: any, option: any) => (
    <li
        {...props}
        key={props.key}
        data-testid={`${props.id.slice(0, props.id.lastIndexOf("-"))}-${option}`}
    >
        {option}
    </li>
);

const renderTagsDefault = (
    tagValue: any[],
    getTagProps: AutocompleteRenderGetTagProps,
) => {
    return tagValue.map((option, index: number) => (
        // eslint-disable-next-line react/jsx-key
        <Chip
            label={option}
            data-testid={`Autocomplete-${option.replaceAll(" ", "_")}`}
            {...getTagProps({ index })}
            key={`${option}-${index}`}
        />
    ));
};

/**
 *
 * @formatterValue {function} - Permet de formatter le OnChange lorsque les option sont des objets
 */

export const AutocompleteFreeSolo = <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({
    isFetching = false,
    name,
    label,
    defaultValue,
    renderOption = renderOptionDefault,
    renderTags = renderTagsDefault,
    renderInputProps = {
        variant: "standard",
    },
    freeSolo = true as FreeSolo,
    multiple = true as Multiple,
    formatterValue,
    readonly,
    ...restProps
}: Type_Props_AutocompleteFreeSolo<
    T,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
>) => {
    const [open, setOpen] = useState(false);

    const { formState, control } = useFormContext(); // retrieve those props

    const isRtlLang = countryCodeLanguageRtl.includes(
        getLocalStorageItem("language"),
    );

    return (
        <>
            {isFetching ? (
                <Skeleton variant="rounded" height={60} />
            ) : (
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue ?? null}
                    render={({ field: { ref, onChange, onBlur, value } }) => (
                        <Autocomplete
                            {...restProps}
                            id={`Autocomplete-${name}`}
                            data-testid={`Autocomplete-${name}`}
                            open={open}
                            onOpen={(event) => {
                                restProps?.onOpen && restProps?.onOpen(event);
                                setOpen(true);
                            }}
                            onClose={(event, reason) => {
                                restProps?.onClose &&
                                    restProps?.onClose(event, reason);
                                setOpen(false);
                            }}
                            renderInput={(
                                params: AutocompleteRenderInputParams,
                            ) => {
                                return (
                                    <TMC_TextField
                                        {...params}
                                        {...renderInputProps}
                                        inputRef={ref} // RHF
                                        label={label}
                                        inputProps={{
                                            ...params.inputProps,
                                            onBlur: (_event) => {
                                                onBlur();
                                                setOpen(false); // Ferme les options de l autocomplete
                                            },
                                            "data-testid": `Autocomplete-${name}-input`,
                                        }}
                                        // RHF
                                        error={!!formState.errors[name]}
                                        helperText={
                                            formState.errors[name] ? (
                                                <FormHelperMessage
                                                    inputName={name as string}
                                                    message={
                                                        formState.errors?.[name]
                                                            ?.message as string
                                                    }
                                                    isRtlLang={isRtlLang}
                                                />
                                            ) : null
                                        }
                                    />
                                );
                            }}
                            renderTags={renderTags}
                            renderOption={renderOption}
                            // RHF
                            value={value || (multiple ? [] : null)}
                            onChange={
                                (async (
                                    _event: React.SyntheticEvent,
                                    value: AutocompleteValue<
                                        T,
                                        true,
                                        false,
                                        true
                                    >,
                                ) => {
                                    if (formatterValue)
                                        onChange(
                                            await formatterValue(
                                                value as (string | T)[],
                                            ),
                                        );
                                    else onChange(value);
                                }) as any
                            }
                            freeSolo={freeSolo}
                            multiple={multiple}
                            disabled={readonly || restProps.disabled}
                        />
                    )}
                />
            )}
        </>
    );
};
