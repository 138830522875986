import {
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    InputAdornment,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

import { useIndexDrawings } from "src/api/tms-projects/drawing";
import { Type_index_drawing } from "src/api/tms-projects/drawing/types";
import { LoadingOrNoData, TMC_Button, TMC_TextField } from "src/components";
import {
    emptyResource,
    Type_Resource,
} from "src/components/Components_Common/forms/InputFile";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { DrawingCard } from "src/components/Components_Teamoty/cards/DrawingCard";
import { useUser } from "src/contexts/user";
import { DrawingDrawer } from "src/drawers/drawings/DrawingDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_Accordion,
    Styled_DrawingsTopBar,
    Styled_Grid,
} from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Drawings/Page_Drawings/Page_Drawings.style";

export const Page_Drawings = () => {
    const { hasPermission } = useUser();
    const CAN_CREATE_UPDATE = hasPermission("drawings_create");

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Drawings",
    );
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Actions");

    ////////////////////////////////////////////
    // ---- STATES                          ----
    ////////////////////////////////////////////
    const [drawingImage, setDrawingImage] =
        useState<Type_Resource>(emptyResource);

    const [openDrawingDrawer, setOpenDrawingDrawer] = useState(false);

    ////////////////////////////////////////////
    // ---- QUERIES AND MUTATIONS           ----
    ////////////////////////////////////////////

    // Fetch data for Drawings list
    const { isFetching, refetch, data: drawings } = useIndexDrawings();

    const drawingsList = [
        {
            id: 1,
            label: "Directory Test",
            drawings: drawings || [],
        },
    ];

    ////////////////////////////////////////////
    // ---- FUNCTIONS                       ----
    ////////////////////////////////////////////

    const handleCloseDrawing = () => {
        setOpenDrawingDrawer(false);
        setDrawingImage(emptyResource);
        refetch();
    };

    return (
        <Box sx={{ height: "100%" }}>
            <Styled_DrawingsTopBar>
                <TMC_TextField
                    name="search"
                    placeholder="Search..."
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon
                                    variant="solid"
                                    icon="search"
                                    fontSize="small"
                                />
                            </InputAdornment>
                        ),
                    }}
                    data-testid="Page_Drawings_TopBar_SearchInput"
                />
                <TMC_Button
                    color="primary"
                    size="large"
                    onClick={() => setOpenDrawingDrawer(true)}
                    data-testid="Page_Drawings_TopBar_NewDrawing_Button"
                >
                    {fmtCta("NewDrawing")}
                </TMC_Button>
            </Styled_DrawingsTopBar>
            <Styled_Grid>
                {!drawings && (
                    <LoadingOrNoData
                        isFetching={isFetching}
                        dataName={fmt("DataName")}
                        action={() => setOpenDrawingDrawer(true)}
                        dataTestIdRef={"drawings"}
                        hasPermission={CAN_CREATE_UPDATE}
                    />
                )}
                {!isFetching &&
                    !!drawings?.length &&
                    drawingsList.map((folder) => (
                        <Styled_Accordion key={folder.id}>
                            <AccordionSummary
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography variant="h3">
                                    {folder.label}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={4} width={"100%"}>
                                    {folder.drawings &&
                                        folder.drawings.map(
                                            (drawing: Type_index_drawing) => (
                                                <Grid item key={drawing.id}>
                                                    <DrawingCard
                                                        drawing={drawing}
                                                    />
                                                </Grid>
                                            ),
                                        )}
                                </Grid>
                            </AccordionDetails>
                        </Styled_Accordion>
                    ))}
            </Styled_Grid>

            <DrawingDrawer
                drawingImage={drawingImage}
                open={openDrawingDrawer}
                onClose={handleCloseDrawing}
            />
        </Box>
    );
};
