import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { Type_show_subProject } from "src/api/tms-projects/subProjects/types";
import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { PdfAppearanceForm } from "src/forms/pdfAppearance/PdfAppearanceForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_PdfAppearanceDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    page: string;
    subProject?: Type_show_subProject;
};

export const PdfAppearanceDrawer = ({
    open,
    onClose,
    page,
    subProject,
}: Type_Props_PdfAppearanceDrawer) => {
    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PdfAppearance",
    );

    // State
    const [isLoading, setIsLoading] = useState(false);

    // Utils
    const formId = "PdfAppearance";
    const handleClose = () => {
        onClose();
        setIsLoading(false);
    };

    return (
        <DrawerGeneric
            open={open}
            onClose={handleClose}
            dataTestIdTitle={`PdfAppearance-Drawer-Title`}
            title={fmt("Title")}
            page={page}
            isFormLoading={isLoading}
            formId={formId}
        >
            <PdfAppearanceForm
                formId={formId}
                onClose={handleClose}
                setIsLoading={setIsLoading}
                subProject={subProject}
            />
        </DrawerGeneric>
    );
};
