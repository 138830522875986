import { ReactNode } from "react";

import { Styled_StackActionsDrawerReverse } from "src/components/Components_Common/Drawer/Drawer.style";

type Type_Props_Footer = {
    children: ReactNode;
};
export const Footer = ({ children }: Type_Props_Footer) => {
    return (
        <Styled_StackActionsDrawerReverse>
            {children}
        </Styled_StackActionsDrawerReverse>
    );
};
