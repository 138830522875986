import React from "react";

export type BoxComponentType = {
    children: React.ReactElement;
    className?: string;
};

/**
 * @deprecated use Mui Box component instead
 * @param children
 * @param className
 * @constructor
 */
export const Box = ({ children, className }: BoxComponentType) => {
    return (
        <div className={`p-8 rounded-md shadow-md ${className}`}>
            {children}
        </div>
    );
};
