import { Stack, ToggleButtonGroupProps } from "@mui/material";
import { ReactNode } from "react";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants/index";

import { Styled_ToggleButtonGroup } from "./ToggleButtonGroup.style";

type ToggleButtonGroupPropsType = {
    children: ReactNode;
    helperText?: string;
};

export const ToggleButtonGroup = ({
    children,
    helperText,
    ...restProps
}: ToggleButtonGroupPropsType & ToggleButtonGroupProps) => {
    return (
        <Stack direction="column">
            <Styled_ToggleButtonGroup {...restProps}>
                {children}
            </Styled_ToggleButtonGroup>
            {helperText && (
                <TMC_FormHelperText
                    helperText={helperText}
                    inputName={restProps.value}
                />
            )}
        </Stack>
    );
};
