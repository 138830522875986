import { Type_point } from "src/components/Components_Common/canvas/types";
import {
    sizeFlowGridX,
    sizeFlowGridY,
} from "src/components/Components_Teamoty/Flow/Flow.const";

export const onGrid = (pt: Type_point, offset: number = 0): Type_point => {
    if (offset) {
        return {
            x:
                pt.x -
                ((pt.x + sizeFlowGridX / offset) % sizeFlowGridX) +
                sizeFlowGridX / offset,
            y:
                pt.y -
                ((pt.y + sizeFlowGridY / offset) % sizeFlowGridY) +
                sizeFlowGridY / offset,
        };
    }
    return {
        x: pt.x - (pt.x % sizeFlowGridX),
        y: pt.y - (pt.y % sizeFlowGridY),
    };
};
