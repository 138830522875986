import { FormikErrors, FormikValues } from "formik";
import { FieldErrors } from "react-hook-form";
import * as Yup from "yup";

import { useGetLanguages } from "src/api/tms-projects/languages";
import { languagesList as languagesListDefault } from "src/assets/translations";
import { MultiLanguagesComponent } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.component";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { DEFAULT_LANGUAGE } from "src/contexts/languages";

/**
 * @deprecated use reactHookForm InputMultiLanguages schema
 */
export const multiLanguagesSchema = Object.keys(languagesListDefault).reduce(
    (schema, language) => {
        if (language !== DEFAULT_LANGUAGE) {
            return schema.shape({
                [language]: Yup.string().max(100, FORM_ERR_FMT.MAX_LENGTH),
            });
        } else {
            return schema.shape({
                [DEFAULT_LANGUAGE]: Yup.string()
                    .trim()
                    .required(FORM_ERR_FMT.REQUIRED)
                    .max(100, FORM_ERR_FMT.MAX_LENGTH),
            });
        }
    },
    Yup.object(),
);

export type Type_relation = { [key: string]: string | Type_relation };

export type Type_Props_MultiLanguagesContainer = {
    name?: string;
    values: FormikValues;
    handleChange: any;
    errors:
        | FormikErrors<string | string[] | false | Type_relation>
        | FieldErrors<any>;
};
/**
 * @deprecated use reactHookForm InputMultiLanguages component
 * @param name
 * @param values
 * @param handleChange
 * @param errors
 * @constructor
 */
export const MultiLanguagesContainer = ({
    name = "names",
    values,
    handleChange,
    errors,
}: Type_Props_MultiLanguagesContainer) => {
    const { data: fetchedLanguages } = useGetLanguages() || {};

    return (
        <MultiLanguagesComponent
            name={name as string}
            values={values}
            handleChange={handleChange}
            errors={errors}
            languagesList={fetchedLanguages ?? languagesListDefault}
        />
    );
};
