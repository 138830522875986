import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment, Stack, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import slugify from "slugify";
import * as Yup from "yup";

import { ProjectPath, Url } from "src/api/paths";
import { useSelectListCompanies } from "src/api/tms-commons/companies";
import { LICENCE_TYPE_ID } from "src/api/tms-commons/licenseTypes/types";
import { useIndexMetadataProjectsForForm } from "src/api/tms-commons/metadata";
import { mutationCreateProject } from "src/api/tms-commons/projects";
import { Type_post_project } from "src/api/tms-commons/projects/types";
import { useSelectListProjectTypes } from "src/api/tms-commons/projectTypes";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { MetadataFormController } from "src/components/Components_Common/forms/reactHookFormComponents/MetadataFormController/MetadataFormController";
import {
    formatSelectListToSelectOptionItem,
    Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { TextFieldWithBlock } from "src/components/Components_Common/forms/reactHookFormComponents/TextFieldWithBlock/TextFieldWithBlock";
import { UploadImageRhf } from "src/components/Components_Common/forms/reactHookFormComponents/UploadImage/UploadImage";
import { AutocompleteLicenseTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteLicenseTypes";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getDate } from "src/utils/date";
import { getUrlName } from "src/utils/urlFormatter";

const Schema_CreateProjectForm = Yup.object().shape({
    name: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    city: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    country: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    startDate: Yup.mixed<Dayjs>().required(FORM_ERR_FMT.REQUIRED),
    thumbnail: Yup.mixed().required(FORM_ERR_FMT.REQUIRED),
    projectTypes: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
    companyId: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    postCode: Yup.string()
        .max(10, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    licenseType: Yup.object()
        .shape({
            id: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        })
        .required(FORM_ERR_FMT.REQUIRED),
    licenseStartDate: Yup.mixed<Dayjs>().required(FORM_ERR_FMT.REQUIRED),
    renewal: Yup.number().min(1, FORM_ERR_FMT.MUST_BE_POSITIF).nullable(),
    cost: Yup.number().min(1, FORM_ERR_FMT.MUST_BE_POSITIF).nullable(),
});

type Type_Props_CreateProjectForm = {
    onClose: () => void;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    formId: string;
};

export const CreateProjectForm = ({
    onClose,
    setIsLoading,
    formId,
}: Type_Props_CreateProjectForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Projects");

    const { data: projectTypes } = useSelectListProjectTypes() || {};
    const { data: companies } = useSelectListCompanies() || {};
    const { mutateAsync: mutateCreate } = mutationCreateProject() || {};
    const { data: metadataProjects = [] } =
        useIndexMetadataProjectsForForm({
            enabled: true,
        }) || {};

    const form = useForm<Type_post_project>({
        ...useFormDefaultConfig,
        shouldFocusError: true,
        defaultValues: {
            name: "",
            postCode: "",
            city: "",
            country: "",
            slug: "",
            projectTypes: [],
            startDate: null,
            thumbnail: undefined,
            metadata: null,
            licenseType: null,
            licenseStartDate: null,
            renewal: undefined,
            cost: undefined,
        },
        resolver: yupResolver<any>(Schema_CreateProjectForm),
    });

    const handleSubmit = async (
        values: Type_post_project,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        setIsLoading(true);

        try {
            await mutateCreate(values);
            onClose();
        } catch (e: any) {
            console.error("HANDLE SUBMIT ERROR", e);
        }

        setIsLoading(false);
    };

    const [licenseTypeWatch, startDateWatch, name] = useWatch({
        name: ["licenseType", "licenseStartDate", "name"],
        control: form.control,
    });

    useEffect(() => {
        const slug = slugify(name, {
            lower: true, // Convertir en minuscules
            strict: true, // Remplacer les caractères spéciaux par des tirets
            trim: true, // Supprimer les espaces en début et en fin de chaîne
        });

        form.setValue("slug", slug);
    }, [name]);

    useEffect(() => {
        if (licenseTypeWatch && startDateWatch) {
            const startDate = getDate(startDateWatch);
            const durationValue = licenseTypeWatch.duration;
            const licenseEndDate = startDate.add(
                parseInt(durationValue, 10),
                "month",
            );

            form.setValue("licenseEndDate", licenseEndDate);
        }
    }, [licenseTypeWatch, startDateWatch, form]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={formId}>
                <Stack gap={6}>
                    <Typography
                        variant="body1Bold"
                        data-testid="CreateProject-Typo-projectInfo"
                    >
                        {fmt(`ProjectInformation`)}
                    </Typography>
                    <UploadImageRhf
                        host={Url.COMMONS}
                        service={ProjectPath.PROJECTS}
                        imgType={"thumbnail"}
                        name="thumbnail"
                        withDescription={true}
                    />
                    <TextField
                        variant={"outlined"}
                        data-testid="CreateProject-Form-name"
                        name={"name"}
                        label={fmt(`Name`)}
                    />
                    <TextFieldWithBlock
                        variant={"outlined"}
                        data-testid="CreateProject-Form-slug"
                        name="slug"
                        label={fmt("URL")}
                        block={getUrlName()}
                        disabled={true}
                    />
                    <DatePicker
                        name={"startDate"}
                        label={fmt("StartDate")}
                        data-testid="CreateProject-Form-startDate"
                    />
                    <Select
                        name={"companyId"}
                        options={formatSelectListToSelectOptionItem(
                            companies || [],
                        )}
                        label={fmt("Company")}
                        variant={"outlined"}
                        data-testid="CreateProject-Form-company"
                    />
                    <Stack
                        direction="row"
                        flexWrap="nowrap"
                        gap={4}
                        width="100%"
                    >
                        <TextField
                            variant={"outlined"}
                            data-testid="CreateProject-Form-postCode"
                            name={"postCode"}
                            label={fmt(`PostCode`)}
                        />
                        <TextField
                            variant={"outlined"}
                            data-testid="CreateProject-Form-city"
                            name={"city"}
                            label={fmt(`City`)}
                        />
                        <TextField
                            variant={"outlined"}
                            data-testid="CreateProject-Form-country"
                            name={"country"}
                            label={fmt("Country")}
                        />
                    </Stack>
                    <Autocomplete
                        label={fmt("ProjectTypes")}
                        data-testid="CreateProject-Form-projectTypes"
                        name="projectTypes"
                        multiple
                        options={projectTypes || []}
                        renderInputProps={{
                            variant: "outlined",
                        }}
                    />
                    <Typography variant="body1Bold">
                        {fmt(`Metadata`)}
                    </Typography>
                    <MetadataFormController metadata={metadataProjects} />
                    <Typography variant="body1Bold">
                        {fmt(`License`)}
                    </Typography>
                    <AutocompleteLicenseTypes
                        data-testid="CreateProject-Form-licenseTypes"
                        name="licenseType"
                        multiple={false}
                        label={fmt("AssignLicenseType")}
                        renderInputProps={{
                            variant: "outlined",
                        }}
                        filters={{
                            license_type_mode_id: LICENCE_TYPE_ID.PROJECT,
                        }}
                    />
                    <Stack
                        direction="row"
                        flexWrap="nowrap"
                        gap={4}
                        width="100%"
                    >
                        <DatePicker
                            data-testid="CreateProject-Form-licenseStartDate"
                            name="licenseStartDate"
                            label={fmt("LicenseStartDate")}
                        />
                        <DatePicker
                            name="licenseEndDate"
                            label={fmt("LicenseEndDate")}
                            data-testid="Licence-DrawerContent-licenseEndDate"
                            disabled
                        />
                    </Stack>
                    <TextField
                        name="renewal"
                        label={fmt(`Renewal`)}
                        data-testid="CreateProject-Form-renewal"
                        variant={"outlined"}
                        type="number"
                    />
                    <TextField
                        name="cost"
                        label={fmt(`Cost`)}
                        variant={"outlined"}
                        data-testid="CreateProject-Form-cost"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    €
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </form>
        </FormProvider>
    );
};
