import { AutocompleteRenderGetTagProps, Box, Checkbox } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import { Type_Filter } from "src/api/filters";
import { useSelectListTrades } from "src/api/tms-projects/trades";
import { Type_selectList_trade } from "src/api/tms-projects/trades/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteTrades = {
    name: string;
    label?: string;
    enabled?: boolean | null;
    multiple?: boolean;
    renderInputProps?: any;
};

export const AutocompleteTrades = ({
    name,
    label,
    enabled,
    multiple = true,
    ...restProps
}: Type_Props_AutocompleteTrades) => {
    const { getValues } = useFormContext(); // retrieve those props

    const filters: Type_Filter = {};
    typeof enabled == "boolean" && (filters.enabled = enabled);

    const { isFetching: isFetchingTrades, data: trades } =
        useSelectListTrades() || {};

    return (
        <Autocomplete
            name={name}
            label={label}
            multiple={multiple}
            isFetching={isFetchingTrades}
            options={(trades as unknown as Type_selectList_trade[]) || []}
            disableCloseOnSelect={multiple}
            renderOption={(props, option) => (
                <li
                    {...props}
                    key={option.id}
                    data-testid={`autocomplete-trades-${name}-input-${option.name}`}
                >
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={
                            !!getValues(name)?.find(
                                (trade: any): boolean =>
                                    trade?.id === option.id,
                            )
                        }
                    />
                    <TMC_Chip
                        label={`${option.code.toUpperCase()} - ${option.name}`}
                        data-testid={`AutocompleteTrades-${option.name.replaceAll(
                            " ",
                            "_",
                        )}`}
                        backgroundColor={option.color}
                    />
                </li>
            )}
            renderTags={(
                tagValue: any[],
                getTagProps: AutocompleteRenderGetTagProps,
            ) =>
                tagValue.map((option, index: number) => (
                    <Box key={index} sx={{ marginRight: 1 }}>
                        <TMC_Chip
                            label={`${option.code.toUpperCase()} - ${option.name}`}
                            data-testid={`AutocompleteTrades-${option.name.replaceAll(
                                " ",
                                "_",
                            )}`}
                            backgroundColor={option.color}
                            {...getTagProps({ index })}
                        />{" "}
                    </Box>
                ))
            }
            {...restProps}
        />
    );
};
