import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import React, { ReactElement } from "react";
import { Line } from "react-konva";

import { Type_Props_PlanningMonthsDays } from "src/components/Components_Teamoty/Planning/Dates/PlanningDate.type";
import { PlanningWeekBackground } from "src/components/Components_Teamoty/Planning/Dates/PlanningWeekBackground";
import {
    heightPlanningDate,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";

export const getGridVertical = (
    height: number,
    dates: Type_Props_PlanningMonthsDays,
    widthDate: number,
): [Array<ReactElement>, Array<ReactElement>] => {
    const theme = usePlanningTheme();

    const elmRects: Array<ReactElement> = [];
    const elmLine: Array<ReactElement> = [];

    let lastDate: planningDateClass = dates.days[0];

    dates.days.map((date: planningDateClass, index: number): void => {
        elmRects.push(
            <PlanningWeekBackground
                key={"glyb" + index}
                height={height + heightPlanningDate}
                width={widthDate}
                date={date}
            />,
        );

        if (lastDate && lastDate.week != date.week) {
            elmLine.push(
                <Line
                    key={"gly" + index}
                    points={[date.pos, 0, date.pos, height]}
                    stroke={theme.colorPlanningLine}
                    strokeWidth={1}
                />,
            );
            lastDate = date;
        }
    });
    return [elmRects, elmLine];
};
