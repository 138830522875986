import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { ProjectPaths, Url } from "src/api/paths";
import {
    Type_com_post_projectType,
    Type_com_put_projectType,
} from "src/api/tms-commons/projectTypes/types";

export const indexProjectTypes = (): GetReturnType => {
    return get(`${Url.COMMONS}${ProjectPaths.PROJECT_TYPES}`);
};

export const createProjectType = (
    data: Type_com_post_projectType,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${ProjectPaths.PROJECT_TYPES}`);
};

export const showProjectType = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${ProjectPaths.PROJECT_TYPES}/${id}`);
};

export const updateProjectType = (
    data: Type_com_put_projectType,
): PostReturnType => {
    return put(data, `${Url.COMMONS}${ProjectPaths.PROJECT_TYPES}/${data?.id}`);
};

export const deleteProjectType = (id: number): RemoveReturnType => {
    return remove(`${Url.COMMONS}${ProjectPaths.PROJECT_TYPES}/${id}`);
};

export const statusProjectType = (data: any, id: number): PutReturnType => {
    return put(data, `${Url.COMMONS}${ProjectPaths.PROJECT_TYPES}/${id}`);
};

export const selectListProjectTypes = (): GetReturnType => {
    return get(`${Url.COMMONS}${ProjectPaths.SELECT_LIST}`);
};
