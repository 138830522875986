import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { SubTradeForm } from "src/forms/subTrades/SubTradeForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_SubTradeDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    subTradeId: number | null;
    action: Type_action;
    page: string;
    tradeId: number | null;
};

export const SubTradeDrawer = ({
    open,
    onClose,
    subTradeId,
    action,
    page,
    tradeId,
}: Type_Props_SubTradeDrawer) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubTrades");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`SubTrade-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"subTrade"}
            page={page}
        >
            <SubTradeForm
                tradeId={tradeId}
                subTradeId={subTradeId}
                action={action}
                setIsLoading={setIsLoading}
                onClose={onClose}
            />
        </DrawerGeneric>
    );
};
