import { get, GetReturnType } from "src/api/fetch";
import { ProjectPath, Url } from "src/api/paths";

export const openProject = (
    slug: string,
    subProjectId: string | undefined,
): GetReturnType => {
    return get(`${Url.PROJECTS}${ProjectPath.OPEN}/${slug}`, {
        subProjectId: Number(subProjectId),
    });
};
