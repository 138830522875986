import {
    Type_index_version_note,
    Type_prj_index_version_note,
} from "src/api/tms-projects/notes/types";
import { formatDateForFront } from "src/utils/date";

export const formatterIndexVersionNotes = (
    notes: Type_prj_index_version_note[],
) => {
    if (!notes) return null;
    return notes.map(formatterVersionNote);
};

export const formatterVersionNote = (
    note: Type_prj_index_version_note,
): Type_index_version_note => {
    return {
        ...note,
        date: formatDateForFront(note.date),
        author: {
            id: note.author.id,
            name: `${note.author.user.firstName} ${note.author.user.lastName}`,
            enabled: note.author.enabled,
        },
    };
};
