import { Box, SelectChangeEvent, Skeleton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, {
    Dispatch,
    memo,
    SetStateAction,
    useCallback,
    useMemo,
} from "react";

import { Type_selectList_subProject } from "src/api/tms-projects/subProjects/types";
import { Styled_Select } from "src/components/Components_Teamoty/SubProjectSelect/stylesheet";

type Type_Props_SubProjectSelect = {
    selectedSubProjectId: number;
    setSelectedSubProjectId: Dispatch<SetStateAction<number | null>>;
    subProjects: Type_selectList_subProject[];
    isFetching: boolean;
};

export const SubProjectSelect = memo(function SubProjectsSelect({
    selectedSubProjectId,
    setSelectedSubProjectId,
    subProjects,
    isFetching,
}: Type_Props_SubProjectSelect) {
    const handleChange = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            setSelectedSubProjectId(e.target.value as number);
        },
        [selectedSubProjectId],
    );

    const selectedSubProject = useMemo(
        () =>
            subProjects.find(
                (subProject) => subProject.id === selectedSubProjectId,
            ),
        [selectedSubProjectId, subProjects],
    );

    return isFetching ? (
        <Skeleton variant="rounded" height={30} />
    ) : (
        <Box p={2}>
            <Styled_Select
                name="subProjects"
                value={selectedSubProjectId || subProjects[0].id}
                label={selectedSubProject && selectedSubProject.name}
                data-testid="Select-SubProject"
                onChange={(e: SelectChangeEvent<unknown>) => handleChange(e)}
            >
                {subProjects.map((subProject: Type_selectList_subProject) => (
                    <MenuItem
                        key={subProject.id}
                        value={subProject.id}
                        data-testid={`ItemList-SubProject-${subProject.id}`}
                    >
                        {subProject.name}
                    </MenuItem>
                ))}
            </Styled_Select>
        </Box>
    );
});
