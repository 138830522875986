import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import {
    Type_Map_planningDates,
    Type_planning,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import { Vector2d } from "konva/lib/types";
import { Dispatch, SetStateAction } from "react";

import { Type_Props_PlanningMonthsDays } from "src/components/Components_Teamoty/Planning/Dates/PlanningDate.type";
import { Type_State_PlanningScroll } from "src/components/Components_Teamoty/Planning/Scrolls/PlanningScroll.type";

export const updateDatesMonthsDays = (
    dataPlanning: Type_planning | null,
    datesActif: Array<planningDateClass>,
    scrollX: Type_State_PlanningScroll,
    widthDate: number,
    setCursorHighlight: Dispatch<SetStateAction<Vector2d>>,
    setDatesMonthsDays: Dispatch<SetStateAction<Type_Props_PlanningMonthsDays>>,
): void => {
    setCursorHighlight({ x: -1, y: -1 });

    setDatesMonthsDays((prev) => {
        const subMonths: planningDateClass[] = [];
        let subDays: planningDateClass[] = [];

        if (dataPlanning) {
            const dates: Type_Map_planningDates = dataPlanning.dates;

            // liste des dates actives avec une marge de 3 jours pour les dates de fin
            subDays = datesActif.slice(
                scrollX.pos,
                3 + scrollX.pos + scrollX.widthPage / widthDate,
            );

            // initialise toutes les positions
            dates.forEach((d: planningDateClass): void => {
                d.pos = -1;
            });

            let prevDate: planningDateClass | null = null;

            // calcul les positions des dates actives
            let currentDate: planningDateClass = subDays[0];
            let posX: number = 0;
            subDays.map((date: planningDateClass): void => {
                if (
                    prevDate?.year != date.year ||
                    prevDate?.month != date.month
                ) {
                    currentDate = date;
                    currentDate.pos = posX;
                    subMonths.push(currentDate);
                } else {
                    currentDate.count++;
                }
                prevDate = date;
                posX += widthDate;
            });

            posX = 0;
            subDays.map((date: planningDateClass) => {
                date.pos = posX;
                posX += widthDate;
            });

            // positionne toutes les positions de toutes les dates
            let lastPos = -widthDate;
            dates.forEach((d: planningDateClass): void => {
                if (d.pos === -1) {
                    d.pos = lastPos;
                }
                lastPos = d.pos;
            });
        }

        prev.days = subDays;
        prev.months = subMonths;

        return { ...prev };
    });
};
