import { Type_point } from "src/components/Components_Common/canvas/types";

export const getMinCoordinate = (coordinates: Type_point[]): Type_point => {
    return coordinates.reduce((min, current) => {
        if (current.x < min.x || (current.x === min.x && current.y < min.y)) {
            return current;
        }
        return min;
    }, coordinates[0]);
};
