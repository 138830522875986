import Box from "@mui/material/Box";
import React, { useState } from "react";

import { APP_BAR_HEIGHT } from "src/components/Components_Teamoty/TopBar/stylesheet";
import { AvatarMenuButton } from "src/components/Components_Teamoty/TopBar/TopBar_Right/AvatarMenu/AvartarMenuButton";
import { AvatarMenu } from "src/components/Components_Teamoty/TopBar/TopBar_Right/AvatarMenu/AvatarMenu";
import { LanguageMenu } from "src/components/Components_Teamoty/TopBar/TopBar_Right/LanguageMenu/LanguageMenu";
import { LanguageMenuButton } from "src/components/Components_Teamoty/TopBar/TopBar_Right/LanguageMenu/LanguageMenuButton";
import { useProject } from "src/contexts/project";
import { ProjectUserInvitationDrawer } from "src/drawers/projectUsers/ProjectUserInvitationDrawer";
import { UserInvitationDrawer } from "src/drawers/users/UserInvitationDrawer";

export const TopBar_Right = () => {
    const { requestConfig } = useProject();

    const [openDrawer, setOpenDrawer] = useState({
        user: false,
        projectUser: false,
    });
    const toggleDrawer = (drawerType: "user" | "projectUser") => {
        setOpenDrawer((prev) => ({ ...prev, [drawerType]: !prev[drawerType] }));
    };

    const [menuState, setMenuState] = useState({
        anchorEl: null as HTMLElement | null,
        menuType: "" as "avatar" | "language" | "",
    });

    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        menuType: "avatar" | "language",
    ) => {
        setMenuState({
            anchorEl: event.currentTarget,
            menuType,
        });
    };

    const handleClose = () => {
        setMenuState({
            anchorEl: null,
            menuType: "",
        });
    };

    return (
        <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            height={APP_BAR_HEIGHT}
            gap={2}
            marginRight={2}
        >
            <Box sx={{ display: { xs: "none", md: "flex" } }} gap={2}>
                <LanguageMenuButton
                    handleClick={(event) => handleClick(event, "language")}
                />
                {menuState.menuType === "language" && (
                    <LanguageMenu
                        anchorEl={menuState.anchorEl}
                        open={Boolean(menuState.anchorEl)}
                        handleClose={handleClose}
                    />
                )}
            </Box>

            <AvatarMenuButton
                handleClick={(event) => handleClick(event, "avatar")}
            />
            {menuState.menuType === "avatar" && (
                <AvatarMenu
                    open={Boolean(menuState.anchorEl)}
                    anchorEl={menuState.anchorEl}
                    handleClose={handleClose}
                />
            )}

            <UserInvitationDrawer
                page="users"
                open={openDrawer.user}
                onClose={() => toggleDrawer("user")}
            />
            <ProjectUserInvitationDrawer
                page="projectUsers"
                open={openDrawer.projectUser}
                onClose={() => toggleDrawer("projectUser")}
                requestConfig={requestConfig}
            />
        </Box>
    );
};
