import { useMutation } from "react-query";

import { createResourceLogistics } from "src/api/tms-logistics/resources/services";
import { Type_log_post_resource } from "src/api/tms-logistics/resources/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const mutationCreateResourceLogistics = (callback?: any) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_log_post_resource) => {
            return createResourceLogistics(data, requestConfig);
        },
        onSuccess: (data: any): void => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateResourceLogistics",
                );
            }
            callback?.(data.data.data);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericErrorLogistics"),
            });
            return err;
        },
    });
};
