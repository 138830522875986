import { yupResolver } from "@hookform/resolvers/yup";
import { FormLabel, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { useIndexCompanies } from "src/api/tms-commons/companies";
import {
    Type_index_company,
    Type_post_company,
    Type_selectList_company,
    Type_show_company,
} from "src/api/tms-commons/companies/types";
import { Type_put_user } from "src/api/tms-users/users/types";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_CompanyForm = Yup.object().shape({
    company: Yup.object().required(FORM_ERR_FMT.REQUIRED),
    nationalId: Yup.string().nullable(),
    postalCode: Yup.string()
        .trim()
        .max(10, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    city: Yup.string()
        .trim()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    country: Yup.string()
        .trim()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
});

type Type_Props_UserCompanyForm = {
    onSubmit: (values: Type_put_user & { company?: Type_show_company }) => void;
    formId: string;
    defaultCompany?: Type_index_company;
    companyIdToUpdate?: number | null;
    userId?: number | null;
};

export const UserCompanyForm = ({
    onSubmit,
    formId,
    defaultCompany,
    userId,
}: Type_Props_UserCompanyForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Companies");
    const [disableCompany, setDisableCompany] = useState<boolean>(false);
    const { data: companiesList, isFetching: isFetchingCompaniesList } =
        useIndexCompanies() || {};

    const isDisabled = useMemo(
        () => disableCompany || isFetchingCompaniesList,
        [disableCompany, isFetchingCompaniesList],
    );

    const form = useForm({
        //set inital values
        defaultValues: {
            company: defaultCompany || {},
            nationalId: defaultCompany?.nationalId || "",
            postalCode: defaultCompany?.postalCode || "",
            city: defaultCompany?.city || "",
            country: defaultCompany?.country || "",
        },
        resolver: yupResolver<any>(Schema_CompanyForm),
    });

    const handleSubmit = (
        values: { company: Type_selectList_company } & Type_post_company,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        const { company: selectedCompany, ...newCompanyData } = values;
        let newCompany = undefined;
        if (selectedCompany.id === -1) {
            // a new company to create
            // destruct the object company to add technicalName and users
            newCompany = {
                ...newCompanyData,
                id: -1,
                users: [userId] as number[],
            };
        }
        onSubmit({
            id: userId as number,
            companyId: selectedCompany.id,
            company: newCompany as Type_show_company,
        });
    };

    const searchCompanyForDisable = (companySelected: {
        id: number;
        name: string;
    }) => {
        const companyFound = !!companiesList?.companies.some(
            (elem: Type_selectList_company) =>
                elem.name === companySelected.name,
        );
        setDisableCompany(companyFound);
        return companyFound;
    };

    const onAddNewValue = (value: Type_selectList_company) => {
        form.setValue("name", value.name);
        form.setValue("company", value);
        const companyFound = searchCompanyForDisable(value);
        if (!companyFound) {
            form.setValue("nationalId", "");
            form.setValue("postalCode", "");
            form.setValue("city", "");
            form.setValue("country", "");
        }
    };

    useEffect(() => {
        const company = form.getValues("company");
        const companyName = company?.name || form.getValues("name");
        const companyData = companiesList?.companies.find(
            (company: Type_selectList_company) => company.name === companyName,
        );
        const isCompanyDisabled = !companyName || companyData;

        setDisableCompany(!!isCompanyDisabled);
        if (companyData) {
            form.setValue("name", companyData?.name);
            form.setValue("nationalId", companyData?.nationalId || "");
            form.setValue("postalCode", companyData?.postalCode);
            form.setValue("city", companyData?.city);
            form.setValue("country", companyData?.country);
        }
    }, [companiesList, form.watch("company")]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={formId}>
                <Stack gap={6}>
                    <Stack gap={1}>
                        <FormLabel>{fmt("CompanyLabel")}</FormLabel>
                        <AutocompleteFreeSoloDialog
                            isFetching={isFetchingCompaniesList}
                            multiple={false}
                            options={companiesList?.companies || []}
                            name="company"
                            freeSolo={true}
                            onAddNewValue={onAddNewValue}
                            renderInputProps={{ variant: "outlined" }}
                            disabled={false}
                            defaultValue={defaultCompany || null}
                        />
                    </Stack>

                    <Stack gap={1}>
                        <TextField
                            name="nationalId"
                            label={fmt(`NationalId`)}
                            disabled={isDisabled}
                        />
                        <Typography
                            component={"span"}
                            color="text.secondary"
                            variant={"body2"}
                        >
                            {fmt("NationalIdHelperText")}
                        </Typography>
                    </Stack>

                    <TextField
                        name="postalCode"
                        label={fmt(`ZipCode`)}
                        disabled={isDisabled}
                    />
                    <TextField
                        name="city"
                        label={fmt(`CityLabel`)}
                        disabled={isDisabled}
                    />
                    <TextField
                        name="country"
                        label={fmt(`CountryLabel`)}
                        disabled={isDisabled}
                    />
                </Stack>
            </form>
        </FormProvider>
    );
};
