import { Stack } from "@mui/material";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { UploadImage } from "src/components/Components_Common/index";
import { Type_UploadImageContainerProps } from "src/components/Components_Common/UploadImage/UploadImage.container";

type Type_Props_UploadImageRhf = Omit<
    Type_UploadImageContainerProps,
    "setValue" | "value"
>;

export const UploadImageRhf = ({
    name,
    ...props
}: Type_Props_UploadImageRhf) => {
    const { formState, control, setValue } = useFormContext(); // retrieve those props

    return (
        <Stack>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref: inputRef, value } }) => (
                    <UploadImage
                        {...props}
                        name={name}
                        value={value}
                        setValue={setValue}
                        {...inputRef}
                    />
                )}
            />
            {formState.errors[name] && (
                <TMC_FormHelperText
                    inputName={name}
                    helperText={formState?.errors[name]?.message as string}
                />
            )}
        </Stack>
    );
};
