import { GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_areaType } from "src/api/tms-commons/areaTypes/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { AreaTypeDrawer } from "src/drawers/areaTypes/AreaTypeDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteAreaType } from "./Page_AreaTypes.container";

export type Type_Props_PageAreaTypesComponent = {
    areaTypes: Type_index_areaType[];
    removeAreaType: (id: number) => void;
    changeStatus: (areaType: Type_index_areaType) => void;
    modalDeleteAreaType: Type_modalDeleteAreaType;
    setModalDeleteAreaType: Dispatch<SetStateAction<Type_modalDeleteAreaType>>;
    isFetching: boolean;
};

export const Page_AreaTypesComponent = ({
    areaTypes,
    removeAreaType,
    changeStatus,
    modalDeleteAreaType,
    setModalDeleteAreaType,
    isFetching,
}: Type_Props_PageAreaTypesComponent) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Teamoty.AreaTypes");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const page = "areaTypes";

    const [selectedAreaType, setSelectedAreaType] = useState<number | null>(
        null,
    );
    const [openAreaTypeDrawer, setOpenAreaTypeDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleAreaTypeEdit = (areaTypeId: number) => {
        setSelectedAreaType(areaTypeId);
        setAction("update");
        setOpenAreaTypeDrawer(true);
    };

    const handleAreaTypeCreate = () => {
        setSelectedAreaType(null);
        setAction("create");
        setOpenAreaTypeDrawer(true);
    };

    const handleAreaTypeDuplicate = (areaTypeId: number) => {
        setSelectedAreaType(areaTypeId);
        setAction("duplicate");
        setOpenAreaTypeDrawer(true);
    };

    const handleCloseAreaTypeDrawer = () => {
        setOpenAreaTypeDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            sortable: false,
        },
        {
            field: "projectTypesCount",
            headerName: fmtTableColumn("Use"),
            sortable: false,
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleAreaTypeEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) =>
        handleAreaTypeDuplicate(row.id);

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_areaType);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteAreaType({
            isOpen: true,
            props: row as Type_index_areaType,
        });
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageAreaTypes-AddOne-btn"
                    onClick={() => handleAreaTypeCreate()}
                    page={page}
                />
            </HeaderGrid>

            <Table
                rows={areaTypes}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleAreaTypeCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleAreaTypeEdit(id as number);
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />
            <AreaTypeDrawer
                open={openAreaTypeDrawer}
                onClose={handleCloseAreaTypeDrawer}
                areaTypeIdToUpdate={selectedAreaType}
                action={action}
                page={page}
            />

            <ModalDelete
                open={modalDeleteAreaType.isOpen}
                onClose={() => setModalDeleteAreaType({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteAreaType?.props?.name as string}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteAreaType({ isOpen: false });
                            removeAreaType(
                                modalDeleteAreaType?.props?.id as number,
                            );
                        },
                    },
                }}
            />
        </>
    );
};
