import { Box, Divider, Select, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { SelectProps } from "@mui/material/Select/Select";
import { styled } from "@mui/material/styles";
import React, { forwardRef } from "react";

import {
    Icon,
    Type_Icon_Variants,
} from "src/components/Components_Common/Icon/Icon";

const Styled_Select = styled(Select)(() => ({
    // Select icon
    "&.MuiInputBase-root": {
        color: "inherit",
        "& .Mui-disabled": {
            color: "inherit",
            WebkitTextFillColor: "inherit",
        },
    },

    // caret-down icon
    "& .MuiSelect-icon": {
        color: "inherit",
    },

    "& > .MuiSvgIcon-root": {
        color: "inherit",
    },
}));

const ItemContent = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
    width: "100%",
}));

export type Type_RegularItem = {
    label: string;
    slug: string;
    value: string | number;
    icon: {
        variant: Type_Icon_Variants;
        icon: string;
    };
};

type Type_DividerItem = {
    divider: true;
};

export type ItemType = Type_RegularItem | Type_DividerItem;

export type Type_Props_IconSelect = SelectProps & {
    items: ItemType[];
    name: string;
};

const IconSelect = forwardRef<HTMLSelectElement, Type_Props_IconSelect>(
    ({ items, ...props }, ref) => {
        return (
            <Styled_Select
                ref={ref}
                {...props}
                IconComponent={(iconProps) =>
                    !props.disabled ? (
                        <Icon
                            variant="solid"
                            icon="caret-down"
                            {...iconProps}
                        />
                    ) : null
                }
                renderValue={(val) => {
                    const regularItems = items.filter(
                        (item): item is Type_RegularItem => true,
                    );
                    const selectedIcon = regularItems.find(
                        (item) => item.value === val,
                    );
                    if (selectedIcon) {
                        return <Icon fontSize="large" {...selectedIcon.icon} />;
                    }
                }}
            >
                {items.map((item, index) => {
                    if ("divider" in item) {
                        return (
                            <Divider
                                key={index}
                                orientation="horizontal"
                                textAlign="center"
                                variant="fullWidth"
                            />
                        );
                    } else {
                        return (
                            <MenuItem
                                data-testid={`icon-item-${item.slug}`}
                                key={index}
                                value={item.value}
                                sx={{ minWidth: 250 }}
                            >
                                <ItemContent>
                                    <Icon fontSize="large" {...item.icon} />
                                    <Typography flexGrow={1}>
                                        {item.label}
                                    </Typography>
                                    {props.value === item.value && (
                                        <Icon variant="light" icon="check" />
                                    )}
                                </ItemContent>
                            </MenuItem>
                        );
                    }
                })}
            </Styled_Select>
        );
    },
);

IconSelect.displayName = "IconSelect";

export default IconSelect;
