import React from "react";

import {
    mutationCreateSequenceNote,
    mutationDeleteSequenceNote,
    mutationUpdateSequenceNote,
    useIndexSequenceNotes,
} from "src/api/tms-scheduling/sequenceNotes";
import { Type_index_sequenceNote } from "src/api/tms-scheduling/sequenceNotes/types";
import { useUser } from "src/contexts/user";
import {
    NotesForm,
    Type_permissionsNote,
    Type_Props_NotesForm,
} from "src/forms/notes/NotesForm";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_SequenceNotes = {
    sequenceId?: number;
};

export const SequenceNotes = ({ sequenceId }: Type_Props_SequenceNotes) => {
    const { closePaper } = useContextualDrawer();
    const { hasPermission } = useUser();

    ///////////////////////////////////////
    ///            Query                ///
    ///////////////////////////////////////

    const { isFetching, data: sequenceNotes } =
        useIndexSequenceNotes({
            sequenceId: sequenceId,
        }) || {};

    ///////////////////////////////////////
    ///           Mutations             ///
    ///////////////////////////////////////

    const { mutateAsync: mutateCreateNote } =
        mutationCreateSequenceNote({
            sequenceId: sequenceId,
        }) || {};
    const { mutateAsync: mutateUpdateNote } =
        mutationUpdateSequenceNote() || {};
    const { mutateAsync: mutateDeleteNote } =
        mutationDeleteSequenceNote({
            sequenceId: sequenceId,
        }) || {};

    ///////////////////////////////////////
    ///         Permissions             ///
    ///////////////////////////////////////

    const permissions: Type_permissionsNote = {
        canShow: hasPermission("sequenceNotes_index"),
        canDelete: hasPermission("sequenceNotes_delete"),
        canEdit: hasPermission("sequenceNotes_create"),
    };

    return (
        <SequenceNotesForm
            apiRef="SequenceNotes"
            notes={sequenceNotes}
            isFetching={isFetching}
            mutateCreateNote={mutateCreateNote}
            mutateUpdateNote={mutateUpdateNote}
            mutateDeleteNote={mutateDeleteNote}
            permissions={permissions}
            onClose={() => closePaper("sequenceNotes")}
            sendEventCount={"updateNotesCount"}
        />
    );
};

// this function formats the data sequenceNotes correctly
const SequenceNotesForm = ({
    ...props
}: Type_Props_NotesForm<Type_index_sequenceNote>) => {
    return <NotesForm {...props} />;
};
