import { DrawerProps } from "@mui/material";
import * as React from "react";

import { mutationCreateCompany } from "src/api/tms-commons/companies";
import { Type_show_company } from "src/api/tms-commons/companies/types";
import { Type_get_me } from "src/api/tms-gateway/auth/types";
import { mutationUpdateUser } from "src/api/tms-users/users";
import { Type_put_user } from "src/api/tms-users/users/types";

import { MyProfileDrawerComponent } from "./MyProfileDrawer.component";

export type Type_Props_MyProfileDrawerContainer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    user: Type_get_me | null;
    company?: Type_show_company;
    step: string;
    setStep: React.Dispatch<React.SetStateAction<string>>;
};

export const MyProfileDrawerContainer = ({
    open,
    onClose,
    user,
    company,
    step,
    setStep,
}: Type_Props_MyProfileDrawerContainer) => {
    const [isLoadingPassword, setIsLoadingPassword] = React.useState(false);

    // ---- FORM  ----

    const updateUser = async (values: Type_put_user) => {
        await mutateUserInfos({ ...values, id: user?.id as number });
    };

    const createCompany = async (
        newCompany: Type_show_company,
        values: Type_put_user,
    ) => {
        // create a new company
        const data = await mutateCreateCompany(newCompany as Type_show_company);
        values.companyId = data.data.data.id;
    };

    const onFormSubmit = async (
        values: Type_put_user & { company?: Type_show_company },
    ) => {
        const newCompany = values.company;
        switch (step) {
            case "1":
                await updateUser(values);
                onClose();
                break;
            case "2":
                // update user company or create new company
                if (newCompany) {
                    await createCompany(newCompany, values);
                }
                await updateUser(values);
                onClose();
                break;
            case "3":
                // submit on PasswordForm
                break;

            default:
                break;
        }
    };

    // ---- MUTATIONS ----
    const { mutateAsync: mutateUserInfos, isLoading: isLoadingUser } =
        mutationUpdateUser() || {};

    const { mutateAsync: mutateCreateCompany, isLoading: isLoadingCompany } =
        mutationCreateCompany() || {};

    const isLoading = React.useMemo(
        () => isLoadingUser || isLoadingCompany || isLoadingPassword,
        [isLoadingUser, isLoadingCompany, isLoadingPassword],
    );

    return (
        <MyProfileDrawerComponent
            user={user}
            isLoading={isLoading}
            setIsLoading={setIsLoadingPassword}
            company={company}
            step={step}
            setStep={setStep}
            open={open}
            onFormSubmit={onFormSubmit}
            onClose={onClose}
        />
    );
};
