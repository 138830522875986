import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AppBar,
    Badge,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CommonColors,
    Container,
    IconButton,
    Palette,
    Paper,
    SimplePaletteColorOptions,
    Stack,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import { ButtonOwnProps } from "@mui/material/Button/Button";
import { IconOwnProps } from "@mui/material/Icon/Icon";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";
import { PaletteOptions, styled, TypeBackground } from "@mui/material/styles";
import { DateRangeCalendar, DateRangePicker } from "@mui/x-date-pickers-pro";
import * as React from "react";

import { TMC_DateTimeRangePicker, TMC_TextField } from "src/components";
import { TextFieldPassword } from "src/components/Components_Common/forms/TextFieldPassword/TextFieldPassword";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { COLORS_DARK } from "src/theme/stylesheet";

const Styled_Section = styled(Accordion)(({ theme }: any) => ({
    padding: theme.spacing(6),
}));

const Section = ({ children }: { children: any }) => {
    return (
        <Styled_Section
            slotProps={{
                transition: { unmountOnExit: true },
            }}
        >
            {children}
        </Styled_Section>
    );
};

// Palette

const ColorLine = ({
    name,
    color,
}: {
    name: string;
    color: SimplePaletteColorOptions;
}) => {
    return (
        <>
            <Typography variant="h2">{name}</Typography>
            <Stack direction={"row"} spacing={4}>
                <ColorItem variant="light" color={color} />
                <ColorItem variant="main" color={color} />
                <ColorItem variant="dark" color={color} />
            </Stack>
        </>
    );
};

const ColorItem = ({
    variant,
    color,
}: {
    variant: "light" | "main" | "dark";
    color: SimplePaletteColorOptions;
}) => {
    return (
        <Stack direction={"row"} spacing={4}>
            <Paper
                sx={(theme) => ({
                    borderRadius: theme.shape?.borderRadiusSmall,
                    width: 48,
                    height: 48,
                    backgroundColor: color[variant],
                })}
            ></Paper>
            <div>
                <Typography variant={"body1Medium"}>{variant}</Typography>
                <Typography>{color[variant]}</Typography>
            </div>
        </Stack>
    );
};

const CommonColorItem = ({ color }: { color: keyof CommonColors }) => {
    const theme = useTheme();
    return (
        <Stack direction={"row"} spacing={4}>
            <Paper
                sx={(theme) => ({
                    borderRadius: theme.shape?.borderRadiusSmall,
                    width: 48,
                    height: 48,
                    backgroundColor: theme.palette.common[color],
                })}
            ></Paper>
            <div>
                <Typography variant={"body1Medium"}>{color}</Typography>
                <Typography>{theme.palette.common[color]}</Typography>
            </div>
        </Stack>
    );
};

const BackgroundColorItem = ({ color }: { color: keyof TypeBackground }) => {
    const theme = useTheme();
    return (
        <Stack direction={"row"} spacing={4}>
            <Paper
                sx={(theme) => ({
                    borderRadius: theme.shape?.borderRadiusSmall,
                    width: 48,
                    height: 48,
                    backgroundColor: theme.palette.background[color],
                })}
            ></Paper>
            <div>
                <Typography variant={"body1Medium"}>{color}</Typography>
                <Typography>{theme.palette.background[color]}</Typography>
            </div>
        </Stack>
    );
};

// Tabs
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const DesignLayoutDemo = () => {
    const theme = useTheme();

    // should use key of the interface PaletteOptions instead of string
    const paletteColors: (keyof PaletteOptions)[] = [
        "primary",
        "secondary",
        "tertiary",
        "clear",
        "success",
        "warning",
        "error",
        "inheritWhite",
        "inheritWhiteGhost",
    ];

    const typographyList = [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "button",
        "buttonSmall",
        "body1",
        "body1Medium",
        "body1Bold",
        "body1Underline",
        "body1BoldUnderline",
        "body1Strike",
        "body2",
        "body2Medium",
        "body2Bold",
        "body2Underline",
        "body2Strike",
        "body3",
        "body3Medium",
        "body3Bold",
        "body3Underline",
        "body3Strike",
    ];

    const buttonVariants = ["contained", "outlined", "text"];
    const buttonSizes = ["large", "medium", "small"];
    const buttonColors = [
        undefined,
        "primary",
        "secondary",
        "tertiary",
        "success",
        "warning",
    ];
    const buttonColorsForDarkBg = [
        undefined,
        "inheritWhite",
        "inheritWhiteGhost",
    ];

    // Tabs
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const makeButtons = (color: string, textColor: string) => {
        return (
            <Stack spacing={8} key={`${textColor}-${color}`}>
                <Typography variant={"h2"} color={textColor}>
                    {color || "Undefined"}
                </Typography>
                <Stack
                    spacing={8}
                    // direction={"row"}
                >
                    {buttonSizes.map((size) => (
                        <Stack
                            spacing={8}
                            key={`${textColor}-${color}-${size}`}
                        >
                            {buttonVariants.map((variant) => (
                                <Stack
                                    spacing={8}
                                    key={`${textColor}-${color}-${size}-${variant}`}
                                >
                                    <div>
                                        <Button
                                            variant={
                                                variant as ButtonOwnProps["variant"]
                                            }
                                            color={
                                                color as ButtonOwnProps["color"]
                                            }
                                            size={
                                                size as ButtonOwnProps["size"]
                                            }
                                        >
                                            {variant}
                                        </Button>
                                    </div>
                                    {/*<Button*/}
                                    {/*    variant={variant}*/}
                                    {/*    color={color}*/}
                                    {/*    disabled*/}
                                    {/*>*/}
                                    {/*    {variant}*/}
                                    {/*</Button>*/}
                                </Stack>
                            ))}
                        </Stack>
                    ))}
                    {buttonSizes.map((size) => (
                        <div key={`${textColor}-${color}-${size}-delete`}>
                            <IconButton
                                aria-label="delete"
                                color={color as IconButtonProps["color"]}
                                size={size as IconButtonProps["size"]}
                            >
                                <Icon
                                    variant="light"
                                    icon="layer-group"
                                    fontSize={size as IconOwnProps["fontSize"]}
                                />
                            </IconButton>
                        </div>
                    ))}
                    {/*<IconButton aria-label="delete" disabled color={color}>*/}
                    {/*    <Icon variant="light" icon="layer-group" />*/}
                    {/*</IconButton>*/}
                </Stack>
            </Stack>
        );
    };

    return (
        <>
            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Palette</Typography>
                </AccordionSummary>
                <Stack spacing={4}>
                    {paletteColors.map((color, key) => (
                        <ColorLine
                            key={key}
                            name={color}
                            color={
                                theme.palette[
                                    color as keyof Palette
                                ] as SimplePaletteColorOptions
                            }
                        ></ColorLine>
                    ))}

                    <Typography variant={"h2"}>Common</Typography>
                    <Stack direction={"row"} spacing={4}>
                        <CommonColorItem color="blue" />
                        <CommonColorItem color="brown" />
                        <CommonColorItem color="cyan" />
                        <CommonColorItem color="green" />
                        <CommonColorItem color="magenta" />
                        <CommonColorItem color="orange" />
                        <CommonColorItem color="violet" />
                        <CommonColorItem color="red" />
                        <CommonColorItem color="yellow" />
                    </Stack>
                    <Typography variant={"h2"}>Background</Typography>
                    <Stack direction={"row"} spacing={4}>
                        <BackgroundColorItem color="default" />
                        <BackgroundColorItem color="paper" />
                        <BackgroundColorItem color="paperSecondary" />
                    </Stack>
                </Stack>
            </Section>
            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Typography</Typography>
                </AccordionSummary>
                <Stack direction={"row"} spacing={8}>
                    <Stack>
                        {typographyList.map((typo, key) => (
                            <Typography variant={typo as any} key={key}>
                                {typo}
                            </Typography>
                        ))}
                    </Stack>
                    <Stack>
                        {typographyList.map((typo, key) => (
                            <Typography
                                variant={typo as any}
                                key={key}
                                color={"primary"}
                            >
                                {typo}
                            </Typography>
                        ))}
                    </Stack>
                    <Stack>
                        {typographyList.map((typo, key) => (
                            <Typography
                                variant={typo as any}
                                key={key}
                                color={"success.main"}
                            >
                                {typo}
                            </Typography>
                        ))}
                    </Stack>
                    <Stack>
                        {typographyList.map((typo, key) => (
                            <Typography
                                variant={typo as any}
                                key={key}
                                color={"error.main"}
                            >
                                {typo}
                            </Typography>
                        ))}
                    </Stack>
                </Stack>
            </Section>
            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Buttons</Typography>
                </AccordionSummary>
                <Stack
                    spacing={16}
                    direction={"row"}
                    sx={(theme) => ({
                        backgroundColor: theme.palette.background.default,
                        width: "90vw",
                        overflowX: "auto",
                    })}
                >
                    {buttonColors.map((color) =>
                        makeButtons(color as string, "#000"),
                    )}
                </Stack>
                <Stack
                    spacing={16}
                    direction={"row"}
                    sx={{
                        backgroundColor: COLORS_DARK.moon100,
                        width: "90vw",
                        overflowX: "auto",
                    }}
                >
                    {buttonColorsForDarkBg.map((color) =>
                        makeButtons(color as string, "#FFF"),
                    )}
                </Stack>
            </Section>

            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Inputs</Typography>
                </AccordionSummary>

                <Stack gap={2}>
                    <Stack>
                        <Typography variant="h1">Textfield</Typography>
                        <TMC_TextField label="Outlined" variant="outlined" />
                    </Stack>
                    <TMC_TextField
                        label="Standard with placeholder"
                        variant="standard"
                        placeholder="My placeholder"
                    />
                    <TMC_TextField
                        label="Standard with placeholder"
                        value="My value"
                        variant="standard"
                        disabled
                    />
                    <Stack>
                        <Typography variant="h1">
                            dateTimeRangePicker
                        </Typography>
                        <TMC_DateTimeRangePicker name="dateTimeRangePicker" />
                    </Stack>

                    <TextFieldPassword
                        name="password"
                        label="Password"
                        dataTestId="TextFieldPassword"
                    />

                    <DateRangePicker
                        localeText={{ start: "Check-in", end: "Check-out" }}
                    />
                    <DateRangeCalendar />
                </Stack>
            </Section>
            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Chip</Typography>
                </AccordionSummary>

                <Stack direction="row" spacing={2}>
                    <Chip label="Chip Filled small" size="small" />
                    <Chip label="Chip Filled medium" size="medium" />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Chip label="Chip Filled" />
                    <Chip label="Chip Outlined" variant="outlined" />
                    <Chip
                        label="Deletable"
                        onDelete={() => {
                            console.log("Delete");
                        }}
                    />
                    <Chip
                        label="Deletable"
                        variant="outlined"
                        onDelete={() => {
                            console.log("Delete");
                        }}
                    />

                    <Stack direction="row" spacing={1}>
                        <Chip
                            label="primary"
                            color="primary"
                            variant="outlined"
                        />
                        <Chip
                            label="success"
                            color="success"
                            variant="outlined"
                        />
                        <Chip label="primary" color="primary" />
                        <Chip label="success" color="success" />
                    </Stack>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <Chip label="Chip Click" onClick={() => {}} />
                    <Chip label="Chip Click" variant="outlined" />
                </Stack>

                <Stack direction="row" spacing={2}>
                    <Chip
                        label="Chip outlined disabled"
                        variant="outlined"
                        disabled
                    />
                    <Chip
                        label="Chip outlined not disabled"
                        variant="outlined"
                    />
                    <Chip label="Chip disabled" disabled />
                    <Chip label="Chip not disabled" />
                    {/*
                        TODO faire un composant Chip avec une possibilité
                        actif/inactif au click qui utilise le disable ou changement
                        de variant.
                    */}
                </Stack>
            </Section>

            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Box and container</Typography>
                </AccordionSummary>
                <Box sx={{ border: "solid 1px #f00" }}>Box content</Box>
                <Container sx={{ border: "solid 1px #f00", p: 4 }} fixed>
                    Container fixed
                    <Stack spacing={4} direction="row">
                        <Paper sx={{ width: 200, height: 300 }}>Paper1</Paper>
                        <Paper sx={{ width: 200, height: 300 }}>Paper2</Paper>
                        <Paper sx={{ width: 200, height: 300 }}>Paper3</Paper>
                        <Paper sx={{ width: 200, height: 300 }}>Paper4</Paper>
                    </Stack>
                </Container>
            </Section>
            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Card</Typography>
                </AccordionSummary>
                <Card sx={{ width: 275 }}>
                    <CardContent>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Word of the Day
                        </Typography>
                        <Typography variant="h5" component="div">
                            be nev o lent
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            adjective
                        </Typography>
                        <Typography variant="body2">
                            well meaning and kindly.
                            <br />
                            {'"a benevolent smile"'}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Section>

            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Accordion</Typography>
                </AccordionSummary>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Accordion 1
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        Accordion 2
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        Accordion Actions
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                    </AccordionDetails>
                    <AccordionActions>
                        <Button>Cancel</Button>
                        <Button>Agree</Button>
                    </AccordionActions>
                </Accordion>
            </Section>
            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Appbar</Typography>
                </AccordionSummary>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                        >
                            <Icon variant="solid" icon="bars-filter" />
                        </IconButton>
                        <Typography variant="h2">MUI AppBar</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                            <IconButton
                                size="large"
                                aria-label="show 4 new mails"
                                color="inherit"
                            >
                                <Badge badgeContent={4} color="error">
                                    <Icon variant={"solid"} icon={"user"} />
                                </Badge>
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Section>
            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Tabs</Typography>
                </AccordionSummary>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Item One" {...a11yProps(0)} />
                        <Tab label="Item Two" {...a11yProps(1)} />
                        <Tab label="Item Three" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    Item One
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    Item Two
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    Item Three
                </CustomTabPanel>
            </Section>
            <Section>
                <AccordionSummary>
                    <Typography variant="h2">Alert</Typography>
                </AccordionSummary>
                <Alert severity="success">This is a success Alert.</Alert>
                <Alert severity="info">This is an info Alert.</Alert>
                <Alert severity="warning">This is a warning Alert.</Alert>
                <Alert severity="error">This is an error Alert.</Alert>
            </Section>
        </>
    );
};
