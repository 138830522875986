import { Icon } from "src/components/Components_Common/Icon/Icon";

type keyIcons_Type = {
    [key: string]: JSX.Element;
};

type Type_keyIconsHookForm = {
    [key: string]: string;
};

export const modesIconsHookForm: Type_keyIconsHookForm = {
    project: "rectangle-vertical-history",
    personal: "user",
};

export const roleTypesIconsHookForm: Type_keyIconsHookForm = {
    superAdministrator: "user-shield",
    administrator: "user-shield",
    subContractor: "building",
    tradeManager: "user-helmet-safety",
    user: "user-tie",
};

export const teamotyAppsIcons: keyIcons_Type = {
    Scheduling: <Icon variant="light" icon="calendar-days" />,
    Logistics: <Icon variant="solid" icon="truck-fast" />,
};
