import { GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_resourceModel } from "src/api/tms-commons/resourceModels/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { ResourceModelDrawer } from "src/drawers/resourceModel/ResourceModelDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteResourceModel } from "src/pages/Pages_Teamoty/Configuration/Page_ResourceModels/Page_ResourceModels.container";

export type Page_ResourceModelsComponentPropsType = {
    resourceModels: Type_index_resourceModel[];
    deleteResourceModel: (id: number) => void;
    changeStatus: (id: number, resourceType: Type_index_resourceModel) => void;
    modalDeleteResourceModel: Type_modalDeleteResourceModel;
    setModalDeleteResourceModel: Dispatch<
        SetStateAction<Type_modalDeleteResourceModel>
    >;
    isFetching: boolean;
};

export const Page_ResourceModelsComponent = ({
    resourceModels,
    deleteResourceModel,
    changeStatus,
    modalDeleteResourceModel,
    setModalDeleteResourceModel,
    isFetching,
}: Page_ResourceModelsComponentPropsType) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.ResourceModels",
    );
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "resourceModels";

    const [selectedResourceModel, setSelectedResourceModel] = useState<
        number | null
    >(null);
    const [openResourceModelDrawer, setOpenResourceModelDrawer] =
        useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const openDrawer = (
        resourceModelId: number | null,
        action: Type_action,
    ) => {
        setSelectedResourceModel(resourceModelId);
        setAction(action);
        setOpenResourceModelDrawer(true);
    };

    const handleResourceModelCreate = () => openDrawer(null, "create");

    const handleResourceModelEdit = (resourceFamilyId: number) =>
        openDrawer(resourceFamilyId, "update");

    const handleResourceModelDuplicate = (resourceFamilyId: number) =>
        openDrawer(resourceFamilyId, "duplicate");

    const handleCloseResourceModelDrawer = () => {
        setOpenResourceModelDrawer(false);
    };

    const callbackEdit = (row: GridRowModel) => {
        handleResourceModelEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) => {
        handleResourceModelDuplicate(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row.id, row as Type_index_resourceModel);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteResourceModel({
            isOpen: true,
            props: row as Type_index_resourceModel,
        });
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            sortable: false,
        },
        {
            field: "resourceFamilyName",
            headerName: fmtTableColumn("ResourceFamily"),
            sortable: false,
        },
        {
            field: "unitName",
            headerName: fmtTableColumn("Unit"),
            sortable: false,
        },
    ];

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="ResourceModel-AddOne-btn"
                    onClick={() => handleResourceModelCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={resourceModels}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleResourceModelCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleResourceModelEdit(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <ModalDelete
                open={modalDeleteResourceModel.isOpen}
                onClose={() => setModalDeleteResourceModel({ isOpen: false })}
                item={fmt("DataName")}
                validationString={
                    modalDeleteResourceModel?.props?.name as string
                }
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteResourceModel({ isOpen: false });
                            deleteResourceModel(
                                modalDeleteResourceModel?.props?.id as number,
                            );
                        },
                    },
                }}
            />

            <ResourceModelDrawer
                open={openResourceModelDrawer}
                onClose={handleCloseResourceModelDrawer}
                resourceModelIdToUpdate={selectedResourceModel}
                action={action}
                page={page}
            />
        </>
    );
};
