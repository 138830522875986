import { Components } from "@mui/material";

const GRID_MIN_WIDTH = 860;

export const MuiDataGridTheme: Components["MuiDataGrid"] = {
    defaultProps: {
        disableRowSelectionOnClick: true,
        disableColumnResize: true,
    },
    styleOverrides: {
        paper: ({ theme }: any) => ({
            padding: theme.spacing(8, 10),
            ".MuiDataGrid-panelWrapper .MuiDataGrid-panelFooter .MuiButton-contained":
                {
                    variant: "text",
                    color: theme.palette.primary.main,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
        }),
        root: ({ theme }: any) => ({
            minWidth: GRID_MIN_WIDTH,
            border: "none",
            ".MuiDataGrid-container--top [role=row]": {
                background: "none",
            },
            ".MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.default,
                borderBottom: theme.border.tableHeader,
                ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within":
                    {
                        outline: "none",
                    },
                ".MuiDataGrid-columnSeparator .MuiSvgIcon-root": {
                    opacity: 0,
                },
            },
            ".MuiDataGrid-columnHeader": {
                ...theme.typography.body3Medium,
                padding: theme.spacing(0, 0, 0, 2),
            },
            ".MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-iconButtonContainer":
                {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
            ".MuiDataGrid-cell": {
                ...theme.typography.body1,
                color: theme.palette.text.secondary,
                padding: theme.spacing(2, 0, 2, 2),
                alignContent: "center",
                "&.teamoty-cell-bold": {
                    ...theme.typography.body1Medium,
                    color: theme.palette.text.primary,
                },
                "&.teamoty-cell-menuDropDown": {
                    padding: 0,
                    selfAlign: "center",
                },
                "&.teamoty-cell-disabled": {
                    textDecoration: "line-through",
                    color: theme.palette.text.disabled,
                    ".MuiChip-label": {
                        color: theme.palette.text.disabled,
                    },
                    ".MuiAvatar-root": {
                        textDecoration: "none",
                    },
                },
                "&.teamoty-cell-hidden": {
                    color: theme.palette.text.disabled,
                    ".MuiChip-label": {
                        color: theme.palette.text.disabled,
                    },
                    ".MuiAvatar-root": {
                        textDecoration: "none",
                    },
                    ".MuiIcon-root": {
                        color: theme.palette.text.disabled,
                    },
                },
                "&:focus, &:focus-within": {
                    outline: "none",
                },
            },
            ".MuiTablePagination-root": {
                overflow: "hidden",
            },

            ".Mui-selected": {
                // TODO: should be removed when we found why that class doesn't exist on Mui default theme
                "&.MuiDataGrid-cell--editable": {
                    border: theme.border.selected,
                },
            },
        }),
    },
};
