import * as React from "react";
import { useState } from "react";

import { useShowTask } from "src/api/tms-scheduling/tasks";
import { Type_show_task } from "src/api/tms-scheduling/tasks/types";
import { LoadingBox } from "src/components";
import { GanttTaskForm } from "src/forms/tasks/GanttTaskForm";
import { Type_event_message, useChannel } from "src/hooks/useChannel";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_GanttTaskContextualDrawer = {
    onClose: () => void;
    id: number;
};

export const GanttTaskContextualDrawer = ({
    onClose,
    id: taskId,
}: Type_Props_GanttTaskContextualDrawer) => {
    const [id, setId] = useState<number>(taskId);

    useChannel({
        eventHandler: (data: Type_event_message): void => {
            if (data.event == "updateIdTask") setId(data?.data as number);
        },
    });

    const { isFetching, data } = useShowTask(id);

    return (
        <Styled_PaperContextualLayout
            id="ganttTask"
            data-testid="GanttTaskContextualDrawer"
        >
            {isFetching || !data ? (
                <LoadingBox />
            ) : (
                <GanttTaskForm
                    onClose={onClose}
                    data={data || ({} as Type_show_task)}
                />
            )}
        </Styled_PaperContextualLayout>
    );
};
