import { Box, Stack } from "@mui/material";
import type { Identifier, XYCoord } from "dnd-core";
import update from "immutability-helper";
import { useCallback, useRef } from "react";
import type { Dispatch, RefObject, SetStateAction } from "react";
import { useDrag, useDrop } from "react-dnd";

import { Type_DropItem } from "src/components/Components_Common/DragAndDrop/ReactDnD.poc";
import { COLORS } from "src/theme/stylesheet";

type Type_Props_ReactDnD_SortableListPoc = {
    data: Type_DropItem[];
    setData: Dispatch<SetStateAction<Type_DropItem[]>>;
};

interface Interface_DropItem {
    id: any;
    text: string;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface Interface_DragItem {
    index: number;
    id: string;
    type: string;
}

const ItemTypes: { DROP_ITEM: string } = {
    DROP_ITEM: "dropItem",
};

const DropItem = ({ id, text, index, moveCard }: Interface_DropItem) => {
    const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
        Interface_DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.DROP_ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: Interface_DragItem, monitor): void {
            if (!ref.current) {
                return;
            }
            const dragIndex: number = item.index;
            const hoverIndex: number = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect: DOMRect =
                ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY: number =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset: XYCoord | null = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY: number =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.DROP_ITEM,
        item: (): { id: any; index: number } => {
            return { id, index };
        },
        collect: (monitor: any): { isDragging: any } => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity: 0.7 | 1 = isDragging ? 0.7 : 1;
    drag(drop(ref));

    return (
        <Box
            ref={ref}
            data-handler-id={handlerId}
            style={{
                padding: "0.5rem",
                backgroundColor: COLORS.white,
                cursor: "move",
                opacity,
            }}
        >
            {text}
        </Box>
    );
};

export const ReactDnD_SortableListPoc = ({
    data,
    setData,
}: Type_Props_ReactDnD_SortableListPoc) => {
    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number): void => {
            setData((prevData: Type_DropItem[]) =>
                update(prevData, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevData[dragIndex] as Type_DropItem],
                    ],
                }),
            );
        },
        [],
    );

    const renderCard = useCallback(
        (
            datum: { id: number; name: string; uniqId: string },
            index: number,
        ) => {
            return (
                <DropItem
                    key={datum.uniqId}
                    index={index}
                    id={datum.id}
                    text={datum.name}
                    moveCard={moveCard}
                />
            );
        },
        [data],
    );

    return (
        <>
            {data && (
                <Stack rowGap={2}>
                    {data.map((datum: Type_DropItem, i: number) =>
                        renderCard(datum, i),
                    )}
                </Stack>
            )}
        </>
    );
};
