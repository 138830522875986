import { Type_planning as Type_dataPlanning } from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import {
    PeriodToggleEnum,
    Type_PlanningDataPeriodsFull,
    Type_PlanningFilters,
} from "@cimba-digital-construction-solution/utils/dist/filters/planningFilters";
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useState,
} from "react";

import cursorCheck from "src/assets/fonts/cursor-check.svg";
import { Type_planningProps } from "src/components/Components_Teamoty/Planning/Dates/PlanningDates";
import { Type_state_PlanningViewFilters } from "src/components/Components_Teamoty/Planning/Planning.type";
import { Enum_typeChart } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";

// Define types of planning
export enum PlanningTypes {
    TIME_LOCATION = "time_location",
    GANTT = "gantt",
}

// Define the different types of dialogs that can be opened in the planning
type Dialogs = "daysOff" | "keyDate";

export type Type_planningDialog = {
    daysOff: boolean;
    keyDate: boolean;
};

type Type_planning = {
    dialogs: {
        models: Type_planningDialog; // Stores the boolean values for dialog visibility
        props: Type_planningProps | null | undefined; // Stores any props related to dialogs
    };
};

export type Type_planningContext = {
    type: PlanningTypes;

    // Holds the current data for the planning & the update function
    dataPlanning: Type_dataPlanning | null; //
    setDataPlanning: Dispatch<SetStateAction<Type_dataPlanning | null>>;

    dialogs: {
        models: Type_planningDialog; // Stores the state of each dialog (open or closed)
        props: Type_planningProps | null | undefined; // Holds any associated props for the dialogs
    };
    setDialogState: Dispatch<SetStateAction<Type_planning>>; // Function to update the dialog state
    toggleDialog: (dialog: Dialogs, props?: Type_planningProps) => void; // Function to toggle a dialog (open/close)

    enableTaskDone: boolean; // Boolean value to indicate whether the task completion functionality is enabled
    setEnableTaskDone: (value: boolean) => void; // Function to update the task completion state

    viewFilters: Type_state_PlanningViewFilters; // Holds the current view filters applied to the planning
    setViewFilters: Dispatch<SetStateAction<Type_state_PlanningViewFilters>>; // Function to update the views filters

    defaultCursor: string;
    setDefaultCursor: (value: string) => void;
    toggleTaskDoneStatus: () => void;

    dataFilters: Type_PlanningFilters; // Holds the current filters applied to the planning system
    setDataFilters: Dispatch<SetStateAction<Type_PlanningFilters>>; // Function to update the filters
};

// Define the initial state for the dialogs
const initialDialogs: Type_planning = {
    dialogs: {
        models: {
            daysOff: false, // Initial state for the daysOff dialog (closed)
            keyDate: false, // Initial state for the keyDate dialog (closed)
        },
        props: null, // No initial props for dialogs
    },
};

const PlanningContext = createContext<Type_planningContext | undefined>(
    undefined,
);

export const usePlanningContext = () => {
    const context = useContext(PlanningContext);
    if (!context) {
        throw new Error(
            "usePlanningContext must be used within a PlanningProvider", // Error handling for invalid usage
        );
    }
    return context; // Return the context if valid
};

type Type_Props_PlanningProvider = {
    type: PlanningTypes;
    children: JSX.Element | JSX.Element[];
};

export const PlanningProvider = ({
    type,
    children,
}: Type_Props_PlanningProvider) => {
    const [dataPlanning, setDataPlanning] = useState<Type_dataPlanning | null>(
        null,
    );

    const [dialogState, setDialogState] =
        useState<Type_planning>(initialDialogs);

    const [planningViewFilters, setPlanningViewFilters] =
        useState<Type_state_PlanningViewFilters>({
            withWeekend: false,
            withDaysOff: false,
            workforceChart: Enum_typeChart.none,
            withPlanningData: true,
            withLinksTaskArea: true,
        });

    const [planningDataFilters, setPlanningDataFilters] =
        useState<Type_PlanningFilters>({
            period: {
                type: PeriodToggleEnum.FULL,
            } as Type_PlanningDataPeriodsFull,
            notSelectedTrades: [],
            notSelectedCompanies: [],
            notSelectedSequences: [],
            notSelectedTasks: [],
            notSelectedFolders: [],
            typesTasks: [],
            status: [],
        });

    const toggleDialog = (dialog: Dialogs, props?: Type_planningProps) => {
        setDialogState((prev) => ({
            ...prev, // Maintain previous state
            dialogs: {
                models: {
                    ...prev.dialogs.models, // Keep the previous dialog state
                    [dialog]: !prev.dialogs.models[dialog], // Toggle the visibility of the specified dialog
                },
                props,
            },
        }));
    };

    // Enable Task Done
    const [enableTaskDone, setEnableTaskDone] = useState(false);
    const [defaultCursor, setDefaultCursor] = useState("default");

    const toggleTaskDoneStatus = () => {
        setEnableTaskDone((prev) => {
            const newCursor = prev ? "default" : `url(${cursorCheck}), none`;
            setDefaultCursor(newCursor);
            return !prev;
        });
    };

    return (
        <PlanningContext.Provider
            value={{
                type,

                dataPlanning,
                setDataPlanning,

                dialogs: dialogState.dialogs,
                setDialogState,
                toggleDialog,

                defaultCursor,
                setDefaultCursor,
                toggleTaskDoneStatus,

                enableTaskDone,
                setEnableTaskDone,

                viewFilters: planningViewFilters,
                setViewFilters: setPlanningViewFilters,

                dataFilters: planningDataFilters,
                setDataFilters: setPlanningDataFilters,
            }}
        >
            {children}
        </PlanningContext.Provider>
    );
};
