// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_MenuItemDropDownMenuRightClick = styled(MenuItem)(
    ({ theme }) => ({
        "&.MuiMenuItem-root": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            ...theme.typography.body2,
            minWidth: "320px",
        },
    }),
);
