import { Box, Stack, styled, Typography } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { SelectSubProject } from "src/components/Components_Teamoty/forms/select/SelectSubProject";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { NavigationMenu } from "src/layouts/Layout_Sidebar/NavigationMenu/NavigationMenu";
import { Styled_StackSideBar } from "src/layouts/Layout_Sidebar/NavigationMenu/NavigationMenu.style";
import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";
import { URL_BASE } from "src/router";
import { COLORS } from "src/theme/stylesheet";

export const Styled_SelectSubProject = styled(SelectSubProject)(
    ({ theme }) => ({
        justifySelf: "center",
        ...theme.typography.body1Medium,
        height: "32px",
        padding: 0,
        "& .MuiInputBase-root": {
            border: "none",
            boxShadow: "none",
            svg: {
                color: COLORS.dark,
            },
        },
    }),
);

export const SubProjectsNavigationMenu = memo(
    function SubProjectsNavigationMenu({
        nameLabel,
        baseUrl,
        blocks,
    }: Type_sidebar_configuration) {
        const { formatMessageWithPartialKey: fmt } = useCoreIntl(
            `MenuSidebar.${nameLabel}`,
        );
        const navigate = useNavigate();
        const urlParams = useParams();
        const { requestConfig } = useProject();

        /////////////////////////////////
        /// States                    ///
        /////////////////////////////////
        const [subProjectId, setSubProjectId] = useState<string | undefined>(
            (urlParams?.subProjectId as string) ?? requestConfig.subProjectId,
        );

        /////////////////////////////////
        /// Url functions             ///
        /////////////////////////////////

        const generateBaseUrl = useCallback((subProjectId: string) => {
            return generatePath(`${baseUrl}`, {
                ...urlParams,
                subProjectId: subProjectId,
            });
        }, []);

        const handleSubProjectChange = (newSubProjectId: string) => {
            setSubProjectId(newSubProjectId);
            // replace subProjectId in url and re
            const newUrlParams = { ...urlParams };
            newUrlParams.subProjectId = newSubProjectId;
            navigate(
                generatePath(`${URL_BASE}${baseUrl}/*`, {
                    ...(newUrlParams as { "*": string }),
                }),
            );
        };

        return (
            <Stack data-testid={`Sidebar-Settings-${nameLabel}`}>
                <Styled_StackSideBar>
                    <Typography
                        data-testid={`Sidebar-Settings-Title-${nameLabel}`}
                        variant="body3Bold"
                        color={COLORS.moonAlpha500}
                    >
                        {fmt(`Title`)}
                    </Typography>
                </Styled_StackSideBar>
                <Box
                    sx={(theme) => ({
                        m: theme.spacing(2),
                    })}
                >
                    <Styled_SelectSubProject
                        name={"subproject"}
                        setSubProjectId={setSubProjectId}
                        value={subProjectId}
                        onChange={(e) => {
                            handleSubProjectChange(e.target.value);
                        }}
                    />
                </Box>
                {subProjectId && (
                    <NavigationMenu
                        nameLabel={nameLabel}
                        baseUrl={generateBaseUrl(subProjectId as string)}
                        blocks={blocks}
                    />
                )}
            </Stack>
        );
    },
);
