import { FormikValues } from "formik";

import { post, PostReturnType } from "src/api/fetch";
import { AuthPath, Url, UsersPath } from "src/api/paths";
import { Type_gtw_post_register } from "src/api/tms-gateway/auth/types";
import {
    Type_usr_post_newPassword,
    Type_usr_post_resetPassword,
} from "src/api/tms-users/auth/types";

export const register = (formValue: Type_gtw_post_register): PostReturnType => {
    return post(formValue, `${Url.USERS}${AuthPath.REGISTER}`);
};

export const confirmMail = (id: string, hash: string): PostReturnType => {
    return post({}, `${Url.USERS}${AuthPath.CONFIRM_MAIL}/${id}/${hash}`);
};

export const resetPassword = (
    data: Type_usr_post_resetPassword,
): PostReturnType => {
    return post({ ...data }, `${Url.USERS}${AuthPath.FORGOT_PASSWORD}`);
};

export const newPassword = (
    data: Type_usr_post_newPassword,
): PostReturnType => {
    return post({ ...data }, `${Url.USERS}${AuthPath.CHANGE_PASSWORD}`);
};

export const changePassword = (
    password: string,
    token: string,
    email: string,
): PostReturnType => {
    return post(
        { password, token, email },
        `${Url.USERS}${AuthPath.CHANGE_PASSWORD}`,
    );
};

/* Permet de valider les CGU. */
export const validateCgu = (): PostReturnType => {
    return post({}, `${Url.USERS}${AuthPath.VALIDATE_CGU}`);
};

/* Vérifie que les CGU ont été validé par l'user. */
export const checkCguValidation = (): PostReturnType => {
    return post({}, `${Url.USERS}${AuthPath.CHECK_CGU_VALIDATION}`);
};

export const changeCurrentPassword = ({
    currentPassword,
    password,
    passwordConfirm: confirmPassword,
}: FormikValues) => {
    return post(
        {
            currentPassword,
            password,
            confirmPassword,
        },
        `${Url.USERS}${UsersPath.USERS_CHANGE_PASSWORD}`,
    );
};
