import {} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

import { Type_Props_FlowToolbarElm } from "./FlowToolbar.type";

const Styled_FlowToolbarButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
}));

export const FlowToolbarButton = ({
    icon,
    code,
    title,
    container,
    variant = "light",
}: Type_Props_FlowToolbarElm) => {
    return (
        <Styled_FlowToolbarButton
            data-testid={"FlowTooltipButton-display-" + icon}
            id={"display-" + icon}
            size={"small"}
            title={title}
            onClick={(): void => {
                const e: KeyboardEvent = new KeyboardEvent("keydown", {
                    key: code,
                    code: code,
                    bubbles: true,
                    cancelable: true,
                });
                container?.dispatchEvent(e);
            }}
        >
            <Icon variant={variant} icon={icon} fontSize="small" />
        </Styled_FlowToolbarButton>
    );
};
