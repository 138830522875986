import { Stack } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { ProjectPath, Url } from "src/api/paths";
import { getImage } from "src/api/storage";
import { Type_index_project } from "src/api/tms-commons/projects/types";
import { Type_MenuItem_DropDown } from "src/components/Components_Common/DropdownMenu/DropdownMenu";
import { ProjectCard } from "src/components/Components_Teamoty/cards/ProjectCard/ProjectCard";
import { UpdateProjectDrawer } from "src/drawers/project/UpdateProjectDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_modalDeleteProject } from "src/pages/Pages_Teamoty/Page_Projects/Page_Projects.container";

export type Type_dataTestIdRef = "Favorites" | "List" | "Archived";

type Type_Props_Card = {
    card: Type_index_project;
    isFav: boolean;
    addProjectToFavourites: (id: number) => void;
    deleteFavouriteProject: (id: number) => void;
    handleProjectStatus: (project: Type_index_project) => void;
    handleProjectArchived: (id: number, project: Type_index_project) => void;
    modalDeleteProject?: Type_modalDeleteProject;
    setModalDeleteProject?: Dispatch<SetStateAction<Type_modalDeleteProject>>;
    dataTestIdRef: Type_dataTestIdRef;
};

const Card = ({
    card,
    isFav,
    addProjectToFavourites,
    deleteFavouriteProject,
    handleProjectStatus,
    handleProjectArchived,
    setModalDeleteProject,
    dataTestIdRef,
}: Type_Props_Card) => {
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Form.Cta");
    const [img, setImg] = useState("");

    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };
    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const menuItems = [
        card.enabled && {
            label: fmtCta("Update"),
            icon: {
                variant: "light",
                name: "pen",
            },
            call: handleOpenDrawer,
        },
        {
            label: fmtCta(card.archived ? "Unarchived" : "Archive"),
            icon: {
                variant: "light",
                name: "box-archive",
            },
            call: () => handleProjectArchived(card.id, card),
        },
    ].filter(Boolean) as Type_MenuItem_DropDown[];

    useEffect(() => {
        const fetchImg = async () => {
            const res = await getImage(
                card.id,
                Url.COMMONS,
                ProjectPath.PROJECTS,
                "thumbnail",
                card.thumbnail as string,
            );
            const imageObjectURL = URL.createObjectURL(res.data);
            setImg(imageObjectURL);
        };
        fetchImg();
    }, [card.id]);

    return (
        <>
            <ProjectCard
                isFav={isFav}
                menuItems={menuItems}
                card={card}
                status={card.status}
                img={img}
                addProjectToFavourites={addProjectToFavourites}
                deleteFavouriteProject={deleteFavouriteProject}
                handleProjectStatus={handleProjectStatus}
                setModalDeleteProject={setModalDeleteProject}
                dataTestIdRef={dataTestIdRef}
            />
            <UpdateProjectDrawer
                projectIdToUpdate={card.id}
                page={"projects"}
                open={openDrawer}
                onClose={handleCloseDrawer}
                tab={"0"}
            />
        </>
    );
};

export type Type_Props_Cards_ProjectsComponent = {
    cards: Type_index_project[];
    addProjectToFavourites: (id: number) => void;
    deleteFavouriteProject: (id: number) => void;
    handleProjectStatus: (project: Type_index_project) => void;
    handleProjectArchived: (id: number, project: Type_index_project) => void;
    modalDeleteProject?: Type_modalDeleteProject;
    setModalDeleteProject?: Dispatch<SetStateAction<Type_modalDeleteProject>>;
    dataTestIdRef: Type_dataTestIdRef;
};

export const Cards_ProjectsComponent = ({
    cards,
    addProjectToFavourites,
    deleteFavouriteProject,
    handleProjectStatus,
    handleProjectArchived,
    modalDeleteProject,
    setModalDeleteProject,
    dataTestIdRef,
}: Type_Props_Cards_ProjectsComponent) => {
    if (cards.length)
        return (
            <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                gap={4}
                data-testid={`Projects-Home-${dataTestIdRef}-ProjectCards`}
            >
                {cards.map((card: Type_index_project, index: number) => (
                    <Card
                        isFav={card.favouriteId !== undefined}
                        key={`${index}-card.name`}
                        card={card}
                        addProjectToFavourites={addProjectToFavourites}
                        deleteFavouriteProject={deleteFavouriteProject}
                        handleProjectStatus={handleProjectStatus}
                        handleProjectArchived={handleProjectArchived}
                        modalDeleteProject={modalDeleteProject}
                        setModalDeleteProject={setModalDeleteProject}
                        dataTestIdRef={dataTestIdRef}
                    />
                ))}
            </Stack>
        );
    return null;
};
