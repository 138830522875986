import { Box, styled, Tab, Tabs } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { COLORS } from "src/theme/stylesheet";

export const APP_BAR_HEIGHT = 40;

export const Styled_AppBar = styled(AppBar)(() => ({
    px: 3,
    backgroundColor: COLORS.teamotyBlue800,
    height: APP_BAR_HEIGHT,
    maxHeight: APP_BAR_HEIGHT,
    minHeight: APP_BAR_HEIGHT,
    color: COLORS.white,
    flex: 1,
}));

export const Styled_Toolbar = styled(Toolbar)(() => ({
    "&.MuiToolbar-root": {
        padding: 0,
        maxHeight: APP_BAR_HEIGHT,
        minHeight: APP_BAR_HEIGHT,
        height: APP_BAR_HEIGHT,
    },
    justifyContent: "space-between",
    flex: 1,
}));

export const Styled_PagesTab = styled(Tab)(({ theme }) => ({
    "&.MuiTab-root": {
        textTransform: "none",
        backgroundColor: "transparent",
        color: COLORS.white,
        fontWeight: 500,
        fontSize: "14px",
        minWidth: "1px",
        lineHeight: "20px",
        height: APP_BAR_HEIGHT,
        minHeight: APP_BAR_HEIGHT,
        padding: theme.spacing(2),
        "&.Mui-selected": {
            color: COLORS.white,
        },
        "&:hover": {
            backgroundColor: theme.palette.navbar.hover,
            color: theme.palette.primary.contrastText,
        },
    },
})) as typeof Tab;

export const Styled_PagesTabs = styled(Tabs)(() => ({
    height: APP_BAR_HEIGHT,
    minHeight: APP_BAR_HEIGHT,
    span: {
        "&.MuiTabs-indicator": {
            backgroundColor: `${COLORS.white}`,
        },
    },
})) as typeof Tabs;

export const TeamotyLogoBlur = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0),
    backgroundImage: "url(/src/assets/images/logoTeamoty.png)",
    backgroundSize: "cover",
    width: 105,
    height: 105,
    gap: 0,
    position: "absolute",
    opacity: "0.8px",
    left: -21,
    bottom: -21,
    filter: "blur(30px)",
}));

export const TopBarLeft_IconButton = styled(IconButton)(({ theme }) => ({
    "&:hover:not(.Mui-selected)": {
        backgroundColor: theme.palette.navbar.hover,
        color: theme.palette.primary.contrastText,
    },
    borderRadius: 0,
    boxShadow: theme.boxShadow.navbar,
}));

export const TopBarRight_IconButton = styled(IconButton)(({ theme }) => ({
    ":hover": { backgroundColor: theme.palette.navbar.hover },
}));
