// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

type Styled_TextFieldProps = {
    ownerState?: {
        isRtl?: boolean;
        isLtrInput?: boolean;
    };
};

export const Styled_TextField = styled(TextField)<
    Styled_TextFieldProps & TextFieldProps
>(({ ownerState, theme }) => ({
    "& .MuiOutlinedInput-root": {
        border: `1px solid ${COLORS.gray1}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0px 1px 1px rgba(25, 49, 84, 0.1)",
        color: COLORS.moon950,
        "& input, & textarea, & .MuiSelect-select": {
            direction: ownerState?.isLtrInput ? "ltr" : "rtl",
            padding: theme.spacing(2),
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            boxShadow: "none", // Enleve la sélection du focus
            "&::placeholder": {
                color: COLORS.gray,
            },
        },
        "&.Mui-focused": {
            borderColor: COLORS.electricBlue,
        },
        "&.Mui-error": {
            color: COLORS.inputAlert,
        },
        "& .MuiInputBase-input ": {
            boxShadow: "none", // Enleve la boxShadow des TMC_TextFieldWithUnit
        },

        "& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
                border: "none",
            },
    },
    // LABEL
    "& .MuiInputLabel-root": {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        color: COLORS.moon500,
        transform: "none",
        transition: "none",
        position: "inherit",
        marginBottom: theme.spacing(1),
        padding: 0,
        "&.Mui-focused, &.Mui-error, &.Mui-disabled ": {
            color: COLORS.moon500,
            backgroundColor: COLORS.white,
        },
    },
    // ASTERIX ERROR
    "& .MuiFormLabel-asterisk.Mui-error": {
        color: COLORS.moon500,
    },
    // DISABLED
    "& .Mui-disabled.MuiInputBase-input ": {
        backgroundColor: COLORS.grayDisabled,
        color: COLORS.grayPlaceholder,
    },
}));

export const Styled_RoundedTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        padding: 0,
        border: "none",
        boxShadow: "none",
        "& input, & textarea, & .MuiSelect-outlined": {
            padding: 0,
            border: "none",
            boxShadow: "none",
        },
        "& .MuiOutlinedInput-root,& .MuiOutlinedInput-input, & .Mui-error": {
            border: `none`,
            boxShadow: "none",
        },
        "&.Mui-error.Mui-focused, &.Mui-error:hover": {
            border: `none`,
        },
        "& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
                border: "none",
            },
        borderRadius: theme.shape.borderRadius,
        color: COLORS.moon950,
        "& input, & textarea, & .MuiSelect-select": {
            padding: theme.spacing(2),
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            boxShadow: "none", // Enleve la sélection du focus
            "&::placeholder": {
                color: COLORS.gray,
            },
        },
        "&.Mui-focused": {
            borderColor: COLORS.electricBlue,
        },
        "&.Mui-error": {
            color: COLORS.inputAlert,
        },
    },
    // LABEL
    "& .MuiInputLabel-root": {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        color: COLORS.moon500,
        transform: "none",
        transition: "none",
        position: "inherit",
        marginBottom: theme.spacing(1),
        padding: 0,
        "&.Mui-focused, &.Mui-error, &.Mui-disabled ": {
            color: COLORS.moon500,
            backgroundColor: COLORS.white,
        },
    },
    // ASTERIX ERROR
    "& .MuiFormLabel-asterisk.Mui-error": {
        color: COLORS.moon500,
    },
    // DISABLED
    "& .Mui-disabled.MuiInputBase-input ": {
        backgroundColor: COLORS.grayDisabled,
        color: COLORS.grayPlaceholder,
    },
}));

export const Styled_RoundedTextFieldNumber = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        border: "none",
        boxShadow: "none",
        padding: 0,
        "&:hover": {
            cursor: "pointer",
        },
        "& input, & textarea": {
            padding: 0,
            textAlign: "center",
            /* Ajout des propriétés pour enlever les boutons (+/-) natifs */
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            /* Firefox */
            "&[type=number]": {
                "-moz-appearance": "textfield",
            },
        },
        "& .MuiOutlinedInput-root, &.Mui-error": {
            border: `none`,
            boxShadow: "none",
        },
        "&.Mui-error.Mui-focused, &.Mui-error:hover": {
            border: `none`,
        },
        "& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
                border: "none",
            },
        borderRadius: theme.shape.borderRadius,
        color: COLORS.moon950,
        "& input, & textarea, & .MuiSelect-select": {
            padding: theme.spacing(2),
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            boxShadow: "none", // Enleve la sélection du focus
            "&::placeholder": {
                color: COLORS.gray,
            },
        },
        "&.Mui-focused": {
            borderColor: COLORS.electricBlue,
        },
        "&.Mui-error": {
            boxShadow: "0px 1px 2px rgba(254, 104, 95, 0.15)",
            color: COLORS.inputAlert,
            border: `solid 1px ${COLORS.inputAlert}`,
            "&:hover, &.Mui-focused": {
                border: `solid 1px ${COLORS.inputAlert}`,
            },
        },
    },
    // LABEL
    "& .MuiInputLabel-root": {
        "&.Mui-disabled ": {
            backgroundColor: COLORS.grayDisabled,
        },
    },
}));
