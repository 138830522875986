import {
    Type_index_progressHistory,
    Type_post_progressHistory,
    Type_put_progressHistory,
    Type_sch_index_progressHistory,
    Type_sch_post_progressHistory,
    Type_sch_put_progressHistory,
    Type_sch_show_progressHistory,
    Type_show_progressHistory,
} from "./types";

export const formatterIndexProgressHistory = (
    progressHistory: Type_sch_index_progressHistory,
): Type_index_progressHistory => {
    return {
        id: progressHistory.id,
        taskId: progressHistory.task_id,
        areaId: progressHistory.area_id,
        value: progressHistory.value,
        date: progressHistory.date,
        subProject: progressHistory.subProject,
        version: progressHistory.version,
        user: progressHistory.user,
    };
};

export const formatterIndexProgressHistories = (
    progressHistories: Type_sch_index_progressHistory[],
): Type_index_progressHistory[] => {
    return progressHistories.map(formatterIndexProgressHistory);
};

export const formatterShowProgressHistory = (
    progressHistory: Type_sch_show_progressHistory,
): Type_show_progressHistory => {
    return {
        id: progressHistory.id,
        taskId: progressHistory.task_id,
        areaId: progressHistory.area_id,
        value: progressHistory.value,
        date: progressHistory.date,
        subProject: progressHistory.subProject,
        version: progressHistory.version,
        user: progressHistory.user,
    };
};

export const formatterCreateProgressHistory = (
    progressHistory: Type_post_progressHistory,
): Type_sch_post_progressHistory => {
    return {
        task_id: progressHistory.taskId,
        area_id: progressHistory.areaId,
        date: progressHistory.date,
        value: progressHistory.value,
    };
};

export const formatterUpdateProgressHistory = (
    progressHistory: Type_put_progressHistory,
): Type_sch_put_progressHistory => {
    return progressHistory;
};
