// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { OutlinedInput, OutlinedInputProps } from "@mui/material";
import { styled } from "@mui/material/styles";

type Styled_OutlinedInputProps = {
    ownerState?: {
        isRtl?: boolean;
        isLtrInput?: boolean;
    };
};

export const Styled_OutlinedInput = styled(OutlinedInput)<
    Styled_OutlinedInputProps & OutlinedInputProps
>(({ ownerState }) => ({
    direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    "& input, & textarea": {
        direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    },
}));

export const Styled_OutlinedInputColorPicker = styled(OutlinedInput)<
    Styled_OutlinedInputProps & OutlinedInputProps
>(({ ownerState }) => ({
    paddingInlineStart: "8px",
    direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    "& input, & textarea": {
        direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    },
}));

export const Styled_OutlinedInputBlock = styled(OutlinedInput)<
    Styled_OutlinedInputProps & OutlinedInputProps
>(({ ownerState }) => ({
    paddingLeft: "8px",
    direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    "& input, & textarea": {
        direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    },
}));

export const Styled_RoundedOutlinedInput = styled(OutlinedInput)<
    Styled_OutlinedInputProps & OutlinedInputProps
>(({ ownerState }) => ({
    direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    border: "none",
    boxShadow: "none",
    paddingRight: 0,
    "& input": {
        padding: 0,
    },
    "&.Mui-error": {
        border: "none",
        boxShadow: "none",
    },
}));

export const Styled_RoundedOutlinedInputColorPicker = styled(OutlinedInput)<
    Styled_OutlinedInputProps & OutlinedInputProps
>(({ ownerState }) => ({
    direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    border: "none",
    boxShadow: "none",
    paddingInlineEnd: 0,
    paddingInlineStart: 0,
    "& input": {
        padding: 0,
    },
    "&.Mui-error": {
        border: "none",
        boxShadow: "none",
    },
}));
