import { Stack } from "@mui/material";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { AutocompleteAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreas";
import { AutocompleteAreasCustom } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreasCustom";
import { AutocompleteAreaTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreaTypes";
import { AutocompleteCompanies } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteCompanies";
import { AutocompleteDrawings } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteDrawings";
import { AutocompleteParentAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteParentAreas";
import { AutocompleteProjectAreaTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteProjectAreaTypes";
import { AutocompleteResources } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResources";
import { AutocompleteResourceTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResourceType";
import { AutocompleteRoles } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteRoles";
import { AutocompleteSubTrades } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteSubTrades";
import { AutocompleteTasks } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteTasks";
import { AutocompleteTrades } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteTrades";
import { AutocompleteUsers } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteUsers";

export const Autocompletes = () => {
    const form = useForm<any>({
        defaultValues: {
            company: null,
            areasCustom: null,
            areas: {
                id: 2,
                name: "Logement A1 (en_GB)",
            },
            areasMultiple: [
                {
                    id: 2,
                    name: "Logement A1 (en_GB)",
                },
                {
                    id: 4,
                    name: "Salle 1 (en_GB)",
                },
            ],
            task: {
                code: "TE2",
                color: "#CC0000",
                depth: 2,
                folderName: "folderSeq B1 (en_GB)",
                id: 203,
                name: "Task E2 (en_GB)",
                sequenceColor: "#FF0000",
                sequenceName: "Sequence C1 (en_GB)",
                taskType: 2,
                type: "task",
            },
            tasks: [
                {
                    code: "TE2",
                    color: "#CC0000",
                    depth: 2,
                    folderName: "folderSeq B1 (en_GB)",
                    id: 203,
                    name: "Task E2 (en_GB)",
                    sequenceColor: "#FF0000",
                    sequenceName: "Sequence C1 (en_GB)",
                    taskType: 2,
                    type: "task",
                },
                {
                    id: 202,
                    name: "Task F1 (en_GB)",
                    depth: 2,
                    type: "task",
                    folderName: "folderSeq B1 (en_GB)",
                    sequenceName: "Sequence C1 (en_GB)",
                    sequenceColor: "#FF0000",
                    color: "#0000CC",
                    code: "TF1",
                    taskType: 2,
                },
            ],
            areasSelect: 1,
            areasSelectMultiple: [3, 2],
        },
    });

    return (
        <FormProvider {...form}>
            <Stack gap={2}>
                <AutocompleteAreasCustom label={"Areas"} name={"areasCustom"} />
                <AutocompleteAreas
                    label={"Areas"}
                    name={"areas"}
                    renderInputProps={{ variant: "outlined" }}
                />
                <AutocompleteAreas
                    label={"Areas"}
                    name={"areasMultiple"}
                    multiple
                    renderInputProps={{ variant: "outlined" }}
                />
                <AutocompleteTasks
                    label={"Task"}
                    name={"task"}
                    renderInputProps={{ variant: "outlined" }}
                    getOptionDisabled={(option) => option.id === 5}
                />
                <AutocompleteTasks
                    label={"Tasks"}
                    name={"tasks"}
                    renderInputProps={{ variant: "outlined" }}
                    multiple
                    getOptionDisabled={(option) => option.id === 5}
                />

                <AutocompleteAreaTypes label={"AreaTypes"} name={"areaTypes"} />
                <AutocompleteProjectAreaTypes
                    label={"Project AreaTypes"}
                    name={"projectAreaTypes"}
                />
                <AutocompleteCompanies label={"Companies"} name={"companies"} />
                <AutocompleteDrawings label={"Drawings"} name={"drawings"} />
                <AutocompleteParentAreas
                    label={"ParentAreas"}
                    name={"parentAreas"}
                />
                <AutocompleteResources label={"Resources"} name={"resources"} />
                <AutocompleteResourceTypes
                    label={"ResourceTypes"}
                    name={"resourceTypes"}
                />
                <AutocompleteRoles label={"Roles"} name={"roles"} />
                <AutocompleteSubTrades label={"SubTrades"} name={"subTrades"} />
                <AutocompleteTasks label={"Tasks"} name={"tasks"} />
                <AutocompleteTrades label={"Trades"} name={"trades"} />
                <AutocompleteUsers label={"Users"} name={"users"} />
            </Stack>
        </FormProvider>
    );
};
