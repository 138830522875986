import { useCallback } from "react";
import { useQuery } from "react-query";

import { selectListAreaWorkLevels } from "src/api/tms-scheduling/areas/services";
import { AreaWorkLevelKeys } from "src/api/tms-scheduling/keys";
import { Type_selectList } from "src/api/types";
import { formatSelectListToSelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { SMALL_STALE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const transformData = (
    data: Type_selectList[],
    withCurrent: boolean,
    name: string,
) => {
    // Increment the 'id' of each item by 1
    const dataCopy = data.map((item) => ({
        ...item,
        id: (item.id as number) + 1,
    }));

    // Remove the last item
    dataCopy.pop();

    // If withCurrent is true, add the 'current' item
    if (withCurrent) {
        dataCopy.unshift({ id: 0, name: name });
    }

    return dataCopy;
};

export const useSelectListAreaWorkLevels = (
    sequenceId?: number,
    withCurrent: boolean = false,
) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Form.Field");
    const { requestConfig } = useProject();

    const newRequestConfig = { ...requestConfig, sequenceId: sequenceId };

    return useQuery({
        queryKey: [AreaWorkLevelKeys.SELECT_LIST, newRequestConfig],
        queryFn: () => selectListAreaWorkLevels(newRequestConfig),
        refetchOnWindowFocus: false,
        select: useCallback((data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useSelectListAreaWorkLevels",
                );
            }
            const transformedData = transformData(
                data?.data?.data,
                withCurrent,
                fmt("Current"),
            );
            return formatSelectListToSelectOptionItem(transformedData);
        }, []),
        enabled: !!requestConfig.projectId && !!sequenceId,
        staleTime: SMALL_STALE_TIME,
    });
};
