import Konva from "konva";

import { Type_point } from "src/components/Components_Common/canvas/types";

export const flowShapeDeliveryFunction = (
    pt: Type_point,
    width: number,
    height: number,
    context: Konva.Context,
    shape?: Konva.Shape,
): void => {
    const x: number = pt.x + width / 2;
    const y: number = pt.y + height / 2;

    context.beginPath(); // commence le dessin
    context.arc(x, y, width / 2, 0, 2 * Math.PI);
    context.closePath(); // on arrête le dessin

    shape && context.fillStrokeShape(shape);
};
