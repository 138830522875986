import { Stack } from "@mui/material";

import { Spinner } from "./Spinner";

export const FullSpinner = () => {
    return (
        <Stack
            width={"100%"}
            height={"100%"}
            justifyContent={"center"}
            alignItems="center"
        >
            <Spinner />
        </Stack>
    );
};
