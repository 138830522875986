import { FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useState } from "react";

import { TMC_Button, TMC_LoadingButton } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

export const Secondary = () => {
    const [loading, setLoading] = useState(true);

    function handleClick() {
        setLoading(true);
    }

    return (
        <>
            {/* SECONDARY */}
            <Grid item xs={1}>
                SECONDARY
            </Grid>
            <Grid item xs={1}>
                <TMC_Button
                    color="secondary"
                    size="large"
                    endIcon={
                        <Icon variant="solid" icon="check" fontSize={"large"} />
                    }
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_Button
                    color="secondary"
                    size="large"
                    disabled={true}
                    endIcon={
                        <Icon variant="solid" icon="check" fontSize={"large"} />
                    }
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_LoadingButton
                    size="large"
                    onClick={handleClick}
                    loading={loading}
                    color="secondary"
                    endIcon={
                        <Icon variant="solid" icon="check" fontSize={"large"} />
                    }
                >
                    <span>Button</span>
                </TMC_LoadingButton>
            </Grid>
            <Grid item xs={1}>
                <FormControlLabel
                    sx={{
                        display: "block",
                    }}
                    control={
                        <Switch
                            checked={loading}
                            onChange={() => setLoading(!loading)}
                            name="loading"
                            color="secondary"
                        />
                    }
                    label="Loading"
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton color="secondary" size="large">
                    <Icon variant="solid" icon="pen" fontSize={"large"} />
                </IconButton>
            </Grid>
            <Grid item xs={1}>
                <IconButton color="secondary" size="large" disabled={true}>
                    <Icon variant="solid" icon="pen" fontSize={"large"} />
                </IconButton>
            </Grid>

            {/* MEDIUM */}
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <TMC_Button
                    color="secondary"
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_Button
                    color="secondary"
                    disabled={true}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_LoadingButton
                    onClick={handleClick}
                    loading={loading}
                    color="secondary"
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    <span>Button</span>
                </TMC_LoadingButton>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <IconButton color="secondary">
                    <Icon variant="solid" icon="pen" />
                </IconButton>
            </Grid>
            <Grid item xs={1}>
                <IconButton color="secondary" disabled={true}>
                    <Icon variant="solid" icon="pen" />
                </IconButton>
            </Grid>

            {/* SMALL */}
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <TMC_Button
                    color="secondary"
                    size="small"
                    endIcon={
                        <Icon variant="solid" icon="check" fontSize={"small"} />
                    }
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_Button
                    color="secondary"
                    size="small"
                    disabled={true}
                    endIcon={
                        <Icon variant="solid" icon="check" fontSize={"small"} />
                    }
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_LoadingButton
                    size="small"
                    onClick={handleClick}
                    loading={loading}
                    color="secondary"
                    disabled={true}
                    endIcon={
                        <Icon variant="solid" icon="check" fontSize={"small"} />
                    }
                >
                    <span>Disabled</span>
                </TMC_LoadingButton>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <IconButton color="secondary" size="small">
                    <Icon variant="solid" icon="pen" fontSize="small" />
                </IconButton>
            </Grid>
            <Grid item xs={1}>
                <IconButton color="secondary" size="small" disabled={true}>
                    <Icon variant="solid" icon="pen" fontSize="small" />
                </IconButton>
            </Grid>
        </>
    );
};
