import {
    MenuProps,
    Select,
    SelectProps,
    Typography,
    useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { forwardRef, ReactElement } from "react";

import { invertColor } from "src/utils/colors";

type Type_Styled_SelectProps = SelectProps & {
    ownerState?: { bgColor?: string; textColor?: string };
};

const Styled_Select = styled(Select)<Type_Styled_SelectProps>(
    ({ theme, ownerState }) => ({
        "&.MuiInputBase-root": {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: ownerState?.bgColor,
            color: ownerState?.textColor,
            padding: theme.spacing(0.5, 1),
            maxHeight: 29,
            ...theme.typography.body2,
        },
        "& .MuiSelect-select": {
            padding: 0,
        },
        "& .MuiSelect-icon": {
            color: ownerState?.textColor,
        },
    }),
);

export type Type_ItemChip = {
    label: string;
    value: string | number;
    chipColor?: string;
    icon?: ReactElement;
};

export type Type_Props_ChipSelectGeneric = SelectProps & {
    items: Type_ItemChip[];
    name: string;
    ownerState?: { bgColor: string; textColor?: string };
    renderOption?: (item: Type_ItemChip) => ReactElement | string;
};

const ChipSelectGeneric = forwardRef<
    HTMLSelectElement,
    Type_Props_ChipSelectGeneric
>(({ items, ownerState, renderOption, value, onChange, ...props }, ref) => {
    const theme = useTheme();

    const CustomdMenuProps: Partial<MenuProps> = {
        PaperProps: {
            style: {
                transformOrigin: "top left",
                boxShadow: theme.boxShadow.menuList,
                border: theme.border.default,
                borderRadius: theme.shape.borderRadius,
            },
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left",
        },
    };

    return (
        <Styled_Select
            value={value}
            ref={ref}
            MenuProps={CustomdMenuProps}
            ownerState={{
                bgColor: ownerState?.bgColor,
                textColor:
                    ownerState?.textColor ||
                    invertColor(ownerState?.bgColor as string),
            }}
            onChange={onChange}
            renderValue={(val) => {
                const selectedOp = items.find((item) => item.value === val);
                return (
                    <Typography variant="body3">{selectedOp?.label}</Typography>
                );
            }}
            {...props}
        >
            {items.map((item, index) => (
                <MenuItem
                    data-testid={`chip-item-${item.value}`}
                    key={index}
                    value={item.value}
                    sx={{ minWidth: 250 }}
                    color="black"
                >
                    {renderOption?.(item) ?? item.label}
                </MenuItem>
            ))}
        </Styled_Select>
    );
});

ChipSelectGeneric.displayName = "ChipSelectGeneric";

export default ChipSelectGeneric;
