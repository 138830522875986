import {
    DialogProps,
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    Stack,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { mutationCreateView } from "src/api/tms-projects/workspacesAndViews/views";
import { formatterCreateView } from "src/api/tms-projects/workspacesAndViews/views/formatters";
import {
    Type_default_view,
    Type_index_view,
} from "src/api/tms-projects/workspacesAndViews/views/types";
import { LoadingBox, TMC_Button } from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { useWorkspaceViewContext } from "src/contexts/workspaces";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getDefaultViews } from "src/layouts/Layout_Workspace/configurations/views";
import { Styled_ViewContainer } from "src/layouts/Layout_Workspace/Views/stylesheet";
import { ViewCard } from "src/layouts/Layout_Workspace/Views/ViewCard";

type Type_Props_ViewsSelection = DialogProps & {
    handleClose: () => void;
    addNewView: (view: Type_index_view) => void;
};

export const ViewSelection = ({
    handleClose,
    addNewView,
    ...props
}: Type_Props_ViewsSelection) => {
    const { currentWorkspace } = useWorkspaceViewContext();
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.DefaultViews",
    );

    const defaultViews = useMemo(
        () =>
            getDefaultViews().sort(
                (itemA: Type_default_view, itemB: Type_default_view) =>
                    itemA.order - itemB.order,
            ),
        ["Views"],
    );

    const [selectedView, setSelectedView] = useState<Type_default_view | null>(
        null,
    );

    const { isLoading, mutateAsync: createView } = mutationCreateView();

    const closeDialog = (reason: string) => {
        if (reason !== "backdropClick") {
            handleClose();
        }
    };

    useEffect(() => {
        if (!props.open) {
            setSelectedView(null);
        }
    }, [props.open]);

    const handleCreateView = async () => {
        if (selectedView && currentWorkspace?.id) {
            const newView = await createView(
                formatterCreateView(selectedView, currentWorkspace.id),
            );
            addNewView(newView.data.data);
            handleClose();
        }
    };

    return (
        <MuiDialog
            {...props}
            onClose={(_event, reason) => {
                closeDialog(reason);
            }}
            fullWidth={true}
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "740px",
                },
            }}
        >
            <Stack direction={"row"} justifyContent={"end"}>
                <IconButton
                    size="small"
                    onClick={handleClose}
                    data-testid={"View-Dialog-Close-btn"}
                    title={fmtActions("Close")}
                    color={"inherit"}
                >
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            </Stack>
            <Stack
                direction={"row"}
                paddingX={10}
                data-testid={"View-Dialog-Title"}
            >
                <SectionTitle title={fmt("Modal.Title")} />
            </Stack>
            <MuiDialogContent sx={{ paddingX: 10, marginTop: 0 }}>
                <Styled_ViewContainer>
                    {defaultViews.map(
                        (defaultView: Type_default_view, index: number) => (
                            <ViewCard
                                key={"ViewCard" + index}
                                view={defaultView}
                                selectedView={selectedView}
                                setSelectedView={setSelectedView}
                            />
                        ),
                    )}
                </Styled_ViewContainer>
            </MuiDialogContent>
            <MuiDialogActions>
                <TMC_Button
                    data-testid={"ViewDialog-Save-btn"}
                    size="medium"
                    variant="contained"
                    disabled={isLoading || selectedView === null}
                    startIcon={
                        isLoading ? (
                            <LoadingBox />
                        ) : (
                            <Icon variant="solid" icon="check" />
                        )
                    }
                    onClick={handleCreateView}
                >
                    {fmtActions("Save")}
                </TMC_Button>
            </MuiDialogActions>
        </MuiDialog>
    );
};
