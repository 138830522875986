import { yupResolver } from "@hookform/resolvers/yup";
import { FormLabel, Stack } from "@mui/material";
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { useIndexVersions } from "src/api/tms-projects/versions";
import { formatterIndexVersionsToSelectList } from "src/api/tms-projects/versions/formatters";
import { Type_index_version } from "src/api/tms-projects/versions/types";
import {
    mutationDuplicateSequence,
    useShowSequence,
} from "src/api/tms-scheduling/sequences";
import {
    Type_post_dup_sequence,
    Type_show_sequence,
} from "src/api/tms-scheduling/sequences/types";
import { Type_DialogParams } from "src/components/Components_Common/Dialog/Dialog";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { Spinner } from "src/components/Components_Common/Spinner/Spinner";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_SequenceDuplication = Yup.object().shape({
    names: Yup.lazy(() => inputMultiLanguagesSchema()),
    version: Yup.number().required(),
});

type Type_Props_SequenceDuplicationForm = {
    dialogParams: Type_DialogParams;
    setIsFormLoading: Dispatch<SetStateAction<boolean>>;
    setDialogParams: Dispatch<SetStateAction<Type_DialogParams>>;
};

export const SequenceDuplicationForm = ({
    dialogParams,
    setIsFormLoading,
    setDialogParams,
}: Type_Props_SequenceDuplicationForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders.Sequence.DuplicateDialog",
    );
    const { data: sequence } = useShowSequence(dialogParams.data.id);

    const [versions, setVersions] = useState<Type_index_version[]>([]);

    const { mutateAsync, isLoading } = mutationDuplicateSequence(
        dialogParams.data.id!,
    );

    /////////////////////////////////////
    ///              FORM             ///
    /////////////////////////////////////

    const duplicatedSequenceNames = useMemo(
        () =>
            sequence
                ? generateSequenceNamesCopies(
                      sequence as Type_show_sequence,
                      fmt("CopySuffix"),
                  )
                : {},
        [sequence],
    );
    const form = useForm<Type_post_dup_sequence>({
        values: { names: duplicatedSequenceNames },
        resolver: yupResolver<any>(Schema_SequenceDuplication),
    });

    const handleSubmit = async (values: Type_post_dup_sequence) => {
        await mutateAsync(values);
        setDialogParams((prevParams) => ({
            ...prevParams,
            open: false,
            data: {},
        }));
    };

    const getCurrentVersion = useMemo(
        () =>
            (versions: Type_index_version[]): number =>
                formatterIndexVersionsToSelectList(versions).find(
                    (v) => v.label == "current",
                )?.value as number,
        [versions],
    );

    const fillListVersions = useCallback(
        (versions: Type_index_version[]) => {
            setVersions(versions);
            if (versions.length) {
                form.setValue("version", getCurrentVersion(versions));
            }
        },
        [form],
    );

    const { isFetching: isFetchingVersions } =
        useIndexVersions(fillListVersions) || {};

    useEffect(() => {
        setIsFormLoading(isLoading);
    }, [isLoading]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={dialogParams.data?.name}
            >
                <Stack gap={1}>
                    <InputMultiLanguages
                        name="names"
                        label={<FormLabel>{fmt(`Name`)}</FormLabel>}
                    />
                    <FormLabel>{fmt(`Version`)}</FormLabel>
                    {isFetchingVersions ? (
                        <Spinner />
                    ) : (
                        <Select
                            name="version"
                            options={formatterIndexVersionsToSelectList(
                                versions,
                            )}
                            placeholder={fmt(`VersionPlaceholder`)}
                        />
                    )}
                </Stack>
            </form>
        </FormProvider>
    );
};

const generateSequenceNamesCopies = (
    sequence: Type_show_sequence,
    suffix: string = "",
): { [k: string]: string } => {
    const names = Object.keys(sequence?.names).reduce(
        (acc, key) => {
            acc[key] = `${sequence.names[key]} ${suffix}`;
            return acc;
        },
        {} as { [p: string]: string },
    );

    return names;
};
