import { useState } from "react";

import {
    mutationDeleteProjectType,
    mutationStatusProjectType,
    useIndexProjectTypes,
} from "src/api/tms-commons/projectTypes";
import { Type_index_projectType } from "src/api/tms-commons/projectTypes/types";

import { Page_ProjectTypesComponent } from "./Page_ProjectTypes.component";

export type Type_modalDeleteProjectType = {
    isOpen: boolean;
    props?: Type_index_projectType;
};

export const Page_ProjectTypesContainer = () => {
    const { isFetching, data } = useIndexProjectTypes() || {};

    const [modalDeleteProjectType, setModalDeleteProjectType] =
        useState<Type_modalDeleteProjectType>({
            isOpen: false,
        });

    const { mutate: mutateDeleteProjectType } =
        mutationDeleteProjectType() || {};

    const deleteProjectType = (id: number) => {
        mutateDeleteProjectType(id);
    };

    const { mutate: mutationProjectTypeStatus } =
        mutationStatusProjectType() || {};

    const changeStatus = async (
        id: number,
        projectType: Type_index_projectType,
    ) => {
        mutationProjectTypeStatus({
            id,
            enabled: !projectType.enabled,
        });
    };

    return (
        <Page_ProjectTypesComponent
            isFetching={isFetching}
            deleteProjectType={deleteProjectType}
            projectTypes={data || []}
            changeStatus={changeStatus}
            modalDeleteProjectType={modalDeleteProjectType}
            setModalDeleteProjectType={setModalDeleteProjectType}
        />
    );
};
