import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { Type_show_taskArea } from "src/api/tms-scheduling/taskArea/types";
import { TMC_Button } from "src/components";
import {
    Accordion,
    Type_accordionList,
} from "src/components/Components_Common/accordions/Accordion";
import { TaskAreaDate } from "src/forms/taskArea/TaskAreaFormComponents/AccordionForms/Date/TaskAreaDate";
import { PeopleTaskArea } from "src/forms/taskArea/TaskAreaFormComponents/AccordionForms/People/TaskAreaPeople";
import { TaskLinks } from "src/forms/tasks/AccordionForms/Links/TaskLinks";
import {
    TaskObservations,
    TaskObservationsActions,
} from "src/forms/tasks/AccordionForms/Observations/TaskObservations";
import { ProgressionHistories } from "src/forms/tasks/AccordionForms/ProgressionHistory/ProgressionHistories";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

const taskAreaFormAccordionExpandedStateKey: string =
    "taskAreaFormAccordionExpandedState";

type Type_Props_TaskAreaFormAccordionList = {
    taskArea: Type_show_taskArea;
    readonly?: boolean;
};

export const TaskAreaFormAccordionList = ({
    taskArea,
    readonly = false,
}: Type_Props_TaskAreaFormAccordionList) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTaskArea.AccordionsTitle",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    const { openPaper } = useContextualDrawer();

    const [expanded, setExpanded] = useState<string[]>(
        getLocalStorageItem(taskAreaFormAccordionExpandedStateKey, true),
    );

    const taskAccordionTitle = (title: string) => (
        <Typography>{title}</Typography>
    );

    const accordionList: Type_accordionList[] = useMemo(
        () => [
            {
                key: "links",
                title: taskAccordionTitle(fmt("Links")),
                children: (
                    <TaskLinks
                        taskId={taskArea.taskId}
                        defaultTaskForAutocomplete={{
                            id: taskArea.taskId,
                            name: `${taskArea.taskName}`,
                        }}
                        areaId={taskArea.areaId}
                    />
                ),
            },
            {
                key: "progression",
                title: taskAccordionTitle(fmt("ProgressionHistory")),
                children: (
                    <ProgressionHistories
                        open={expanded.includes("progression")}
                        taskAreaId={taskArea.id as number}
                    />
                ),
            },
            {
                key: "observations",
                title: taskAccordionTitle(fmt("Observation")),
                children: (
                    <TaskObservations
                        areaId={taskArea.areaId}
                        taskId={taskArea.taskId}
                        taskAreaId={taskArea.id}
                    />
                ),
                action: (
                    <TaskObservationsActions
                        areaId={taskArea.areaId}
                        taskId={taskArea.taskId}
                        taskAreaId={taskArea.id}
                    />
                ),
            },
            {
                key: "assignees",
                title: taskAccordionTitle(fmt("People")),
                children: (
                    <PeopleTaskArea
                        areaId={taskArea.areaId}
                        taskId={taskArea.taskId}
                        taskAreaId={taskArea.id}
                    />
                ),
                ...(!readonly && {
                    action: (
                        <TMC_Button
                            data-testid={`List-all-task-people-task-btn`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openPaper("peopleTaskArea", {
                                    taskId: taskArea.taskId,
                                    taskAreaId: taskArea.id,
                                    areaId: taskArea.areaId,
                                    focus: false,
                                });
                            }}
                            variant="text"
                            size="small"
                        >
                            {fmtActions("SeeAll")}
                        </TMC_Button>
                    ),
                }),
            },
            {
                key: "date",
                title: taskAccordionTitle(fmt("Date")),
                children: <TaskAreaDate />,
            },
            // {
            //     key: "source",
            //     title: taskAccordionTitle(fmt("Source")),
            //     children: <>SourceTaskArea</>,
            // },
        ],
        [taskArea],
    );

    const handleAccordionChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            event.preventDefault();
            event.stopPropagation();
            setExpanded((prevExpanded) =>
                isExpanded
                    ? [...prevExpanded, panel]
                    : prevExpanded.filter((key) => key !== panel),
            );
        };

    useEffect(() => {
        setLocalStorageItem(taskAreaFormAccordionExpandedStateKey, expanded);
    }, [expanded]);

    return (
        <Box>
            {accordionList.map(
                (item) =>
                    shouldDisplay({
                        name: item.key,
                        taskType: taskArea?.type,
                        type: "accordion",
                    }) && (
                        <Accordion
                            key={item.key}
                            expanded={expanded.includes(item.key)}
                            onChange={handleAccordionChange(item.key)}
                            action={item.action}
                            title={item.title}
                            data-testid={`accordion-${item.key}`}
                            slotProps={{
                                transition: { unmountOnExit: true },
                            }} // Improved performance, see https://v5.mui.com/material-ui/react-accordion/#performance
                        >
                            {item.children}
                        </Accordion>
                    ),
            )}
        </Box>
    );
};
