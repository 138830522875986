import Konva from "konva";
import React from "react";
import { Shape } from "react-konva";

import { Type_Props_PlanningShapeTask } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

export const PlanningShapeTask = ({
    shapePlanningFunction,
    ...props
}: Type_Props_PlanningShapeTask) => {
    return (
        <Shape
            sceneFunc={(context: Konva.Context, shape: Konva.Shape): void => {
                shapePlanningFunction(
                    props.width as number,
                    props.height as number,
                    context,
                    shape,
                );
            }}
            {...props}
        />
    );
};
