import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { mutationNewPassword } from "src/api/tms-users/auth";
import { Type_post_newPassword } from "src/api/tms-users/auth/types";
import { TMC_Button } from "src/components";
import { PasswordRequirements } from "src/components/Components_Common/forms/PasswordRequirements";
import { TextFieldPassword } from "src/components/Components_Common/forms/reactHookFormComponents/TextFieldPassword/TextFieldPassword";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_NewPasswordForm = Yup.object().shape({
    token: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    password: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .min(16)
        .matches(/[0-9]/)
        .matches(/[a-zA-Z]/)
        .matches(/^\S+$/, FORM_ERR_FMT.NO_WHITESPACE),
    passwordConfirm: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .oneOf([Yup.ref("password")], FORM_ERR_FMT.PASSWORD_MISMATCH),
});

export const NewPasswordForm = () => {
    const [requirementsComplete, setRequirementsComplete] = useState(false);

    const { formatMessageWithPartialKey: fmt } = useCoreIntl("ResetPassword");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { mutateAsync, isLoading } = mutationNewPassword();

    const form = useForm<Type_post_newPassword>({
        ...useFormDefaultConfig,
        defaultValues: {
            token: "",
            password: "",
            passwordConfirm: "",
        },
        resolver: yupResolver<any>(Schema_NewPasswordForm),
    });

    const handleSubmit = async (
        values: Type_post_newPassword,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        try {
            await mutateAsync(values);
        } catch (e) {
            console.error("error", e);
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Stack gap={4}>
                    <TextFieldPassword
                        name="password"
                        label={fmtLabels("Password")}
                        dataTestId="RegisterForm-password"
                    />
                    <PasswordRequirements
                        password={form.watch("password")}
                        passwordConfirm={form.watch("passwordConfirm")}
                        setIsRequirementsComplete={setRequirementsComplete}
                    />
                    <TextFieldPassword
                        name="passwordConfirm"
                        label={fmtLabels("PasswordConfirmation")}
                        dataTestId="RegisterForm-confirmPassword"
                    />
                    <TMC_Button
                        data-testid="ResetPassword-form-submit-button"
                        type="submit"
                        variant="contained"
                        color={"primary"}
                        disabled={
                            !form.formState.isValid ||
                            requirementsComplete ||
                            isLoading
                        }
                    >
                        {fmt("Submit")}
                    </TMC_Button>
                </Stack>
            </form>
        </FormProvider>
    );
};
