import React, {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { Type_ProjectRequestConfig } from "src/api/fetch";
import { SubProjectKeys } from "src/api/tms-projects/keys";
import { formatterLocaleProjectLanguages } from "src/api/tms-projects/languages/formatters";
import { useOpenProject } from "src/api/tms-projects/projects";
import {
    Type_open_project_language,
    Type_open_project_project,
    Type_open_project_subProject,
    Type_open_project_version,
} from "src/api/tms-projects/projects/types";
import { Type_show_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";
import { countryCodeLanguages } from "src/assets/translations";
import { useLanguage } from "src/contexts/languages";
import { redirectToIdentification } from "src/utils/urlFormatter";

type Type_contextProject = {
    setContextState: Dispatch<SetStateAction<Type_contextProject>>;
    project: Type_open_project_project | null;
    subProject: Type_open_project_subProject | null;
    versions: Type_open_project_version[] | null;
    projectLanguages: Type_open_project_language[] | null;
    projectDefaultLanguage: string | null;
    currentWorkspace: Type_show_workspace | null;
    isLoading: boolean;
    error: boolean;
    requestConfig: Type_ProjectRequestConfig;
};

const initialProjectState: Type_contextProject = {
    setContextState: () => {},
    project: null,
    subProject: null,
    projectLanguages: null,
    versions: null,
    projectDefaultLanguage: null,
    currentWorkspace: null,
    isLoading: false,
    error: false,
    requestConfig: {},
};

type Type_projectProvider = {
    children: ReactElement;
};

const ProjectContext = createContext<Type_contextProject | undefined>(
    undefined,
);

export const useProject = (): Type_contextProject => {
    const context = useContext(ProjectContext);
    if (!context) {
        throw new Error(
            "useProject must be used within a ProjectContextProvider",
        );
    }
    return context;
};

export const ProjectProvider = ({
    children,
}: Type_projectProvider): ReactElement => {
    const { projectSlug, subProjectId } = useParams();

    const [contextState, setContextState] =
        useState<Type_contextProject>(initialProjectState);
    const { setLanguages } = useLanguage();
    const queryClient = useQueryClient();

    ////////////////////////////////////////////
    ///         Queries                      ///
    ////////////////////////////////////////////

    const {
        isFetching: isFetchingOpenProject,
        data: openProject,
        error: errorOpenProject,
        refetch: refetchOpenProject,
    } = useOpenProject(projectSlug, subProjectId);

    if (errorOpenProject) {
        console.error("Error fetching project:", errorOpenProject);
        redirectToIdentification();
    }

    ////////////////////////////////////////////
    ///         Effects                      ///
    ////////////////////////////////////////////

    useEffect(() => {
        if (isFetchingOpenProject) {
            setContextState((prev: Type_contextProject) => ({
                ...prev,
                isLoading: true,
            }));
        }

        if (!openProject?.project) {
            // reset context
            setContextState(initialProjectState);
            setLanguages(countryCodeLanguages);
            return;
        }

        setProject(openProject.project);
        setSubProject(openProject.subProject);
        setProjectLanguages(openProject.languages);
        setVersion(openProject.versions);
        // setCurrentWorkspace(openProject.currentWorkspace);

        queryClient.invalidateQueries({
            queryKey: [
                SubProjectKeys.SHOW,
                contextState.project?.id,
                subProjectId,
            ],
        });
    }, [isFetchingOpenProject, openProject]);

    useEffect(() => {
        if (projectSlug !== undefined) {
            refetchOpenProject();
        } else if (contextState.project !== null) {
            setContextState(initialProjectState);
            setLanguages(countryCodeLanguages);
        }
    }, [projectSlug]);

    useEffect(() => {
        if (subProjectId === undefined) {
            setSubProject(null);
        }
    }, [subProjectId]);

    ////////////////////////////////////////////
    ///         Memoized values               ///
    ////////////////////////////////////////////

    const memoizedContextState = useMemo(() => contextState, [contextState]);
    const memoizedSetContextState = useCallback(setContextState, []);

    ////////////////////////////////////////////
    ///         Helper functions              ///
    ////////////////////////////////////////////

    const setProject = useCallback(
        (project: Type_open_project_project | null) => {
            setContextState((prev) => ({
                ...prev,
                project,
                isLoading: false,
                error: false,
                requestConfig: {
                    ...prev.requestConfig,
                    projectId: project?.id,
                },
            }));
        },
        [],
    );

    const setSubProject = useCallback(
        (subProject: Type_open_project_subProject | null) => {
            setContextState((prev) => ({
                ...prev,
                isLoading: false,
                subProject,
                requestConfig: {
                    ...prev.requestConfig,
                    subProjectId: subProject?.id,
                },
            }));
        },
        [],
    );

    const setProjectLanguages = useCallback(
        (languages: Type_open_project_language[] | null) => {
            const newLanguages: string[] = languages
                ? formatterLocaleProjectLanguages(languages)
                : countryCodeLanguages;
            setLanguages(newLanguages);

            const projectDefaultLanguage =
                languages?.find((language) => language.default)?.locale ?? null;

            setContextState((prev) => ({
                ...prev,
                projectLanguages: languages ?? prev.projectLanguages,
                projectDefaultLanguage:
                    projectDefaultLanguage ?? prev.projectDefaultLanguage,
            }));
        },
        [],
    );

    const setVersion = useCallback(
        (versions: Type_open_project_version[] | null): void => {
            const currentVersion: Type_open_project_version | null | undefined =
                versions &&
                versions.find(
                    (version: Type_open_project_version): boolean =>
                        version.currentVersion,
                );

            setContextState((prev) => ({
                ...prev,
                isLoading: false,
                versions,
                requestConfig: {
                    ...prev.requestConfig,
                    versionId: currentVersion?.id,
                },
            }));
        },
        [],
    );

    /*const setCurrentWorkspace = useCallback(
        (currentWorkspace: Type_show_workspace | null) => {
            setContextState((prev) => ({
                ...prev,
                currentWorkspace: currentWorkspace ?? prev.currentWorkspace,
            }));
        },
        [],
    );*/

    return (
        <ProjectContext.Provider
            value={{
                ...memoizedContextState,
                setContextState: memoizedSetContextState,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};
