import {
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import React, { useMemo } from "react";

import { useSelectListTimeModels } from "src/api/tms-commons/timeModels";
import { useIndexTrades } from "src/api/tms-projects/trades";
import { Type_index_trade } from "src/api/tms-projects/trades/types";
import { useSelectListAreaWorkLevels } from "src/api/tms-scheduling/areas";
import { useIndexTasksForMatrix } from "src/api/tms-scheduling/tasks";
import { LoadingBox } from "src/components";
import {
    formatSelectListToSelectOptionItem,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { ColorPickerColumn } from "src/components/Components_Common/matrix/components/ColorPickerColumn";
import { multiLanguesColumns } from "src/components/Components_Common/matrix/components/MultiLanguesColumn";
import { singleSelectColumn } from "src/components/Components_Common/matrix/components/SingleSelectColumn";
import { Matrix } from "src/components/Components_Common/matrix/Matrix";
import { useProject } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const formatSelectListTrades = (
    data: Type_index_trade[],
): Type_SelectOptionItem[] => {
    return data.map((item) => ({
        label: item.name,
        value: item.id,
    }));
};

// TO DO : filter on trade
export const formatSelectListSubTrades = (
    data: Type_index_trade[],
): Type_SelectOptionItem[] => {
    return data.flatMap((trade) =>
        trade.subTrades.map((subTrade) => ({
            label: subTrade.name,
            value: subTrade.id,
        })),
    );
};

type Type_Props_MatrixSequence = {
    sequenceId?: number;
};

export const MatrixSequence = ({ sequenceId }: Type_Props_MatrixSequence) => {
    const page = "tasks";
    const { checkPermission, user } = useUser();
    const hasPermission = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    const { projectLanguages = [] } = useProject();
    const checkLanguages =
        projectLanguages?.map(({ locale }) => `names-${locale}`) ?? [];

    const editableFields = [
        ...checkLanguages,
        ...["code", "color", "timeModelId", "tradeId"],
    ];

    //i18n
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Lists");

    // Fetch Data
    const { data: dataTasks = [], isFetching: isFetchingTasks } =
        useIndexTasksForMatrix({
            sequence_id: sequenceId,
        });
    const { data: trades = [], isFetching: isFetchingTrades } =
        useIndexTrades() || {}; // TODO nouvelle route demandé au back
    const companies: any = [
        {
            id: 1,
            name: "Cimba Digital Construction Solutions",
        },
        {
            id: 2,
            name: "Soc 1",
        },
        {
            id: 3,
            name: "Soc 2",
        },
        {
            id: 4,
            name: "Soc 3",
        },
    ]; // TODO nouvelle route demandé au back
    const { data: timeModels = [], isFetching: isFetchingTimeModels } =
        useSelectListTimeModels() || {};
    const { data: workLevels = [], isFetching: isFetchingWorkLevels } =
        useSelectListAreaWorkLevels(sequenceId, true);

    const isFetching = useMemo(
        () =>
            isFetchingTasks ||
            isFetchingTrades ||
            isFetchingTimeModels ||
            isFetchingWorkLevels,
        [
            isFetchingTasks,
            isFetchingTrades,
            isFetchingTimeModels,
            isFetchingWorkLevels,
        ],
    );

    const getHiddenCellClassName = (params: GridCellParams<any, any, any>) => {
        if (!params.isEditable) {
            // checkboxes have to be hidden by css
            return "Teamoty-dataGrid-cell-hidden";
        }
        return "";
    };

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "type",
                headerName: fmtTableColumn("Type"),
                flex: 1,
                renderCell: (params: GridRenderCellParams<any, any, any>) => {
                    return fmt(`TaskType.${params.value}`);
                },
            },
            {
                field: "code",
                headerName: fmtTableColumn("Code"),
                flex: 1,
                display: "flex",
                editable: hasPermission,
            },
            ...multiLanguesColumns({
                field: "names",
                headerName: fmtTableColumn("Name"),
                languages: projectLanguages,
                editable: hasPermission,
            }),
            ColorPickerColumn({
                field: "color",
                headerName: fmtTableColumn("Color"),
                editable: hasPermission,
                display: "flex",
                width: 30,
            }),
            {
                field: "withTradeColor",
                type: "boolean",
                headerName: fmtTableColumn("WithTradeColor"),
                flex: 1,
                editable: hasPermission,
                cellClassName: getHiddenCellClassName,
            },
            singleSelectColumn({
                field: "tradeId",
                headerName: fmtTableColumn("Trade"),
                type: "singleSelect",
                valueOptions: formatSelectListTrades(trades!),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            }),
            singleSelectColumn({
                field: "subTradeId",
                headerName: fmtTableColumn("SubTrade"),
                type: "singleSelect",
                valueOptions: formatSelectListSubTrades(trades!),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            }),
            singleSelectColumn({
                field: "companyId",
                headerName: fmtTableColumn("Company"),
                type: "singleSelect",
                valueOptions: formatSelectListToSelectOptionItem(companies!),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            }),
            {
                field: "duration",
                headerName: fmtTableColumn("Duration"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "workforce",
                headerName: fmtTableColumn("Workforce"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "areaQuantity",
                headerName: fmtTableColumn("Quantity"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            singleSelectColumn({
                field: "workLevel",
                headerName: fmtTableColumn("WorkLevel"),
                type: "singleSelect",
                valueOptions: workLevels,
                editable: hasPermission,
                flex: 1,
                display: "flex",
                cellClassName: getHiddenCellClassName,
            }),
            {
                field: "team",
                headerName: fmtTableColumn("Team"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "follow",
                type: "boolean",
                headerName: fmtTableColumn("Follow"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "flow",
                type: "boolean",
                headerName: fmtTableColumn("Flow"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            {
                field: "checking",
                type: "boolean",
                headerName: fmtTableColumn("Checking"),
                editable: hasPermission,
                flex: 1,
                cellClassName: getHiddenCellClassName,
            },
            singleSelectColumn({
                field: "timeModelId",
                headerName: fmtTableColumn("TimeModel"),
                type: "singleSelect",
                valueOptions: formatSelectListToSelectOptionItem(timeModels!),
                editable: hasPermission,
                flex: 1,
                display: "flex",
            }),
        ],
        [isFetching],
    );

    // Check if a field is editable
    const isCellEditable = (params: GridCellParams<any, any, any>) => {
        if (!hasPermission) return false;

        // These fields are always displayed
        if (editableFields.includes(params.field)) return true;

        // These fields are displayed only if the task type is in the list
        return shouldDisplay({
            name: params.field,
            taskType: params.row.type,
        });
    };

    return (
        <>
            {isFetching ? (
                <LoadingBox />
            ) : (
                <Matrix
                    columns={columns}
                    rows={dataTasks}
                    isCellEditable={isCellEditable}
                />
            )}
        </>
    );
};
