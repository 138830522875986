import { styled, Tab, Tabs } from "@mui/material";

import { APP_BAR_HEIGHT } from "src/components/Components_Teamoty/TopBar/stylesheet";

export const Styled_ViewTab = styled(Tab)(({ theme }) => ({
    "&.MuiTab-root": {
        textTransform: "none",
        backgroundColor: theme.palette.navbar.background,
        color: theme.palette.common.white,
        minWidth: "1px",
        ...theme.typography.body2,
        height: APP_BAR_HEIGHT,
        minHeight: APP_BAR_HEIGHT,
        padding: theme.spacing(2),
        "&.Mui-selected": {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
        },
        "&:hover:not(.Mui-selected)": {
            backgroundColor: theme.palette.navbar.hover,
            color: theme.palette.primary.contrastText,
        },
        boxShadow: theme.boxShadow.navbar,
    },
})) as typeof Tab;

export const Styled_ViewsTabs = styled(Tabs)(() => ({
    height: APP_BAR_HEIGHT,
    minHeight: APP_BAR_HEIGHT,
    p: 0,
    span: {
        "&.MuiTabs-indicator": {
            display: "none",
        },
    },
    "&.MuiTabScrollButton-root.Mui-disabled": {
        opacity: 1,
    },
})) as typeof Tabs;
