import { Divider, ListItemIcon, Menu } from "@mui/material";
import React, { memo, useState } from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { usePlanningContext } from "src/contexts/planning";
import {
    Type_event_dropDownOnRightClick,
    Type_event_message,
    useChannel,
} from "src/hooks/useChannel";

import { actions } from "./actions";
import { Styled_MenuItemDropDownMenuRightClick } from "./DropDownMenuRightClick.style";

export type Type_MenuItem_DropDownRightClick = {
    label?: string;
    call?: keyof typeof actions;
    selected?: boolean;
    show?: (props: any) => boolean;
    icon?: {
        variant: string;
        name: string;
        color?: string;
    };
    divider?: boolean;
    keepOpenOnClick?: boolean;
};

type Type_Props_DropDownMenuRightClick = {
    name: string;
};

export const MemoizedDropDownMenuRightClick = ({
    name,
}: Type_Props_DropDownMenuRightClick) => {
    const planningContext = usePlanningContext();
    //
    // STATE
    //
    const initialState: Type_event_dropDownOnRightClick = {
        open: false,
        mouseX: 0,
        mouseY: 0,
        menuItems: [],
        props: null,
    };

    const [menuPosition, setMenuPosition] =
        useState<Type_event_dropDownOnRightClick>(initialState);

    const handleClose = () => setMenuPosition((_old) => initialState);

    //
    // useChannel
    //
    useChannel({
        eventHandler: ({ event, data }: Type_event_message) => {
            if (event === "dropDownOnRightClick") {
                setMenuPosition(data as Type_event_dropDownOnRightClick);
            }
        },
    });

    const handleConextMenu = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.preventDefault();
        // Ferme le menuDropDown au clic droit dans le vide
        if (menuPosition.open) {
            handleClose();
        }
    };

    const anchorPosition = {
        top: menuPosition.mouseY,
        left: menuPosition.mouseX,
    };

    const dataTestid = "menu-dropdown-right-click-" + name;

    const { menuItems } = menuPosition;

    if (!menuPosition.open) return null;
    return (
        <Menu
            onContextMenu={handleConextMenu}
            open={menuPosition.open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            data-testid={dataTestid}
        >
            {menuItems.map((menuItem: any, index: number) => {
                if (menuItem.divider) {
                    return (
                        <Divider
                            key={index}
                            orientation="horizontal"
                            textAlign="center"
                            variant="fullWidth"
                        />
                    );
                }
                // call callback function to show item if exists
                if (!Object.prototype.hasOwnProperty.call(menuItem, "show")) {
                    return (
                        <Styled_MenuItemDropDownMenuRightClick
                            selected={menuItem.selected}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (
                                    menuItem.call &&
                                    actions[
                                        menuItem.call as keyof typeof actions
                                    ]
                                ) {
                                    actions[
                                        menuItem.call as keyof typeof actions
                                    ](planningContext, menuPosition.props);
                                }
                                if (!menuItem.keepOpenOnClick) {
                                    handleClose();
                                }
                            }}
                            key={index}
                        >
                            {menuItem.icon && (
                                <ListItemIcon>
                                    <Icon
                                        variant={menuItem.icon.variant}
                                        icon={menuItem.icon.name}
                                        sx={{
                                            color: menuItem.icon.color
                                                ? menuItem.icon.color
                                                : "inherit",
                                            marginRight: "6px",
                                        }}
                                        {...menuItem.icon}
                                    />
                                </ListItemIcon>
                            )}
                            {menuItem.label}
                        </Styled_MenuItemDropDownMenuRightClick>
                    );
                }
            })}
        </Menu>
    );
};

MemoizedDropDownMenuRightClick.displayName = "DropDownMenuRightClick";

export const DropDownMenuRightClick = memo(MemoizedDropDownMenuRightClick);
