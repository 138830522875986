import { styled } from "@mui/material/styles";
import * as React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { invertColor } from "src/utils/colors";

const Styled_SequenceIconBadge = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme?.shape?.borderRadiusSmall || 0,
    padding: theme.spacing(0.5),
}));

type Type_Props_SequenceBadge = {
    color: string;
};

export const SequenceBadgeIcon = ({ color }: Type_Props_SequenceBadge) => {
    return (
        <Styled_SequenceIconBadge sx={{ backgroundColor: color || "#FFF" }}>
            <Icon
                variant={"kit"}
                icon={"light-tds-sequence"}
                fontSize={"medium"}
                sx={() => ({
                    color: invertColor(color || "#FFF"),
                })}
            />
        </Styled_SequenceIconBadge>
    );
};
