import React from "react";
import { Circle } from "react-konva";

import { usePlanningTheme } from "src/components/Components_Teamoty/Planning/Planning.const";
import { Type_Props_PlanningShapePunchList } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

export const PlanningShapePunchList = ({
    heightArea,
    width,
}: Type_Props_PlanningShapePunchList) => {
    const theme = usePlanningTheme();

    const PlanningTaskCircle =
        heightArea > 16
            ? {
                  x: width - 7,
                  y: 7,
                  strokeWidth: 1,
              }
            : {
                  x: width - 6,
                  y: 5.5,
                  strokeWidth: 1.5,
              };

    return (
        <Circle
            x={PlanningTaskCircle.x}
            y={PlanningTaskCircle.y}
            radius={3}
            fill={theme.colorPlanningPunchlist}
            stroke={theme.strokePlanningPunchlist}
            strokeWidth={PlanningTaskCircle.strokeWidth}
        />
    );
};
