import { Stack } from "@mui/material";
import React from "react";

import { TMC_Badge } from "src/components/Components_Common/_MuiComponentsVariants";
import { useDaysContext } from "src/components/Components_Common/Days/useDaysContext";

export type Type_Props_DaysBadge = {
    direction?: "row" | "column";
    disableEdition?: boolean;
    initialValue?: number;
    valueCallback?: (value: number) => void;
    dataTestIdPrefix?: string;
};

export const DaysBadge = ({
    direction = "row",
    disableEdition = false,
    initialValue,
    valueCallback,
    dataTestIdPrefix,
}: Type_Props_DaysBadge) => {
    const { days, handleDayToggle, value } = useDaysContext({
        initialValue,
        valueCallback,
    });

    return (
        <Stack display={"flex"} direction={direction} spacing={1}>
            {days.map(({ key, binary, label }) => {
                const onClick = disableEdition
                    ? {}
                    : {
                          onClick: () =>
                              handleDayToggle(binary, !(value & binary)),
                      };

                return (
                    <TMC_Badge
                        key={key}
                        data-testid={`${dataTestIdPrefix}badge-${key}`}
                        label={label.substring(0, 3)}
                        sx={{
                            cursor: `${
                                disableEdition ? "auto" : "pointer"
                            } !important`,
                        }}
                        {...onClick}
                        disabled={!(value & binary)}
                    />
                );
            })}
        </Stack>
    );
};
