import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import React from "react";
import { Circle, Group, Shape, Text } from "react-konva";

import { actions } from "src/components/Components_Common/dropdownMenuRightClick/actions";
import { Type_Props_PlanningMonthsDays } from "src/components/Components_Teamoty/Planning/Dates/PlanningDate.type";
import { useMenuItems } from "src/components/Components_Teamoty/Planning/Dialogs/menusConf";
import {
    fontSizeDiamondDayKey,
    heightPlanningDate,
    heightPlanningMonth,
    marginPlanningDiamondDayKey,
    radiusCircleDiamondDayKey,
    usePlanningTheme,
    widthDiamondDayKey,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { usePlanningContext } from "src/contexts/planning";
import { useChannel } from "src/hooks/useChannel";
import { invertColor } from "src/utils/colors";
import { KeysMouse } from "src/utils/shortcuts";

type Type_Props_DayKey = {
    dates: Type_Props_PlanningMonthsDays;
    date: planningDateClass;
    widthDate: number;
};

export const DayKey = ({ dates, date, widthDate }: Type_Props_DayKey) => {
    if (!date?.dayKeys?.length) return null;

    const theme = usePlanningTheme();

    const { sendEvent } = useChannel({});
    const { withoutMenu } = useMenuItems();
    const planningContext = usePlanningContext();

    const diamondScene: { [key: string]: { x: number; y: number } } = {
        leftDot: { x: marginPlanningDiamondDayKey, y: widthDiamondDayKey },
        topDot: { x: widthDiamondDayKey, y: marginPlanningDiamondDayKey },
        rightDot: {
            x: heightPlanningMonth - marginPlanningDiamondDayKey,
            y: widthDiamondDayKey,
        },
        bottomDot: {
            x: widthDiamondDayKey,
            y: heightPlanningMonth - marginPlanningDiamondDayKey,
        },
    };

    const dayKeysNbr: number = date.dayKeys.length;

    const hasFewDayKeys: boolean = dayKeysNbr > 1;

    const handleClickOnDayKey = (
        { evt }: KonvaEventObject<MouseEvent>,
        date?: planningDateClass,
    ) => {
        if (evt.button === KeysMouse.LEFT_CLICK) {
            const selectedDate = date
                ? dates?.days?.find(
                      (currentDate) =>
                          currentDate.timestamp === date?.timestamp,
                  )
                : null;

            if (selectedDate?.dayKeys?.length === 1) {
                actions["editAKeyDate"](planningContext, {
                    id: selectedDate?.dayKeys[0].keyDate.id,
                    date: undefined,
                });
            } else {
                const { clientX, clientY } = evt;

                sendEvent("dropDownOnRightClickEditKeyDate", {
                    open: true,
                    mouseX: clientX,
                    mouseY: clientY,
                    menuItems: withoutMenu,
                    props: {
                        date: date ?? undefined,
                        selectedDate: selectedDate?.dayKeys,
                    },
                });
            }
        }
    };

    return (
        <Group
            x={date.pos}
            offsetX={(heightPlanningMonth - widthDate) / 2}
            offsetY={heightPlanningDate}
            onClick={(e) => handleClickOnDayKey(e, date)}
            onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) => {
                changeCursor(e, "pointer");
            }}
            onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) => {
                changeCursor(e, planningContext.defaultCursor);
            }}
        >
            <Shape
                sceneFunc={(context, shape) => {
                    context.beginPath();
                    context.moveTo(
                        diamondScene.leftDot.x,
                        diamondScene.leftDot.y,
                    );
                    context.lineTo(
                        diamondScene.topDot.x,
                        diamondScene.topDot.y,
                    );
                    context.lineTo(
                        diamondScene.rightDot.x,
                        diamondScene.rightDot.y,
                    );
                    context.lineTo(
                        diamondScene.bottomDot.x,
                        diamondScene.bottomDot.y,
                    );
                    context.closePath();
                    context.fillStrokeShape(shape);
                }}
                fill={
                    hasFewDayKeys
                        ? theme.colorBackgroundTextDiamondDayKey
                        : date.dayKeys[0]?.keyDate?.color
                }
                stroke={theme.colorStrokeDiamondDayKey}
                strokeWidth={1}
            />
            {hasFewDayKeys ? (
                <Text
                    text={dayKeysNbr.toString()}
                    fontSize={fontSizeDiamondDayKey}
                    width={heightPlanningMonth}
                    height={heightPlanningMonth + marginPlanningDiamondDayKey}
                    align="center"
                    verticalAlign="middle"
                    fill={theme.colorTextDiamondDayKey}
                />
            ) : (
                <Circle
                    x={heightPlanningMonth / 2} // Centre horizontalement le cercle dans le losange
                    y={heightPlanningMonth / 2} // Centre verticalement le cercle dans le losange
                    radius={radiusCircleDiamondDayKey}
                    fill={invertColor(date.dayKeys[0]?.keyDate?.color)}
                />
            )}
        </Group>
    );
};
