import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { CauseOfDelayPath, Url } from "src/api/paths";
import {
    Type_com_post_causeOfDelay,
    Type_com_put_causeOfDelay,
} from "src/api/tms-commons/causesOfDelay/types";

export const indexCausesOfDelay = (): GetReturnType => {
    return get(`${Url.COMMONS}${CauseOfDelayPath.CAUSES_OF_DELAY}`);
};

export const createCauseOfDelay = (
    data: Type_com_post_causeOfDelay,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${CauseOfDelayPath.CAUSES_OF_DELAY}`);
};

export const showCauseOfDelay = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${CauseOfDelayPath.CAUSES_OF_DELAY}/${id}`);
};

export const updateCauseOfDelay = (
    id: number,
    data: Type_com_put_causeOfDelay,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${CauseOfDelayPath.CAUSES_OF_DELAY}/${id}`);
};

export const statusCauseOfDelay = (
    data: Type_com_put_causeOfDelay,
    id: number,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${CauseOfDelayPath.CAUSES_OF_DELAY}/${id}`);
};

export const deleteCauseOfDelay = (id: number): RemoveReturnType => {
    return remove(`${Url.COMMONS}${CauseOfDelayPath.CAUSES_OF_DELAY}/${id}`);
};
