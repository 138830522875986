import { get, GetReturnType, put, PutReturnType } from "src/api/fetch";
import { PlatformSettingsPath, Url } from "src/api/paths";

import { Type_com_put_platformSettings } from "./types";

export const showPlatformSetting = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${PlatformSettingsPath.PLATFORM_SETTINGS}/${id}`);
};

export const updatePlatformSetting = (
    id: number,
    data: Type_com_put_platformSettings,
): PutReturnType => {
    return put(
        data,
        `${Url.COMMONS}${PlatformSettingsPath.PLATFORM_SETTINGS}/${id}`,
    );
};
