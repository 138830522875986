import { ChipProps } from "@mui/material";
import React from "react";

import { Styled_ColorChip } from "src/components/Components_Common/_MuiComponentsVariants/Chip/Chip.style";
import { COLORS } from "src/theme/stylesheet";

export type Type_Props_ColorChip = {
    backgroundColor: string;
};
export const ColorChip = ({
    backgroundColor,
    ...restProps
}: Type_Props_ColorChip & ChipProps) => {
    const ChipColor = backgroundColor || COLORS.grey;

    return (
        <Styled_ColorChip
            {...restProps}
            label={""}
            ownerState={{
                chipBackgroundColor: ChipColor,
            }}
        />
    );
};
