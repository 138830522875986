import { DatePickerProps } from "@mui/x-date-pickers-pro";
import { Controller, useFormContext } from "react-hook-form";

import {
    DatePicker as TMC_DatePicker,
    Type_Props_DatePickerProps,
} from "src/components/Components_Common/_MuiComponentsVariants/DatePicker/DatePicker.component";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

type Type_Props_DatePicker = Type_Props_DatePickerProps &
    Type_ReadonlyComponent &
    DatePickerProps<any> & {
        name: string;
    };

export const DatePicker = ({ name, ...restProps }: Type_Props_DatePicker) => {
    const { formState, control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, value, ...field } }) => (
                <TMC_DatePicker
                    {...restProps}
                    inputRef={ref}
                    value={value || null}
                    {...field}
                    error={!!formState.errors[name]}
                    helperText={formState.errors[name]?.message as string}
                />
            )}
        />
    );
};
