import { IconProps } from "@mui/material";

import { Icon } from "src/components/Components_Common/Icon/Icon";

export type Type_SpinnerComponentProps = {
    style?: "dots" | "button" | undefined;
    size?: "small" | "medium" | "large" | undefined;
};

export const Spinner = ({
    style,
    size,
    ...restProps
}: Type_SpinnerComponentProps & IconProps) => {
    if (style === "dots") {
        return (
            <Icon
                data-testid={`LoadingBox-${style}`}
                variant="solid"
                icon="spinner-third"
                spinPulse
                {...restProps}
            />
        );
    } else if (style === "button") {
        return (
            <Icon
                data-testid={`LoadingBox-${style}`}
                variant="solid"
                icon="spinner-third"
                spinPulse
                fontSize={size ?? "small"}
                {...restProps}
            />
        );
    } else {
        return (
            <p data-testid={`LoadingBox`} style={{ display: "flex" }}>
                <Icon
                    {...restProps}
                    variant="solid"
                    icon="spinner-third"
                    spin
                    fontSize={size ?? "large"}
                />
            </p>
        );
    }
};
