import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import {
    Type_planningSubTrade,
    Type_planningWorkforceDay,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import Konva from "konva";
import React, { useEffect, useState } from "react";
import { Circle, Group, Label, Line, Rect, Tag, Text } from "react-konva";
import { Portal } from "react-konva-utils";

import { PlanningDrawerTitle } from "src/components/Components_Teamoty/Planning/Drawer/PlanningDrawerTitle";
import { PlanningPlanningDrawerResizerX } from "src/components/Components_Teamoty/Planning/Drawer/PlanningPlanningDrawerResizerX";
import {
    heightPlanningDrawerTitle,
    marginPlanningElements,
    marginPlanningWorkforceCurve,
    paddingPlanningWorkforceCurveLabel,
    pointerPlanningWorkforceCurveLabel,
    sizePlanningWorkforceCurveLabel,
    sizePlanningWorkforceCurvePoint,
    stepPlanningWorkforceCurve,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { getGridVertical } from "src/components/Components_Teamoty/Planning/tools/getGridVertical";

import { getQuantitiesBySubTrades } from "./getQuantitiesBySubTrades";
import {
    Enum_typeLabel,
    Type_Map_planningQuantities,
    Type_Props_PlanningWorkforceCurve,
    Type_State_PlanningWorkforceCurve,
} from "./PlanningWorkforce.type";

export const PlanningWorkforceCurve = ({
    x,
    y,
    width,
    height,
    dates,
    widthDate,
    workforce,
    workforceSubTrades,
    subTrades,
    // workforceCompanies,
    // companies,
    onClose,
    onResize,
}: Type_Props_PlanningWorkforceCurve) => {
    const theme = usePlanningTheme();

    const heightReal: number =
        height - marginPlanningElements - heightPlanningDrawerTitle;

    // ----------------------------------------------------------------------------------------------------------------

    const [label, setLabel] = useState<Type_State_PlanningWorkforceCurve>({
        show: false,
        x: 0,
        y: 0,
        quantity: "",
        type: Enum_typeLabel.none,
    });

    const [quantities, setQuantities] = useState<number[]>([]);
    const [maxQuantity, setMaxQuantity] = useState<number>(0);

    const [quantitiesSubTrades, setQuantitiesSubTrades] =
        useState<Type_Map_planningQuantities>(new Map<number, number[]>());

    // const [quantitiesCompanies, setQuantitiesCompanies] =
    //     useState<Type_Map_planningQuantities>(new Map<number, number[]>());

    // ----------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        let newMaxQuantity: number = 0;
        workforce.forEach((value: Type_planningWorkforceDay): void => {
            newMaxQuantity = Math.max(newMaxQuantity, value.quantity);
        });

        setMaxQuantity(newMaxQuantity);
    }, [workforce]);

    // ----------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        const newQuantities: number[] = [];
        dates.days.map((date: planningDateClass): void => {
            let quantity: number = 0;
            if (workforce.has(date.timestamp)) {
                quantity = (
                    workforce.get(date.timestamp) as Type_planningWorkforceDay
                ).quantity;
            }
            newQuantities.push(quantity);
        });
        setQuantities([...newQuantities]);

        getQuantitiesBySubTrades(dates, workforce, setQuantitiesSubTrades);
        // getQuantitiesByCompanies(dates, workforce, setQuantitiesCompanies);
    }, [dates, workforce, widthDate]);

    // ----------------------------------------------------------------------------------------------------------------

    const heightGraph: number = heightReal - marginPlanningWorkforceCurve;
    const circles: Array<React.JSX.Element> = [];

    // ----------------------------------------------------------------------------------------------------------------
    // dataSubTrade

    const yyy: number[] = [];
    workforceSubTrades.forEach((subTradeId: number): void => {
        const subTrade: Type_planningSubTrade = subTrades.get(
            subTradeId,
        ) as Type_planningSubTrade;

        if (quantitiesSubTrades.has(subTradeId)) {
            const values: number[] = quantitiesSubTrades.get(
                subTradeId,
            ) as number[];

            values.map((value: number, index: number): void => {
                const yy: number = maxQuantity
                    ? (heightGraph / maxQuantity) * value
                    : 0;

                const hh: number =
                    typeof yyy[index] === "undefined" ? yy : yyy[index] + yy;

                circles.push(
                    <Rect
                        key={"glr" + subTradeId + "." + index}
                        x={index * widthDate + widthDate * 0.25}
                        y={heightReal - hh}
                        height={yy}
                        width={widthDate * 0.5}
                        fill={subTrade.color}
                        hitStrokeWidth={0}
                        onMouseOver={(): void => {
                            setLabel(
                                (): Type_State_PlanningWorkforceCurve => ({
                                    show: true,
                                    x: index * widthDate + widthDate * 0.25,
                                    y: heightReal - hh + yy / 2 + 6,
                                    quantity: subTrade.name + "  : " + value,
                                    type: Enum_typeLabel.rect,
                                }),
                            );
                        }}
                        onMouseOut={(): void => {
                            setLabel(
                                (
                                    prev: Type_State_PlanningWorkforceCurve,
                                ): Type_State_PlanningWorkforceCurve => ({
                                    ...prev,
                                    show: false,
                                }),
                            );
                        }}
                    />,
                );

                yyy[index] = hh;
            });
        }
    });

    // ----------------------------------------------------------------------------------------------------------------
    // data

    const points: number[] = [];

    let index: number = 0;

    quantities.length &&
        dates.days.map((date: planningDateClass): void => {
            if (index < quantities.length) {
                const quantity: number = quantities[index++];
                const yy: number = maxQuantity
                    ? heightReal - (heightGraph / maxQuantity) * quantity
                    : heightReal;

                points.push(date.pos + widthDate / 2, yy);

                circles.push(
                    <Circle
                        key={"glc" + index}
                        x={date.pos + widthDate / 2}
                        y={yy}
                        radius={sizePlanningWorkforceCurvePoint}
                        fill={theme.colorPlanningWorkforceCurvePoint}
                        hitStrokeWidth={2}
                        onMouseOver={(
                            e: Konva.KonvaEventObject<MouseEvent>,
                        ): void => {
                            e.target.to({
                                radius: 2 * sizePlanningWorkforceCurvePoint,
                                duration: 0.2,
                            });
                            setLabel(
                                (): Type_State_PlanningWorkforceCurve => ({
                                    show: true,
                                    x: date.pos + widthDate / 2,
                                    y: yy,
                                    quantity: quantity.toString(),
                                    type: Enum_typeLabel.circle,
                                }),
                            );
                        }}
                        onMouseOut={(
                            e: Konva.KonvaEventObject<MouseEvent>,
                        ): void => {
                            e.target.to({
                                radius: sizePlanningWorkforceCurvePoint,
                                duration: 0,
                            });

                            setLabel(
                                (
                                    prev: Type_State_PlanningWorkforceCurve,
                                ): Type_State_PlanningWorkforceCurve => ({
                                    ...prev,
                                    show: false,
                                }),
                            );
                        }}
                    />,
                );
            }
        });

    // ----------------------------------------------------------------------------------------------------------------
    // grid

    const [elmRects, elmLine] = getGridVertical(heightReal, dates, widthDate);

    // ----------------------------------------------------------------------------------------------------------------
    // scale

    let step: number = 0;
    if (heightReal > stepPlanningWorkforceCurve * 2) {
        step = Math.ceil(maxQuantity / stepPlanningWorkforceCurve);
        maxQuantity > 10 && (step = Math.ceil(step / 10) * 10);
    }

    for (
        let quantity: number = 0;
        quantity <= maxQuantity && step > 0;
        quantity += step
    ) {
        const yy: number = maxQuantity
            ? Math.round(heightReal - (heightGraph / maxQuantity) * quantity)
            : heightReal;
        elmLine.push(
            <Line
                key={"glyy" + yy}
                points={[-5, yy, width + 5, yy]}
                stroke={theme.colorPlanningLine}
                strokeWidth={1}
            />,
        );
        elmLine.push(
            <Text
                key={"glyt" + yy}
                x={-50}
                y={yy - 10}
                height={20}
                text={quantity.toString()}
                padding={5}
                width={50}
                align={"right"}
                verticalAlign={"middle"}
            />,
        );
    }

    // ----------------------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------------------

    return (
        <Group>
            <Group
                x={x}
                y={y}
                clipX={-50}
                clipY={-0.5}
                clipWidth={width + 50}
                clipHeight={height + 0.5}
            >
                <PlanningDrawerTitle
                    width={width}
                    titleKey={"WorkforceCurveTitle"}
                    onClose={onClose}
                />
                <Group y={heightPlanningDrawerTitle}>
                    <Rect
                        width={width}
                        height={heightReal}
                        stroke={theme.colorPlanningLine}
                        strokeWidth={1}
                    />
                    {elmRects}
                    {elmLine}
                    <Line
                        points={points}
                        stroke={theme.colorPlanningWorkforceCurveLine}
                        strokeWidth={2}
                    />
                    {circles}
                </Group>
            </Group>
            <PlanningPlanningDrawerResizerX
                x={1}
                y={y}
                width={x + width}
                height={height}
                onResize={onResize}
            />
            <Portal selector=".top" enabled={true}>
                {label.show && (
                    <Label
                        offsetY={-y - heightPlanningDrawerTitle}
                        offsetX={-x}
                        x={label.x}
                        y={
                            label.y > sizePlanningWorkforceCurveLabel
                                ? label.y - sizePlanningWorkforceCurvePoint * 2
                                : label.y + sizePlanningWorkforceCurvePoint * 2
                        }
                    >
                        <Tag
                            fill={theme.fillPlanningWorkforceCurveLabel}
                            pointerDirection={
                                label.type == Enum_typeLabel.circle
                                    ? label.y > sizePlanningWorkforceCurveLabel
                                        ? "down"
                                        : "up"
                                    : "right"
                            }
                            pointerWidth={pointerPlanningWorkforceCurveLabel}
                            pointerHeight={pointerPlanningWorkforceCurveLabel}
                            lineJoin={"round"}
                        />
                        <Text
                            text={label.quantity.toString()}
                            padding={paddingPlanningWorkforceCurveLabel}
                            fill={theme.colorPlanningWorkforceCurveLabel}
                        />
                    </Label>
                )}
            </Portal>
        </Group>
    );
};
