import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

interface Styled_ChipDigitProps extends ChipProps {
    ownerState: {
        isFocus: boolean;
    };
}

export const Styled_ChipDigit = styled(Chip)<Styled_ChipDigitProps>(
    ({ theme, ownerState }: any) => ({
        height: 24,
        padding: 0,
        borderRadius: "2px",
        border: `none`,
        ...theme.typography.body2,
        "& .MuiChip-label": {
            padding: `0 ${theme.spacing(1)}`,
            color: COLORS.moon500,
        },
        ...(ownerState?.isFocus && {
            backgroundColor: COLORS.blue300,
            "& .MuiChip-label": {
                borderRadius: "2px",
                padding: `0 ${theme.spacing(1)}`,
                color: COLORS.blue600,
            },
        }),
    }),
);
