import { Stack, StackProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

interface Styled_StackMainAlertProps extends StackProps {
    ownerState: {
        status: "info" | "success" | "warning" | "error";
    };
}

/**
 * @deprecated
 */
export const Styled_StackMainAlert = styled(Stack)<Styled_StackMainAlertProps>(
    ({ theme, ownerState }: any) => ({
        justifyContent: "start",
        alignItems: "start",
        padding: "8px 0 8px 16px",
        gap: theme.spacing(2),
        borderRadius: theme.shape.borderRadiusLarge,
        ...(ownerState?.status === "info" && {
            backgroundColor: COLORS.blue100,
            border: `1px solid ${COLORS.blue300}`,
        }),
        ...(ownerState?.status === "success" && {
            backgroundColor: COLORS.gray100,
            border: `1px solid ${COLORS.blue300}`,
        }),
        ...(ownerState?.status === "warning" && {
            backgroundColor: COLORS.blue100,
            border: `1px solid ${COLORS.blue300}`,
        }),
        ...(ownerState?.status === "error" && {
            backgroundColor: COLORS.red100,
            border: `1px solid ${COLORS.red300}`,
        }),
    }),
);
