import {
    Box,
    BoxProps,
    FormControlLabel,
    FormControlLabelProps,
    styled,
} from "@mui/material";

import { COLORS } from "src/theme/stylesheet";

type Type_Styled_BoxProps = {
    ownerState: {
        format?: "rounded" | "standard" | undefined;
    };
};

export const Styled_Box = styled(Box)<Type_Styled_BoxProps & BoxProps>(
    ({ theme, ownerState }) => ({
        padding: theme.spacing(2),
        ...(ownerState?.format === "rounded" && {
            border: `1px solid ${COLORS.moon200}`,
            borderRadius: theme.shape.borderRadiusLarge,
        }),
    }),
);

type Styled_FormControlLabelProps = {
    ownerState: {
        highlighted?: boolean;
    };
};

export const Styled_FormControlLabel = styled(FormControlLabel)<
    Styled_FormControlLabelProps & FormControlLabelProps
>(({ ownerState }) => ({
    flexDirection: "row-reverse",
    margin: 0,
    width: "100%",
    justifyContent: "space-between",
    ...(ownerState?.highlighted && {
        "& .MuiFormControlLabel-label": {
            backgroundColor: COLORS.yellow500,
        },
    }),
}));
