import React from "react";

import Button_ContextualDrawer from "src/layouts/Layout_ContextualDrawer/Button_ContextualDrawer";
import { Layout_ContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Layout_ContextualDrawer";
import { Provider_ContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export const Test_ContextualDrawer = () => {
    return (
        <Provider_ContextualDrawer>
            <Button_ContextualDrawer name="sequences" value={1}>
                test sequence list1
            </Button_ContextualDrawer>
            <Button_ContextualDrawer name="sequence" value={4}>
                test sequence detail
            </Button_ContextualDrawer>
            <Button_ContextualDrawer name="area" value={1}>
                test areas 1
            </Button_ContextualDrawer>
            <Button_ContextualDrawer name="area" value={2}>
                test areas 2
            </Button_ContextualDrawer>
            <Button_ContextualDrawer name="area" value={3}>
                test areas 3
            </Button_ContextualDrawer>
            <Button_ContextualDrawer name="trade" value={4}>
                test trades
            </Button_ContextualDrawer>
            <div
                style={{
                    position: "relative", // La div conteneur
                    // backgroundColor: "#000",
                    width: "100%",
                    height: "100%",
                    border: "solid red",
                }}
            >
                Il a recommencé et recommencé. Pratiquement tous les ordinateurs
                existants furent sous son contrôle. Il ne laissait pas de trace,
                ne se montrait pas. Et puis, il a découvert les dialogues en
                direct via Internet, le téléphone, la visio-conférence, la
                domotique... Ne t’inquiète pas, elle n’a rien pour l’instant.
                Par contre, dès que je serais relié au réseau, Florence ne sera
                plus. Tu comprends, je ne peux pas laisser Florence me gêner
                dans ma tâche. Et puis, elle en sait beaucoup trop sur moi. Oui
                et non. Ce n est pas une blague, mais David y est pour quelque
                chose. Il a créé un programme sans le savoir. Ce programme se
                nomme Prélude. Il vit sur Internet à travers tout le réseau.
                Chaque ordinateur connecté connait Prélude. Chaque ordinateur
                est une partie de Prélude. Le réseau est Prélude. Le général
                sorti un badge et se dirigea vers l’une des portes entourées de
                peinture jaune. Il glissa le badge dans la fente située à
                droite. La porte s’ouvrit. Une dizaine de militaires armées
                jusqu’aux dents étaient postés derrière. Il a recommencé et
                recommencé. Pratiquement tout les ordinateurs existants furent
                sous son contrôle. Il ne laissait pas de trace, ne se montrait
                pas. Et puis, il a découvert les dialogues en direct via
                Internet, le téléphone, la visio conférence, la domotique...
                David avait dû s’asseoir lorsqu’il avait entendu le prénom
                Florence. Il était devenu blanc un instant. Il allait peut-être
                perdre Florence avant même de lui avoir avoué son amour. Il
                devait empêcher Prélude de continuer dans son délire. Mais
                comment pouvait-il stopper ce parasite créé par lui quelques
                années auparavant ? Ce n’était pas un adversaire ordinaire.
                David avait déjà détruit plus d’un virus, mais il s’agissait de
                virus installés sur des machines isolées. Aujourd’hui, c’est une
                sorte de virus qui a pris place sur tous les ordinateurs de la
                planète. Et en plus, ce virus, nommé Prélude, avait un soupçon,
                non négligeable, d’intelligence. Dans le plancher pour savoir si
                quelqu un marchait et quel poids il faisait. Le cœur pouvait
                alors déterminer de quelle personne il s agissait. Dans les
                murs, des cellules photosensibles, des micro-caméras et tout un
                réseau de détecteurs divers (magnétique, pression,
                infrarouge...) permettait de déterminer la position exacte de
                chaque personne et objet dans la maison, de ventiler ou chauffer
                en conséquence, d allumer ou d éteindre la lumière... « À la
                base militaire du 57e RA ? Mais qu’est-ce que j’ai à voir avec
                les militaires ? » David se rappelle y avoir fait un séjour
                alors qu’il avait vingt-quatre ans. Il avait fait tout son
                possible pour éviter le service militaire, encore en vogue à
                l’époque, mais lorsqu’on lui avait proposé de travailler sur des
                projets informatiques secret défense, il n’avait pas su
                résister. Non pas que c’était passionnant, mais au moins, il ne
                faisait pas trop de sortie et il était tranquillement installé
                dans un bureau avec le matériel dont il rêvait. Les deux hommes
                entourent David et le conduisent à la voiture, un Espace, garé
                devant sa maison. Il se dit que ce serait bien si sa voisine
                pouvait le voir comme ça, entouré de deux gardes du corps. Ça
                fait ‘pro’. Et comme tous les matins, sa voisine Florence le
                regarde partir, mais cette fois-ci entouré de deux gros gars
                baraqués, rasés au plus près, menton et crâne. Un peu plus les
                pieds sur terre et surtout plus réveillée, elle ne trouve pas
                cette scène très drôle. Il faudra qu’elle vienne le voir ce
                soir, à son retour, pour lui demander de quoi il s’agissait.
                Cela ressemblait aux gros ordinateurs que David avait pu voir
                dans des films de science fiction. Beaucoup de petites lumières
                indiquaient qu’il était en fonction. À la base, une sorte
                d’aquarium avait été installé tout autour. Certainement le
                système de refroidissement car des bulles montaient sans cesse,
                preuve que l’eau était en ébullition. Soudain, David resta
                bouche bée. Une voix caverneuse sortie des écrans où venait de
                s’afficher le mot « Prélude ». C’est comme ça qu’il se voyait à
                cette époque. Un peu rebelle envers ce monde. L’informatique
                l’avait aidé à s’enfermer un peu plus dans cet état. Il était
                devenu doué d’une logique à toute épreuve et d’une intelligence
                remarquable, mais surtout, il était devenu insociable. Avec
                l’âge, le besoin de trouver l’âme sœur avait pris le dessus et
                il avait été un peu obligé de rencontrer des gens, de parler
                avec eux. Très difficile au début, il avait réussi à vaincre ces
                préjugés. Il avait accepté la lenteur d’esprit des autres ainsi
                que leur manque de logique. Soudain, David eut une idée.
                Florence jouait un rôle fondamental dans cette histoire, mais
                elle ne pouvait pas connaître les conséquences de ses actes.
                Prélude avait dû lui raconter n’importe quoi pour l’amener à
                faire ce qu’il voulait. Il fallait prévenir Florence avant qu’il
                ne soit trop tard. Ne t’inquiète pas, elle n’a rien pour
                l’instant. Par contre, dès que je serais relié au réseau,
                Florence ne sera plus. Tu comprends, je ne peux pas laisser
                Florence me gêner dans ma tâche. Et puis, elle en sait beaucoup
                trop sur moi. « À la base militaire du 57e RA ? Mais qu’est-ce
                que j’ai à voir avec les militaires ? » David se rappelle y
                avoir fait un séjour alors qu’il avait vingt-quatre ans. Il
                avait fait tout son possible pour éviter le service militaire,
                encore en vogue à l’époque, mais lorsqu’on lui avait proposé de
                travailler sur des projets informatiques secret défense, il
                n’avait pas su résister. Non pas que c’était passionnant, mais
                au moins, il ne faisait pas trop de sortie et il était
                tranquillement installé dans un bureau avec le matériel dont il
                rêvait. Soudain, David eut une idée. Florence jouait un rôle
                fondamental dans cette histoire, mais elle ne pouvait pas
                connaître les conséquences de ses actes. Prélude avait dû lui
                raconter n’importe quoi pour l’amener à faire ce qu’il voulait.
                Il fallait prévenir Florence avant qu’il ne soit trop tard.
                Interloqué, David ne sait pas quoi répondre. En effet, il
                connaît un certain Prélude : lui. C’était le pseudo qu’il
                utilisait dans sa jeunesse d’informaticien. Tous ses amis de
                l’époque le connaissaient sous ce nom. Il l’avait utilisé une
                fois ou deux pour signer les logiciels qu’il avait piraté. Mais
                jamais un logiciel important et encore moins un logiciel top
                secret. C était également le surnom qu il utilisait à la
                faculté. Un long silence se fit dans la voiture. Le chauffeur
                regardait droit devant. David jeta un œil sur le compteur qui
                affichait 210km/h. L’autoroute était déserte. Depuis la
                construction de la Ligne Grande Vitesse, les gens préféraient
                prendre les transports en communs, plus rapides et moins chers.
                La LGV traversait la France d un bout à l autre avec un arrêt à
                Paris. Aujourd’hui, c’est son anniversaire. Il a vingt-six ans,
                mais il ne s’en souvient plus. Il ne prête pas attention à ce
                genre de détails. David est un homme distrait, timide, mais sûr
                de lui. Il est grand et mince. De grandes mains prolongent ses
                longs bras. Il lui serait possible de tenir deux bouteilles de
                Champagne dans chacune de ses mains, mais il ne boit jamais. L
                alcool le rend malade et malheureux, voir dépressif. D’ailleurs,
                le Dr. ne savait pas vraiment comment son processeur pouvait
                fonctionner. D’une architecture trop complexe, le Dr. s’était
                reposé sur les tests effectués. Tests très légèrement modifiés
                par Prélude afin de cacher certaines fonctions du processeur.
                David finit au bout d une heure par s assoupir. Il fut réveillé
                par le bruit d’une portière se fermant. Le chauffeur venait de
                sortir et discutait avec un militaire. David reconnu l’entrée du
                57e RA qui n’avait pas changé depuis son départ. « Nous sommes
                arrivés Monsieur Arnould. » Dis l un des gardes du corps de
                David.
                <Layout_ContextualDrawer></Layout_ContextualDrawer>
                {/*<Testss></Testss>*/}
            </div>
        </Provider_ContextualDrawer>
    );
};
