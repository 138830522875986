import { flowShapeDeliveryFunction } from "src/components/Components_Teamoty/Flow/shapeFunction/flowShapeDeliveryFunction";
import { flowShapeMilestoneFunction } from "src/components/Components_Teamoty/Flow/shapeFunction/flowShapeMilestoneFunction";
import { flowShapeStudyFunction } from "src/components/Components_Teamoty/Flow/shapeFunction/flowShapeStudyFunction";
import { flowShapeTechnicalFunction } from "src/components/Components_Teamoty/Flow/shapeFunction/flowShapeTechnicalFunction";
import { flowShapeWaitingFunction } from "src/components/Components_Teamoty/Flow/shapeFunction/flowShapeWaitingFunction";
import { flowShapeWorkFunction } from "src/components/Components_Teamoty/Flow/shapeFunction/flowShapeWorkFunction";

import { FlowShapeMilestoneBase } from "./FlowShapeMilestoneBase";
import { FlowShapeTaskBase } from "./FlowShapeTaskBase";
import { FlowShapeTaskLightBase } from "./FlowShapeTaskLightBase";

export const components_shape_flow = {
    work: flowShapeWorkFunction,
    study: flowShapeStudyFunction,
    waiting: flowShapeWaitingFunction,
    technical: flowShapeTechnicalFunction,
    milestone: flowShapeMilestoneFunction,
    delivery: flowShapeDeliveryFunction,
};

export type Type_props_components_shape_flow =
    keyof typeof components_shape_flow;

export const componentsTaskFlow = {
    work: FlowShapeTaskBase,
    study: FlowShapeTaskBase,
    waiting: FlowShapeTaskLightBase,
    technical: FlowShapeTaskLightBase,
    milestone: FlowShapeMilestoneBase,
    delivery: FlowShapeMilestoneBase,
};

export const propsTaskFlow = {
    work: { withDuration: true, withAreaQuantity: true, withTeam: true },
    study: { withDuration: true, withAreaQuantity: true, withTeam: false },
    waiting: { withDuration: true, withAreaQuantity: false, withTeam: false },
    technical: { withDuration: true, withAreaQuantity: true, withTeam: false },
    milestone: {
        withDuration: false,
        withAreaQuantity: false,
        withTeam: false,
    },
    delivery: { withDuration: false, withAreaQuantity: false, withTeam: false },
};
