import { ChipTypeMap } from "@mui/material";
import { useState } from "react";

import { useSelectListUnits } from "src/api/tms-commons/units";
import { Type_selectList_unit } from "src/api/tms-commons/units/types";
import {
    Autocomplete,
    Type_Props_Autocomplete,
} from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteUnit<
    T extends Type_selectList_unit,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    Type_Props_Autocomplete<
        T,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
    >,
    | "options"
    | "open"
    | "onOpen"
    | "onClose"
    | "loading"
    | "isFetching"
    | "multiple"
> & {
    name: string;
    label?: string;
    multiple?: boolean;
};

export const AutocompleteUnits = ({
    name,
    label,
    multiple = false,
    ...restProps
}: Type_Props_AutocompleteUnit<
    Type_selectList_unit,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
>) => {
    const [open, setOpen] = useState<boolean>(false);

    const { data: units, isFetching: isFetchingUnits } =
        useSelectListUnits(open);

    return (
        <Autocomplete
            name={name}
            label={label}
            options={units || []}
            openOnFocus
            open={open}
            multiple={multiple}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={isFetchingUnits}
            {...restProps}
        />
    );
};
