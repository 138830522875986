import { FormLabel, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useSelectListTimeModels } from "src/api/tms-commons/timeModels";
import { DaysBadge } from "src/components/Components_Common/Days/DaysBadge";
import {
    formatSelectListToSelectOptionItem,
    Select,
    Type_Props_Select,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_SelectTimeModel = Omit<Type_Props_Select, "options"> & {
    custom?: boolean;
};

export const SelectTimeModel = ({
    custom = true,
    ...props
}: Type_Props_SelectTimeModel) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.DateForm",
    );

    ///////////////////////////////
    /// States                  ///
    ///////////////////////////////

    const customTimeMode: Type_SelectOptionItem = {
        value: 0,
        label: fmt("Custom"),
    };

    const [open, setOpen] = useState<boolean>(false);
    const [timeModels, setTimeModels] = useState<Type_SelectOptionItem[]>([]);

    ///////////////////////////////
    /// Queries                 ///
    ///////////////////////////////

    const { data } = useSelectListTimeModels(open);

    ///////////////////////////////
    /// Form                   ///
    ///////////////////////////////

    const { getValues, setValue } = useFormContext();

    const initialValueWorkingDays: number =
        (getValues("timeModelWorkingDays") as number) || 0;
    const valueWorkingDaysCallback = (value: number): void =>
        setValue("timeModelWorkingDays", value);

    ///////////////////////////////
    /// Effects                 ///
    ///////////////////////////////

    // On first render, show current time model value
    useEffect(() => {
        if (!data && getValues(props.name) && timeModels.length === 0) {
            const nameParts = props.name.split(".");
            if (nameParts.length === 2) {
                const labelName = nameParts[0];
                setTimeModels([
                    {
                        value: getValues(props.name),
                        label: getValues(`${labelName}.name`),
                    },
                ]);
            }
        }
    }, []);

    // Add custom time model to the list
    useEffect(() => {
        if (data?.length) {
            const formattedData = formatSelectListToSelectOptionItem(data);
            setTimeModels((_old) =>
                custom ? [...formattedData, customTimeMode] : formattedData,
            );
        }
    }, [data]);

    return (
        <Stack gap={2} flex={1}>
            <Select
                {...props}
                options={timeModels}
                SelectProps={{
                    open: open,
                    onOpen: () => {
                        setOpen(true);
                    },
                    onClose: () => {
                        setOpen(false);
                    },
                }}
            />

            {getValues(props.name) === 0 && (
                <>
                    <FormLabel>{fmt("WorkingDays")}</FormLabel>
                    <DaysBadge
                        dataTestIdPrefix="Taskform-select-custom-time-model"
                        valueCallback={valueWorkingDaysCallback}
                        initialValue={initialValueWorkingDays}
                    />
                </>
            )}
        </Stack>
    );
};
