import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    debounce,
    Grid,
    IconButton,
    Input,
    List,
    Stack,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { ResourceKeys } from "src/api/tms-projects/keys";
import { useSelectListResources } from "src/api/tms-projects/resources";
import { Type_select_resource } from "src/api/tms-projects/resources/types";
import { ResourceTaskKeys } from "src/api/tms-scheduling/keys";
import {
    mutationCreateResourceTask,
    mutationDeleteResourceTask,
    mutationUpdateResourceTask,
    useIndexResourceTask,
} from "src/api/tms-scheduling/resourceTask";
import { formatterPostToIndex } from "src/api/tms-scheduling/resourceTask/formatter";
import { Type_index_resourceTasks } from "src/api/tms-scheduling/resourceTask/types";
import { Empty, TMC_Button } from "src/components";
import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { AutocompleteResources } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResources";
import { DEBOUNCE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { HeaderToolbar } from "src/layouts/Layout_ContextualDrawer/components/HeaderToolbar";
import {
    Content,
    Form,
    Header,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////
///     STYLED                             ///
//////////////////////////////////////////////

const resourceValidationSchema = Yup.object().shape({
    resources: Yup.array(),
});

type Type_Props_TaskForm = {
    onClose: () => void;
    id: number;
    focus: boolean;
};

export type Type_modalDeleteResource = {
    isOpen: boolean;
    id?: number;
    props?: any;
};

export const ResourceTaskForm = ({
    onClose,
    id: taskId,
    focus,
}: Type_Props_TaskForm) => {
    const { sendEvent } = useChannel({});
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    ///////////////////////////////////////
    ///            i18n                 ///
    ///////////////////////////////////////
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerResources",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtDrawer } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Resources",
    );

    ///////////////////////////////////////
    ///            States               ///
    ///////////////////////////////////////
    const [resources, setResources] = useState<Type_index_resourceTasks[]>([]);
    const [modalDeleteResource, setModalDeleteResource] =
        useState<Type_modalDeleteResource>({
            isOpen: false,
        });

    ///////////////////////////////////////
    ///            Api calls            ///
    ///////////////////////////////////////
    const { data: resourcesList } = useSelectListResources();

    const { isFetching: isFetchingResources } = useIndexResourceTask(
        setResources,
        taskId,
        "form",
    );

    const { mutateAsync: createResourceTask, isLoading: isCreatingResource } =
        mutationCreateResourceTask(taskId);
    const { mutateAsync: deleteResourceTask, isLoading: isDeletingResource } =
        mutationDeleteResourceTask(taskId);
    const { mutateAsync: updateResourceTask } =
        mutationUpdateResourceTask(taskId);

    // React hook form
    const form = useForm<any>({
        defaultValues: {
            resources: [],
        },
        resolver: yupResolver<any>(resourceValidationSchema),
    });

    const selectedResources = useWatch({
        control: form.control,
        name: "resources",
    });

    const addNewResourceToTask = async () => {
        for (const resource of selectedResources) {
            const { data } = await createResourceTask({
                resource_id: resource.id,
                quantity: 0,
            });
            const updatedResources = [
                ...formatterPostToIndex(resources, data.data),
            ];
            setResources((_prev) => updatedResources);
        }
        form.reset({ resources: [] });
    };

    const createResource = async (resourceId: number) => {
        await createResourceTask({
            resource_id: resourceId,
            quantity: 0,
        }).then(() => {
            Promise.all([
                queryClient.invalidateQueries([
                    ResourceKeys.SELECT_LIST,
                    requestConfig,
                ]),
                queryClient.invalidateQueries([
                    ResourceTaskKeys.INDEX,
                    { ...requestConfig, taskId },
                    "form",
                ]),
            ]);
        });
    };

    const deleteResourceCallback = () => {
        setResources((prev) => {
            return prev
                .map((resourceType) => {
                    const updatedResourceArray = resourceType.resources.filter(
                        (resource) =>
                            resource.id !== modalDeleteResource?.props?.id,
                    );
                    if (updatedResourceArray.length > 0) {
                        return {
                            ...resourceType,
                            resources: updatedResourceArray,
                        };
                    }
                    return null;
                })
                .filter(
                    (resourceType) => resourceType !== null,
                ) as Type_index_resourceTasks[];
        });
        setModalDeleteResource({ isOpen: false });
    };

    ///////////////////////////////////////
    ///         Handler                 ///
    ///////////////////////////////////////

    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        resourceId: number,
    ) => {
        const newResourceId = +event.target.value;
        const { data } = await updateResourceTask({
            id: resourceId,
            resource_id: newResourceId,
        });
        setResources((prevResources) => {
            return prevResources.map((resourceType) => {
                return {
                    ...resourceType,
                    resources: resourceType.resources.map((resource) => {
                        if (resource.resourceTaskId === resourceId) {
                            return {
                                id: newResourceId,
                                resourceTaskId: resourceId,
                                name: data.data.resource.name,
                                quantity: data.data.quantity,
                            };
                        }
                        return resource;
                    }),
                };
            });
        });
    };

    const handleQuantityChange = useCallback(
        debounce(
            async ({
                event,
                resourceTaskId,
            }: {
                event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                >;
                resourceTaskId: number;
            }) => {
                const newQuantity = +event.target.value;
                const { data } = await updateResourceTask({
                    id: resourceTaskId,
                    quantity: newQuantity,
                });
                setResources((prevResources) => {
                    return prevResources.map((resourceType) => {
                        return {
                            ...resourceType,
                            resources: resourceType.resources.map(
                                (resource) => {
                                    if (
                                        resource.resourceTaskId ===
                                        resourceTaskId
                                    ) {
                                        return {
                                            ...resource,
                                            quantity: data.data.quantity,
                                        };
                                    }
                                    return resource;
                                },
                            ),
                        };
                    });
                });
            },
            DEBOUNCE_TIME,
        ),
        [],
    );

    const resourceListOptions = (
        resourceTypeId: number,
    ): { label: string; value: number; disabled: boolean }[] => {
        if (!resourcesList) return [];
        return resourcesList
            .filter(
                (resourceList: Type_select_resource) =>
                    resourceList.resourceType.id === resourceTypeId,
            )
            .map((res: Type_select_resource) => {
                return {
                    label: res.name,
                    value: res.id,
                    disabled: resources
                        .flatMap((obj) =>
                            obj.resources.map((resource) => resource.id),
                        )
                        .includes(res.id),
                };
            });
    };

    const isAddButtonDisabled = useMemo(
        () => isCreatingResource || !selectedResources?.length,
        [isCreatingResource, selectedResources],
    );

    const isFetching = useMemo(
        () => isFetchingResources || isCreatingResource,
        [isFetchingResources, isCreatingResource],
    );

    const disabledOptions = useMemo(
        () =>
            resources.flatMap((obj) =>
                obj.resources.map((resource) => resource.id),
            ),
        [resources],
    );

    useEffect(() => {
        if (!isFetchingResources) {
            sendEvent("updateResourceTasks", resources);
        }
    }, [resources]);

    return (
        <FormProvider {...form}>
            <Form>
                <Header>
                    <HeaderToolbar onClose={onClose} />
                    <Typography>{fmt("Title")}</Typography>
                </Header>
                {isFetching ? (
                    <FullSpinner />
                ) : (
                    <>
                        <Stack
                            spacing={2}
                            mb={8}
                            direction="row"
                            alignItems={"end"}
                            padding={4}
                        >
                            <AutocompleteResources
                                autoFocus={focus}
                                name="resources"
                                placeholder={fmt("ResourcePlaceholder")}
                                disabledOptions={disabledOptions}
                                createResource={createResource}
                            />

                            <TMC_Button
                                data-testid={`add-new-resource-btn`}
                                variant="contained"
                                onClick={addNewResourceToTask}
                                disabled={isAddButtonDisabled}
                            >
                                {fmtActions("Add")}
                            </TMC_Button>
                        </Stack>
                        <Content padding={4}>
                            {resources.length > 0 ? (
                                resources.map((resourceType) => (
                                    <Box
                                        key={`resourceType_${resourceType.id}`}
                                        mb={2}
                                    >
                                        <Typography
                                            typography={"body2"}
                                            color="text.secondary"
                                        >
                                            {resourceType.name}
                                        </Typography>
                                        <List>
                                            {resourceType.resources.map(
                                                (resource) => (
                                                    <Grid
                                                        container
                                                        p={2}
                                                        key={`resource_${resource.id}`}
                                                        alignItems={"center"}
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={6}>
                                                            {resourceListOptions(
                                                                resourceType.id,
                                                            ).every(
                                                                (e) =>
                                                                    e.disabled,
                                                            ) ? (
                                                                <Typography>
                                                                    {
                                                                        resource.name
                                                                    }
                                                                </Typography>
                                                            ) : (
                                                                <Select
                                                                    name={`resource_${resource.id}`}
                                                                    onChange={(
                                                                        e,
                                                                    ) =>
                                                                        handleSelectChange(
                                                                            e,
                                                                            resource.resourceTaskId,
                                                                        )
                                                                    }
                                                                    value={
                                                                        resource.id
                                                                    }
                                                                    variant="standard"
                                                                    sx={{
                                                                        ".MuiInputBase-root::before, .MuiInputBase-root:hover::before":
                                                                            {
                                                                                borderBottom:
                                                                                    "none",
                                                                            },
                                                                        "& .MuiInput-input":
                                                                            {
                                                                                textWrap:
                                                                                    "wrap",
                                                                            },
                                                                    }}
                                                                    options={resourceListOptions(
                                                                        resourceType.id,
                                                                    )}
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Stack
                                                                spacing={1}
                                                                direction="row"
                                                                justifyContent={
                                                                    "end"
                                                                }
                                                                alignItems={
                                                                    "baseline"
                                                                }
                                                            >
                                                                <Input
                                                                    sx={{
                                                                        width: "25%",
                                                                        "::before":
                                                                            {
                                                                                borderBottom:
                                                                                    "none",
                                                                            },
                                                                    }}
                                                                    onChange={(
                                                                        e,
                                                                    ) =>
                                                                        handleQuantityChange(
                                                                            {
                                                                                event: e,
                                                                                resourceTaskId:
                                                                                    resource.resourceTaskId,
                                                                            },
                                                                        )
                                                                    }
                                                                    defaultValue={
                                                                        resource.quantity
                                                                    }
                                                                    name={`resource_${resource.id}_quantity`}
                                                                    type="number"
                                                                />
                                                                <Typography>
                                                                    {
                                                                        resourceType
                                                                            .unit
                                                                            .name
                                                                    }
                                                                </Typography>
                                                                <IconButton
                                                                    data-testid="remove-resource-task-btn"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setModalDeleteResource(
                                                                            {
                                                                                isOpen: true,
                                                                                id: resource.resourceTaskId,
                                                                                props: resource,
                                                                            },
                                                                        );
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        icon="minus"
                                                                        variant="light"
                                                                    />
                                                                </IconButton>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                ),
                                            )}
                                        </List>
                                    </Box>
                                ))
                            ) : (
                                <Empty
                                    message={fmtDrawer("EmptyResourceMessage")}
                                    dataTestIdRef={"ResourceTaskForm"}
                                />
                            )}
                        </Content>
                    </>
                )}
            </Form>
            <ModalDelete
                open={modalDeleteResource.isOpen}
                onClose={() => setModalDeleteResource({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteResource?.props?.name as string}
                actions={{
                    onSubmit: {
                        isLoading: isDeletingResource,
                        onClick: () => {
                            deleteResourceTask(
                                modalDeleteResource?.props
                                    ?.resourceTaskId as number,
                            ).then(deleteResourceCallback);
                        },
                    },
                }}
            />
        </FormProvider>
    );
};
