import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const EditorBox = styled(Box)<BoxProps>(({ theme }) => ({
    padding: theme.spacing(4),
    "& .MuiTiptap-MenuBar-root": {
        borderBottom: `${theme.border.default}`,
    },
    "& .ProseMirror": {
        fontSize: "14px",
        "& h1, & h2, & h3, & h4, & h5, & h6": {
            scrollMarginTop: 50,
        },
    },
    "& ol": {
        marginLeft: "25px",
    },
    "& ul": {
        marginLeft: "25px",
    },
}));
