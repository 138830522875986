import { Stack, StackProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useTheme } from "src/contexts/theme";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

export type Type_ThemeSwitcherProps = {
    headerText?: boolean;
};

type Type_Styled_ThemeElm = {
    ownerState: {
        background: string;
        borderRadius: string;
    };
};

const Styled_ThemeElm = styled(Stack)<Type_Styled_ThemeElm & StackProps>(
    ({ ownerState }) => ({
        cursor: "pointer",
        width: "100%",
        textTransform: "capitalize",
        textAlign: "center",
        color: COLORS.dark,
        background: ownerState.background,
        borderRadius: ownerState.borderRadius,
        ":hover": {
            backgroundColor: `${COLORS.slateGray100} !important`,
            borderRadius: "4px !important",
        },
    }),
);

const Styled_ThemeSwitcher = styled(Stack)(({ theme }) => ({
    backgroundColor: COLORS.moonAlpha100,
    borderRadius: theme.shape.borderRadiusLarge,
    margin: theme.spacing(2, 4, 0, 4),
    padding: theme.spacing(1),
}));

const THEMES = [
    {
        key: "light",
        icon: <Icon icon="sun-bright" variant="light" />,
    },
    {
        key: "dark",
        icon: <Icon icon="moon" variant="light" />,
    },
    {
        key: "auto",
        icon: <Icon icon="wand-magic-sparkles" variant="light" />,
    },
];

const ThemeElm = ({ label, themeType, setTheme, icon }: any) => {
    return (
        <Styled_ThemeElm
            onClick={() => setTheme(label)}
            data-testid={`ThemeSwitcher-${label}-btn`}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
            ownerState={{
                background:
                    themeType === label ? COLORS.slateGray100 : "transparent",
                borderRadius: themeType === label ? "4px" : "0px",
            }}
        >
            {icon}
            <Typography fontSize={"14px"} lineHeight={"32px"}>
                {label}
            </Typography>
        </Styled_ThemeElm>
    );
};

export const ThemeSwitcher = ({ headerText }: Type_ThemeSwitcherProps) => {
    const { theme, setMode } = useTheme();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("TopBar.Menus");

    return (
        <>
            {headerText && (
                <Typography variant="body3Bold" sx={{ mx: 4 }}>
                    {fmt("Theme")}
                </Typography>
            )}
            <Styled_ThemeSwitcher
                data-testid="ThemeSwitcher-btnGroup"
                className="space-x-1"
                direction={"row"}
                gap={1}
                justifyContent={"space-between"}
            >
                {THEMES.map((item, index) => {
                    return (
                        <ThemeElm
                            key={index}
                            setTheme={setMode}
                            label={item.key}
                            icon={item.icon}
                            themeType={theme.mode}
                        />
                    );
                })}
            </Styled_ThemeSwitcher>
        </>
    );
};
