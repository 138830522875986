import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";

export const projectSettingsCategoriesList: Type_sidebar_configuration = {
    nameLabel: "Project.Settings",
    baseUrl: "/projects/:projectSlug/settings",
    blocks: [
        {
            title: "ProjectSettings", // translation key, see app/src/layouts/Layout_Sidebar/Main/index.tsx
            categories: [
                {
                    nameLabel: "SubProjects",
                    urlName: "subprojects",
                    permissionKeys: ["subProjects_index"],
                    variant: "light",
                    icon: "list-tree",
                    contentType: "table",
                },
                {
                    nameLabel: "UserManagement",
                    urlName: "users",
                    permissionKeys: ["projectRoles_index"],
                    variant: "light",
                    icon: "user",
                    contentType: "table",
                },
                {
                    nameLabel: "Companies",
                    urlName: "companies",
                    permissionKeys: ["projectCompanies_index"],
                    variant: "light",
                    icon: "building",
                    contentType: "table",
                },
                {
                    nameLabel: "ProjectDetails",
                    urlName: "project-details",
                    permissionKeys: [
                        "projects_view",
                        "licenses_index",
                        "licenses_view",
                        "licenses_create",
                        "licenses_update",
                        "licenses_delete",
                    ],
                    variant: "light",
                    icon: "info",
                    contentType: "section",
                },
            ],
        },
        /* Temporairement désactivé : AP-3677
        {
            title: "SharedData", // translation key, see app/src/layouts/Layout_Sidebar/Main/index.tsx
            categories: [
                {
                    nameLabel: "SharedDaysOff", // translation key, see app/src/layouts/Layout_Sidebar/Main/index.tsx
                    urlName: "days-off",
                    permissionKeys: ["dayOffs_index"],
                    variant: "light",
                    contentType: "table",
                },
                {
                    nameLabel: "Trades",
                    urlName: "trades",
                    permissionKeys: ["trades_index"],
                    variant: "light",
                    contentType: "table",
                },
                {
                    nameLabel: "SharedResources",
                    urlName: "resources",
                    permissionKeys: [
                        "projectResourceTypes_index",
                        "resources_index",
                    ],
                    variant: "light",
                    contentType: "table",
                    subCategories: [
                        {
                            permissionKey: "resources_index",
                            nameLabel: "Resources",
                            urlName: "resources",
                        },
                        {
                            permissionKey: "projectResourceTypes_index",
                            nameLabel: "ResourceTypes",
                            urlName: "resource-types",
                        },
                    ],
                },
            ],
        },
         */
    ],
};
