import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_UserInformationHeader = styled(Box)(({ theme, color }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadiusLarge,
    width: "100%",
    height: 80,
    background: `
        radial-gradient(96.71% 651.92% at 8.06% 111.87%, rgba(255, 255, 255, 0) 0%, ${theme.palette.background.paper} 100%),
        linear-gradient(0deg, ${color}, ${color})
    `,
}));
