import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useCallback } from "react";
import { generatePath, Link, useParams } from "react-router-dom";

import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useLayoutSidebar } from "src/layouts/Layout_Sidebar/Layout_Sidebar_Provider";
import { Type_sidebar_subCategory } from "src/layouts/Layout_Sidebar/types";
import { URL_BASE } from "src/router";

export const Tabs = () => {
    const urlParams = useParams();
    const {
        configuration,
        currentCategory,
        currentSubCategoryUrlName,
        subCategories,
    } = useLayoutSidebar();
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl(
        `MenuSidebar.${configuration?.nameLabel}.Labels`,
    );

    const generateFullPath = useCallback(
        (tab: string): string => {
            // Check if we are on subproject settings page
            const basePath = urlParams.subProjectId
                ? `${URL_BASE}${configuration?.baseUrl}/subProject/:subProjectId/${currentCategory?.urlName}`
                : `${URL_BASE}${configuration?.baseUrl}/${currentCategory?.urlName}`;

            return generatePath(`${basePath}/${tab}`, {
                ...urlParams,
            });
        },
        [configuration, currentCategory, urlParams],
    );

    return (
        <Box
            sx={{
                width: "100%",
                typography: "body1",
                pl: 6,
                borderBottom: 1,
                borderColor: "divider",
            }}
        >
            {currentSubCategoryUrlName && (
                <TabContext
                    value={
                        currentSubCategoryUrlName ??
                        subCategories?.[0]?.urlName ??
                        ""
                    }
                >
                    <Box>
                        <TabList>
                            {subCategories?.map(
                                (
                                    item: Type_sidebar_subCategory,
                                    index: number,
                                ) => {
                                    return (
                                        <Tab
                                            data-testid={`Tab-${item.nameLabel}`}
                                            component={Link}
                                            to={generateFullPath(item.urlName)}
                                            value={item.urlName}
                                            key={index}
                                            label={fmtLabels(item.nameLabel)}
                                            sx={{
                                                textTransform: "none",
                                                alignItems: "start",
                                                paddingX: 0,
                                                minWidth: 0,
                                                marginRight: 4,
                                            }}
                                        />
                                    );
                                },
                            )}
                        </TabList>
                    </Box>
                </TabContext>
            )}
        </Box>
    );
};
