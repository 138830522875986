import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { CauseOfDelayForm } from "src/forms/causesOfDelay/CauseOfDelayForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_CauseOfDelayDrawer = {
    open: boolean;
    onClose: () => void;
    causeOfDelayIdToUpdate: number | null;
    action: Type_action;
    page: string;
};

export const CauseOfDelayDrawer = ({
    open,
    onClose,
    causeOfDelayIdToUpdate,
    action,
    page,
}: Type_Props_CauseOfDelayDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.CausesOfDelay",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`CauseOfDelay-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"causesOfDelay"}
            page={page}
        >
            <CauseOfDelayForm
                action={action}
                onClose={onClose}
                causeOfDelayIdToUpdate={
                    causeOfDelayIdToUpdate === null
                        ? undefined
                        : causeOfDelayIdToUpdate
                }
                setIsLoading={setIsLoading}
            ></CauseOfDelayForm>
        </DrawerGeneric>
    );
};
