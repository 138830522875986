import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { DaysOff, Url } from "src/api/paths";
import {
    Type_prj_post_dayOff,
    Type_prj_put_dayOff,
    Type_prj_putStatus_dayOff,
} from "src/api/tms-projects/daysOffs/types";

export const indexDaysOff = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${DaysOff.DAYS_OFF}`, requestConfig);
};

export const showDayOff = (
    requestConfig: Type_RequestConfig,
    id: number,
): GetReturnType => {
    return get(`${Url.PROJECTS}${DaysOff.DAYS_OFF}/${id}`, requestConfig);
};

export const statusDayOff = (
    data: Type_prj_putStatus_dayOff,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${DaysOff.DAYS_OFF}/${data.id}`,
        requestConfig,
    );
};

export const createDayOff = (
    data: Type_prj_post_dayOff,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.PROJECTS}${DaysOff.DAYS_OFF}`, requestConfig);
};

export const updateDayOff = (
    data: Type_prj_put_dayOff,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${DaysOff.DAYS_OFF}/${data.id}`,
        requestConfig,
    );
};

export const deleteDayOff = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(`${Url.PROJECTS}${DaysOff.DAYS_OFF}/${id}`, requestConfig);
};
