import { Box } from "@mui/material";
import React from "react";
import { Html } from "react-konva-utils";
import { HtmlTransformAttrs } from "react-konva-utils/es/html";

import {
    keyFlowCopy,
    keyFlowEdit,
    keyFlowMatrix,
    keyFlowShare,
    keyFlowTrash,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_Props_FlowToolbar } from "./FlowToolbar.type";
import { FlowToolbarButton } from "./FlowToolbarButton";
import { FlowToolbarDivider } from "./FlowToolbarDivider";

export const FlowToolbar = ({
    container,
    task,
    width,
    stageFlow,
}: Type_Props_FlowToolbar) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Planning");
    return (
        <Html
            transformFunc={(attrs: HtmlTransformAttrs): HtmlTransformAttrs => ({
                ...attrs,
                x: stageFlow.x,
                y: stageFlow.y,
                scaleX: 1,
                scaleY: 1,
            })}
        >
            <Box
                sx={{
                    display: "flex",
                    top: task.pt.y * stageFlow.scale - 8 - 30 - 18,
                    left: (task.pt.x + width / 2) * stageFlow.scale - 140 / 2,
                    height: "auto",
                    width: "140px",
                    backgroundColor: "#23272F",
                    position: "absolute",
                    color: "white",
                    borderRadius: "4px",
                    border: "solid 2px #23272F",
                    padding: "2px",
                }}
            >
                <FlowToolbarButton
                    code={keyFlowEdit}
                    container={container}
                    icon="pen"
                    title={fmt("Toolbar.Pen")}
                />

                <FlowToolbarButton
                    code={keyFlowMatrix}
                    container={container}
                    icon="table"
                    title={fmt("Toolbar.Table")}
                />

                <FlowToolbarDivider />

                <FlowToolbarButton
                    code={keyFlowTrash}
                    container={container}
                    icon="trash"
                    title={fmt("Toolbar.Trash")}
                />

                <FlowToolbarButton
                    code={keyFlowCopy}
                    container={container}
                    icon="copy"
                    title={fmt("Toolbar.Copy")}
                />

                <FlowToolbarDivider />

                <FlowToolbarButton
                    code={keyFlowShare}
                    container={container}
                    icon="share-from-square"
                    title={fmt("Toolbar.Share")}
                />
            </Box>
        </Html>
    );
};
