import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_SelectLinkType = {
    name: string;
    label?: string;
};

export const SelectLinkType = ({ name, label }: Type_Props_SelectLinkType) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerLinks",
    );

    return (
        <Select
            name={name}
            label={label}
            data-testid="Select-TaskLink"
            options={[
                { label: fmt("Types.SS"), value: 1 },
                { label: fmt("Types.FS"), value: 2 },
                { label: fmt("Types.SF"), value: 3 },
                { label: fmt("Types.FF"), value: 4 },
            ]}
            variant={"outlined"}
        />
    );
};

SelectLinkType.displayName = "SelectLinkType";
