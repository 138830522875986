import { Button } from "@mui/material";
import React from "react";

import { useContextualDrawer } from "./Provider_ContextualDrawer";

type Type_Props_Layout_ContextualDrawer = {
    // components: { [key: string]: any };
    name: string;
    value: number;
    children: any;
};
const Button_ContextualDrawer = ({
    name,
    value,
    children,
}: Type_Props_Layout_ContextualDrawer) => {
    const { openPaper } = useContextualDrawer();

    const updateContextualDrawer = (name: string, value: number) => {
        openPaper(name, { value: value });
    };

    return (
        <Button onClick={() => updateContextualDrawer(name, value)}>
            {children}
        </Button>
    );
};

export default Button_ContextualDrawer;
