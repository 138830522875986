import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import { Input } from "src/components/Components_Common/forms/reactHookFormComponents/Input/Input";
import { HeaderToolbar } from "src/components/Components_Teamoty/contextualDrawers/HeaderToolbar/HeaderToolbar";
import { IconSelectTaskType } from "src/components/Components_Teamoty/forms/select/IconSelectTaskType";
import { useLanguage } from "src/contexts/languages";
import { taskLinearBackground } from "src/forms/taskArea/TaskAreaFormComponents/TaskAreaFormHeader";
import { Header } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";

type Type_Props_TaskAreaPunchlistFormHeader = {
    onClose: () => void;
};

export const TaskAreaPunchlistFormHeader = ({
    onClose,
}: Type_Props_TaskAreaPunchlistFormHeader) => {
    const { languages } = useLanguage();
    const { getValues } = useFormContext();

    const [withTradeColor, subTradeTradeColor, color, taskId] = getValues([
        "withTradeColor",
        "subTrade.trade.color",
        "color",
        "taskId",
    ]);

    const localeToShortLocale = (locale: string) =>
        locale.slice(0, 2).toUpperCase();

    const backgroundColor = withTradeColor ? subTradeTradeColor : color;

    return (
        <Header
            data-testid="DrawerHeader"
            ownerState={{ borderBottom: true }}
            sx={{
                color: invertColor(backgroundColor),
                background: taskLinearBackground(backgroundColor),
            }}
        >
            <HeaderToolbar
                dataTestIdRef="Punchlist-Header"
                onClose={onClose}
                taskId={taskId}
                showFlagObservations={true}
            />
            <Stack direction={"row"} alignItems={"start"}>
                <Stack flexGrow={1}>
                    {languages.map((language, index) => (
                        <Grid container key={index} rowSpacing={0.5}>
                            <Grid item xs={1}>
                                {index === 0 ? ( // TODO should be refactored
                                    <Box sx={{ ml: 2 }}>
                                        <IconSelectTaskType
                                            data-testid={`select-icons-task`}
                                            name="type"
                                            readonly={true}
                                        />
                                    </Box>
                                ) : (
                                    <Typography
                                        variant={"body3"}
                                        paddingInlineStart={3}
                                        sx={(theme) => ({
                                            opacity: theme.opacity.mid,
                                        })}
                                    >
                                        {localeToShortLocale(language)}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={11} paddingInlineStart={2}>
                                <Input
                                    variant="standard"
                                    InputProps={{
                                        sx: { color: "inherit" },
                                    }}
                                    name={`names.${language}`}
                                    data-testid={`taskAreaPunchlist-drawer-input-name-${language}`}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Stack>
            </Stack>
        </Header>
    );
};
