import {
    DialogProps,
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";

import { LoadingBox } from "src/components/Components_Common";
import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_WorkspaceConfirmModal = DialogProps & {
    handleClose: () => void;
    handleClick: (type: string) => void;
    selected: string;
    isLoading: boolean;
};

export const WorkspaceConfirmModal = ({
    handleClose,
    handleClick,
    selected,
    isLoading,
    ...props
}: Type_Props_WorkspaceConfirmModal) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Workspace.ModalConfirm",
    );

    const handleConfirm = () => {
        handleClick(selected);
        handleClose();
    };

    return (
        <MuiDialog {...props}>
            <Stack direction={"row"} justifyContent={"end"}>
                <IconButton
                    size="small"
                    onClick={handleClose}
                    data-testid={"Dialog-Close-btn"}
                    title={fmtActions("Close")}
                    color={"inherit"}
                >
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            </Stack>
            <Stack direction={"row"} paddingX={10}>
                <SectionTitle
                    title={fmt("Title")}
                    data-testid={"WorkspaceConfirmationDialog-Title"}
                />
            </Stack>
            <MuiDialogContent sx={{ paddingX: 10, marginTop: 0 }}>
                <Typography variant="body1">{fmt("Content")}</Typography>
            </MuiDialogContent>
            <MuiDialogActions>
                <TMC_Button
                    data-testid={"WorkspaceConfirmationDialog-Apply-btn"}
                    size="medium"
                    variant="contained"
                    color={"success"}
                    startIcon={
                        isLoading ? (
                            <LoadingBox />
                        ) : (
                            <Icon variant="solid" icon="check" />
                        )
                    }
                    onClick={handleConfirm}
                >
                    {fmtActions("Apply")}
                </TMC_Button>
            </MuiDialogActions>
        </MuiDialog>
    );
};
