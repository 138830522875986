import { styled } from "@mui/material";
import { forwardRef } from "react";

import { Type_Props_TextField } from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextField";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";

const Styled_TextField = styled(TextField)(({ theme }) => ({
    ...theme.typography.body1Medium,
    ".MuiInputBase-root": {
        ...theme.typography.body1Medium,
        color: "inherit",
        // Ce double sélecteur Mui-disabled peut sembler inutile
        // mais je n'ai pas trouvé d'autre moyen de faire fonctionner le style avec le TaskForm en readonly
        "& .Mui-disabled": {
            color: "inherit",
            WebkitTextFillColor: "inherit",
        },
        "&.Mui-disabled": {
            color: "inherit",
            WebkitTextFillColor: "inherit",
        },
    },
}));

type Type_Props_TextFieldStyled = Omit<
    Type_Props_TextField,
    "variant" | "size"
> & {
    name: string;
};

export const Styled_HeaderInput = forwardRef<
    HTMLInputElement,
    Type_Props_TextFieldStyled
>((props, ref) => {
    return (
        <Styled_TextField
            {...props}
            ref={ref}
            variant="standard"
            size="small"
            fullWidth
        />
    );
});

Styled_HeaderInput.displayName = "Styled_HeaderInput";
