import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "@mui/material";
import { useCallback } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { TaskKeys } from "src/api/tms-scheduling/keys";
import { mutationUpdateTask } from "src/api/tms-scheduling/tasks";
import {
    formatterShowToPutTask,
    formatterShowToTaskEvent,
} from "src/api/tms-scheduling/tasks/formatters";
import {
    Type_put_task,
    Type_sch_show_task,
    Type_show_task,
} from "src/api/tms-scheduling/tasks/types";
import { DEBOUNCE_TIME, useFormDefaultConfig } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { HeaderForm } from "src/forms/tasks/HeaderForm/HeaderForm";
import { nameFormSchema } from "src/forms/tasks/NameForm/NameForm";
import { Type_event_task, useChannel } from "src/hooks/useChannel";
import {
    Content,
    Form,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { ShortKeys } from "src/utils/shortcuts";

//////////////////////////////////////////////
///     FORM                               ///
//////////////////////////////////////////////

const taskValidationSchema = Yup.object().shape({
    ...nameFormSchema.fields,
    // ...mainFormSchema.fields,
    // ...dateFormSchema.fields,
    // ...automaticOptimizationSchema.fields,
    // ...waitingDaysFormSchema.fields,
    // ...slideOutFormSchema.fields,
    // ...advancedSchema.fields,
});

//////////////////////////////////////////////
///     TYPES                              ///
//////////////////////////////////////////////

type Type_Props_GanttTaskForm = {
    onClose: () => void;
    data: Type_show_task;
};

export const GanttTaskForm = ({
    onClose,
    data: task,
}: Type_Props_GanttTaskForm) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    ///////////////////////////////////////
    ///         Queries                 ///
    ///////////////////////////////////////

    const { mutateAsync } = mutationUpdateTask();

    ///////////////////////////////////////
    ///         Events                  ///
    ///////////////////////////////////////

    const { sendEvent } = useChannel({
        eventHandler: ({ event, data }) => {
            if (event === `updateTask_${task.id}`) {
                const formattedData = formatterShowToPutTask(
                    data as Type_show_task,
                );
                form.reset({ ...form.getValues(), ...formattedData });
            }
        },
    });

    ///////////////////////////////////////
    ///         Form                    ///
    ///////////////////////////////////////

    const formattedTask = formatterShowToPutTask(task);

    const form = useForm<Type_put_task>({
        ...useFormDefaultConfig,
        defaultValues: formattedTask,
        values: formattedTask,
        mode: "onBlur", // submit onBlur
        resolver: yupResolver<any>(taskValidationSchema),
    });

    const onSubmit: SubmitHandler<Type_put_task> = async (
        values: Type_put_task,
    ) => {
        console.log("====> onSubmit: should call API", values);
        await form.trigger();

        if (Object.keys(form.formState.dirtyFields).length > 0) {
            const result = Object.fromEntries(
                Object.keys(form.formState.dirtyFields).map((key) => [
                    key,
                    values[key as keyof Type_put_task],
                ]),
            ) as Type_put_task;

            // check if result is empty
            if (Object.keys(result).length === 0) {
                console.debug(
                    "====> onSubmit: no dirty fields to submit",
                    result,
                );
                return;
            }

            // Add ids for request headers
            result.id = values.id;

            console.debug("====> onSubmit: dirty fields to submit", result);
            await mutateAsync(result).then((data) => {
                if (data.success && data?.data?.data) {
                    const formattedData: Type_event_task =
                        formatterShowToTaskEvent(
                            data.data.data as Type_sch_show_task,
                        );
                    sendEvent("updateTask", formattedData);
                }
            });
        } else {
            console.debug("====> onSubmit: no dirty fields to submit");
        }
    };

    const debounceOnSubmit = useCallback(
        debounce((props) => onSubmit(props), DEBOUNCE_TIME),
        [],
    );

    ///////////////////////////////////////
    ///         Everything else         ///
    ///////////////////////////////////////

    // React Query refresh function to invalidate the cache
    const refresh = useCallback(() => {
        queryClient.invalidateQueries([TaskKeys.SHOW, task.id, requestConfig]);
    }, [task.id, requestConfig]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === ShortKeys.ENTER) {
            (event.target as HTMLElement).blur();
        }
    };

    return (
        <FormProvider {...form}>
            <Form
                onKeyDown={handleKeyDown}
                onBlur={form.handleSubmit(debounceOnSubmit, (props) =>
                    console.error("handleSubmit: invalid response", props),
                )}
                data-testid="GanttTaskForm-Form"
            >
                <HeaderForm
                    refresh={refresh}
                    onClose={onClose}
                    data={task}
                    readonly={false}
                />
                <Content data-testid="GanttTaskForm-Content">
                    {/*<MainForm/>*/}
                    {/*<GanttTaskFormAccordionList task={task} />*/}
                    {/*<DevTool control={form.control} />*/}
                </Content>
            </Form>
        </FormProvider>
    );
};
