import { Divider, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useLayoutSidebar } from "src/layouts/Layout_Sidebar/Layout_Sidebar_Provider";
import {
    Styled_LayoutSidebarMainSection,
    Styled_LayoutSidebarMainTable,
    Styled_LayoutSidebarRoot,
    Styled_MainContent,
} from "src/layouts/Layout_Sidebar/stylesheet";
import { Tabs } from "src/layouts/Layout_Sidebar/Tabs/Tabs";

type Type_Props_Main = {
    children: React.ReactNode;
};

const MainSection = ({ children }: Type_Props_Main) => {
    return (
        <Styled_LayoutSidebarMainSection>
            {children}
        </Styled_LayoutSidebarMainSection>
    );
};

const MainTable = ({ children }: Type_Props_Main) => {
    return (
        <Styled_LayoutSidebarMainTable>
            {children}
        </Styled_LayoutSidebarMainTable>
    );
};

export const Main = () => {
    const { title, currentCategory } = useLayoutSidebar();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Labels");

    return (
        <Styled_LayoutSidebarRoot data-testid={"LayoutSidebar-Main-Container"}>
            <Typography
                variant="h1"
                sx={{
                    px: 6,
                    pb: 0,
                    pt: currentCategory?.subCategories ? 4 : 8,
                }}
                data-testid={`LayoutSidebar-Main-Title-${title}`}
            >
                {title && title.trim() !== "" && fmt(title)}
            </Typography>
            <div>
                {currentCategory?.subCategories ? (
                    <Tabs />
                ) : (
                    <Divider orientation="horizontal" sx={{ paddingTop: 8 }} />
                )}
            </div>
            <Styled_MainContent
                data-testid={"LayoutSidebar-Main-Content"}
                ownerState={{
                    isOverflowHidden: currentCategory?.contentType === "table",
                }}
            >
                {currentCategory?.contentType === "section" ? (
                    <MainSection>
                        <Outlet />
                    </MainSection>
                ) : (
                    <MainTable>
                        <Outlet />
                    </MainTable>
                )}
            </Styled_MainContent>
        </Styled_LayoutSidebarRoot>
    );
};
