import { Grid, Stack, Typography } from "@mui/material";

import { Avatar } from "src/components/Components_Common";

type Type_Props_UserNameCell = {
    id: string;
    name: string;
    email: string;
    enabled: boolean;
};

export const UserNameCell = ({
    id,
    name,
    email,
    enabled,
}: Type_Props_UserNameCell) => {
    return (
        <Grid container direction={"row"}>
            <Grid item paddingRight={2} alignSelf={"center"}>
                <Avatar
                    color={!enabled ? "text.disabled" : "inherit"}
                    data-testid={`UserNameCell-Avatar-${id}`}
                    enabled={enabled}
                    name={name ? name : "_"}
                    sx={{ width: 24, height: 24, fontSize: "12px" }}
                />
            </Grid>
            <Grid item>
                {name !== "" ? (
                    <Stack>
                        <Typography
                            data-testid={`UserNameCell-name-${id}`}
                            textOverflow={"ellipsis"}
                            variant="body1Medium"
                            color={!enabled ? "text.disabled" : "inherit"}
                        >
                            {name}
                        </Typography>
                        <Typography
                            data-testid={`UserNameCell-email-${id}`}
                            textOverflow={"ellipsis"}
                            color={
                                !enabled ? "text.disabled" : "text.secondary"
                            }
                            variant="body3Medium"
                        >
                            {email}
                        </Typography>
                    </Stack>
                ) : (
                    <Typography
                        data-testid={`UserNameCell-email-${id}`}
                        textOverflow={"ellipsis"}
                        variant="body1Medium"
                        color={!enabled ? "text.disabled" : "inherit"}
                    >
                        {email}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
