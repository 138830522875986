import { useEffect, useState } from "react";

import { useCoreIntl } from "src/hooks/useCoreIntl";

type type_Props_useDaysContext = {
    initialValue?: number;
    valueCallback?: (value: number) => void;
};
export const useDaysContext = ({
    initialValue = 0,
    valueCallback,
}: type_Props_useDaysContext) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Days");
    const [value, setValue] = useState<number>(initialValue);

    const days = [
        { key: "monday", binary: 1, label: fmt("Monday") },
        { key: "tuesday", binary: 2, label: fmt("Tuesday") },
        { key: "wednesday", binary: 4, label: fmt("Wednesday") },
        { key: "thursday", binary: 8, label: fmt("Thursday") },
        { key: "friday", binary: 16, label: fmt("Friday") },
        { key: "saturday", binary: 32, label: fmt("Saturday") },
        { key: "sunday", binary: 64, label: fmt("Sunday") },
    ];

    const handleDayToggle = (
        dayBinary: number,
        isChecked: boolean | null = null,
    ) => {
        if (isChecked === null) {
            isChecked = !!(value & dayBinary);
        }

        const updatedDays = isChecked ? value | dayBinary : value & ~dayBinary;
        setValue(updatedDays);
    };

    useEffect(() => {
        if (valueCallback) {
            valueCallback(value);
        }
    }, [value]);

    return { days, value, handleDayToggle, setValue };
};
