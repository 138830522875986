import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { PunchlistItemCausePath, Url } from "src/api/paths";
import {
    Type_com_post_punchlistItemCause,
    Type_com_put_punchlistItemCause,
} from "src/api/tms-commons/punchlistItemCauses/types";

export const indexPunchlistItemCauses = (): GetReturnType => {
    return get(`${Url.COMMONS}${PunchlistItemCausePath.PUNCHLIST_ITEM_CAUSE}`);
};

export const createPunchlistItemCause = (
    data: Type_com_post_punchlistItemCause,
): PostReturnType => {
    return post(
        data,
        `${Url.COMMONS}${PunchlistItemCausePath.PUNCHLIST_ITEM_CAUSE}`,
    );
};

export const showPunchlistItemCause = (id: number): GetReturnType => {
    return get(
        `${Url.COMMONS}${PunchlistItemCausePath.PUNCHLIST_ITEM_CAUSE}/${id}`,
    );
};

export const updatePunchlistItemCause = (
    id: number,
    data: Type_com_put_punchlistItemCause,
): PutReturnType => {
    return put(
        data,
        `${Url.COMMONS}${PunchlistItemCausePath.PUNCHLIST_ITEM_CAUSE}/${id}`,
    );
};

export const statusPunchlistItemCause = (
    data: Type_com_put_punchlistItemCause,
    id: number,
): PutReturnType => {
    return put(
        data,
        `${Url.COMMONS}${PunchlistItemCausePath.PUNCHLIST_ITEM_CAUSE}/${id}`,
    );
};

export const deletePunchlistItemCause = (id: number): RemoveReturnType => {
    return remove(
        `${Url.COMMONS}${PunchlistItemCausePath.PUNCHLIST_ITEM_CAUSE}/${id}`,
    );
};

export const selectPunchlistItemCauses = (): GetReturnType => {
    return get(`${Url.COMMONS}${PunchlistItemCausePath.SELECT_LIST}`);
};
