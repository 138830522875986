import { Accordion, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_DrawingsTopBar = styled(Stack)(() => ({
    flexGrow: 1,
}));

Styled_DrawingsTopBar.defaultProps = {
    direction: "row",
    justifyContent: "space-between",
    spacing: 1,
};

export const Styled_Grid = styled(Stack)(() => ({
    flexGrow: 1,
}));

export const Styled_Accordion = styled(Accordion)(() => ({}));

Styled_Accordion.defaultProps = {
    defaultExpanded: true,
    disableGutters: true,
    elevation: 0,
    square: true,
};
