import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import { useIndexDefaultPriority } from "src/api/tms-commons/priorityOfConstraints";
import {
    mutationCreateDefaultConstraint,
    useIndexConstraints,
} from "src/api/tms-scheduling/constraints";
import { formatterGetEventIndexConstraint } from "src/api/tms-scheduling/constraints/formatters";
import { Type_index_constraint } from "src/api/tms-scheduling/constraints/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { ItemChildrenProps } from "src/components/Components_Common/accordions/Accordion";
import { Empty } from "src/components/Components_Common/Empty/Empty";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Spinner } from "src/components/Components_Common/Spinner/Spinner";
import { LONG_STALE_TIME } from "src/configurations/app";
import {
    Type_event_constraint,
    Type_event_message,
    useChannel,
} from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

import { TaskObservation } from "./TaskObservation";

type Type_Props_TaskObservations = ItemChildrenProps & {
    taskId: number;
    areaId: number;
    taskAreaId: number;
};

export const TaskObservations = ({
    taskId,
    areaId,
    taskAreaId,
}: Type_Props_TaskObservations) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations",
    );
    const { openPaper } = useContextualDrawer();
    const [observations, setObservations] = useState<Type_index_constraint[]>(
        [],
    );

    /////////////////////////
    ///     Channels      ///
    /////////////////////////
    const { sendEvent } = useChannel({
        eventHandler: ({ event, data }: Type_event_message) => {
            if (event === "addObservation") {
                const observationsData = data as Type_event_constraint;
                const formattedConstraint: Type_index_constraint =
                    formatterGetEventIndexConstraint(observationsData);
                setObservations((old) => {
                    if (!Array.isArray(old)) {
                        return [formattedConstraint];
                    }
                    return [...old, formattedConstraint];
                });
            }
            if (event === "deleteObservation") {
                const observationId = data as number;
                setObservations((old) =>
                    old.filter(
                        (observation) => observation.id !== observationId,
                    ),
                );
            }
        },
    });

    const handleCreatedConstraint = (constraint: Type_index_constraint) => {
        openPaper("observation", { constraint });
        sendEvent("setActiveObservation", constraint.id);
    };

    ////////////////////////////////
    ///    Queries & Mutations   ///
    ////////////////////////////////

    const { isFetching, data } = useIndexConstraints({
        taskAreaId,
    });

    useEffect(() => {
        if (data && !isFetching) {
            setObservations(data);
            sendEvent("setFlagObservations", data?.length);
        }
    }, [isFetching]);

    const { mutateAsync: createDefaultConstraint } =
        mutationCreateDefaultConstraint(handleCreatedConstraint, {
            taskId,
            taskAreaId,
            areaId,
        });
    const { data: defaultPriority } = useIndexDefaultPriority(LONG_STALE_TIME);

    const handleAddNewConstraint = async (
        e: React.MouseEvent<Element, MouseEvent>,
    ) => {
        e.preventDefault();
        e.stopPropagation();
        const defaultConstraintParams = {
            priority: defaultPriority as number,
        };
        await createDefaultConstraint(defaultConstraintParams);
    };

    return isFetching ? (
        <FullSpinner />
    ) : (
        <Stack gap={2}>
            {taskAreaId && observations?.length ? (
                observations.map((obs) => (
                    <TaskObservation key={obs.id} constraint={obs} />
                ))
            ) : (
                <Empty
                    actionMessage={fmt("CreateNew")}
                    actionBtnVariant="text"
                    message={fmt("EmptyMessage")}
                    onClick={handleAddNewConstraint}
                    dataTestIdRef="TaskObservations-empty"
                />
            )}
        </Stack>
    );
};

type Type_Props_TaskObservationsActions = {
    taskId: number;
    areaId: number;
    taskAreaId: number;
};

/**
 * TODO move to new file
 */
export const TaskObservationsActions = ({
    taskId,
    areaId,
    taskAreaId,
}: Type_Props_TaskObservationsActions) => {
    const theme = useTheme();
    const { openPaper } = useContextualDrawer();
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { data: defaultPriority } = useIndexDefaultPriority(LONG_STALE_TIME);

    const handleCreatedConstraint = (constraint: Type_index_constraint) => {
        openPaper("observation", { constraint });
        sendEvent("setActiveObservation", constraint.id);
    };

    const { isLoading, mutateAsync: createDefaultConstraint } =
        mutationCreateDefaultConstraint(handleCreatedConstraint, {
            taskId,
            taskAreaId,
            areaId,
        });

    const handleClick = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const defaultConstraintParams = {
            priority: defaultPriority as number,
        };

        await createDefaultConstraint(defaultConstraintParams);
    };

    return (
        <IconButton
            sx={{
                display: "flex",
                alignItems: "center",
                padding: theme.spacing(1),
            }}
            color="primary"
            data-testid={`Add-new-observation-btn`}
            onClick={handleClick}
            disabled={isLoading}
        >
            {isLoading ? (
                <Spinner style="button" size="small" />
            ) : (
                <Icon
                    color="inherit"
                    fontSize="small"
                    icon="add"
                    variant="light"
                />
            )}
            <Typography variant="body3Medium" color={"inherit"}>
                {fmtActions("New")}
            </Typography>
        </IconButton>
    );
};
