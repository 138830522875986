import Konva from "konva";
import React, { useState } from "react";
import { Group, Line, Path, Text } from "react-konva";

import { TextEdit } from "src/components/Components_Common/canvas/TextEdit";
import {
    marginPanningAreaLineBottom,
    marginPanningAreaLineTop,
    offsetPlanningArea,
    pictogramCaretDown,
    pictogramCaretRight,
    sizePlanningAreaPictogram,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { usePlanningContext } from "src/contexts/planning";

import { Type_Props_PlanningArea } from "./PlanningArea.type";

export const PlanningArea = ({
    area,
    x,
    y,
    width,
    heightArea,
    onChangeNameArea,
    onChangeCloseArea,
}: Type_Props_PlanningArea) => {
    const theme = usePlanningTheme();
    const { defaultCursor } = usePlanningContext();
    const [open, setOpen] = useState<boolean>(area.close === true);

    const withCloseIcon: boolean =
        !!area.areas || typeof area.lines[0] === "string";

    const offsetText: number = withCloseIcon
        ? -offsetPlanningArea * 2
        : -offsetPlanningArea;
    const height: number = area.size * heightArea;

    return (
        <Group>
            {withCloseIcon && (
                <Path
                    x={
                        x +
                        (offsetPlanningArea * 3) / 2 -
                        sizePlanningAreaPictogram / 3
                    }
                    y={y + heightArea / 2 - sizePlanningAreaPictogram / 2}
                    scaleX={sizePlanningAreaPictogram / 512}
                    scaleY={sizePlanningAreaPictogram / 512}
                    fill={theme.colorPanningAreaIcon}
                    data={open ? pictogramCaretRight : pictogramCaretDown}
                    hitStrokeWidth={sizePlanningAreaPictogram * 30}
                    onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) => {
                        changeCursor(e, "pointer");
                    }}
                    onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) => {
                        changeCursor(e, defaultCursor);
                    }}
                    onClick={(e: Konva.KonvaEventObject<MouseEvent>) => {
                        e.cancelBubble = true;
                        area.close = !area.close;
                        setOpen((prev: boolean) => !prev);
                        onChangeCloseArea && onChangeCloseArea();
                    }}
                />
            )}
            <Line
                offsetX={-offsetPlanningArea / 2}
                points={[
                    x,
                    y + marginPanningAreaLineTop,
                    x,
                    y + height - marginPanningAreaLineBottom,
                ]}
                stroke={area.color}
                strokeWidth={3}
                lineCap="round"
            />
            {!onChangeNameArea && (
                <Text
                    x={x - offsetText}
                    y={y}
                    padding={5}
                    height={heightArea}
                    verticalAlign="middle"
                    wrap="none"
                    ellipsis={true}
                    width={width - x + offsetText}
                    text={area.name}
                    fill={theme.colorPanningAreaTexte}
                />
            )}
            {onChangeNameArea && (
                <TextEdit
                    x={x - offsetText}
                    y={y}
                    padding={5}
                    height={heightArea}
                    verticalAlign="middle"
                    wrap="none"
                    ellipsis={true}
                    width={width - x + offsetText}
                    text={area.name}
                    fill={theme.colorPanningAreaTexte}
                    onChange={(value: string): void => {
                        area.name = value;
                        onChangeNameArea(area.id, value);
                    }}
                />
            )}

            <Line
                points={[x, y, x + width, y]}
                stroke={theme.colorPlanningLine}
                strokeWidth={1}
            />
        </Group>
    );
};
