import React from "react";
import { Group, Layer } from "react-konva";
import useMeasure from "react-use-measure";

import { Image } from "src/components/Components_Common/canvas/Image";
import { Shape } from "src/components/Components_Common/canvas/Shape";
import { Stage } from "src/components/Components_Common/canvas/Stage";
import { StageProvider } from "src/components/Components_Common/canvas/StageContext";

const allShapes: any = [
    {
        label: {
            name: "forme 1",
            width: 100,
            rotation: 0,
            pt: {
                x: 50,
                y: 50,
            },
        },
        pts: [
            { x: 50, y: 50 },
            { x: 100, y: 50 },
            { x: 100, y: 100 },
            { x: 50, y: 100 },
            { x: 25, y: 100 },
        ],
    },

    {
        label: {
            name: "forme 2",
            width: 100,
            rotation: 0,
            pt: {
                x: 250,
                y: 250,
            },
        },
        pts: [
            { x: 250, y: 250 },
            { x: 300, y: 250 },
            { x: 300, y: 300 },
            { x: 250, y: 300 },
        ],
    },
    {
        label: {
            name: "forme 3",
            width: 100,
            rotation: 0,
            pt: {
                x: 350,
                y: 350,
            },
        },
        pts: [
            { x: 350, y: 350 },
            { x: 400, y: 350 },
            { x: 400, y: 400 },
            { x: 350, y: 400 },
        ],
    },
];

export const KonvaDrawing = () => {
    const [ref, bounds] = useMeasure();

    return (
        <div
            style={{
                width: "80vw",
                height: "80vh",
                backgroundColor: "lightgray",
                margin: "10px",
            }}
            ref={ref}
        >
            <StageProvider>
                <Stage bounds={bounds}>
                    <Layer>
                        <Image src="https://konvajs.org/assets/yoda.jpg" />
                        {allShapes.map((shape: any, index: number) => (
                            <Shape
                                key={index}
                                index={index + 1}
                                pts={shape.pts}
                                label={shape.label}
                                color="#007AFF"
                            />
                        ))}
                        <Group name="top" />
                    </Layer>
                </Stage>
            </StageProvider>
        </div>
    );
};
