import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import * as Yup from "yup";

import {
    mutationCreateCauseOfDelay,
    mutationUpdateCauseOfDelay,
    useShowCauseOfDelay,
} from "src/api/tms-commons/causesOfDelay";
import {
    Type_post_causeOfDelay,
    Type_show_causeOfDelay,
} from "src/api/tms-commons/causesOfDelay/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { useFormDefaultConfig } from "src/configurations/app";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_CauseOfDelayForm = {
    onClose: () => void;
    causeOfDelayIdToUpdate?: number | undefined;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_CauseOfDelayForm = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.lazy(() => inputMultiLanguagesSchema()),
    });
});

export const CauseOfDelayForm = ({
    onClose,
    causeOfDelayIdToUpdate,
    action,
    setIsLoading,
}: Type_Props_CauseOfDelayForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.CausesOfDelay",
    );

    const { mutateAsync: mutateCreate } = mutationCreateCauseOfDelay();
    const { mutateAsync: mutateUpdate } = mutationUpdateCauseOfDelay();
    const { data, isFetching } = useShowCauseOfDelay(
        causeOfDelayIdToUpdate as number,
    );

    const form = useTmcForm<Type_show_causeOfDelay | Type_post_causeOfDelay>({
        ...useFormDefaultConfig,
        defaultValues: {
            names: {},
        },
        values: data,
        resolver: yupResolver<any>(Schema_CauseOfDelayForm),
        onClose,
        setIsLoading,
        action,
    });

    const handleSubmit = async (values: any) => {
        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_causeOfDelay);
            } else {
                delete values.id;
                await mutateCreate(values as Type_post_causeOfDelay);
            }
            // Fermeture du drawer
            onClose();
        } catch (_e: any) {
            console.error("Error when creating or updating cause of delay");
        }
    };

    return (
        <TmcFormProvider form={form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"causesOfDelay"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <InputMultiLanguages
                        name="names"
                        label={fmt("CauseOfDelayName")}
                    />
                )}
            </form>
        </TmcFormProvider>
    );
};
