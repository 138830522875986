import { DialogTitleProps, DialogTitle as MuiDialogTitle } from "@mui/material";
import React from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

type Type_Props_DialogTitle = DialogTitleProps & {
    handleClose: () => void;
};

export const TMC_DialogTitle = ({
    handleClose,
    children,
    ...props
}: Type_Props_DialogTitle) => {
    return (
        <MuiDialogTitle {...props}>
            {children}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                data-testid="drawer-iconButton-close"
            >
                <Icon variant="solid" icon="xmark" />
            </IconButton>
        </MuiDialogTitle>
    );
};
