import { Stack } from "@mui/material";
import React, { ReactNode, useContext } from "react";

import defaultImg from "src/assets/images/background-login.jpeg";
import Glob from "src/assets/images/globe2.svg";
import { languages as languagesList } from "src/assets/translations";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { LanguagesContext } from "src/contexts/languages";

import {
    Styled_imgLogin,
    Styled_optionSelectLangLogin,
    Styled_selectLangLogin,
    Styled_StackFooterLogin,
    Styled_StackHeaderLogin,
    Styled_StackLoginLeft,
    Styled_StackLoginMain,
    Styled_StackLoginRight,
} from "./Layout_NotLogged.style";

type Type_Layout_NotLoggedComponentProps = {
    children: React.ReactNode;
    services: object[];
    servicesSideMenu: object;
    img?: string;
};

export const Layout_NotLoggedComponent = ({
    children,
    img = "",
}: Type_Layout_NotLoggedComponentProps) => {
    return (
        <Styled_StackLoginMain>
            <Styled_StackLoginLeft data-testid="Identification-LoginLeft-container">
                <HeaderLogin />
                <Stack
                    width="100%"
                    flexGrow={1}
                    alignItems="center"
                    justifyContent="center"
                    overflow={"auto"}
                >
                    {children}
                </Stack>
                <FooterLogin />
            </Styled_StackLoginLeft>
            <Styled_StackLoginRight>
                <Styled_imgLogin
                    data-testid="Not_Logged_Img_Building_Site"
                    src={img ? img : defaultImg}
                    alt="building site"
                />
            </Styled_StackLoginRight>
        </Styled_StackLoginMain>
    );
};

const HeaderLogin = () => {
    return (
        <Styled_StackHeaderLogin>
            <div></div>
        </Styled_StackHeaderLogin>
    );
};

const FooterLogin = () => {
    // const { formatMessageWithPartialKey: fmt } = useCoreIntl("Identification");
    const { switchLanguage, languages, language } =
        useContext(LanguagesContext);

    // const links = [
    //     { label: "ReleaseNote", to: "/" },
    //     { label: "CGU", to: "/" },
    //     { label: "LegalNotice", to: "/" },
    // ];

    return (
        <Styled_StackFooterLogin>
            <Stack
                data-testid="FooterLogin-selectLang"
                flexWrap="nowrap"
                flexDirection="row"
                gap={1}
                alignItems="center"
            >
                <img src={Glob} alt="globe" style={{ width: "16px" }} />
                <Styled_selectLangLogin
                    onChange={(e: React.ChangeEvent<any>) =>
                        switchLanguage(e.target.value as string)
                    }
                    value={language}
                >
                    {languages.map((language: string, index: number) => (
                        <Styled_optionSelectLangLogin
                            value={language}
                            key={index}
                        >
                            {languagesList[language] as ReactNode}
                        </Styled_optionSelectLangLogin>
                    ))}
                </Styled_selectLangLogin>
                <Icon
                    variant="solid"
                    icon="caret-down"
                    style={{ position: "relative", left: "-20px" }}
                />
            </Stack>
            {/*<Stack flexWrap="nowrap" flexDirection="row" gap={2}>*/}
            {/*    {links.map((link, index) => (*/}
            {/*        <Link*/}
            {/*            to={link.to}*/}
            {/*            key={index}*/}
            {/*            data-testid={`FooterLogin-link-${link.label}`}*/}
            {/*        >*/}
            {/*            <Typography*/}
            {/*                key={link.label}*/}
            {/*                variant="body3Underline"*/}
            {/*                color={COLORS.blue700}*/}
            {/*            >*/}
            {/*                {fmt(link.label)}*/}
            {/*            </Typography>*/}
            {/*        </Link>*/}
            {/*    ))}*/}
            {/*</Stack>*/}
        </Styled_StackFooterLogin>
    );
};
