import {
    sizeFlowGridX,
    sizeFlowGridY,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import {
    Type_flow,
    Type_flowTask,
} from "src/components/Components_Teamoty/Flow/Flow.type";

import { getTaskWidth } from "./getTaskWidth";

export type Type_Props_ReplaceTasks = {
    tasksLinks: Type_flow;
    fixedSize?: boolean;
};

export const replaceTasks = ({
    tasksLinks,
    fixedSize,
}: Type_Props_ReplaceTasks): Type_flow => {
    const inDegrees: Map<number, number> = new Map<number, number>();
    const graph: Map<number, number[]> = new Map<number, number[]>();
    const graphInv: Map<number, number[]> = new Map<number, number[]>();
    const sortedTasks: Type_flowTask[] = [];

    for (const task of tasksLinks.tasks) {
        inDegrees.set(task.id, 0);
        graph.set(task.id, []);
        graphInv.set(task.id, []);
    }

    for (const link of tasksLinks.links) {
        // not taskArea
        if (link.areaFrom || link.areaTo) continue;

        // not externe in sequence
        if (!graph.has(link.taskTo)) continue;
        if (!graph.has(link.taskFrom)) continue;

        inDegrees.set(link.taskTo, (inDegrees.get(link.taskTo) ?? 0) + 1);
        graph.get(link.taskFrom)?.push(link.taskTo);
        graphInv.get(link.taskTo)?.push(link.taskFrom);
    }

    const sources: number[] = Array.from(inDegrees.keys()).filter(
        (key) => inDegrees.get(key) === 0,
    );

    while (sources.length > 0) {
        const id: number = sources.shift() as number;
        const task: Type_flowTask | undefined = tasksLinks.tasks.find(
            (task) => task.id === id,
        );

        if (!task) {
            throw new Error("Graph is not valid");
        }

        sortedTasks.push(task);

        if (graph.has(task.id)) {
            // @ts-expect-error Graph is always good
            for (const link of graph.get(task.id)) {
                // @ts-expect-error inDegrees is always good
                inDegrees.set(link, inDegrees.get(link) - 1);

                if (inDegrees.get(link) === 0) {
                    sources.push(link);
                }
            }
        }
    }

    // set ranks
    const ranks: Map<number, number> = new Map<number, number>();
    for (const task of sortedTasks) {
        const predecessorRanks: number[] | undefined = graphInv
            .get(task.id)
            ?.map((id) => ranks.get(id) ?? 0);
        const maxPredecessorRank: number = predecessorRanks?.length
            ? Math.max(...predecessorRanks)
            : 0;

        ranks.set(task.id, maxPredecessorRank + 1);
    }

    // heads tasks
    const heads: Type_flowTask[] = sortedTasks.filter(
        (task) => graphInv.get(task.id)?.length === 0,
    );

    const groups: Map<number, number> = new Map<number, number>();
    const groupsTasks: Map<number, Type_flowTask[]> = new Map<
        number,
        Type_flowTask[]
    >();

    // init groups ans groupsTasks for heard tasks
    for (const task of heads) {
        const idGroup: number = task.id;
        groups.set(task.id, idGroup);
        groupsTasks.set(idGroup, []);
    }

    // create groups ans groupsTasks
    for (const task of sortedTasks) {
        if (!groups.has(task.id)) {
            throw new Error("Graph is not valid for group");
        }
        const idGroup: number = groups.get(task.id) as number;
        groupsTasks.get(idGroup)?.push(task);
        graph.get(task.id)?.forEach((id) => groups.set(id, idGroup));
    }

    // task position by group
    const positions: Map<number, Map<number, number>> = new Map<
        number,
        Map<number, number>
    >();

    // set X position
    const offsetX: number = sizeFlowGridX;
    for (const task of sortedTasks) {
        const group: number = groups.get(task.id) as number;
        const positionMap: Map<number, number> =
            positions.get(group) ?? new Map<number, number>();

        const width: number =
            Math.ceil(getTaskWidth(task, fixedSize) / sizeFlowGridX) *
            sizeFlowGridX;
        if (graphInv.get(task.id)?.length === 0) {
            positionMap.set(task.id, width + sizeFlowGridX);
            task.pt.x = offsetX;
        } else {
            const predecessorPositions: number[] | undefined = graphInv
                .get(task.id)
                ?.map((id) => positionMap.get(id) ?? 0);
            const maxPredecessorPosition: number = predecessorPositions?.length
                ? Math.max(...predecessorPositions)
                : 0;

            const x: number = maxPredecessorPosition + sizeFlowGridX;
            positionMap.set(task.id, x + width + sizeFlowGridX);
            task.pt.x = x + offsetX;
        }

        positions.set(group, positionMap);
    }

    // set Y position by group
    let offsetY: number = sizeFlowGridY;
    const positionsY: Map<number, number> = new Map<number, number>();

    groupsTasks.forEach((group: Type_flowTask[]): void => {
        let currentRank: number = 0;
        let currentY: number = 0;
        let maxY: number = 0;
        let maxPosY: number = 0;
        for (const task of group) {
            const rank: number = ranks.get(task.id) as number;
            if (currentRank !== rank) {
                maxY = Math.max(currentY, maxY);
                currentY = 0;
                currentRank = rank;
            }

            const predecessorPositions: number[] | undefined = graphInv
                .get(task.id)
                ?.map((id) => positionsY.get(id) ?? 0);
            const maxPredecessorPosition: number = predecessorPositions?.length
                ? Math.max(...predecessorPositions)
                : 0;

            task.pt.y = Math.max(currentY + offsetY, maxPredecessorPosition);
            positionsY.set(task.id, task.pt.y);

            currentY = task.pt.y - offsetY + sizeFlowGridY * 2;

            maxPosY = Math.max(task.pt.y, maxPosY);
        }
        offsetY = maxPosY + sizeFlowGridY * 2;
    });

    return tasksLinks;
};
