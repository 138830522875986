import { Skeleton } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { useSelectListPunchlistItemCauses } from "src/api/tms-commons/punchlistItemCauses";
import {
    formatSelectListToSelectOptionItem,
    Select,
    Type_Props_Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

type Type_Props_SelectCause = Omit<Type_Props_Select, "options"> & {
    enabled?: boolean; // Load data even if the select is not open.
};

export const SelectPunchlistItemCauses = ({
    enabled = false,
    ...props
}: Type_Props_SelectCause) => {
    ///////////////////////////////
    /// States                  ///
    ///////////////////////////////
    const [open, setOpen] = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(enabled);

    ///////////////////////////////
    /// Queries                 ///
    ///////////////////////////////

    const { data, isFetching } = useSelectListPunchlistItemCauses(loadData);

    return (
        <>
            {isFetching && <Skeleton variant="rounded" height={32} />}
            {!isFetching && (
                <Select
                    {...props}
                    options={formatSelectListToSelectOptionItem(data || [])}
                    SelectProps={{
                        open: open,
                        onOpen: () => {
                            setOpen(true);
                            setLoadData(true);
                        },
                        onClose: () => {
                            setOpen(false);
                        },
                    }}
                />
            )}
        </>
    );
};
