// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

interface Styled_BadgeProps extends ChipProps {
    ownerState?: {
        chipBackgroundColor?: string;
        chipTextColor?: string;
        highlighted?: boolean;
    };
}

export const Styled_Badge = styled(Chip)<Styled_BadgeProps>(
    ({ theme, ownerState, clickable = true }) => ({
        "&.MuiChip-root": {
            cursor: clickable ? "pointer" : "not-allowed",
            padding: `${theme.spacing(1)}`,
            borderRadius: `${theme.spacing(1)}`,
            gap: "2px",
            border: `${theme.spacing(0.3)} solid black`,
            color: COLORS.dark,
            backgroundColor: ownerState?.chipBackgroundColor,
            "& > .MuiChip-label": {
                ...theme.typography.body2Medium,
                color: COLORS.dark, //TODO remove
            },
            "& > .MuiChip-icon": {
                color: COLORS.dark, //TODO remove
                width: "15px",
                height: "15px",
            },
        },
        "&.Mui-disabled": {
            pointerEvents: "visible",
            backgroundColor: `${COLORS.moon100} !important`,
            gap: "2px",
            border: `${theme.spacing(0.3)} solid ${COLORS.moon100}`,
            opacity: 1,

            "& > .MuiChip-label": {
                color: COLORS.moon700, //TODO remove
                ...theme.typography.body2,
            },
            "& > .MuiChip-icon": {
                color: COLORS.moon700, //TODO remove
            },
        },
    }),
);
