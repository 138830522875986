import { useState } from "react";

import {
    mutationDeleteUnitType,
    mutationStatusUnitType,
    useIndexUnitTypes,
} from "src/api/tms-commons/unitTypes";
import {
    Type_index_unitType,
    Type_put_unitType,
} from "src/api/tms-commons/unitTypes/types";

import { Page_UnitTypesComponent } from "./Page_UnitTypes.component";

export type Type_modalDeleteUnitType = {
    isOpen: boolean;
    props?: Type_index_unitType;
};

export const Page_UnitTypesContainer = () => {
    const [modalDeleteUnitType, setModalDeleteUnitType] =
        useState<Type_modalDeleteUnitType>({
            isOpen: false,
        });

    const { isFetching, data: unitTypes } = useIndexUnitTypes() || {};

    const { mutate: mutateDeleteUnitType } = mutationDeleteUnitType() || {};

    const removeUnitType = (id: number) => {
        mutateDeleteUnitType(id);
    };

    const { mutate: mutationUnitTypeStatus } = mutationStatusUnitType() || {};

    const changeStatus = (unitType: Type_index_unitType) => {
        mutationUnitTypeStatus({
            id: unitType.id,
            enabled: !unitType.enabled,
        } as Type_put_unitType);
    };

    return (
        <Page_UnitTypesComponent
            isFetching={isFetching}
            unitTypes={unitTypes || []}
            removeUnitType={removeUnitType}
            changeStatus={changeStatus}
            modalDeleteUnitType={modalDeleteUnitType}
            setModalDeleteUnitType={setModalDeleteUnitType}
        />
    );
};
