import { Grid, Typography } from "@mui/material";
import React, { MouseEventHandler } from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_drawerTab, Type_Props_Drawer_Header } from "./Header.container";
import {
    Styled_Header,
    Styled_HeaderTypography,
    Styled_Tab,
    Styled_TabList,
} from "./Header.style";

export const HeaderComponent = ({
    title,
    onClose,
    description,
    currentStep,
    maxStep,
    drawerTabs = [],
    handleChange,
    dataTestIdTitle,
}: Type_Props_Drawer_Header) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.Drawer.Header",
    );
    const hasSteps: boolean = !!currentStep && !!maxStep && maxStep > 1;

    return (
        <Styled_Header
            ownerState={{
                withTabs: !!drawerTabs.length,
            }}
            item
            container
        >
            <Grid
                item
                container
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
            >
                {hasSteps ? (
                    <Typography
                        variant="subtitle2"
                        data-testid={`DrawerContent-Title-steps-${currentStep}`}
                    >
                        {fmt("Steps", {
                            values: {
                                currentStep: currentStep,
                                maxStep: maxStep,
                            },
                        })}
                    </Typography>
                ) : (
                    <Styled_HeaderTypography
                        variant="h2"
                        data-testid={dataTestIdTitle}
                    >
                        {title}
                    </Styled_HeaderTypography>
                )}
                <IconButton
                    size={"small"}
                    aria-label="close"
                    onClick={onClose as MouseEventHandler}
                    data-testid="drawer-iconButton-close"
                >
                    <Icon variant="solid" icon="xmark" />
                </IconButton>
            </Grid>
            {hasSteps && (
                <Grid item marginTop={2}>
                    <Typography variant="h2" data-testid={dataTestIdTitle}>
                        {title}
                    </Typography>
                </Grid>
            )}
            {!!description && (
                <Grid item marginTop={2}>
                    <Typography>{description}</Typography>
                </Grid>
            )}
            {!!drawerTabs.length && (
                <Styled_TabList onChange={handleChange} data-testid="TabList">
                    {drawerTabs.map(
                        (drawerTab: Type_drawerTab, key: number) => {
                            return (
                                <Styled_Tab
                                    data-testid={`Tab-${drawerTab.value}`}
                                    label={drawerTab.label}
                                    value={drawerTab.value}
                                    key={key}
                                />
                            );
                        },
                    )}
                </Styled_TabList>
            )}
        </Styled_Header>
    );
};
