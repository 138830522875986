import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { ProjectUsersPath, Url } from "src/api/paths";
import {
    Type_prj_post_projectUser,
    Type_prj_put_projectUser,
} from "src/api/tms-projects/projectUsers/types";

export const indexProjectUsers = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ProjectUsersPath.PROJECT_USERS}`,
        requestConfig,
    );
};

export const showProjectUser = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ProjectUsersPath.PROJECT_USERS}/${id}`,
        requestConfig,
    );
};

export const createProjectUser = (
    data: Type_prj_post_projectUser,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.PROJECTS}${ProjectUsersPath.PROJECT_USERS}`,
        requestConfig,
    );
};

export const updateProjectUser = (
    id: number,
    data: Type_prj_put_projectUser,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${ProjectUsersPath.PROJECT_USERS}/${id}`,
        requestConfig,
    );
};

export const deleteProjectUser = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.PROJECTS}${ProjectUsersPath.PROJECT_USERS}/${id}`,
        requestConfig,
    );
};

export const selectListProjectUsers = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ProjectUsersPath.PROJECT_USERS_SELECT_LIST}`,
        requestConfig,
    );
};
