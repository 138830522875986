type Message = string | NestedDictionary;

export type NestedDictionary = {
    [x: string]: Message;
};
type FlattenedDictionary = {
    [x: string]: string;
};

// Fonction transformant le json en liste de string "label": "value"
// exemple :
// { "Home": { "title": "Titre", "subtitle": "Sous-titre" }}
// devient
// { "Home.title": "Titre", "Home.subtitle": "Sous-titre" }
export const flattenMessages = (
    nestedMessages: NestedDictionary,
    prefix = "",
): FlattenedDictionary =>
    Object.keys(nestedMessages).reduce(
        (messages: FlattenedDictionary, key = "") => {
            const value: Message = nestedMessages[key];
            const prefixedKey: string =
                prefix !== "" ? `${prefix}.${key}` : key;

            if (typeof value === "string") {
                messages[prefixedKey] = value;
            } else {
                Object.assign(messages, flattenMessages(value, prefixedKey));
            }

            return messages;
        },
        {},
    );
