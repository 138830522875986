import Konva from "konva";
import React, { RefObject, useEffect, useRef } from "react";
import { Group, Label, Tag, Text } from "react-konva";

import { invertColor } from "src/utils/colors";

import { Type_Props_TextChip } from "./types";

export const TextChip = ({
    x,
    y,
    height,
    width,
    name,
    code,
    color,
    ratioMaxWidth = 3,
    marginLabel = 2,
    paddingLabel = 4,
    cornerRadius = 2,
}: Type_Props_TextChip) => {
    const refLabel: RefObject<Konva.Label> = useRef<Konva.Label>(null);
    const refCode: RefObject<Konva.Text> = useRef<Konva.Text>(null);
    const refName: RefObject<Konva.Text> = useRef<Konva.Text>(null);

    useEffect(() => {
        if (refLabel.current && refCode.current && refName.current) {
            const { width: codeWidth, height: codHeight } =
                refLabel.current.getClientRect();

            let labelWidth: number = codeWidth;
            if (ratioMaxWidth && codeWidth > width / ratioMaxWidth) {
                labelWidth = width / ratioMaxWidth;
                refCode.current.width(labelWidth);
            }

            refLabel.current.y(y + (height - codHeight) / 2);
            refName.current.x(x + labelWidth + marginLabel);
            refName.current.width(width - labelWidth - marginLabel);
        }
    }, []);

    return (
        <Group>
            <Label ref={refLabel} x={x} y={y} height={height}>
                <Tag fill={color} cornerRadius={cornerRadius} />
                <Text
                    ref={refCode}
                    padding={paddingLabel}
                    verticalAlign={"middle"}
                    text={code}
                    wrap={"none"}
                    ellipsis={true}
                    fill={invertColor(color)}
                />
            </Label>
            <Text
                ref={refName}
                x={x}
                y={y}
                height={height}
                width={width}
                padding={paddingLabel}
                verticalAlign={"middle"}
                wrap={"none"}
                text={name}
                ellipsis={true}
            />
        </Group>
    );
};
