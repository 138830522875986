import { GridColDef } from "@mui/x-data-grid-premium";

import { useCoreIntl } from "src/hooks/useCoreIntl";

export const MatrixSequenceAreasColumns = (
    depth: number,
    enableOrderEdition: boolean,
): GridColDef[] => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Sequences.Table",
    );

    const columnArray: GridColDef[] = [];
    for (let i = 0; i < depth; i++) {
        const index = i + 1;
        columnArray.push(
            {
                field: `area_name_${i}`,
                headerName: `${fmt("AreaName")} ${index}`,
                flex: 1,
                resizable: true,
                sortable: true,
                editable: false,
                filterable: true,
            },
            {
                field: `area_type_name_${i}`,
                headerName: `${fmt("AreaType")} ${index}`,
                flex: 1,
                resizable: true,
                sortable: true,
                editable: false,
                filterable: true,
            },
        );
    }

    columnArray.push(
        {
            field: "selected",
            headerName: fmt("Selection"),
            resizable: true,
            flex: 1,
            type: "boolean",
            editable: true,
            filterable: false,
            sortable: false,
            align: "center",
        },
        {
            field: "order",
            headerName: fmt("CustomOrder"),
            filterable: false,
            flex: 1,
            resizable: true,
            editable: enableOrderEdition,
            align: "center",
            headerAlign: "center",
        },
    );

    return columnArray;
};
