import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import { KonvaEventObject } from "konva/lib/Node";
import React from "react";

import { DayKey } from "src/components/Components_Teamoty/Planning/DayKey/DayKey";
import { heightPlanningDate } from "src/components/Components_Teamoty/Planning/Planning.const";

import { PlanningDate } from "./PlanningDate";
import { Type_Props_PlanningMonthsDays } from "./PlanningDate.type";
import { PlanningDateLine } from "./PlanningDateLine";

export const getDates = (
    dates: Type_Props_PlanningMonthsDays,
    widthDate: number,
    handleContextMenuDates: {
        (
            { evt }: KonvaEventObject<PointerEvent>,
            date: planningDateClass,
        ): void;
        (event: KonvaEventObject<PointerEvent>, date?: planningDateClass): void;
    },
): [
    Array<React.JSX.Element>,
    Array<React.JSX.Element>,
    Array<React.JSX.Element>,
] => {
    const elmDates: Array<React.JSX.Element> = [];
    const elmDatesLine: Array<React.JSX.Element> = [];
    const elmDayKeys: Array<React.JSX.Element> = [];

    let lastDate: planningDateClass = dates.days[0];

    dates.days.map((date: planningDateClass, index: number): void => {
        elmDates.push(
            <PlanningDate
                key={"d" + index}
                height={heightPlanningDate}
                width={widthDate}
                date={date}
                handleContextMenuDates={handleContextMenuDates}
            />,
        );

        elmDayKeys.push(
            <DayKey
                dates={dates}
                key={"dayKey" + index}
                date={date}
                widthDate={widthDate}
            />,
        );

        if (
            lastDate &&
            (lastDate.month != date.month || lastDate.year != date.year)
        ) {
            elmDatesLine.push(
                <PlanningDateLine
                    key={"dl" + index}
                    height={heightPlanningDate}
                    width={widthDate}
                    date={date}
                    handleContextMenuDates={handleContextMenuDates}
                />,
            );
            lastDate = date;
        }
    });

    return [elmDates, elmDatesLine, elmDayKeys];
};
