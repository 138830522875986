import Konva from "konva";
import React from "react";
import { Group, Path, Rect, Text } from "react-konva";

import {
    fontSizeButton,
    sizePlanningButtonPictogram,
    sizePlanningScroll,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { usePlanningContext } from "src/contexts/planning";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_Props_PlanningZoomButton } from "./PlanningZoom.type";

export const PlanningZoomButton = ({
    x,
    y,
    pictogram,
    text,
    width,
    height,
    selected,
    onChange,
}: Type_Props_PlanningZoomButton) => {
    const theme = usePlanningTheme();
    const { defaultCursor } = usePlanningContext();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Planning");

    return (
        <Group
            x={x}
            y={y}
            onClick={() => {
                onChange();
            }}
            onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) => {
                changeCursor(e, "pointer");
            }}
            onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) => {
                changeCursor(e, defaultCursor);
            }}
        >
            <Rect
                width={width ? width : sizePlanningScroll}
                height={height ? height : sizePlanningScroll}
                fill={
                    selected ? theme.colorPlanningSelected : theme.transparent
                }
            />

            {pictogram && (
                <Path
                    scaleX={sizePlanningButtonPictogram / 512}
                    scaleY={sizePlanningButtonPictogram / 512}
                    x={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                    y={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                    fill={
                        selected
                            ? theme.colorPlanningButtonTextSelected
                            : theme.colorPlanningButtonText
                    }
                    data={pictogram}
                />
            )}

            {!pictogram && text && (
                <Text
                    width={width ? width : sizePlanningScroll}
                    height={height ? height : sizePlanningScroll}
                    align="center"
                    verticalAlign="middle"
                    text={fmt(text)}
                    fontSize={fontSizeButton}
                    fill={
                        selected
                            ? theme.colorPlanningButtonTextSelected
                            : theme.colorPlanningButtonText
                    }
                />
            )}
        </Group>
    );
};
