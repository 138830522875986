import { Type_index_area } from "src/api/tms-projects/areas/types";

type Type_AreasToDisplay = {
    areasIdsInPath: number[];
    areasSelectedIds: string[];
};

export const getFlattenAreas = ({
    items,
    flattenedObjects = [],
}: {
    items: Type_index_area[];
    flattenedObjects?: Type_index_area[];
}): Type_index_area[] => {
    items.forEach((item) => {
        const copyItem = { ...item };
        copyItem.children = [];
        flattenedObjects.push(copyItem);

        getFlattenAreas({ items: item.children, flattenedObjects });
    });

    return flattenedObjects;
};

type Type_Props_getFlattenAreasIds = {
    items: Type_index_area[];
    drawing_id: number;
    currentPath?: number[];
    areasIdsInPath?: number[];
    areasSelectedIds?: string[];
};

export const getFlattenAreasIds = ({
    items,
    drawing_id,
    currentPath = [],
    areasIdsInPath = [],
    areasSelectedIds = [],
}: Type_Props_getFlattenAreasIds): Type_AreasToDisplay => {
    items.forEach((item) => {
        if (item.parentId === null) {
            // on remet à zero le currentPath sur les élement de premier niveau
            currentPath = [];
        } else if (currentPath.indexOf(item.parentId) === -1) {
            currentPath.push(item.parentId);
        }

        if (item?.drawing?.id === drawing_id) {
            // On a un au moins une zone de sélectionnée dans le chemin courant
            currentPath.push(item.id);
            areasSelectedIds.push(String(item.id));

            // on sauvegarde donc les ids du chemin courant dans l'accumulateur
            areasIdsInPath = areasIdsInPath.concat(currentPath);
        }

        if (item.children && item.children.length > 0) {
            // il y a des enfants, on poursuit la recursion
            const {
                areasIdsInPath: areasIdsInPathReturn,
                areasSelectedIds: areasSelectedIdsReturn,
            } = getFlattenAreasIds({
                items: item.children,
                drawing_id,
                currentPath,
                areasIdsInPath,
                areasSelectedIds,
            });

            areasIdsInPath = [...areasIdsInPath, ...areasIdsInPathReturn];
            areasSelectedIds = [...areasSelectedIds, ...areasSelectedIdsReturn];
        }
    });

    // on supprime les id en doublon
    areasIdsInPath = Array.from(new Set(areasIdsInPath));
    areasSelectedIds = Array.from(new Set(areasSelectedIds));

    return {
        areasIdsInPath,
        areasSelectedIds,
    };
};

// fonction recursive pour sélectionner les zones à afficher dans la sidebar à partir d'une liste d'id
export const getAreasToDisplay = (
    items: Type_index_area[],
    filteredIds: number[],
): Type_index_area[] => {
    const itemsToReturn: Type_index_area[] = [];

    items.forEach((item) => {
        if (filteredIds.includes(item.id)) {
            // L'élément courant est inclus dans la liste

            if (item.children && item.children.length > 0) {
                // on filtre ses enfants
                item.children = getAreasToDisplay(item.children, filteredIds);
            }

            // On l'ajoute au résultat
            itemsToReturn.push(item);
        }
    });

    return itemsToReturn;
};

export const getAreasFilteredByDrawingId = (
    items: Type_index_area[],
    drawingId: number,
    allowAreaWithDrawingIdNull: boolean = false,
) => {
    const itemsToReturn: Type_index_area[] = [];

    items.forEach((item) => {
        if (
            item.drawing?.id === drawingId ||
            (allowAreaWithDrawingIdNull && item.drawing === null)
        ) {
            // L'élément courant est inclus dans la liste

            if (item.children && item.children.length > 0) {
                // on filtre ses enfants
                item.children = getAreasFilteredByDrawingId(
                    item.children,
                    drawingId,
                    allowAreaWithDrawingIdNull,
                );
            }

            // On l'ajoute au résultat
            itemsToReturn.push(item);
        }
    });

    return itemsToReturn;
};
