import { useNavigate } from "react-router-dom";

import { Divider, SimpleInputCancel } from "src/components";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export const Page_ChangePasswordConfirmComponent = () => {
    const navigate = useNavigate();
    const { formatMessageWithPartialKey } = useCoreIntl(
        "ChangePasswordConfirm",
    );
    const language = getLocalStorageItem("language");

    const goHome = () => {
        navigate(`/${language}/login`, { replace: true });
    };

    return (
        <div className="bg-gray-200 flex justify-center items-center w-full h-screen">
            <div className="block-login flex justify-center w-full">
                <div className="p-8 rounded-md shadow-md">
                    <h1 className="w-full text-center">
                        {formatMessageWithPartialKey("Title")}
                    </h1>
                    <Divider className="my-6" />
                    <p>{formatMessageWithPartialKey("Message")}</p>
                    <p>{formatMessageWithPartialKey("Instruction_0")}</p>
                    <Divider className="my-6" />
                    <SimpleInputCancel
                        label={formatMessageWithPartialKey("CtaHome")}
                        onClick={goHome}
                    />
                </div>
            </div>
        </div>
    );
};
