import { DialogProps } from "@mui/material";
import { ReactNode } from "react";

import { ConfirmDeleteModalComponent } from "src/components/Components_Common/ConfirmDeleteModal/ConfirmDeleteModalComponent";

export type headerModalType = {
    title?: string;
    onClose?: any;
};

type actionModalType = {
    onClick: () => void;
    content: ReactNode;
    isLoading?: boolean;
    disabled?: boolean;
};

export type actionsModalType = {
    onClose?: actionModalType;
    onSubmit?: actionModalType;
};

export type Type_formModal = {
    validationString?: string;
};

export type ModalContainerType = {
    header?: headerModalType;
    actions?: actionsModalType;
    form?: Type_formModal;
};
/**
 * @deprecated use ModalDelete instead
 * @param children
 * @param restProps
 * @constructor
 */
export const ConfirmDeleteModalContainer = ({
    children,
    ...restProps
}: ModalContainerType & DialogProps) => {
    return (
        <ConfirmDeleteModalComponent {...restProps}>
            {children}
        </ConfirmDeleteModalComponent>
    );
};
