import { formatDateForBack, formatDateForFront } from "src/utils/date";

import {
    Type_index_keyDate,
    Type_post_keyDate,
    Type_put_keyDate,
    Type_sch_index_keyDate,
    Type_sch_post_keyDate,
    Type_sch_put_keyDate,
    Type_sch_show_keyDate,
    Type_show_keyDate,
} from "./types";

export const formatterShowKeyDate = (
    keyDateFromBack: Type_sch_show_keyDate,
): Type_show_keyDate => {
    return {
        id: keyDateFromBack.id,
        names: keyDateFromBack.names,
        color: keyDateFromBack.color,
        code: keyDateFromBack.code,
        date: formatDateForFront(keyDateFromBack.date)!,
        SCurve: keyDateFromBack.SCurve,
        bufferAlert: keyDateFromBack.bufferAlert,
        bufferValue: keyDateFromBack.bufferValue,
        timeModelId: keyDateFromBack.timeModel_id,
        taskNumbers: keyDateFromBack?.tasks,
        areas: keyDateFromBack?.areas,
        enabled: keyDateFromBack.enabled,
    };
};

export const formatterPostKeyDate = (
    keyDateFromFront: Type_post_keyDate,
): Type_sch_post_keyDate => {
    return {
        names: keyDateFromFront.names,
        color: keyDateFromFront.color,
        code: keyDateFromFront.code,
        SCurve: keyDateFromFront.SCurve,
        bufferAlert: keyDateFromFront.bufferAlert,
        bufferValue: keyDateFromFront.bufferValue,
        areas: keyDateFromFront?.areas?.map(({ id }) => id) ?? undefined,
        timeModel_id: keyDateFromFront.timeModelId as number,
        date: formatDateForBack(keyDateFromFront.date)!,
        taskNumbers:
            keyDateFromFront.taskNumbers?.map(({ id }) => id) ?? undefined,
    };
};

export const formatterPutKeyDate = (
    keyDateFromFront: Type_put_keyDate,
): Type_sch_put_keyDate => {
    return {
        names: keyDateFromFront.names,
        color: keyDateFromFront.color,
        code: keyDateFromFront.code,
        SCurve: keyDateFromFront.SCurve,
        bufferAlert: keyDateFromFront.bufferAlert,
        bufferValue: keyDateFromFront.bufferValue,
        areas: keyDateFromFront?.areas?.map(({ id }) => id) ?? undefined,
        timeModel_id: keyDateFromFront.timeModelId as number,
        date: formatDateForBack(keyDateFromFront.date)!,
        taskNumbers:
            keyDateFromFront.taskNumbers?.map(({ id }) => id) ?? undefined,
    };
};

export const formatterIndexKeyDate = (
    keyDateFromBack: Type_sch_index_keyDate,
): Type_index_keyDate => {
    return {
        id: keyDateFromBack.id,
        name: keyDateFromBack.name,
        color: keyDateFromBack.color,
        code: keyDateFromBack.code,
        date: formatDateForFront(keyDateFromBack.date)!,
        SCurve: keyDateFromBack.SCurve,
        bufferAlert: keyDateFromBack.bufferAlert,
        bufferValue: keyDateFromBack.bufferValue,
        timeModelId: keyDateFromBack.timeModel_id,
        enabled: keyDateFromBack.enabled,
    };
};

export const formatterIndexKeyDates = (
    keyDatesFromBack: Type_sch_index_keyDate[],
): Type_index_keyDate[] => {
    return keyDatesFromBack.map((keyDate: Type_sch_index_keyDate) =>
        formatterIndexKeyDate(keyDate),
    );
};
