import { useMutation, useQuery } from "react-query";

import { PeopleKeys } from "src/api/tms-scheduling/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { formatterSelectPeople, formatterUpdatePerson } from "./formatter";
import {
    createPeople,
    selectPeople,
    showPeople,
    updatePeople,
} from "./services";
import { Type_sch_post_people, Type_sch_put_people } from "./types";

///////////////////////////////////////
///            Queries              ///
///////////////////////////////////////

export const useSelectPeople = () => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useQuery({
        queryKey: [PeopleKeys.SELECT, { ...requestConfig }],
        queryFn: () => selectPeople({ ...requestConfig }),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterSelectPeople(data.data.data);
        },
        onError: (err) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const useShowPeople = (id?: number) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    return useQuery(
        [PeopleKeys.SHOW, id, requestConfig],
        () => showPeople(id || 0, requestConfig),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            select: (data) => {
                return data.data.data;
            },
            onError: (err) => {
                console.error(fmtErrors("GenericError", {}), err);
                addWarning({ description: fmtErrors("GenericError", {}) });
                return err;
            },
        },
    );
};

///////////////////////////////////////
///           Mutations             ///
///////////////////////////////////////

export const mutationCreatePeople = () => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_post_people) =>
            createPeople(data, { ...requestConfig }),
        onSuccess: (data: any) => {
            return data.data.data;
        },
        onError: (err: any): void => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const mutationUpdatePeople = (callback: any) => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: ({
            id,
            data,
        }: {
            id: number;
            data: Type_sch_put_people;
        }) => {
            return updatePeople(id, data, { ...requestConfig });
        },
        onSuccess: (data: any) => {
            return callback(formatterUpdatePerson(data.data.data));
        },
        onError: (err: any) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};
