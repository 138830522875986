import { ReactElement } from "react";

import {
    Enum_TreeItem_ItemType,
    Type_sequenceFolderTree_item,
} from "src/api/tms-scheduling/sequenceFolders/types";

import { SequencesTreeItemLabelFolder } from "./SequenceTreeItemLabels/SequencesTreeItemLabelFolder";
import { SequencesTreeItemLabelSequence } from "./SequenceTreeItemLabels/SequencesTreeItemLabelSequence";
import { SequencesTreeItemLabelTask } from "./SequenceTreeItemLabels/SequencesTreeItemLabelTask";

type Type_Props_SequencesTreeItemLabel = {
    children: React.ReactNode;
    item: Type_sequenceFolderTree_item;
    expandable?: boolean | number;
};

export const SequencesTreeItemLabel = ({
    item,
    children,
    ...other
}: Type_Props_SequencesTreeItemLabel): ReactElement => {
    if (item.itemType === Enum_TreeItem_ItemType.folder) {
        return (
            <SequencesTreeItemLabelFolder item={item} {...other}>
                {children}
            </SequencesTreeItemLabelFolder>
        );
    } else if (item.itemType === Enum_TreeItem_ItemType.sequence) {
        return <SequencesTreeItemLabelSequence item={item} {...other} />;
    } else {
        return <SequencesTreeItemLabelTask item={item} {...other} />;
    }
};
