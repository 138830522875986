import { AutocompleteRenderGetTagProps, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Type_Filter } from "src/api/filters";
import { formatterTradesFromDataPlanningSubTrades } from "src/api/tms-projects/trades/formatters";
import { Type_selectList_trade } from "src/api/tms-projects/trades/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { usePlanningContext } from "src/contexts/planning";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_PlanningFilterTrades = {
    name: string;
    label?: string;
    enabled?: boolean | null;
    renderInputProps?: any;
};

export const PlanningFilterTrades = ({
    name,
    label,
    enabled,
    ...restProps
}: Type_Props_PlanningFilterTrades) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerFilters",
    );

    const { getValues, setValue, watch } = useFormContext(); // retrieve those props
    const { dataPlanning } = usePlanningContext();

    const icon: React.ReactElement = <Icon variant="light" icon="square" />;
    const checkedIcon: React.ReactElement = (
        <Icon variant="solid" icon="square-check" />
    );

    const filters: Type_Filter = {};
    typeof enabled == "boolean" && (filters.enabled = enabled);

    const [tradeList, setTradeList] = useState<Type_selectList_trade[]>([]);

    useEffect(() => {
        if (dataPlanning) {
            const trades = formatterTradesFromDataPlanningSubTrades(
                dataPlanning.subTrades,
            );
            setTradeList(trades);
        }
    }, [dataPlanning?.subTrades]);

    const selectedTrades = watch(name) as Type_selectList_trade[];

    // Retreive the unselected trades
    useEffect(() => {
        const selectedTradesIds =
            selectedTrades?.map((company) => company.id) || []; // Get selected trades IDs

        if (selectedTradesIds.length === 0) {
            setValue("notSelectedTrades", []);
        } else {
            const notSelectedTrades = tradeList
                .filter((trade) => !selectedTradesIds.includes(trade.id))
                .map((trade) => trade.id);

            setValue("notSelectedTrades", notSelectedTrades);
        }
    }, [tradeList, selectedTrades]); // Trigger effect on tradesList and selectedTrades

    return (
        <Autocomplete
            name={name}
            label={label}
            multiple
            options={tradeList}
            disableCloseOnSelect={true}
            renderOption={(props, option) => (
                <li
                    {...props}
                    key={option.id}
                    data-testid={`autocomplete-trades-${name}-input-${option.name}`}
                >
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                            !!getValues(name)?.find(
                                (trade: any): boolean =>
                                    trade?.id === option.id,
                            )
                        }
                    />
                    <TMC_Chip
                        label={`${option.code.toUpperCase()} - ${option.name}`}
                        data-testid={`AutocompleteTrades-${option.name.replaceAll(
                            " ",
                            "_",
                        )}`}
                        backgroundColor={option.color}
                    />
                </li>
            )}
            renderTags={(
                tagValue: any[],
                getTagProps: AutocompleteRenderGetTagProps,
            ) =>
                tagValue.map((option, index: number) => {
                    const { key, ...tagProps } = getTagProps({
                        index,
                    });
                    return (
                        <TMC_Chip
                            key={key}
                            label={`${option.code.toUpperCase()} - ${option.name}`}
                            data-testid={`AutocompleteTrades-${option.name.replaceAll(
                                " ",
                                "_",
                            )}`}
                            backgroundColor={option.color}
                            {...tagProps}
                        />
                    );
                })
            }
            renderInputProps={{
                variant: "outlined",
                placeholder: fmt("TradesPlaceholder"),
            }}
            {...restProps}
        />
    );
};
