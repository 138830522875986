import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useSelectListResourceTypes } from "src/api/tms-projects/resourceTypes";
import { Type_selectList } from "src/api/types";
import {
    formatSelectListToSelectOptionItem,
    Select,
    Type_Props_Select,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

type Type_Props_SelectResourceType = Omit<Type_Props_Select, "options">;

export const SelectResourceType = (props: Type_Props_SelectResourceType) => {
    const { getValues } = useFormContext(); // retrieve those props

    // On first render, show current time model value
    const initialValue: Type_SelectOptionItem[] = useMemo(() => {
        const nameParts = props.name.split(".");
        if (nameParts.length === 2) {
            const labelName = nameParts[0];
            return [
                {
                    value: getValues(props.name),
                    label: getValues(`${labelName}.name`),
                },
            ];
        }
        return [];
    }, []);

    ///////////////////////////////
    /// States                  ///
    ///////////////////////////////
    const [open, setOpen] = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);

    ///////////////////////////////
    /// Queries                 ///
    ///////////////////////////////

    const { data } = useSelectListResourceTypes(loadData);

    return (
        <Select
            {...props}
            options={
                data
                    ? formatSelectListToSelectOptionItem(
                          data as Type_selectList[],
                      )
                    : initialValue
            }
            {...props}
            SelectProps={{
                open: open,
                onOpen: () => {
                    setOpen(true);
                    setLoadData(true);
                },
                onClose: () => {
                    setOpen(false);
                },
            }}
            hasNoneValue={true}
        />
    );
};
