import React, { useCallback, useEffect, useState } from "react";
import { generatePath, Link, useLocation, useParams } from "react-router-dom";

import {
    Styled_PagesTab,
    Styled_PagesTabs,
} from "src/components/Components_Teamoty/TopBar/stylesheet";
import { URL_BASE } from "src/router";

export type Type_TopBarPages = {
    key: string;
    path: string;
};

type Type_Props_PagesTabs = {
    pages: Type_TopBarPages[];
};
export const PagesTabs = ({ pages }: Type_Props_PagesTabs) => {
    const [value, setValue] = useState<string | false>(false);
    const location = useLocation();
    const urlParams = useParams();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        const pathSegment = location.pathname.split("/")[2];
        const page = pages.find((page) => page.path === pathSegment);
        if (page) {
            setValue(page.path);
        } else {
            setValue(false);
        }
    }, [pages, location.pathname]);

    const generateFullPath = useCallback(
        (path: string): string =>
            generatePath(`${URL_BASE}/${path}`, {
                ...urlParams,
            }),
        [urlParams],
    );

    return (
        <Styled_PagesTabs value={value} onChange={handleChange}>
            {pages.map((page: Type_TopBarPages, index: number) => (
                <Styled_PagesTab
                    data-testid={`TopBar-Pages-${page.key}`}
                    key={index}
                    label={page.key}
                    value={page.path}
                    to={generateFullPath(`/${page.path}`)}
                    component={Link}
                />
            ))}
        </Styled_PagesTabs>
    );
};
