import { ChipProps } from "@mui/material";

import { COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

import { Styled_Badge } from "./Badge.style";

type Type_Props_Badge = {
    label: string;
    backgroundColor?: string;
    icon?: any;
};

/**
 * @deprecated use new component TMC_Chip
 */
export const Badge = ({
    backgroundColor,
    label,
    icon,
    ...restProps
}: Type_Props_Badge & ChipProps) => {
    const ChipColor = backgroundColor || COLORS.white;

    return (
        <Styled_Badge
            label={label}
            icon={icon}
            ownerState={{
                chipBackgroundColor: ChipColor,
                chipTextColor: invertColor(ChipColor),
            }}
            {...restProps}
        />
    );
};
