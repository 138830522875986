import { useMutation } from "react-query";

import {
    formatterDownLoadPrint,
    getFilenameFromContentDisposition,
} from "src/api/tms-print/print/formatters";
import { downloadPrint } from "src/api/tms-print/print/services";
import { Type_post_print } from "src/api/tms-print/print/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { downloadBlobFile } from "src/utils/file";

export type Type_Props_mutationPrint_Mutate = {
    settings: Type_post_print;
    shouldDownloadDirectly: boolean;
};

export const mutationPrint = () => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationKey: ["print", requestConfig],
        mutationFn: ({
            settings,
            // Will be used in onSuccess variables
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            shouldDownloadDirectly,
        }: Type_Props_mutationPrint_Mutate) =>
            downloadPrint(formatterDownLoadPrint(settings), requestConfig),
        onSuccess: (data, variables): void => {
            if (!data?.success || !data?.data) {
                throw new Error("Wrong format data: mutationPrint");
            }

            const filename = getFilenameFromContentDisposition(
                data.headers["content-disposition"],
            );

            if (!filename) {
                throw new Error("Wrong format filename: mutationPrint");
            }
            // Optional download handling or returning URL
            if (variables.shouldDownloadDirectly) {
                downloadBlobFile(data.data, filename, "application/pdf");
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationPrint", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
