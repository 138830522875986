import { useCallback, useEffect, useState } from "react";

import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { usePlanningContext } from "src/contexts/planning";

enum Direction {
    Horizontal = "Horizontal",
    Vertical = "Vertical",
}

export type Type_Direction = "right" | "left" | "top" | "bottom";

export const useResizable = (name: string, _direction: Type_Direction) => {
    const [node, setNode] = useState<HTMLElement | null>(null);
    const [width, setWidth] = useState<string | null>(null);
    const [height, setHeight] = useState<string | null>(null);
    const { defaultCursor } = usePlanningContext();

    useEffect(() => {
        const savedWidth = localStorage.getItem(
            `resizableWidth_${name}_${_direction}`,
        );
        const savedHeight = localStorage.getItem(
            `resizableHeight_${name}_${_direction}`,
        );

        if (savedWidth) setWidth(savedWidth);
        if (savedHeight) setHeight(savedHeight);
    }, []);

    useEffect(() => {
        if (width && node !== null) {
            node.style.width = width;
        }
        if (height && node !== null) {
            node.style.height = height;
        }
    }, [node, width, height]);

    const ref = useCallback(
        (nodeEle: HTMLElement | null) => {
            setNode(nodeEle);
        },
        [_direction],
    );

    const updateCursor = (direction: Direction, e: any) => {
        changeCursor(
            e,
            direction === Direction.Horizontal ? "col-resize" : "row-resize",
        );
        document.body.style.userSelect = "none";
    };

    const resetCursor = (e: any) => {
        changeCursor(e, defaultCursor);
        document.body.style.removeProperty("user-select");
    };

    const handleMouseDown = useCallback(
        (e: MouseEvent) => {
            if (!node) {
                return;
            }
            const target = e.target as HTMLElement;
            const direction =
                target.classList.contains("resizer--right_v") ||
                target.classList.contains("resizer--left_v")
                    ? Direction.Horizontal
                    : Direction.Vertical;
            const startPos = {
                x: e.clientX,
                y: e.clientY,
            };
            const styles = window.getComputedStyle(node);
            const w = parseInt(styles.width, 10);
            const h = parseInt(styles.height, 10);

            const handleMouseMove = (e: MouseEvent) => {
                const dx = e.clientX - startPos.x;
                const dy = e.clientY - startPos.y;

                direction === Direction.Horizontal
                    ? (node.style.width = `${
                          _direction === "right" ? w - dx : w + dx
                      }px`)
                    : (node.style.height = `${
                          _direction === "top" ? h + dy : h - dy
                      }px`);
                updateCursor(direction, e);
            };

            const handleMouseUp = () => {
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
                resetCursor(e);

                if (direction == Direction.Horizontal) {
                    localStorage.setItem(
                        `resizableWidth_${name}_${_direction}`,
                        node.style.width,
                    );
                } else {
                    localStorage.setItem(
                        `resizableHeight_${name}_${_direction}`,
                        node.style.height,
                    );
                }
            };

            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        },
        [node, _direction],
    );

    const handleTouchStart = useCallback(
        (e: TouchEvent) => {
            if (!node) {
                return;
            }

            const target = e.target as HTMLElement;
            const direction =
                target.classList.contains("resizer--right_v") ||
                target.classList.contains("resizer--left_v")
                    ? Direction.Horizontal
                    : Direction.Vertical;
            const touch = e.touches[0];
            const startPos = {
                x: touch.clientX,
                y: touch.clientY,
            };
            const styles = window.getComputedStyle(node);
            const w = parseInt(styles.width, 10);
            const h = parseInt(styles.height, 10);

            const handleTouchMove = (e: TouchEvent) => {
                const touch = e.touches[0];
                const dx = touch.clientX - startPos.x;
                const dy = touch.clientY - startPos.y;

                direction === Direction.Horizontal
                    ? (node.style.width = `${
                          _direction === "right" ? w - dx : w + dx
                      }px`)
                    : (node.style.height = `${
                          _direction === "top" ? h + dy : h - dy
                      }px`);

                updateCursor(direction, e);
            };

            const handleTouchEnd = () => {
                document.removeEventListener("touchmove", handleTouchMove);
                document.removeEventListener("touchend", handleTouchEnd);
                resetCursor(e);

                if (direction == Direction.Horizontal) {
                    localStorage.setItem(
                        `resizableWidth_${name}_${_direction}`,
                        node.style.width,
                    );
                } else {
                    localStorage.setItem(
                        `resizableHeight_${name}_${_direction}`,
                        node.style.height,
                    );
                }
            };

            document.addEventListener("touchmove", handleTouchMove, {
                passive: true,
            });
            document.addEventListener("touchend", handleTouchEnd);
        },
        [node, _direction],
    );

    useEffect(() => {
        if (!node) {
            return;
        }
        const resizerElement = node.querySelector(
            "#icon-resize",
        ) as HTMLElement;
        if (resizerElement) {
            resizerElement.addEventListener("mousedown", handleMouseDown);
            resizerElement.addEventListener("touchstart", handleTouchStart, {
                passive: true,
            });
        }

        return () => {
            resizerElement.removeEventListener("mousedown", handleMouseDown);
            resizerElement.removeEventListener("touchstart", handleTouchStart);
        };
    }, [node, handleMouseDown, handleTouchStart]);

    return [ref];
};
