import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { SubProjectsPath, Url } from "src/api/paths";
import { mutationUpdateSubProjectPdfSettings } from "src/api/tms-projects/subProjects";
import {
    Type_put_subProject,
    Type_show_subProject,
} from "src/api/tms-projects/subProjects/types";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { UploadImageRhf } from "src/components/Components_Common/forms/reactHookFormComponents/UploadImage/UploadImage";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const uploadImageSettings = {
    withDescription: true,
    limitResolutions: {
        min: 200,
        max: undefined,
    },
};

const Schema_Image_file = Yup.mixed()
    .optional()
    .nullable() // Explicitly allow null values
    .test("check_type", FORM_ERR_FMT.REQUIRED, function (value) {
        return (
            value === undefined ||
            value === null ||
            typeof value === "string" ||
            Array.isArray(value)
        ); // Check if the value is a string or an array
        // can be a string when it is an old uploaded image, and an Array when it is a new uploaded image (this format is given by UploadImageRhf)
    });

const Schema_PdfAppearance = Yup.object().shape({
    exportTitle: Yup.string().nullable(),
    exportSubTitle: Yup.string().nullable(),
    headerBackgroundColor: Yup.string(),
    leftPicture: Schema_Image_file,
    rightPicture: Schema_Image_file,
});

type Type_Props_PdfAppearanceForm = {
    onClose: () => void;
    formId: string;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    subProject?: Type_show_subProject;
};

export const PdfAppearanceForm = ({
    onClose,
    formId,
    setIsLoading,
    subProject,
}: Type_Props_PdfAppearanceForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PdfAppearance.Form",
    );

    // --------- Mutations ---------
    const { mutateAsync: mutateUpdate, isLoading: isUpdatingSubproject } =
        mutationUpdateSubProjectPdfSettings(subProject?.id as number);

    // -------- FORM ----------
    const form = useForm<Type_put_subProject>({
        defaultValues: {
            ...subProject,
            exportSubTitle: subProject?.exportSubTitle ?? "",
            exportTitle: subProject?.exportTitle ?? "",
        },
        resolver: yupResolver<any>(Schema_PdfAppearance),
    });

    const handleSubmit = async (
        values: Type_put_subProject,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        // TODO: isDirty, colorpicker
        await mutateUpdate(values);
        onClose();
    };

    useEffect(() => {
        setIsLoading(isUpdatingSubproject);
    }, [isUpdatingSubproject]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={formId}>
                <Stack mt={4} gap={6}>
                    <Stack gap={2}>
                        <TextField name="exportTitle" label={fmt(`Title`)} />
                        <TextField
                            name="exportSubTitle"
                            label={fmt(`SubTitle`)}
                        />
                        <ColorPicker
                            name="headerBackgroundColor"
                            label={fmt("BackgroundColor")}
                            data-testid="PdfAppearance-Drawer-background-color"
                        />
                    </Stack>
                    <Stack gap={2}>
                        <Typography variant="h3">
                            {fmt(`LeftPicture`)}
                        </Typography>
                        <UploadImageRhf
                            name="leftPicture"
                            host={Url.PROJECTS}
                            service={`${SubProjectsPath.SUB_PROJECTS}`}
                            imgType={"picture"}
                            id={subProject?.id as number}
                            extraQueryParams={{
                                position: "left",
                                t: String(subProject?.updatedAt),
                            }}
                            {...uploadImageSettings}
                        />
                    </Stack>
                    <Stack gap={2}>
                        <Typography variant="h3">
                            {fmt(`RightPicture`)}
                        </Typography>
                        <UploadImageRhf
                            name="rightPicture"
                            host={Url.PROJECTS}
                            service={`${SubProjectsPath.SUB_PROJECTS}`}
                            imgType={"picture"}
                            id={subProject?.id as number}
                            extraQueryParams={{
                                position: "right",
                                t: String(subProject?.updatedAt),
                            }}
                            {...uploadImageSettings}
                        />
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};
