import {
    Type_chart_item,
    Type_pieChart,
    Type_tfe_getReport,
} from "src/api/tms-tfe/types";

export const formatterPieChart = ({
    done: doneItems = 0,
    doing: doingItems = 0,
    todo: todoItems = 0,
    late: lateItems = 0,
}: Type_tfe_getReport): Type_pieChart => {
    const chartItems: Type_chart_item[] = [];

    chartItems.push({
        name: "Completed",
        y: doneItems,
    });

    chartItems.push({
        name: "InProgress",
        y: doingItems,
    });

    chartItems.push({
        name: "ToDo",
        y: todoItems,
    });

    chartItems.push({
        name: "Delay",
        y: lateItems,
    });

    const totalItems = doneItems + doingItems + todoItems + lateItems;

    return { total: totalItems, series: chartItems };
};
