import {
    Type_index_dayOff,
    Type_post_dayOff,
    Type_prj_index_dayOff,
    Type_prj_post_dayOff,
    Type_prj_put_dayOff,
    Type_prj_show_dayOff,
    Type_show_dayOff,
} from "src/api/tms-projects/daysOffs/types";
import { formatDateForBack, formatDateForFront } from "src/utils/date";

export const formatterIndexDaysOff = (
    daysOff: Type_prj_index_dayOff[],
): Type_index_dayOff[] => {
    return daysOff.map((dayOff: Type_prj_index_dayOff) => {
        return formatterIndexDayOff(dayOff);
    });
};

export const formatterIndexDayOff = (
    dayOff: Type_prj_index_dayOff,
): Type_index_dayOff => {
    return {
        id: dayOff.id,
        name: dayOff.name,
        startDate: formatDateForFront(dayOff.startDate),
        endDate: formatDateForFront(dayOff.endDate),
        trades: dayOff.trades,
        companies: dayOff.companies,
        subProject: dayOff.subProject,
        enabled: dayOff.enabled,
    };
};

export const formatterShowDayOff = (
    dayOff: Type_prj_show_dayOff,
): Type_show_dayOff => {
    return {
        id: dayOff.id,
        names: dayOff.names,
        startDate: formatDateForFront(dayOff.startDate),
        endDate: formatDateForFront(dayOff.endDate),
        companies: dayOff.companies,
        trades: dayOff.trades,
        subProject: dayOff.subProject,
        enabled: dayOff.enabled,
    };
};
export const formatterPostDayOff = (
    dayOff: Type_post_dayOff,
): Type_prj_post_dayOff => {
    return {
        names: dayOff.names,
        startDate: formatDateForBack(dayOff.startDate),
        endDate: dayOff.endDate
            ? formatDateForBack(dayOff.endDate)
            : formatDateForBack(dayOff.startDate),
        companies: dayOff.companies
            ? dayOff.companies.map((company: any) => company.id)
            : [],
        trades: dayOff.trades
            ? dayOff.trades.map((trade: any) => trade.id)
            : [],
    };
};

export const formatterPutDayOff = (
    dayOff: Type_show_dayOff,
): Type_prj_put_dayOff => {
    return {
        id: dayOff.id,
        names: dayOff.names,
        startDate: formatDateForBack(dayOff.startDate),
        endDate:
            dayOff.endDate === null
                ? formatDateForBack(dayOff.startDate)
                : formatDateForBack(dayOff.endDate),
        companies: dayOff.companies
            ? dayOff.companies.map((company: any) => company.id)
            : [],
        trades: dayOff.trades
            ? dayOff.trades.map((trade: any) => trade.id)
            : [],
    };
};
