import { IconButton as MuiIconButton, Tooltip } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton/IconButton";
import * as React from "react";
import { forwardRef, Ref } from "react";

type Type_Props_IconButton = IconButtonProps & {
    title?: string | null;
};

export const IconButton = forwardRef(
    (
        { title, children, ...props }: Type_Props_IconButton,
        ref: Ref<HTMLButtonElement>,
    ) => {
        if (title !== undefined) {
            return (
                <Tooltip title={title}>
                    <span>
                        {/*  span is necessary when button is disabled because the tooltip needs to listen to the btn event to display the title */}
                        <MuiIconButton {...props} ref={ref}>
                            {children}
                        </MuiIconButton>
                    </span>
                </Tooltip>
            );
        }
        return (
            <MuiIconButton {...props} ref={ref}>
                {children}
            </MuiIconButton>
        );
    },
);

IconButton.displayName = "IconButton";
