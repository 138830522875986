import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ResourceFamilyKeys } from "src/api/tms-commons/keys";
import {
    formatterIndexResourceFamilies,
    formatterIndexResourceFamily,
    formatterPostResourceFamily,
    formatterSelectListResourceFamilies,
    formatterShowResourceFamily,
    formatterStatusResourceFamily,
    formatterUpdateResourceFamily,
} from "src/api/tms-commons/resourceFamilies/formatters";
import {
    createResourceFamily,
    deleteResourceFamily,
    indexResourceFamilies,
    selectListResourceFamilies,
    showResourceFamily,
    statusResourceFamily,
    updateResourceFamily,
} from "src/api/tms-commons/resourceFamilies/services";
import {
    Type_index_resourceFamily,
    Type_post_resourceFamily,
    Type_put_resourceFamily,
    Type_show_resourceFamily,
} from "src/api/tms-commons/resourceFamilies/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexResourceFamilies = () => {
    return useQuery({
        queryKey: [ResourceFamilyKeys.INDEX],
        queryFn: indexResourceFamilies,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexResourceFamilies");
            }
            return formatterIndexResourceFamilies(data?.data?.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useShowResourceFamily = (id: number) => {
    return useQuery(
        [ResourceFamilyKeys.SHOW, id],
        () => showResourceFamily(id),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Wrong format data: useShowResourceFamily");
                }
                return formatterShowResourceFamily(data.data.data);
            },
            onError: (err) => {
                return err;
            },
        },
    );
};

export const mutationCreateResourceFamily = (
    callback?: (resourceFamily: Type_index_resourceFamily) => void,
) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceFamily",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newResourceFamily: Type_post_resourceFamily) =>
            createResourceFamily(
                formatterPostResourceFamily(newResourceFamily),
            ),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusResourceFamily",
                );
            }

            const resourceFamily = formatterIndexResourceFamily(data.data.data);

            await queryClient.invalidateQueries({
                queryKey: [ResourceFamilyKeys.INDEX],
            });

            if (typeof callback === "function") {
                await queryClient.invalidateQueries([
                    ResourceFamilyKeys.SELECT_LIST,
                ]);

                callback(resourceFamily);
            }
            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        resourceFamily: resourceFamily.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusResourceFamily = () => {
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: Type_put_resourceFamily) => {
            return statusResourceFamily(
                formatterStatusResourceFamily(newStatus),
                newStatus.id,
            );
        },
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusResourceFamily",
                );
            }

            await queryClient.invalidateQueries(ResourceFamilyKeys.INDEX);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateResourceFamily = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceFamily",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (resourceFamilyToUpdate: Type_show_resourceFamily) =>
            updateResourceFamily(
                formatterUpdateResourceFamily(resourceFamilyToUpdate),
                resourceFamilyToUpdate.id,
            ),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateResourceFamily",
                );
            }

            const resourceFamily = formatterIndexResourceFamily(data.data.data);

            await queryClient.invalidateQueries([ResourceFamilyKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        resourceFamily: resourceFamily.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useSelectListResourceFamilies = () => {
    return useQuery({
        queryKey: [ResourceFamilyKeys.SELECT_LIST],
        queryFn: selectListResourceFamilies,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useSelectListResourceFamilies",
                );
            }
            return formatterSelectListResourceFamilies(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const mutationDeleteResourceFamily = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceFamily",
    );

    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => deleteResourceFamily(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationDeleteResourceFamily",
                );
            }
            await queryClient.invalidateQueries({
                queryKey: [ResourceFamilyKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationDeleteResourceFamily", err);
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
