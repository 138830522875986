import { TabProps } from "@mui/base";
import { TabList, TabListProps, TabPanel, TabPanelProps } from "@mui/lab";
import { Box, BoxProps, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const Styled_TabListContainerProjects = styled(Box)<BoxProps>(() => ({
    boxShadow: `inset 0 -1px 0 0 ${COLORS.moon200}`,
}));

export const Styled_TabListProjects = styled(TabList)<TabListProps>(() => ({
    "& .MuiTabs-indicator": {
        height: "3px",
        backgroundColor: COLORS.blue600,
        borderRadius: "2px 2px 0px 0px",
    },
}));

export const Styled_TabProjects = styled(Tab)<TabProps>(({ theme }) => ({
    "&.MuiTab-root": {
        padding: 0,
        minWidth: 0,
        marginRight: theme.spacing(4),
        textTransform: "none",
        color: COLORS.moon900,
        fontSize: "16px",
        lineHeight: theme.spacing(6),
        fontWeight: 400,
        "&.Mui-selected": {
            color: COLORS.blue600,
        },
    },
}));

export const Styled_TabPanelProjects = styled(TabPanel)<TabPanelProps>(
    ({ theme }) => ({
        "&.MuiTabPanel-root": {
            padding: theme.spacing(4, 0),
        },
    }),
);
