import React from "react";

import { Box, Divider } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const Page_ConfirmMailComponent = () => {
    const { formatMessageWithPartialKey } = useCoreIntl("ConfirmMail");
    return (
        <div className="bg-gray-200 flex justify-center items-center w-full h-screen">
            <div className="block-login flex justify-center w-full">
                <Box className="space-y-4 flex flex-col justify-center items-center">
                    <>
                        <div className="text-5xl text-green-500 pt-6">
                            <Icon
                                variant="solid"
                                icon="envelope-circle-check"
                            />
                        </div>
                        <h1 className="text-2xl pb-6 uppercase font-medium text-gray-800">
                            {formatMessageWithPartialKey("Message")}
                        </h1>
                        <Divider className="my-12" />
                        <h2 className="text-sm text-gray-600 italic">
                            {formatMessageWithPartialKey("Instruction_0")}
                        </h2>
                    </>
                </Box>
            </div>
        </div>
    );
};
