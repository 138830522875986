import { Type_MenuItem_DropDownRightClick } from "src/components/Components_Common/dropdownMenuRightClick/DropDownMenuRightClick";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useMenuItems = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Planning.RightClickDropDown",
    );

    const menuItemsPlanningGrid: Type_MenuItem_DropDownRightClick[] = [
        /*{ Temporairement désactivé
            label: fmt("Newtask"),
            call: "createNewTask",
            icon: {
                variant: "light",
                name: "plus",
            },
        },*/
        {
            label: fmt("AddAKeydateToZone"),
            call: "addAKeyDate",
            icon: {
                variant: "kit",
                name: "light-tds-task-milestone",
            },
        },
        /* { Temporairement désactivé
            label: fmt("HideZone"),
            call: "hideArea",
            icon: {
                variant: "light",
                name: "eye-slash",
            },
        },*/
    ];

    const menuItemsPlanningAreas: Type_MenuItem_DropDownRightClick[] = [
        {
            label: fmt("Edit"),
            call: "editArea",
            icon: {
                variant: "regular",
                name: "pen",
            },
        },
        {
            divider: true,
        },
        {
            label: fmt("DuplicateZone"),
            call: "duplicateArea",
            icon: {
                variant: "regular",
                name: "copy",
            },
        },
        {
            label: fmt("HideZone"),
            call: "hideArea",
            icon: {
                variant: "regular",
                name: "eye-slash",
            },
        },
        {
            label: fmt("DisabledZone"),
            call: "statusArea",
            icon: {
                variant: "regular",
                name: "circle-dashed",
            },
        },
        {
            label: fmt("InsertAbove"),
            call: "insertAboveArea",
            icon: {
                variant: "regular",
                name: "arrow-up-to-line",
            },
        },
        {
            label: fmt("InsertBelow"),
            call: "insertBelowArea",
            icon: {
                variant: "regular",
                name: "arrow-down-to-line",
            },
        },
    ];

    const menuItemsPlanningDates: Type_MenuItem_DropDownRightClick[] = [
        {
            label: fmt("AddAKeydate"),
            call: "addAKeyDate",
            icon: {
                variant: "kit",
                name: "light-tds-task-milestone",
            },
        },
        {
            label: fmt("AddADayOff"),
            call: "addADayOff",
            icon: {
                variant: "regular",
                name: "calendar-xmark",
            },
        },
    ];

    const withoutMenu: Type_MenuItem_DropDownRightClick[] = [];

    return {
        menuItemsPlanningGrid,
        menuItemsPlanningAreas,
        menuItemsPlanningDates,
        withoutMenu,
    };
};
