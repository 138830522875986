import { useQuery, UseQueryResult } from "react-query";

import { getImg, GetReturnType, Type_RequestConfig } from "src/api/fetch";
import { ONE_MONTH_STALE_TIME } from "src/configurations/app";

import { Type_imgType } from "./types";

export const getImage = (
    id: number,
    host: string,
    service: string,
    imgType: Type_imgType,
    queryParams?: string,
): GetReturnType => {
    return getImg(`${host}${service}/${id}/${imgType}?${queryParams}`);
};

export const getImageQuery = (
    id: number,
    host: string,
    service: string,
    imgType: Type_imgType,
    queryParams?: string,
    enabled?: boolean,
    config?: Type_RequestConfig,
): UseQueryResult => {
    return useQuery(
        [host, service, id, imgType, queryParams],
        (): Promise<string | undefined> => {
            return getImg(
                `${host}${service}/${id}/${imgType}?${queryParams}`,
                config,
            ).then((r) => {
                return URL.createObjectURL(r.data);
            });
        },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            staleTime: ONE_MONTH_STALE_TIME,
            enabled: enabled ?? true,
        },
    );
};
