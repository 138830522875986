import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Stack } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import * as Yup from "yup";

import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import {
    InputFile,
    Type_Resource,
} from "src/components/Components_Common/forms/InputFile";
import { Input } from "src/components/Components_Common/forms/reactHookFormComponents/Input/Input";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_ReportForm = {
    onClose: () => void;
    reportIdToUpdate?: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_Report = Yup.lazy(() => {
    return Yup.object().shape({
        name: Yup.string().required(FORM_ERR_FMT.REQUIRED),
    });
});

export const ReportForm = ({
    onClose,
    action,
    setIsLoading,
}: Type_Props_ReportForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Report");

    const [reportTemplate, setReportTemplate] = useState<Type_Resource>({
        file: null,
        image: null,
        errors: false,
    });

    // const { mutateAsync: mutateCreate } = mutationCreateReport() || {};
    //
    // const { mutateAsync: mutateUpdate } = mutationUpdateReport();

    // const { data, isFetching } = useShowReport(reportIdToUpdate as number);

    // TODO remove after link with back
    const isFetching = false;
    const data: any[] | undefined = undefined;

    const form = useTmcForm<any>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            name: "",
        },
        values: data,
        resolver: yupResolver<any>(Schema_Report),
        onClose,
        setIsLoading,
    });

    const handleSubmit = async (values: any) => {
        values.template = reportTemplate.file;
        try {
            if (action === "update") {
                // await mutateUpdate(values);
            } else {
                // await mutateCreate(values);
            }

            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            console.error("Error when creating or updating report", e);
        }
    };

    return (
        <TmcFormProvider form={form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"report"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack marginTop={4} gap={4}>
                        <Input
                            variant="outlined"
                            placeholder={fmt("Name")}
                            data-testid={"report-drawer-input-name"}
                            name="name"
                        />

                        <Alert severity="info">{fmt("InfoTags")}</Alert>

                        <InputFile
                            acceptFormats={["docx"]}
                            state={reportTemplate}
                            setState={setReportTemplate}
                            data-testid="Form-Drawing-Input-Drawing-File"
                        />
                    </Stack>
                )}
            </form>
        </TmcFormProvider>
    );
};
