import React, { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";
import { Type_Props_TextField as Type_Props_TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextField";

type Type_Props_TextField = Type_Props_TMC_TextField & {
    name: string;
};

export const Input = forwardRef<HTMLInputElement, Type_Props_TextField>(
    ({ name, ...props }, ref) => {
        const { formState, control } = useFormContext();

        return (
            <>
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { ref: inputRef, ...rest } }) => (
                        <TMC_TextField
                            {...props}
                            {...rest}
                            inputRef={ref || inputRef}
                            error={!!formState.errors[name]}
                            helperText={
                                formState.errors[name] && (
                                    <FormHelperMessage
                                        inputName={name}
                                        message={
                                            formState?.errors[name]
                                                ?.message as string
                                        }
                                    />
                                )
                            }
                        />
                    )}
                />
            </>
        );
    },
);

Input.displayName = "Input";
