import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateUnitType,
    mutationUpdateUnitType,
    useShowUnitType,
} from "src/api/tms-commons/unitTypes";
import {
    Type_index_unitType,
    Type_post_unitType,
    Type_show_unitType,
} from "src/api/tms-commons/unitTypes/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_UnitTypeForm = {
    onClose: () => void;
    onSubmit?: (unitType: Type_index_unitType) => void;
    action: Type_action;
    unitTypeId?: number;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    names?: { [key: string]: string };
};

const Schema_UnitTypeForm = Yup.object().shape({
    names: Yup.lazy(() => inputMultiLanguagesSchema()),
    units: Yup.object()
        .shape({
            id: Yup.number(),
            name: Yup.string(),
        })
        .required(FORM_ERR_FMT.REQUIRED),
});

export const UnitTypeForm = ({
    onClose,
    action,
    onSubmit,
    unitTypeId,
    setIsLoading,
    names,
}: Type_Props_UnitTypeForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.UnitTypes");

    const { data, isFetching } = useShowUnitType(unitTypeId as number);

    const { mutateAsync: mutateCreate } =
        mutationCreateUnitType(onSubmit) || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateUnitType() || {};

    const handleSubmit = async (
        values: Type_show_unitType | Type_post_unitType,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        // TODO: isDirty ne fonctionne pas avec la création d'une nouvelle unité

        setIsLoading(true);

        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_unitType);
            } else {
                await mutateCreate(values as Type_post_unitType);
            }
            // Fermeture du drawer
            onClose();
        } catch (_e: any) {
            console.error("Error when creating or updating unit");
        }

        setIsLoading(false);
    };

    const form = useForm<Type_show_unitType | Type_post_unitType>({
        defaultValues: {
            id: undefined,
            names: names || {},
        },
        values: data,
        resolver: yupResolver<any>(Schema_UnitTypeForm),
    });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"unitTypes"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <InputMultiLanguages
                        name="names"
                        label={fmt(`UnitTypeName`)}
                    />
                )}
            </form>
        </FormProvider>
    );
};
