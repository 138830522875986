import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { mutationCheckToken } from "src/api/tms-users/users";
import { formatterCheckToken } from "src/api/tms-users/users/formatters";
import { Type_checkToken_user } from "src/api/tms-users/users/types";
import { RegisterForm } from "src/forms/register/RegisterForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Register_Container } from "src/pages/Pages_Common/Page_Register/stylesheet";

export const Page_Register = () => {
    const { token } = useParams();
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Teamoty.Register");

    const { mutate: mutateCheckToken, isLoading } = mutationCheckToken() || {};

    const [tokenData, setTokenData] = useState<Type_checkToken_user>();

    useEffect(() => {
        if (token) {
            mutateCheckToken(token, {
                onSuccess: (res) => {
                    if (!res?.success || !res?.data?.data) {
                        throw new Error(
                            "Wrong format data: mutationCheckToken",
                        );
                    }
                    !isLoading &&
                        setTokenData(formatterCheckToken(res.data.data));
                },
            });
        }
    }, [token]);

    return (
        <>
            {!isLoading && tokenData?.isValid && (
                <Register_Container data-testid={"RegisterForm-container"}>
                    <Typography
                        variant={"h1"}
                        marginBottom={4}
                        data-testid={"RegisterForm-title"}
                    >
                        {fmt("Title")}
                    </Typography>
                    <RegisterForm tokenData={tokenData!} />
                </Register_Container>
            )}
        </>
    );
};
