import { Box, List, Stack, Typography } from "@mui/material";
import { memo, useState } from "react";

import { useIndexResourceTask } from "src/api/tms-scheduling/resourceTask";
import { Type_index_resourceTasks } from "src/api/tms-scheduling/resourceTask/types";
import { Empty } from "src/components";
import { ItemChildrenProps } from "src/components/Components_Common/accordions/Accordion";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Type_event_resourceTasks, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

const ResourcesList = memo(
    ({ resources }: { resources: Type_index_resourceTasks[] }) => {
        return (
            <>
                {resources.map((resourceType) => (
                    <Box key={`resourceType_${resourceType.id}`} mb={2}>
                        <Typography typography={"body2"} color="text.secondary">
                            {resourceType.name}
                        </Typography>
                        <List>
                            {resourceType.resources.map((resource) => (
                                <Stack
                                    key={`resource_${resource.id}`}
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                >
                                    <Typography>{resource.name}</Typography>
                                    <Typography>
                                        ({resource.quantity}{" "}
                                        {resourceType.unit.name})
                                    </Typography>
                                </Stack>
                            ))}
                        </List>
                    </Box>
                ))}
            </>
        );
    },
);

ResourcesList.displayName = "ResourcesList";

type Type_Props_ResourceTaskForm = ItemChildrenProps & {
    taskId: number;
};

export const ResourcesTask = ({
    taskId,
    readonly,
}: Type_Props_ResourceTaskForm) => {
    const [resources, setResources] = useState<Type_index_resourceTasks[]>([]);
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Resources",
    );
    const { openPaper } = useContextualDrawer();

    const { isFetching: isFetchingResource } = useIndexResourceTask(
        setResources,
        taskId,
        "list",
    );

    useChannel({
        eventHandler: ({ event, data }) => {
            const resourceTasksData = data as Type_event_resourceTasks;
            if (event === "updateResourceTasks") {
                setResources(resourceTasksData);
            }
        },
    });

    const handleNewResource = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        openPaper("resource", {
            id: taskId,
            focus: true,
        });
    };

    return isFetchingResource ? (
        <FullSpinner />
    ) : resources.length > 0 ? (
        <ResourcesList resources={resources} />
    ) : (
        <Empty
            message={fmt("EmptyResourceMessage")}
            {...(!readonly && {
                actionMessage: fmt("AddFirstResource"),
                onClick: handleNewResource,
            })}
            sx={{ marginBottom: 2 }}
            dataTestIdRef={"ResourceTask"}
        />
    );
};
