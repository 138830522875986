import { Components } from "@mui/material";

export const MuiListItemTextTheme: Components["MuiListItemText"] = {
    styleOverrides: {
        root: ({ theme }: any) => ({
            "&&.Mui-selected": {
                color: theme.palette.primary.main,
            },
        }),
    },
};
