import {
    DialogActionsProps,
    DialogActions as MuiDialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

export const Styled_DialogActions = styled(MuiDialogActions)(() => ({}));

type Type_Props_DialogTitle = DialogActionsProps;

export const DialogActions = ({
    children,
    ...props
}: Type_Props_DialogTitle) => {
    return <Styled_DialogActions {...props}>{children}</Styled_DialogActions>;
};
