import { DrawerProps, Drawer as MuiDrawer } from "@mui/material";
import { ReactNode } from "react";

import { isRtlLang } from "src/assets/translations";

import { Styled_SmallDrawer } from "./Drawer.style";

type sizeType = "small" | "inehrit";
type DrawerPropsType = {
    children: ReactNode;
    size?: sizeType;
};

export const Drawer = ({
    children,
    size,
    ...restProps
}: DrawerPropsType & DrawerProps) => {
    if (!restProps.anchor) {
        restProps.anchor = isRtlLang() ? "left" : "right";
    }

    if (size === "small")
        return (
            <Styled_SmallDrawer {...restProps}>{children}</Styled_SmallDrawer>
        );
    else return <MuiDrawer {...restProps}>{children}</MuiDrawer>;
};
