import {
    FormControl,
    IconButton,
    InputLabel,
    OutlinedInputProps,
    Stack,
    TextFieldProps,
} from "@mui/material";
import { FormikErrors } from "formik";
import React, { useState } from "react";

import {
    countryCodeLanguageLtr,
    countryCodeLanguageRtl,
} from "src/assets/translations";
import { Styled_RoundedFormControl } from "src/components/Components_Common/_MuiComponentsVariants/FormControl/FormControl.style";
import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants/index";
import {
    Styled_InputAdornmentTextFielWithBlock,
    Styled_InputAdornmentTextFielWithUnit,
} from "src/components/Components_Common/_MuiComponentsVariants/InputAdornment/MuiInputAdornment.style";
import {
    Styled_OutlinedInput,
    Styled_OutlinedInputBlock,
    Styled_OutlinedInputColorPicker,
    Styled_RoundedOutlinedInput,
    Styled_RoundedOutlinedInputColorPicker,
} from "src/components/Components_Common/_MuiComponentsVariants/OutlinedInput/OutlinedInput.style";
import {
    Styled_RoundedTextField,
    Styled_RoundedTextFieldNumber,
    Styled_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextFieldDeprecated.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { COLORS } from "src/theme/stylesheet";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export type formatModalType = "rounded";

export type Type_Props_TextField = TextFieldProps & {
    format?: formatModalType;
    fullWidth?: boolean;
    unit?: JSX.Element | null | string;
    password?: boolean;
    min?: number | string;
    max?: number | string;
};

/**
 * @deprecated use component app/src/components/Components_Common/_MuiComponentsVariants/TextField/TextField.tsx
 */
export const TextField = ({
    format,
    fullWidth,
    ...restProps
}: Type_Props_TextField) => {
    const isRtlLang = countryCodeLanguageRtl.includes(
        getLocalStorageItem("language"),
    );
    const isLtrInput = countryCodeLanguageLtr.includes(restProps.name || "");
    const isRtrInput = countryCodeLanguageRtl.includes(restProps.name || "");
    const textFieldProps: TextFieldProps = {
        ...restProps,
        helperText: null,
    };

    if (format === "rounded" && restProps.type === "number")
        return <TextFieldTypeNumber {...restProps} />;
    else if (format === "rounded") {
        const [isFocused, setIsFocused] = useState<boolean>(false);

        const handleFocus = (e: any) => {
            setIsFocused(true);
            restProps?.onFocus?.(e);
        };

        const handleBlur = (e: any) => {
            setIsFocused(false);
            restProps?.onBlur?.(e);
        };

        return (
            <Stack
                flexDirection="column"
                width={fullWidth ? "100%" : "initial"}
            >
                <Styled_RoundedFormControl
                    ownerState={{
                        error: restProps.error,
                        disabled: restProps.disabled,
                        focused: isFocused,
                        isRtl: isRtlLang,
                        isLtrInput: isRtlLang ? isLtrInput : !isRtrInput,
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                >
                    <Styled_RoundedTextField {...textFieldProps} />
                </Styled_RoundedFormControl>
                {restProps.helperText && (
                    <TMC_FormHelperText
                        helperText={restProps.helperText as string}
                        isRtlLang={isRtlLang}
                        inputName={restProps.name as string}
                    />
                )}
            </Stack>
        );
    }
    return (
        <Stack flexDirection="column">
            <Styled_TextField
                {...textFieldProps}
                ownerState={{
                    isRtl: isRtlLang,
                    isLtrInput: isRtlLang ? isLtrInput : !isRtrInput,
                }}
            />
            {restProps.helperText && (
                <TMC_FormHelperText
                    inputName={restProps.name as string}
                    helperText={restProps.helperText as string}
                    isRtlLang={isRtlLang}
                />
            )}
        </Stack>
    );
};

type Type_TextFieldPasswordProps = {
    helperText?: JSX.Element | null | string;
    format?: formatModalType;
};

/**
 * @deprecated
 */
export const TextFieldPassword = ({
    format,
    helperText,
    ...restProps
}: Type_TextFieldPasswordProps & OutlinedInputProps) => {
    const isRtlLang = countryCodeLanguageRtl.includes(
        getLocalStorageItem("language"),
    );
    const isLtrInput = countryCodeLanguageLtr.includes(restProps.name || "");
    const isRtrInput = countryCodeLanguageRtl.includes(restProps.name || "");

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const ownerState = {
        error: restProps.error,
        disabled: restProps.disabled,
        isRtl: isRtlLang,
        isLtrInput: isRtlLang ? isLtrInput : !isRtrInput,
    };

    if (format === "rounded") {
        return (
            <Stack flexDirection="column">
                <Styled_RoundedFormControl ownerState={ownerState}>
                    {restProps.label && (
                        <InputLabel shrink>
                            {restProps.label}
                            {restProps.required && " *"}
                        </InputLabel>
                    )}
                    <Styled_RoundedOutlinedInput
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                            <Styled_InputAdornmentTextFielWithUnit
                                position="end"
                                ownerState={ownerState}
                            >
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    size="small"
                                >
                                    <Icon
                                        variant="solid"
                                        icon={
                                            showPassword ? "eye-slash" : "eye"
                                        }
                                        sx={{ color: COLORS.moon900 }}
                                    />
                                </IconButton>
                            </Styled_InputAdornmentTextFielWithUnit>
                        }
                        {...restProps}
                        ownerState={ownerState}
                    />
                </Styled_RoundedFormControl>
                {helperText && (
                    <TMC_FormHelperText
                        inputName={restProps.name as string}
                        helperText={helperText as string}
                        isRtlLang={isRtlLang}
                    />
                )}
            </Stack>
        );
    }
    return (
        <Stack flexDirection="column">
            <FormControl>
                {restProps.label && (
                    <InputLabel shrink>
                        {restProps.label}
                        {restProps.required && " *"}
                    </InputLabel>
                )}
                <Styled_OutlinedInput
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                        <Styled_InputAdornmentTextFielWithUnit
                            position="end"
                            ownerState={ownerState}
                        >
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                size="small"
                            >
                                <Icon
                                    variant="solid"
                                    icon={showPassword ? "eye-slash" : "eye"}
                                    sx={{ color: COLORS.moon900 }}
                                />
                            </IconButton>
                        </Styled_InputAdornmentTextFielWithUnit>
                    }
                    {...restProps}
                    ownerState={ownerState}
                />
            </FormControl>
            {helperText && (
                <TMC_FormHelperText
                    inputName={restProps.name as string}
                    helperText={helperText as string}
                    isRtlLang={isRtlLang}
                />
            )}
        </Stack>
    );
};

/**
 * @deprecated
 */
export const TextFieldTypeNumber = ({
    ...restProps
}: TextFieldProps & Type_Props_TextField) => {
    const [value, setValue] = useState<number>(
        restProps.value
            ? (restProps.value as number)
            : restProps.defaultValue
              ? (restProps.defaultValue as number)
              : 0,
    );
    const isRtlLang = countryCodeLanguageRtl.includes(
        getLocalStorageItem("language"),
    );
    const isLtrInput = countryCodeLanguageLtr.includes(restProps.name || "");
    const isRtrInput = countryCodeLanguageRtl.includes(restProps.name || "");
    const textFieldProps: TextFieldProps = {
        ...restProps,
        helperText: null,
    };
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleDecrement = (event: any): void => {
        setValue((prevValue: number): number => {
            if (restProps?.min === undefined || +restProps?.min < +prevValue) {
                if (restProps?.onChange) {
                    event.target.name = restProps?.name || "";
                    event.target.type = "number";
                    event.target.value = String(value - 1);
                    restProps.onChange(event);
                }
                return prevValue - 1;
            } else return prevValue;
        });
    };

    const handleIncrement = (event: any) => {
        setValue((prevValue: number): number => {
            if (restProps?.max === undefined || +restProps?.max > +prevValue) {
                if (restProps?.onChange) {
                    event.target.name = restProps?.name || "";
                    event.target.type = "number";
                    event.target.value = String(value + 1);
                    restProps.onChange(event);
                }
                return prevValue + 1;
            } else return prevValue;
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(event.target.value);
        newValue = isNaN(newValue) ? 0 : newValue;

        setValue(() => {
            if (restProps?.onChange) {
                event.target.value = String(newValue);
                restProps.onChange(event);
            }
            return newValue;
        });
    };

    return (
        <Stack flexDirection="column">
            <Styled_RoundedFormControl
                ownerState={{
                    error: restProps.error,
                    disabled: restProps.disabled,
                    focused: isFocused,
                    isRtl: isRtlLang,
                    isLtrInput: isRtlLang ? isLtrInput : !isRtrInput,
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
            >
                <Styled_RoundedTextFieldNumber
                    {...textFieldProps}
                    value={value}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <button
                                data-testid={`TextFieldNumber-Form-ButtonDecrement-${restProps.name}`}
                                type="button"
                                disabled={restProps.disabled}
                                onClick={handleDecrement}
                                style={{ height: "23px" }}
                            >
                                <Icon variant="solid" icon="minus" />
                            </button>
                        ),
                        endAdornment: (
                            <button
                                data-testid={`TextFieldNumber-Form-ButtonIncrement-${restProps.name}`}
                                type="button"
                                disabled={restProps.disabled}
                                onClick={handleIncrement}
                                style={{ height: "23px" }}
                            >
                                <Icon variant="solid" icon="plus" />
                            </button>
                        ),
                    }}
                />
            </Styled_RoundedFormControl>
            {restProps.helperText && (
                <TMC_FormHelperText
                    inputName={restProps.name as string}
                    helperText={restProps.helperText as string}
                    isRtlLang={isRtlLang}
                />
            )}
        </Stack>
    );
};

type TextFieldWithUnitPropsType = {
    unit: JSX.Element | null | string;
    helperText?:
        | JSX.Element
        | null
        | string
        | false
        | FormikErrors<any>
        | FormikErrors<any>[];
    format?: formatModalType;
    positionAdornment?: string;
};

/**
 * @deprecated
 */
export const TextFieldWithUnit = ({
    format,
    unit,
    helperText,
    positionAdornment,
    ...restProps
}: TextFieldWithUnitPropsType & OutlinedInputProps) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const isRtlLang = countryCodeLanguageRtl.includes(
        getLocalStorageItem("language"),
    );
    const isLtrInput = countryCodeLanguageLtr.includes(restProps.name || "");
    const isRtrInput = countryCodeLanguageRtl.includes(restProps.name || "");

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const ownerState = {
        error: restProps.error,
        disabled: restProps.disabled,
        focused: isFocused,
        isRtl: isRtlLang,
        isLtrInput: isRtlLang ? isLtrInput : !isRtrInput,
    };

    if (format === "rounded") {
        return (
            <Stack flexDirection="column">
                <Styled_RoundedFormControl
                    ownerState={ownerState}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                >
                    {restProps.label && (
                        <InputLabel shrink>
                            {restProps.label}
                            {restProps.required && " *"}
                        </InputLabel>
                    )}
                    {positionAdornment === "start" ? (
                        <Styled_RoundedOutlinedInputColorPicker
                            startAdornment={
                                <Styled_InputAdornmentTextFielWithUnit
                                    position="start"
                                    ownerState={{
                                        ...ownerState,
                                        focused:
                                            ownerState.focused ||
                                            (!!restProps.value &&
                                                !restProps.disabled),
                                    }}
                                >
                                    {unit}
                                </Styled_InputAdornmentTextFielWithUnit>
                            }
                            {...restProps}
                            ownerState={ownerState}
                        />
                    ) : (
                        <Styled_RoundedOutlinedInput
                            endAdornment={
                                <Styled_InputAdornmentTextFielWithUnit
                                    position="end"
                                    ownerState={{
                                        ...ownerState,
                                        focused:
                                            ownerState.focused ||
                                            (!!restProps.value &&
                                                !restProps.disabled),
                                    }}
                                >
                                    {unit}
                                </Styled_InputAdornmentTextFielWithUnit>
                            }
                            {...restProps}
                            ownerState={ownerState}
                        />
                    )}
                </Styled_RoundedFormControl>
                {helperText && (
                    <TMC_FormHelperText
                        inputName={restProps.name as string}
                        helperText={String(helperText)}
                        isRtlLang={isRtlLang}
                    />
                )}
            </Stack>
        );
    }
    return (
        <Stack flexDirection="column">
            <FormControl onFocus={handleFocus} onBlur={handleBlur}>
                {restProps.label && (
                    <InputLabel shrink>
                        {restProps.label}
                        {restProps.required && " *"}
                    </InputLabel>
                )}
                {positionAdornment === "start" ? (
                    <Styled_OutlinedInputColorPicker
                        startAdornment={
                            <Styled_InputAdornmentTextFielWithUnit
                                position="start"
                                ownerState={{
                                    ...ownerState,
                                    focused:
                                        ownerState.focused ||
                                        (!!restProps.value &&
                                            !restProps.disabled),
                                }}
                            >
                                {unit}
                            </Styled_InputAdornmentTextFielWithUnit>
                        }
                        {...restProps}
                        ownerState={ownerState}
                    />
                ) : (
                    <Styled_OutlinedInput
                        endAdornment={
                            <Styled_InputAdornmentTextFielWithUnit
                                position="end"
                                ownerState={{
                                    ...ownerState,
                                    focused:
                                        ownerState.focused ||
                                        (!!restProps.value &&
                                            !restProps.disabled),
                                }}
                            >
                                {unit}
                            </Styled_InputAdornmentTextFielWithUnit>
                        }
                        {...restProps}
                        ownerState={ownerState}
                    />
                )}
            </FormControl>
            {helperText && (
                <TMC_FormHelperText
                    inputName={restProps.name as string}
                    helperText={String(helperText)}
                    isRtlLang={isRtlLang}
                />
            )}
        </Stack>
    );
};

type Type_TextFieldWithBlockProps = {
    block: string;
    helperText?: JSX.Element | null | string;
};

/**
 * @deprecated
 */
export const TextFieldWithBlock = ({
    block,
    helperText,
    ...restProps
}: Type_TextFieldWithBlockProps & OutlinedInputProps) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const isRtlLang = countryCodeLanguageRtl.includes(
        getLocalStorageItem("language"),
    );
    const isLtrInput = countryCodeLanguageLtr.includes(restProps.name || "");
    const isRtrInput = countryCodeLanguageRtl.includes(restProps.name || "");

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const ownerState = {
        error: restProps.error,
        disabled: restProps.disabled,
        focused: isFocused,
        isRtl: isRtlLang,
        isLtrInput: isRtlLang ? isLtrInput : !isRtrInput,
    };

    return (
        <Stack flexDirection="column">
            <FormControl onFocus={handleFocus} onBlur={handleBlur}>
                {restProps.label && (
                    <InputLabel shrink>
                        {restProps.label}
                        {restProps.required && " *"}
                    </InputLabel>
                )}
                <Styled_OutlinedInputBlock
                    autoComplete="off"
                    startAdornment={
                        <Styled_InputAdornmentTextFielWithBlock
                            position="start"
                            ownerState={{
                                ...ownerState,
                                focused:
                                    ownerState.focused ||
                                    (!!restProps.value && !restProps.disabled),
                            }}
                        >
                            {block}
                        </Styled_InputAdornmentTextFielWithBlock>
                    }
                    {...restProps}
                    ownerState={ownerState}
                />
            </FormControl>
            {helperText && (
                <TMC_FormHelperText
                    inputName={restProps.name as string}
                    helperText={helperText as string}
                    isRtlLang={isRtlLang}
                />
            )}
        </Stack>
    );
};
