import { yupResolver } from "@hookform/resolvers/yup";
import { TabContext, TabPanel } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateArea,
    mutationUpdateArea,
    useShowArea,
} from "src/api/tms-projects/areas";
import {
    Type_post_area,
    Type_put_area,
} from "src/api/tms-projects/areas/types";
import { LoadingBox } from "src/components";
import { Type_drawerTab } from "src/components/Components_Common/Drawer/Header/Header.container";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import {
    DrawerTab,
    DrawerTabList,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { AutocompleteAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreas";
import { AutocompleteDrawings } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteDrawings";
import { AutocompleteProjectAreaTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteProjectAreaTypes";
import { AutocompleteResourcesGeneric } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResourcesGeneric";
import { AutocompleteResourceTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResourceType";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const Schema_Area = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.lazy(() => inputMultiLanguagesSchema()),
        color: Yup.string(),
        areaType: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .required(FORM_ERR_FMT.REQUIRED),
        parent: Yup.object()
            .shape({
                id: Yup.number().nullable(),
                name: Yup.string(),
            })
            .nullable(),
        drawing: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable(),
        resource: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable(),
        resourceType: Yup.object()
            .shape({
                id: Yup.number(),
                name: Yup.string(),
            })
            .nullable(),
        scheduling: Yup.boolean(),
        permanentUse: Yup.boolean(),
    });
});

const switchSx = {
    justifyContent: "space-between",
    ".MuiTypography-root": {
        paddingLeft: 0,
    },
};

type Type_props_AreaForm = {
    onClose: () => void;
    areaIdToUpdate?: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const AreaForm = ({
    onClose,
    areaIdToUpdate,
    action,
    setIsLoading,
}: Type_props_AreaForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Area");
    const { mutateAsync: mutateUpdate } =
        mutationUpdateArea(true, true, false) || {};
    const { mutateAsync: mutateCreate } = mutationCreateArea(true, false) || {};

    // get area by id
    const { isFetching, data } = useShowArea(areaIdToUpdate as number) || {};

    const form = useForm({
        ...useFormDefaultConfig,
        defaultValues: {
            id: areaIdToUpdate,
            names: {},
            scheduling: true,
            assignResource: false,
            assignResourceType: false,
            permanentUse: false,
            areaType: null,
            parentArea: null,
            drawing: null,
            resource: null,
            resourceType: null,
        },
        values: data,
        resolver: yupResolver<any>(Schema_Area),
    });

    //handle tabs
    const [tab, setTab] = useState<"0" | "1">("0");
    const handleChange = (event: React.SyntheticEvent, newValue: "0" | "1") => {
        setTab(newValue);
    };

    const drawerTabs = [
        { label: fmt("Header.Tabs.Area"), value: "0" },
        { label: fmt("Header.Tabs.Scheduling"), value: "1" },
    ];

    // submit functions
    const handleSubmit = async (values: Type_put_area | Type_post_area) => {
        // TODO: isDirty
        try {
            setIsLoading(true);
            if (action === "update") {
                await mutateUpdate({
                    id: areaIdToUpdate!,
                    data: values as Type_put_area,
                });
            } else if (action === "create") {
                await mutateCreate({
                    names: values.names,
                    color: values.color,
                    areaType: values.areaType,
                    parentArea: values.parent,
                    parent_id: values.parent?.id,
                    drawing: values.drawing,
                    resource: values.resource,
                    resourceType: values.resourceType,
                    scheduling: values.scheduling,
                    permanentUse: values.permanentUse,
                } as Type_post_area);
            }
            onClose();
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const control = form.control;

    const [scheduling, assignResourceType, assignResource] = useWatch({
        control,
        name: ["scheduling", "assignResourceType", "assignResource"],
    });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"areas"}>
                <TabContext value={tab}>
                    <DrawerTabList
                        onChange={handleChange}
                        data-testid="Role-TabList"
                    >
                        {drawerTabs.map(
                            (drawerTab: Type_drawerTab, key: number) => {
                                return (
                                    <DrawerTab
                                        data-testid={`Tab-${drawerTab.value}`}
                                        label={drawerTab.label}
                                        value={drawerTab.value}
                                        key={key}
                                    />
                                );
                            },
                        )}
                    </DrawerTabList>
                    {isFetching ? (
                        <LoadingBox />
                    ) : (
                        <>
                            <TabPanel value={"0"} sx={{ padding: 0 }}>
                                <Stack spacing={6}>
                                    <InputMultiLanguages
                                        name="names"
                                        label={fmt("Content.Labels.Name")}
                                    />
                                    <ColorPicker
                                        name="color"
                                        label={fmt("Content.Labels.Color")}
                                        data-testid="Areas-Drawer-AddArea-color"
                                    />
                                    <SectionTitle
                                        title={fmt(
                                            "Content.SubTitles.AreaOptions",
                                        )}
                                    />
                                    <AutocompleteProjectAreaTypes
                                        name={"areaType"}
                                        label={fmt("Content.Labels.AreaType")}
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                    <AutocompleteAreas
                                        label={fmt("Content.Labels.ParentArea")}
                                        name="parent"
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                    <SectionTitle
                                        title={fmt(
                                            "Content.SubTitles.DrawingOptions",
                                        )}
                                    />
                                    <AutocompleteDrawings
                                        name={"drawing"}
                                        label={fmt("Content.Labels.Drawing")}
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                </Stack>
                            </TabPanel>
                            <TabPanel
                                value={"1"}
                                sx={{ padding: 0, marginTop: 2 }}
                            >
                                <Stack gap={6}>
                                    <Stack
                                        flexDirection={"column"}
                                        width={"100%"}
                                    >
                                        <Stack
                                            display={"inline-flex"}
                                            flexDirection={"row"}
                                            justifyContent={"space-between"}
                                            width={"100%"}
                                        >
                                            <Typography variant={"body1"}>
                                                {fmt(
                                                    "Content.Labels.Scheduling",
                                                )}
                                            </Typography>
                                            <Switch name={"scheduling"} />
                                        </Stack>
                                        <Typography
                                            variant={"body2"}
                                            color={"sectionTitle.contrastText"}
                                        >
                                            {fmt("Content.Desc.Scheduling")}
                                        </Typography>
                                    </Stack>
                                    {scheduling && (
                                        <>
                                            <SectionTitle
                                                title={fmt(
                                                    "Content.Labels.ResourceConfiguration",
                                                )}
                                            />
                                            <Switch
                                                name="assignResourceType"
                                                label={fmt(
                                                    "Content.Labels.AssignResourceType",
                                                )}
                                                data-testid={
                                                    "Form-Switch-AssignResourceType"
                                                }
                                                sx={switchSx}
                                            />
                                        </>
                                    )}

                                    {assignResourceType && (
                                        <>
                                            <AutocompleteResourceTypes
                                                name="resourceType"
                                                label={fmt(
                                                    "Content.Labels.AssignResourceType",
                                                )}
                                                desc={fmt(
                                                    "Content.Desc.ResourceType",
                                                )}
                                                renderInputProps={{
                                                    variant: "outlined",
                                                }}
                                            />
                                            <Switch
                                                name="assignResource"
                                                label={fmt(
                                                    "Content.Labels.AssignResource",
                                                )}
                                                data-testid={
                                                    "Form-Switch-AssignResource"
                                                }
                                                sx={switchSx}
                                            />
                                        </>
                                    )}
                                    {assignResource && (
                                        <>
                                            <AutocompleteResourcesGeneric
                                                name="resource"
                                                label={fmt(
                                                    "Content.Labels.AssignResource",
                                                )}
                                                desc={fmt(
                                                    "Content.Desc.Resource",
                                                )}
                                                renderInputProps={{
                                                    variant: "outlined",
                                                }}
                                            />
                                            <Switch
                                                name="permanentUse"
                                                label={fmt(
                                                    "Content.Labels.PermanentUse",
                                                )}
                                                data-testid={
                                                    "Form-Switch-permanentUse"
                                                }
                                                sx={switchSx}
                                                caption={fmt(
                                                    "Content.Desc.PermanentUse",
                                                )}
                                            />
                                        </>
                                    )}
                                </Stack>
                            </TabPanel>
                        </>
                    )}
                </TabContext>
            </form>
        </FormProvider>
    );
};
