import { FormikValues } from "formik";

import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter } from "src/api/filters";
import { SubProjectsPath, Url } from "src/api/paths";
import { Type_prj_put_subProject } from "src/api/tms-projects/subProjects/types";

export const indexSubProjects = (
    requestConfig: Type_RequestConfig,
    filters: Type_Filter = {},
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(
        `${Url.PROJECTS}${SubProjectsPath.SUB_PROJECTS}${urlParams}`,
        requestConfig,
    );
};

export const createSubProject = (
    data: FormikValues,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.PROJECTS}${SubProjectsPath.SUB_PROJECTS}`,
        requestConfig,
    );
};

export const updateSubProject = (
    data: Type_prj_put_subProject,
    id: number,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${SubProjectsPath.SUB_PROJECTS}/${id}`,
        requestConfig,
    );
};

export const showSubProject = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${SubProjectsPath.SUB_PROJECTS}/${id}`,
        requestConfig,
    );
};

export const statusSubProject = (
    data: FormikValues,
    id: number,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${SubProjectsPath.SUB_PROJECTS}/${id}`,
        requestConfig,
    );
};

export const deleteSubProject = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.PROJECTS}${SubProjectsPath.SUB_PROJECTS}/${id}`,
        requestConfig,
    );
};

export const selectListSubProjects = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${SubProjectsPath.SUB_PROJECTS_SELECT_LIST}`,
        requestConfig,
    );
};

export const currentWorkspaceSubProject = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${SubProjectsPath.SUB_PROJECTS_CURRENT_WORKSPACE}`,
        requestConfig,
    );
};
