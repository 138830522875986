import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_CommentsContainer = styled(Stack)(({ theme }) => ({
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.tertiary?.main,
    borderTop: theme.border.default,
    borderBottom: theme.border.default,
}));

export const ObservationCommentsList = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations.Comments",
    );

    //TODO: completer

    return (
        <Styled_CommentsContainer>
            <Typography variant="body3" color="secondary">
                {fmt("EmptyComments")}
            </Typography>
        </Styled_CommentsContainer>
    );
};
