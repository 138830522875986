import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

const Styled_Main = styled(Box)(() => ({
    flexDirection: "row",
    position: "relative",
    zIndex: 10,
}));
const Styled_Toolbar = styled(Stack)(({ theme }) => ({
    zIndex: 9000,
    backgroundColor: theme.palette.background.default,
    borderRight: theme.border.default,
    height: "100%",
    width: 48,
    alignItems: "center",
}));

type Type_Props_ToolBarWithDrawer = {
    handleShow: () => void;
    children: ReactNode;
};

export const ToolbarWithDrawer = ({
    handleShow,
    children,
}: Type_Props_ToolBarWithDrawer) => {
    return (
        <Styled_Main data-testid="ToolbarWithDrawer-Main">
            <Styled_Toolbar
                paddingY={1}
                data-testid="ToolbarWithDrawer-Toolbar"
            >
                <IconButton
                    aria-label="areas-sequence-btn"
                    color={"inherit"}
                    size="small"
                    data-testid={`ToolbarWithDrawer-Button-layer-group`}
                    onClick={handleShow}
                >
                    <Icon variant="light" icon="layer-group" />
                </IconButton>
            </Styled_Toolbar>
            {children}
        </Styled_Main>
    );
};
