// Gestion du zoom sur le canvas
import Konva from "konva";
import React, { ReactNode } from "react";
import { Stage as K_Stage } from "react-konva";
import { RectReadOnly } from "react-use-measure";

import { useStageContext } from "./StageContext";
import { Type_point } from "./types";

type Type_Props_Stage = {
    children: ReactNode;
    bounds: RectReadOnly;
};

// gestion du stage avec zoom et déplacement
export const Stage = ({ children, bounds }: Type_Props_Stage) => {
    const { setContextState, stageScale, stageX, stageY, changeSelecting } =
        useStageContext();

    const handleWheel = (e: Konva.KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();

        const scaleBy = 1.2;
        const stage: Konva.Stage | null = e.target.getStage();
        if (stage) {
            const oldScale = stage.scaleX();

            const position = stage.getPointerPosition();
            if (position) {
                const mousePointTo: Type_point = {
                    x: position.x / oldScale - stage.x() / oldScale,
                    y: position.y / oldScale - stage.y() / oldScale,
                };

                const newScale: number =
                    e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

                setContextState((prev) => ({
                    ...prev,
                    stageScale: newScale,
                    stageX:
                        -(mousePointTo.x - position.x / newScale) * newScale,
                    stageY:
                        -(mousePointTo.y - position.y / newScale) * newScale,
                }));
            }
        }
    };

    return (
        <K_Stage
            width={bounds.width}
            height={bounds.height}
            onWheel={handleWheel}
            draggable={true}
            scaleX={stageScale}
            scaleY={stageScale}
            x={stageX}
            y={stageY}
            onClick={() => {
                changeSelecting(-1);
            }}
            onDragEnd={() => {}}
        >
            {children}
        </K_Stage>
    );
};
