import {
    ToggleButton as MuiToggleButton,
    Stack,
    Typography,
} from "@mui/material";
import { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { Styled_ToggleButtonGroup } from "src/components/Components_Common/forms/reactHookFormComponents/ToggleButtonGroup/ToggleButtonGroup.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";

export type Type_ToggleButtonGroupItem = {
    label?: string;
    value: string | number;
    icon?: string;
};

type Type_Props_ToggleButtonGroup = ToggleButtonGroupProps & {
    name: string;
    items: Type_ToggleButtonGroupItem[];
    groupLabel?: string;
};

const handleLabelCase = (label?: string) => {
    if (!label) return;
    const words = label.replace(/([A-Z])/g, " $1");
    return words.trim().replace(/\b\w/g, function (char, index) {
        return index === 0 ? char.toUpperCase() : char.toLowerCase();
    });
};

export const ToggleButtonGroup = ({
    name,
    items,
    groupLabel = "",
    ...props
}: Type_Props_ToggleButtonGroup) => {
    const { control, formState } = useFormContext();

    return (
        <div>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Styled_ToggleButtonGroup
                        {...props}
                        {...field}
                        onChange={(e, value) => {
                            field.onChange(value);
                        }}
                        aria-label={groupLabel}
                    >
                        {items.map(({ value, label, icon }: any) => (
                            <MuiToggleButton
                                key={value}
                                value={value as string}
                                aria-label={label}
                                data-testid={`ToggleButton-${label}`}
                            >
                                <Stack
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                >
                                    {icon && (
                                        <Icon
                                            variant={"solid"}
                                            icon={icon}
                                            fontSize={"large"}
                                        />
                                    )}
                                    <Typography variant={"body1"} margin={0}>
                                        {handleLabelCase(label)}
                                    </Typography>
                                </Stack>
                            </MuiToggleButton>
                        ))}
                    </Styled_ToggleButtonGroup>
                )}
            />
            {formState.errors[name] && (
                <TMC_FormHelperText
                    helperText={formState?.errors[name]?.message as string}
                    inputName={name}
                />
            )}
        </div>
    );
};
