import { Stack } from "@mui/material";
import React, { Children, ReactNode } from "react";

type Type_Props_HeaderGrid = {
    children: ReactNode;
};

export const HeaderGrid = ({ children }: Type_Props_HeaderGrid) => {
    const count = Children.count(children);
    return (
        <Stack
            direction="row"
            justifyContent={count > 1 ? "space-between" : "end"}
            height={56}
            alignItems={"center"}
            paddingY={2}
        >
            {children}
        </Stack>
    );
};
