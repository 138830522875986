import { DEFAULT_COLORS } from "src/theme/stylesheet";

export const sizeFlowGridX: number = 60;
export const sizeFlowGridY: number = 60;
export const widthFlowShapeFixedSize: number = 2;
export const colorFlowGrid: string = "#b2b2b9";
export const colorFlowBackground: string = "#EDEFF3";

export const widthFlowShape: number = 2 * sizeFlowGridX;
export const heightFlowShape: number = sizeFlowGridY;
export const widthFlowShapeStroke: number = 2;
export const colorFlowCircleFill: string = "#FFFFFF";
export const colorFlowCircleStroke: string = "#0A0C0F";
export const widthFlowCircleStroke: number = 1;
export const propsFlowStrokeWidth: number = 1;
export const propsFlowStrokeColor: string = "#D4DAE2";
export const widthFlowLine: number = 2;

export const hitFlowLine: number = 10;

export const paddingFlowText: number = 6;
export const paddingFlowGroup: number = 2;

export const sizePictogramFlowGroup: number = 10;

export const radiusFlowCircleOver: number = 6;
export const radiusFlowCircle: number = 4;
export const minFlowDist: number = 12;

export const colorFlowShapeStroke: string = "rgba(0,0,0,0.2)";
export const defaultTaskName: string = "New task";
export const defaultFirstTaskName: string = "First task";
export const defaultTaskColor: string = DEFAULT_COLORS.task;

export const sizeFlowPictogramPlus: number = 10;
export const sizeFlowPlus: number = 12;
export const strokeFlowPlus: number = 2;
export const colorFlowPlus: string = "#007AFF";
export const colorFlowSelected: string = "#007AFF";
export const marginFlowSelected: number = 10;

export const keyFlowEdit: string = "e";
export const keyFlowMatrix: string = "m";
export const keyFlowTrash: string = "Delete";
export const keyFlowCopy: string = "c";
export const keyFlowShare: string = "s";
export const doubleClick: string = "dbClick";
export const fontFlowFamily: string = "Inter";
export const sizeFlowTaskName: number = 12;
export const fontFlowVariant: string = "monospace uppercase/lining";
export const fontFlowStyleGroup: string = "500";
export const offsetFlowSizeGroup: number = 3;
export const fontFlowSizeGroup: number = 14;
export const strokeFlowGroup: string = "white";
export const fillFlowGroup: string = "#8595AD";

export const colorFlowLink: string = "#8595AD";
export const colorFlowLinkSelected: string = "#007AFF";

export const colorFlowLinkOver: string = "#5e6a79";

export const colorFlowLinkText: string = "#FFFFFF";

export const pictogramFlowI: string =
    "M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z";

export const pictogramFlowPlus: string =
    "M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z";

export const pictogramFlowPen: string =
    "M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z";
