import {
    Type_com_index_unitType,
    Type_com_post_unitType,
    Type_com_put_unitType,
    Type_com_selectList_unitType,
    Type_com_show_unitType,
    Type_index_unitType,
    Type_post_unitType,
    Type_put_unitType,
    Type_show_unitType,
} from "src/api/tms-commons/unitTypes/types";
import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

export const formatterIndexUnitTypes = (
    unitTypes: Type_com_index_unitType[],
): Type_index_unitType[] => {
    if (!unitTypes) return [];
    return unitTypes.map((unitType: Type_com_index_unitType) =>
        formatterIndexUnitType(unitType),
    );
};

export const formatterIndexUnitType = (
    unitType: Type_com_index_unitType,
): Type_index_unitType => {
    return {
        id: unitType.id,
        enabled: unitType.enabled,
        name: unitType.name,
    };
};

export const formatterShowUnitType = (
    unitType: Type_com_show_unitType,
): Type_show_unitType => {
    return unitType;
};

export const formatterCreateUnitType = (
    unitType: Type_post_unitType,
): Type_com_post_unitType => {
    return { names: unitType.names };
};
export const formatterUpdateUnitType = (
    unitType: Type_show_unitType,
): Type_com_put_unitType => {
    return {
        names: unitType.names,
        enabled: unitType.enabled,
    };
};

export const formatterSelectUnitType = (
    unitTypes: Type_com_selectList_unitType[],
): Type_SelectOptionItem[] => {
    if (!unitTypes) return [];
    return unitTypes.map((unitType: Type_com_selectList_unitType) => ({
        value: unitType.id,
        label: unitType.name,
    }));
};

export const formatterStatusUnitType = (
    unitType: Type_put_unitType,
): Type_com_put_unitType => {
    return {
        enabled: unitType.enabled,
    };
};
