import { Box, Stack } from "@mui/material";
import React from "react";

import { mutationCreateArea } from "src/api/tms-projects/areas";
import { Type_post_area } from "src/api/tms-projects/areas/types";
import { Type_CountryCode } from "src/assets/translations";
import { Header, Spinner, TMC_Button } from "src/components";
import { AccordionAreasDnd } from "src/components/Components_Common/accordions/AccordionAreasDnd";
import { useLanguage } from "src/contexts/languages";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_PaperContextualLayout,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

/**
 * @deprecated
 */
export const AreasContextualDrawer = () => {
    const { languages } = useLanguage();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerAreas",
    );
    const { openPaper, closePaper } = useContextualDrawer();

    //create default area
    const { mutateAsync, isLoading } = mutationCreateArea();

    const createNewArea = async (parentId: number | null) => {
        const defaultValues: Type_post_area = {
            names: {},
            areaType: { id: 0, name: "" },
            parent_id: parentId,
        };
        languages.forEach((language: Type_CountryCode) => {
            defaultValues.names[language] = fmt(`DefaultAreaNames.${language}`);
        });
        const resp = await mutateAsync(defaultValues);
        openPaper("area", { id: resp.data.data.id });
    };

    return (
        <>
            <Styled_PaperContextualLayout>
                <Header
                    title={fmt("Areas")}
                    onClose={() => closePaper("areas")}
                    dataTestIdTitle={"Areas-Drawer-Update-Title"}
                />
                <Box padding={2}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                        mb={2}
                    >
                        <TMC_Button
                            data-testid="Areas-Drawer-New-btn"
                            onClick={() => createNewArea(null)}
                            color="primary"
                            disabled={isLoading}
                            endIcon={
                                isLoading && (
                                    <Spinner size="small" style="button" />
                                )
                            }
                        >
                            {fmt("Create")}
                        </TMC_Button>
                    </Stack>
                    <AccordionAreasDnd createNewArea={createNewArea} />
                </Box>
            </Styled_PaperContextualLayout>
        </>
    );
};
