import {
    Type_com_index_projectRole,
    Type_index_projectRole,
} from "src/api/tms-commons/projectRoles/types";

export const formatterIndexProjectRole = (
    data: Type_com_index_projectRole,
): Type_index_projectRole => {
    return {
        id: data.id,
        name: data.name,
        roleType: {
            id: data.roleType.id,
            name: data.roleType.name,
        },
    };
};
export const formatterIndexProjectRoles = (
    data: Type_com_index_projectRole[],
): Type_index_projectRole[] => {
    return data.map((projectRole: Type_com_index_projectRole) =>
        formatterIndexProjectRole(projectRole),
    );
};
