import { Stack, styled, Typography } from "@mui/material";
import React from "react";

import { useUser } from "src/contexts/user";
import { AddCompanyUserForm } from "src/forms/companies/AddCompanyUserForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const AddCompany_Container = styled(Stack)(() => ({
    display: "flex",
    flexDirection: "column",
    maxWidth: "480px",
    height: "84%",
}));

export const Page_AddCompany = () => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Teamoty.AddCompany");
    const { user } = useUser();

    if (!user) return null;

    return (
        <AddCompany_Container data-testid="Page_AddCompany-container">
            <Typography variant="h1" marginBottom={4}>
                {fmt("Title")}
            </Typography>
            <AddCompanyUserForm userId={user.id} />
        </AddCompany_Container>
    );
};
