import {
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuList,
    Popover,
    Skeleton,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
    mutationDeleteSequence,
    mutationStatusSequence,
    useShowSequence,
} from "src/api/tms-scheduling/sequences";
import { formatterIndexToEventSequence } from "src/api/tms-scheduling/sequences/formatters";
import {
    Type_sch_put_sequence,
    Type_show_sequence,
} from "src/api/tms-scheduling/sequences/types";
import { Spinner, TMC_Button } from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    Dialog,
    Type_DialogConfig,
    Type_DialogParams,
} from "src/components/Components_Common/Dialog/Dialog";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { SequenceDuplicationForm } from "src/forms/sequence/SequenceDuplicationForm";
import {
    Type_event_delete,
    Type_event_sequence,
    useChannel,
} from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

const Styled_Toolbar = styled("div")(({ theme }) => ({
    flexShrink: 0,
    alignSelf: "stretch",
    height: 48,
    padding: `0 ${theme.spacing(2)}`,
    borderBottom: theme.border.default,

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

const Styled_SequenceColorChip = styled(Box)(({ theme }) => ({
    height: 24,
    width: 24,
    borderRadius: theme.shape.borderRadiusSmall,
    border: `solid 2px rgba(0, 0, 0, ${theme.opacity.high})`,
}));

type Type_Props_SequencePertToolbar = {
    handleClose: () => void;
    sequenceId: number;
    setFixedSize: Dispatch<SetStateAction<boolean>>;
};

export type Type_modalDeleteSequence = {
    isOpen: boolean;
    props?: Type_show_sequence;
};

export const SequencePertToolbar = ({
    handleClose,
    sequenceId,
    setFixedSize,
}: Type_Props_SequencePertToolbar) => {
    const { lang } = useParams();
    const { sendEvent } = useChannel({});
    const { openPaper } = useContextualDrawer();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.PertDrawer.Toolbar",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    const [dialogParams, setDialogParams] = useState<Type_DialogParams>({
        open: false,
        data: { name: "" },
    });

    const [modalDeleteSequence, setModalDeleteSequence] =
        useState<Type_modalDeleteSequence>({
            isOpen: false,
        });

    const {
        data: sequence = {
            areas: [],
            color: "",
            earliestDate: null,
            id: 0,
            names: {},
            sequenceFolder: { enabled: false, id: 0, name: "" },
            notesCount: 0,
            fixedSize: false,
            enabled: false,
        },
        isFetching,
    } = useShowSequence(sequenceId, "sequencePertDrawer");

    const [state, setState] = useState<Type_show_sequence>(sequence);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [notesCounting, setNotesCount] = useState<number>(0);

    const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

    const { mutateAsync: mutateDeleteSequence } = mutationDeleteSequence(
        state.id,
    );

    const { mutate: mutateStatusSequence } = mutationStatusSequence();

    useChannel({
        eventHandler: ({ event, data: dataEvent }) => {
            if (event === "updateSequence") {
                if ((dataEvent as Type_event_sequence).id === sequence?.id) {
                    setFixedSize((dataEvent as Type_event_sequence).fixedSize);
                }
            }
            if (event === "updateNotesCount") {
                // change flag count with delta (+/-)
                setNotesCount((old) => old + (dataEvent as number));
            }
        },
    });

    //update in real time with useChannel event "updateSequence"
    useChannel({
        eventHandler: ({ event, data }): void => {
            const sequenceData = data as Type_event_sequence;
            if (
                event === "updateSequence" &&
                sequenceData?.id === sequence?.id
            ) {
                if (!sequenceData.enabled) {
                    // ferme le PERT si la sequence est désactivée, car on ne peut pas ouvrir une sequence désactivée, l'action disparait dans le drawer des sequences
                    handleClose();
                } else {
                    setState((prevState: Type_show_sequence) => ({
                        ...prevState,
                        ...formatterIndexToEventSequence(
                            lang as string,
                            sequenceData,
                        ),
                    }));
                }
            }
        },
    });

    useChannel({
        eventHandler: ({ event, data: dataEvent }) => {
            if (event === "deleteSequence") {
                if ((dataEvent as Type_event_delete).id === state.id) {
                    handleClose();
                }
            }
        },
    });

    const handleEdit = (id: number | undefined): void => {
        openPaper("sequence", { id }, true);
    };

    const handleEditNotes = (): void => {
        openPaper("sequenceNotes", {
            sequenceId: state?.id,
            focus: true,
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const id = open ? "simple-popover" : undefined;

    const sortTaskAuto = () => {
        sendEvent("sortTask");
    };

    const handleCloseDialog = (): void => {
        setDialogParams({ open: false, data: {} });
    };

    const handleClickDuplicateSequence = (sequence: Type_show_sequence) => {
        handleClosePopover();
        setDialogParams({
            open: true,
            data: { name: "duplicateSequence", ...sequence },
        });
    };

    const handleStatusSequence = (sequence: Type_show_sequence) => {
        handleClosePopover();
        mutateStatusSequence({
            id: sequence.id,
            enabled: !sequence.enabled,
        } as Type_sch_put_sequence);
    };

    const handleClickDeleteSequence = (sequence: Type_show_sequence) => {
        handleClosePopover();
        setModalDeleteSequence({
            isOpen: true,
            props: sequence,
        });
    };

    const removeSequence = async (id: number) => {
        await mutateDeleteSequence(id);
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteSequence({ isOpen: false });
                removeSequence(modalDeleteSequence?.props?.id as number);
            },
        },
    };

    useEffect(() => {
        if (!isFetching) {
            setState(sequence);
            setNotesCount(sequence.notesCount);
            setFixedSize(!!sequence?.fixedSize);
        }
    }, [isFetching]);

    const dialogConfig: Type_DialogConfig = {
        duplicateSequence: {
            props: {
                open: dialogParams.open,
                onClose: handleCloseDialog,
                dialogTitle: `${fmt("Duplicating")} "${state.names[lang as string] as string}"`,
                name: "duplicateSequence",
                isLoading: isFormLoading,
                children: (
                    <SequenceDuplicationForm
                        setIsFormLoading={setIsFormLoading}
                        dialogParams={dialogParams}
                        setDialogParams={setDialogParams}
                    />
                ),
                actions: (
                    <TMC_Button
                        form={dialogParams?.data?.name}
                        type="submit"
                        size="medium"
                        variant="contained"
                        disabled={isFormLoading}
                        startIcon={
                            isFormLoading ? (
                                <Spinner />
                            ) : (
                                <Icon variant="solid" icon="copy" />
                            )
                        }
                        data-testid={`Dialog-${dialogParams?.data?.name}-btn`}
                    >
                        {fmt("Duplicate")}
                    </TMC_Button>
                ),
            },
        },
    };

    const SkeletonComponent = () => (
        <Stack direction={"row"} gap={2}>
            <Skeleton variant="rounded" height={24} width={24} />
            <Skeleton variant="rounded" height={24} width={100} />
            <Typography variant={"body2"}>/</Typography>
            <Skeleton variant="rounded" height={24} width={100} />
            <Skeleton variant="rounded" height={24} width={24} />
            <Skeleton variant="rounded" height={24} width={24} />
            <Skeleton variant="rounded" height={24} width={24} />
        </Stack>
    );

    return (
        <>
            <Styled_Toolbar data-testid="SequencePertToolbar-Stack">
                <IconButton
                    aria-label="organize-sequence-btn"
                    color={"inherit"}
                    data-testid={"Sequence-SortTask-Btn"}
                    onClick={sortTaskAuto}
                >
                    <Icon
                        icon="light-sitemap-sparkle"
                        variant="kit"
                        sx={{ mr: 1, width: 22 }}
                    />
                    <Typography variant="body2"> {fmt("Organize")}</Typography>
                </IconButton>
                <Stack
                    direction={"row"}
                    flexGrow={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={2}
                    sx={{ userSelect: "none" }}
                >
                    {state.id !== 0 ? (
                        <>
                            <Styled_SequenceColorChip
                                component={"div"}
                                sx={{
                                    backgroundColor: state?.color,
                                }}
                            />
                            <Typography variant={"body2"}>
                                {state?.sequenceFolder?.name} /
                            </Typography>
                            <Typography
                                variant={
                                    state.enabled
                                        ? "body2Medium"
                                        : "body2MediumStrike"
                                }
                                color={
                                    state.enabled ? "inherit" : "text.secondary"
                                }
                            >
                                {state?.names[lang as string]}
                            </Typography>
                            <IconButton
                                data-testid={`SequencePert-Toolbar-Notes-Edit`}
                                color={"inherit"}
                                size={"small"}
                                title={fmt("Notes")}
                                onClick={handleEditNotes}
                            >
                                <Icon
                                    variant={"light"}
                                    icon={"sticky-note"}
                                    fontSize={"small"}
                                />
                                {notesCounting > 0 && (
                                    <Typography
                                        data-testid={`SequencePert-Toolbar-Notes-Count`}
                                        variant="body2"
                                        pl={2}
                                    >
                                        {notesCounting}
                                    </Typography>
                                )}
                            </IconButton>
                            <IconButton
                                onClick={() => handleEdit(state?.id)}
                                color={"inherit"}
                                size="small"
                                title={fmt("Edit")}
                                data-testid={`SequencePert-Toolbar-Edit`}
                            >
                                <Icon
                                    variant="regular"
                                    icon="pen"
                                    fontSize="small"
                                />
                            </IconButton>
                            <IconButton
                                aria-describedby={id}
                                color={"inherit"}
                                size="small"
                                onClick={handleClick}
                                title={fmt("MoreOptions")}
                                data-testid={`SequencePert-Toolbar-Dropdown`}
                            >
                                <Icon
                                    variant="regular"
                                    icon="ellipsis-vertical"
                                    fontSize="small"
                                />
                            </IconButton>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <MenuList
                                    sx={(theme) => ({
                                        width: "fit-content",
                                        color: theme.palette.text.primary,
                                    })}
                                >
                                    <MenuItem
                                        onClick={() =>
                                            handleClickDuplicateSequence(state)
                                        }
                                    >
                                        <ListItemIcon>
                                            <Icon
                                                variant="light"
                                                icon="copy"
                                                data-testid="sequence-more-copy-btn"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {fmtActions("Duplicate")}
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            handleStatusSequence(state)
                                        }
                                    >
                                        <ListItemIcon>
                                            <Icon
                                                variant="light"
                                                icon="circle-dashed"
                                                data-testid="sequence-more-disable-btn"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {fmtActions(
                                                state.enabled
                                                    ? "Disable"
                                                    : "Enable",
                                            )}
                                        </ListItemText>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={() =>
                                            handleClickDeleteSequence(state)
                                        }
                                        sx={(theme) => ({
                                            color: theme.palette.error.main,
                                        })}
                                    >
                                        <ListItemIcon>
                                            <Icon
                                                variant="light"
                                                icon="trash"
                                                data-testid="sequence-more-delete-btn"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {fmtActions("Delete")}
                                        </ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Popover>
                        </>
                    ) : (
                        <SkeletonComponent />
                    )}
                </Stack>
                <IconButton
                    onClick={handleClose}
                    size="small"
                    data-testid={`SequencePert-Toolbar-Close`}
                >
                    <Icon variant={"regular"} icon={"xmark"} />
                </IconButton>
            </Styled_Toolbar>
            <Dialog {...dialogConfig[dialogParams?.data?.name]?.props} />
            <ModalDelete
                open={modalDeleteSequence.isOpen}
                onClose={() => setModalDeleteSequence({ isOpen: false })}
                item={fmt("Sequence")}
                validationString={
                    modalDeleteSequence?.props?.names[lang as string] as string
                }
                actions={actions}
            />
        </>
    );
};
