import Konva from "konva";
import { RefObject, useEffect, useRef } from "react";
import { Group, Path, Tag, Text } from "react-konva";

import {
    fillFlowGroup,
    fontFlowFamily,
    fontFlowSizeGroup,
    fontFlowStyleGroup,
    marginFlowSelected,
    offsetFlowSizeGroup,
    paddingFlowGroup,
    pictogramFlowPen,
    sizePictogramFlowGroup,
    strokeFlowGroup,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";

import { Type_Props_FlowGroup } from "./FlowGroup.type";

export const FlowGroup = ({ task }: Type_Props_FlowGroup) => {
    const x: number = task.pt.x as number;
    const y: number = task.pt.y as number;

    const height: number = fontFlowSizeGroup + offsetFlowSizeGroup;

    const refTag: RefObject<Konva.Tag> = useRef<Konva.Tag>(null);
    const refText: RefObject<Konva.Text> = useRef<Konva.Text>(null);
    const refPath: RefObject<Konva.Path> = useRef<Konva.Path>(null);

    useEffect(() => {
        if (refTag.current && refText.current && refPath.current) {
            const { width: widthText, height: heightText } =
                refText.current.measureSize(refText.current.text());

            refTag.current.width(
                widthText +
                    paddingFlowGroup * 2 +
                    sizePictogramFlowGroup +
                    paddingFlowGroup * 3,
            );
            refTag.current.height(heightText + paddingFlowGroup);

            refPath.current.x(widthText + paddingFlowGroup * 3);
            refPath.current.y(
                (heightText + paddingFlowGroup - sizePictogramFlowGroup) / 2,
            );
        }
    }, [refTag.current, refText.current]);

    return (
        <Group
            x={Math.round(x - marginFlowSelected / 2)}
            y={Math.round(y - height - 5)}
            scaleX={1}
            scaleY={1}
            onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) => {
                changeCursor(e, "pointer");
            }}
            onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) => {
                changeCursor(e, "default");
            }}
        >
            <Tag
                ref={refTag}
                fill={fillFlowGroup}
                cornerRadius={paddingFlowGroup}
            />
            <Text
                ref={refText}
                text={(task.group as string).toString()}
                fontSize={fontFlowSizeGroup}
                fontFamily={fontFlowFamily}
                fontStyle={fontFlowStyleGroup}
                fill={strokeFlowGroup}
                padding={paddingFlowGroup}
                align="left"
                wrap="none"
            />
            <Path
                ref={refPath}
                scaleX={sizePictogramFlowGroup / 512}
                scaleY={sizePictogramFlowGroup / 512}
                data={pictogramFlowPen}
                fill={"white"}
            />
        </Group>
    );
};
