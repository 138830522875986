export const downloadBlobFile = (
    data: BlobPart,
    filename: string,
    type: string,
) => {
    // Create a Blob from the data
    const blob = new Blob([data], {
        type,
    });

    // Create a temporary anchor element
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    // Trigger the download
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

// Function to convert blob URL to base64
export const blobUrlToBase64 = async (blobUrl: string): Promise<string> => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            const base64String = reader.result as string;
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

// Function to get the picture URL or convert it to base64 if it's a blob URL
export const getPictureUrl = async (
    picture: string | { data_url: string }[] | undefined | null,
): Promise<string | undefined | null> => {
    if (typeof picture === "string") {
        // Check if it's a blob URL and convert if necessary
        if (picture.startsWith("blob:")) {
            return await blobUrlToBase64(picture);
        }
        return picture;
    } else if (Array.isArray(picture) && picture.length > 0) {
        return picture[0]?.data_url;
    }

    // undefined or null
    return picture === null ? null : undefined;
};
