import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { ResourcePath, Url } from "src/api/paths";

export const indexResourceTasks = (
    requestConfig: Type_RequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${ResourcePath.RESOURCE_TASKS}`,
        requestConfig,
        null,
        signal,
    );
};

export const createResourceTask = (
    data: any,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${ResourcePath.RESOURCE_TASKS}`,
        requestConfig,
    );
};

export const updateResourceTask = (
    data: any,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${ResourcePath.RESOURCE_TASKS}/${data.id}`,
        requestConfig,
    );
};

export const showResourceTask = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${ResourcePath.RESOURCE_TASKS}/${id}`,
        requestConfig,
    );
};

export const removeResourceTask = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${ResourcePath.RESOURCE_TASKS}/${id}`,
        requestConfig,
    );
};
