import { unix } from "dayjs";
import React, { useState } from "react";

import { TMC_Button } from "src/components/Components_Common";
import { Dialog } from "src/components/Components_Common/Dialog/Dialog";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { usePlanningContext } from "src/contexts/planning";
import { DayOffForm } from "src/forms/dayOff/DayOffForm";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_DialogParamsRightClick } from "./index";

export const DaysOffDialog = () => {
    const { formatMessageWithPartialKey: fmtAction } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { sendEvent } = useChannel({});
    const { toggleDialog, dialogs } = usePlanningContext();

    const [isLoading, setIsLoading] = useState(false);
    const [dialogParams] = useState<Type_DialogParamsRightClick>({
        open: dialogs.models.daysOff,
        action: "create",
        data: {
            defaultDate: dialogs.props?.date?.timestamp
                ? unix(dialogs.props.date.timestamp)
                : null,
        },
    });

    const closeDialog = () => {
        toggleDialog("daysOff");
    };

    const addNewDayOff = (): void => {
        sendEvent("updatePlanningDaysOff");
    };

    const title = `${dialogParams?.data?.id ? fmtAction("Update") : fmtAction("New")} ${fmtLabels("DaysOff")}`;

    return (
        <Dialog
            name="daysOff"
            open={dialogParams.open}
            dialogTitle={title}
            isLoading={isLoading}
            onClose={() => closeDialog()}
            actions={
                <TMC_Button
                    form={"daysOff"}
                    type="submit"
                    size="medium"
                    variant="contained"
                    disabled={isLoading}
                    startIcon={
                        !isLoading && <Icon variant="solid" icon="add" />
                    }
                    data-testid={`dialog-daysOff-save-btn`}
                >
                    {isLoading ? (
                        <Icon variant="solid" icon="spinner-third" spin />
                    ) : (
                        fmtAction("Create")
                    )}
                </TMC_Button>
            }
        >
            <DayOffForm
                defaultValues={{
                    id: Number(dialogParams?.data?.id),
                    startDate: dialogParams?.data?.defaultDate,
                    endDate: dialogParams?.data?.defaultDate,
                }}
                onClose={closeDialog}
                onFormSubmit={addNewDayOff}
                dayOffIdToUpdate={dialogParams?.data?.id}
                setIsLoading={setIsLoading}
                action={dialogParams.action || "create"}
                isOnPlanning={true}
            />
        </Dialog>
    );
};
