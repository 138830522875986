import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Checkbox, Stack, Typography } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateCompany,
    useIndexCompanies,
} from "src/api/tms-commons/companies";
import { formatterIndexCompanies } from "src/api/tms-commons/companies/formatters";
import { searchCompany } from "src/api/tms-commons/companies/services";
import {
    Type_com_index_company,
    Type_index_company,
    Type_show_company,
} from "src/api/tms-commons/companies/types";
import {
    mutationCreateProjectCompany,
    mutationUpdateProjectCompany,
    useShowProjectCompany,
} from "src/api/tms-projects/projectCompanies";
import {
    Type_post_projectCompany,
    Type_show_projectCompany,
} from "src/api/tms-projects/projectCompanies/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { AutocompleteSubTrades } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteSubTrades";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_CompanyForm = Yup.lazy(() => {
    return Yup.object().shape({
        id: Yup.number().nullable(),
        company: Yup.object()
            .typeError(FORM_ERR_FMT.REQUIRED)
            .required(FORM_ERR_FMT.REQUIRED),
        nationalId: Yup.string().nullable(),
        postalCode: Yup.string()
            .trim()
            .max(10, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        city: Yup.string()
            .trim()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        country: Yup.string()
            .trim()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        team: Yup.number().when("subProject", {
            is: (subProject: any) => !!subProject,
            then: (schema) => {
                return schema
                    .transform((value) => (Number.isNaN(value) ? null : value))
                    .min(1, FORM_ERR_FMT.MUST_BE_POSITIF)
                    .nullable();
            },
            otherwise: (schema) => schema.nullable(),
        }),
        subTrades: Yup.array().optional(),
    });
});

type Type_Props_CompanyForm = {
    onClose: () => void;
    action: Type_action;
    companyIdToUpdate: number | null;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    requestConfigParams: AxiosRequestConfig;
};

export const ProjectCompanyForm = ({
    onClose,
    action,
    companyIdToUpdate,
    setIsLoading,
    requestConfigParams,
}: Type_Props_CompanyForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Companies");
    const [showSearch, setShowSearch] = useState<Type_index_company[]>([]);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [disableCompany, setDisableCompany] = useState<boolean>(false);
    const actionCanUpdate = action === "update";
    const isDisabled = disableCompany || actionCanUpdate;

    const { subProject, isLoading: isLoadingSubProject } = useProject();

    const { mutateAsync: mutateCreate } =
        mutationCreateProjectCompany(requestConfigParams) || {};

    const { mutateAsync: mutateCreateCompany } =
        mutationCreateCompany(false) || {};

    const { mutateAsync: mutateUpdate } =
        mutationUpdateProjectCompany(requestConfigParams) || {};

    const { data, isFetching } = useShowProjectCompany(
        companyIdToUpdate as number,
    );

    const { data: companiesList, isFetching: isFetchingCompaniesList } =
        useIndexCompanies() || {};

    const handleSubmit = async (values: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault();

        setIsLoading(true);

        // TODO: isDirty... Beaucoup trop de setValues, refacto à prévoir avec formatter
        if (action === "update") {
            try {
                await mutateUpdate(values as Type_show_projectCompany);
            } catch (_e: any) {
                console.error("Error when updating company");
            }
            onClose();
        } else {
            searchCompany(values.company.name, values.company.id)
                .then(async (data) => {
                    if (
                        !disableCompany &&
                        !confirm &&
                        Array.isArray(data.data.data) &&
                        data.data.data.length > 0
                    ) {
                        setShowSearch(
                            formatterIndexCompanies(
                                data?.data?.data as Type_com_index_company[],
                            ),
                        );
                    } else {
                        try {
                            if (!disableCompany) {
                                mutateCreateCompany({
                                    ...values,
                                    name: values.company.name,
                                } as Type_show_company).then((data) => {
                                    values.id = data?.data?.data.id;
                                    values.technicalName =
                                        data?.data?.data.name;
                                    mutateCreate(
                                        values as Type_post_projectCompany,
                                    );
                                });
                            } else {
                                values.id = values.company.id;
                                values.technicalName = values.company.name;
                                mutateCreate(values);
                            }
                        } catch (_e: any) {
                            console.error("Error when creating company");
                        }
                        onClose();
                    }
                })
                .catch((error) => console.log(error));
        }

        setIsLoading(false);
    };

    const searchCompanyForDisable = (companySelected: {
        id: number;
        name: string;
    }) => {
        if (
            companiesList?.companies?.find(
                (elem) => elem.name === companySelected.name,
            )
        ) {
            setDisableCompany(true);
        } else {
            setDisableCompany(false);
        }
    };

    const form = useForm({
        defaultValues: {
            company: "",
            companyName: "",
            nationalId: "",
            postalCode: "",
            city: "",
            country: "",
            subTrades: subProject ? [] : undefined,
            team: undefined,
            subProject: subProject, // for schema validation only
        },
        values: { ...data, subProject: subProject },
        resolver: yupResolver<any>(Schema_CompanyForm),
    });

    useEffect(() => {
        const company = form.getValues("company");
        const companyName = company?.name || form.getValues("companyName");
        const companyData = companiesList?.companies?.find(
            (company) => company.name === companyName,
        );

        const isCompanyDisabled = !companyName || companyData;

        setDisableCompany(!!isCompanyDisabled);

        if (companyData && !companyIdToUpdate) {
            form.setValue("companyName", companyData.name);
            form.setValue("nationalId", companyData.nationalId || "");
            form.setValue("postalCode", companyData.postalCode);
            form.setValue("city", companyData.city);
            form.setValue("country", companyData.country);
        }
    }, [companiesList, form.watch("company")]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"projectCompanies"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <Stack gap={4}>
                            <AutocompleteFreeSoloDialog
                                label={fmt("CompanyName")}
                                isFetching={isFetchingCompaniesList}
                                multiple={false}
                                options={companiesList?.companies || []}
                                name="company"
                                freeSolo={true}
                                onAddNewValue={(value) => {
                                    form.setValue("company", value);
                                    searchCompanyForDisable(value);
                                }}
                                renderInputProps={{ variant: "outlined" }}
                                disabled={actionCanUpdate}
                            />
                            <TextField
                                name="nationalId"
                                label={fmt(`NationalId`)}
                                disabled={isDisabled}
                            />
                            <TextField
                                name="postalCode"
                                label={fmt(`ZipCode`)}
                                disabled={isDisabled}
                            />
                            <TextField
                                name="city"
                                label={fmt(`CityLabel`)}
                                disabled={isDisabled}
                            />
                            <TextField
                                name="country"
                                label={fmt(`CountryLabel`)}
                                disabled={isDisabled}
                            />
                            {subProject && !isLoadingSubProject && (
                                <TextField
                                    name="team"
                                    label={fmt(`Team`)}
                                    type="number"
                                    InputProps={{ inputProps: { min: 1 } }}
                                />
                            )}
                        </Stack>
                        {subProject && !isLoadingSubProject && (
                            <Stack gap={4}>
                                <Typography>{fmt("AdditionalInfo")}</Typography>
                                <AutocompleteSubTrades
                                    label={fmt("Trades")}
                                    multiple={true}
                                    name="subTrades"
                                    renderInputProps={{ variant: "outlined" }}
                                />
                                {showSearch.length > 0 ? (
                                    <>
                                        <Alert severity="error">
                                            {fmt("CompaniesSimilar")}
                                            <ul>
                                                {showSearch.map(
                                                    (elem, index) => (
                                                        <li key={index}>
                                                            {"• " +
                                                                elem.name +
                                                                ", " +
                                                                elem.city +
                                                                ", " +
                                                                elem.country}
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </Alert>
                                        <Stack
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Checkbox
                                                onClick={() =>
                                                    setConfirm(!confirm)
                                                }
                                                checked={confirm}
                                            />
                                            <Typography mt={2}>
                                                {fmt("ConfirmCompany")}
                                            </Typography>
                                        </Stack>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Stack>
                        )}
                    </Stack>
                )}
            </form>
        </FormProvider>
    );
};
