import { useEffect, useState } from "react";
import { ErrorsType, ImageListType } from "react-images-uploading";

import { getImageQuery } from "src/api/storage";
import { Type_imgType } from "src/api/types";
import { useProject } from "src/contexts/project";

import {
    Type_UploadImageComponentProps,
    UploadImageComponent,
} from "./UploadImage.component";

export type Type_UploadImageContainerProps = Omit<
    Type_UploadImageComponentProps,
    "onChange" | "errors" | "setErrors" | "img" | "deleteDefaultImg"
> & {
    name: string;
    setValue: (
        field: string,
        value: any,
        shouldValidate?: Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
            shouldTouch: boolean;
        }>,
    ) => void;
    host: string;
    service: string;
    imgType: Type_imgType;
    id?: number | undefined; // if undefined, the api getImage is not called
    extraQueryParams?: Record<string, string>;
};

export const UploadImageContainer = ({
    name,
    setValue,
    host,
    service,
    imgType,
    id,
    extraQueryParams,
    ...props
}: Type_UploadImageContainerProps) => {
    const { requestConfig } = useProject();

    const [errors, setErrors] = useState<ErrorsType>(null);
    const [img, setImg] = useState<string>((props?.value as string) || "");

    let cacheTimestamp = extraQueryParams?.t ?? "";
    // Uniquement pour l'api projects
    // On récupère le timestamp qui se trouve à la fin de l'url de l'image renvoyée par l'api
    if (typeof props.value === "string" && service === "projects") {
        cacheTimestamp = props.value.slice(props.value.length - 14);
    }

    const onChange = (imageList: ImageListType | null) => {
        if (errors || imageList === null) {
            console.error(errors);
            setErrors(null);
        }
        setValue(name, imageList); // setValue est le handleChange de react hook form
    };

    const queryParams = new URLSearchParams({
        ...extraQueryParams,
        t: cacheTimestamp,
    });

    const { data } = getImageQuery(
        id as number,
        host,
        service,
        imgType,
        queryParams.toString(),
        !!id,
        requestConfig,
    );

    const deleteDefaultImg = () => {
        setImg("");
        onChange(null);
    };

    useEffect(() => {
        if (!img && id && data) {
            setImg(data as string);
        }
    }, [data]);

    return (
        <UploadImageComponent
            {...props}
            onChange={onChange}
            errors={errors}
            setErrors={setErrors}
            img={img}
            deleteDefaultImg={deleteDefaultImg}
        />
    );
};
