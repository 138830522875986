// Crée un nouvel item ou bien remplace l'existant.
export const setLocalStorageItem = (key: string, value: unknown): void => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (
    key: string,
    needArray?: boolean,
): any | null | [] => {
    const item: string | null = localStorage.getItem(key);

    // Only attempt to parse if the item exists and is a valid string
    if (item) {
        try {
            return JSON.parse(item);
        } catch (_e) {
            return null;
        }
    }

    return needArray ? [] : null;
};

export const removeLocalStorageItem = (key: string): void => {
    localStorage.removeItem(key);
};

export const clearLocalStorage = (): void => {
    localStorage.clear();
};
