import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { Type_index_resourceFamily } from "src/api/tms-commons/resourceFamilies/types";
import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ResourceFamilyForm } from "src/forms/resourceFamily/ResourceFamilyForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_ResourceFamilyDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    resourceFamilyIdToUpdate: number | null;
    action: Type_action;
    page: string;
    names?: { [key: string]: string };
    onFormSubmit?: (resourceFamily: Type_index_resourceFamily) => void;
};

export const ResourceFamilyDrawer = ({
    open,
    onClose,
    resourceFamilyIdToUpdate,
    action,
    page,
    names,
    onFormSubmit,
}: Type_Props_ResourceFamilyDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceFamily",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`ResourceFamily-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"resourceFamily"}
            page={page}
        >
            <ResourceFamilyForm
                onClose={onClose}
                resourceFamilyIdToUpdate={resourceFamilyIdToUpdate}
                setIsLoading={setIsLoading}
                action={action}
                names={names}
                onSubmit={onFormSubmit}
            />
        </DrawerGeneric>
    );
};
