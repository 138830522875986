import { Stack, Typography } from "@mui/material";

import { Type_index_progressHistory } from "src/api/tms-scheduling/progressHistory/types";
import { Avatar } from "src/components/Components_Common/Avatar/Avatar";
import { displayFormattedDateTime } from "src/utils/date";

import { Styled_Container } from "./ProgressionHistory.style";

type Type_Props_ProgressionHistory = {
    progression: Type_index_progressHistory;
};

export const ProgressionHistory = ({
    progression,
}: Type_Props_ProgressionHistory) => {
    const fullName = `${progression.user.firstName} ${progression.user.lastName}`;

    return (
        <Styled_Container>
            <Stack direction="column" spacing={1} flexGrow={1}>
                <Stack direction="row" spacing={1}>
                    <Avatar
                        name={fullName}
                        sx={{
                            width: 20,
                            height: 20,
                            fontSize: "10px",
                        }}
                    />
                    <Typography variant="body2Bold" component="span">
                        {fullName}
                    </Typography>
                    <Typography
                        color="secondary"
                        variant="body2"
                        component="span"
                    >
                        updated the progress to
                    </Typography>
                    <Typography variant="body2Bold" component="span">
                        {progression.value} %
                    </Typography>
                </Stack>
                <Typography color="secondary" variant="body2">
                    {displayFormattedDateTime(progression.date)}
                </Typography>
            </Stack>
        </Styled_Container>
    );
};
