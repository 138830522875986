import { PlanningColDataBegin } from "./ColData/PlanningColDataBegin";
import { PlanningColDataDuration } from "./ColData/PlanningColDataDuration";
import { PlanningColDataEnd } from "./ColData/PlanningColDataEnd";
import { PlanningColDataProgress } from "./ColData/PlanningColDataProgress";
import { Type_PlanningDrawer } from "./Drawer/PlanningDrawer.type";
import { widthPlanningAreaCel } from "./Planning.const";
import {
    Type_Map_PlanningColsDataSize,
    Type_PlanningColDataSize,
} from "./Planning.type";

export const initialPlanningColsDataSize: Type_Map_PlanningColsDataSize =
    new Map<string, Type_PlanningColDataSize>();

initialPlanningColsDataSize.set("begin", {
    show: true,
    width: 100,
    order: 0,
    component: PlanningColDataBegin,
    title: "ColDataTitleBegin",
});
initialPlanningColsDataSize.set("end", {
    show: true,
    width: 100,
    order: 1,
    component: PlanningColDataEnd,
    title: "ColDataTitleEnd",
});
initialPlanningColsDataSize.set("progress", {
    show: true,
    width: 100,
    order: 2,
    component: PlanningColDataProgress,
    title: "ColDataTitleProgress",
});
initialPlanningColsDataSize.set("duration", {
    show: true,
    width: 100,
    order: 3,
    component: PlanningColDataDuration,
    title: "ColDataTitleDuration",
});

export const initialPlanningWorkforceCurve: Type_PlanningDrawer = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
};
export const initialPlanningWorkforceSubTradesTab: Type_PlanningDrawer = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
};

export const initialPlanningWorkforceCompaniesTab: Type_PlanningDrawer = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
};

export const initialPlanningAreas: Type_PlanningDrawer = {
    x: 1,
    y: 0,
    width: widthPlanningAreaCel,
    height: 0,
};
export const initialPlanningColsData: Type_PlanningDrawer = {
    x: 0,
    y: 0,
    width: 3 * widthPlanningAreaCel,
    height: 0,
};
