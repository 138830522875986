import { Type_planningColData } from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import React from "react";
import { Group, Text } from "react-konva";
import { useParams } from "react-router-dom";

import { TextEdit } from "src/components/Components_Common/canvas/TextEdit";
import { formatDate } from "src/components/Components_Teamoty/Planning/tools/dates";

import {
    Type_PlanningColData,
    Type_Props_PlanningColData,
} from "./PlanningColsData.type";

export const PlanningColDataBegin = ({
    areas,
    colsData,
    x,
    y,
    width,
    onChangeValueColData,
}: Type_Props_PlanningColData) => {
    const { lang } = useParams();

    const elmCol: Array<React.JSX.Element> = [];

    areas.forEach((area: Type_PlanningColData, id: string): void => {
        if (colsData.has(id)) {
            const data: Type_planningColData = colsData.get(
                id,
            ) as Type_planningColData;
            const date: Date = new Date(data.begin * 1000);
            elmCol.push(
                onChangeValueColData && data.id ? (
                    <TextEdit
                        key={"pcdb" + id}
                        x={0}
                        y={area.pos}
                        width={width}
                        height={area.height}
                        wrap={"none"}
                        ellipsis={true}
                        verticalAlign={"middle"}
                        align={"center"}
                        type={"date"}
                        text={formatDate(date, lang)}
                        textUnformatted={date.toISOString().substring(0, 10)}
                        format={(value: string): string =>
                            formatDate(new Date(value), lang)
                        }
                        onChange={(value: string): void => {
                            onChangeValueColData("begin", data.id, value);
                        }}
                    />
                ) : (
                    <Text
                        key={"pcdb" + id}
                        x={0}
                        y={area.pos}
                        width={width}
                        height={area.height}
                        wrap={"none"}
                        ellipsis={true}
                        verticalAlign={"middle"}
                        align={"center"}
                        text={formatDate(date, lang)}
                    />
                ),
            );
        }
    });

    return (
        <Group x={x} y={y}>
            {elmCol}
        </Group>
    );
};
