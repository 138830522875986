import { useState } from "react";

import {
    mutationDeleteAreaType,
    mutationStatusAreaType,
    useIndexAreaTypes,
} from "src/api/tms-commons/areaTypes";
import {
    Type_com_put_areaType,
    Type_index_areaType,
} from "src/api/tms-commons/areaTypes/types";

import { Page_AreaTypesComponent } from "./Page_AreaTypes.component";

export type Type_modalDeleteAreaType = {
    isOpen: boolean;
    props?: Type_index_areaType;
};

export const Page_AreaTypesContainer = () => {
    const { isFetching, data: fetchedAreaTypes = [] } =
        useIndexAreaTypes() || {};

    const { mutate: mutationAreaTypeStatus } = mutationStatusAreaType() || {};

    const [modalDeleteAreaType, setModalDeleteAreaType] =
        useState<Type_modalDeleteAreaType>({
            isOpen: false,
        });
    const { mutate: mutateDeleteAreaType } = mutationDeleteAreaType() || {};

    const removeAreaType = (id: number) => {
        mutateDeleteAreaType(id);
    };

    const changeStatus = (areaType: Type_index_areaType) => {
        mutationAreaTypeStatus({
            id: areaType.id,
            enabled: !areaType.enabled,
        } as Type_com_put_areaType);
    };

    return (
        <Page_AreaTypesComponent
            areaTypes={fetchedAreaTypes}
            removeAreaType={removeAreaType}
            changeStatus={changeStatus}
            modalDeleteAreaType={modalDeleteAreaType}
            setModalDeleteAreaType={setModalDeleteAreaType}
            isFetching={isFetching}
        />
    );
};
