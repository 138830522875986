import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ResourceModelForm } from "src/forms/resourceModel/ResourceModelForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_ResourceModelDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    resourceModelIdToUpdate: number | null;
    action: Type_action;
    page: string;
};

export const ResourceModelDrawer = ({
    open,
    onClose,
    resourceModelIdToUpdate,
    action,
    page,
}: Type_Props_ResourceModelDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceModel",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`ResourceModel-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"resourceModel"}
            page={page}
        >
            <ResourceModelForm
                onClose={onClose}
                resourceModelIdToUpdate={resourceModelIdToUpdate}
                setIsLoading={setIsLoading}
                action={action}
            />
        </DrawerGeneric>
    );
};
