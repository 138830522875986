import { Components } from "@mui/material";

export const MuiButtonTheme: Components["MuiButton"] = {
    styleOverrides: {
        root: ({ theme }: any) => ({
            fontWeight: 500,
            textTransform: "inherit",
            boxShadow: "none",
            borderRadius: theme.spacing(1),
            "&.MuiButton-sizeLarge": {
                padding: theme.spacing(2, 4),
                height: 40,
                fontSize: 16,
            },
            "&.MuiButton-sizeMedium": {
                padding: theme.spacing(2, 3),
                height: 32,
                fontSize: 14,
            },
            "&.MuiButton-sizeSmall": {
                padding: theme.spacing(2),
                height: 24,
                fontSize: 12,
            },

            "&.MuiButton-iconSizeLarge": {
                fontSize: 20,
            },
            "&.MuiButton-iconSizeMedium": {
                fontSize: 16,
            },
            "&.MuiButton-iconSizeSmall": {
                fontSize: 12,
            },

            //Color
            "&.MuiButton-colorInheritWhite": {
                boxShadow: "none",
                "&.Mui-disabled": {
                    backgroundColor: theme.palette.inheritWhite.light,
                    color: theme.palette.inheritWhite.contrastText,
                    boxShadow: "none",
                },
            },

            // Variant
            "&.MuiButton-ghost": {
                backgroundColor: "transparent",
                "&.Mui-disabled": {
                    backgroundColor: "transparent",
                },
            },

            "&.MuiButton-ghost.MuiButton-colorInheritWhite": {
                "&.Mui-disabled": {
                    color: theme.palette.inheritWhiteGhost.contrastText,
                    boxShadow: "none",
                },
            },
        }),
    },
    defaultProps: {
        variant: "contained",
    },
};
