import {
    Type_com_put_platformSettings,
    Type_com_show_platformSettings,
    Type_show_platformSettings,
} from "src/api/tms-commons/platformSettings/types";

export const formatterShowPlatformSetting = (
    platformSettings: Type_com_show_platformSettings,
): Type_show_platformSettings => {
    return {
        id: platformSettings.id,
        defaultLanguage: platformSettings.defaultLanguage,
        emailAddresses: platformSettings.emailAddresses.split(","),
    };
};

export const formatterPutPlatformSetting = (
    platformSettings: Type_show_platformSettings,
): Type_com_put_platformSettings => {
    return {
        defaultLanguage: platformSettings.defaultLanguage,
        emailAddresses: platformSettings.emailAddresses.join(","),
    };
};
