import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_PopoverLanguage = styled(Popover)(({ theme }) => ({
    "& .MuiList-root": {
        padding: 0,
        border: "none",
    },
    "& .MuiPaper-root": {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.5, 0),
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        minWidth: "320px",
    },
}));
