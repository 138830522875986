import { Collapse, Switch } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";

import {
    Styled_Box,
    Styled_FormControlLabel,
} from "src/components/Components_Common/CollapseBox/CollapseBox.style";

type Type_CollapseBoxContainerProps = {
    children: ReactNode;
    label?: string;
    callback?: () => void;
    format?: "rounded" | "standard" | undefined;
    value: boolean;
    highlighted: boolean;
    name: string;
};

export const CollapseBox = ({
    children,
    label = "",
    value,
    callback,
    format = "rounded",
    highlighted,
    name,
}: Type_CollapseBoxContainerProps) => {
    const [checked, setChecked] = useState<boolean>(value);

    useEffect(() => {
        if (value !== checked) setChecked(value);
    }, [value]);

    const handleChange = () => {
        setChecked((prev) => !prev);
        if (callback) callback();
    };

    return (
        <Styled_Box ownerState={{ format }}>
            <Styled_FormControlLabel
                data-testid={`CollapseBox-FormControlLabel-${name}`}
                control={
                    <Switch
                        data-testid={`CollapseBox-Switch-${name}`}
                        checked={checked}
                        onChange={handleChange}
                    />
                }
                label={label}
                ownerState={{ highlighted }}
            />
            {children && <Collapse in={checked}>{children}</Collapse>}
        </Styled_Box>
    );
};
