import { ChangePassword_Step_1 } from "./ChangePassword_Steps/ChangePassword_Step_1.component";
import { ChangePassword_Step_2 } from "./ChangePassword_Steps/ChangePassword_Step_2.component";
import { Type_requirements } from "./Page_ChangePassword.container";
import { Styled_Stack_ChildrenChangePassword } from "./Page_ChangePassword.style";

type Type_changePasswordComponentProps = {
    passwordChanged: boolean;
    formik: any;
    requirements: Type_requirements;
    isRequirementsComplete: boolean;
};

export const Page_ChangePasswordComponent = ({
    passwordChanged,
    formik,
    requirements,
    isRequirementsComplete,
}: Type_changePasswordComponentProps) => {
    return (
        <Styled_Stack_ChildrenChangePassword gap={6}>
            {!passwordChanged ? (
                <ChangePassword_Step_1
                    formik={formik}
                    requirements={requirements}
                    isRequirementsComplete={isRequirementsComplete}
                />
            ) : (
                <ChangePassword_Step_2 />
            )}
        </Styled_Stack_ChildrenChangePassword>
    );
};
