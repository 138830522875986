import {
    Type_index_role,
    Type_show_role,
    Type_usr_index_permission,
    Type_usr_index_role,
    Type_usr_put_role,
    Type_usr_selectList_role,
    Type_usr_show_role,
} from "src/api/tms-users/rolesAndPermissions/types";
import { Type_selectList } from "src/api/types";
import { permissionsSelectConfiguration } from "src/configurations/permissionsSelect.configuration";

//ROLES
export const formatterIndexRoles = (
    data: Type_usr_index_role[],
): Type_index_role[] => {
    if (!data) return [];
    return data.map((item: Type_usr_index_role) => formatterIndexRole(item));
};

export const formatterIndexRole = (
    data: Type_usr_index_role,
): Type_index_role => {
    return {
        id: data.id,
        name: data.name,
        enabled: data.enabled,
        roleTypeName: data.roleType.name,
    };
};

// PERMISSIONS
export const filterPermissions = (permissionsArray?: string[]) => {
    if (!permissionsArray) {
        return [];
    }
    let filteredPermissions = [...permissionsArray];

    for (const project of permissionsSelectConfiguration) {
        for (const category of project.categories) {
            for (const subCategory of category.categories) {
                if (
                    !subCategory.permissions.every((p) =>
                        filteredPermissions.includes(p),
                    )
                ) {
                    for (const permissions of subCategory.categories) {
                        for (const permission of permissions.permissions) {
                            if (filteredPermissions.includes(permission))
                                filteredPermissions =
                                    filteredPermissions.filter(
                                        (p) => p !== permission,
                                    );
                        }
                    }
                }
            }
        }
    }
    return filteredPermissions;
};

export const formatterSelectListRoles = (
    roles: Type_usr_selectList_role[],
): Type_selectList[] => {
    if (!roles) return [];
    return roles.map((role: Type_usr_selectList_role) =>
        formatterSelectListRole(role),
    );
};

export const formatterSelectListRole = (
    role: Type_usr_selectList_role,
): Type_selectList => {
    return {
        id: role.id,
        name: role.name,
    };
};

export const formatterShowRole = (data: Type_usr_show_role): Type_show_role => {
    return {
        id: data.id,
        names: data.names,
        role_type_id: data.roleType.id,
        enabled: data.enabled,
        permissions: data.permissions.map(
            (permission: Type_usr_index_permission) => permission.name,
        ),
    };
};

export const formatterUpdateRole = (
    data: Type_show_role,
): Type_usr_put_role => {
    return {
        id: data.id,
        names: data.names,
        role_type_id: data.role_type_id,
        enabled: data.enabled,
        permissions: filterPermissions(data.permissions),
    };
};
