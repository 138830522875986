import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import * as React from "react";
import * as Yup from "yup";

import { useSelectListResourceModels } from "src/api/tms-commons/resourceModels";
import {
    mutationCreateResourceType,
    mutationUpdateResourceType,
    useShowResourceType,
} from "src/api/tms-projects/resourceTypes";
import { formatterPutResourceType } from "src/api/tms-projects/resourceTypes/formatters";
import {
    Type_post_resourceType,
    Type_show_resourceType,
} from "src/api/tms-projects/resourceTypes/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import {
    formatSelectListToSelectOptionItem,
    Select,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

type Type_Props_ResourceTypeForm = {
    onClose: () => void;
    resourceTypeId: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_ResourceType = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.lazy(() => inputMultiLanguagesSchema()),
        resourceModel: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        size: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(1, FORM_ERR_FMT.MUST_BE_POSITIF)
            .nullable(),
    });
});

export const ResourceTypeForm = ({
    onClose,
    resourceTypeId,
    action,
    setIsLoading,
}: Type_Props_ResourceTypeForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceType",
    );

    const [resourceModels, setResourceModels] = useState<
        Type_SelectOptionItem[]
    >([]);

    const { isFetching: isFetchingResourceType, data: resourceType } =
        useShowResourceType(resourceTypeId as number);

    const { isFetching: isFetchingResourceModels, data } =
        useSelectListResourceModels();

    const { mutateAsync: mutateCreate } = mutationCreateResourceType() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateResourceType();

    const form = useTmcForm<Type_show_resourceType | Type_post_resourceType>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: undefined,
            names: {},
            size: undefined,
        },
        values: resourceType,
        resolver: yupResolver<any>(Schema_ResourceType),
        onClose,
        setIsLoading,
        action,
    });

    const isFetching = useMemo(
        () =>
            !!(resourceTypeId && isFetchingResourceType) ||
            isFetchingResourceModels,
        [resourceTypeId, isFetchingResourceType, isFetchingResourceModels],
    );

    useEffect(() => {
        if (data) {
            setResourceModels(formatSelectListToSelectOptionItem(data));
        }
    }, [data]);

    const handleSubmit = (values: any) => {
        try {
            if (action === "update") {
                mutateUpdate(
                    formatterPutResourceType(values as Type_show_resourceType),
                );
            } else {
                delete values.id;
                mutateCreate(values as Type_post_resourceType);
            }
            onClose();
        } catch (e: any) {
            console.log(e);
        }
    };

    return (
        <TmcFormProvider form={form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"resourceType"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <>
                        <Stack gap={4}>
                            <InputMultiLanguages
                                name="names"
                                label={fmt(`Name`)}
                            />
                            <Select
                                name="resourceModel"
                                options={resourceModels}
                                label={fmt("ResourceModel")}
                                data-testid="ResourceType-DrawerContent-resourceModel"
                            />
                            <Stack>
                                <TextField
                                    name="size"
                                    label={fmt(`Size`)}
                                    type="number"
                                    inputProps={{
                                        min: 1,
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    color={COLORS.moon500}
                                    pt={1}
                                >
                                    {fmt("SizeDescription")}
                                </Typography>
                            </Stack>
                        </Stack>
                    </>
                )}
            </form>
        </TmcFormProvider>
    );
};
