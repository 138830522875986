import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ProjectTypesForm } from "src/forms/projectTypes/ProjectTypesForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_ProjectTypeDrawerContainer = {
    open: boolean;
    onClose: () => void;
    projectTypeIdToUpdate: number | null;
    action: Type_action;
    page: string;
};

export const ProjectTypeDrawer = ({
    open,
    onClose,
    projectTypeIdToUpdate,
    action,
    page,
}: Type_Props_ProjectTypeDrawerContainer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectTypes",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            title={
                action === "update"
                    ? fmt("Header.TitleUpdate")
                    : fmt("Header.TitleCreate")
            }
            page={page}
            dataTestIdTitle={`ProjectTypes-Drawer-${action}-Title`}
            isFormLoading={isLoading}
            formId={"projectTypes"}
        >
            <ProjectTypesForm
                onClose={onClose}
                projectTypeIdToUpdate={
                    projectTypeIdToUpdate === null
                        ? undefined
                        : projectTypeIdToUpdate
                }
                action={action}
                setIsLoading={setIsLoading}
            />
        </DrawerGeneric>
    );
};
