import {
    Enum_typeCircleFlow,
    Enum_typeFlowLink,
} from "src/components/Components_Teamoty/Flow/Flow.enum";

export const findTypeFlow = (
    from: Enum_typeCircleFlow | undefined,
    to: Enum_typeCircleFlow | undefined,
): Enum_typeFlowLink => {
    if (
        from === Enum_typeCircleFlow.Finish &&
        to === Enum_typeCircleFlow.Start
    ) {
        return Enum_typeFlowLink.FS;
    }

    if (
        from === Enum_typeCircleFlow.Start &&
        to === Enum_typeCircleFlow.Start
    ) {
        return Enum_typeFlowLink.SS;
    }

    if (
        from === Enum_typeCircleFlow.Finish &&
        to === Enum_typeCircleFlow.Finish
    ) {
        return Enum_typeFlowLink.FF;
    }

    return Enum_typeFlowLink.SF;
};
