import { Stack } from "@mui/material";
import { Form, FormikProvider, FormikValues, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import {
    mutationUpdateSequenceFolderLegacy,
    useShowSequenceFolderLegacy,
} from "src/api/tms-scheduling/sequenceFolders";
import {
    Header,
    LoadingBox,
    SectionTitleWithOptional,
    TMC_Button,
} from "src/components";
import {
    Styled_StackActionsDrawer,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { MultiLanguages } from "src/components/Components_Common/forms";
import { multiLanguagesSchema } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.container";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_PaperContextualLayout,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export type Type_Props_SequenceFolderContextualDrawer = {
    id: number;
};

export const SequenceFolderContextualDrawer = ({
    id: sequenceFolderId,
}: Type_Props_SequenceFolderContextualDrawer) => {
    const { requestConfig } = useProject();
    const { subProjectId } = useParams();

    requestConfig.subProjectId = parseInt(subProjectId as string);
    // requestConfig.subProjectId = 1;
    const { closePaper } = useContextualDrawer();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Sequences.SequenceFolder.Drawer",
    );
    const { formatMessageWithPartialKey: fmtForm } = useCoreIntl("Form");

    const [disable, setDisable] = useState<boolean>(false);
    const [data, setData] = useState({
        id: 0,
        enabled: true,
        names: {},
        sequences: [],
        subProject: {},
    });

    const { isFetching } = useShowSequenceFolderLegacy(
        setData,
        sequenceFolderId,
    );

    const { mutateAsync: mutateUpdate } = mutationUpdateSequenceFolderLegacy(
        () => {},
    );

    useEffect(() => {
        formik.setValues(data);
    }, [data]);

    const handleSubmit = async (values: FormikValues) => {
        setDisable(true);

        try {
            await mutateUpdate(values);
            setDisable(false);
        } catch (e: any) {
            console.log(e);
        }
    };

    const SequenceFolderSchema = Yup.lazy(() => {
        return Yup.object().shape({
            names: Yup.object().shape(multiLanguagesSchema.fields),
        });
    });

    const formik: any = useFormik({
        initialValues: data,
        validationSchema: SequenceFolderSchema,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (
        <Styled_PaperContextualLayout id="testSequenceDetail">
            <Header
                title={fmt("TitleUpdate")}
                onClose={() => closePaper("sequenceFolder")}
                data-testid="SequenceFolder-Drawer-AddSequenceFolder-title"
            />
            <FormikProvider value={formik}>
                <Form>
                    {isFetching && <LoadingBox />}

                    {!isFetching && (
                        <Styled_StackContentDrawer>
                            <SectionTitleWithOptional
                                title={fmt(`LabelName`)}
                            />
                            <Stack>
                                <MultiLanguages
                                    values={formik.values}
                                    handleChange={formik.handleChange}
                                    errors={formik.errors}
                                />
                            </Stack>
                        </Styled_StackContentDrawer>
                    )}

                    <Styled_StackActionsDrawer>
                        <TMC_Button
                            data-testid="SequenceFolder-Drawer-Btn-Leave"
                            variant="text"
                            type="button"
                            onClick={() => closePaper("sequenceFolder")}
                        >
                            {fmtForm(`Cta.Leave`)}
                        </TMC_Button>
                        <TMC_Button
                            data-testid="SequenceFolder-Drawer-Btn-Submit"
                            variant="contained"
                            type="submit"
                            disabled={disable}
                            sx={{ minWidth: 200 }}
                            endIcon={
                                !disable && (
                                    <Icon variant="solid" icon="check" />
                                )
                            }
                        >
                            {disable ? (
                                <Icon
                                    variant="solid"
                                    icon="spinner-third"
                                    spin
                                />
                            ) : (
                                fmtForm(`Cta.Save`)
                            )}
                        </TMC_Button>
                    </Styled_StackActionsDrawer>
                </Form>
            </FormikProvider>
        </Styled_PaperContextualLayout>
    );
};
