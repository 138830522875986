import { Box, Typography } from "@mui/material";
import * as React from "react";
import {
    cloneElement,
    isValidElement,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useParams } from "react-router-dom";

import { Type_show_task } from "src/api/tms-scheduling/tasks/types";
import { TMC_Button } from "src/components";
import {
    Accordion,
    Type_accordionList,
} from "src/components/Components_Common/accordions/Accordion";
import { Advanced } from "src/forms/tasks/AccordionForms/Advanced";
import { AutomaticOptimizationForm } from "src/forms/tasks/AccordionForms/AutomaticOptimizationForm/AutomaticOptimizationForm";
import { DateForm } from "src/forms/tasks/AccordionForms/DateForm";
import { TaskLinks } from "src/forms/tasks/AccordionForms/Links/TaskLinks";
import { SlideOut } from "src/forms/tasks/AccordionForms/SlideOut/SlideOut";
import { WaitingDaysTask } from "src/forms/tasks/AccordionForms/WaitingDays/WaitingDays";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

import { PeopleTask } from "./AccordionForms/PeopleTask/PeopleTask";
import { ProductTypesTask } from "./AccordionForms/ProductTypes/ProductTypesTask";
import { RatioTask } from "./AccordionForms/RatioTask/RatioTask";
import { ResourcesTask } from "./AccordionForms/Resources/ResourcesTask";

const taskFormAccordionExpandedStateKey = "taskFormAccordionExpandedState";

type Type_Props_TaskFormAccordionList = {
    task: Type_show_task;
    readonly?: boolean;
};

export const TaskFormAccordionList = ({
    task,
    readonly = false,
}: Type_Props_TaskFormAccordionList) => {
    const { lang } = useParams();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { openPaper } = useContextualDrawer();

    ///////////////////////////////////////
    ///         States                  ///
    ///////////////////////////////////////
    const [expanded, setExpanded] = useState<string[]>(
        getLocalStorageItem(taskFormAccordionExpandedStateKey, true),
    );

    const taskAccordionTitle = (title: string) => (
        <Typography>{title}</Typography>
    );

    const accordionList: Type_accordionList[] = useMemo(
        () => [
            {
                key: "links",
                title: taskAccordionTitle(fmt("AccordionsTitle.Links")),
                children: (
                    <TaskLinks
                        taskId={task.id}
                        defaultTaskForAutocomplete={{
                            id: task.id,
                            name: `${task.code} - ${task.names[lang as string]}`,
                        }}
                    />
                ),
            },
            {
                key: "date",
                title: taskAccordionTitle(fmt("AccordionsTitle.Date")),
                children: <DateForm />,
            },
            {
                key: "automaticOptimization",
                title: taskAccordionTitle(
                    fmt("AccordionsTitle.AutomaticOptimization"),
                ),
                children: (
                    <AutomaticOptimizationForm sequenceId={task?.sequence.id} />
                ),
            },
            {
                key: "waitingDays",
                title: taskAccordionTitle(fmt("AccordionsTitle.WaitingDays")),
                children: <WaitingDaysTask />,
            },
            {
                key: "ratio",
                title: taskAccordionTitle(fmt("AccordionsTitle.Ratio")),
                children: <RatioTask />,
            },
            {
                key: "slideOut",
                title: taskAccordionTitle(fmt("AccordionsTitle.SlideOut")),
                children: <SlideOut />,
            },
            {
                key: "assignees",
                title: taskAccordionTitle(fmt("AccordionsTitle.Assignees")),
                children: <PeopleTask taskId={task.id} />,
                ...(!readonly && {
                    action: (
                        <TMC_Button
                            data-testid={`List-all-task-people-task-btn`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openPaper("peopleTask", {
                                    taskId: task.id,
                                });
                            }}
                            variant="text"
                            size="small"
                        >
                            {fmtActions("SeeAll")}
                        </TMC_Button>
                    ),
                }),
            },
            {
                key: "resources",
                title: taskAccordionTitle(fmt("AccordionsTitle.Resources")),
                children: <ResourcesTask taskId={task.id} />,
                ...(!readonly && {
                    action: (
                        <TMC_Button
                            data-testid={`List-all-task-resources-btn`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openPaper("resource", {
                                    id: task.id,
                                });
                            }}
                            variant="text"
                            size="small"
                        >
                            {fmtActions("SeeAll")}
                        </TMC_Button>
                    ),
                }),
            },
            {
                key: "materials",
                title: taskAccordionTitle(fmt("AccordionsTitle.Materials")),
                children: <ProductTypesTask taskId={task.id} />,
                ...(!readonly && {
                    action: (
                        <TMC_Button
                            data-testid={`List-all-task-materials-btn`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openPaper("productType", {
                                    id: task.id,
                                });
                            }}
                            variant="text"
                            size="small"
                        >
                            {fmtActions("SeeAll")}
                        </TMC_Button>
                    ),
                }),
            },
            {
                key: "advanced",
                title: taskAccordionTitle(fmt("AccordionsTitle.Advanced")),
                children: <Advanced />,
            },
        ],
        [task.id],
    );

    const handleAccordionChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            event.preventDefault();
            event.stopPropagation();
            setExpanded((prevExpanded) =>
                isExpanded
                    ? [...prevExpanded, panel]
                    : prevExpanded.filter((key) => key !== panel),
            );
        };

    useEffect(() => {
        setLocalStorageItem(taskFormAccordionExpandedStateKey, expanded);
    }, [expanded]);

    return (
        <Box>
            {accordionList.map(
                (item) =>
                    shouldDisplay({
                        name: item.key,
                        taskType: task?.type,
                        type: "accordion",
                    }) && (
                        <Accordion
                            key={item.key}
                            expanded={expanded.includes(item.key)}
                            onChange={handleAccordionChange(item.key)}
                            action={item.action}
                            title={item.title}
                            data-testid={`accordion-${item.key}`}
                            slotProps={{
                                transition: { unmountOnExit: true },
                            }} // Improved performance, see https://v5.mui.com/material-ui/react-accordion/#performance
                        >
                            {isValidElement(item.children)
                                ? cloneElement(item.children, {
                                      readonly,
                                  })
                                : item.children}
                        </Accordion>
                    ),
            )}
        </Box>
    );
};
