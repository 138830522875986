import { DrawerProps } from "@mui/material";
import React, { useState } from "react";

import { Type_show_platformSettings } from "src/api/tms-commons/platformSettings/types";
import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { PlatformSettingsForm } from "src/forms/platformSettings/PlatformSettingsForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_PlatformSettingsDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    platformSetting?: Type_show_platformSettings;
    page: string;
    isFetching: boolean;
};

export const PlatformSettingsDrawer = ({
    open,
    onClose,
    platformSetting,
    page,
    isFetching,
}: Type_Props_PlatformSettingsDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PlatformSettings",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`Platform-Settings-Drawer-edit-Title`}
            title={fmt("TitleUpdate")}
            isFormLoading={isLoading}
            formId={"platformSettings"}
            page={page}
        >
            <PlatformSettingsForm
                onClose={onClose}
                platformSetting={platformSetting}
                setIsLoading={setIsLoading}
                isFetching={isFetching}
            />
        </DrawerGeneric>
    );
};
