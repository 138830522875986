import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import { ThemeSwitcher } from "src/components";
import { Autocompletes } from "src/pages/Pages_Common/Page_DemoProject/components/Autocompletes";
import { KonvaFlow } from "src/pages/Pages_Common/Page_Demo/components/canvas/KonvaFlow";
import { PlanningDemo } from "src/pages/Pages_Common/Page_Demo/components/PlanningDemo";
import { PrintDemo } from "src/pages/Pages_Common/Page_DemoProject/components/PrintDemo";
import { PlanningProvider, PlanningTypes } from "src/contexts/planning";

type Type_demoList = { label: string; component: React.ReactNode };

export const Page_DemoProject = () => {
    const demoList: Type_demoList[] = [
        { label: "Autocompletes", component: <Autocompletes /> },
        {
            label: "pert",
            component: <KonvaFlow />,
        },
        {
            label: "planning",
            component: <PlanningDemo />,
        },
        {
            label: "Print",
            component: <PrintDemo />,
        },
    ];

    const [show, setShow] = useState<{ [key: string]: boolean }>({
        Autocompletes: false,
    });

    const handleChange = (name: string, value: boolean) => {
        setShow((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <PlanningProvider type={PlanningTypes.TIME_LOCATION}>
            <Box
                gap={4}
                sx={(theme) => ({
                    backgroundColor: theme.palette.background.default,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                })}
            >
                <ThemeSwitcher />
                <Box
                    sx={{
                        p: 4,
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                    }}
                >
                    {demoList.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Switch
                                    name={item.label}
                                    checked={show[item.label] ?? false}
                                    onChange={(e) =>
                                        handleChange(
                                            item.label,
                                            e.target.checked,
                                        )
                                    }
                                />
                            }
                            label={
                                <Typography variant="body1Bold" sx={{ px: 2 }}>
                                    {item.label}
                                </Typography>
                            }
                        />
                    ))}
                </Box>
                <Box
                    sx={{
                        p: 4,
                        overflow: "auto",
                    }}
                >
                    {demoList.map(
                        (item, index) =>
                            show &&
                            show[item.label] && (
                                <div key={index}>{item.component}</div>
                            ),
                    )}
                </Box>
            </Box>
        </PlanningProvider>
    );
};
