import { Stack, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";

import {
    LINK_DIRECTION,
    Type_index_taskLink,
} from "src/api/tms-scheduling/taskLinks/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { Enum_typeFlowLink } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_Content = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: theme.border.default,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    "&:hover": {
        backgroundColor: theme.palette.common.greyLight,
    },
}));

const Styled_Link = styled(Stack)<Type_Props_TaskContainer>(
    ({ type, theme }) => ({
        position: "relative",
        padding: `0px ${theme.spacing(1.5)}`,
        gap: theme.spacing(1),
        "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            width: theme.spacing(0.5),
            background:
                type === LINK_DIRECTION.FROM
                    ? theme.palette.common.red
                    : theme.palette.common.blue,
            borderRadius: theme.shape.borderRadius,
        },
    }),
);

const Styled_LinkLine = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
}));

const Styled_AreaTitle = styled(Stack)(() => ({
    flexDirection: "row",
    alignItems: "center",
}));

type Type_Props_TaskLink = Type_ReadonlyComponent & {
    link: Type_index_taskLink;
    type: LINK_DIRECTION;
    setOpen: (open: boolean) => void;
    setLinkId: (linkId: number | undefined) => void;
    setDirection: (direction: LINK_DIRECTION) => void;
    areaId?: number;
};
type Type_Props_TaskContainer = {
    type: LINK_DIRECTION.FROM | LINK_DIRECTION.TO;
};
export const TaskLink = ({
    link,
    type,
    readonly,
    setOpen,
    setLinkId,
    setDirection,
}: Type_Props_TaskLink) => {
    const { checkPermission } = useUser();
    const theme = useTheme();
    const CAN_UPDATE_LINK = checkPermission("taskLinks", "update") && !readonly;
    const [isHovered, setIsHovered] = useState(false);

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Links",
    );
    const { formatMessageWithPartialKey: fmtLinks } = useCoreIntl(
        "Project.Views.Planning.DrawerLinks",
    );

    const [taskDirection] = useState<"taskTo" | "taskFrom">(
        type === LINK_DIRECTION.FROM ? "taskFrom" : "taskTo",
    );

    const openDialogUpdateLink = () => {
        setLinkId(link.id);
        setDirection(type);
        setOpen(true);
    };

    return (
        <Styled_Content
            data-testid={`TaskLink-Update-${link.id}`}
            {...(!readonly && {
                onClick: openDialogUpdateLink,
                onMouseEnter: () => setIsHovered(true),
                onMouseLeave: () => setIsHovered(false),
            })}
            sx={{ cursor: CAN_UPDATE_LINK ? "pointer" : "default" }}
        >
            <Stack direction="row" alignItems={"flex-start"}>
                <Stack gap={1}>
                    <Styled_Link type={type}>
                        <Styled_LinkLine>
                            {/** Task Code */}
                            {link[taskDirection]?.code && (
                                <Typography variant="body2">
                                    {link[taskDirection].code}
                                </Typography>
                            )}
                            {/** Task Name */}
                            <Typography variant="body2Bold">
                                {link[taskDirection]?.name || fmt("NewLink")}
                            </Typography>
                        </Styled_LinkLine>

                        {/** Sequence */}
                        {link[taskDirection]?.sequence && (
                            <Styled_LinkLine>
                                {link[taskDirection].sequence.code && (
                                    <Typography variant="body3">
                                        {link[taskDirection].sequence.code}
                                    </Typography>
                                )}
                                <Typography variant="body3Medium">
                                    {link[taskDirection].sequence.name}
                                </Typography>
                            </Styled_LinkLine>
                        )}

                        {/** LinkType, WaitingDay, Quantity */}
                        <Stack direction="row" gap={1}>
                            <Typography variant="body3">
                                {fmtLinks(
                                    `Types.${Enum_typeFlowLink[link.linkType]}`,
                                )}
                            </Typography>
                            {link.waitingDay > 0 && (
                                <Typography variant="body3">
                                    - {link.waitingDay}{" "}
                                    {link.dayMode == 1
                                        ? fmt("DaysMode.WorkingDays")
                                        : fmt("DaysMode.CalendarDays")}
                                </Typography>
                            )}

                            {link.quantity > 0 && (
                                <Typography variant="body3">
                                    - {link.quantity} u
                                </Typography>
                            )}
                        </Stack>
                    </Styled_Link>

                    {/** Areas */}
                    {link.areaFrom && (
                        <Stack>
                            <Typography
                                color={theme.palette.text.secondary}
                                variant="body3"
                            >
                                {fmtLinks("FromArea")}
                            </Typography>
                            <Styled_AreaTitle>
                                <Typography variant="body3Medium">
                                    {link.areaFrom.name}
                                </Typography>
                            </Styled_AreaTitle>
                        </Stack>
                    )}
                    {link.areaTo && (
                        <Stack>
                            <Typography
                                color={theme.palette.text.secondary}
                                variant="body3"
                            >
                                {fmtLinks("ToArea")}
                            </Typography>
                            <Styled_AreaTitle>
                                <Typography variant="body3Medium">
                                    {link.areaTo.name}
                                </Typography>
                            </Styled_AreaTitle>
                        </Stack>
                    )}
                </Stack>
            </Stack>
            {isHovered && CAN_UPDATE_LINK && (
                <Icon
                    icon="pen"
                    data-testid={`TaskLink-Update-Icon-${link.id}`}
                    variant="light"
                    sx={{ color: theme.palette.text.secondary }}
                />
            )}
        </Styled_Content>
    );
};
