import { typeMilestones } from "@cimba-digital-construction-solution/utils/dist/decoder/const";

import { Type_Props_getWidth } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

export const getWidth = ({ task, widthDate }: Type_Props_getWidth): number => {
    return typeMilestones.includes(task.type)
        ? Math.round(widthDate)
        : Math.round(
              task.endDate.pos -
                  task.beginDate.pos +
                  (task.endOffset - task.beginOffset) * widthDate,
          );
};
