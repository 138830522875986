import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Stack,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { generatePath, Link, useParams } from "react-router-dom";

import { useImageDrawing } from "src/api/tms-projects/drawing";
import { Type_index_drawing } from "src/api/tms-projects/drawing/types";
import defaultImg from "src/assets/images/logoT.png"; // TODO replace by a better default image
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { URL_TEAMOTY_PROJECT } from "src/router";

const Styled_Card = styled(Card)(() => ({
    width: 372,
    minWidth: 275,
}));

const Styled_CardMedia = styled(CardMedia)(() => ({
    maxHeight: 160,
})) as typeof CardMedia;

type Type_Props_DrawingCard = {
    drawing: Type_index_drawing;
};

export const DrawingCard = ({ drawing }: Type_Props_DrawingCard) => {
    const urlParams = useParams();
    const drawingPath = generatePath(
        `${URL_TEAMOTY_PROJECT}/:subProjectId/drawings/:drawingId`,
        {
            ...(urlParams as { subProjectId: string }),
            drawingId: drawing.id as unknown as string,
        },
    );

    const { data: drawingImg, isLoading } = useImageDrawing(drawing);

    return (
        <Styled_Card>
            <CardActionArea component={Link} to={drawingPath}>
                <Styled_CardMedia
                    component="img"
                    image={isLoading ? defaultImg : (drawingImg as string)}
                    alt="Building"
                />
                <CardContent>
                    <Stack direction={"row"} spacing={2}>
                        <Icon variant="solid" icon="vector-polygon" />
                        <Typography variant="h2" gutterBottom>
                            {drawing.name}
                        </Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Styled_Card>
    );
};
