import * as React from "react";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useSelectListUnits } from "src/api/tms-commons/units";
import { Type_selectList } from "src/api/types";
import {
    formatSelectListToSelectOptionItem,
    Select,
    Type_Props_Select,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

type Type_Props_SelectUnits = Omit<Type_Props_Select, "options">;

export const SelectUnits = ({ ...props }: Type_Props_SelectUnits) => {
    const { getValues } = useFormContext(); // retrieve those props

    // On first render, show current time model value
    const initialValue: Type_SelectOptionItem[] = useMemo(() => {
        const nameParts = props.name.split(".");
        if (nameParts.length === 2) {
            const labelName = nameParts[0];
            return [
                {
                    value: getValues(props.name),
                    label: getValues(`${labelName}.name`),
                },
            ];
        }
        return [];
    }, []);

    ///////////////////////////////
    /// States                  ///
    ///////////////////////////////
    const [open, setOpen] = useState<boolean>(false);

    ///////////////////////////////
    /// Queries                 ///
    ///////////////////////////////

    const { data } = useSelectListUnits(open);

    return (
        <Select
            {...props}
            options={
                data
                    ? formatSelectListToSelectOptionItem(
                          data as Type_selectList[],
                      )
                    : initialValue
            }
            {...props}
            SelectProps={{
                open: open,
                onOpen: () => {
                    setOpen(true);
                },
                onClose: () => {
                    setOpen(false);
                },
            }}
        />
    );
};
