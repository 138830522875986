import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { UnitTypeKeys } from "src/api/tms-commons/keys";
import {
    formatterCreateUnitType,
    formatterIndexUnitType,
    formatterIndexUnitTypes,
    formatterSelectUnitType,
    formatterShowUnitType,
    formatterStatusUnitType,
    formatterUpdateUnitType,
} from "src/api/tms-commons/unitTypes/formatters";
import {
    createUnitType,
    deleteUnitType,
    indexUnitTypes,
    selectListUnitTypes,
    showUnitType,
    statusUnitType,
    updateUnitType,
} from "src/api/tms-commons/unitTypes/services";
import {
    Type_index_unitType,
    Type_post_unitType,
    Type_put_unitType,
    Type_show_unitType,
} from "src/api/tms-commons/unitTypes/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexUnitTypes = () => {
    return useQuery({
        queryKey: [UnitTypeKeys.INDEX],
        queryFn: indexUnitTypes,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexUnitTypes");
            }

            return formatterIndexUnitTypes(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useShowUnitType = (id: number) => {
    return useQuery([UnitTypeKeys.SHOW, id], () => showUnitType(id), {
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowUnitType");
            }

            return formatterShowUnitType(data.data.data);
        },
        enabled: !!id,
        refetchOnWindowFocus: false,
        onError: (err) => {
            return err;
        },
    });
};

export const mutationCreateUnitType = (
    callback?: (unitType: Type_index_unitType) => void,
) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.UnitTypes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newUnitType: Type_post_unitType) =>
            createUnitType(formatterCreateUnitType(newUnitType)),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateUnitType");
            }

            const unitType: Type_index_unitType = formatterIndexUnitType(
                data.data.data,
            );

            await queryClient.invalidateQueries([UnitTypeKeys.INDEX]);

            if (typeof callback === "function") {
                await queryClient.invalidateQueries([UnitTypeKeys.SELECT_LIST]);

                callback(unitType);
            }

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        unitType: unitType.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateUnitType = () => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.UnitTypes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (unitType: Type_show_unitType) =>
            updateUnitType(unitType.id, formatterUpdateUnitType(unitType)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateUnitType");
            }

            const unitType: Type_index_unitType = formatterIndexUnitType(
                data.data.data,
            );

            await queryClient.invalidateQueries([UnitTypeKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        unitType: unitType.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusUnitType = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newStatus: Type_put_unitType) => {
            return statusUnitType(
                newStatus.id,
                formatterStatusUnitType(newStatus),
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusUnitType");
            }

            await queryClient.invalidateQueries([UnitTypeKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useSelectListUnitTypes = () => {
    return useQuery({
        queryKey: [UnitTypeKeys.SELECT_LIST],
        queryFn: selectListUnitTypes,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListUnitTypes");
            }
            return formatterSelectUnitType(data.data.data);
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationDeleteUnitType = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.UnitTypes");

    const queryClient = useQueryClient();

    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => deleteUnitType(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteUnitType");
            }
            await queryClient.invalidateQueries({
                queryKey: [UnitTypeKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
