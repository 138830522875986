import { InputButtonComponent } from "./InputButton.component";

export type InputButtonPropsType = {
    label: string;
    onClick?: any;
    props?: any;
    type?: string;
};

export const InputButtonContainer = (props: InputButtonPropsType) => {
    return <InputButtonComponent {...props} />;
};
