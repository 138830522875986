import { Box, BoxProps, Stack, StackProps, styled } from "@mui/material";

type Type_Styled_BoxProps = {
    ownerState: {
        color: string;
    };
};

export const Styled_BoxCategoryCollapse = styled(Box)<
    Type_Styled_BoxProps & BoxProps
>(({ theme, ownerState }) => ({
    padding: `0 0 0 ${theme.spacing(2)}`,
    borderLeft: `4px solid ${ownerState.color}`,
    color: ownerState.color,
    FlexDirection: "column",
    flexWrap: "nowrap",
}));

export const Styled_StackCategoryCollapse = styled(Stack)<StackProps>(() => ({
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
}));

export const Styled_StackLabelCategoryCollapse = styled(Stack)<StackProps>(
    () => ({
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        flexGrow: 1,
    }),
);
