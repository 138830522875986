import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_Container = styled(Stack)(({ theme }) => ({
    // cursor: "pointer", // remove temporary until feature available
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "row",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
        backgroundColor: theme.palette.common.greyLight,
    },
}));
