import { useState } from "react";

import {
    mutationDeleteResourceFamily,
    mutationStatusResourceFamily,
    useIndexResourceFamilies,
} from "src/api/tms-commons/resourceFamilies";
import { Type_index_resourceFamily } from "src/api/tms-commons/resourceFamilies/types";

import { Page_ResourceFamiliesComponent } from "./Page_ResourceFamilies.component";

export type Type_modalDeleteResourceFamily = {
    isOpen: boolean;
    props?: Type_index_resourceFamily;
};

export const Page_ResourceFamiliesContainer = () => {
    const { isFetching, data } = useIndexResourceFamilies() || {};

    const { mutate: mutateResourceFamilyStatus } =
        mutationStatusResourceFamily() || {};

    const [modalDeleteResourceFamily, setModalDeleteResourceFamily] =
        useState<Type_modalDeleteResourceFamily>({
            isOpen: false,
        });

    const { mutateAsync: mutateDeleteResourceFamily } =
        mutationDeleteResourceFamily() || {};

    const removeResourceFamily = async (id: number) => {
        await mutateDeleteResourceFamily(id);
    };

    const changeStatus = async (resourceFamily: Type_index_resourceFamily) => {
        mutateResourceFamilyStatus({
            id: resourceFamily.id,
            enabled: !resourceFamily.enabled,
        });
    };

    return (
        <Page_ResourceFamiliesComponent
            isFetching={isFetching}
            deleteResourceFamily={removeResourceFamily}
            resourceFamilies={data || []}
            changeStatus={changeStatus}
            modalDeleteResourceFamily={modalDeleteResourceFamily}
            setModalDeleteResourceFamily={setModalDeleteResourceFamily}
        />
    );
};
