import { ChipProps } from "@mui/material";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

import { Styled_Chip } from "./Chip.style";

export type Type_Props_Chip = ChipProps & {
    label: string | React.ReactElement;
    backgroundColor?: string;
    icon?: React.ReactElement;
    tradeCode?: boolean;
    tradeLabel?: boolean;
    isTag?: boolean;
    checked?: boolean;
    highlighted?: boolean;
};

/**
 * @deprecated use component app/src/components/Components_Common/Chip/Chip
 */
export const Chip = ({
    backgroundColor,
    label,
    tradeCode,
    tradeLabel,
    isTag,
    checked = false,
    icon,
    highlighted,
    ...restProps
}: Type_Props_Chip & ChipProps) => {
    const ChipColor = backgroundColor || COLORS.grey;

    return (
        <Styled_Chip
            {...restProps}
            label={label}
            ownerState={{
                highlighted,
                chipBackgroundColor: ChipColor,
                chipTextColor: invertColor(ChipColor),
                tradeCode,
                tradeLabel,
                isTag,
            }}
            icon={
                icon ?? checked ? (
                    <Icon variant="solid" icon="check" />
                ) : undefined
            }
        />
    );
};
