import { LinkProps } from "@mui/material";
import { ReactNode } from "react";

import { Styled_Link, Styled_SmallLink } from "./Link.style";

type sizeType = "small" | "inehrit";
type LinkPropsType = {
    children: ReactNode;
    size?: sizeType;
};

export const Link = ({
    children,
    size,
    ...restProps
}: LinkPropsType & LinkProps) => {
    if (size === "small")
        return <Styled_SmallLink {...restProps}>{children}</Styled_SmallLink>;
    else return <Styled_Link {...restProps}>{children}</Styled_Link>;
};
