import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_requirements = {
    MinimumLength: boolean;
    Alphanumeric: boolean;
    MustBeSame: any;
};

export type Type_passwordRequirements = {
    password: string;
    passwordConfirm: string;
    setIsRequirementsComplete?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PasswordRequirements = ({
    password,
    passwordConfirm,
    setIsRequirementsComplete,
}: Type_passwordRequirements) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("ChangePassword");
    const [requirements, setRequirements] = useState<Type_requirements>({
        MinimumLength: false,
        Alphanumeric: false,
        MustBeSame: false,
    });

    useEffect(() => {
        setRequirements({
            MinimumLength: password.length >= 16,
            Alphanumeric: /.*[a-zA-Z].*\d.*|.*\d.*[a-zA-Z].*/.test(password),
            MustBeSame: password === passwordConfirm,
        });
    }, [password, passwordConfirm]);

    useEffect(() => {
        setIsRequirementsComplete?.(
            requirements.Alphanumeric &&
                requirements.MinimumLength &&
                requirements.MustBeSame,
        );
    }, [requirements]);

    return (
        <Stack gap={1} data-testid="PasswordRequirement-container">
            <Stack direction="row" spacing={1}>
                {requirements.MinimumLength ? (
                    <Icon variant="light" icon="circle-check" color="success" />
                ) : (
                    <Icon variant="solid" icon="circle-xmark" color="error" />
                )}
                <Typography
                    variant="body2"
                    color={
                        requirements.MinimumLength
                            ? "success.main"
                            : "error.main"
                    }
                    data-testid="PasswordRequirement-MinimumLength"
                >
                    {fmt(`Step1.Form.Requirements.MinimumLength`)}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
                {requirements.Alphanumeric ? (
                    <Icon variant="light" icon="circle-check" color="success" />
                ) : (
                    <Icon variant="solid" icon="circle-xmark" color="error" />
                )}
                <Typography
                    variant="body2"
                    color={
                        requirements.Alphanumeric
                            ? "success.main"
                            : "error.main"
                    }
                    data-testid="PasswordRequirement-Alphanumeric"
                >
                    {fmt(`Step1.Form.Requirements.Alphanumeric`)}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
                {password.length && requirements.MustBeSame ? (
                    <Icon variant="light" icon="circle-check" color="success" />
                ) : (
                    <Icon variant="solid" icon="circle-xmark" color="error" />
                )}
                <Typography
                    variant="body2"
                    color={
                        password.length && requirements.MustBeSame
                            ? "success.main"
                            : "error.main"
                    }
                    data-testid="PasswordRequirement-MustBeSame"
                >
                    {fmt(`Step1.Form.Requirements.MustBeSame`)}
                </Typography>
            </Stack>
        </Stack>
    );
};
