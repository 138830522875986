import { useQuery } from "react-query";

import { formatterSelectListAreaTypes } from "src/api/tms-commons/areaTypes/formatters";
import { selectListProjectAreaTypes } from "src/api/tms-projects/areaTypes/services";
import { AreaTypeKeys } from "src/api/tms-projects/keys";
import { useProject } from "src/contexts/project";

export const useSelectListProjectAreaTypes = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [AreaTypeKeys.SELECT_LIST_PROJECT, requestConfig],
        queryFn: () => selectListProjectAreaTypes(requestConfig),
        refetchOnWindowFocus: false,
        enabled: !!requestConfig.projectId,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useSelectListProjectAreaTypes",
                );
            }
            return formatterSelectListAreaTypes(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};
