import {
    Type_index_subProject,
    Type_post_subProject,
    Type_prj_index_subProject,
    Type_prj_post_subProject,
    Type_prj_put_subProject,
    Type_prj_selectList_subproject,
    Type_prj_show_subProject,
    Type_prj_show_subProjectWithCurrentWorkspace,
    Type_put_default_subProject,
    Type_put_subProject,
    Type_selectList_subProject,
    Type_show_subProject,
    Type_show_subProjectWithCurrentWorkspace,
} from "src/api/tms-projects/subProjects/types";
import { displayFormattedDate } from "src/utils/date";
import { getPictureUrl } from "src/utils/file";

export const formatterIndexSubProjects = (
    subProjects: Type_prj_index_subProject[],
): Type_index_subProject[] => {
    if (!subProjects) return [];

    return subProjects.map((subProject: Type_prj_index_subProject) =>
        formatterIndexSubProject(subProject),
    );
};

export const formatterIndexSubProject = (
    subProject: Type_prj_index_subProject,
): Type_index_subProject => {
    return {
        id: subProject.id,
        name: subProject.name,
        enabled: subProject.enabled,
        dateCreated: displayFormattedDate(subProject.dateCreated),
    };
};

export const formatterShowSubProject = (
    subProject: Type_prj_show_subProject,
): Type_show_subProject => {
    return {
        id: subProject.id,
        name: subProject.name,
        optimizedFlow: subProject.optimizedFlow,
        minDuration: subProject.minDuration,
        timeModel: subProject.timeModel,
        versionLimit: subProject.versionLimit,
        workingDays: subProject.workingDays,
        exportTitle: subProject.exportTitle,
        exportSubTitle: subProject.exportSubTitle,
        headerBackgroundColor: subProject.headerBackgroundColor,
        updatedAt: subProject.updated_at,
    };
};

/**
 * @deprecated
 * use formatterUpdateSubProject instead
 * @param subProject
 */
export const formatterUpdatePdfSettings = async (
    subProject: Type_put_subProject,
): Promise<Type_prj_put_subProject> => {
    const { headerBackgroundColor, leftPicture, rightPicture } = subProject;
    const exportTitle = subProject.exportTitle || null;
    const exportSubTitle = subProject.exportSubTitle || null;

    // Convert leftPicture and rightPicture if necessary
    const leftPictureBase64 = await getPictureUrl(leftPicture);
    const rightPictureBase64 = await getPictureUrl(rightPicture);

    return {
        exportTitle,
        exportSubTitle,
        headerBackgroundColor,
        ...(leftPictureBase64 !== undefined
            ? // si leftPicture undefined, on conserve l'image précédente, si null, on la supprime
              { leftPicture: leftPictureBase64 }
            : null),
        ...(rightPictureBase64 !== undefined
            ? // si leftPicture undefined, on conserve l'image précédente, si null, on la supprime
              { rightPicture: rightPictureBase64 }
            : null),
    };
};

export const formatterCreateSubProject = (
    subProject: Type_post_subProject,
): Type_prj_post_subProject => {
    return {
        name: subProject.name,
    };
};

export const formatterUpdateSubProject = (
    subProject: Type_put_subProject,
): Type_prj_put_subProject => {
    // todo: traitement sur les images dans le cas où ce formatter sera générique
    const { ...rest } = subProject;
    return {
        ...(rest as Type_prj_put_subProject),
    };
};

/**
 * @deprecated
 * use formatterUpdateSubProject instead
 * @param subProject
 */
export const formatterUpdateDefaultSettings = (
    subProject: Type_put_default_subProject,
): Type_prj_put_subProject => {
    return {
        optimizedFlow: subProject.optimizedFlow,
        minDuration: subProject.minDuration,
        timeModel_id: subProject.timeModel.id,
        versionLimit: subProject.versionLimit,
        workingDays: subProject.workingDays,
    };
};

export const formatterSelectListSubProjects = (
    subProjects: Type_prj_selectList_subproject[],
): Type_selectList_subProject[] => {
    return subProjects;
};

export const formatterShowSubProjectWithCurrentWorkspace = (
    subProject: Type_prj_show_subProjectWithCurrentWorkspace,
): Type_show_subProjectWithCurrentWorkspace => {
    return subProject;
};
