import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField as MuiTextField, Stack } from "@mui/material";
import { Dayjs } from "dayjs";
import React, { useState } from "react";
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useForm,
} from "react-hook-form";
import * as Yup from "yup";

import { Type_sch_index_task } from "src/api/tms-scheduling/tasks/types";
import { ItemType } from "src/components/Components_Common/forms/IconSelect/IconSelect";
import { multiLanguagesSchema } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.container";
import { CheckboxGroup } from "src/components/Components_Common/forms/reactHookFormComponents/CheckboxGroup/CheckboxGroup";
import { ColorPicker } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPicker";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { DateTimeRangePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DateTimeRangePicker/DateTimeRangePicker";
import { DaysBadgeField } from "src/components/Components_Common/forms/reactHookFormComponents/days/DaysBadgeField";
import { DaysCheckboxField } from "src/components/Components_Common/forms/reactHookFormComponents/days/DaysCheckboxField";
import { IconSelect } from "src/components/Components_Common/forms/reactHookFormComponents/IconSelect/IconSelect";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { RadioGroup } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import {
    ToggleButtonGroup,
    Type_ToggleButtonGroupItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/ToggleButtonGroup/ToggleButtonGroup";
import { AutocompleteAreaTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreaTypes";
import { AutocompleteCompanies } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteCompanies";
import { AutocompleteDrawings } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteDrawings";
import { AutocompleteParentAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteParentAreas";
import { AutocompleteResources } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResources";
import { AutocompleteResourceTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteResourceType";
import { AutocompleteRoles } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteRoles";
import { AutocompleteTasksCustom } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteTasksCustom";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { isValidDate } from "src/utils/date";

const validationSchema = Yup.lazy(() => {
    return Yup.object({
        names: Yup.object().shape(multiLanguagesSchema.fields),
        email: Yup.string().email("Invalid email address"),
        password: Yup.string(),
        // .min(8, "Must be 8 characters or more")
        text: Yup.string(),
        companies: Yup.array().of(
            Yup.object().shape({
                id: Yup.number(),
                name: Yup.string(),
            }),
        ),
        color: Yup.string(),
        date: Yup.object()
            .nullable()
            .test("date", "WrongDate", (date) => {
                return date === null || isValidDate(date as Dayjs);
            }),
        select: Yup.string(),
        selectIcon: Yup.string(),
        checkbox: Yup.boolean(),
        switch: Yup.boolean(),
        radio: Yup.string(),
        toggleButtonGroup: Yup.number(),
        toggleButtonGroupMulti: Yup.array().of(Yup.number()),
        parentArea: Yup.object()
            .shape({
                id: Yup.number().nullable(),
                name: Yup.string(),
            })
            .nullable(),
        workingDays: Yup.number(),
        workingDaysBadge: Yup.string(),
        checkboxGroup: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
    });
});

type Type_MyFormValues = {
    names: any;
    email: string;
    password: string;
    text: string;
    companies: { id?: number; name?: string }[];
    color: string;
    date: Dayjs | null;
    select: string;
    selectIcon: string;
    checkbox: boolean;
    taskFrom: Type_sch_index_task | undefined;
    switch: boolean;
    radioGroup: string;
    toggleButtonGroup?: number;
    toggleButtonGroupMulti: number[];
    parentArea?: {
        id: number;
        name: string;
    } | null;
    drawings: number | null;
    resources: any;
    resourceTypes: any;
    roleType: any;
    roleTypes: any;
    users: any[];
    parentArea2: string | null;
    workingDays: number;
    workingDaysBadge: number;
    checkboxGroup: number[];
    dateTimeRangePicker: Dayjs[];
};

// const area1 = {
//     id: 1,
//     names: {
//         en_GB: "Zone 1",
//         fr_FR: "Zone 1",
//     },
//     color: "#FFFFB0",
//     sortOrder: 1,
//     parentArea: {
//         id: 7,
//         name: "",
//     },
//     areaType: {
//         id: 1,
//         name: "Housing",
//     },
//     drawing: {
//         id: 4,
//         name: "Sélection_284.png",
//         enabled: true,
//     },
//     scheduling: true,
//     resourceType: null,
//     resource: null,
//     permanentUse: false,
//     show: true,
// };
//
// const area2 = {
//     id: 2,
//     names: {
//         en_GB: "Zone 2",
//         fr_FR: "Zone 2",
//     },
//     color: "#9845B0",
//     sortOrder: 2,
//     parentArea: {
//         id: 7,
//         name: "",
//     },
//     areaType: {
//         id: 1,
//         name: "Housing",
//     },
//     drawing: {
//         id: 4,
//         name: "Sélection_284.png",
//         enabled: true,
//     },
//     scheduling: true,
//     resourceType: null,
//     resource: null,
//     permanentUse: false,
//     show: true,
// };

const selectList = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
    { label: "Option 4", value: 4 },
];

const taskTypeList: ItemType[] = [
    {
        label: "Work",
        slug: "work",
        value: "work",
        icon: {
            variant: "kit",
            icon: "light-tds-task-work",
        },
    },
    {
        label: "Study",
        slug: "study",
        value: "study",
        icon: {
            variant: "kit",
            icon: "light-tds-task-study",
        },
    },
    { divider: true },
    {
        label: "Waiting",
        slug: "waiting",
        value: "waiting",
        icon: {
            variant: "kit",
            icon: "light-tds-task-waiting",
        },
    },
    {
        label: "Delivery",
        slug: "delivery",
        value: "delivery",
        icon: {
            variant: "kit",
            icon: "light-tds-task-delivery",
        },
    },
    { divider: true },
    {
        label: "Milestone",
        slug: "milestone",
        value: "milestone",
        icon: {
            variant: "kit",
            icon: "light-tds-task-milestone",
        },
    },
    {
        label: "Technical",
        slug: "technical",
        value: "technical",
        icon: {
            variant: "kit",
            icon: "light-tds-task-technical",
        },
    },
];

const radioGroupList = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
];

const toggleGroupList: Type_ToggleButtonGroupItem[] = [
    { label: "Option 1", value: 1, icon: "user" },
    {
        label: "Option 2",
        value: 2,
        icon: "helmet-safety",
    },
    { label: "Option 3", value: 3, icon: "ghost" },
];

const toggleGroupListWithoutIcon: Omit<Type_ToggleButtonGroupItem, "icon">[] =
    toggleGroupList.map((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { icon, ...rest } = item;
        return rest;
    });

const toggleGroupListWithoutLabel: Omit<Type_ToggleButtonGroupItem, "icon">[] =
    toggleGroupList.map((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { label, ...rest } = item;
        return rest;
    });

/**
 * Documentation MUI preview:
 * https://deploy-preview-37522--material-ui.netlify.app/base-ui/guides/working-with-react-hook-form/
 *
 *
 *
 * @constructor
 */
export const ReactHookFormSubmitOnBlur = () => {
    const handleSubmit: SubmitHandler<Type_MyFormValues> = (values: any) => {
        console.log("====> handleSubmit: should call API", values);
        // TODO add debounce, check data have change since last submit
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data] = useState<Type_MyFormValues>({
        names: {},
        email: "",
        password: "",
        text: "",
        companies: [],
        color: "",
        date: null,
        select: "",
        selectIcon: "",
        checkbox: false,
        switch: false,
        taskFrom: undefined,
        radioGroup: "",
        toggleButtonGroup: undefined,
        toggleButtonGroupMulti: [],
        parentArea: null,
        drawings: null,
        resources: null,
        resourceTypes: null,
        roleType: null,
        roleTypes: [],
        users: [],
        parentArea2: null,
        workingDays: 0,
        workingDaysBadge: 0,
        checkboxGroup: [2],
        dateTimeRangePicker: [],
    });

    const form = useForm<Type_MyFormValues>({
        ...useFormDefaultConfig,
        defaultValues: data,
        // values: data,
        mode: "onBlur", // submit onBlur
        resolver: yupResolver<any>(validationSchema),
    });

    const checkboxes = [
        { id: 1, name: "Checkbox 1" },
        { id: 2, name: "Checkbox 2" },
        { id: 3, name: "Checkbox 3" },
    ];

    return (
        <>
            <FormProvider {...form}>
                <form onBlur={form.handleSubmit(handleSubmit)}>
                    <Stack
                        spacing={2}
                        sx={{ maxWidth: "400px", m: 4, marginBottom: 10 }}
                    >
                        {/*<Stack direction="row" spacing={2}>*/}
                        {/*    Change form initialValues :/!*<TMC_Button*!/*/}
                        {/*        color="secondary"*/}
                        {/*        onClick={() => {*/}
                        {/*            formik.handleQuit(); // clear previous data debounce ans for submit current data*/}
                        {/*            setData((prevState) => ({*/}
                        {/*                ...prevState,*/}
                        {/*                names: { ...area1.names },*/}
                        {/*                color: area1.color,*/}
                        {/*            }));*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        Area 1*/}
                        {/*    </TMC_Button>*/}
                        {/*    <TMC_Button*/}
                        {/*        color="secondary"*/}
                        {/*        onClick={() => {*/}
                        {/*            formik.handleQuit(); // clear previous data debounce ans for submit current data*/}
                        {/*            setData((prevState) => ({*/}
                        {/*                ...prevState,*/}
                        {/*                names: { ...area2.names },*/}
                        {/*                color: area2.color,*/}
                        {/*            }));*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        Area 2*/}
                        {/*    </TMC_Button>*/}
                        {/*</Stack>*/}

                        <DateTimeRangePicker name="dateTimeRangePicker" />

                        <Stack direction="row" spacing={2}>
                            <Controller
                                name="email"
                                control={form.control}
                                render={({ field }) => (
                                    <MuiTextField
                                        {...field}
                                        type="email"
                                        error={!!form.formState.errors.email}
                                        helperText={
                                            <>
                                                {
                                                    form.formState.errors?.email
                                                        ?.message
                                                }
                                            </>
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="password"
                                control={form.control}
                                render={({ field }) => (
                                    <MuiTextField
                                        {...field}
                                        type="password"
                                        error={!!form.formState.errors.password}
                                        helperText={
                                            <>
                                                {
                                                    form.formState.errors
                                                        ?.password?.message
                                                }
                                            </>
                                        }
                                    />
                                )}
                            />
                        </Stack>

                        <TextField name={"text"} label={"Texte"} />

                        <AutocompleteTasksCustom name="taskFrom" label="Task" />

                        <InputMultiLanguages
                            name="names"
                            label={"Translation"}
                        />

                        <ColorPicker
                            name="color"
                            label={"Color"}
                            data-testid="Areas-Drawer-AddArea-color"
                        />
                        <AutocompleteCompanies
                            name={"companies"}
                            label={"Société"}
                        />

                        <DatePicker name="date" />

                        <Select name="select" options={selectList} />

                        <IconSelect
                            name="selectIcon"
                            items={taskTypeList}
                        ></IconSelect>

                        <Switch name="switch" label="Switch Label" />
                        <Switch
                            name="switch"
                            label="Switch Label"
                            labelPlacement="end"
                        />

                        <RadioGroup
                            name="radioGroup"
                            options={radioGroupList}
                        ></RadioGroup>

                        <ToggleButtonGroup
                            name="toggleButtonGroupMulti"
                            items={toggleGroupList}
                            groupLabel="Group label"
                        />

                        <ToggleButtonGroup
                            name="toggleButtonGroup"
                            items={toggleGroupList}
                            groupLabel="Group label"
                            exclusive
                        />

                        <ToggleButtonGroup
                            name="toggleButtonGroup"
                            items={toggleGroupListWithoutLabel}
                            groupLabel="Group label without label"
                            exclusive
                        />

                        <ToggleButtonGroup
                            name="toggleButtonGroup"
                            items={toggleGroupListWithoutIcon}
                            groupLabel="Group label without icon"
                            exclusive
                        />

                        <AutocompleteAreaTypes
                            name="autoAreaType"
                            label={"Area types"}
                        />
                        <AutocompleteDrawings
                            name="drawings"
                            label={"Drawings"}
                        />
                        <AutocompleteResources
                            name="resources"
                            label={"Resources"}
                        />
                        <AutocompleteResourceTypes
                            name="resourceTypes"
                            label={"ResourceTypes"}
                        />
                        <AutocompleteParentAreas
                            name={"parentArea2"}
                            label={"Parent Area 2"}
                        />
                        <AutocompleteRoles
                            label={"test role types"}
                            name={"roleType"}
                        />
                        <AutocompleteRoles
                            label={"test role types"}
                            name={"roleTypes"}
                            multiple
                        />
                        <DaysCheckboxField
                            name="workingDays"
                            direction={"row"}
                            dataTestIdPrefix="workingDays-"
                        />
                        <DaysBadgeField
                            name="workingDaysBadge"
                            direction={"row"}
                            dataTestIdPrefix="workingDays-"
                        />
                        <CheckboxGroup
                            name={"checkboxGroup"}
                            options={checkboxes}
                        />
                    </Stack>
                </form>
            </FormProvider>
            <DevTool control={form.control} /> {/* set up the dev tool */}
        </>
    );
};
