import { Type_Map_planningSubTrades } from "@cimba-digital-construction-solution/utils/dist/decoder/types";

import { Type_index_subProject } from "src/api/tms-projects/subProjects/types";
import { Type_prj_index_subTrade } from "src/api/tms-projects/subTrades/types";
import {
    Type_index_trade,
    Type_post_trade,
    Type_prj_index_trade,
    Type_prj_post_trade,
    Type_prj_put_trade,
    Type_prj_selectList_trade,
    Type_prj_show_trade,
    Type_selectList_trade,
    Type_show_trade,
} from "src/api/tms-projects/trades/types";
import { DEFAULT_COLORS } from "src/theme/stylesheet";

export const formatterIndexTrades = (
    trades: Type_prj_index_trade[],
): Type_index_trade[] => {
    return trades.map((trade: Type_prj_index_trade) =>
        formatterIndexTrade(trade),
    );
};

export const formatterIndexTrade = (
    trade: Type_prj_index_trade,
): Type_index_trade => {
    return {
        id: trade.id,
        name: trade.name,
        color: trade.color ?? DEFAULT_COLORS.trade,
        code: trade.code,
        enabled: trade.enabled,
        subProject: trade.subProject,
        subTrades: trade.subTrades.map((subTrade: Type_prj_index_subTrade) => ({
            id: subTrade.id,
            name: subTrade.name,
            team: subTrade.team,
            code: subTrade.code,
            trade: trade,
            subProject: trade.subProject,
            enabled: subTrade.enabled,
        })),
    };
};

export const formatterShowTrade = (
    trade: Type_prj_show_trade,
): Type_show_trade => {
    return {
        id: trade.id,
        names: trade.names,
        color: trade.color ?? DEFAULT_COLORS.trade,
        code: trade.code,
        subProject: trade.subProject as Type_index_subProject,
        enabled: trade.enabled,
        subTrades: trade.subTrades,
    };
};

export const formatterUpdateTrade = (
    data: Type_show_trade,
): Type_prj_put_trade => {
    return {
        id: data.id,
        names: data.names,
        color: data.color ?? DEFAULT_COLORS.trade,
        code: data.code,
        enabled: data.enabled,
    };
};

export const formatterCreateTrade = (
    data: Type_prj_post_trade,
): Type_post_trade => {
    return {
        names: data.names,
        color: data.color ?? DEFAULT_COLORS.trade,
        code: data.code,
    };
};

export const formatterSelectListTrade = (
    trades: Type_prj_selectList_trade[],
): Type_selectList_trade[] => {
    return trades.map((trade: Type_prj_selectList_trade) => ({
        id: trade.id,
        code: trade.code,
        name: trade.name,
        color: trade.color ?? DEFAULT_COLORS.trade,
        enabled: trade.enabled,
    }));
};

export const formatterTradesFromDataPlanningSubTrades = (
    subTrades: Type_Map_planningSubTrades,
): Type_selectList_trade[] => {
    return Array.from(subTrades).reduce<Type_selectList_trade[]>(
        (acc, [_key, value]) => {
            if (!acc.some((trade) => trade.id === value.tradeId)) {
                acc.push({
                    id: value.tradeId,
                    code: value.tradeCode,
                    name: value.tradeName,
                    color: value.color,
                });
            }
            return acc;
        },
        [],
    );
};
