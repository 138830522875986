import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const Styled_StackToastContainer = styled(Stack)(({ theme }: any) => ({
    zIndex: 2,
    flexDirection: "row",
    flexWrap: "nowrap",
    backgroundColor: COLORS.moon900,
    borderRadius: theme.shape.borderRadiusLarge,
    minHeight: "80px",
}));

export const Styled_StackToastIcon = styled(Stack)(() => ({
    justifyContent: "center",
    alignItems: "center",
    width: "56px",
}));

export const Styled_StackToastChildren = styled(Stack)(() => ({
    justifyContent: "center",
}));

export const Styled_StackToastClose = styled(Stack)(() => ({
    width: "40px",
}));
