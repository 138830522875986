import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { TaskAreaNoteKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskAreaNote,
    formatterIndexTaskAreaNotes,
    formatterUpdateTaskAreaNote,
} from "src/api/tms-scheduling/taskAreaNotes/formatters";
import {
    createTaskAreaNote,
    deleteTaskAreaNote,
    indexTaskAreaNotes,
    updateTaskAreaNote,
} from "src/api/tms-scheduling/taskAreaNotes/services";
import {
    Type_post_taskAreaNote,
    Type_sch_index_taskAreaNote,
} from "src/api/tms-scheduling/taskAreaNotes/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { Type_put_note } from "src/forms/notes/NoteForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreaNotes = (taskAreaConfig: Type_RequestConfig) => {
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskAreaConfig };

    return useQuery({
        queryKey: [TaskAreaNoteKeys.INDEX, config],
        queryFn: () => indexTaskAreaNotes(config),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexTaskAreaNotes");
            }
            return formatterIndexTaskAreaNotes(
                data.data.data as Type_sch_index_taskAreaNote[],
            );
        },
        enabled:
            !!taskAreaConfig.taskId &&
            !!taskAreaConfig.areaId &&
            !!taskAreaConfig.taskAreaId &&
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId,
    });
};

export const mutationCreateTaskAreaNote = (
    taskAreaConfig: Type_RequestConfig,
    callback: (id: number) => void,
) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const config = { ...requestConfig, ...taskAreaConfig };

    return useMutation({
        mutationFn: (newTaskAreaNote: Type_post_taskAreaNote) => {
            return createTaskAreaNote(
                formatterCreateTaskAreaNote(newTaskAreaNote),
                config,
            );
        },
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateTaskAreaNote",
                );
            }

            if (callback) {
                callback(data.data.data.task_area_id);
            }
            await queryClient.invalidateQueries([
                TaskAreaNoteKeys.INDEX,
                config,
            ]);
            addSuccess({
                description: fmt("ToastSuccessCreate"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateTaskAreaNote = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (updatedTaskAreaNote: Type_put_note) =>
            updateTaskAreaNote(
                updatedTaskAreaNote.id,
                formatterUpdateTaskAreaNote(updatedTaskAreaNote),
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateTaskAreaNote",
                );
            }

            addSuccess({
                description: fmt("ToastSuccessUpdate"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteTaskAreaNote = (
    taskAreaConfig: Type_RequestConfig,
) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const config = { ...requestConfig, ...taskAreaConfig };

    return useMutation({
        mutationFn: (taskAreaNoteId: number) =>
            deleteTaskAreaNote(taskAreaNoteId, requestConfig),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationDeleteTaskAreaNote",
                );
            }

            await queryClient.invalidateQueries([
                TaskAreaNoteKeys.INDEX,
                config,
            ]);
            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};
