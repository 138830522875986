import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const metadataTypesSelect: Type_SelectOptionItem[] = [
    { label: "Date", value: "date" },
    { label: "Text", value: "text" },
    { label: "Radio", value: "radio" },
    { label: "Checkbox", value: "checkbox" },
    { label: "Select", value: "select" },
];

export const formatterTradMetadataTypesSelect = (
    metadataList: Type_SelectOptionItem[],
): Type_SelectOptionItem[] => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("MetadataUi.Types");
    return metadataList.map((metadataListItem: Type_SelectOptionItem) => ({
        label: fmt(metadataListItem.label),
        value: metadataListItem.value,
    }));
};

export const metadataWithValues: string[] = ["checkbox", "select", "radio"];
