import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_Container = styled(Stack)(({ theme }) => ({
    cursor: "pointer",
    padding: theme.spacing(2, 0),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
        backgroundColor: theme.palette.common.greyLight,
    },
}));

export const Styled_Content = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(1),
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
}));
export const Styled_HeaderAuthor = styled(Stack)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
}));
export const Styled_Username = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
    flex: "1 0 0",
}));
export const Styled_Footer = styled(Stack)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
}));
