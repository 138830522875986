import {
    Type_index_resourceType,
    Type_post_resourceType,
    Type_prj_index_resourceType,
    Type_prj_post_ResourceType,
    Type_prj_put_resourceType,
    Type_prj_select_resourceType,
    Type_prj_show_resourceType,
    Type_select_resourceType,
    Type_show_resourceType,
} from "src/api/tms-projects/resourceTypes/types";

export const formatterIndexResourceTypes = (
    resourceTypes: Type_prj_index_resourceType[],
): Type_index_resourceType[] => {
    return resourceTypes.map((resourceType: Type_prj_index_resourceType) =>
        formatterIndexResourceType(resourceType),
    );
};
export const formatterIndexResourceType = (
    resourceType: Type_prj_index_resourceType,
): Type_index_resourceType => {
    return {
        id: resourceType.id,
        name: resourceType.name,
        resourceModelName: resourceType.resourceModel.name,
        subProject: resourceType.subProject,
        size: resourceType.size,
        params: resourceType.params,
        enabled: resourceType.enabled,
    };
};

export const formatterShowResourceType = (
    resourceType: Type_prj_show_resourceType,
): Type_show_resourceType => {
    return {
        id: resourceType.id,
        names: resourceType.names,
        resourceModel: resourceType.resourceModel.id,
        enabled: resourceType.enabled,
        size: resourceType.size,
        subProject: resourceType.subProject,
        params: resourceType.subProject,
    };
};

export const formatterPostResourceType = (
    resourceType: Type_post_resourceType,
): Type_prj_post_ResourceType => {
    return {
        names: resourceType.names,
        resource_model_id: resourceType.resourceModel,
        size: resourceType.size,
        params: resourceType.params,
    };
};

export const formatterPutResourceType = (
    resourceType: Type_show_resourceType,
): Type_prj_put_resourceType => {
    return {
        id: resourceType.id,
        names: resourceType.names,
        resource_model_id: resourceType.resourceModel,
        size: resourceType.size,
        params: resourceType.params,
        enabled: resourceType.enabled,
    };
};

export const formatterSelectListResourceTypes = (
    data: Type_prj_select_resourceType[],
): Type_select_resourceType[] => {
    return data.map((datum: Type_prj_select_resourceType) => ({
        id: datum.id,
        name: datum.name,
        size: datum.size,
    }));
};
