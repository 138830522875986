import { FormikValues } from "formik";

import {
    Enum_TreeItem_ItemType,
    Type_index_sequenceFolder,
    Type_index_sequenceFolderTree,
    Type_index_sequenceFolderTree_sequence,
    Type_index_sequenceFolderTree_task,
    Type_post_sequenceFolder,
    Type_put_sequenceFolder,
    Type_sch_index_sequenceFolder,
    Type_sch_index_sequenceFolderTree,
    Type_sch_index_sequenceFolderTree_sequence,
    Type_sch_index_sequenceFolderTree_task,
    Type_sch_post_sequenceFolder,
    Type_sch_put_sequenceFolder,
    Type_sch_put_sequenceFolderLegacy,
    Type_sch_select_sequenceFolder,
    Type_sch_show_sequenceFolder,
    Type_select_sequenceFolder,
    Type_show_sequenceFolder,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { DEFAULT_COLORS } from "src/theme/stylesheet";

export const formatterIndexSequencesFolder = (
    sequencesFolder: Type_sch_index_sequenceFolder[],
) => {
    if (!sequencesFolder) return null;
    return sequencesFolder.map(formatterIndexSequenceFolder);
};

export const formatterIndexSequenceFolder = (
    sequenceFolder: Type_sch_index_sequenceFolder,
): Type_index_sequenceFolder => {
    return sequenceFolder;
};

export const formatterShowSequenceFolder = (
    sequenceFolder: Type_sch_show_sequenceFolder,
): Type_show_sequenceFolder => {
    return sequenceFolder;
};

export const formatterCreateSequenceFolderLegacy = (
    sequenceFolder: FormikValues,
): Type_sch_post_sequenceFolder => {
    return { names: sequenceFolder.names };
};

export const formatterCreateSequenceFolder = (
    sequenceFolder: Type_post_sequenceFolder,
): Type_sch_post_sequenceFolder => {
    return { names: sequenceFolder.names };
};

export const formatterUpdateSequenceFolderLegacy = (
    sequenceFolder: FormikValues,
): Type_sch_put_sequenceFolderLegacy => {
    return {
        id: sequenceFolder.id,
        names: sequenceFolder.names,
        sequences: sequenceFolder.sequences,
        enabled: sequenceFolder.enabled,
    };
};

export const formatterUpdateSequenceFolder = (
    sequenceFolder: Type_put_sequenceFolder,
): Type_sch_put_sequenceFolder => {
    return {
        names: sequenceFolder.names,
        enabled: sequenceFolder.enabled,
    };
};

export const formatterSelectListSequenceFolders = (
    sequenceFolders: Type_sch_select_sequenceFolder[],
): Type_select_sequenceFolder[] => {
    return sequenceFolders.map((folder: Type_sch_select_sequenceFolder) => ({
        value: folder ? folder.id : "",
        label: folder ? folder.name : "",
    }));
};

export const formatterTreeSequenceFolder = (
    sequenceFolder: Type_sch_index_sequenceFolder,
) => {
    return {
        id: sequenceFolder.id,
        label: sequenceFolder.name,
        itemId: `folder-${sequenceFolder.id}`,
        itemType: "folder",
        children: [],
    };
};
export const formatterTreeSequenceFolders = (
    sequenceFolders: Type_sch_index_sequenceFolderTree[],
): Type_index_sequenceFolderTree[] => {
    return sequenceFolders.map(
        (
            sequenceFolder: Type_sch_index_sequenceFolderTree,
        ): Type_index_sequenceFolderTree => ({
            id: sequenceFolder.id,
            label: sequenceFolder.name,
            itemId: `folder-${sequenceFolder.id}`,
            itemType: Enum_TreeItem_ItemType.folder,
            children: formatterTreeSequences(sequenceFolder.sequences),
            enabled: true,
        }),
    );
};

export const formatterTreeSequence = (
    sequence: Type_sch_index_sequenceFolderTree_sequence,
): Type_index_sequenceFolderTree_sequence => {
    return {
        id: sequence.id,
        number: sequence.number,
        label: sequence.name,
        code: sequence.code,
        color: sequence.color ?? DEFAULT_COLORS.sequence,
        itemId: `sequence-${sequence.id}`,
        itemType: Enum_TreeItem_ItemType.sequence,
        enabled: sequence.enabled,
        children: formatterTreeTasks(sequence.tasks, sequence.enabled),
    };
};

const formatterTreeSequences = (
    sequences: Type_sch_index_sequenceFolderTree_sequence[],
): Type_index_sequenceFolderTree_sequence[] => {
    return sequences.map(
        (
            sequence: Type_sch_index_sequenceFolderTree_sequence,
        ): Type_index_sequenceFolderTree_sequence =>
            formatterTreeSequence(sequence),
    );
};

const formatterTreeTasks = (
    tasks: Type_sch_index_sequenceFolderTree_task[],
    sequenceEnabled: boolean,
): Type_index_sequenceFolderTree_task[] => {
    return tasks.map(
        (
            task: Type_sch_index_sequenceFolderTree_task,
        ): Type_index_sequenceFolderTree_task => ({
            id: task.id,
            number: task.number,
            label: task.name,
            code: task.code,
            color: task.color ?? DEFAULT_COLORS.task,
            type: task.type,
            itemId: `task-${task.number}`,
            itemType: Enum_TreeItem_ItemType.task,
            enabled: sequenceEnabled,
        }),
    );
};
