import * as React from "react";
import { useState } from "react";

import {
    InputFile,
    Type_Resource,
} from "src/components/Components_Common/forms/InputFile";

export const InputDrawingFileDemo = () => {
    const [drawingImage, setDrawingImage] = useState<Type_Resource>({
        file: null,
        image: null,
        errors: false,
    });
    return (
        <div>
            <InputFile
                acceptFormats={["png", "jpg", "pdf"]}
                state={drawingImage}
                setState={setDrawingImage}
                data-testid="Form-Drawing-Input-Drawing-File"
            />
        </div>
    );
};
