/* DEFAULT CONFIGURATION */

import { Type_PlanningFilters } from "@cimba-digital-construction-solution/utils/dist/filters/planningFilters";

import { Type_printSettings } from "src/api/tms-print/print/types";

export enum Enum_typeView {
    planning = "planning",
    gantt = "gantt",
    dashboard = "dashboard",
    workflow = "workflow",
    grid = "grid",
    areamap = "areamap",
}
export type Type_default_view = {
    name: string;
    order: number;
    data: Type_data_view;
};

// Type data

export type Type_prj_view_data = {
    type: keyof typeof Enum_typeView; // TODO complete with default view settings
    version: number;
    settings: unknown;
    filters?: Type_PlanningFilters;
};

export type Type_data_view =
    | Type_data_view_planning
    | Type_data_view_gantt
    | Type_data_view_dashboard
    | Type_data_view_grid
    | Type_data_view_areamap
    | Type_data_view_workflow;

type Type_data_view_generic = {
    version: number;
    settings: unknown;
};

export type Type_data_view_planning = Type_data_view_generic & {
    type: Enum_typeView.planning;
    settings: Type_settings_planning;
    filters?: Type_PlanningFilters;
};

export type Type_data_view_gantt = Type_data_view_generic & {
    type: Enum_typeView.gantt;
    settings: Type_settings_planning;
    filters?: Type_PlanningFilters;
};

export type Type_data_view_dashboard = Type_data_view_generic & {
    type: Enum_typeView.dashboard;
    settings: Type_settings_dashboard;
};

export type Type_data_view_grid = Type_data_view_generic & {
    type: Enum_typeView.grid;
};

export type Type_data_view_areamap = Type_data_view_generic & {
    type: Enum_typeView.areamap;
};

export type Type_data_view_workflow = Type_data_view_generic & {
    type: Enum_typeView.workflow;
};

// Type view data settings

export type Type_settings_planning = {
    print: Type_printSettings;
};

export type Type_settings_dashboard = {
    widgets: any[];
};

/* API */
export type Type_prj_index_view = {
    id: number;
    name: string;
    order: number;
    enabled: boolean;
    data: Type_prj_view_data;
};

export type Type_prj_show_view = {
    id: number;
    name: string;
    order: number;
    enabled: boolean;
    data: Type_prj_view_data;
};

export type Type_prj_post_view = {
    name: string;
    order: number;
    workspace_id: number;
    data: Type_prj_view_data;
};

export type Type_prj_put_view = {
    name?: string;
    order?: number;
    workspace_id?: number;
    data?: Type_prj_view_data;
    enabled?: any;
};

export type Type_prj_select_view = {
    id: number;
    name: string;
    order: number;
    enabled: boolean;
};

/* FRONT */

export type Type_select_view = {
    id: number;
    name: string;
    order: number;
    enabled: boolean;
};

export type Type_index_view = {
    id: number;
    name: string;
    order: number;
    enabled: boolean;
    data: Type_data_view;
};

export type Type_show_view = {
    id: number;
    name: string;
    order: number;
    enabled: boolean;
    data: Type_data_view;
};

export type Type_post_view = {
    name: string;
    order: number;
    enabled: boolean;
    data: Type_prj_view_data;
};
