import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { PeopleTaskAreaKeys } from "src/api/tms-scheduling/keys";
import {
    formatterFormPeopleTaskArea,
    formatterIndexPeopleTaskAreas,
} from "src/api/tms-scheduling/peopleTaskArea/formatter";
import {
    createTaskAreaPeople,
    indexTaskAreaPeople,
    removeTaskAreaPeople,
} from "src/api/tms-scheduling/peopleTaskArea/services";
import {
    Type_post_peopleTaskArea,
    Type_sch_index_peopleTaskArea,
} from "src/api/tms-scheduling/peopleTaskArea/types";
import { NO_STALE_TIME, Type_stale_times } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreaPeople = (
    taskAreaConfig: Type_RequestConfig,
    staleTime: Type_stale_times = NO_STALE_TIME,
) => {
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskAreaConfig };

    return useQuery({
        queryKey: [PeopleTaskAreaKeys.INDEX, config],
        queryFn: ({ signal }) => indexTaskAreaPeople(config, signal),
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexPeopleTaskAreas(
                data?.data?.data as Type_sch_index_peopleTaskArea[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
        enabled:
            !!config.projectId &&
            !!config.subProjectId &&
            !!config.versionId &&
            !!config.taskAreaId,
        staleTime: staleTime,
    });
};

export const mutationCreateTaskAreaPeople = (
    taskAreaConfig: Type_RequestConfig,
    callback?: (id: number) => void,
) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const config = { ...requestConfig, ...taskAreaConfig };

    return useMutation({
        mutationFn: (taskAreaPeople: Type_post_peopleTaskArea) =>
            createTaskAreaPeople(
                formatterFormPeopleTaskArea(taskAreaPeople),
                config,
            ),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data)
                throw new Error(
                    "Wrong format data: mutationCreateTaskAreaPeople",
                );

            if (callback && data.data.data?.task_area_id) {
                callback(data.data.data.task_area_id);
            }

            return data;
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteTaskAreaPeople = (
    taskAreaConfig: Type_RequestConfig,
) => {
    const { addWarning, addSuccess } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.TaskAreaPeople",
    );
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskAreaConfig };
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) =>
            removeTaskAreaPeople(id, {
                ...requestConfig,
            }),
        onSuccess: async (data) => {
            if (!data.success) {
                throw new Error(
                    "Wrong format data: mutationDeleteTaskAreaPeople",
                );
            }

            await queryClient.invalidateQueries([
                PeopleTaskAreaKeys.INDEX,
                config,
            ]);
            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
            return data;
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
