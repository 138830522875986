import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { PunchlistItemCauseForm } from "src/forms/punchlistItemCauses/PunchlistItemCauseForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_PunchlistItemCauseDrawer = {
    open: boolean;
    onClose: () => void;
    id: number | null;
    action: Type_action;
    page: string;
};

export const PunchlistItemCauseDrawer = ({
    open,
    onClose,
    id,
    action,
    page,
}: Type_Props_PunchlistItemCauseDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PunchlistItemCauses",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`PunchlistItemCause-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            page={page}
            formId={"punchlistItemCauses"}
            isFormLoading={isLoading}
        >
            <PunchlistItemCauseForm
                onClose={onClose}
                action={action}
                id={id === null ? undefined : id}
                setIsLoading={setIsLoading}
            />
        </DrawerGeneric>
    );
};
