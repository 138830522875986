import { Grid, Stack } from "@mui/material";
import React, { useState } from "react";

import { useShowPlatformSetting } from "src/api/tms-commons/platformSettings";
import { languages as languagesList } from "src/assets/translations";
import { LoadingBox } from "src/components";
import { SettingsRowLine } from "src/components/Components_Common/SettingsRowLine/SettingsRowLine";
import { SettingsRowTitle } from "src/components/Components_Common/SettingsRowTitle/SettingsRowTitle";
import { PlatformSettingsDrawer } from "src/drawers/platformSettings/PlatformSettingsDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const Page_PlatformSettings = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.PlatformSettings",
    );
    const { isFetching, data: platformSettings } =
        useShowPlatformSetting(1) || {};

    const page = "platformSettings";

    const [openPlatformSettingsDrawer, setOpenPlatformSettingsDrawer] =
        useState(false);

    const handleUpdatePlatformSettings = () => {
        setOpenPlatformSettingsDrawer(true);
    };

    const handleClosePlatformSettingsDrawer = () => {
        setOpenPlatformSettingsDrawer(false);
    };

    return (
        <>
            <Stack rowGap={4} mt={6}>
                <Grid container rowGap={2}>
                    <SettingsRowTitle
                        title={fmt("Title")}
                        dataTestId={"Project-details-project-name"}
                        handleClick={handleUpdatePlatformSettings}
                    />
                    {isFetching ? (
                        <LoadingBox />
                    ) : (
                        <>
                            <SettingsRowLine
                                title={fmt("EmailsAddress")}
                                dataTestId={
                                    "PlatformConfiguration_PlatformSettings_Emails_Address"
                                }
                                value={platformSettings?.emailAddresses.join(
                                    ", ",
                                )}
                            />
                            <SettingsRowLine
                                title={fmt("DefaultLanguage")}
                                dataTestId={
                                    "PlatformConfiguration_PlatformSettings_Default_Language"
                                }
                                value={
                                    platformSettings?.defaultLanguage
                                        ? languagesList[
                                              platformSettings.defaultLanguage
                                          ].toString()
                                        : ""
                                }
                            />
                        </>
                    )}
                </Grid>
            </Stack>
            <PlatformSettingsDrawer
                page={page}
                open={openPlatformSettingsDrawer}
                platformSetting={platformSettings}
                onClose={handleClosePlatformSettingsDrawer}
                isFetching={isFetching}
            />
        </>
    );
};
