import { useState } from "react";

import {
    mutationAddProjectToFavourites,
    mutationRemoveProjectFromFavourites,
    useIndexFavouriteProjects,
} from "src/api/tms-commons/favouriteProjects";
import {
    mutationArchiveProject,
    mutationRemoveProject,
    mutationStatusProject,
    useIndexProjects,
} from "src/api/tms-commons/projects";
import {
    Type_index_project,
    Type_put_project,
} from "src/api/tms-commons/projects/types";

import { Page_ProjectsComponent } from "./Page_Projects.component";

export type Type_modalDeleteProject = {
    isOpen: boolean;
    card?: Type_index_project;
};

export const Page_ProjectsContainer = () => {
    /* QUERIES ET MUTATIONS */
    //FAVOURITES
    const { mutate: mutateAddProjectToFavourites } =
        mutationAddProjectToFavourites() || {};
    const { mutate: mutateRemoveProjectFromFavourites } =
        mutationRemoveProjectFromFavourites() || {};
    const { data: favouriteProjects } = useIndexFavouriteProjects();

    //PROJECT
    const { data: projects } = useIndexProjects();
    const { mutate: mutateProjectStatus } = mutationStatusProject() || {};
    const { mutate: mutateArchiveProject } = mutationArchiveProject() || {};
    const { mutate: mutateRemoveProject } = mutationRemoveProject() || {};

    /* STATE AND FUNCTIONS */
    const [modalDeleteProject, setModalDeleteProject] =
        useState<Type_modalDeleteProject>({
            isOpen: false,
        });

    // ADD TO FAVOURITES
    const addProjectToFavourites = (id: number) => {
        mutateAddProjectToFavourites(id);
    };

    //DELETE PROJECT FROM FAVOURITES
    const deleteFavouriteProject = (id: number) => {
        mutateRemoveProjectFromFavourites(id);
    };

    // CHANGE STATUS
    const changeStatus = (project: Type_index_project) => {
        mutateProjectStatus({
            id: project.id,
            enabled: !project.enabled,
        } as Type_put_project);
    };

    // ARCHIVE PROJECT
    const archiveProject = (id: number, project: Type_index_project) => {
        mutateArchiveProject({
            id,
            archived: !project.archived,
        } as Type_put_project);
    };

    //DELETE PROJECT
    const deleteProject = (id: number) => {
        mutateRemoveProject(id);
    };

    const projectsWithoutArchived: Type_index_project[] = projects
        ? projects.filter((project: Type_index_project) => !project.archived)
        : [];

    const archivedProjects: Type_index_project[] = projects
        ? projects.filter((project: Type_index_project) => project.archived)
        : [];

    const favouriteProjectsMap = new Map(
        favouriteProjects?.map((fav) => [fav.id, fav]),
    );

    const allProjects: Type_index_project[] = projectsWithoutArchived
        .map(
            (project: Type_index_project) =>
                favouriteProjectsMap.get(project.id) ?? project,
        )
        .filter(Boolean);

    return (
        <Page_ProjectsComponent
            projects={allProjects}
            archivedProjects={archivedProjects}
            favouritesProjects={favouriteProjects || []}
            addProjectToFavourites={addProjectToFavourites}
            deleteFavouriteProject={deleteFavouriteProject}
            handleProjectStatus={changeStatus}
            handleProjectArchived={archiveProject}
            deleteProject={deleteProject}
            modalDeleteProject={modalDeleteProject}
            setModalDeleteProject={setModalDeleteProject}
        />
    );
};
