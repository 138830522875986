import * as React from "react";
import { ComponentProps, useState } from "react";

import { useSelectAreas } from "src/api/tms-projects/areas";
import {
    AutocompleteTree,
    formatSelectListToSelectListTree,
} from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/AutocompleteTree";

type Type_Props_AutocompleteAreas = Omit<
    ComponentProps<typeof AutocompleteTree>,
    "options" | "isFetching"
> & {
    name: string;
    label: string;
    multiple?: boolean;
};

export const AutocompleteAreas = ({
    name,
    label,
    multiple,
    ...props
}: Type_Props_AutocompleteAreas) => {
    ///////////////////////////////
    /// States                  ///
    ///////////////////////////////

    const [open, setOpen] = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);

    ///////////////////////////////
    /// Queries                 ///
    ///////////////////////////////

    const { data, isFetching } = useSelectAreas(loadData) || {};

    return (
        <AutocompleteTree
            name={name}
            label={label}
            multiple={multiple}
            options={data ? formatSelectListToSelectListTree(data) : []}
            isFetching={isFetching}
            {...props}
            // Controlled loading mode
            open={open}
            onOpen={() => {
                setOpen(true);
                setLoadData(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
        />
    );
};
