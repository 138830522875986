import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useIndexPeopleTask } from "src/api/tms-scheduling/peopleTask";
import { Type_index_peopleTask } from "src/api/tms-scheduling/peopleTask/types";
import { Empty, UserSmallCard } from "src/components";
import { ItemChildrenProps } from "src/components/Components_Common/accordions/Accordion";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { hasKey } from "src/utils/object";
import { sortData } from "src/utils/sortData";

type Type_Props_PeopleTaskList = {
    peopleTask: Type_index_peopleTask[];
};

const PeopleTaskList = ({ peopleTask }: Type_Props_PeopleTaskList) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    const sortedPeopleTask: Type_index_peopleTask[] = useMemo(
        () => sortData(peopleTask, "firstName"),
        [peopleTask],
    );

    return (
        <Box display="flex" flexDirection="column" gap={1} paddingBottom={2}>
            {sortedPeopleTask?.map((assignee: Type_index_peopleTask) => (
                <UserSmallCard
                    id={assignee.id}
                    firstName={assignee.firstName}
                    lastName={assignee.lastName}
                    email={assignee.email}
                    key={`peopleTask_${assignee.id}`}
                    badgeLabel={
                        hasKey(assignee, "useId") || assignee?.userId
                            ? undefined
                            : fmt("Ext")
                    }
                    sx={{ paddingX: 0 }}
                />
            ))}
        </Box>
    );
};

type Type_Props_PeopleTask = ItemChildrenProps & {
    taskId: number;
};

export const PeopleTask = ({ taskId, readonly }: Type_Props_PeopleTask) => {
    const [peopleTasks, setPeopleTasks] = useState<Type_index_peopleTask[]>([]);
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    const { openPaper } = useContextualDrawer();

    const { isFetching, data } = useIndexPeopleTask({ taskId: taskId });

    useEffect(() => {
        if (data) {
            setPeopleTasks(data);
        }
    }, [data]);

    const handleNewPerson = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        openPaper("peopleTask", {
            taskId: taskId,
            focus: true,
        });
    };

    return isFetching ? (
        <FullSpinner />
    ) : peopleTasks?.length ? (
        <PeopleTaskList peopleTask={peopleTasks} />
    ) : (
        <Empty
            message={fmt("EmptyPeopleTaskMessage")}
            {...(!readonly && {
                actionMessage: fmt("AssignSomeone"),
                onClick: handleNewPerson,
            })}
            sx={{ marginBottom: 2 }}
            dataTestIdRef="PeopleTask"
        />
    );
};
