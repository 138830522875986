import { useQuery } from "react-query";

import { formatterIndexProjectRoles } from "src/api/tms-commons/projectRoles/formatter";
import { indexProjectRoles } from "src/api/tms-commons/projectRoles/services";
import { Type_index_projectRole } from "src/api/tms-commons/projectRoles/types";
import { ProjectRoleKeys } from "src/api/tms-projects/keys";
import { useProject } from "src/contexts/project";

export const useIndexProjectRoles = () => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [ProjectRoleKeys.INDEX, requestConfig],
        queryFn: () => indexProjectRoles(requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_index_projectRole[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexProjectRoles");
            }
            return formatterIndexProjectRoles(data.data.data);
        },
        enabled: !!requestConfig.projectId,
    });
};
