import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import {
    getFilterJoin,
    getParamsJoin,
    Type_Filter_Areas,
    Type_Params,
} from "src/api/filters";
import { AreaPath, Url } from "src/api/paths";
import {
    Type_prj_post_area,
    Type_prj_put_area,
} from "src/api/tms-projects/areas/types";

export const indexAreas = (
    requestConfig: Type_RequestConfig,
    filters: Type_Filter_Areas = {},
    params: Type_Params = {},
): GetReturnType => {
    const urlFilters: string = getFilterJoin(filters);
    const urlParams: string = getParamsJoin(params, urlFilters);

    return get(
        `${Url.PROJECTS}${AreaPath.AREAS}${urlFilters}${urlParams}`,
        requestConfig,
    );
};

export const showArea = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${AreaPath.AREAS}/${id}`, requestConfig);
};

export const createArea = (
    data: Type_prj_post_area,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.PROJECTS}${AreaPath.AREAS}`, requestConfig);
};

export const updateArea = (
    id: number,
    data: Type_prj_put_area,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(data, `${Url.PROJECTS}${AreaPath.AREAS}/${id}`, requestConfig);
};

export const duplicateArea = (
    id: number,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        {},
        `${Url.PROJECTS}${AreaPath.AREAS}/${id}/duplicate`,
        requestConfig,
    );
};

export const removeArea = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(`${Url.PROJECTS}${AreaPath.AREAS}/${id}`, requestConfig);
};

export const selectListAreas = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${AreaPath.AREAS_SELECT_LIST}`, requestConfig);
};

export const exportAreas = (
    requestConfig: Type_RequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${AreaPath.AREAS_EXPORT}`,
        requestConfig,
        null,
        signal,
        {
            responseType: "arraybuffer",
        },
    );
};

export const importAreas = (data: any, requestConfig: Type_RequestConfig) => {
    return post(data, `${Url.PROJECTS}${AreaPath.AREAS_IMPORT}`, {
        ...requestConfig,
        "Content-Type": "multipart/form-data",
    });
};

export const updateAreaOnDnd = (
    data: {
        id: number;
        sortOrder: number;
        parentId?: number | null;
    },
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${AreaPath.AREAS}/${data.id}`,
        requestConfig,
    );
};
