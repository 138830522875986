import { useState } from "react";

import {
    mutationStatusSubProject,
    useDeleteSubProject,
    useIndexSubProjects,
} from "src/api/tms-projects/subProjects";
import { Type_index_subProject } from "src/api/tms-projects/subProjects/types";

import { Page_SubProjectsComponent } from "./Page_SubProjects.component";

export type Type_modalDeleteSubProject = {
    isOpen: boolean;
    props?: Type_index_subProject;
};

export const Page_SubProjectsContainer = () => {
    // Queries & Mutations
    const { isFetching, data: fetchedSubProjects = [] } =
        useIndexSubProjects() || {};

    const { mutate: mutationSubProjectStatus } =
        mutationStatusSubProject() || {};

    const [modalDeleteSubProject, setModalDeleteSubProject] =
        useState<Type_modalDeleteSubProject>({
            isOpen: false,
        });

    const { mutateAsync: mutateDeleteSubProject } = useDeleteSubProject() || {};

    const removeSubProject = async (id: number) => {
        mutateDeleteSubProject(id);
    };

    const changeStatus = (id: number, subProject: Type_index_subProject) => {
        mutationSubProjectStatus({
            id,
            enabled: !subProject.enabled,
        });
    };

    return (
        <Page_SubProjectsComponent
            isFetching={isFetching}
            subProjects={fetchedSubProjects}
            changeStatus={changeStatus}
            removeSubProject={removeSubProject}
            modalDeleteSubProject={modalDeleteSubProject}
            setModalDeleteSubProject={setModalDeleteSubProject}
        />
    );
};
