import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import {
    Type_Map_planningDates,
    Type_planning,
    Type_planningArea,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import { Dispatch, SetStateAction } from "react";

import { Type_state_PlanningViewFilters } from "src/components/Components_Teamoty/Planning/Planning.type";
import { Type_State_PlanningScroll } from "src/components/Components_Teamoty/Planning/Scrolls/PlanningScroll.type";

export const updateDataPlanning = (
    dataPlanning: Type_planning | null,
    filters: Type_state_PlanningViewFilters,
    heightArea: number,
    setDatesActif: Dispatch<SetStateAction<Array<planningDateClass>>>,
    setAreasActif: Dispatch<SetStateAction<Array<Type_planningArea>>>,
    setScrollX: Dispatch<SetStateAction<Type_State_PlanningScroll>>,
    setScrollY: Dispatch<SetStateAction<Type_State_PlanningScroll>>,
): void => {
    if (dataPlanning) {
        const dates: Type_Map_planningDates = dataPlanning.dates;
        const newDatesActif = Array.from(dates.values()).filter(
            (d: planningDateClass) =>
                (d.workDay || filters.withWeekend) &&
                (d.isWorkingDay || filters.withDaysOff),
        );
        setDatesActif(newDatesActif);

        const newAreasActif = dataPlanning.areas;
        setAreasActif(newAreasActif);

        setScrollY((prev: Type_State_PlanningScroll) => {
            prev.size = newAreasActif.reduce(
                (v: number, a: Type_planningArea) => a.size + v,
                0,
            );
            prev.page = Math.min(prev.size, prev.widthPage / heightArea);
            prev.pos = Math.min(Math.max(0, prev.pos), prev.size - prev.page);
            return { ...prev };
        });

        setScrollX((prev: Type_State_PlanningScroll) => {
            prev.size = newDatesActif.length;
            prev.page = Math.min(prev.size, prev.widthPage / heightArea);
            prev.pos = Math.min(Math.max(0, prev.pos), prev.size - prev.page);
            return { ...prev };
        });
    }
};
