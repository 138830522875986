import { Box, ButtonProps, IconButton, Stack } from "@mui/material";
import {
    gridFilteredDescendantCountLookupSelector,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid-premium";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";

type Type_Props_CustomGroupingCell = GridRenderCellParams<any, any, any> & {
    children: React.ReactNode;
};

export const CustomGroupingCell = ({
    children,
    ...props
}: Type_Props_CustomGroupingCell) => {
    const apiRef = useGridApiContext();
    const { id, field, rowNode } = props;

    const handleClick: ButtonProps["onClick"] = (event) => {
        if (rowNode.type !== "group") {
            return;
        }

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );

    const filteredDescendantCount =
        filteredDescendantCountLookup[rowNode.id] ?? 0;

    return (
        <Stack
            height={"100%"}
            direction="row"
            alignItems={"center"}
            sx={{ ml: rowNode.depth * 4 }}
            color={"text.primary"}
        >
            {filteredDescendantCount > 0 && rowNode.type === "group" ? (
                <IconButton
                    size={"small"}
                    onClick={handleClick}
                    color={"inherit"}
                >
                    <Icon
                        variant="solid"
                        icon={
                            rowNode?.childrenExpanded
                                ? "caret-down"
                                : "caret-right"
                        }
                    />
                </IconButton>
            ) : (
                <Box width={30} />
            )}
            {children}
        </Stack>
    );
};
