import { Grid } from "@mui/material";
import { Dayjs } from "dayjs";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";

import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { SelectTimeModel } from "src/components/Components_Teamoty/forms/select/SelectTimeModel";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Section } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { isValidDate } from "src/utils/date";

export type Type_dateForm = {
    earliestDate?: Dayjs;
    timeModelId?: number | null;
    daily?: boolean;
};

export const dateFormInitialData = {
    earliestDate: undefined,
    timeModelId: null,
    daily: false,
};

export const dateFormSchema = Yup.object().shape({
    earliestDate: Yup.object()
        .nullable()
        .test("date", "WrongDate", (date) => {
            return date === null || isValidDate(date as Dayjs);
        }),
    timeModelId: Yup.string().nullable(),
    daily: Yup.boolean(),
});

type Type_Props_DateForm = Type_ReadonlyComponent;

export const DateForm = ({ readonly }: Type_Props_DateForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.DateForm",
    );

    const { control } = useFormContext();
    const taskType = useWatch({
        name: "type",
        control: control,
    });

    return (
        <Section>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DatePicker
                        name={"earliestDate"}
                        label={fmt("EarliestDate")}
                        data-testid="Form-Task-earliestDate"
                        readonly={readonly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectTimeModel
                        name={"timeModel.id"}
                        size={"small"}
                        label={fmt("TimeModel")}
                        variant={"outlined"}
                        data-testid="Form-Task-timeModelId"
                        readonly={readonly}
                    />
                </Grid>
                {shouldDisplay({
                    name: "daily",
                    taskType: taskType as number,
                }) && (
                    <Grid item xs={12}>
                        <Switch
                            name={"daily"}
                            label={fmt("Daily")}
                            labelPlacement="end"
                            data-testid="Form-Task-daily"
                            readonly={readonly}
                        />
                    </Grid>
                )}
            </Grid>
        </Section>
    );
};
