import { Stack, Typography } from "@mui/material";
import React, { ComponentProps } from "react";

import { useSelectListResources } from "src/api/tms-projects/resources";
import { formatterSelectListResources } from "src/api/tms-projects/resources/formatters";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteResourceGeneric = Omit<
    ComponentProps<typeof Autocomplete>,
    "options" | "isFetching"
> & {
    desc?: string;
};

export const AutocompleteResourcesGeneric = ({
    name,
    label,
    desc,
    ...props
}: Type_Props_AutocompleteResourceGeneric) => {
    const { isFetching, data: resources } = useSelectListResources() || {};

    return (
        <Stack width={"100%"} flexDirection={"column"}>
            <Autocomplete
                name={name}
                label={label}
                isFetching={isFetching}
                options={formatterSelectListResources(resources || [])}
                {...props}
            />
            {desc && (
                <Typography
                    variant={"body2"}
                    color={"sectionTitle.contrastText"}
                >
                    {desc}
                </Typography>
            )}
        </Stack>
    );
};
