import {
    AutocompleteRenderGetTagProps,
    AutocompleteRenderGroupParams,
    AutocompleteRenderOptionState,
    Box,
    ChipTypeMap,
    List,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { useTreeSequenceFolders } from "src/api/tms-scheduling/sequenceFolders";
import {
    Enum_TreeItem_ItemType,
    Type_sequenceFolderTree_item,
} from "src/api/tms-scheduling/sequenceFolders/types";
import {
    AutocompleteTree,
    Type_Props_AutocompleteTree,
    Type_selectListTree,
} from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/AutocompleteTree";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { TaskChip } from "src/components/Components_Teamoty/chips/TaskChip";
import { separatorCodeTask } from "src/configurations/app";
import { useTaskType } from "src/hooks/task/useTaskType";

const LIMIT_TAGS = 5;

// Types and formatters for tree list of tasks

export type Type_selectListTreeTasks = Type_selectListTree & {
    type: Enum_TreeItem_ItemType;
    folderName: string;
    sequenceName: string;
    sequenceColor: string;
    taskType?: number;
};

const createSelectListItem = (
    item: Type_sequenceFolderTree_item,
    depth: number,
    folderName: string,
    sequenceName: string,
    sequenceColor: string,
): Type_selectListTreeTasks => {
    const baseItem: Type_selectListTreeTasks = {
        id: item.id,
        name: item.label,
        depth,
        type: item.itemType,
        folderName,
        sequenceName,
        sequenceColor,
    };

    if (
        // item.itemType === "sequence" ||
        item.itemType === "task"
    ) {
        baseItem.color = item.color ?? "";
        baseItem.code = item.code ?? "";
        baseItem.taskType = item.type ?? "";
    }

    return baseItem;
};

const formatterFlattenTreeSequenceFolders = (
    selectListTasks: Type_sequenceFolderTree_item[],
    depth = 0,
    folderName: string = "",
    sequenceName: string = "",
    sequenceColor: string = "",
): Type_selectListTree[] => {
    return selectListTasks.reduce(
        (acc: Type_selectListTree[], item: Type_sequenceFolderTree_item) => {
            if (item.itemType === "task") {
                acc.push(
                    createSelectListItem(
                        item,
                        depth,
                        folderName,
                        sequenceName,
                        sequenceColor,
                    ),
                );
            }

            if (
                (item.itemType === "folder" || item.itemType === "sequence") &&
                item.children?.length
            ) {
                if (item.itemType === "sequence") {
                    acc.push(
                        ...formatterFlattenTreeSequenceFolders(
                            item.children,
                            depth + 1,
                            folderName,
                            item.label,
                            item.color,
                        ),
                    );
                } else if (item.itemType === "folder") {
                    acc.push(
                        ...formatterFlattenTreeSequenceFolders(
                            item.children,
                            depth + 1,
                            item.label,
                            "",
                            "",
                        ),
                    );
                }
            }

            return acc;
        },
        [],
    );
};

const getOptionLabel = (option: Type_selectListTreeTasks | string) =>
    typeof option === "string"
        ? option
        : option?.code
          ? `${option.code} ${separatorCodeTask} ${option.name}`
          : option.name;

const renderOptionTasksTree = (
    props: any,
    option: Type_selectListTreeTasks,
    state: AutocompleteRenderOptionState,
) => {
    const { key, ...optionProps } = props;
    const { getTaskTypeIcon } = useTaskType({ iconKitVariant: "solid" });

    return (
        <MenuItem
            key={`${key}-${option.id}`}
            data-testid={`item-${key}-${option.id}`}
            {...optionProps}
            selected={state.selected}
            disabled={option.disabled}
        >
            <ListItemIcon>
                <Icon
                    {...getTaskTypeIcon(option.taskType as number)}
                    sx={{ color: option.color }}
                />
            </ListItemIcon>
            <ListItemText
                primary={`${option.code} ${separatorCodeTask} ${option.name}`}
                primaryTypographyProps={{ variant: "body2" }}
            />
        </MenuItem>
    );
};

const renderTagsDefault = (
    tagValue: Type_selectListTreeTasks[],
    getTagProps: AutocompleteRenderGetTagProps,
) => {
    return tagValue.map((option: Type_selectListTreeTasks, index: number) => {
        const { key, ...restProps } = getTagProps({ index });
        return (
            <TaskChip
                key={key}
                {...restProps}
                name={option.name}
                code={option.code}
                type={option.taskType!}
                color={option.color!}
            />
        );
    });
};

const renderGroupTasksTree = (params: AutocompleteRenderGroupParams) => {
    return (
        <List key={params.key} sx={{ pt: 0 }} dense={true}>
            <Box sx={{ px: 4, pb: 1 }}>
                <Typography variant="h5" color="text.secondary">
                    {params.group}
                </Typography>
            </Box>
            {params.children}
        </List>
    );
};

// Component

type Type_Props_AutocompleteTasks<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    Type_Props_AutocompleteTree<
        Type_selectListTreeTasks,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
    >,
    "options" | "isFetching"
> & {
    name: string;
    label: string;
    multiple?: boolean;
};

export const AutocompleteTasks = <
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({
    name,
    label,
    multiple,
    ...props
}: Type_Props_AutocompleteTasks<
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
>) => {
    ///////////////////////////////
    /// States                  ///
    ///////////////////////////////

    const [open, setOpen] = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);

    ///////////////////////////////
    /// Queries                 ///
    ///////////////////////////////

    const { data, isFetching } = useTreeSequenceFolders(loadData) || {};

    return (
        <AutocompleteTree
            name={name}
            label={label}
            multiple={multiple}
            options={
                data
                    ? (formatterFlattenTreeSequenceFolders(
                          data,
                      ) as Type_selectListTreeTasks[])
                    : []
            }
            isFetching={isFetching}
            {...props}
            // Custom Autocomplete task rendering
            getOptionLabel={getOptionLabel}
            renderOption={renderOptionTasksTree}
            renderTags={renderTagsDefault}
            groupBy={(option) => option.sequenceName}
            renderGroup={renderGroupTasksTree}
            limitTags={LIMIT_TAGS}
            // Controlled loading mode
            open={open}
            onOpen={() => {
                setOpen(true);
                setLoadData(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
        />
    );
};
