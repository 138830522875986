import { Typography } from "@mui/material";
import React from "react";

import { useCoreIntl } from "src/hooks/useCoreIntl";
import { LayoutSidebarProvider } from "src/layouts/Layout_Sidebar/Layout_Sidebar_Provider";
import { Main } from "src/layouts/Layout_Sidebar/Main";
import { NavigationMenu } from "src/layouts/Layout_Sidebar/NavigationMenu/NavigationMenu";
import { SubProjectsNavigationMenu } from "src/layouts/Layout_Sidebar/NavigationMenu/SubProjectsNavigationMenu";
import {
    Styled_Header,
    Styled_LayoutSidebarContainer,
    Styled_NavigationMenuContainer,
    Styled_Sidebar,
} from "src/layouts/Layout_Sidebar/stylesheet";
import { Type_Props_Layout_Sidebar } from "src/layouts/Layout_Sidebar/types";

export const Layout_Sidebar = ({
    configuration,
    configurationSubProjects,
}: Type_Props_Layout_Sidebar) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        `MenuSidebar.${configuration.nameLabel}`,
    );

    const contextBlocks = () => {
        const configBlocks = configuration.blocks || [];
        const subProjectBlocks = configurationSubProjects?.blocks || [];

        return [...configBlocks, ...subProjectBlocks];
    };

    // merge configuration and configurationSubProjects
    // Useful for LayoutSidebarProvider to find current category
    const contextConfiguration = {
        ...configuration,
        blocks: contextBlocks(),
    };

    return (
        <LayoutSidebarProvider configuration={contextConfiguration}>
            <Styled_LayoutSidebarContainer data-testid="LayoutSidebar-Container">
                <Styled_Sidebar data-testid="LayoutSidebar-Sidebar-Container">
                    <Styled_Header data-testid="LayoutSidebar-Sidebar-Header">
                        <Typography
                            noWrap
                            title={fmt("Title")}
                            variant="h3"
                            data-testid={`LayoutSidebar-Title-${configuration.nameLabel}`}
                        >
                            {fmt("Title")}
                        </Typography>
                    </Styled_Header>
                    <Styled_NavigationMenuContainer>
                        <NavigationMenu
                            nameLabel={configuration.nameLabel}
                            baseUrl={configuration.baseUrl}
                            blocks={configuration.blocks}
                            data-testid="LayoutSidebar-Sidebar-NavigationMenu"
                        />
                        {configurationSubProjects && (
                            <SubProjectsNavigationMenu
                                nameLabel={configurationSubProjects.nameLabel}
                                baseUrl={configurationSubProjects.baseUrl}
                                blocks={configurationSubProjects.blocks}
                            />
                        )}
                    </Styled_NavigationMenuContainer>
                </Styled_Sidebar>
                <Main />
            </Styled_LayoutSidebarContainer>
        </LayoutSidebarProvider>
    );
};
