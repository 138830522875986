import { Grid } from "@mui/material";
import React, { memo, useState } from "react";

import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { Styled_ColorChip } from "src/components/Components_Common/_MuiComponentsVariants/Chip/Chip.style";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Type_Props_TextField } from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextField";
import {
    Styled_ButtonColorGrid,
    Styled_Popover,
    Styled_StackColorAdornment,
} from "src/components/Components_Common/ColorPicker/ColorPicker.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLOR_PICKER_COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

export type Type_ColorPickerChip = {
    id?: string | number;
    name?: string;
    color?: string;
    onClick?: (event: any) => void;
};

export const ColorPickerChip = ({
    id,
    color,
    name,
    ...restProps
}: Type_ColorPickerChip) => {
    return (
        <Styled_ColorChip
            {...restProps}
            data-testid={`ColorPickerChip-${name}-${id}`}
            data-testid-color={color}
            ownerState={{
                chipBackgroundColor: color ?? "transparent",
            }}
        />
    );
};

type Type_Props_ColorPopover = {
    anchorEl: HTMLButtonElement | HTMLDivElement | null;
    open: boolean;
    onClose: () => void;
    callback: (color: string) => void;
    selectedColors?: string[];
    currentColor?: string;
    name: string;
};

const ColorPopover = ({
    anchorEl,
    open,
    onClose,
    callback,
    selectedColors,
    currentColor,
    name,
}: Type_Props_ColorPopover) => {
    const handleColorPickerClick = (color: string): void => {
        callback(color);
        onClose();
    };

    return (
        <Styled_Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transitionDuration={{
                enter: 0,
                exit: 0,
                appear: 0,
            }}
        >
            <Grid container spacing={1} columns={8}>
                {COLOR_PICKER_COLORS.map((color: string, index: number) => {
                    return (
                        <Grid item xs={1} key={index}>
                            <Styled_ButtonColorGrid
                                onClick={() => handleColorPickerClick(color)}
                                ownerState={{ fullPaletteColor: color }}
                                data-testid={`ColorPicker-${name}-${color}`}
                            >
                                {(color === currentColor && (
                                    <Icon
                                        variant="solid"
                                        icon="check"
                                        sx={{
                                            color: invertColor(color),
                                        }}
                                        data-testid={`ColorPicker-${name}-${color}-check`}
                                    />
                                )) ||
                                    (selectedColors?.includes(color) && (
                                        <Icon
                                            variant="solid"
                                            icon="close"
                                            sx={{
                                                color: invertColor(color),
                                            }}
                                            data-testid={`ColorPicker-${name}-${color}-close`}
                                        />
                                    ))}
                            </Styled_ButtonColorGrid>
                        </Grid>
                    );
                })}
            </Grid>
        </Styled_Popover>
    );
};

const MemoizedColorPopover = memo(ColorPopover);

type Type_Props_ColorPicker = Omit<Type_Props_TextField, "slotProps"> & {
    name: string;
    callbackColor: (color: string) => void;
    currentColor?: string;
    selectedColors?: string[];
    isMatrix?: boolean;
};

export const TMC_ColorPicker = ({
    name,
    currentColor,
    selectedColors,
    callbackColor,
    error,
    helperText,
    isMatrix,
    ...restProps
}: Type_Props_ColorPicker) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Form.Field.ColorPicker",
    );

    const [anchorEl, setAnchorEl] = useState<
        HTMLButtonElement | HTMLDivElement | null
    >(null);

    const open: boolean = Boolean(anchorEl);

    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <>
            {isMatrix ? (
                <ColorPickerChip
                    onClick={handleClick}
                    color={currentColor}
                    name={name}
                    id={restProps.id}
                />
            ) : (
                <TMC_TextField
                    {...restProps}
                    value={fmt("PickAColour")}
                    name={name}
                    onClick={handleClick}
                    inputProps={{
                        readOnly: true,
                        "data-testid": `ColorPicker-${name}-selected`,
                        "data-testid-color": currentColor,
                        sx: {
                            "&:hover": {
                                cursor: "pointer",
                            },
                            paddingLeft: 1,
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <Styled_StackColorAdornment
                                sx={{
                                    backgroundColor:
                                        currentColor ?? "transparent",
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                            />
                        ),
                        endAdornment: (
                            <IconButton
                                size="small"
                                sx={{ cursor: "pointer" }}
                                color="secondary"
                            >
                                <Icon
                                    fontSize="small"
                                    icon={open ? "caret-up" : "caret-down"}
                                    variant={"solid"}
                                    color={"inherit"}
                                />
                            </IconButton>
                        ),
                    }}
                    {...(error && { error: error })}
                    {...(helperText && { helperText: helperText })}
                />
            )}
            <MemoizedColorPopover
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
                selectedColors={selectedColors}
                callback={callbackColor}
                currentColor={currentColor}
                name={name}
            />
        </>
    );
};
