import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { AreaTypeForm } from "src/forms/areaTypes/AreaTypeForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_AreaTypeDrawer = {
    open: boolean;
    onClose: () => void;
    areaTypeIdToUpdate: number | null;
    action: Type_action;
    page: string;
};

export const AreaTypeDrawer = ({
    open,
    onClose,
    areaTypeIdToUpdate,
    action,
    page,
}: Type_Props_AreaTypeDrawer) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.AreaTypes");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`AreaType-Drawer-${action}-Title`}
            title={
                action === "update"
                    ? fmt("Header.TitleUpdate")
                    : fmt("Header.TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"areaTypes"}
            page={page}
        >
            <AreaTypeForm
                onClose={onClose}
                areaTypeIdToUpdate={
                    areaTypeIdToUpdate === null ? undefined : areaTypeIdToUpdate
                }
                setIsLoading={setIsLoading}
                action={action}
            />
        </DrawerGeneric>
    );
};
