import {
    Type_index_version,
    Type_prj_index_version,
    Type_prj_show_version,
    Type_show_version,
} from "src/api/tms-projects/versions/types";
import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { formatDateTimeForFront } from "src/utils/date";

export const formatterIndexVersion = (
    version: Type_prj_index_version,
): Type_index_version => {
    return {
        id: version.id,
        name: version.name,
        enabled: version.enabled,
        currentVersion: version.currentVersion,
        definitiveVersion: version.definitiveVersion,
        transversal: version.transversal,
        locked: version.locked,
        baseVersion: version.baseVersion,
        createdAt: formatDateTimeForFront(version.created_at),
        updatedAt: formatDateTimeForFront(version.updated_at),
        users: version.users,
        roles: version.roles,
        notes: version.notes,
    };
};

export const formatterShowVersion = (
    version: Type_prj_show_version,
): Type_show_version => {
    return {
        id: version.id,
        names: version.names,
        enabled: version.enabled,
        currentVersion: version.currentVersion,
        transversal: version.transversal,
        locked: version.locked,
        baseVersion: version.baseVersion,
        createdAt: formatDateTimeForFront(version.created_at),
        updatedAt: formatDateTimeForFront(version.updated_at),
        notes: version.notes,
        users: version.users,
        roles: version.roles,
    };
};

// TODO add return type
export const formatterIndexVersions = (versions: Type_prj_index_version[]) => {
    if (!versions) return null;
    return versions.map(formatterIndexVersion);
};

// TODO add return type
export const formatterMutateVersion = (version: Type_prj_index_version) => {
    return {
        ...version,
        baseVersion: version.baseVersion || false,
        currentVersion: version.currentVersion || false,
        definitiveVersion: version.definitiveVersion || false,
        locked: version.locked || false,
        users: version.users?.map((user) => user.id),
        roles: version.roles?.map((role) => role.id),
    };
};

export const formatterIndexVersionToSelectOptionItem = (
    version: Type_index_version,
): Type_SelectOptionItem => {
    return {
        label: version.currentVersion ? "current" : version.name,
        value: version.id,
    };
};

export const formatterIndexVersionsToSelectList = (
    versions: Type_index_version[],
): Type_SelectOptionItem[] => {
    return versions.map(formatterIndexVersionToSelectOptionItem);
};
