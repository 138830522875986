export const sortData = (data: any[], keyToCompare: string) => {
    return data.sort((a, b) => a[keyToCompare]?.localeCompare(b[keyToCompare]));
};

export const sortDataByKeys = (data: any[], keysToCompare: string[]) => {
    return data.sort((a, b) => {
        for (const key of keysToCompare) {
            const comparison = a[key]?.localeCompare(b[key]);
            if (comparison !== 0) {
                return comparison;
            }
        }
        return 0;
    });
};

export const sortDscData = (data: any[], keyToCompare: string) => {
    return data.sort((a, b) => b[keyToCompare]?.localeCompare(a[keyToCompare]));
};
