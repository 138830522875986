import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import logoTeamoty from "src/assets/images/logoTeamoty.png";
import {
    PagesTabs,
    Type_TopBarPages,
} from "src/components/Components_Teamoty/TopBar/PagesTabs/PagesTabs";
import {
    APP_BAR_HEIGHT,
    TeamotyLogoBlur,
} from "src/components/Components_Teamoty/TopBar/stylesheet";
import { ProjectMenuButton } from "src/components/Components_Teamoty/TopBar/TopBar_Left/ProjectMenu/ProjectMenuButton";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { ProjectMenu } from "./ProjectMenu/ProjectMenu";

export const TopBar_Left = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("TopBar.Menus");
    const urlParams = useParams();

    const isProjectRoute = !!urlParams.projectSlug;

    const pages: Type_TopBarPages[] = [
        {
            key: fmt("Projects"),
            path: "projects",
        },
        {
            key: fmt("Library"),
            path: "library",
        },
        {
            key: fmt("Statistics"),
            path: "statistics",
        },
    ];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            paddingX={0}
            overflow={"hidden"}
        >
            <Box height={APP_BAR_HEIGHT} position={"relative"}>
                <TeamotyLogoBlur />
            </Box>

            {isProjectRoute ? (
                <>
                    <ProjectMenuButton handleClick={handleClick} />

                    {open && (
                        <ProjectMenu
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            open={open}
                        />
                    )}
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ color: COLORS.moonAlpha500 }}
                    />
                    <div id={"workspacesMenuContent"}></div>
                    <div id={"topBarLeftContent"}></div>
                </>
            ) : (
                <>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        paddingX={4}
                        paddingY={2}
                    >
                        <img
                            alt={"teamoty logo"}
                            src={logoTeamoty}
                            width={21}
                            style={{ zIndex: 1 }}
                        />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: "flex",
                            height: "100%",
                            gap: 4,
                        }}
                    >
                        <PagesTabs pages={pages} />
                    </Box>
                </>
            )}
        </Box>
    );
};
