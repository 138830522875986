import { Grid, styled } from "@mui/material";

export const SIDEBAR_WIDTH: number = 274;
export const HEADER_HEIGHT: number = 48;

export const Styled_Layout = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexGrow: 1,
}));

export const Styled_Header = styled(Grid)(({ theme }) => ({
    width: "100%",
    height: HEADER_HEIGHT,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.border.default,
    padding: theme.spacing(2),
}));
Styled_Header.defaultProps = {
    container: true,
};

export const Styled_Main = styled(Grid)(() => ({
    flexGrow: 1,
}));
Styled_Main.defaultProps = {
    container: true,
};

export const Styled_Sidebar = styled(Grid)(({ theme }) => ({
    width: SIDEBAR_WIDTH,
    padding: `0 ${theme.spacing(2)}`,
    borderRight: theme.border.default,
    backgroundColor: theme.palette.background.paper,
}));

export const Styled_Section = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
}));
