import { Box, Popover, Stack, TextFieldProps, Typography } from "@mui/material";
import {
    SimpleTreeView,
    TreeItem,
    TreeItemContentProps,
    TreeItemProps,
    useTreeItemState,
} from "@mui/x-tree-view";
import { clsx } from "clsx";
import React, { forwardRef, SyntheticEvent, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Type_RequestConfig } from "src/api/fetch";
import { useSelectListAreas } from "src/api/tms-projects/areas";
import {
    Type_index_area,
    Type_select_area,
} from "src/api/tms-projects/areas/types";
import { LoadingBox, TMC_TextField } from "src/components/Components_Common";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { AreasTreeView } from "src/components/Components_Teamoty/trees/AreasTreeView";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_resetValue = { id: null; name: string };

export const findValue = (
    arr: Type_select_area[],
    id: number | null,
    resetValue: Type_resetValue,
): Type_resetValue | Type_select_area => {
    if (id === null) return resetValue;
    let result: Type_resetValue | Type_select_area = resetValue;
    arr.forEach((obj: Type_select_area): void => {
        if (obj.id === id) {
            result = obj;
        }
        if (obj.children && result.id === null) {
            const found: Type_resetValue | Type_select_area = findValue(
                obj.children,
                id,
                resetValue,
            );
            if (found.id !== null) result = found;
        }
    });
    return result;
};

/**
 * Component from MuiX doc
 * @see https://v6.mui.com/x/react-tree-view/#contentcomponent-prop
 */
const CustomContent = forwardRef(function CustomContent(
    props: TreeItemContentProps,
    ref,
) {
    const {
        classes,
        className,
        label,
        itemId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItemState(itemId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        preventSelection(event);
    };

    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleSelection(event);
    };

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div
                onClick={handleExpansionClick}
                className={classes.iconContainer}
            >
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});

/**
 * Component from MuiX doc
 * @see https://v6.mui.com/x/react-tree-view/#contentcomponent-prop
 */
const CustomTreeItem = forwardRef(function CustomTreeItem(
    props: TreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
});

const renderOptions = (option: Type_select_area) => {
    return (
        <CustomTreeItem
            key={option.id}
            itemId={String(option.id)}
            label={option.name}
        >
            {option.children?.map((child: Type_select_area) =>
                renderOptions(child),
            )}
        </CustomTreeItem>
    );
};

type Type_Props_AutocompleteAreas = TextFieldProps & {
    label: string;
    name: string;
    multiple?: boolean;
    requestConfig?: Type_RequestConfig;
    areaId?: number;
};

/**
 * @deprecated use AutocompleteAreas instead
 * @param label
 * @param name
 * @param multiple
 * @param areaId
 * @constructor
 */
export const AutocompleteAreasCustom = ({
    label,
    name,
    multiple = false,
    areaId,
}: Type_Props_AutocompleteAreas) => {
    // i18n
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtPlaceholder } =
        useCoreIntl("Form.Placeholder");

    // State
    const [checkedAreas, setCheckedAreas] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // API
    const { isFetching: isFetchingSelectAreas, data: areas } =
        useSelectListAreas(!multiple) || {};

    // Rhf
    const { formState, control, getValues, setValue } = useFormContext(); // retrieve those props

    const resetValue: Type_resetValue | number[] = multiple
        ? []
        : { id: null, name: "" };

    useEffect((): void => {
        !isFetchingSelectAreas &&
            getValues(name) &&
            areas &&
            setValue(
                name,
                findValue(
                    areas as Type_select_area[],
                    getValues(name).id,
                    resetValue as Type_resetValue,
                ),
            );
    }, [isFetchingSelectAreas]);

    useEffect(() => {
        if (checkedAreas) setValue(name, checkedAreas);
    }, [checkedAreas]);

    const handleReset = (): void => {
        setValue(name, resetValue);
    };

    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };
    const open: boolean = Boolean(anchorEl);
    const id: "simple-popover" | undefined = open
        ? "simple-popover"
        : undefined;

    const handleSelect = (
        _event: SyntheticEvent<Element, Event>,
        nodeId: any,
    ): void => {
        const selectedArea = findValue(
            areas as Type_select_area[],
            parseInt(nodeId),
            resetValue as Type_resetValue,
        );
        setValue(name, selectedArea);
        handleClose();
    };

    const removeAreaFromHierarchy = (
        areas: Type_select_area[],
        id: number,
    ): Type_select_area[] => {
        return areas?.reduce((acc, area) => {
            if (area.id === areaId) {
                return acc;
            }
            const updatedArea = {
                ...area,
                children: area.children
                    ? removeAreaFromHierarchy(area.children, id)
                    : undefined,
            };
            return [...acc, updatedArea];
        }, [] as Type_select_area[]);
    };

    const areasToDisplay: Type_select_area[] = areaId
        ? removeAreaFromHierarchy(areas as Type_select_area[], areaId)
        : (areas as Type_select_area[]);

    return (
        <Stack flex={1}>
            <Controller
                control={control}
                name={name}
                render={({ field: { ref, value, ...field } }) => {
                    return (
                        <TMC_TextField
                            onClick={handleClick}
                            label={label}
                            value={
                                multiple && value
                                    ? `${checkedAreas?.length} ${fmtPlaceholder("ZonesSelected")}`
                                    : value && value.name
                                      ? value.name
                                      : ""
                            }
                            fullWidth
                            inputRef={ref}
                            inputProps={{ readOnly: true }}
                            {...field}
                            error={!!formState.errors[name]}
                            helperText={
                                formState.errors[name]?.message as string
                            }
                        />
                    );
                }}
            />
            {!multiple && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <button type="button" onClick={handleReset}>
                        <Typography variant={"buttonSmall"} sx={{ margin: 0 }}>
                            {fmtActions("Clear")}
                        </Typography>
                    </button>
                </div>
            )}
            <div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    sx={{ backgroundColor: "transparent", scroll: "auto" }}
                >
                    <Box sx={{ minWidth: "600px", p: 2 }}>
                        {multiple ? (
                            isFetchingSelectAreas ? (
                                <LoadingBox />
                            ) : (
                                <AreasTreeView
                                    height={"70vh"}
                                    areas={areas as Type_index_area[]}
                                    selectedAreas={checkedAreas}
                                    setSelectedAreas={setCheckedAreas}
                                />
                            )
                        ) : (
                            <SimpleTreeView
                                slots={{
                                    expandIcon: MyExpandIcon,
                                    collapseIcon: MyCollapseIon,
                                }}
                                onSelectedItemsChange={handleSelect}
                            >
                                {isFetchingSelectAreas ? (
                                    <LoadingBox />
                                ) : (
                                    areasToDisplay?.map((datum) =>
                                        renderOptions(datum),
                                    )
                                )}
                            </SimpleTreeView>
                        )}
                    </Box>
                </Popover>
            </div>
        </Stack>
    );
};

const MyExpandIcon = () => {
    return <Icon variant="solid" icon="chevron-right" />;
};

const MyCollapseIon = () => {
    return <Icon variant="solid" icon="chevron-down" />;
};
