import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { ResourcePeriodKeys } from "src/api/tms-projects/keys";
import {
    formatterCreateResourcePeriod,
    formatterIndexResourcePeriods,
    formatterUpdateResourcePeriod,
} from "src/api/tms-projects/resourcePeriods/formatters";
import {
    createResourcePeriod,
    indexResourcePeriods,
    updateResourcePeriod,
} from "src/api/tms-projects/resourcePeriods/services";
import {
    Type_index_resourcePeriod,
    Type_post_resourcePeriod,
    Type_put_resourcePeriod,
} from "src/api/tms-projects/resourcePeriods/types";
import { SMALL_STALE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexResourcePeriods = (resourceId: number) => {
    const { requestConfig, isLoading } = useProject();

    const requestConfigResourcePeriods: Type_RequestConfig = {
        ...requestConfig,
        resourceId: resourceId,
    };

    return useQuery({
        queryKey: [ResourcePeriodKeys.INDEX, requestConfigResourcePeriods],
        queryFn: () => indexResourcePeriods(requestConfigResourcePeriods),
        refetchOnWindowFocus: false,
        select: (data): Type_index_resourcePeriod[] => {
            return formatterIndexResourcePeriods(data.data.data);
        },
        enabled: !!requestConfig.projectId && !!resourceId && !isLoading,
        staleTime: SMALL_STALE_TIME,
    });
};

export const mutationCreateResourcePeriod = () => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (resourcePeriod: Type_post_resourcePeriod) => {
            return createResourcePeriod(
                {
                    ...requestConfig,
                    resourceId: resourcePeriod.id,
                },
                formatterCreateResourcePeriod(resourcePeriod),
            );
        },
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateResourcePeriod",
                );
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateResourcePeriod = () => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    let values: Type_put_resourcePeriod;

    return useMutation({
        mutationFn: (resourcePeriod: Type_put_resourcePeriod) => {
            values = resourcePeriod;
            return updateResourcePeriod(
                {
                    ...requestConfig,
                    resourceId: resourcePeriod.id,
                },
                formatterUpdateResourcePeriod(resourcePeriod),
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateResourcePeriod",
                );
            }
            await queryClient.invalidateQueries([
                ResourcePeriodKeys.INDEX,
                {
                    ...requestConfig,
                    resourceId: values.id as number,
                },
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
