import { DrawerProps } from "@mui/material";

import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

import { PlanningSettingsForm } from "./PlanningSettingsForm";

type Type_Props_PlanningSettingsContextualDrawer = DrawerProps & {
    onClose: () => void;
};

export const PlanningSettingsContextualDrawer = ({
    onClose,
}: Type_Props_PlanningSettingsContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout id="planningSettings">
            <PlanningSettingsForm onClose={onClose} />
        </Styled_PaperContextualLayout>
    );
};
