import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

import { Alert } from "src/pages/Pages_Common/Page_Demo/components/buttons/Alert";
import { Clear } from "src/pages/Pages_Common/Page_Demo/components/buttons/Clear";
import { InheritWhite } from "src/pages/Pages_Common/Page_Demo/components/buttons/InheritWhite";
import { Primary } from "src/pages/Pages_Common/Page_Demo/components/buttons/Primary";
import { Secondary } from "src/pages/Pages_Common/Page_Demo/components/buttons/Secondary";
import { Success } from "src/pages/Pages_Common/Page_Demo/components/buttons/Success";

export const Demo_Buttons = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1} columns={7} padding={4}>
                {/* HEADER */}
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    DEFAULT
                </Grid>
                <Grid item xs={1}>
                    DISABLED
                </Grid>
                <Grid item xs={1}>
                    LOADING
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    DEFAULT
                </Grid>
                <Grid item xs={1}>
                    DISABLED
                </Grid>

                <Primary />
                <Secondary />
                <Clear />
                <Alert />
                <Success />
                <InheritWhite />
            </Grid>
        </Box>
    );
};
