import { Box, CardHeader, darken, Popover } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const Styled_PopoverAvatar = styled(Popover)(({ theme }) => ({
    "&.MuiPopover-root": {
        "& .MuiList-root": {
            padding: 0,
            border: "none",
        },
        "& .MuiPopover-paper": {
            width: "320px",
            borderRadius: theme.shape.borderRadius,
            padding: 0,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
        },
    },
}));

export const Styled_CardHeaderMenuAvatarDropdown = styled(CardHeader)(
    ({ theme }: any) => ({
        paddingBottom: theme.spacing(2),
        "&& .MuiCardHeader-title": {
            ...theme.typography.body1Bold,
            textTransform: "none",
        },
        "&& .MuiCardHeader-subheader": {
            ...theme.typography.body3,
            color: COLORS.moon500,
        },
        "&& .MuiCardHeader-avatar": {
            marginRight: "8px",
        },
    }),
);

export const Styled_CardHeaderCoverMenuDropDown = styled(Box)(({ color }) => ({
    position: "relative",
    borderRadius: "4px 4px 0px 0px",
    background: `linear-gradient(
        to right, 
        ${color}, 
        ${darken(color as string, 0.16)}
    )`,
    width: "100%",
    height: 70,
}));
