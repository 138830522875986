import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Styled_FlowToolbarDivider = styled(Divider)(({ theme }: any) => ({
    backgroundColor: theme.palette.common.white,
}));

export const FlowToolbarDivider = () => {
    return (
        <Styled_FlowToolbarDivider
            orientation="vertical"
            variant="middle"
            flexItem
        />
    );
};
