import { Components } from "@mui/material";

export const MuiToggleButtonTheme: Components["MuiToggleButton"] = {
    styleOverrides: {
        root: ({ theme }: any) => ({
            textTransform: "none",
            "&.Mui-selected": {
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.primary.light,
            },
            div: {
                display: "flex",
                justifyContent: "center",
            },
        }),
    },
};
