import { DataGridPremiumProps, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction } from "react";

import { Type_index_area } from "src/api/tms-projects/areas/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { TreeTable } from "src/components/Components_Common/TreeTable/TreeTable";
import { AreaCell } from "src/components/Components_Teamoty/cells";
import { AreaDrawer } from "src/drawers/areas/AreaDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Type_changeVisibilityAreaParams,
    Type_modalDeleteArea,
} from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Areas/Table_Areas/TableAreas.container";

type Type_Props_TableAreas = {
    isFetching: boolean;
    areas: Type_index_area[];
    page: string;
    duplicateArea: (id: number) => void;
    changeVisibilityArea: ({
        id,
        show,
    }: Type_changeVisibilityAreaParams) => void;
    changeStatusArea: (area: Type_index_area) => void;
    modalDelete: Type_modalDeleteArea;
    setModalDelete: Dispatch<SetStateAction<Type_modalDeleteArea>>;
    deleteArea: (id: number) => void;
    handleAreaEdit: (row: GridRowModel) => void;
    handleAreaCreate: () => void;
    handleCloseAreaDrawer: () => void;
    selectedArea: number | null;
    openAreaDrawer: boolean;
    action: Type_action;
};

export const TableAreasComponent = ({
    isFetching,
    areas,
    page,
    duplicateArea,
    changeVisibilityArea,
    changeStatusArea,
    modalDelete,
    setModalDelete,
    deleteArea,
    handleAreaEdit,
    handleAreaCreate,
    handleCloseAreaDrawer,
    selectedArea,
    openAreaDrawer,
    action,
}: Type_Props_TableAreas) => {
    const { formatMessageWithPartialKey: fmtTable } = useCoreIntl("Table");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );

    //CALLBACK FUNCTIONS
    const callbackEdit = (row: GridRowModel) => {
        handleAreaEdit(row);
    };

    const callbackDuplicate = (row: GridRowModel) => {
        duplicateArea(row.id);
    };

    const callbackVisibility = (row: GridRowModel) => {
        changeVisibilityArea({ id: row.id, show: row.show });
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatusArea(row as Type_index_area);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDelete({
            isOpen: true,
            props: row as Type_index_area,
        });
    };

    const columns: DataGridPremiumProps["columns"] = [
        {
            field: "areaTypeName",
            flex: 1,
            headerName: fmtTable("Column.AreaType"),
        },
    ];

    const menuItems = (row: GridRowModel) => {
        if (!row) return [];

        return !row.hasParentHidden
            ? [
                  {
                      label: fmt(row.show ? "Hide" : "Show"),
                      icon: {
                          name: row.show ? "eye-slash" : "eye",
                          variant: "light",
                      },
                      call: (props: GridRowModel) => {
                          callbackVisibility(props);
                      },
                  },
              ]
            : [];
    };

    return (
        <>
            <TreeTable
                treeData
                columns={columns}
                data={areas}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleAreaCreate(),
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackStatus,
                    callbackDelete,
                    callbackDuplicate,
                }}
                menuItems={menuItems}
                onClick={(row: GridRowModel) => {
                    handleAreaEdit(row);
                }}
                page={page}
                hierarchy={"children"}
                groupHeaderName={fmtTable("Column.Areas")}
                groupingCellComponent={(row) => <AreaCell row={row} />}
            />
            <AreaDrawer
                formId={"areas"}
                page={page}
                onClose={handleCloseAreaDrawer}
                open={openAreaDrawer}
                action={action}
                areaIdToUpdate={selectedArea}
            />
            <ModalDelete
                open={modalDelete.isOpen}
                onClose={() => setModalDelete({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDelete?.props?.name as string}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDelete({ isOpen: false });
                            deleteArea(modalDelete?.props?.id as number);
                        },
                    },
                }}
            />
        </>
    );
};
