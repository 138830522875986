import { Stack, Switch, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import {
    Header,
    LoadingBox,
    Spinner,
    TMC_Button,
    TMC_TextField,
} from "src/components";
import {
    Styled_FormDrawer,
    Styled_MainStack,
    Styled_StackActionDrawer,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { MultiLanguages } from "src/components/Components_Common/forms";
import { multiLanguagesSchema } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.container";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Editor } from "src/components/Components_Common/richText/Editor";
import { EditorBox } from "src/components/Components_Common/richText/Editor.style";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

export type Type_Version_Duplicate_DrawerContentComponent_Props = {
    isFetching: boolean;
    onClose: () => void;
    onSubmit: (values: any, props: any) => void;
    data: any;
    isLoading: boolean;
};

export const Version_Duplicate_DrawerContentComponent = ({
    isFetching,
    onClose,
    onSubmit,
    data,
    isLoading,
}: Type_Version_Duplicate_DrawerContentComponent_Props) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Versions.Drawer",
    );

    const VersionSchema = Yup.lazy(() => {
        return Yup.object().shape({
            names: Yup.object().shape(multiLanguagesSchema.fields),
            transversal: Yup.boolean().default(false),
            projection: Yup.boolean().default(false),
        });
    });

    return (
        <>
            {isFetching && <LoadingBox />}
            {!isFetching && (
                <Styled_MainStack>
                    <Header
                        onClose={onClose}
                        title={`${fmt("DuplicateVersion")} : ${data.name}`}
                        dataTestIdTitle={"Version-Drawer-Duplicate-Title"}
                    />
                    <Formik
                        initialValues={data}
                        onSubmit={onSubmit}
                        validationSchema={VersionSchema}
                        validateOnChange={false}
                    >
                        {({ errors, handleChange, values }) => {
                            return (
                                <Styled_FormDrawer>
                                    <Styled_StackContentDrawer>
                                        <Stack spacing={6}>
                                            <MultiLanguages
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                            />

                                            <Stack
                                                display={"flex"}
                                                direction={"row"}
                                                justifyContent={"space-between"}
                                            >
                                                <Stack
                                                    display={"flex"}
                                                    direction={"column"}
                                                >
                                                    <Typography variant="body1">
                                                        {fmt("CrossChecking")}
                                                    </Typography>
                                                    <Typography
                                                        color={COLORS.moon500}
                                                        variant="body2"
                                                    >
                                                        {fmt(
                                                            "CrossCheckingSubTitle",
                                                        )}
                                                    </Typography>
                                                </Stack>

                                                <Switch
                                                    checked={values.transversal}
                                                    onChange={handleChange}
                                                    name="crossChecking"
                                                    value={values.transversal}
                                                />
                                            </Stack>
                                            <Stack spacing={2}>
                                                <Typography variant="h3">
                                                    {fmt(`VersionNote`)}
                                                </Typography>
                                                <TMC_TextField placeholder="" />
                                                <EditorBox>
                                                    <Editor
                                                        name={"note"}
                                                        buttonBold
                                                        buttonItalic
                                                        buttonUnderline
                                                        buttonOrderedList
                                                        buttonBulletedList
                                                        buttonEditLink
                                                    />
                                                </EditorBox>
                                            </Stack>
                                            <Stack spacing={2}>
                                                <Typography variant="h3">
                                                    {fmt(`Projection`)}
                                                </Typography>
                                                <Stack
                                                    display={"flex"}
                                                    direction={"row"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                >
                                                    <Stack
                                                        display={"flex"}
                                                        direction={"column"}
                                                    >
                                                        <Typography variant="body1">
                                                            {fmt(
                                                                `AllowProjection`,
                                                            )}
                                                        </Typography>
                                                    </Stack>

                                                    <Switch
                                                        checked={
                                                            values.projection
                                                        }
                                                        onChange={handleChange}
                                                        name="projection"
                                                        value={
                                                            values.projection
                                                        }
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Styled_StackContentDrawer>

                                    <Styled_StackActionDrawer>
                                        <TMC_Button
                                            data-testid="Versions-Drawer-Save-Duplicate"
                                            variant="contained"
                                            type="submit"
                                            disabled={isLoading}
                                            sx={{ minWidth: 200 }}
                                            endIcon={
                                                !isLoading && (
                                                    <Icon
                                                        variant="solid"
                                                        icon="check"
                                                    />
                                                )
                                            }
                                        >
                                            {isLoading ? (
                                                <Spinner />
                                            ) : (
                                                fmt(`CtaSave`)
                                            )}
                                        </TMC_Button>
                                    </Styled_StackActionDrawer>
                                </Styled_FormDrawer>
                            );
                        }}
                    </Formik>
                </Styled_MainStack>
            )}
        </>
    );
};
