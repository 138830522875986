import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { useSelectListResources } from "src/api/tms-projects/resources";
import { Type_select_resource } from "src/api/tms-projects/resources/types";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { ResourceDialog } from "src/dialogs/resources/ResourceDialog";
import { COLORS } from "src/theme/stylesheet";
import { sortDataByKeys } from "src/utils/sortData";

type Type_Props_AutocompleteResource = {
    name: string;
    label?: string;
    desc?: string;
    placeholder?: string;
    projectId?: number;
    subProjectId?: number;
    autoFocus?: boolean;
    disabledOptions?: number[];
    createResource?: (resourceId: number) => Promise<void>;
};

export const AutocompleteResources = ({
    name,
    label,
    autoFocus = false,
    disabledOptions = [],
    desc,
    createResource,
    placeholder = "",
}: Type_Props_AutocompleteResource) => {
    const [active, setIsActive] = useState<boolean>(false);
    const { isFetching, data: resources } =
        useSelectListResources(active) || {};
    const [openDialog, toggleOpenDialog] = useState(false);
    const [resourceName, setResourceName] = useState<string>("");

    const handleClose = () => {
        setResourceName("");
        toggleOpenDialog(false);
    };

    const page = "resources";

    return (
        <Stack width={"100%"} flexDirection={"column"}>
            <AutocompleteFreeSoloDialog
                autoFocus={autoFocus}
                name={name}
                onOpen={() => {
                    setIsActive(true);
                }}
                label={label}
                renderInputProps={{
                    placeholder: placeholder,
                }}
                loading={isFetching}
                groupBy={(option: Type_select_resource) =>
                    option?.resourceType?.name as string
                }
                options={
                    resources
                        ? sortDataByKeys(resources, [
                              "resourceTypeName",
                              "name",
                          ])
                        : []
                }
                getOptionDisabled={(option) =>
                    disabledOptions?.includes(+option.id)
                }
                onAddNewValue={(value) => {
                    setResourceName(value.name);
                    toggleOpenDialog(true);
                }}
            />
            {desc?.length && (
                <Typography variant={"body2"} color={COLORS.moon500}>
                    {desc}
                </Typography>
            )}
            <ResourceDialog
                open={openDialog}
                onClose={handleClose}
                createResource={createResource}
                resourceName={resourceName}
                page={page}
            />
        </Stack>
    );
};
