import { useMutation, useQuery, useQueryClient } from "react-query";

import { ProjectUserKeys } from "src/api/tms-projects/keys";
import {
    Type_post_projectUserInvitation,
    Type_prj_index_projectUserWithMeta,
    Type_put_projectUser,
} from "src/api/tms-projects/projectUsers/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterCreateProjectUserInvitation,
    formatterIndexProjectUsersWithMeta,
    formatterSelectProjectUsers,
    formatterShowProjectUser,
    formatterUpdateProjectUser,
} from "./formatters";
import {
    createProjectUser,
    deleteProjectUser,
    indexProjectUsers,
    selectListProjectUsers,
    showProjectUser,
    updateProjectUser,
} from "./services";

export const useIndexProjectUsers = () => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [ProjectUserKeys.INDEX, requestConfig],
        queryFn: () => indexProjectUsers(requestConfig),
        refetchOnWindowFocus: false,
        select: (res) => {
            if (!res?.success || !res?.data) {
                throw new Error("Wrong format data: useIndexProjectUsers");
            }
            return formatterIndexProjectUsersWithMeta(
                res?.data as Type_prj_index_projectUserWithMeta,
            );
        },
        onError: (err) => {
            return err;
        },
        enabled: !!requestConfig.projectId,
    });
};

export const useSelectListProjectUsers = () => {
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useQuery({
        queryKey: [ProjectUserKeys.SELECT_LIST, { ...requestConfig }],
        queryFn: () => selectListProjectUsers({ ...requestConfig }),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterSelectProjectUsers(data.data.data);
        },
        onError: (err) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const useShowProjectUser = (id: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [ProjectUserKeys.SHOW, id, requestConfig],
        queryFn: () => showProjectUser(id, requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data) {
                throw new Error("Wrong format data: useIndexProjectUsers");
            }

            return formatterShowProjectUser(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const mutationUpdateProjectUser = (
    withToastSubscription: boolean = false,
) => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Users",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_put_projectUser) =>
            updateProjectUser(
                data.id,
                formatterUpdateProjectUser(data),
                requestConfig,
            ),
        onSuccess: async (res) => {
            if (!res?.success || !res?.data) {
                throw new Error("Wrong format data: mutationUpdateProjectUser");
            }

            if (withToastSubscription) {
                addSuccess({
                    description: fmt("ToastSuccessAccepted"),
                });
            }

            await queryClient.invalidateQueries([
                ProjectUserKeys.INDEX,
                { ...requestConfig },
            ]);
        },
        onError: (err: any) => {
            addWarning({ description: fmtErr("GenericError", {}) });
            return err;
        },
    });
};

export const mutationCreateProjectUser = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (data: {
            data: Type_post_projectUserInvitation;
            userId: number;
        }) =>
            createProjectUser(
                formatterCreateProjectUserInvitation(data.data, data.userId),
                { ...requestConfig, subProjectId: data.data.subProject },
            ),
        onSuccess: async (res) => {
            if (!res?.success || !res?.data) {
                throw new Error("Wrong format data: mutationCreateProjectUser");
            }

            await queryClient.invalidateQueries([
                ProjectUserKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusProjectUser = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (data: Type_put_projectUser) => {
            return updateProjectUser(
                data.id,
                formatterUpdateProjectUser(data),
                requestConfig,
            );
        },
        onSuccess: async (res) => {
            if (!res?.success || !res?.data) {
                throw new Error("Wrong format data: mutationStatusProjectUser");
            }

            await queryClient.invalidateQueries([
                ProjectUserKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteProjectUser = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (id: number) => deleteProjectUser(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationDeleteProjectUser");
            }

            await queryClient.invalidateQueries([
                ProjectUserKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeclineProjectUser = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Users",
    );
    const { addSuccess, addWarning } = useToast();

    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (id: number) => deleteProjectUser(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationDeclineProjectUser",
                );
            }

            addSuccess({
                description: fmt("ToastSuccessDeclined"),
            });

            await queryClient.invalidateQueries([
                ProjectUserKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};
