import { InputButtonPropsType } from "./InputButton.container";

export type InputButtonType = {
    type: "inputButton";
    label?: {
        label?: string;
        languageLabel?: string;
    };
    options?: any;
};

export const InputButtonComponent = ({
    label,
    props,
    onClick,
    type,
}: InputButtonPropsType) => {
    return (
        <button
            type={type}
            {...props}
            onClick={onClick}
            className={
                `bg-teamoty-primary text-white px-4 py-2 rounded-md shadow-md` +
                " " +
                props?.className
            }
        >
            {label}
        </button>
    );
};
