import { TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_TabButton = styled(Tab)(() => ({
    textTransform: "none",
}));

export const Styled_TabPanelPermissionSelection = styled(TabPanel)(
    ({ theme }: any) => ({
        padding: `${theme.spacing(3)} 0 0 0`,
        // Calc view height - padding - header - footer - tabs drawer -tabs and search permissions
        height: "calc(100vh - 32px - 80px - 80px - 70px - 182px)",
        overflow: "auto",

        "&.requiredText": {
            height: "calc(100vh - 32px - 80px - 80px - 70px - 182px - 28px)",
        },

        "&.searchText": {
            height: "calc(100vh - 32px - 80px - 80px - 70px - 182px - 24px)",
        },

        "&.searchText.requiredText": {
            height: "calc(100vh - 32px - 80px - 80px - 70px - 182px - 24px - 28px)",
        },
    }),
);
