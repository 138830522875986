// Utilisation d'un context pour le select d'une zone
import React, {
    createContext,
    ReactElement,
    useContext,
    useState,
} from "react";

type Type_stageContext = {
    setContextState: React.Dispatch<React.SetStateAction<Type_stageContext>>;
    stageScale: number;
    stageX: number;
    stageY: number;
    isSelecting: number;
    changeSelecting: (index: number) => void;
};

type Type_Props_stageProvider = {
    children: ReactElement;
};

const initialStageContext: Type_stageContext = {
    setContextState: () => {},
    stageScale: 1,
    stageX: 0,
    stageY: 0,
    isSelecting: -1,
    changeSelecting: (index: number): void => {
        console.debug("changeSelecting initial function", index);
    },
};
const StageContext = createContext<Type_stageContext>(initialStageContext);

// Exportez le hook useStage après la déclaration du contexte
export const useStageContext = () => {
    const context = useContext(StageContext);
    if (!context) {
        throw new Error("useStage must be used within a StageProvider");
    }
    return context;
};

export const StageProvider = ({ children }: Type_Props_stageProvider) => {
    const [contextState, setContextState] =
        useState<Type_stageContext>(initialStageContext);

    const changeSelecting = (index: number) => {
        setContextState((prev) => ({
            ...prev,
            isSelecting: index,
        }));
    };

    const contextFunction = {
        setContextState,
        changeSelecting,
    };

    return (
        <StageContext.Provider value={{ ...contextState, ...contextFunction }}>
            {children}
        </StageContext.Provider>
    );
};
