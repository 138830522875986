import { Stack } from "@mui/material";
import {
    DateTimeRangePickerProps,
    DateTimeRangePicker as MuiDateTimeRangePicker,
    renderDigitalClockTimeView,
} from "@mui/x-date-pickers-pro";
import React, { forwardRef } from "react";

import {
    leftArrowIcon,
    rightArrowIcon,
    switchViewIcon,
} from "src/components/Components_Common/_MuiComponentsVariants/DatePicker/DatePicker.style";
import {
    TMC_FormHelperText,
    TMC_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants/index";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_DateTimeRangePickerProps =
    DateTimeRangePickerProps<any> &
        Type_ReadonlyComponent & {
            name?: string;
            helperText?: string;
            error?: boolean;
        };

export const DateTimeRangePicker = forwardRef<
    any,
    Type_Props_DateTimeRangePickerProps
>(({ helperText, name, error, readonly, ...restProps }, ref) => {
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    return (
        <Stack
            flexDirection="column"
            data-testid={`DateTimeRangePicker-${name}-container`}
        >
            <MuiDateTimeRangePicker
                timeSteps={{ ...restProps.timeSteps, minutes: 30 }}
                viewRenderers={{
                    ...restProps.viewRenderers,
                    hours: renderDigitalClockTimeView,
                }}
                slots={{
                    leftArrowIcon: leftArrowIcon,
                    rightArrowIcon: rightArrowIcon,
                    switchViewIcon: switchViewIcon,
                    textField: TMC_TextField,
                }}
                localeText={{
                    start: fmtLabels("Start"),
                    end: fmtLabels("End"),
                }}
                disabled={readonly || restProps.disabled}
                sx={{
                    "&.MuiMultiInputDateTimeRangeField-root": {
                        alignItems: "end",
                        "& .MuiStack-root": {
                            flex: 1,
                        },
                        "& .MuiMultiInputDateTimeRangeField-separator": {
                            paddingBottom: "9px",
                        },
                    },
                }}
                {...restProps}
                ref={ref}
            />
            {helperText && error && (
                <TMC_FormHelperText
                    helperText={helperText as string}
                    inputName={name as string}
                />
            )}
        </Stack>
    );
});

DateTimeRangePicker.displayName = "DateTimeRangePicker";
