import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { TagsPath, Url } from "src/api/paths";

import { Type_sch_post_tag } from "./types";

export const selectTags = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${TagsPath.TAGS_LIST}`, requestConfig);
};

export const createTag = (
    data: Type_sch_post_tag,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.SCHEDULING}${TagsPath.TAGS}`, requestConfig);
};
