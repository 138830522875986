import {
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    AutocompleteValue,
    Chip,
    MenuItem,
    Autocomplete as MuiAutocomplete,
    Skeleton,
} from "@mui/material";
import {
    AutocompleteProps,
    AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete/Autocomplete";
import { ChipTypeMap } from "@mui/material/Chip";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Type_selectList } from "src/api/types";
import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

export type Type_selectListTree = Type_selectList & { depth: number };

export const formatSelectListToSelectListTree = (
    data: Type_selectList[],
): Type_selectListTree[] => {
    return data.map(({ depth, ...item }) => ({
        depth: depth ?? 0,
        ...item,
    }));
};

const getOptionLabelDefault = (option: Type_selectListTree | string) =>
    typeof option === "string" ? option : option?.name;

const renderOptionDefault = (
    props: any,
    option: Type_selectListTree,
    state: AutocompleteRenderOptionState,
) => {
    const { key, ...optionProps } = props;
    return (
        <MenuItem
            key={`${key}-${option.id}`}
            data-testid={`item-${key}`}
            sx={(theme) => ({
                marginLeft: `calc(${theme.spacing(4)} * ${option.depth})`,
                borderLeft: `solid ${option?.color} 4px`,
            })}
            {...optionProps}
            selected={state.selected}
            disabled={option.disabled}
        >
            {option.name}
        </MenuItem>
    );
};

const renderTagsDefault = (
    tagValue: Type_selectListTree[],
    getTagProps: AutocompleteRenderGetTagProps,
) =>
    tagValue.map((option, index: number) => (
        // eslint-disable-next-line react/jsx-key
        <Chip
            label={option.name}
            data-testid={`Autocomplete-tag-${option.id}`}
            {...getTagProps({ index })}
            key={`${option.name}-${index}`}
        />
    ));

export type Type_Props_AutocompleteTree<
    T extends Type_selectListTree,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    "renderInput"
> &
    Type_ReadonlyComponent & {
        isFetching?: boolean;
        placeholder?: string;
        name: string;
        label?: string;
        renderInputProps?: any;
        disabledOptions?: number[];
    };

export const AutocompleteTree = <
    T extends Type_selectListTree,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({
    isFetching = false,
    name,
    label,
    getOptionLabel = getOptionLabelDefault,
    renderOption = renderOptionDefault,
    renderTags = renderTagsDefault,
    renderInputProps = {
        variant: "standard",
    },
    multiple,
    readonly,
    ...restProps
}: Type_Props_AutocompleteTree<
    T,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
>) => {
    const { formState, control } = useFormContext(); // retrieve those props

    return (
        <>
            {isFetching ? (
                <Skeleton variant="rounded" height={60} />
            ) : (
                <Controller
                    control={control}
                    name={name}
                    render={({
                        field: { ref, onChange, onBlur: onBlurRhf, value },
                    }) => (
                        <MuiAutocomplete
                            {...restProps}
                            id={`Autocomplete-${name}`}
                            data-testid={`Autocomplete-${name}-${multiple ? "multiple" : "simple"}`}
                            multiple={multiple}
                            disableCloseOnSelect={
                                restProps.disableCloseOnSelect || multiple
                            }
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={(
                                option,
                                selectedValues,
                            ): boolean => option?.id === selectedValues.id}
                            renderInput={(
                                params: AutocompleteRenderInputParams,
                            ) => {
                                return (
                                    <TMC_TextField
                                        {...params}
                                        {...renderInputProps}
                                        inputRef={ref} // RHF
                                        label={label}
                                        inputProps={{
                                            ...params.inputProps,
                                            onBlur: (e) => {
                                                if (params.inputProps.onBlur) {
                                                    params.inputProps.onBlur(
                                                        e as React.FocusEvent<HTMLInputElement>,
                                                    );
                                                }
                                                onBlurRhf();
                                            },
                                            "data-testid": `Autocomplete-${name}-input`,
                                        }}
                                        // RHF
                                        error={!!formState.errors[name]}
                                        helperText={
                                            formState.errors[name] ? (
                                                <FormHelperMessage
                                                    inputName={name as string}
                                                    message={
                                                        formState.errors?.[name]
                                                            ?.message as string
                                                    }
                                                />
                                            ) : null
                                        }
                                    />
                                );
                            }}
                            renderTags={renderTags}
                            renderOption={renderOption}
                            disabled={readonly || restProps.disabled}
                            // RHF
                            value={value || (multiple ? [] : null)}
                            onChange={(
                                event,
                                value: AutocompleteValue<
                                    T,
                                    Multiple,
                                    DisableClearable,
                                    FreeSolo
                                >,
                            ) => {
                                onChange(value);
                            }}
                            filterSelectedOptions={false} // On ne peut pas permettre de filtrer les options sélectionnées, car on perdrait la cohérence de l'arborescence
                            autoHighlight={true}
                        />
                    )}
                />
            )}
        </>
    );
};
