import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";

import { Type_LanguagesList } from "src/assets/translations";
import { TMC_FormHelperText } from "src/components";
import { Type_Props_MultiLanguagesContainer } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.container";
import { Styled_Box } from "src/components/Components_Common/forms/MultiLanguages/MultiLanguages.style";
import { DEFAULT_LANGUAGE } from "src/contexts/languages";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

export type Type_Props_MultiLanguagesComponent =
    Type_Props_MultiLanguagesContainer & {
        name: string;
        languagesList?: Type_LanguagesList;
    };

export const MultiLanguagesComponent = ({
    name,
    values,
    handleChange,
    errors,
    languagesList,
}: Type_Props_MultiLanguagesComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Teamoty");

    return (
        <Box width={"100%"}>
            <Typography
                variant="h3"
                marginBottom={4}
                marginTop={2}
                data-testid="Form_FormikMultiLangues-Title"
            >
                {fmt(`Drawer.Form.InputNameLabel`)}
            </Typography>
            {!languagesList && (
                <Stack gap={0.5} direction="column">
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                </Stack>
            )}
            {languagesList && (
                <Grid container item flexDirection="column">
                    <Styled_Box>
                        <div>
                            <label htmlFor={`${name}.${DEFAULT_LANGUAGE}`}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: COLORS.moon500,
                                        marginInlineEnd: 1,
                                    }}
                                >
                                    {fmt(
                                        `Configuration.Labels.${languagesList[DEFAULT_LANGUAGE]}`,
                                    )}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: COLORS.moon500,
                                        marginInlineEnd: 1,
                                    }}
                                >
                                    {fmt(
                                        `Configuration.Labels.DefaultLanguage`,
                                    )}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color={COLORS.warning}
                                >
                                    {fmt(`Configuration.Labels.Required`)}
                                </Typography>
                            </label>
                            <input
                                type={"text"}
                                name={`${name}.${DEFAULT_LANGUAGE}`}
                                value={values[name]?.[DEFAULT_LANGUAGE]}
                                onChange={handleChange}
                                data-testid={`Form_FormikMultiLangues_input_${DEFAULT_LANGUAGE}`}
                            />
                            {
                                // @ts-expect-error: names is not included in errors
                                !!errors[name]?.[DEFAULT_LANGUAGE] && (
                                    <TMC_FormHelperText
                                        helperText={
                                            // @ts-expect-error: names is not included in errors
                                            errors[name]?.[
                                                DEFAULT_LANGUAGE
                                            ] as string
                                        }
                                        inputName={DEFAULT_LANGUAGE}
                                    />
                                )
                            }
                        </div>
                        {Object.entries(languagesList).map(([key, value]) => {
                            if (key === DEFAULT_LANGUAGE) return null;
                            return (
                                <div key={key}>
                                    <label htmlFor={`${name}.${key}`}>
                                        <Typography
                                            variant="body2"
                                            color={COLORS.moon500}
                                        >
                                            {fmt(
                                                `Configuration.Labels.${value}`,
                                            )}
                                        </Typography>
                                    </label>
                                    <input
                                        type={"text"}
                                        name={`${name}.${key}`}
                                        value={values[name]?.[key]}
                                        onChange={handleChange}
                                        data-testid={`Form_FormikMultiLangues_input_${key}`}
                                    />
                                    {
                                        // @ts-expect-error: names is not included in errors
                                        !!errors[name]?.[key] && (
                                            <TMC_FormHelperText
                                                helperText={
                                                    // @ts-expect-error: names is not included in errors
                                                    errors[name]?.[
                                                        key
                                                    ] as string
                                                }
                                                inputName={key}
                                            />
                                        )
                                    }
                                </div>
                            );
                        })}
                    </Styled_Box>
                </Grid>
            )}
        </Box>
    );
};
