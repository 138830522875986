import { useState } from "react";

import {
    mutationDeletePriorityOfConstraint,
    mutationStatusPriorityOfConstraint,
    useIndexPriorityOfConstraints,
} from "src/api/tms-commons/priorityOfConstraints";
import {
    Type_index_priorityOfConstraint,
    Type_put_priorityOfConstraint,
} from "src/api/tms-commons/priorityOfConstraints/types";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { Page_PriorityOfConstraintsComponent } from "src/pages/Pages_Teamoty/Configuration/Page_PriorityOfConstraints/Page_PriorityOfConstraints.component";

export type Type_modalDeletePriorityOfConstraint = {
    isOpen: boolean;
    props?: Type_index_priorityOfConstraint;
};

export const Page_PriorityOfConstraintsContainer = () => {
    const { isFetching, data } =
        useIndexPriorityOfConstraints(MEDIUM_STALE_TIME) || {};

    const { mutate: mutationStatus } =
        mutationStatusPriorityOfConstraint() || {};

    const changeStatus = async (
        priorityOfConstraint: Type_index_priorityOfConstraint,
    ) => {
        mutationStatus({
            id: priorityOfConstraint.id,
            enabled: !priorityOfConstraint.enabled,
        } as Type_put_priorityOfConstraint);
    };

    const [
        modalDeletePriorityOfConstraint,
        setModalDeletePriorityOfConstraint,
    ] = useState<Type_modalDeletePriorityOfConstraint>({
        isOpen: false,
    });

    const { mutate: mutateDeletePriorityOfConstraint } =
        mutationDeletePriorityOfConstraint() || {};

    const removePriorityOfConstraint = (id: number) => {
        mutateDeletePriorityOfConstraint(id);
    };

    return (
        <Page_PriorityOfConstraintsComponent
            isFetching={isFetching}
            priorityOfConstraints={data || []}
            changeStatus={changeStatus}
            removePriorityOfConstraint={removePriorityOfConstraint}
            modalDeletePriorityOfConstraint={modalDeletePriorityOfConstraint}
            setModalDeletePriorityOfConstraint={
                setModalDeletePriorityOfConstraint
            }
        />
    );
};
