import { Box } from "@mui/material";
import {
    GridCell,
    GridColDef,
    GridRenderCellParams,
    GridRow,
    GridRowId,
    GridRowModel,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import React, { useMemo, useState } from "react";

import {
    DropdownMenuGeneric,
    Type_CustomMenuItem,
} from "src/components/Components_Common/DropdownMenuGeneric/DropdownMenuGeneric";
import { LoadingOrNoData } from "src/components/Components_Common/LoadingOrNoData/LoadingOrNoData";
import {
    Styled_Props_Table,
    Styled_Table,
} from "src/components/Components_Common/Table/Table.style";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

// WIDTHS AND SIZES
const HEADERS_HEIGHT = 193;
export const GRID_HEIGHT = `calc(100vh - ${HEADERS_HEIGHT}px)`;

// TO complete
const defaultColumnWidth: Type_defaultColumnWidth = {
    date: { width: 100 },
    dates: { width: 250 },
    postalCode: { width: 100 },
    workingDays: { width: 355 },
    color: { minWidth: 35, maxWidth: 35 },
    sortOrder: { width: 80 },
    subscription: { minWidth: 56, maxWidth: 56 },
};

type Type_Props_Table = Styled_Props_Table & {
    columns: Type_column[];
    highLighted?: string[];
    noDataConfig: {
        isFetching: boolean;
        dataName: string;
        action: () => void;
    };
    onClick?: (id: GridRowId) => void;
    menuDropDown?: Type_menuDropDown;
    page: string;
    height?: string | number;
    menuItems?: Type_CustomMenuItem[];
    showSearchBar?: boolean;
};

export type Type_menuDropDown = {
    callbackEdit?: (row: GridRowModel) => void;
    callbackDuplicate?: (row: GridRowModel) => void;
    callbackDownload?: (row: GridRowModel) => void;
    callbackStatus?: (row: GridRowModel) => void;
    callbackDelete?: (row: GridRowModel) => void;
    showEdit?: (row: GridRowModel) => boolean;
    showDuplicate?: (row: GridRowModel) => boolean;
    showStatus?: (row: GridRowModel) => boolean;
    showDelete?: (row: GridRowModel) => boolean;
};

export type Type_column = GridColDef<any> & {
    colType?:
        | "date"
        | "dates"
        | "postalCode"
        | "workingDays"
        | "color"
        | "sortOrder"
        | "subscription";
};

type Type_defaultColumnWidth = {
    [key: string]: {
        maxWidth?: number;
        minWidth?: number;
        width?: number;
        flex?: number;
    };
};

type Type_InitialStateHoverRow = number | null;

const QuickSearchToolbar = () => {
    return (
        <Box ml={2} mb={3}>
            <GridToolbarQuickFilter
                sx={(theme) => ({
                    borderBottom: theme.border.default,
                })}
            />
        </Box>
    );
};

const extendColumnWithSize = (
    columns: Type_column[],
): readonly GridColDef[] => {
    return columns.map(function (column: any) {
        if (column.width || column.flex) return column;

        return column.colType && defaultColumnWidth[column.colType]
            ? { ...column, ...defaultColumnWidth[column.colType] }
            : { ...column, flex: 1 };
    });
};

export const Table = ({
    columns,
    highLighted = ["name"], // can be any column or several
    noDataConfig,
    menuDropDown,
    onClick,
    page,
    height,
    hideFooter = true,
    menuItems,
    showSearchBar = false,
    ...props
}: Type_Props_Table) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Table.DefaultLabels",
    );

    const customLocaleText = {
        noResultsOverlayLabel: fmt("NoRowsLabel"),
        toolbarQuickFilterPlaceholder: fmt("SearchPlaceHolder"),
    };

    const [isHovered, setIsHovered] = useState<Type_InitialStateHoverRow>(null);
    const { checkPermission, user } = useUser();

    const CAN_CREATE_UPDATE = useMemo(
        () => checkPermission(page, "create"),
        [user],
    );
    const CAN_DELETE = useMemo(() => checkPermission(page, "delete"), [user]);

    const handleClick = (rowId: GridRowId) => {
        if (typeof onClick === "function" && CAN_CREATE_UPDATE) {
            onClick(rowId);
        }
    };

    const handleMouseEnter = (rowId: GridRowId) => {
        setIsHovered(rowId as number);
    };

    const handleMouseLeave = () => {
        setIsHovered(null);
    };

    let dropDownMenuColumn;

    let newColumns = extendColumnWithSize(columns);
    if (
        (menuDropDown &&
            Object.keys(menuDropDown).length &&
            (CAN_CREATE_UPDATE || CAN_DELETE)) ||
        (menuItems && (CAN_CREATE_UPDATE || CAN_DELETE))
    ) {
        dropDownMenuColumn = {
            field: "menuDropDown",
            headerName: "",
            minWidth: 35,
            maxWidth: 35,
            renderCell: (props: GridRenderCellParams<any, any, any>) => {
                return (
                    isHovered === props.row.id && (
                        <DropdownMenuGeneric
                            id={props.row.id}
                            props={props.row}
                            showEdit={(row) => row.enabled}
                            showDuplicate={(row) => row.enabled}
                            {...menuDropDown}
                            menuItems={props.row.enabled ? menuItems : []}
                            page={page}
                        />
                    )
                );
            },
            sortable: false,
        };
        newColumns = [...newColumns, dropDownMenuColumn];
    }

    return (
        <Box
            height={height ?? "auto"}
            data-testid={`Table-${page}-Container`}
            width="100%"
            sx={{
                overflowY: "hidden",
                overflowX: "auto",
            }}
        >
            {newColumns && (
                <Styled_Table
                    ownerState={{
                        showCursor:
                            CAN_CREATE_UPDATE && typeof onClick === "function",
                    }}
                    {...props}
                    localeText={customLocaleText}
                    columns={newColumns}
                    disableColumnMenu
                    autoHeight={height === undefined}
                    getRowHeight={() => "auto"}
                    columnHeaderHeight={40}
                    hideFooter={!!hideFooter}
                    sx={{
                        "--DataGrid-overlayHeight": "300px",
                    }}
                    getCellClassName={(params) => {
                        const highlighted = highLighted?.includes(params.field)
                            ? "teamoty-cell-bold"
                            : "";
                        const disabled =
                            params.row.enabled === false
                                ? "teamoty-cell-disabled"
                                : "";
                        return `teamoty-cell-${params.field} ${highlighted} ${disabled}`;
                    }}
                    slots={{
                        toolbar: showSearchBar ? QuickSearchToolbar : null,
                        cell: (props) => (
                            <GridCell
                                {...props}
                                data-testid={`cell-${props.rowId}-${props.column.field}`}
                            />
                        ),
                        row: (props) => (
                            <GridRow
                                {...(onClick && {
                                    onClick: () =>
                                        props.row.enabled &&
                                        handleClick(props.rowId),
                                })}
                                onMouseEnter={() =>
                                    handleMouseEnter(props.rowId)
                                }
                                onMouseLeave={handleMouseLeave}
                                {...props}
                                data-testid={`row-${props.rowId}`}
                            />
                        ),
                        noRowsOverlay: () => (
                            <Box
                                sx={{
                                    marginTop: 6,
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <LoadingOrNoData
                                    isFetching={
                                        noDataConfig.isFetching || false
                                    }
                                    dataName={noDataConfig.dataName}
                                    action={noDataConfig.action}
                                    dataTestIdRef={page}
                                    hasPermission={CAN_CREATE_UPDATE}
                                />
                            </Box>
                        ),
                    }}
                />
            )}
        </Box>
    );
};
