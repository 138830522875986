import {
    Type_com_index_license,
    Type_com_post_license,
    Type_com_put_license,
    Type_com_show_license,
    Type_index_license,
    Type_post_license,
    Type_show_license,
} from "src/api/tms-commons/licenses/types";
import {
    formatDateForBack,
    formatDateForFront,
    isoToMonths,
} from "src/utils/date";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export const formatterIndexLicenses = (
    licenses: Type_com_index_license[],
): Type_index_license[] => {
    if (!licenses) return [];
    return licenses.map((license: Type_com_index_license) =>
        formatterIndexLicense(license),
    );
};

export const formatterIndexLicense = (
    license: Type_com_index_license,
): Type_index_license => {
    const language = getLocalStorageItem("language");
    return {
        id: license.id as number,
        name: license.licenseType.names[language],
        duration: isoToMonths(license.licenseType.duration),
        startDate: formatDateForFront(license.startDate)!,
        endDate: formatDateForFront(license.endDate),
        renewal: license.renewal,
        cost: license.cost,
        enabled: true,
    };
};

export const formatterShowLicense = (
    license: Type_com_show_license,
): Type_show_license => {
    const language = getLocalStorageItem("language");

    return {
        id: license.id,
        startDate: formatDateForFront(license.startDate)!,
        renewal: license.renewal,
        licenseType: {
            id: license.licenseType.id,
            duration: license.licenseType.duration,
            name: license.licenseType.names[language],
        },
        endDate: formatDateForFront(license.endDate),
        cost: license.cost,
    };
};

export const formatterCreateLicense = (
    license: Type_show_license,
): Type_com_post_license => {
    return {
        startDate: formatDateForBack(license.startDate)!,
        renewal: license.renewal,
        license_type_id: license.licenseType.id,
        cost: license.cost,
    };
};

export const formatterUpdateLicense = (
    license: Type_show_license,
): Type_com_put_license => {
    return {
        startDate: formatDateForBack(license.startDate),
        endDate: formatDateForBack(license.endDate),
        renewal: license.renewal,
        license_type_id: license.licenseType ? license.licenseType.id : 0,
        cost: license.cost,
    };
};

export const formatterPostLicense = (
    license: Type_post_license,
): Type_com_post_license => {
    return {
        startDate: formatDateForBack(license.startDate)!,
        renewal: license.renewal,
        license_type_id: license.license_type_id,
        cost: license.cost,
    };
};
