import { yupResolver } from "@hookform/resolvers/yup";
import TabContext from "@mui/lab/TabContext";
import { Dayjs } from "dayjs";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { useSelectListCompanies } from "src/api/tms-commons/companies";
import { useIndexMetadataProjectsForForm } from "src/api/tms-commons/metadata";
import { Type_metadataProjects_For_Form } from "src/api/tms-commons/metadata/types";
import {
    mutationUpdateProject,
    useShowProject,
} from "src/api/tms-commons/projects";
import { Type_show_project } from "src/api/tms-commons/projects/types";
import { useSelectListProjectTypes } from "src/api/tms-commons/projectTypes";
import { LoadingBox } from "src/components";
import { Type_drawerTab } from "src/components/Components_Common/Drawer/Header/Header.container";
import {
    DrawerTab,
    DrawerTabList,
    Styled_TabPanelDrawerContent,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { MetadataFormController } from "src/components/Components_Common/forms/reactHookFormComponents/MetadataFormController/MetadataFormController";
import { Table_Licenses } from "src/components/Components_Teamoty/tables/TableLicenses/Table_Licenses";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { Tab_ProjectDetailForm } from "src/forms/project/Tab_ProjectDetailForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_UpdateProjectForm = Yup.lazy(() => {
    return Yup.object().shape({
        name: Yup.string()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        city: Yup.string()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        country: Yup.string()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        startDate: Yup.mixed<Dayjs>().required(FORM_ERR_FMT.REQUIRED),
        thumbnail: Yup.mixed().required(FORM_ERR_FMT.REQUIRED),
        projectTypes: Yup.array().min(1, FORM_ERR_FMT.REQUIRED),
        companyId: Yup.string().required(FORM_ERR_FMT.REQUIRED),
        postCode: Yup.string()
            .max(10, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
    });
});

type Type_Props_UpdateProjectForm = {
    projectIdToUpdate: number;
    onClose: () => void;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    tab: string;
};

export const UpdateProjectForm = ({
    projectIdToUpdate,
    onClose,
    setIsLoading,
    tab,
}: Type_Props_UpdateProjectForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Projects");

    const [activeTab, setTab] = useState<string>("0");

    const { isFetching: isFetching_ProjectTypes, data: projectTypes = [] } =
        useSelectListProjectTypes() || {};

    const { isFetching: isFetching_Companies, data: companies = [] } =
        useSelectListCompanies() || {};

    const {
        isFetching: isFetching_MetadataProjects,
        data: metadataProjects = [],
    } =
        useIndexMetadataProjectsForForm({
            enabled: true,
        }) || {};

    const { isFetching: isFetching_ProjectById, data } =
        useShowProject(projectIdToUpdate);

    const isFetching = useMemo(
        () =>
            isFetching_MetadataProjects ||
            isFetching_ProjectById ||
            isFetching_ProjectTypes ||
            isFetching_Companies,
        [
            isFetching_MetadataProjects,
            isFetching_ProjectById,
            isFetching_ProjectTypes,
            isFetching_Companies,
        ],
    );

    const setMetaDataValues = (metadata: Record<string, string>[]) => {
        const values: Record<string, string> = {};
        metadata.forEach((input) => {
            if (!data?.metadata?.[input.label]) {
                if (input.value) values[input.label] = input.value;
            }
        });
        return values;
    };

    const setDefaultMetaDataValues = (
        metadata: Type_metadataProjects_For_Form[],
    ) => {
        const defaultValues: any = {};
        metadata.forEach((input) => {
            if (!data?.metadata?.[input.name]) {
                switch (input.metadataType) {
                    case "checkbox":
                        defaultValues[input.name] = [];
                        break;
                    case "date":
                        defaultValues[input.name] = null;
                        break;
                    default:
                        defaultValues[input.name] = "";
                }
            }
        });
        return defaultValues;
    };

    const form = useForm<Type_show_project>({
        ...useFormDefaultConfig,
        defaultValues: {
            id: projectIdToUpdate,
            name: "",
            postCode: "",
            city: "",
            country: "",
            thumbnail: undefined,
            slug: "",
            projectTypes: [],
            licenses: [],
            metadata: setDefaultMetaDataValues(metadataProjects || []),
        },
        values: {
            ...data!,
            metadata: setMetaDataValues(data?.metadata || []),
            licenses: data?.licenses || [],
        },
        resolver: yupResolver<any>(Schema_UpdateProjectForm),
    });

    const { mutateAsync: mutateUpdate } = mutationUpdateProject();

    const handleSubmit = async (
        values: Type_show_project,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        setIsLoading(true);

        try {
            await mutateUpdate(values);

            onClose();
        } catch (e: any) {
            console.error("HANDLE SUBMIT ERROR", e);
        }

        setIsLoading(false);
    };

    const drawerTabs = useMemo(() => {
        if (!metadataProjects?.length) {
            return [{ label: fmt("TabsLabel.Global"), value: "0" }];
        }
        if (tab === "1") {
            setTab(tab);
        }
        return [
            { label: fmt("TabsLabel.Global"), value: "0" },
            { label: fmt("TabsLabel.Metadata"), value: "1" },
            { label: fmt("TabsLabel.Licenses"), value: "2" },
        ];
    }, [metadataProjects]);

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    return (
        <TabContext value={activeTab}>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)} id={"Project"}>
                    <DrawerTabList
                        onChange={handleChange}
                        data-testid="EditProject-DrawerContent-TabList"
                    >
                        {drawerTabs.map(
                            (drawerTab: Type_drawerTab, key: number) => {
                                return (
                                    <DrawerTab
                                        data-testid={`Tab-${drawerTab.value}`}
                                        label={drawerTab.label}
                                        value={drawerTab.value}
                                        key={key}
                                    />
                                );
                            },
                        )}
                    </DrawerTabList>
                    {isFetching ? (
                        <LoadingBox />
                    ) : (
                        data && (
                            <>
                                <Styled_TabPanelDrawerContent value={"0"}>
                                    <Tab_ProjectDetailForm
                                        projectTypes={projectTypes}
                                        companies={companies}
                                        projectId={data.id}
                                    />
                                </Styled_TabPanelDrawerContent>
                                <Styled_TabPanelDrawerContent value={"1"}>
                                    <MetadataFormController
                                        metadata={metadataProjects}
                                    />
                                </Styled_TabPanelDrawerContent>
                            </>
                        )
                    )}
                </form>
            </FormProvider>
            {data && (
                <Styled_TabPanelDrawerContent value={"2"}>
                    <Table_Licenses
                        title={fmt("AssignLicense")}
                        licenses={data?.licenses || []}
                        projectId={data?.id}
                    />
                </Styled_TabPanelDrawerContent>
            )}
        </TabContext>
    );
};
