import { useState } from "react";

import {
    mutationDeleteMetadataProject,
    mutationStatusMetadataProject,
    useIndexMetadataProjects,
} from "src/api/tms-commons/metadata";
import {
    Type_index_metadataProject,
    Type_put_metadataProject,
} from "src/api/tms-commons/metadata/types";

import { Page_MetadataProjectsComponent } from "./Page_MetadataProjects.component";

export type Type_modalDeleteMetadataProjects = {
    isOpen: boolean;
    props?: Type_index_metadataProject;
};

export const Page_MetadataProjectsContainer = () => {
    const { isFetching, data: metadataProjects } =
        useIndexMetadataProjects() || {};

    const [modalDeleteMetadataProjects, setModalDeleteMetadataProjects] =
        useState<Type_modalDeleteMetadataProjects>({
            isOpen: false,
        });

    const { mutate: mutateDeleteMetadataProject } =
        mutationDeleteMetadataProject() || {};

    const removeMetadataProject = (id: number) => {
        mutateDeleteMetadataProject(id);
    };

    const { mutate: mutateMetadataProjectStatus } =
        mutationStatusMetadataProject() || {};

    const changeStatus = (metadataProject: Type_index_metadataProject) => {
        mutateMetadataProjectStatus({
            id: metadataProject.id,
            enabled: !metadataProject.enabled,
        } as Type_put_metadataProject);
    };

    return (
        <Page_MetadataProjectsComponent
            isFetching={isFetching}
            metadataProjects={metadataProjects || []}
            changeStatus={changeStatus}
            deleteMetadataProjects={removeMetadataProject}
            modalDeleteMetadataProjects={modalDeleteMetadataProjects}
            setModalDeleteMetadataProjects={setModalDeleteMetadataProjects}
        />
    );
};
