import {
    formatterMultiLanguageForMatrixToBack,
    formatterMultiLanguageForMatrixToFront,
} from "src/api/formatters";
import {
    Type_index_area,
    Type_index_area_forMatrix,
    Type_post_area,
    Type_prj_index_area,
    Type_prj_index_area_forMatrix,
    Type_prj_post_area,
    Type_prj_put_area,
    Type_prj_select_area,
    Type_prj_show_area,
    Type_put_area,
    Type_select_area,
    Type_show_area,
} from "src/api/tms-projects/areas/types";
import { SELECT_NONE_VALUE } from "src/configurations/app";
import { DEFAULT_COLORS } from "src/theme/stylesheet";

export const formatterIndexArea = (
    area: Type_prj_index_area,
): Type_index_area => {
    return {
        id: area.id,
        name: area.name,
        color: area.color ?? DEFAULT_COLORS.area,
        sortOrder: area.sortOrder,
        parentId: area.parent_id,
        areaType: area.areaType,
        areaTypeName: area.areaType.name,
        drawing: area.drawing,
        enabled: area.enabled,
        scheduling: area.scheduling,
        resourceTypeId: area.resource_type_id,
        resourceId: area.resource_id,
        permanentUse: area.permanentUse,
        show: area.show,
        coordinateName: area.coordinateName,
        coordinates: area.coordinates,
        rotationName: area.rotationName,
        widthName: area.widthName,
        children: formatterIndexAreas(area.children),
        isUsed: area.isUsed,
    };
};

export const formatterIndexAreas = (
    areas: Type_prj_index_area[] = [],
): Type_index_area[] =>
    areas.map((area: Type_prj_index_area) => formatterIndexArea(area));

export const formatterIndexAreaForMatrix = (
    areaForMatrix: Type_prj_index_area_forMatrix,
): Type_index_area_forMatrix => {
    const { areaType_id, resource_id, resource_type_id, names, ...rest } =
        areaForMatrix;

    return {
        ...rest,
        areaTypeId: areaType_id ?? SELECT_NONE_VALUE,
        resourceId: resource_id ?? SELECT_NONE_VALUE,
        resourceTypeId: resource_type_id ?? SELECT_NONE_VALUE,
        ...formatterMultiLanguageForMatrixToFront("names", names),
    };
};

export const formatterIndexAreasForMatrix = (
    areasForMatrix: Type_prj_index_area_forMatrix[] = [],
): Type_index_area_forMatrix[] =>
    areasForMatrix.map((areaForMatrix: Type_prj_index_area_forMatrix) =>
        formatterIndexAreaForMatrix(areaForMatrix),
    );

export const formatterShowArea = (area: Type_prj_show_area): Type_show_area => {
    return {
        id: area.id,
        names: area.names,
        color: area.color ?? DEFAULT_COLORS.area,
        sortOrder: area.sortOrder,
        parent: area.parent,
        areaType: {
            id: area.areaType.id,
            name: area.areaType.name,
            enabled: area.areaType.enabled,
        },
        drawing: area.drawing,
        scheduling: area.scheduling,
        resourceType: area.resource_type_id
            ? { id: area.resource_type_id, name: "" }
            : null,
        resource: area.resource_id ? { id: area.resource_id, name: "" } : null,
        permanentUse: area.permanentUse,
        show: area.show,
    };
};

export const formatterCreateArea = (
    area: Type_post_area,
): Type_prj_post_area => {
    return {
        names: area.names,
        color: area?.color,
        areaType_id: area.areaType.id,
        parent_id: area.parent ? area.parent?.id : area.parent_id || null,
        drawing_id: area.drawing?.id,
        scheduling: area.scheduling,
        resource_type_id: area.resourceType?.id,
        resource_id: area.resource?.id,
        permanentUse: area.permanentUse,
    };
};

export const formatterUpdateArea = (area: Type_put_area): Type_prj_put_area => {
    return {
        id: area?.id,
        names: area.names,
        color: area.color,
        areaType_id: area.areaType?.id,
        parent_id: area?.parent?.id ? area.parent.id : undefined,
        drawing_id: area.drawing?.id ? area.drawing?.id : undefined,
        scheduling: area.scheduling,
        resource_type_id: area.resourceType?.id,
        resource_id: area.resource?.id,
        permanentUse: area.permanentUse,
        coordinates: area.coordinates,
        coordinateName: area.coordinateName,
        widthName: area.widthName,
        rotationName: area.rotationName,
        sortOrder: area.sortOrder,
        show: area.show,
        enabled: area.enabled,
    };
};

export const formatterUpdateAreaForMatrix = (
    area: Type_index_area_forMatrix,
): Type_prj_put_area => {
    return {
        names: formatterMultiLanguageForMatrixToBack("names", area),
        color: area.color,
        areaType_id: area.areaTypeId,
        resource_id:
            area.resourceId === SELECT_NONE_VALUE ? null : area.resourceId,
        resource_type_id:
            area.resourceTypeId === SELECT_NONE_VALUE
                ? null
                : area.resourceTypeId,
        permanentUse: area.permanentUse,
        show: area.show,
    };
};

export const formatterSelectListAreas = (
    selectListAreas: Type_prj_select_area[],
): Type_select_area[] => {
    if (!selectListAreas) return [];
    const formattedSelectListAreas: Type_select_area[] = [];
    selectListAreas.map((selectListArea: Type_prj_select_area): void => {
        formattedSelectListAreas.push(formatterSelectListArea(selectListArea));
    });
    return formattedSelectListAreas;
};

export const formatterSelectListArea = (
    selectListArea: Type_prj_select_area,
): Type_select_area => {
    if (selectListArea?.children?.length) {
        return {
            id: selectListArea.id,
            name: selectListArea.name,
            children: formatterSelectListAreas(selectListArea.children),
        };
    } else {
        return {
            id: selectListArea.id,
            name: selectListArea.name,
        };
    }
};

export const formatterFlattenSelectAreas = (
    selectListAreas: Type_select_area[] | Type_prj_select_area[],
    depth = 0,
): Type_select_area[] => {
    return (selectListAreas as Type_select_area[]).reduce(
        (acc: Type_select_area[], { id, name, children }: Type_select_area) => {
            acc.push({ id, name, depth });

            if (children?.length) {
                acc.push(...formatterFlattenSelectAreas(children, depth + 1));
            }

            return acc;
        },
        [],
    );
};

export const formatterDndArea = (area: {
    id: number;
    sortOrder: number;
    parentId: number | null | undefined;
}) => {
    const newSortOrder: number = area.sortOrder === -1 ? 0 : area.sortOrder;
    return {
        id: area.id,
        sortOrder: newSortOrder,
        parent_id: area.parentId === undefined ? null : area.parentId,
    };
};

export const formatterSortArea = (area: { id: number; sortOrder: number }) => {
    const newSortOrder: number = area.sortOrder === -1 ? 0 : area.sortOrder;
    return {
        id: area.id,
        sortOrder: newSortOrder,
    };
};
