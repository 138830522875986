import { Stack } from "@mui/material";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { AutocompleteFreeSolo } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSolo/AutocompleteFreeSolo";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { SectionTitleWithOptional } from "src/components/Components_Common/SectionTitle";
import { useFormDefaultConfig } from "src/configurations/app";
import { Section } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export const AutocompleteDemo = () => {
    const form = useForm({
        ...useFormDefaultConfig,
        defaultValues: {
            "autocomplete-freesolo": [],
            "autocomplete-freesolo-dialog": [],
        },
    });

    return (
        <Section>
            <SectionTitleWithOptional
                title={"Composants Autocomplete React hook form"}
            />
            <Stack spacing={4} gap={2}>
                <FormProvider {...form}>
                    <form
                        onSubmit={form.handleSubmit((data) =>
                            console.log("HANDLE SUBMIT", data),
                        )}
                        id={"companies"}
                    >
                        <Autocomplete
                            isFetching={false}
                            name={"autocomplete"}
                            label={"Autocomplete"}
                            multiple
                            renderInputProps={{ variant: "outlined" }}
                            options={[
                                { id: 1, name: "option1" },
                                { id: 2, name: "option2" },
                                { id: 3, name: "option3" },
                            ]}
                        />
                        <AutocompleteFreeSolo
                            name={"autocomplete-freesolo"}
                            label={"Autocomplete FreeSolo"}
                            renderInputProps={{ variant: "outlined" }}
                            options={["option1", "option2", "option3"]}
                        />

                        <AutocompleteFreeSoloDialog
                            isFetching={false}
                            name={"autocomplete-freesolo-dialog"}
                            label={"Autocomplete free solo dialog"}
                            renderInputProps={{ variant: "outlined" }}
                            options={[
                                { id: 1, name: "option1" },
                                { id: 2, name: "option2" },
                                { id: 3, name: "option3" },
                            ]}
                            onAddNewValue={(value) =>
                                alert(
                                    "Call onAddNewValue, value : [" +
                                        value?.name +
                                        "]",
                                )
                            }
                        />
                    </form>
                </FormProvider>
            </Stack>
        </Section>
    );
};
