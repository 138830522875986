import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import {
    DaysBadge,
    Type_Props_DaysBadge,
} from "src/components/Components_Common/Days/DaysBadge";

type Type_Props_Days = Omit<
    Type_Props_DaysBadge,
    "initialValue" | "valueCallback"
> & {
    name: string;
};

export const DaysBadgeField = ({ name, ...props }: Type_Props_Days) => {
    const { control, formState } = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <DaysBadge
                        initialValue={value}
                        valueCallback={(value) => onChange(value)}
                        {...props}
                    />
                )}
            />
            {formState.errors[name] && (
                <TMC_FormHelperText
                    inputName={name}
                    helperText={formState?.errors[name]?.message as string}
                />
            )}
        </>
    );
};
