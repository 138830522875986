import React from "react";
import { Outlet } from "react-router-dom";

import { Styled_Layout } from "src/layouts/Layout_FullWidth/stylesheet";

export { Styled_Header as Header } from "src/layouts/Layout_FullWidth/stylesheet";
export { Styled_Main as Main } from "src/layouts/Layout_FullWidth/stylesheet";
export { Styled_Section as Section } from "src/layouts/Layout_FullWidth/stylesheet";
export { Styled_Sidebar as Sidebar } from "src/layouts/Layout_FullWidth/stylesheet";

/**
 * Common FullWidth Layout
 *
 * The normal skeleton will be
 *
 * <Layout_FullWidth>
 *   <YourPage>
 *       <Header>
 *         Your Header
 *       </Header>
 *       <Main>
 *         <Sidebar>
 *             Your sidebar content
 *         </Sidebar>
 *         <Section>
 *             Your page content
 *         </Section>
 *       </Main>
 *   </YourPage>
 * </Layout_FullWidth>
 *
 * @constructor
 */
export const Layout_FullWidth = () => {
    return (
        <Styled_Layout>
            <Outlet />
        </Styled_Layout>
    );
};
