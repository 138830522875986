import { Box, Divider, Stack } from "@mui/material";
import React, { useCallback } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { mutationCreateSequenceFolder } from "src/api/tms-scheduling/sequenceFolders";
import { Spinner, TMC_Button } from "src/components/Components_Common";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { URL_BASE } from "src/router";

export const Blank = () => {
    const { project } = useProject();
    const navigate = useNavigate();
    const urlParams = useParams();
    const basePath = generatePath(`${URL_BASE}`, { ...urlParams });
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.Blank",
    );

    const { mutate, isLoading } = mutationCreateSequenceFolder(true);

    const handleClick = () => {
        mutate({});
    };

    const navigateToProjectSettings = useCallback(() => {
        if (urlParams.projectSlug && project?.slug) {
            navigate(
                `${basePath}/projects/${project.slug}/settings/subprojects`,
            );
        }
    }, [navigate, urlParams.projectSlug, project?.slug, basePath]);

    return (
        <Box component="div" sx={{ flexGrow: 1 }}>
            <Stack
                sx={{
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Stack justifyContent={"center"} gap={4}>
                    <TMC_Button
                        disabled={isLoading}
                        onClick={handleClick}
                        type="button"
                        variant="contained"
                        data-testid={"Planning-Blank-Button-GettingStarted"}
                        endIcon={
                            isLoading && <Spinner size="small" style="button" />
                        }
                    >
                        {fmt("GettingStarted")}
                    </TMC_Button>
                    <Divider variant="middle" flexItem />
                    <TMC_Button
                        disabled={isLoading}
                        onClick={navigateToProjectSettings}
                        type="button"
                        variant="contained"
                        color={"info"}
                        data-testid={"Planning-Blank-Button-ProjectSettings"}
                    >
                        {fmt("ProjectSettings")}
                    </TMC_Button>
                </Stack>
            </Stack>
        </Box>
    );
};
