import {
    GridRenderCellParams,
    GridRowId,
    GridRowModel,
} from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Type_index_metadataProject } from "src/api/tms-commons/metadata/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { MetadataProjectDrawer } from "src/drawers/metadataProject/MetadataProjectDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteMetadataProjects } from "./Page_MetadataProjects.container";

export type Type_Page_MetadataProjectsComponentProps = {
    metadataProjects: Type_index_metadataProject[];
    changeStatus: (metadataProjects: Type_index_metadataProject) => void;
    deleteMetadataProjects: (id: number) => void;
    modalDeleteMetadataProjects: Type_modalDeleteMetadataProjects;
    setModalDeleteMetadataProjects: Dispatch<
        SetStateAction<Type_modalDeleteMetadataProjects>
    >;
    isFetching: boolean;
};

export const Page_MetadataProjectsComponent = ({
    metadataProjects,
    changeStatus,
    deleteMetadataProjects,
    modalDeleteMetadataProjects,
    setModalDeleteMetadataProjects,
    isFetching,
}: Type_Page_MetadataProjectsComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.MetadataProjects",
    );
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmtMetadata } =
        useCoreIntl("MetadataUi.Types");

    const page = "metadataProjects";

    const [selectedMetadataProject, setSelectedMetadataProject] = useState<
        number | null
    >(null);
    const [openMetadataProjectDrawer, setOpenMetadataProjectDrawer] =
        useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleMetadataCreate = () => {
        setSelectedMetadataProject(null);
        setAction("create");
        setOpenMetadataProjectDrawer(true);
    };

    const handleMetadataEdit = (metadataId: number) => {
        setSelectedMetadataProject(metadataId);
        setAction("update");
        setOpenMetadataProjectDrawer(true);
    };

    const handleMetadataDuplicate = (metadataId: number) => {
        setSelectedMetadataProject(metadataId);
        setAction("duplicate");
        setOpenMetadataProjectDrawer(true);
    };

    const handleCloseMetadataDrawer = () => {
        setOpenMetadataProjectDrawer(false);
    };

    const callbackEdit = (row: GridRowModel) => {
        handleMetadataEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) => {
        handleMetadataDuplicate(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row as Type_index_metadataProject);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteMetadataProjects({
            isOpen: true,
            props: row as Type_index_metadataProject,
        });
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            sortable: false,
        },
        {
            field: "metadataType",
            headerName: fmtTableColumn("MetadataType"),
            sortable: false,
            renderCell: (props: GridRenderCellParams<any, any, any>) => {
                return fmtMetadata(props.row.metadataType);
            },
        },
        {
            field: "values",
            headerName: fmtTableColumn("Values"),
            sortable: false,
        },
        {
            field: "sort",
            headerName: fmtTableColumn("Order"),
            sortable: false,
            colType: "sortOrder",
            type: "number",
        },
    ];

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteMetadataProjects({ isOpen: false });
                deleteMetadataProjects(
                    modalDeleteMetadataProjects?.props?.id as number,
                );
            },
        },
    };

    // Calcule le prochain "ordre de tri" disponible en prenant le plus grand "sort" existant dans metadataUsers et en ajoutant 1.
    const nextDefaultSortOrder = useMemo(
        () =>
            metadataProjects.reduce(
                (previous, { sort }) => Math.max(previous, sort),
                0,
            ) + 1,
        [metadataProjects],
    );

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageMetadatProject-Add-btn"
                    onClick={() => handleMetadataCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={metadataProjects}
                columns={gridColumns}
                disableColumnSorting
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleMetadataCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleMetadataEdit(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <ModalDelete
                open={modalDeleteMetadataProjects.isOpen}
                onClose={() =>
                    setModalDeleteMetadataProjects({ isOpen: false })
                }
                item={fmt("DataName")}
                validationString={
                    modalDeleteMetadataProjects?.props?.name as string
                }
                actions={actions}
            />

            <MetadataProjectDrawer
                open={openMetadataProjectDrawer}
                onClose={handleCloseMetadataDrawer}
                metadataIdToUpdate={selectedMetadataProject}
                action={action}
                page={page}
                nextDefaultSortOrder={nextDefaultSortOrder}
            />
        </>
    );
};
