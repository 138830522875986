import { DrawerProps } from "@mui/material";
import {
    createContext,
    CSSProperties,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useState,
} from "react";

import { countryCodeLanguageLtr } from "src/assets/translations";
import { Drawer } from "src/components";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export type DrawerAnchorType = "top" | "right" | "bottom" | "left" | undefined;

type DrawerContextType = {
    setDrawer: (
        drawerChildren: JSX.Element,
        options?: DrawerProps,
        style?: CSSProperties,
    ) => void;
    closeDrawer: () => void;
};

// Valeurs initiales du contexte
const DrawerContext = createContext<DrawerContextType>({
    setDrawer: () => {},
    closeDrawer: () => {},
});

/** @deprecated It is recommended to use the Mui Drawer component instead. */
export const useDrawer = () => useContext(DrawerContext);

type drawerType = {
    options: DrawerProps;
    children: JSX.Element | null;
    style?: CSSProperties;
};

export const DrawerProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [drawers, setDrawers] = useState<drawerType[]>([]);

    const setDrawer = useCallback(
        (
            drawerChildren: JSX.Element,
            options: DrawerProps = {
                anchor: "right",
                ModalProps: {
                    BackdropProps: {
                        onClick: () => null,
                    },
                },
                elevation: 2,
            },
            style?: CSSProperties,
        ) => {
            setDrawers((prevDrawers: drawerType[]) => [
                ...prevDrawers,
                {
                    options: {
                        ...options,
                        open: true,
                        anchor:
                            document.dir === "ltr"
                                ? options.anchor
                                : options.anchor === "right"
                                  ? "left"
                                  : "right",
                    },
                    children: drawerChildren,
                    style: style,
                },
            ]);
        },
        [],
    );

    const closeDrawer = useCallback(() => {
        setDrawers((prevDrawers: drawerType[]) => {
            return prevDrawers.slice(0, prevDrawers.length - 1);
        });
    }, []);

    const drawerElements = drawers.map((drawer: drawerType, index: number) => {
        const isLTR: boolean = countryCodeLanguageLtr.includes(
            getLocalStorageItem("language"),
        );
        const zAxisIndex: number = drawers.length - index;
        const scaleValue: number = 1 - (zAxisIndex - 1) / 100;
        const xAxisTranslateValue: number = 2 * (zAxisIndex - 1);

        const drawerOptions = {
            ...drawer.options,
            PaperProps: {
                style: {
                    scale: `${scaleValue}`,
                    transform: `translate(${
                        isLTR ? "-" : ""
                    }${xAxisTranslateValue}%)`,
                    overflow: "hidden",
                },
            },
            hideBackdrop: index !== 0,
        };

        return (
            <Drawer key={index} style={drawer.style} options={drawerOptions}>
                {drawer.children}
            </Drawer>
        );
    });

    return (
        <DrawerContext.Provider value={{ setDrawer, closeDrawer }}>
            {/* Reste de l'application */}
            {children}
            {/* Drawers GLOBAL */}
            {drawerElements}
        </DrawerContext.Provider>
    );
};
