import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Editor } from "src/components/Components_Common/richText/Editor";

type Type_Props_EditorRhf = {
    name: string;
    footer?: any;
    hideRichText?: () => void;
    editable?: boolean;
    dataTestIdRef?: string;
};

export const EditorRhf = ({
    name,
    footer,
    hideRichText,
    editable = true,
    ...props
}: Type_Props_EditorRhf) => {
    const { control, formState } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, name } }) => {
                return (
                    <Editor
                        {...props}
                        editable={editable}
                        buttonBold
                        buttonItalic
                        buttonUnderline
                        footer={footer}
                        hideRichText={hideRichText}
                        onChange={onChange}
                        content={value}
                        name={name}
                        helperText={
                            (formState.errors?.[name] as any)?.text
                                ?.message as string
                        }
                    />
                );
            }}
        />
    );
};
