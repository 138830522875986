import {
    Type_sch_index_people,
    Type_sch_select_people,
    Type_select_people,
} from "./types";

export const formatterSelectPeople = (
    data: Type_sch_select_people[],
): Type_select_people[] => {
    return data.map((peopleTask: Type_sch_select_people) =>
        formatterSelectPerson(peopleTask),
    );
};

export const formatterSelectPerson = (
    person: Type_sch_select_people,
): Type_select_people => {
    return {
        id: person.id,
        userId: person?.user_id,
        firstName: person.firstName,
        lastName: person.lastName,
        email: person.email,
    };
};

export const formatterUpdatePerson = (
    person: Type_sch_index_people,
): Type_select_people => {
    return {
        id: person.id,
        userId: person?.user_id,
        firstName: person.firstName,
        lastName: person.lastName,
        email: person.email,
    };
};
