import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Stack,
    Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";

export type Type_ChildType = {
    key: string;
    label: string;
    description?: string;
    link?: { icon: string; action: () => void };
    icon?: any;
    selected?: boolean;
    action: () => void; // Modify the return type as needed
    hidden?: boolean;
    disabled?: boolean;
};

export type Type_MenuItems = {
    parentKey: string | null;
    hidden?: boolean;
    childs: Type_ChildType[];
};

type Type_MenuTreeContainerProps = {
    items: Type_MenuItems[];
    dividerTop?: boolean;
    dividerBottom?: boolean;
    scrollHeight?: boolean;
};

export const MenuTree = ({
    items,
    dividerTop,
    dividerBottom,
    scrollHeight,
}: Type_MenuTreeContainerProps) => {
    return (
        <MenuList
            component={"div"}
            sx={{
                maxHeight: scrollHeight ? items.length * 380 : "100%",
                overflowX: "hidden",
                maxWidth: "320px",
                overflowY: scrollHeight ? "auto" : "hidden",
            }}
        >
            {dividerTop && <Divider />}
            {items.map(
                (item) =>
                    !item.hidden && (
                        <Box
                            data-testid={
                                item.parentKey &&
                                `MenuTree-list-${item.parentKey.replaceAll(
                                    ".",
                                    "_",
                                )}`
                            }
                            key={item.parentKey}
                        >
                            {item.parentKey && (
                                <Typography variant="body3Bold">
                                    {item.parentKey}
                                </Typography>
                            )}

                            {item.childs.map(
                                (child) =>
                                    !child.hidden && (
                                        <MenuItem
                                            disabled={child.disabled}
                                            key={child.key}
                                            selected={child.selected}
                                            data-testid={`MenuTree-item-${child.key.replaceAll(
                                                ".",
                                                "_",
                                            )}`}
                                            onClick={() => child.action()}
                                        >
                                            {child.icon && (
                                                <ListItemIcon>
                                                    {child.icon}
                                                </ListItemIcon>
                                            )}
                                            <ListItemText>
                                                <Stack
                                                    direction={"row"}
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        sx={{
                                                            textWrap: "wrap",
                                                        }}
                                                        variant="body1"
                                                    >
                                                        {child.label}
                                                    </Typography>
                                                    {child.link && (
                                                        <IconButton
                                                            sx={{ padding: 2 }}
                                                            size="small"
                                                            onClick={(
                                                                event,
                                                            ) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                child.link?.action();
                                                            }}
                                                            data-testid={`MenuTree-navigateToSubProjectSetting-${child.key.replaceAll(
                                                                ".",
                                                                "_",
                                                            )}`}
                                                        >
                                                            <Icon
                                                                variant="solid"
                                                                icon={
                                                                    child.link
                                                                        .icon
                                                                }
                                                                fontSize="small"
                                                            />
                                                        </IconButton>
                                                    )}
                                                </Stack>
                                                {child.description && (
                                                    <Typography
                                                        color="text.secondary"
                                                        sx={{
                                                            mb: 2,
                                                            fontSize: "12px",
                                                            textWrap: "wrap",
                                                        }}
                                                    >
                                                        {child.description}
                                                    </Typography>
                                                )}
                                            </ListItemText>
                                        </MenuItem>
                                    ),
                            )}
                            {dividerBottom && <Divider />}
                        </Box>
                    ),
            )}
        </MenuList>
    );
};
