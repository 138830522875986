import React from "react";
import { Group } from "react-konva";

import {
    workforceCharts,
    zoomXLevels,
    zoomYLevels,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { Enum_typeChart } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";
import { PlanningWorkforceButtons } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforceButtons";
import { Enum_typeZoom } from "src/components/Components_Teamoty/Planning/Zooms/PlanningZoom.type";
import { PlanningZoomButtons } from "src/components/Components_Teamoty/Planning/Zooms/PlanningZoomButtons";
import { usePlanningContext } from "src/contexts/planning";

import { Type_Props_PlanningScrolls } from "./PlanningScroll.type";
import { PlanningScrollX } from "./PlanningScrollX";
import { PlanningScrollY } from "./PlanningScrollY";

export const PlanningScrolls = ({
    scrollX,
    scrollY,
    zoomX,
    zoomY,
    gantt = false,
}: Type_Props_PlanningScrolls) => {
    const { viewFilters, setViewFilters } = usePlanningContext();

    const changeZoom = (pos: number, who?: Enum_typeZoom) => {
        switch (who) {
            case Enum_typeZoom.x:
                zoomX.changeZoom(pos);
                break;

            case Enum_typeZoom.y:
                zoomY?.changeZoom(pos);
                break;
        }
    };

    const changeChart = (chart: Enum_typeChart): void => {
        const currentWorkforceChart = viewFilters.workforceChart;
        setViewFilters((prev) => {
            return {
                ...prev,
                workforceChart:
                    currentWorkforceChart === chart
                        ? Enum_typeChart.none
                        : chart,
            };
        });
    };

    return (
        <Group>
            <PlanningWorkforceButtons
                x={0}
                y={scrollX.y}
                buttons={workforceCharts}
                currentChart={viewFilters.workforceChart}
                changeChart={(chart: Enum_typeChart) => changeChart(chart)}
            />
            <PlanningScrollY
                x={scrollY.x}
                y={scrollY.y}
                width={scrollY.width}
                widthPage={scrollY.widthPage}
                page={scrollY.page}
                size={scrollY.size}
                pos={scrollY.pos}
                changePos={scrollY.changePos}
            />
            <PlanningScrollX
                x={scrollX.x}
                y={scrollX.y}
                width={scrollX.width}
                widthPage={scrollY.widthPage}
                page={scrollX.page}
                size={scrollX.size}
                pos={scrollX.pos}
                changePos={scrollX.changePos}
            />
            <PlanningZoomButtons
                pos={zoomX.pos}
                x={zoomX.x}
                y={zoomX.y}
                buttons={zoomXLevels}
                direction={Enum_typeZoom.y}
                changeZoom={(pos: number): void => {
                    changeZoom(pos, Enum_typeZoom.x);
                }}
            />
            {/*<PlanningZoomX*/}
            {/*    x={zoomX.x}*/}
            {/*    y={zoomX.y}*/}
            {/*    width={zoomX.width}*/}
            {/*    min={zoomX.min}*/}
            {/*    max={zoomX.max}*/}
            {/*    pos={zoomX.pos}*/}
            {/*    changeZoom={changeZoom}*/}
            {/*/>*/}
            {!gantt && zoomY && (
                <PlanningZoomButtons
                    pos={zoomY.pos}
                    x={zoomY.x}
                    y={zoomY.y}
                    buttons={zoomYLevels}
                    direction={Enum_typeZoom.y}
                    changeZoom={(pos: number): void => {
                        changeZoom(pos, Enum_typeZoom.y);
                    }}
                    withBorder={true}
                />
            )}
        </Group>
    );
};
