import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SimpleTreeView, TreeItem, TreeViewProps } from "@mui/x-tree-view";
import React, {
    Dispatch,
    memo,
    SetStateAction,
    useEffect,
    useState,
} from "react";

import { Type_index_area } from "src/api/tms-projects/areas/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

const Styled_AreaColor = styled(Box)(({ theme }: any) => ({
    borderRadius: theme.borderRadius,
    height: theme.spacing(4),
    width: theme.spacing(4),
}));

type Type_Props_AreaColor = {
    backgroundColor: string;
};

export const AreaColor = ({ backgroundColor }: Type_Props_AreaColor) => {
    return <Styled_AreaColor sx={{ backgroundColor: backgroundColor }} />;
};

type Type_Props_renderAreaItem = {
    area: Type_index_area;
    selectedAreas: string[];
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleAllChildrenClick: (
        event: React.MouseEvent<HTMLButtonElement>,
        areas: Type_index_area[],
        add: boolean,
        selectedAreas: string[],
    ) => void;
    drawingId?: number;
};

const renderAreaItem = ({
    area,
    selectedAreas,
    handleOnChange,
    handleAllChildrenClick,
    drawingId,
}: Type_Props_renderAreaItem) => {
    const [btnVisible, setBtnVisible] = useState(false);

    return (
        <TreeItem
            key={`AreasTreeView-${area.id}`}
            itemId={String(area.id)}
            data-testid={`AreasTreeView-${area.name}`}
            onMouseOver={() => setBtnVisible(true)}
            onMouseLeave={() => setBtnVisible(false)}
            label={
                <Stack direction={"row"} alignItems="center" spacing={2}>
                    <Checkbox
                        data-testid={`AreasTreeView-${area.name}-checkbox`}
                        checked={selectedAreas.indexOf(String(area.id)) !== -1}
                        value={String(area.id)}
                        disabled={
                            drawingId
                                ? area.drawing !== null &&
                                  area.drawing.id !== drawingId
                                : false
                        }
                        disableRipple
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        onChange={handleOnChange}
                    />
                    <AreaColor backgroundColor={area.color} />
                    <Typography flexGrow={1}>{area.name}</Typography>
                    {area.children.length > 0 && (
                        <Box display={btnVisible ? "visible" : "none"}>
                            <IconButton
                                data-testid={`AreasTreeView-${area.name}-check-all-children`}
                                onClick={(event) =>
                                    handleAllChildrenClick(
                                        event,
                                        area.children,
                                        true,
                                        selectedAreas,
                                    )
                                }
                            >
                                <Icon variant="light" icon="check" />
                            </IconButton>
                            <IconButton
                                data-testid={`AreasTreeView-${area.name}-uncheck-all-children`}
                                onClick={(event) =>
                                    handleAllChildrenClick(
                                        event,
                                        area.children,
                                        false,
                                        selectedAreas,
                                    )
                                }
                            >
                                <Icon variant="light" icon="xmark" />
                            </IconButton>
                        </Box>
                    )}
                </Stack>
            }
        >
            {area.children?.map((item: Type_index_area) =>
                renderAreaItem({
                    area: item,
                    selectedAreas,
                    handleOnChange,
                    handleAllChildrenClick,
                    drawingId,
                }),
            )}
        </TreeItem>
    );
};

type Type_Props_AreasTree = TreeViewProps<any> & {
    height: number | string;
    areas: Type_index_area[];
    selectedAreas: string[];
    setSelectedAreas: Dispatch<SetStateAction<string[]>>;
    drawingId?: number;
    outlined?: boolean;
    isAllExpanded?: boolean;
};

export const getFlattenIds = (
    items: Type_index_area[],
    selectedAreas: string[],
    add: boolean,
    drawingId?: number,
): string[] => {
    let result: string[] = [];

    items.forEach((item) => {
        if (!item.drawing || item.drawing.id === drawingId || !drawingId) {
            const itemIdStr = String(item.id);

            // N'ajoute l'ID que s'il n'est pas présent dans selectedAreas
            if (!add || !selectedAreas.includes(itemIdStr)) {
                result.push(itemIdStr);
            }
        }

        if (item?.children?.length > 0) {
            result = result.concat(
                getFlattenIds(item.children, selectedAreas, add, drawingId),
            );
        }
    });

    return result;
};

const MemoizedAreasTreeView = ({
    height,
    areas,
    selectedAreas = [],
    setSelectedAreas,
    drawingId,
    outlined,
    isAllExpanded = false,
}: Type_Props_AreasTree) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (event.target.checked) {
            setSelectedAreas((prev) => [...prev, event.target.value]);
        } else {
            setSelectedAreas((prev) => [
                ...prev.filter((id) => id !== event.target.value),
            ]);
        }
    };

    const handleAllChildrenClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        areas: Type_index_area[],
        add: boolean,
        selectedAreas: string[],
    ): void => {
        event.stopPropagation();
        const childrenAreaIds = getFlattenIds(
            areas,
            selectedAreas,
            add,
            drawingId,
        );

        if (add) {
            setSelectedAreas((prev) => [...prev, ...childrenAreaIds]);
        } else {
            setSelectedAreas((prev) => [
                ...prev.filter((id) => !childrenAreaIds.includes(id)),
            ]);
        }
    };

    const handleExpandedItemsChange = (
        _event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedItems(itemIds);
    };

    useEffect(() => {
        if (!selectedAreas?.length || !areas?.length) return;

        const result: Set<string> = new Set();

        const searchSelectedChildren = (area: Type_index_area): boolean => {
            const isSelected: boolean = selectedAreas.includes(String(area.id));

            let hasSelectedChild: boolean = false;
            for (const child of area.children) {
                if (searchSelectedChildren(child)) {
                    hasSelectedChild = true;
                }
            }

            if (hasSelectedChild || isSelected || isAllExpanded) {
                if (area.parentId !== null) {
                    result.add(String(area.parentId));
                }
            }

            return isSelected || hasSelectedChild;
        };

        for (const area of areas) {
            searchSelectedChildren(area);
        }

        setExpandedItems(Array.from(result));
    }, []);

    return (
        <SimpleTreeView
            aria-label="Areas tree view"
            slots={{
                expandIcon: MyExpandIcon,
                collapseIcon: MyCollapseIon,
            }}
            sx={(theme) => ({
                maxHeight: height,
                flexGrow: 1,
                overflowY: "auto",
                border: outlined ? theme.border.default : "none",
                borderRadius: outlined
                    ? theme?.shape?.borderRadiusSmall
                    : "none",
            })}
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
        >
            {areas.map((area) =>
                renderAreaItem({
                    area,
                    selectedAreas,
                    handleOnChange: handleCheck,
                    handleAllChildrenClick,
                    drawingId,
                }),
            )}
        </SimpleTreeView>
    );
};

MemoizedAreasTreeView.displayName = "AreasTreeView";

export const AreasTreeView = memo(MemoizedAreasTreeView);

const MyExpandIcon = () => {
    return <Icon variant="solid" icon="chevron-right" />;
};

const MyCollapseIon = () => {
    return <Icon variant="solid" icon="chevron-down" />;
};
