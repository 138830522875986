import { Grid, Tooltip } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useFormContext } from "react-hook-form";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    Styled_ButtonColorGrid,
    Styled_Popover,
} from "src/components/Components_Common/ColorPicker/ColorPicker.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLOR_PICKER_COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

// TODO maybe should be merge with other colorpicker
type Type_Props_ColorPopover = {
    anchorEl: HTMLButtonElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    handleSelectColor: (color: string) => void;
    currentValue?: string;
    selectedColors?: string[];
    header?: ReactElement;
    disabled?: boolean;
};

const ColorPopover = ({
    anchorEl,
    setAnchorEl,
    handleSelectColor,
    currentValue,
    selectedColors,
    header,
    disabled,
}: Type_Props_ColorPopover) => {
    const handleColorPickerClick = (color: string): void => {
        handleSelectColor(color);
        setAnchorEl(null);
    };

    return (
        <Styled_Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            {header !== undefined && header}
            <Grid
                sx={{ opacity: disabled ? 0.3 : 1 }}
                container
                spacing={1}
                columns={8}
            >
                {COLOR_PICKER_COLORS.map((color: string, index: number) => {
                    return (
                        <Grid item xs={1} key={index}>
                            <Styled_ButtonColorGrid
                                disabled={disabled}
                                onClick={() => handleColorPickerClick(color)}
                                data-testid={`ColorPickerPopover-${color}-button`}
                                ownerState={{ fullPaletteColor: color }}
                            >
                                {(color === currentValue && (
                                    <Icon
                                        variant="solid"
                                        icon="check"
                                        sx={{
                                            color: invertColor(color),
                                        }}
                                    />
                                )) ||
                                    (selectedColors?.includes(color) && (
                                        <Icon
                                            variant="solid"
                                            icon="close"
                                            sx={{
                                                color: invertColor(color),
                                            }}
                                        />
                                    ))}
                            </Styled_ButtonColorGrid>
                        </Grid>
                    );
                })}
            </Grid>
        </Styled_Popover>
    );
};

type Type_Props_ColorPickerIcon = {
    name: string;
    currentValue?: string;
    selectedColors?: string[];
    header?: ReactElement;
    disabled?: boolean;
    handleIconClick?: () => void;
};
export const ColorPickerIcon = ({
    name,
    handleIconClick,
    ...props
}: Type_Props_ColorPickerIcon) => {
    const { register, setValue } = useFormContext<any>();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Form.Field.ColorPickerIcon",
    );

    const handleClick = (event: any): void => {
        handleIconClick && handleIconClick();
        setAnchorEl(event.currentTarget);
    };

    const handleSelectColor = (color: string) => {
        setValue(name, color, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };

    return (
        <>
            <Tooltip title={fmt("Tooltip")} placement={"left"}>
                <IconButton
                    color={"inherit"}
                    onClick={handleClick}
                    data-testid={`ColorPickerIcon-${name}-button`}
                >
                    <Icon variant={"light"} icon={"palette"} />
                </IconButton>
            </Tooltip>
            <input
                type="text"
                {...register(name)}
                style={{ display: "none" }}
                data-testid={`ColorPickerIcon-${name}-input`}
            />
            <ColorPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleSelectColor={handleSelectColor}
                {...props}
            />
        </>
    );
};
