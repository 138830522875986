import {
    Type_index_drawing,
    Type_prj_index_drawing,
    Type_prj_select_drawing,
    Type_prj_show_drawing,
    Type_select_drawing,
    Type_show_drawing,
} from "src/api/tms-projects/drawing/types";
import { getDate } from "src/utils/date";

export const formatterIndexDrawings = (
    data: Type_prj_index_drawing[],
): Type_index_drawing[] => {
    return data.map((datum: Type_prj_index_drawing) =>
        formatterIndexDrawing(datum),
    );
};

export const formatterIndexDrawing = (
    datum: Type_prj_index_drawing,
): Type_index_drawing => {
    return {
        id: datum.id,
        name: datum.name,
        grayscale: datum.grayscale,
        textSize: datum.textSize,
        enabled: datum.enabled,
        updatedAt: getDate(datum?.updated_at),
    };
};

export const formatterShowDrawing = (
    datum: Type_prj_show_drawing,
): Type_show_drawing => {
    return {
        id: datum.id,
        name: datum.name,
        grayscale: datum.grayscale,
        textSize: datum.textSize,
        enabled: datum.enabled,
        updatedAt: getDate(datum?.updated_at),
    };
};

export const formatterSelectListDrawings = (
    data: Type_prj_select_drawing[],
): Type_select_drawing[] => {
    if (!data) {
        return [];
    }
    return data;
};

export const formatterShowToSelect = (
    data: Type_show_drawing,
): Type_select_drawing => {
    return {
        id: data.id,
        name: data.name,
    };
};
