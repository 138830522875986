import Konva from "konva";
import React from "react";
import { Label, Tag } from "react-konva";

import { TextEdit } from "src/components/Components_Common/canvas/TextEdit";
import {
    colorFlowLinkText,
    fontFlowFamily,
} from "src/components/Components_Teamoty/Flow/Flow.const";

import { Type_Props_FlowLinkInfo } from "./FlowLink.type";

export const FlowLinkQuantity = ({
    refText,
    x,
    y,
    stroke,
    text,
    setOver,
}: Type_Props_FlowLinkInfo) => {
    const width: number = 31;
    const height: number = 17;

    const colorTagFill = stroke;
    const colorText: string = colorFlowLinkText;

    const onMouseOver = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;
        setOver(true);
    };

    const onMouseOut = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;
        setOver(false);
    };

    return (
        <Label x={x + width * 0.25} y={y - height / 2}>
            <Tag
                height={height}
                width={width}
                fill={colorTagFill}
                stroke={colorTagFill}
                strokeWidth={1}
            />
            <TextEdit
                refText={refText}
                text={text}
                fontFamily={fontFlowFamily}
                fontSize={10}
                width={width}
                fill={colorText}
                height={height}
                align="center"
                verticalAlign="middle"
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                editOffsetY={5}
                background={colorTagFill}
            />
        </Label>
    );
};
