import {
    heightFlowShape,
    widthFlowShape,
    widthFlowShapeFixedSize,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { Enum_typeFlowTask } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { Type_flowTask } from "src/components/Components_Teamoty/Flow/Flow.type";

export const getTaskWidth = (
    task: Type_flowTask,
    fixedSize?: boolean,
): number => {
    const time: number = fixedSize
        ? widthFlowShapeFixedSize
        : task?.duration ?? 1;

    switch (task.type) {
        case Enum_typeFlowTask.Work:
        case Enum_typeFlowTask.Study:
        case Enum_typeFlowTask.Technical:
        case Enum_typeFlowTask.Waiting:
            return Math.min(
                widthFlowShape * 7,
                Math.round((widthFlowShape * Math.max(1, time)) / 4) * 4,
            );

        case Enum_typeFlowTask.Milestone:
        case Enum_typeFlowTask.Delivery:
            return heightFlowShape;
        default:
            return Math.min(
                widthFlowShape * 7,
                Math.floor(widthFlowShape + 0.5) * Math.max(1, time),
            );
    }
};
