import { Typography } from "@mui/material";
import dayjs from "dayjs";
import * as React from "react";

import { FormattedDate } from "src/pages/Pages_Common/Page_Demo/FormattedDate";
import {
    displayFormattedDate,
    displayFormattedDateTime,
    formatDateForBack,
    formatDateForFront,
    formatDateTimeForBack,
    formatDateTimeForFront,
    getDate,
} from "src/utils/date";

export const FormattedDateDemo = () => {
    const date = getDate("2018-08-18");
    const dateTime = getDate("2018-08-18 00:00:00");
    const dateBack = formatDateForBack("2018-08-18");
    const dateTimeBack = formatDateTimeForBack("2018-08-18 00:00:00");
    return (
        <div>
            <Typography variant="h3">en_GB</Typography>
            <FormattedDate date={dateTime} language="en_GB" />
            <Typography variant="h3">en_US</Typography>
            <FormattedDate date={dateTime} language="en_US" />
            <Typography variant="h3">fr_FR</Typography>
            <FormattedDate date={dateTime} language="fr_FR" />
            <Typography variant="h3">de_DE</Typography>
            <FormattedDate date={dateTime} language="de_DE" />
            <hr />
            <Typography variant="h3">With current locale</Typography>
            <FormattedDate date={dateTime} />
            <hr />
            <Typography variant="h3">
                User locale time for {dayjs.tz.guess()}
            </Typography>
            <div>{displayFormattedDate(date)}</div>
            <div>{displayFormattedDateTime(dateTime)}</div>

            <Typography variant="h3">UTC date time for backend</Typography>
            <div>{dateBack}</div>
            <div>{dateTimeBack}</div>
            <Typography variant="h3">Back To Front</Typography>
            <div>
                Affiche date par le front:
                {displayFormattedDate(formatDateForFront(dateBack))}
            </div>
            <div>
                Affiche date time par le front:
                {displayFormattedDateTime(formatDateTimeForFront(dateTimeBack))}
            </div>
        </div>
    );
};
