import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    Type_RequestConfig,
} from "src/api/fetch";
import { ResourcePath, Url } from "src/api/paths";
import {
    Type_prj_post_resourcePeriod,
    Type_prj_put_resourcePeriod,
} from "src/api/tms-projects/resourcePeriods/types";

export const indexResourcePeriods = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ResourcePath.RESOURCE_PERIODS}`,
        requestConfig,
    );
};

export const createResourcePeriod = (
    requestConfig: Type_RequestConfig,
    data: Type_prj_post_resourcePeriod,
): PostReturnType => {
    return post(
        data,
        `${Url.PROJECTS}${ResourcePath.RESOURCE_PERIODS}`,
        requestConfig,
    );
};

export const updateResourcePeriod = (
    requestConfig: Type_RequestConfig,
    data: Type_prj_put_resourcePeriod,
): PostReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${ResourcePath.RESOURCE_PERIODS}/${data.id}`,
        requestConfig,
    );
};
