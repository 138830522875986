import { Stack, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import { Accordion } from "src/components/Components_Common/accordions/Accordion";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const SequenceFooter = () => {
    const { getValues } = useFormContext();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Sequence");

    return (
        <Accordion
            title={
                <Typography variant="body2">{fmt("Form.Source")}</Typography>
            }
            data-testid={`sequence-drawer-accordion-source`}
        >
            <Stack direction={"row"}>
                <Typography flex={1} variant={"body1"} color={"secondary"}>
                    Sequence ID
                </Typography>
                <Typography flex={1} variant={"body1"}>
                    {getValues("id")}
                </Typography>
            </Stack>
        </Accordion>
    );
};
