import { Box, Button, MenuItem, Popover, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const Styled_ProjectMenuBtn = styled(Button)(({ theme }) => ({
    padding: theme.spacing(2, 4),
    backgroundColor: "transparent",
    color: COLORS.white,
    gap: theme.spacing(1),
    borderRadius: "0px",
    "&:hover:not(.Mui-disabled), &.Mui-focused": {
        backgroundColor: theme.palette.navbar.hover,
        color: theme.palette.primary.contrastText,
    },
    "& .MuiTypography-root ": {
        color: "inherit",
    },
}));

export const Styled_PopoverProjectMenu = styled(Popover)(({ theme }) => ({
    "&.MuiPopover-root": {
        "& .MuiPopover-paper": {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            width: theme.spacing(88),
            maxWidth: theme.spacing(88),
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${COLORS.moon200}`,
            boxShadow: "0px 8px 16px rgba(22, 50, 95, 0.18)",
        },
    },
}));

export const Styled_SubProjectMenuImage = styled(Box)(({ theme }) => ({
    borderRadius: theme?.shape?.borderRadius,
    backgroundSize: "cover",
    width: 128,
    height: 92,
    position: "absolute",
    bottom: "8px",
}));

export const Styled_SubProjectMenuImageBlur = styled(Box)(() => ({
    filter: "blur(40px)",
    backgroundSize: "cover",
    width: 128,
    height: 92,
    position: "absolute",
    bottom: "8px",
}));

export const Styled_MenuItem = styled(MenuItem)(() => ({}));

export const Styled_SubProjectTypography = styled(Typography)(() => ({
    marginLeft: "20px",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
}));
