import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Form, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { useShowPeople } from "src/api/tms-scheduling/people";
import { Type_sch_put_people } from "src/api/tms-scheduling/people/types";
import { Type_dialogParamsDeprecated } from "src/components/Components_Common/Dialog/DialogGeneric";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_createNewPeople } from "./PeopleTaskForm";

type Type_Props_PeopleTaskFormDialog = {
    setIsFormLoading: Dispatch<SetStateAction<boolean>>;
    dialogParams: Type_dialogParamsDeprecated;
    formId: string;
    peopleTaskValue: string;
    createNewPeople: (data: Type_createNewPeople) => Promise<void>;
    updatePeople: (data: {
        id: number;
        data: Type_sch_put_people;
    }) => Promise<void>;
    onClose: () => void;
};

const newPeopleTaskValidationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required(FORM_ERR_FMT.REQUIRED),
    lastName: Yup.string().trim().required(FORM_ERR_FMT.REQUIRED),
    email: Yup.string().trim().email(FORM_ERR_FMT.MUST_BE_EMAIL),
});

export const PeopleTaskFormDialog = ({
    setIsFormLoading,
    dialogParams,
    formId,
    peopleTaskValue,
    onClose,
    createNewPeople,
    updatePeople,
}: Type_Props_PeopleTaskFormDialog) => {
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { data: people } = useShowPeople(dialogParams.id || undefined);

    const parseValue = (input: string) => {
        const words = input.split(/\s+/);
        const emailIndex = words.findIndex((word) => word.includes("@"));

        if (emailIndex === -1) {
            return {
                firstName: words[0] || "",
                lastName: words.slice(1).join(" ").trim() || "",
                email: "",
            };
        }

        return {
            firstName: words[0]?.includes("@") ? "" : words[0],
            lastName: words.slice(1, emailIndex).join(" ").trim() || "",
            email: words.slice(emailIndex).join(" "),
        };
    };

    const form = useForm<any>({
        defaultValues:
            dialogParams?.id && people
                ? {
                      firstName: "",
                      lastName: "",
                      email: "",
                  }
                : parseValue(peopleTaskValue),
        mode: "onSubmit",
        resolver: yupResolver<any>(newPeopleTaskValidationSchema),
    });

    useEffect(() => {
        if (dialogParams.id && people)
            form.reset({
                firstName: people?.firstName,
                lastName: people?.lastName,
                email: people?.email,
            });
    }, [people]);

    const onSubmit = async () => {
        setIsFormLoading(true);
        if (dialogParams.action === "create") {
            await createNewPeople({
                ...form.getValues(),
                userId: null,
            });
        } else if (dialogParams.action === "update") {
            await updatePeople({
                id: dialogParams.id as number,
                data: form.getValues(),
            });
        }
        setIsFormLoading(false);
        onClose();
    };

    return (
        <FormProvider {...form}>
            <Form onSubmit={() => onSubmit()} id={formId}>
                <Stack gap={2}>
                    <Stack direction="row" gap={2}>
                        <Stack direction="column" sx={{ flexGrow: 1 }}>
                            <TextField
                                required
                                name="firstName"
                                data-testid={`NewPeopleTask-firstName}`}
                                label={fmtLabels("Name")}
                            />
                        </Stack>
                        <Stack direction="column" sx={{ flexGrow: 1 }}>
                            <TextField
                                required
                                data-testid={`newPeopleTask-lastName}`}
                                name="lastName"
                                label={fmtLabels("LastName")}
                            />
                        </Stack>
                    </Stack>
                    <Stack>
                        <TextField
                            fullWidth
                            data-testid={`newPeopleTask-email}`}
                            name="email"
                            label={fmtLabels("Email")}
                        />
                    </Stack>
                </Stack>
            </Form>
        </FormProvider>
    );
};
