import { Box } from "@mui/material";

import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixSequenceAreas } from "src/components/Components_Scheduling/Matrix/MatrixSequenceAreas/MatrixSequenceAreas";

type Type_Props_AreaTableFullScreen = Omit<
    Type_props_DialogFullScreen,
    "title"
> & {
    sequenceId: number;
    sequenceName: string;
};

export const TableAreasFullScreen = ({
    openDialog,
    setToggleDialog,
    sequenceName,
    sequenceId,
}: Type_Props_AreaTableFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={sequenceName}
        >
            <Box sx={{ flexGrow: 1, mx: 4 }}>
                <MatrixSequenceAreas sequenceId={sequenceId} />
            </Box>
        </DialogFullScreen>
    );
};
