import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const BoxModal = styled(Box)<BoxProps>(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    backgroundColor: COLORS.white,
    borderRadius: 3,
    boxShadow: "8px",
}));
