import { useState } from "react";

import { Page_ReportsComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Reports/Page_Reports.component";

export type Type_modalDeleteReport = {
    isOpen: boolean;
    props?: any;
};

export const Page_ReportsContainer = () => {
    // const { isFetching, data = [] } = useIndexReport() || {};

    const isFetching = false;
    const data: any[] | undefined = undefined;

    // STATUS
    // const { mutate: mutationReportStatus } = mutationStatusReport() || {};

    const changeStatus = async (/*id: number, report: any*/) => {
        // mutationReportStatus({
        //     id,
        //     enabled: !report.enabled,
        // });
    };

    // DELETE
    const [modalDeleteReport, setModalDeleteReport] =
        useState<Type_modalDeleteReport>({
            isOpen: false,
            props: { name: "" },
        });

    // const { mutateAsync: mutateDeleteReport } = mutationDeleteReport() || {};
    const removeReport = async (/*id: number*/) => {
        // await mutateDeleteReport(id);
    };

    return (
        <Page_ReportsComponent
            isFetching={isFetching}
            reports={data ?? []}
            changeStatus={changeStatus}
            deleteReport={removeReport}
            modalDeleteReport={modalDeleteReport}
            setModalDeleteReport={setModalDeleteReport}
        />
    );
};
