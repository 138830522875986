import { Type_index_sequenceArea_forMatrix } from "src/api/tms-scheduling/sequenceAreas/types";

export type Row = Type_index_sequenceArea_forMatrix & {
    children?: Row[];
};

export type Type_Matrix_SequenceArea_Row = {
    [key: `area_name_${number}`]: string;
    [key: `area_type_name_${number}`]: string;
    order: number | null;
    areaSortOrder: number;
    id: number; // Id of the area
    seqAreaId: number | null; // If of the sequence-area
    selected: boolean;
};

export const formatAreasForTable = (
    areas: Type_index_sequenceArea_forMatrix[],
): Type_Matrix_SequenceArea_Row[] => {
    // Find the max depth to determine the number of name/type columns needed
    const maxDepth = getMaxDepth(areas);

    // Helper function to build the structure recursively
    const buildHierarchy = (
        parentId: number | null = null,
        depth = 1,
    ): Row[] => {
        return areas
            .filter((area) => area.areaParentId === parentId)
            .sort((a, b) => a.areaSortOrder - b.areaSortOrder)
            .map((area) => ({
                ...area,
                children: buildHierarchy(area.areaId, depth + 1),
            }));
    };

    const hierarchy = buildHierarchy();

    // Convert the hierarchical structure into rows
    const rows: Type_Matrix_SequenceArea_Row[] = [];

    const addRow = (
        node: Row,
        depth = 1,
        path: Record<string, string | number | undefined> = {},
    ) => {
        const row: Type_Matrix_SequenceArea_Row = {
            order: null,
            areaSortOrder: 0,
            id: 0,
            seqAreaId: null,
            selected: false,
        };

        // Fill each level's area_name and area_type up to maxDepth
        for (let i = 1; i <= maxDepth; i++) {
            row[`area_name_${i - 1}`] = String(
                path[`area_name_${i - 1}`] || "",
            );
            row[`area_type_name_${i - 1}`] = String(
                path[`area_type_name_${i - 1}`] || "",
            );
        }

        // Fill current node's data
        row[`area_name_${depth - 1}`] = node.areaName;
        row[`area_type_name_${depth - 1}`] = node.areaTypeName;
        row.order = node.order;
        row.areaSortOrder = node.areaSortOrder;
        row.id = node.areaId;
        row.seqAreaId = node.id;
        row.selected = !!node.id;

        rows.push(row);

        // Recursively add rows for children
        node.children?.forEach((child) =>
            addRow(child, depth + 1, {
                ...path,
                [`area_name_${depth - 1}`]: node.areaName,
                [`area_type_name_${depth - 1}`]: node.areaTypeName,
            }),
        );
    };

    // Flatten hierarchy into rows
    hierarchy.forEach((rootNode) => addRow(rootNode));

    return rows;
};

export const getMaxOrder = (areas: Type_Matrix_SequenceArea_Row[]): number =>
    Math.max(...areas.map((area) => area.order || 0));

export const getMaxDepth = (areas: Type_index_sequenceArea_forMatrix[]) =>
    Math.max(...areas.map((area) => area.areaDeep));
