import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { mutationResetPassword } from "src/api/tms-users/auth";
import { Type_post_resetPassword } from "src/api/tms-users/auth/types";
import { TMC_Button } from "src/components";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_ResetPasswordForm = Yup.object().shape({
    email: Yup.string()
        .email(FORM_ERR_FMT.MUST_BE_EMAIL)
        .required(FORM_ERR_FMT.REQUIRED),
});

export const ResetPasswordForm = () => {
    const { lang } = useParams();
    const navigate = useNavigate();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl("ResetPassword");
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { mutateAsync, isLoading } = mutationResetPassword();

    const form = useForm<Type_post_resetPassword>({
        ...useFormDefaultConfig,
        defaultValues: {
            email: "",
        },
        resolver: yupResolver<any>(Schema_ResetPasswordForm),
    });

    const navigateToLogin = (): void => {
        navigate(`/${lang}/login`, { replace: true });
    };

    const handleSubmit = async (
        values: Type_post_resetPassword,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        try {
            await mutateAsync(values);
        } catch (e) {
            console.error("error", e);
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Stack gap={4}>
                    <TextField
                        variant={"outlined"}
                        data-testid="ResetPassword-form-email-field"
                        name="email"
                        label={fmtLabels(`Email`)}
                        autoComplete="on"
                    />
                    <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Typography variant={"body2"}>
                            {fmt("RememberPassword")}
                        </Typography>
                        <TMC_Button
                            data-testid="ResetPassword-form-signin-button"
                            type="button"
                            onClick={navigateToLogin}
                            variant="text"
                            color={"secondary"}
                        >
                            {fmtActions("SignIn")}
                        </TMC_Button>
                    </Stack>
                    <TMC_Button
                        data-testid="ResetPassword-form-submit-button"
                        type="submit"
                        variant="contained"
                        color={"primary"}
                        disabled={!form.formState.isValid || isLoading}
                    >
                        {fmt("Submit")}
                    </TMC_Button>
                </Stack>
            </form>
        </FormProvider>
    );
};
