import { useState } from "react";

import {
    mutationDeleteRole,
    mutationStatusRole,
    useIndexRoles,
} from "src/api/tms-users/rolesAndPermissions";
import { Type_index_role } from "src/api/tms-users/rolesAndPermissions/types";

import { Page_RolesComponent } from "./Page_Roles.component";

export type Type_modalDeleteRole = {
    isOpen: boolean;
    props?: Type_index_role;
};

export const Page_RolesContainer = () => {
    const { isFetching, data: fetchedRoles = [] } = useIndexRoles() || {};

    const { mutate: mutationRoleStatus } = mutationStatusRole() || {};

    const [modalDeleteRole, setModalDeleteRole] =
        useState<Type_modalDeleteRole>({
            isOpen: false,
        });

    const { mutateAsync: mutateDeleteRole } = mutationDeleteRole() || {};

    const removeRole = (id: number) => {
        mutateDeleteRole(id);
    };

    const changeStatus = async (id: number, role: any) => {
        mutationRoleStatus({
            id,
            enabled: !role.enabled,
        });
    };

    return (
        <Page_RolesComponent
            isFetching={isFetching}
            removeRole={removeRole}
            changeStatus={changeStatus}
            roles={fetchedRoles}
            modalDeleteRole={modalDeleteRole}
            setModalDeleteRole={setModalDeleteRole}
        />
    );
};
