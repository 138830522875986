import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";
import { Type_Props_TextField } from "src/components/Components_Common/_MuiComponentsVariants/TextField/TextField";
import { TMC_ColorPicker } from "src/components/Components_Common/ColorPicker/TMC_ColorPicker";

type Type_Props_ColorPicker = Omit<Type_Props_TextField, "slotProps"> & {
    name: string;
    selectedColors?: string[];
};

export const ColorPicker = ({
    name,
    selectedColors,
    ...restProps
}: Type_Props_ColorPicker) => {
    const { formState, control, setValue } = useFormContext(); // retrieve those props

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={({ field: { ref: inputRef, value } }) => (
                    <>
                        <TMC_ColorPicker
                            currentColor={value}
                            name={name}
                            selectedColors={selectedColors}
                            callbackColor={(color) => {
                                setValue(name, color);
                            }}
                            error={!!formState.errors[name]}
                            helperText={
                                formState.errors[name] && (
                                    <FormHelperMessage
                                        inputName={name}
                                        message={
                                            formState?.errors[name]
                                                ?.message as string
                                        }
                                    />
                                )
                            }
                            {...restProps}
                            {...inputRef}
                        />
                    </>
                )}
            />
        </>
    );
};
