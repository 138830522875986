import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { ProgressHistoryKeys, TaskAreaKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskArea,
    formatterIndexTaskAreas,
    formatterProgressTaskArea,
    formatterShowTaskArea,
} from "src/api/tms-scheduling/taskArea/formatters";
import {
    createTaskArea,
    indexTaskAreas,
    setProgressTaskArea,
    showTaskArea,
} from "src/api/tms-scheduling/taskArea/services";
import {
    Type_post_taskArea,
    Type_progress_taskArea,
    Type_sch_index_taskArea,
    Type_sch_show_taskArea,
} from "src/api/tms-scheduling/taskArea/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreas = (areaId: number, taskId: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [TaskAreaKeys.INDEX, requestConfig, areaId, taskId],
        queryFn: ({ signal }) =>
            indexTaskAreas(
                {
                    ...requestConfig,
                    areaId: areaId,
                    taskId: taskId,
                },
                signal,
            ),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexTaskAreas(
                data?.data?.data as Type_sch_index_taskArea[],
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowTaskArea = (
    taskId: number,
    areaId: number,
    taskAreaId: number | null,
    staleTime = 0,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [
            TaskAreaKeys.SHOW,
            requestConfig,
            taskAreaId,
            taskId,
            areaId,
        ],
        queryFn: ({ signal }) =>
            showTaskArea(
                { ...requestConfig, taskId: taskId, areaId: areaId },
                taskAreaId,
                signal,
            ),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterShowTaskArea(
                data?.data?.data as Type_sch_show_taskArea,
            );
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
        staleTime,
    });
};

/**
 * Create OR Update a taskArea
 */
export const mutationUpsertTaskArea = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (taskArea: Type_post_taskArea) =>
            createTaskArea(formatterCreateTaskArea(taskArea), {
                ...requestConfig,
                areaId: taskArea.areaId,
                taskId: taskArea.taskId,
                taskAreaId: taskArea.id,
            }),
        onSuccess: (data, variables): void => {
            if (data && data?.data?.success) {
                queryClient.setQueryData(
                    [
                        TaskAreaKeys.SHOW,
                        requestConfig,
                        null,
                        variables.taskId,
                        variables.areaId,
                    ],
                    (oldData) => {
                        if (!oldData) return data;
                        // Must have the same props as the 'success' function of the response useShowTask
                        return {
                            ...oldData,
                            ...data,
                        };
                    },
                );
                sendEvent("postTaskArea");
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

/**
 * Update progress value of a taskArea
 */
export const mutationSetProgressTaskArea = () => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (progressTaskArea: Type_progress_taskArea) =>
            setProgressTaskArea(formatterProgressTaskArea(progressTaskArea), {
                ...requestConfig,
                taskId: progressTaskArea.taskId,
                areaId: progressTaskArea.areaId,
            }),
        onSuccess: (data, variables): void => {
            if (data?.data?.success) {
                const taskAreaId = data?.data?.data?.id;

                queryClient.setQueryData(
                    [
                        TaskAreaKeys.SHOW,
                        requestConfig,
                        variables?.taskAreaId, // TaskAreaId if already exists or null
                        variables.taskId,
                        variables.areaId,
                    ],
                    (oldData: any) => {
                        // Must have the same props as the 'success' function of the response useShowTask
                        return {
                            ...oldData,
                            data: {
                                ...oldData?.data,
                                data: {
                                    ...oldData?.data?.data,
                                    ...data?.data?.data,
                                    id: taskAreaId,
                                    progressValue: variables.progressValue,
                                },
                            },
                        };
                    },
                );

                queryClient.invalidateQueries([
                    ProgressHistoryKeys.INDEX,
                    requestConfig,
                    taskAreaId,
                ]);
                sendEvent("progressTaskArea" + variables.origin);
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

//////////////////////////////////////////////
///     Utils                              ///
//////////////////////////////////////////////

/**
 * Update taskAreaShow cache
 */
export const updateTaskAreaIdOnShowCache = (
    taskAreaId: number,
    taskId: number,
    areaId: number,
    requestConfig: Type_RequestConfig,
    queryClient: QueryClient,
) => {
    queryClient.setQueryData(
        [TaskAreaKeys.SHOW, requestConfig, null, taskId, areaId],
        (oldData: any) => {
            if (!oldData)
                return {
                    data: {
                        data: {
                            id: taskAreaId,
                        },
                    },
                };
            // Must have the same props as the 'success' function of the response useShowTask
            return {
                ...oldData,
                data: {
                    ...oldData.data,
                    data: {
                        ...oldData.data.data,
                        id: taskAreaId,
                    },
                },
            };
        },
    );
};

//////////////////////////////////////////////
