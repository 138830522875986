import { Type_SelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

export const formatterTimelineToOptionItem = (
    data: string[],
): Type_SelectOptionItem[] => {
    return data.map((item) => {
        return {
            value: item,
            label: item,
        };
    });
};

export const formatterPaperFormatToOptionItem = (
    data: string[],
): Type_SelectOptionItem[] => {
    return data.map((item) => {
        return {
            value: item,
            label: item,
        };
    });
};
