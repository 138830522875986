import { AxiosRequestConfig } from "axios";
import * as React from "react";
import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { formatterSelectList } from "src/api/formatters";
import { ProjectCompanyKeys } from "src/api/tms-projects/keys";
import {
    formatterCreateProjectCompany,
    formatterIndexProjectCompaniesWithMeta,
    formatterIndexProjectCompany,
    formatterShowProjectCompany,
    formatterStatusProjectCompany,
    formatterUpdateProjectCompany,
} from "src/api/tms-projects/projectCompanies/formatters";
import {
    createProjectCompany,
    deleteProjectCompany,
    indexProjectCompanies,
    selectListProjectCompanies,
    showProjectCompany,
    statusProjectCompany,
    updateProjectCompany,
} from "src/api/tms-projects/projectCompanies/services";
import {
    Type_post_projectCompany,
    Type_prj_index_projectCompanyWithMeta,
    Type_put_projectCompany,
    Type_show_projectCompany,
} from "src/api/tms-projects/projectCompanies/types";
import { Type_selectList } from "src/api/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexProjectCompanies = (axiosConfig: AxiosRequestConfig) => {
    const { requestConfig } = useProject();
    const config = { ...requestConfig };

    return useQuery({
        queryKey: [ProjectCompanyKeys.INDEX, requestConfig, axiosConfig],
        queryFn: ({ signal }) =>
            indexProjectCompanies(requestConfig, signal, axiosConfig),
        refetchOnWindowFocus: false,
        select: (res) => {
            if (!res?.success || !res?.data?.data) {
                throw new Error("Wrong format data: useIndexProjectCompanies");
            }

            return formatterIndexProjectCompaniesWithMeta(
                res.data as Type_prj_index_projectCompanyWithMeta,
            );
        },
        enabled: !!config.projectId,
    });
};

export const useShowProjectCompany = (id: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [ProjectCompanyKeys.SHOW, id, requestConfig],
        queryFn: ({ signal }) =>
            showProjectCompany(id, requestConfig as Type_RequestConfig, signal),
        refetchOnWindowFocus: false,
        select: (data): Type_show_projectCompany => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowProjectCompany");
            }

            return formatterShowProjectCompany(data.data.data);
        },
        enabled: !!requestConfig?.projectId && !!id,
    });
};

export const mutationStatusProjectCompany = (
    axiosConfig: AxiosRequestConfig,
) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: Type_put_projectCompany) => {
            return statusProjectCompany(
                newStatus.id,
                formatterStatusProjectCompany(newStatus),
                requestConfig,
                axiosConfig,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusProjectCompany",
                );
            } else {
                await queryClient.invalidateQueries([
                    ProjectCompanyKeys.INDEX,
                    requestConfig,
                    axiosConfig,
                ]);
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationCreateProjectCompany = (
    configParams: AxiosRequestConfig,
) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectCompanies",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_post_projectCompany) => {
            return createProjectCompany(
                formatterCreateProjectCompany(data),
                requestConfig,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateProjectCompany",
                );
            } else {
                const company = formatterIndexProjectCompany(data.data.data);

                await queryClient.invalidateQueries([
                    ProjectCompanyKeys.INDEX,
                    { ...requestConfig, ...configParams },
                ]);

                addSuccess({
                    description: fmt("ToastSuccess", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            projectCompany: company.name,
                        },
                    }),
                });
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationCreateCompany", err);
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateProjectCompany = (
    configParams: AxiosRequestConfig,
) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectCompanies",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_show_projectCompany) =>
            updateProjectCompany(
                data.id,
                formatterUpdateProjectCompany(data),
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateProjectCompany",
                );
            } else {
                const company = formatterIndexProjectCompany(data.data.data);

                await queryClient.invalidateQueries([
                    ProjectCompanyKeys.INDEX,
                    requestConfig,
                    configParams,
                ]);

                addSuccess({
                    description: fmt("ToastSuccessUpdate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            projectCompany: company.name,
                        },
                    }),
                });
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};

export const useSelectListProjectCompanies = (
    enabled: boolean = true,
): UseQueryResult<Type_selectList[]> => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [ProjectCompanyKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListProjectCompanies(requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_selectList[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useSelectListProjectCompanies",
                );
            }
            return formatterSelectList(data.data.data);
        },
        enabled: !!requestConfig.projectId && enabled,
    });
};

export const mutationDeleteProjectCompany = (
    configParams: AxiosRequestConfig,
) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectCompanies",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (id: number) => {
            return deleteProjectCompany(id, requestConfig);
        },
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationDeleteProjectCompany",
                );
            } else {
                await queryClient.invalidateQueries([
                    ProjectCompanyKeys.INDEX,
                    requestConfig,
                    configParams,
                ]);

                addSuccess({
                    description: fmt("ToastSuccessDelete"),
                });
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
