import { FormikValues } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { VersionKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexVersion,
    formatterIndexVersionsToSelectList,
    formatterMutateVersion,
    formatterShowVersion,
} from "src/api/tms-projects/versions/formatters";
import {
    createVersion,
    deleteVersion,
    indexVersions,
    selectListVersions,
    showVersion,
    updateVersion,
} from "src/api/tms-projects/versions/services";
import { Type_prj_index_version } from "src/api/tms-projects/versions/types";
import { useProject } from "src/contexts/project";

export const useIndexVersions = (callback: any) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [VersionKeys.INDEX, requestConfig],
        queryFn: () => indexVersions(requestConfig),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data.success && data?.data?.data) {
                callback(formatterIndexVersion(data.data.data));
            }
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};

export const useShowVersion = (callback: any, id: number) => {
    const { requestConfig } = useProject();
    return useQuery(
        [VersionKeys.SHOW, id],
        () => showVersion(id, requestConfig),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                if (data.success && data?.data?.data) {
                    callback(formatterShowVersion(data.data.data));
                }
            },
            onError: (err) => {
                console.error(err);
                return err;
            },
        },
    );
};

export const mutationCreateVersion = (callback: any) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (version: FormikValues) =>
            createVersion(
                formatterMutateVersion(version as Type_prj_index_version),
                requestConfig,
            ),
        onSuccess: async (data: any, _newVersion: FormikValues) => {
            if (data.success) {
                callback(formatterIndexVersion(data.data.data));
            }
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const mutationUpdateVersion = (callback?: any) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (versionToUpdate: any) =>
            updateVersion(
                formatterMutateVersion(versionToUpdate),
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: useIndexVersionNotes");
            }
            callback && callback(formatterIndexVersion(data.data.data));
            await queryClient.invalidateQueries({
                queryKey: [VersionKeys.INDEX, requestConfig],
            });
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useDeleteVersion = (callback: (id: number) => void) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) => deleteVersion(id, requestConfig),
        onSuccess: (_data, id) => {
            callback(id);
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useSelectListVersion = (callback: any) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [VersionKeys.SELECT_LIST, requestConfig],
        queryFn: () => selectListVersions(requestConfig),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data.success && data?.data?.data) {
                callback(formatterIndexVersionsToSelectList(data?.data?.data));
            }
        },
        onError: (err) => {
            console.error(err);
            return err;
        },
    });
};
