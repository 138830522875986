import { DialogProps } from "@mui/material";
import React, { useState } from "react";

import { formatterTaskToPunchlist } from "src/api/tms-scheduling/punchlist/formatters";
import { Type_index_punchlist } from "src/api/tms-scheduling/punchlist/types";
import { Type_show_taskArea } from "src/api/tms-scheduling/taskArea/types";
import { Dialog } from "src/components/Components_Common/Dialog/Dialog";
import { TaskAreaPunchlistForm } from "src/forms/taskPunchlist/TaskAreaPunchlistForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_TaskPunchlistModal = {
    onClose: () => void;
    open: boolean;
    data: Type_show_taskArea;
    page?: string;
} & DialogProps;

export const TaskPunchlistDialog = ({
    onClose,
    open,
    data,
    page,
}: Type_Props_TaskPunchlistModal) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Punchlist");
    const formId = "taskPunchlistDialog";
    const [isLoading, setIsLoading] = useState(false);

    const [punchlistData] = useState<Type_index_punchlist>(
        formatterTaskToPunchlist(data),
    );

    return (
        <Dialog
            name={formId}
            open={open}
            onClose={onClose}
            page={page}
            isLoading={isLoading}
            actionText={fmt("CompleteMyTask")}
            sx={{ minWidth: "560px" }}
            clean
        >
            <TaskAreaPunchlistForm
                setIsLoading={setIsLoading}
                onClose={onClose}
                data={punchlistData}
                formId={formId}
            />
        </Dialog>
    );
};
