import Konva from "konva";
import React, { RefObject, useRef, useState } from "react";
import { Arrow } from "react-konva";
import { Html, Portal } from "react-konva-utils";

import { LINK_DIRECTION } from "src/api/tms-scheduling/taskLinks/types";
import { Type_point } from "src/components/Components_Common/canvas/types";
import {
    colorFlowLink,
    colorFlowLinkSelected,
    heightFlowShape,
    hitFlowLine,
    radiusFlowCircle,
    widthFlowLine,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { Enum_typeFlowLink } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { getTaskWidth } from "src/components/Components_Teamoty/Flow/tools/getTaskWidth";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { Link } from "src/dialogs/Link";

import { Type_Props_FlowLink } from "./FlowLink.type";
import { FlowLinkQuantity } from "./FlowLinkQuantity";
import { FlowLinkWaiting } from "./FlowLinkWaiting";

export const FlowLink = ({
    link,
    taskFrom,
    taskTo,
    index,
    selected,
    changeSelected,
    fixedSize,
}: Type_Props_FlowLink) => {
    const strokeColorSelected: string = colorFlowLinkSelected;
    const strokeColor: string = colorFlowLink;

    const refQuantity: RefObject<Konva.Text> = useRef<Konva.Text | null>(null);
    const refWaiting: RefObject<Konva.Text> = useRef<Konva.Text | null>(null);

    const [over, setOver] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    // const [points, setPoints] = useState<Array<number>>([]);

    // useEffect((): void => {
    const widthFrom: number = getTaskWidth(taskFrom, fixedSize);
    const widthTo: number = getTaskWidth(taskTo, fixedSize);

    const height: number = heightFlowShape;
    const offsetHeight: number = heightFlowShape;

    const shapeDrawFunctionFS = (): number[] => {
        const points: number[] = [];
        points.push(taskFrom.pt.x + widthFrom + radiusFlowCircle);
        points.push(taskFrom.pt.y + offsetHeight / 2);

        points.push(taskTo.pt.x - radiusFlowCircle);
        points.push(taskTo.pt.y + height / 2);
        return points;
    };

    const shapeDrawFunctionSS = (): number[] => {
        const points: number[] = [];
        points.push(taskFrom.pt.x - radiusFlowCircle);
        points.push(taskFrom.pt.y + offsetHeight / 2);

        points.push(taskTo.pt.x - radiusFlowCircle);
        points.push(taskTo.pt.y + height / 2);
        return points;
    };

    const shapeDrawFunctionFF = (): number[] => {
        const points: number[] = [];
        points.push(taskFrom.pt.x + widthFrom + radiusFlowCircle);
        points.push(taskFrom.pt.y + offsetHeight / 2);

        points.push(taskTo.pt.x + widthTo + radiusFlowCircle);
        points.push(taskTo.pt.y + height / 2);
        return points;
    };

    const shapeDrawFunctionSF = (): number[] => {
        const points: number[] = [];
        points.push(taskFrom.pt.x - radiusFlowCircle);
        points.push(taskFrom.pt.y + offsetHeight / 2);

        points.push(taskTo.pt.x + widthTo + radiusFlowCircle);
        points.push(taskTo.pt.y + height / 2);
        return points;
    };

    const shapeDrawFunction = (): number[] => {
        switch (link.type) {
            case Enum_typeFlowLink.SF:
                return shapeDrawFunctionSF();
            case Enum_typeFlowLink.SS:
                return shapeDrawFunctionSS();
            case Enum_typeFlowLink.FF:
                return shapeDrawFunctionFF();
            default:
                return shapeDrawFunctionFS();
        }
    };
    const points: number[] = shapeDrawFunction();

    // setPoints(newPoints);
    // }, [obstacles]);

    const clickHandle = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;
        changeSelected({ selected: true, link: link });
    };

    const dblClickHandle = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;
        changeSelected({ selected: false, link: link });
        setOpen(true);
    };

    const onMouseOver = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;
        changeCursor(e, "pointer");
        setOver(true);
    };

    const onMouseOut = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;
        changeCursor(e, "default");
        setOver(false);
    };

    const selectedArrow: boolean =
        selected.selected && selected?.link?.id === link.id;

    const selectedArrowFromTask: boolean =
        selected?.task?.id === link.taskFrom ||
        selected?.task?.id === link.taskTo;

    const color: string =
        selectedArrow || selectedArrowFromTask
            ? strokeColorSelected
            : over
              ? strokeColorSelected
              : strokeColor;

    const firstPoints: Type_point = {
        x: points[0],
        y: points[1],
    };
    const lastPoints: Type_point = {
        x: points[points.length - 2],
        y: points[points.length - 1],
    };

    return (
        <>
            <Portal
                selector=".top"
                enabled={over || selectedArrow || selectedArrowFromTask}
            >
                <Arrow
                    name="preview"
                    points={points}
                    id={link.id.toString()}
                    lineJoin="round"
                    key={index * 10 + 6}
                    stroke={color}
                    fill={color}
                    onClick={clickHandle}
                    onDblClick={dblClickHandle}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    strokeWidth={widthFlowLine}
                    hitStrokeWidth={hitFlowLine}
                    tension={0}
                />
                {points.length >= 4 && link.quantity && +link.quantity > 0 ? (
                    <FlowLinkQuantity
                        refText={refQuantity}
                        text={link.quantity.toString()}
                        x={firstPoints.x}
                        y={firstPoints.y}
                        stroke={color}
                        strokeWidth={widthFlowLine}
                        setOver={setOver}
                    />
                ) : (
                    <></>
                )}
                {points.length >= 4 &&
                link.waitingDay &&
                +link.waitingDay > 0 ? (
                    <FlowLinkWaiting
                        refText={refWaiting}
                        text={link.waitingDay.toString()}
                        x={lastPoints.x}
                        y={lastPoints.y}
                        stroke={color}
                        strokeWidth={widthFlowLine}
                        setOver={setOver}
                    />
                ) : (
                    <></>
                )}
            </Portal>
            <Html>
                <Link
                    handleClose={() => setOpen(false)}
                    linkId={link.id}
                    open={open}
                    direction={LINK_DIRECTION.TO}
                />
            </Html>
        </>
    );
};
