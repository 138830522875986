import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    debounce,
    Grid,
    IconButton,
    Input,
    List,
    Stack,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { ProductTypeKeys } from "src/api/tms-projects/keys";
import {
    mutationCreateProductType,
    useSelectListProductTypes,
} from "src/api/tms-projects/productTypes";
import {
    Type_index_productType,
    Type_prj_select_productType,
} from "src/api/tms-projects/productTypes/types";
import { ProductTypeTaskKeys } from "src/api/tms-scheduling/keys";
import {
    mutationCreateProductTypeTask,
    mutationDeleteProductTypeTask,
    mutationUpdateProductTypeTask,
    useIndexProductTypeTask,
} from "src/api/tms-scheduling/taskProductTypes";
import {
    formatterPostProductTypeTaskToIndex,
    sortProductByName,
} from "src/api/tms-scheduling/taskProductTypes/formatter";
import { Type_index_productTypeTasks } from "src/api/tms-scheduling/taskProductTypes/types";
import { Empty, TMC_Button } from "src/components";
import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { AutocompleteProductTypes } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteProductTypes";
import { DEBOUNCE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { HeaderToolbar } from "src/layouts/Layout_ContextualDrawer/components/HeaderToolbar";
import {
    Content,
    Form,
    Header,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export type Type_modalDeleteProductType = {
    isOpen: boolean;
    id?: number;
    props?: Type_index_productTypeTasks;
};

const productValidationSchema = Yup.object().shape({
    productTypes: Yup.array(),
});

export type Type_createNewProduct = {
    names: { [key: string]: string };
    unit_id: number;
};

type Type_Props_TaskForm = {
    onClose: () => void;
    id: number;
    focus: boolean;
};

export const ProductTypesTaskForm = ({
    onClose,
    id: taskId,
    focus,
}: Type_Props_TaskForm) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { sendEvent } = useChannel({});

    // i18n
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerProductTypes",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtDrawer } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.ProductType",
    );

    const [productsTypes, setProductsTypes] = useState<
        Type_index_productTypeTasks[]
    >([]);

    const [productTypesList, setProductTypesList] = useState<
        Type_prj_select_productType[]
    >([]);

    const [modalDeleteProduct, setModalDeleteProduct] =
        useState<Type_modalDeleteProductType>({
            isOpen: false,
        });

    const { data: productTypesListData, isFetching: isFetchingProductTypes } =
        useSelectListProductTypes(true, "list") || {};

    const { mutateAsync: deleteProductTypeTask, isLoading: isDeletingProduct } =
        mutationDeleteProductTypeTask(taskId);

    const { data, isFetching: isFetchingProductTypesTask } =
        useIndexProductTypeTask(taskId, "form");

    const { mutateAsync: updateProductTypesTasks } =
        mutationUpdateProductTypeTask(taskId);

    useEffect(() => {
        if (data) {
            setProductsTypes(data);
        }
    }, [data]);

    useEffect(() => {
        if (productTypesListData) {
            setProductTypesList(productTypesListData);
        }
    }, [productTypesListData]);

    const {
        mutateAsync: createProductTypesTask,
        isLoading: isCreatingProductTypes,
    } = mutationCreateProductTypeTask(taskId);

    // React hook form
    const form = useForm<any>({
        defaultValues: {
            productTypes: [],
        },
        mode: "onSubmit",
        resolver: yupResolver<any>(productValidationSchema),
    });

    const selectedProductTypes = useWatch({
        control: form.control,
        name: "productTypes",
    });

    const addNewProductTypesToTask = async () => {
        for (const product of selectedProductTypes) {
            const { data } = await createProductTypesTask({
                productType_id: product.id,
                task_id: taskId,
                quantity: 0,
            });

            setProductsTypes((_prev) =>
                sortProductByName([
                    ..._prev,
                    formatterPostProductTypeTaskToIndex(data.data),
                ]),
            );
        }
        form.reset({ productTypes: [] });
    };

    const isAddButtonDisabled = useMemo(
        () => isCreatingProductTypes || !selectedProductTypes?.length,
        [isCreatingProductTypes, selectedProductTypes],
    );

    const disabledOptions = useMemo(
        () => productsTypes.map((product) => product.productType.id),
        [productsTypes],
    );

    const deleteProductCallback = () => {
        setProductsTypes((prev) => {
            return prev.filter(
                (item) => item.id !== modalDeleteProduct?.props?.id,
            );
        });
        setModalDeleteProduct({ isOpen: false });
    };

    useEffect(() => {
        if (!isFetchingProductTypesTask) {
            sendEvent("updateProductTypesTasks", productsTypes);
        }
    }, [productsTypes]);

    const handleQuantityChange = useCallback(
        debounce(
            async ({
                event,
                productTypeTaskId,
            }: {
                event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                >;
                productTypeTaskId: number;
            }) => {
                const newQuantity = +event.target.value;
                await updateProductTypesTasks({
                    id: productTypeTaskId,
                    quantity: newQuantity,
                });
                setProductsTypes((prevProducts) => {
                    return prevProducts.map((product) => {
                        if (product.id === productTypeTaskId) {
                            return {
                                ...product,
                                quantity: newQuantity,
                            };
                        } else {
                            return product;
                        }
                    });
                });
            },
            DEBOUNCE_TIME,
        ),
        [],
    );

    const handleSelectChange = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        productId: number,
    ) => {
        const newProductId = +event.target.value;
        await updateProductTypesTasks({
            id: productId,
            productType_id: newProductId,
        });
        setProductsTypes((prevProducts) => {
            const products = prevProducts.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        productType: productTypesList.find(
                            (item) => item.id === newProductId,
                        ) as Type_prj_select_productType,
                    };
                } else {
                    return product;
                }
            });
            return sortProductByName(products);
        });
    };

    const productsListOptions = (): {
        label: string;
        value: number;
        disabled: boolean;
    }[] =>
        productTypesList.map((product) => {
            return {
                label: product.name,
                value: product.id,
                disabled: productsTypes
                    .flatMap((obj) => obj.productType.id)
                    .includes(product.id),
            };
        });

    const { mutateAsync: createProductType } = mutationCreateProductType(
        async (product: Type_index_productType) => {
            await createProductTypesTask({
                productType_id: product.id,
                task_id: taskId,
                quantity: 0,
            }).then(() => {
                Promise.all([
                    queryClient.invalidateQueries([
                        ProductTypeKeys.SELECT_LIST,
                        requestConfig,
                    ]),
                    queryClient.invalidateQueries([
                        ProductTypeTaskKeys.INDEX,
                        { ...requestConfig, taskId },
                    ]),
                ]);
            });
        },
    );

    return (
        <FormProvider {...form}>
            <Form>
                <Header>
                    <HeaderToolbar onClose={onClose} />
                    <Typography>{fmt("Title")}</Typography>
                </Header>
                <Stack
                    spacing={2}
                    mb={8}
                    direction="row"
                    alignItems={"end"}
                    padding={4}
                >
                    <AutocompleteProductTypes
                        autoFocus={focus}
                        name="productTypes"
                        disabledOptions={disabledOptions}
                        createNewProduct={createProductType}
                        multiple={true}
                        renderInputProps={{
                            placeholder: fmt("ProductTypesPlaceholder"),
                            variant: "outlined",
                        }}
                    />

                    <TMC_Button
                        data-testid={`add-new-product-btn`}
                        variant="contained"
                        onClick={addNewProductTypesToTask}
                        disabled={isAddButtonDisabled}
                    >
                        {fmtActions("Add")}
                    </TMC_Button>
                </Stack>
                <Content padding={4}>
                    {isFetchingProductTypesTask || isFetchingProductTypes ? (
                        <FullSpinner />
                    ) : productsTypes.length > 0 ? (
                        <List>
                            {productsTypes.map((product) => (
                                <Box key={`product_${product.id}`}>
                                    <Grid
                                        container
                                        p={2}
                                        alignItems={"center"}
                                        flexGrow={1}
                                        spacing={2}
                                    >
                                        <Grid item flexGrow={1} xs={6}>
                                            {productsListOptions().every(
                                                (e) => e.disabled,
                                            ) ? (
                                                <Typography>
                                                    {product.productType.name}
                                                </Typography>
                                            ) : (
                                                <Select
                                                    name={`product_${product.id}`}
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            product.id,
                                                        )
                                                    }
                                                    value={
                                                        product.productType.id
                                                    }
                                                    variant="standard"
                                                    sx={{
                                                        ".MuiInputBase-root::before, .MuiInputBase-root:hover::before":
                                                            {
                                                                borderBottom:
                                                                    "none",
                                                            },
                                                        "& .MuiInput-input": {
                                                            textWrap: "wrap",
                                                        },
                                                    }}
                                                    options={productsListOptions()}
                                                />
                                            )}
                                        </Grid>

                                        <Grid item flexGrow={1} xs={6}>
                                            <Stack
                                                spacing={1}
                                                direction="row"
                                                // justifyContent={"end"}
                                                alignItems={"baseline"}
                                            >
                                                <Input
                                                    sx={{
                                                        width: "25%",
                                                        "::before": {
                                                            borderBottom:
                                                                "none",
                                                        },
                                                    }}
                                                    onChange={(e) =>
                                                        handleQuantityChange({
                                                            event: e,
                                                            productTypeTaskId:
                                                                product.id,
                                                        })
                                                    }
                                                    defaultValue={
                                                        product.quantity
                                                    }
                                                    name={`product_${product.id}_quantity`}
                                                    type="number"
                                                />
                                                <Typography
                                                    sx={{
                                                        width: "50%",
                                                    }}
                                                >
                                                    {
                                                        product.productType.unit
                                                            .name
                                                    }
                                                </Typography>
                                                <IconButton
                                                    sx={{
                                                        width: "25%",
                                                    }}
                                                    data-testid="remove-product-task-btn"
                                                    size="small"
                                                    onClick={() => {
                                                        setModalDeleteProduct({
                                                            isOpen: true,
                                                            id: product.id,
                                                            props: product,
                                                        });
                                                    }}
                                                >
                                                    <Icon
                                                        icon="minus"
                                                        variant="light"
                                                    />
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </List>
                    ) : (
                        <Empty
                            message={fmtDrawer("EmptyProductTypeMessage")}
                            dataTestIdRef={"ProductTypesTaskForm"}
                        />
                    )}
                </Content>
            </Form>
            <ModalDelete
                open={modalDeleteProduct.isOpen}
                onClose={() => setModalDeleteProduct({ isOpen: false })}
                item={fmt("DataName")}
                validationString={
                    modalDeleteProduct?.props?.productType.name as string
                }
                actions={{
                    onSubmit: {
                        isLoading: isDeletingProduct,
                        onClick: () => {
                            deleteProductTypeTask(
                                modalDeleteProduct?.props?.id as number,
                            ).then(deleteProductCallback);
                        },
                    },
                }}
            />
        </FormProvider>
    );
};
