import { useMutation, useQuery, useQueryClient } from "react-query";

import { SequenceAreaKeys } from "src/api/tms-scheduling/keys";
import {
    createSequenceArea,
    deleteSequenceArea,
    indexSequenceAreas,
    updateSequenceArea,
} from "src/api/tms-scheduling/sequenceAreas/services";
import {
    Type_index_sequenceArea,
    Type_sch_index_sequenceArea,
    Type_sch_index_sequenceArea_forMatrix,
    Type_sch_post_sequenceArea,
    Type_sch_put_sequenceArea,
    Type_sch_put_sequenceArea_forMatrix,
} from "src/api/tms-scheduling/sequenceAreas/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterIndexSequenceArea,
    formatterIndexSequenceAreas,
    formatterIndexSequenceAreasForMatrix,
} from "./formatter";

export const useIndexSequenceAreas = (
    sequenceId: number,
    enabled?: boolean,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        enabled: enabled,
        queryKey: [SequenceAreaKeys.INDEX, requestConfig, sequenceId],
        queryFn: () => indexSequenceAreas({ sequenceId, ...requestConfig }),
        refetchOnWindowFocus: false,
        select: (data) => {
            const SequenceAreas: Type_sch_index_sequenceArea[] =
                data?.data?.data;
            return formatterIndexSequenceAreas(SequenceAreas);
        },
    });
};

export const mutationCreateSequenceArea = (
    callback?: (sequenceArea: Type_index_sequenceArea) => void,
) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (newSequenceArea: Type_sch_post_sequenceArea) =>
            createSequenceArea(newSequenceArea, requestConfig),
        onSuccess: (data: any) => {
            if (data.success) {
                const sequenceArea: Type_index_sequenceArea =
                    formatterIndexSequenceArea(data.data.data);
                callback?.(sequenceArea);
            }
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useIndexSequenceAreasForMatrix = (
    sequenceId: number,
    enabled: boolean = false,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        enabled: enabled,
        queryKey: [
            SequenceAreaKeys.INDEX_FOR_MATRIX,
            requestConfig,
            sequenceId,
        ],
        queryFn: () =>
            indexSequenceAreas(
                { sequenceId, ...requestConfig },
                {
                    forMatrix: true,
                },
            ),
        refetchOnWindowFocus: false,
        select: (data) => {
            const sequenceAreas = data?.data?.data;
            return formatterIndexSequenceAreasForMatrix(
                sequenceAreas as Type_sch_index_sequenceArea_forMatrix[],
            );
        },
    });
};

//UPDATE MATRIX
export const mutationUpdateSequenceAreasForMatrix = ({
    callback,
    showToast = true,
    shouldInvalidateQueries = true,
    withData = false,
}: {
    callback?: (sequenceArea: Type_index_sequenceArea) => void;
    showToast: boolean;
    shouldInvalidateQueries: boolean;
    withData: boolean;
}) => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: {
            id: number;
            data: Type_sch_put_sequenceArea_forMatrix;
        }) =>
            updateSequenceArea(
                {
                    id: data.id,
                    data: data.data,
                },
                {
                    ...requestConfig,
                    withData: withData,
                },
            ),
        onSuccess: async (data: any) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationUpdateSequenceAreasForMatrix",
                );
            }

            if (shouldInvalidateQueries) {
                await queryClient.invalidateQueries({
                    queryKey: [
                        SequenceAreaKeys.INDEX_FOR_MATRIX,
                        requestConfig,
                    ],
                });
            }

            if (showToast) {
                addSuccess({
                    description: fmt("Toast.ToastSuccessUpdate"),
                });
            }
            if (withData && callback) {
                return callback(formatterIndexSequenceArea(data.data.data));
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateSequenceAreasForMatrix", err);
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateSequenceArea = (
    callback?: (sequenceArea: Type_index_sequenceArea) => void,
) => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (sequenceArea: Type_sch_put_sequenceArea) => {
            const { id, ...data } = sequenceArea;

            return updateSequenceArea(
                {
                    id: id,
                    data,
                },
                requestConfig,
            );
        },
        onSuccess: (data) => {
            if (data.success && callback) {
                const sequenceArea: Type_index_sequenceArea =
                    formatterIndexSequenceArea(data.data.data);
                callback(sequenceArea);
            }
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationDeleteSequenceArea = (callback?: (id: number) => void) => {
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (id: number) => deleteSequenceArea(id, requestConfig),
        onSuccess: (data, id) => {
            if (data.success && callback) {
                callback(id);
            }
        },
        onError: (err: any) => {
            console.error(fmtError("GenericError", {}), err);

            return err;
        },
    });
};
