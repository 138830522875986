import {
    Box,
    LinearProgress,
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    Stack,
} from "@mui/material";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import React, { useEffect, useState } from "react";

import { Type_default_workspace } from "src/api/tms-projects/workspacesAndViews/workspaces/types";
import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { useWorkspaceViewContext } from "src/contexts/workspaces";
import { WorkspaceConfirmModal } from "src/dialogs/WorkspaceConfirmModal";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getDefaultSubprojectConfiguration } from "src/layouts/Layout_Workspace/configurations/workspacesSubproject";
import { Styled_WorkspaceContainer } from "src/layouts/Layout_Workspace/WorkspaceCards/stylesheet";
import { WorkspaceCard } from "src/layouts/Layout_Workspace/WorkspaceCards/WorkspaceCard";

type Type_Props_DialogSelection = DialogProps & {
    isLoading: boolean;
    handleClose: any;
    handleClick: (type: string) => void;
    withConfirmation: boolean;
    progressValue: number;
    withProgress: boolean;
};

export const WorkspaceSelection = ({
    isLoading,
    handleClose,
    handleClick,
    withConfirmation,
    progressValue,
    withProgress,
    ...props
}: Type_Props_DialogSelection) => {
    const { currentWorkspace } = useWorkspaceViewContext();
    const [selected, setSelected] = useState<string>("");
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Workspace.Menu",
    );
    const isDisabled =
        isLoading || currentWorkspace?.type === selected || selected === "";

    const workspaces = Array.from(getDefaultSubprojectConfiguration().values());

    useEffect(() => {
        if (currentWorkspace?.type) {
            setSelected(currentWorkspace.type);
        }
    }, [currentWorkspace]);

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    };
    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    };

    const closeDialog = (reason: string) => {
        if (reason !== "backdropClick") {
            handleClose();
        }
    };

    const handleChangeWorkspace = (type: string) => {
        if (!withConfirmation) {
            handleClick(type);
        } else {
            handleOpenConfirmation();
        }
    };

    return (
        <>
            <MuiDialog
                {...props}
                onClose={(_event, reason) => {
                    closeDialog(reason);
                }}
                disableEscapeKeyDown={!currentWorkspace?.type}
                maxWidth={"lg"}
                fullWidth={true}
            >
                <Stack direction={"row"} justifyContent={"end"}>
                    <IconButton
                        size="small"
                        onClick={handleClose}
                        data-testid={"Workspace-Dialog-Close-btn"}
                        title={fmtActions("Close")}
                        color={"inherit"}
                        disabled={!currentWorkspace?.type}
                    >
                        <Icon variant="light" icon="xmark" />
                    </IconButton>
                </Stack>
                <Stack
                    direction={"row"}
                    paddingX={10}
                    data-testid={"Workspace-Dialog-Title"}
                >
                    <SectionTitle title={fmt("Title.Workspaces")} />
                </Stack>
                <MuiDialogContent sx={{ paddingX: 10, marginTop: 0 }}>
                    <Styled_WorkspaceContainer>
                        {workspaces.map(
                            (
                                workspace: Type_default_workspace,
                                index: number,
                            ) => (
                                <WorkspaceCard
                                    key={`${workspace.type}-${index}`}
                                    workspace={workspace}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            ),
                        )}
                    </Styled_WorkspaceContainer>
                    {withProgress && (
                        <Box mt={5}>
                            <LinearProgress
                                variant="determinate"
                                value={progressValue}
                            />
                        </Box>
                    )}
                </MuiDialogContent>
                <MuiDialogActions>
                    <TMC_Button
                        data-testid={"WorkspaceDialog-Apply-btn"}
                        size="medium"
                        variant="contained"
                        disabled={isLoading || isDisabled || withProgress}
                        startIcon={
                            !isLoading && <Icon variant="solid" icon="check" />
                        }
                        onClick={() => handleChangeWorkspace(selected)}
                    >
                        {fmtActions("Apply")}
                    </TMC_Button>
                </MuiDialogActions>
            </MuiDialog>
            <WorkspaceConfirmModal
                handleClose={handleCloseConfirmation}
                open={openConfirmation}
                handleClick={handleClick}
                selected={selected}
                isLoading={isLoading}
            />
        </>
    );
};
