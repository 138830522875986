import {
    Type_com_index_punchlistItemCause,
    Type_com_post_punchlistItemCause,
    Type_com_put_punchlistItemCause,
    Type_com_selectList_punchlistItemCause,
    Type_com_show_punchlistItemCause,
    Type_index_punchlistItemCause,
    Type_post_punchlistItemCause,
    Type_put_punchlistItemCause,
    Type_selectList_punchlistItemCause,
    Type_show_punchlistItemCause,
} from "src/api/tms-commons/punchlistItemCauses/types";

export const formatterIndexPunchlistItemCauses = (
    punchlistItemCauses: Type_com_index_punchlistItemCause[],
): Type_index_punchlistItemCause[] => {
    if (!punchlistItemCauses) return [];
    return punchlistItemCauses.map(formatterIndexPunchlistItemCause);
};

export const formatterIndexPunchlistItemCause = (
    punchlistItemCause: Type_com_index_punchlistItemCause,
): Type_index_punchlistItemCause => {
    return {
        id: punchlistItemCause.id,
        enabled: punchlistItemCause.enabled,
        name: punchlistItemCause.name,
    };
};

export const formatterPutPunchlistItemCause = (
    punchlistItemCause: Type_put_punchlistItemCause,
): Type_com_put_punchlistItemCause => {
    return {
        names: punchlistItemCause.names,
        enabled: punchlistItemCause.enabled,
    };
};

export const formatterShowPunchlistItemCause = (
    punchlistItemCause: Type_com_show_punchlistItemCause,
): Type_show_punchlistItemCause => {
    return {
        id: punchlistItemCause.id,
        enabled: punchlistItemCause.enabled,
        names: punchlistItemCause.names,
    };
};

export const formatterPostPunchlistItemCause = (
    punchlistItemCause: Type_post_punchlistItemCause,
): Type_com_post_punchlistItemCause => {
    return {
        names: punchlistItemCause.names,
    };
};

export const formatterSelectListPunchlistItemCauses = (
    data: Type_com_selectList_punchlistItemCause[],
): Type_selectList_punchlistItemCause[] => {
    if (!data) return [];

    return data.map((item: Type_com_selectList_punchlistItemCause) => ({
        id: item.id,
        name: item.name,
    }));
};
