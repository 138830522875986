import * as React from "react";

import { TaskAreaNotes } from "src/forms/taskAreaNotes/TaskAreaNotes";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_TaskAreaNotesContextualDrawer = {
    taskId?: number;
    areaId?: number;
    taskAreaId?: number;
};

export const TaskAreaNotesContextualDrawer = ({
    taskId,
    areaId,
    taskAreaId,
}: Type_Props_TaskAreaNotesContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout
            data-testid={`TaskNotes-ContextualDrawer`}
        >
            <TaskAreaNotes
                taskId={taskId}
                areaId={areaId}
                taskAreaId={taskAreaId}
            />
        </Styled_PaperContextualLayout>
    );
};
