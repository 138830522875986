import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
    formatterCreateAreaType,
    formatterIndexAreaType,
    formatterIndexAreaTypes,
    formatterSelectListAreaTypes,
    formatterShowAreaType,
    formatterUpdateAreaType,
} from "src/api/tms-commons/areaTypes/formatters";
import {
    createAreaType,
    deleteAreaType,
    indexAreaTypes,
    selectListAreaTypes,
    showAreaType,
    statusAreaType,
    updateAreaType,
} from "src/api/tms-commons/areaTypes/services";
import {
    Type_com_put_areaType,
    Type_post_areaType,
    Type_show_areaType,
} from "src/api/tms-commons/areaTypes/types";
import { AreaTypeKeys } from "src/api/tms-commons/keys";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexAreaTypes = () => {
    return useQuery({
        queryKey: [AreaTypeKeys.INDEX],
        queryFn: indexAreaTypes,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexAreaTypes");
            }
            return formatterIndexAreaTypes(data.data.data);
        },
    });
};

export const useShowAreaType = (id: number) => {
    return useQuery({
        queryKey: [AreaTypeKeys.SHOW, id],
        queryFn: () => showAreaType(id),
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowAreaType");
            }
            return formatterShowAreaType(data.data.data);
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationCreateAreaType = () => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.AreaTypes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newAreaType: Type_post_areaType) =>
            createAreaType(formatterCreateAreaType(newAreaType)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateAreaType");
            }

            const formattedData = formatterIndexAreaType(data.data.data);

            await queryClient.invalidateQueries([AreaTypeKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        areaType: formattedData.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateAreaType = () => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.AreaTypes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (areaTypeToUpdate: Type_show_areaType) =>
            updateAreaType(formatterUpdateAreaType(areaTypeToUpdate)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateAreaType");
            }

            const areaType = formatterIndexAreaType(data.data.data);

            await queryClient.invalidateQueries([AreaTypeKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        areaType: areaType.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusAreaType = () => {
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: Type_com_put_areaType) => {
            return statusAreaType(newStatus);
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusAreaType");
            }

            await queryClient.invalidateQueries([AreaTypeKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteAreaType = () => {
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.AreaTypes");

    return useMutation({
        mutationFn: (id: number) => {
            return deleteAreaType(id);
        },
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteAreaType");
            }
            await queryClient.invalidateQueries([AreaTypeKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useSelectListAreaTypes = () => {
    return useQuery({
        queryKey: [AreaTypeKeys.SELECT_LIST],
        queryFn: selectListAreaTypes,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListAreaTypes");
            }
            return formatterSelectListAreaTypes(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};
