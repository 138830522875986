import { Box, Stack, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { Formik } from "formik";
import React, { ReactElement, useState } from "react";
import * as Yup from "yup";

import {
    Type_index_version_note,
    Type_prj_put_version_note,
} from "src/api/tms-projects/notes/types";
import {
    Avatar,
    Header,
    LoadingBox,
    Spinner,
    TMC_Button,
} from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import {
    Styled_FormDrawer,
    Styled_MainStack,
    Styled_StackActionDrawer,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Editor } from "src/components/Components_Common/richText/Editor";
import { EditorBox } from "src/components/Components_Common/richText/Editor.style";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";
import { displayFormattedDateTime, getDate } from "src/utils/date";

export type Type_Version_Notes_DrawerContentComponent_Props = {
    isFetching: boolean;
    onClose: () => void;
    onSubmit: (values: any, callback?: any) => void;
    handleDelete: (id: number) => void;
    onUpdate: (values: Type_prj_put_version_note) => void;
    isLoading?: boolean;
    notes: Type_index_version_note[];
    versionId: number;
};

export const Version_Notes_DrawerContentComponent = ({
    isFetching,
    isLoading,
    onClose,
    onSubmit,
    handleDelete,
    onUpdate,
    notes,
    versionId,
}: Type_Version_Notes_DrawerContentComponent_Props) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Versions.Drawer",
    );

    const { getFullName, hasPermission } = useUser();

    const CAN_CREATE_NOTE = hasPermission("versionNotes_create");
    const CAN_SHOW_NOTES = hasPermission("versionNotes_index");

    const sortedNotes = notes.sort((a, b) => {
        if (!b.date) return 1;
        if (!a.date) return -1;
        return b.date.diff(a.date);
    });

    const [selectedNoteId, setSelectedNoteId] = useState<number | null>(null);
    const [selectedNoteText, setSelectedNoteText] = useState<string>("");
    const [showCreateNote, setShowCreateNote] = useState(false);

    const enableEditNote = (id: number) => {
        if (id == selectedNoteId) {
            setSelectedNoteId(null);
        } else {
            setSelectedNoteId(id);
            setShowCreateNote(false);
        }
    };

    const handleSaveNote = () => {
        if (selectedNoteId && selectedNoteText) {
            if (
                selectedNoteText ==
                notes.find((s) => s.id == selectedNoteId)?.text
            ) {
                setSelectedNoteId(null);
                setSelectedNoteText("");
                return;
            }
            onUpdate({
                id: selectedNoteId,
                version_id: versionId,
                text: selectedNoteText,
                enabled: true,
            });
        }
    };

    const VersionSchema = Yup.lazy(() => {
        return Yup.object().shape({
            text: Yup.string(),
            date: Yup.date(),
        });
    });

    return (
        <>
            {isFetching && <LoadingBox />}
            {!isFetching && (
                <Styled_MainStack>
                    <Header
                        onClose={onClose}
                        title={fmt("Notes.Title")}
                        dataTestIdTitle={"VersionNote-Drawer-Create-Title"}
                    />
                    <Formik
                        initialValues={{
                            text: "",
                        }}
                        onSubmit={(values) => {
                            return onSubmit(values, () =>
                                setShowCreateNote(false),
                            );
                        }}
                        validationSchema={VersionSchema}
                        validateOnChange={false}
                    >
                        {({
                            errors: _errors,
                            handleChange: _handleChange,
                            values: _values,
                            setFieldValue,
                        }) => {
                            return (
                                <Styled_FormDrawer>
                                    <>
                                        <Styled_StackContentDrawer>
                                            {CAN_CREATE_NOTE && (
                                                <>
                                                    {showCreateNote && (
                                                        <Box
                                                            sx={{
                                                                p: 3,
                                                            }}
                                                        >
                                                            <Editor
                                                                name={"new"}
                                                                buttonBold
                                                                buttonItalic
                                                                buttonUnderline
                                                                buttonEditLink
                                                                buttonOrderedList
                                                                buttonBulletedList
                                                                buttonHistory
                                                                footer={
                                                                    <EditorFooter
                                                                        fullName={getFullName()}
                                                                        createdAt={getDate()}
                                                                    />
                                                                }
                                                                onChange={(
                                                                    content,
                                                                ) => {
                                                                    setFieldValue(
                                                                        "text",
                                                                        content,
                                                                    );
                                                                }}
                                                                hideRichText={() => {
                                                                    setShowCreateNote(
                                                                        false,
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    )}

                                                    {!showCreateNote && (
                                                        <Stack
                                                            direction={"row"}
                                                            justifyContent={
                                                                "end"
                                                            }
                                                        >
                                                            <TMC_Button
                                                                data-testid="Version-Notes-New"
                                                                type="submit"
                                                                sx={{
                                                                    color: COLORS.electricBlue,
                                                                }}
                                                                variant="text"
                                                                onClick={() => {
                                                                    setSelectedNoteId(
                                                                        null,
                                                                    );
                                                                    setShowCreateNote(
                                                                        true,
                                                                    );
                                                                }}
                                                                startIcon={
                                                                    <Icon
                                                                        variant="solid"
                                                                        icon="plus"
                                                                    />
                                                                }
                                                            >
                                                                {fmt(
                                                                    `Notes.NewNote`,
                                                                )}
                                                            </TMC_Button>
                                                        </Stack>
                                                    )}
                                                </>
                                            )}
                                            {CAN_SHOW_NOTES &&
                                                sortedNotes.map((note) => (
                                                    <Stack
                                                        key={note.id}
                                                        sx={{
                                                            my: 3,
                                                            px: 3,
                                                        }}
                                                    >
                                                        <EditorBox>
                                                            <Editor
                                                                name={note.id.toString()}
                                                                content={note}
                                                                key={note.id}
                                                                buttonBold
                                                                buttonItalic
                                                                buttonUnderline
                                                                buttonEditLink
                                                                buttonOrderedList
                                                                buttonBulletedList
                                                                buttonHistory
                                                                editable={
                                                                    selectedNoteId ==
                                                                    note.id
                                                                }
                                                                footer={
                                                                    <EditorFooter
                                                                        key={
                                                                            note.id
                                                                        }
                                                                        note={
                                                                            note
                                                                        }
                                                                        onDelete={
                                                                            handleDelete
                                                                        }
                                                                        onEdit={
                                                                            enableEditNote
                                                                        }
                                                                        fullName={
                                                                            note
                                                                                .author
                                                                                .name
                                                                        }
                                                                        createdAt={
                                                                            note?.date
                                                                        }
                                                                        isSelected={
                                                                            note.id ===
                                                                            selectedNoteId
                                                                        }
                                                                        isLoading={
                                                                            isLoading
                                                                        }
                                                                        onSave={
                                                                            handleSaveNote
                                                                        }
                                                                    />
                                                                }
                                                                onChange={(
                                                                    content,
                                                                ) =>
                                                                    setSelectedNoteText(
                                                                        content,
                                                                    )
                                                                }
                                                            />
                                                        </EditorBox>
                                                    </Stack>
                                                ))}
                                        </Styled_StackContentDrawer>

                                        <Styled_StackActionDrawer>
                                            {CAN_CREATE_NOTE && (
                                                <TMC_Button
                                                    data-testid="Versions-Drawer-Create-Note"
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={
                                                        !showCreateNote ||
                                                        isLoading
                                                    }
                                                    sx={{ minWidth: 200 }}
                                                    endIcon={
                                                        !isLoading && (
                                                            <Icon
                                                                variant="solid"
                                                                icon="check"
                                                            />
                                                        )
                                                    }
                                                >
                                                    {isLoading ? (
                                                        <Spinner />
                                                    ) : (
                                                        fmt(`CtaSave`)
                                                    )}
                                                </TMC_Button>
                                            )}
                                        </Styled_StackActionDrawer>
                                    </>
                                </Styled_FormDrawer>
                            );
                        }}
                    </Formik>
                </Styled_MainStack>
            )}
        </>
    );
};

const EditorFooter = ({
    fullName,
    createdAt,
    note,
    isSelected = false,
    isLoading = false,
    onDelete = () => {},
    onEdit = () => {},
    onSave = () => {},
}: {
    fullName: string;
    createdAt: Dayjs | null;
    isSelected?: boolean;
    note?: Type_index_version_note;
    onDelete?: (id: number) => void;
    onEdit?: (id: number) => void;
    onSave?: () => void;
    isLoading?: boolean;
}): ReactElement => {
    const { hasPermission } = useUser();
    const CAN_DELETE_NOTES = hasPermission("versionNotes_delete");
    const CAN_EDIT_NOTES = hasPermission("versionNotes_update");

    return (
        <Stack mx={3} my={4} direction="row" justifyContent={"space-between"}>
            <Stack alignItems={"center"} spacing={2} direction="row">
                <Avatar
                    name={fullName}
                    sx={{
                        width: 32,
                        height: 32,
                    }}
                />

                <Typography variant="body3Bold">{fullName}</Typography>
                <Typography variant="body3">
                    · {displayFormattedDateTime(createdAt)}
                </Typography>
            </Stack>
            {note && (
                <Stack alignItems={"center"} spacing={2} direction="row">
                    {isLoading && isSelected ? (
                        <Spinner style="button" size="small" />
                    ) : (
                        <>
                            {CAN_EDIT_NOTES && !isSelected && (
                                <IconButton
                                    data-testid={`Version_Edit_Note_${note.id}`}
                                    onClick={() => onEdit(note.id)}
                                >
                                    <Icon
                                        icon="pen-to-square"
                                        variant="light"
                                        fontSize="small"
                                    />
                                </IconButton>
                            )}

                            {CAN_EDIT_NOTES && isSelected && (
                                <IconButton
                                    data-testid={`Version_Save_Note_${note.id}`}
                                    onClick={() => onSave()}
                                >
                                    <Icon
                                        icon="check"
                                        variant="solid"
                                        fontSize="small"
                                    />
                                </IconButton>
                            )}
                            {CAN_DELETE_NOTES && (
                                <IconButton
                                    onClick={() => onDelete(note.id)}
                                    data-testid={`Version_Delete_Note_${note.id}`}
                                >
                                    <Icon
                                        icon="trash"
                                        variant="light"
                                        fontSize="small"
                                    />
                                </IconButton>
                            )}
                        </>
                    )}
                </Stack>
            )}
        </Stack>
    );
};
