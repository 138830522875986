import Konva from "konva";
import React, { RefObject, useRef } from "react";
import { Group, Rect } from "react-konva";

import { Type_point } from "src/components/Components_Common/canvas/types";
import {
    durationPlanningScrollCursor,
    sizePlanningScroll,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { usePlanningContext } from "src/contexts/planning";

import { Type_Props_PlanningScroll } from "./PlanningScroll.type";
import { PlanningScrollCursor } from "./PlanningScrollCursor";

export const PlanningScrollX = ({
    changePos,
    x,
    y,
    width,
    page,
    size,
    pos,
    variant = "normal",
}: Type_Props_PlanningScroll) => {
    const theme = usePlanningTheme();
    const { defaultCursor } = usePlanningContext();
    const wc: number = size ? (width * page) / size : 0;
    const xc: number = size ? (width * pos) / size : 0;

    const refCursor: RefObject<Konva.Shape> = useRef<Konva.Shape>(null);
    const refScroll: RefObject<Konva.Group> = useRef<Konva.Group>(null);

    const move = (x: number): void => {
        const newPos: number = Math.round(width ? (x / width) * size : 0);
        if (newPos != pos) {
            changePos(newPos);
        }
    };

    const withScroll: boolean = width > 1 && page < size;

    return (
        <Group ref={refScroll} x={x} y={y}>
            {withScroll && (
                <Rect
                    x={0}
                    y={0}
                    width={width}
                    height={sizePlanningScroll}
                    fill={theme.colorPlanningScroll}
                    onMouseOver={(e: Konva.KonvaEventObject<MouseEvent>) => {
                        changeCursor(e, "default");
                    }}
                    onMouseOut={(e: Konva.KonvaEventObject<MouseEvent>) => {
                        changeCursor(e, defaultCursor);
                    }}
                    onClick={(e: Konva.KonvaEventObject<MouseEvent>): void => {
                        e.cancelBubble = true;
                        if (refCursor.current) {
                            const mousePos: Type_point =
                                e.target.getRelativePointerPosition() as Type_point;
                            const newX: number = mousePos.x - wc / 2;
                            const x: number = Math.min(
                                width - wc,
                                Math.max(0, newX),
                            );
                            const rect: Konva.Shape = refCursor.current;
                            move(x);
                            rect.to({
                                x: x,
                                fill: theme.colorPlanningScrollCursorOver,
                                duration: durationPlanningScrollCursor,
                            });
                        }
                    }}
                />
            )}
            {withScroll && (
                <PlanningScrollCursor
                    refCursor={refCursor}
                    onDragMove={(
                        e: Konva.KonvaEventObject<DragEvent>,
                    ): void => {
                        e.cancelBubble = true;
                        const x: number = Math.min(
                            width - wc,
                            Math.max(0, e.target.x()),
                        );
                        move(x);
                        e.target.x(x);
                        e.target.y(0);
                    }}
                    x={xc}
                    y={0}
                    width={wc}
                    variant={variant}
                />
            )}
        </Group>
    );
};
