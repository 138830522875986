import React, {
    createContext,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import {
    generatePath,
    matchPath,
    useLocation,
    useParams,
} from "react-router-dom";

import { useUser } from "src/contexts/user";
import {
    Type_sidebar_category,
    Type_sidebar_configuration,
    Type_sidebar_subCategory,
} from "src/layouts/Layout_Sidebar/types";
import { URL_BASE } from "src/router";
import { redirectToIdentification } from "src/utils/urlFormatter";

type Type_contextLayoutSidebar = {
    title: string;
    configuration: Type_sidebar_configuration | undefined;
    currentCategory: Type_sidebar_category | undefined;
    currentSubCategoryUrlName: string | undefined;
    subCategories: Type_sidebar_subCategory[] | undefined | null;
    setTitle: (newTitle: string) => void;
    isCategoryActive: (
        configuration: Type_sidebar_configuration,
        category: Type_sidebar_category,
    ) => boolean;
};

const initialLayoutSidebarState: Type_contextLayoutSidebar = {
    title: "",
    configuration: undefined,
    currentCategory: undefined,
    subCategories: undefined, // SubCategories filtered by user permissions
    currentSubCategoryUrlName: undefined,
    setTitle: () => {},
    isCategoryActive: () => {
        return false;
    },
};

type Type_Props_LayoutSidebarProvider = {
    children: ReactElement;
    configuration: Type_sidebar_configuration;
};

const LayoutSidebarContext = createContext(initialLayoutSidebarState);

export const useLayoutSidebar = () => {
    const context = useContext(LayoutSidebarContext);
    if (!context) {
        throw new Error(
            "useLayoutSidebar must be used within a LayoutSidebarProvider",
        );
    }
    return context;
};

export const LayoutSidebarProvider = ({
    children,
    configuration,
}: Type_Props_LayoutSidebarProvider) => {
    const location = useLocation();
    const { user, hasPermission } = useUser();
    const urlParams = useParams();

    const [contextState, setContextState] = useState<Type_contextLayoutSidebar>(
        {
            ...initialLayoutSidebarState,
            configuration: configuration,
        },
    );

    const setTitle = useCallback((newTitle: string) => {
        setContextState((prev) => ({
            ...prev,
            title: newTitle,
        }));
    }, []);

    const checkSubCategoriesPermissions = useCallback(
        (
            data: Type_sidebar_subCategory[] | null,
        ): Type_sidebar_subCategory[] | null => {
            if (!data) return null;
            const res: Type_sidebar_subCategory[] = [];

            data.map((subCategory: Type_sidebar_subCategory) => {
                if (
                    hasPermission(subCategory.permissionKey) ||
                    subCategory.permissionKey.length === 0
                ) {
                    res.push({
                        urlName: subCategory.urlName,
                        permissionKey: subCategory.permissionKey,
                        nameLabel: subCategory.nameLabel,
                    });
                }
            });

            return res;
        },
        [hasPermission],
    );

    const isCategoryActive = useCallback(
        (
            configuration: Type_sidebar_configuration,
            category: Type_sidebar_category,
        ) => {
            const path = `${URL_BASE}${configuration.baseUrl}/${category.urlName}`;
            const match = category.subCategories
                ? matchPath(
                      {
                          path: `${path}/:tab`,
                      },
                      location.pathname,
                  )
                : matchPath(path, location.pathname);

            return !!match;
        },
        [location.pathname],
    );

    /**
     * - check permission on category
     * - update currentCategory context
     * - check permissions on subCategories
     * - update subCategories list
     */
    const updateCurrentCategory = (
        configuration: Type_sidebar_configuration,
        category: Type_sidebar_category,
    ) => {
        const contextUpdate: Pick<
            Type_contextLayoutSidebar,
            | "title"
            | "configuration"
            | "currentCategory"
            | "subCategories"
            | "currentSubCategoryUrlName"
        > = {
            title: category.nameLabel,
            configuration,
            currentCategory: category as Type_sidebar_category,
            subCategories: undefined,
            currentSubCategoryUrlName: undefined,
        };
        if (category?.subCategories) {
            // Check if we are on subproject settings page
            const basePath = urlParams.subProjectId
                ? `${URL_BASE}${configuration?.baseUrl}/subProject/:subProjectId/${category?.urlName}`
                : `${URL_BASE}${configuration?.baseUrl}/${category?.urlName}`;

            // Get current SubCategory urlName
            const matchSubCat = matchPath(
                {
                    path: `${basePath}/:tab`,
                },
                location.pathname,
            );

            contextUpdate.currentSubCategoryUrlName = matchSubCat
                ? matchSubCat.params.tab // use name from url
                : category?.subCategories?.[0]?.urlName ?? undefined; // get first sub cat by default

            // Update subcategories list based on user's permissions
            contextUpdate.subCategories = checkSubCategoriesPermissions(
                category?.subCategories as Type_sidebar_subCategory[],
            );
        }

        // update current state with found category
        setContextState((prev) => ({
            ...prev,
            ...contextUpdate,
        }));
    };

    // Find which is the current category based on current location path
    useEffect(() => {
        configuration.blocks?.find(function (block) {
            return block.categories.find((category: Type_sidebar_category) => {
                const path = generatePath(
                    `${URL_BASE}${configuration.baseUrl}`,
                    {
                        ...urlParams,
                    },
                );

                // Check if we are on subproject settings page
                const basePath = urlParams.subProjectId
                    ? `${path}/subProject/:subProjectId/${category.urlName}`
                    : `${path}/${category.urlName}`;

                const match = category?.subCategories
                    ? matchPath(`${basePath}/:tab`, location.pathname)
                    : matchPath(basePath, location.pathname);

                if (match) {
                    if (user) {
                        // Here we found the current category
                        if (
                            hasPermission(category.permissionKeys) ||
                            category.permissionKeys.length === 0
                        ) {
                            updateCurrentCategory(configuration, category);
                            return true;
                        }

                        console.error(
                            "The user does not have permission to access the page.",
                        );
                        redirectToIdentification();
                        return true;
                    }
                }
            });
        });
    }, [location.pathname, configuration]);

    return (
        <LayoutSidebarContext.Provider
            value={{
                ...contextState,
                setTitle,
                isCategoryActive,
            }}
        >
            {children}
        </LayoutSidebarContext.Provider>
    );
};
