export enum Enum_typeZoom {
    x = 1,
    y = 2,
}

export type Type_PlanningZoomButton = {
    pictogram?: string;
    text?: string;
    width?: number;
    size: number;
};

export type Type_PlanningZoom = {
    pos: number;
    min: number;
    max: number;
    x: number;
    y: number;
    width: number;
};

export type Type_Callback_PlanningZoomChange = (
    pos: number,
    who?: Enum_typeZoom,
) => void;

export type Type_Props_PlanningZoom = Type_PlanningZoom & {
    changeZoom: Type_Callback_PlanningZoomChange;
};

export type Type_Props_PlanningZoomButtons = {
    pos: number;
    x: number;
    y: number;
    changeZoom: Type_Callback_PlanningZoomChange;
    buttons: Array<Type_PlanningZoomButton>;
    direction: Enum_typeZoom;
    withBorder?: boolean;
};

export const initialPlanningZoomX: Type_PlanningZoom = {
    pos: 24,
    min: 4,
    max: 44,
    x: 0,
    y: 0,
    width: 0,
};

export const initialPlanningZoomY: Type_PlanningZoom = {
    pos: 32,
    min: 10,
    max: 100,
    x: 0,
    y: 0,
    width: 0,
};

export type Type_Props_PlanningZoomLock = {
    x: number;
    y: number;
    lock: boolean;
    changeLock: () => void;
};

export type Type_Props_PlanningZoomButton = {
    x: number;
    y: number;
    pictogram?: string;
    text?: string;
    width?: number;
    height?: number;
    selected: boolean;
    onChange: () => void;
};
