import { yupResolver } from "@hookform/resolvers/yup";
import { TabContext, TabPanel } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { Type_put_default_subProject } from "src/api/tms-projects/subProjects/types";
import { Type_drawerTab } from "src/components/Components_Common/Drawer/Header/Header.container";
import {
    DrawerTab,
    DrawerTabList,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { DaysCheckboxField } from "src/components/Components_Common/forms/reactHookFormComponents/days/DaysCheckboxField";
import {
    Select,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { useFormDefaultConfig } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const switchSx = {
    justifyContent: "space-between",
    ".MuiTypography-root": {
        paddingLeft: 0,
    },
};

const Schema_DefaultSettings = Yup.lazy(() => {
    return Yup.object().shape({
        optimizedFlow: Yup.boolean(),
        versionLimit: Yup.number(),
        minDuration: Yup.number(),
        timeModel: Yup.number(),
    });
});

type Type_props_DefaultSettingsForm = {
    handleSubmit: (values: Type_put_default_subProject) => void;
    timeModels: Type_SelectOptionItem[] | [];
    step?: "sequence" | "version";
};

export const DefaultSettingsForm = ({
    handleSubmit,
    timeModels,
    step = "sequence",
}: Type_props_DefaultSettingsForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.DefaultSettings",
    );
    const { subProject } = useProject();

    if (!subProject) {
        return <></>;
    }

    const [tab, setTab] = useState<"sequence" | "version">(step);
    const handleChangeTab = (
        event: React.SyntheticEvent,
        newValue: "sequence" | "version",
    ) => {
        setTab(newValue);
    };

    const {
        id,
        optimizedFlow,
        minDuration,
        timeModel,
        versionLimit,
        workingDays,
    } = subProject;

    const form = useForm({
        ...useFormDefaultConfig,
        defaultValues: {
            id,
            optimizedFlow,
            minDuration,
            timeModel: timeModel.id,
            versionLimit,
            workingDays,
        },
        resolver: yupResolver(Schema_DefaultSettings),
    });

    const drawerTabs = [
        { label: fmt("Header.Tabs.Sequence"), value: "sequence" },
        { label: fmt("Header.Tabs.Version"), value: "version" },
    ];

    const minDurations = [
        { value: 0, label: fmt("Labels.MinDuration0") },
        { value: 0.25, label: fmt("Labels.MinDuration0.25") },
        { value: 0.5, label: fmt("Labels.MinDuration0.5") },
        { value: 1, label: fmt("Labels.MinDuration1") },
    ];

    const limitVersions = [
        { value: 0, label: fmt("Labels.VersionLimitNone") },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
    ];

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"defaultSettings"}
            >
                <TabContext value={tab}>
                    <DrawerTabList
                        onChange={handleChangeTab}
                        data-testid="Role-TabList"
                    >
                        {drawerTabs.map(
                            (drawerTab: Type_drawerTab, key: number) => {
                                return (
                                    <DrawerTab
                                        data-testid={`Tab-${drawerTab.value}`}
                                        label={drawerTab.label}
                                        value={drawerTab.value}
                                        key={key}
                                    />
                                );
                            },
                        )}
                    </DrawerTabList>

                    <TabPanel value={"sequence"} sx={{ padding: 0 }}>
                        <Stack spacing={6} mt={4}>
                            <Typography variant="body1Bold">
                                {fmt("Labels.PlanningSettings")}
                            </Typography>
                            <Select
                                label={fmt("Labels.MinDuration")}
                                name={"minDuration"}
                                options={minDurations}
                            />
                            <DaysCheckboxField
                                name="workingDays"
                                dataTestIdPrefix={"DefaultSettings-WorkingDays"}
                            />
                            <Typography variant="body1Bold">
                                {fmt("Labels.TaskSettings")}
                            </Typography>
                            <Stack
                                display={"inline-flex"}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                width={"100%"}
                            >
                                <Typography variant={"body1"}>
                                    {fmt("Labels.OptimizedFlow")}
                                </Typography>
                                <Switch
                                    name={"optimizedFlow"}
                                    inputProps={{
                                        "aria-label": "controlled",
                                    }}
                                    data-testid={"Form-Switch-OptimizedFlow"}
                                    sx={switchSx}
                                />
                            </Stack>

                            <Select
                                name={"timeModel"}
                                data-testid="PlanningSettings-Drawer-timeModel"
                                label={fmt("Labels.TimeModel")}
                                options={timeModels}
                            />
                        </Stack>
                    </TabPanel>
                    <TabPanel value={"version"} sx={{ padding: 0 }}>
                        <Stack spacing={6} mt={4}>
                            <Typography variant="body1Bold">
                                {fmt("Labels.VersionSettings")}
                            </Typography>
                            <Select
                                name={"versionLimit"}
                                data-testid="PlanningSettings-Drawer-versionLimit"
                                label={fmt("Labels.VersionLimit")}
                                defaultValue={subProject.versionLimit}
                                options={limitVersions}
                            />
                        </Stack>
                    </TabPanel>
                </TabContext>
            </form>
        </FormProvider>
    );
};
