import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_CardHeaderCoverProjectDetail = styled(Box)(
    ({ theme }: any) => ({
        position: "relative",
        backgroundSize: "cover",
        width: 270,
        height: 180,
        marginTop: theme.spacing(-30),
        outline: "2px solid #FFF",
        borderRadius: theme.shape.borderRadius,
    }),
);
