import { Components } from "@mui/material";

export const MuiDialogTheme: Components["MuiDialog"] = {
    styleOverrides: {
        paper: ({ theme }: any) => ({
            borderRadius: theme.shape.borderRadius,
            minWidth: 640,
        }),
    },
};
