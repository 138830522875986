import {
    Type_com_index_timeModel,
    Type_com_post_timeModel,
    Type_com_put_timeModel,
    Type_com_select_timeModel,
    Type_com_show_timeModel,
    Type_index_timeModel,
    Type_put_timeModel,
    Type_select_timeModel,
    Type_show_timeModel,
} from "src/api/tms-commons/timeModels/types";

export const formatterIndexTimeModels = (
    trades: Type_com_index_timeModel[],
): Type_index_timeModel[] => {
    return trades.map((timeModel: Type_com_index_timeModel) =>
        formatterIndexTimeModel(timeModel),
    );
};

export const formatterIndexTimeModel = (
    timeModel: Type_com_index_timeModel,
): Type_index_timeModel => {
    return {
        id: timeModel.id,
        name: timeModel.name,
        workingDays: timeModel.workingDays,
        quantity: timeModel.quantity,
        enabled: timeModel.enabled,
    };
};

export const formatterUpdateTimeModel = (
    timeModel: Type_show_timeModel,
): Type_com_put_timeModel => {
    return {
        names: timeModel.names,
        workingDays: timeModel.workingDays,
        quantity: timeModel.quantity,
        enabled: timeModel.enabled,
    };
};

export const formatterCreateTimeModel = (
    timeModel: Type_show_timeModel,
): Type_com_post_timeModel => {
    return {
        names: timeModel.names,
        workingDays: timeModel.workingDays,
        quantity: timeModel.quantity,
    };
};

export const formatterShowTimeModel = (
    timeModel: Type_com_show_timeModel,
): Type_show_timeModel => {
    return {
        id: timeModel.id,
        names: timeModel.names,
        workingDays: timeModel.workingDays,
        quantity: timeModel.quantity,
        enabled: timeModel.enabled,
    };
};

export const formatterSelectListTimeModel = (
    timeModels: Type_com_select_timeModel[],
): Type_select_timeModel[] => {
    if (!timeModels) return [];
    return timeModels.map((timeModel: Type_com_select_timeModel) => ({
        id: timeModel.id,
        name: timeModel.name,
    }));
};

export const formatterStatusTimeModel = (
    causeOfDelay: Type_put_timeModel,
): Type_com_put_timeModel => {
    return {
        enabled: causeOfDelay.enabled,
    };
};
