import { Button } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { DialogFullScreen } from "src/components/Components_Common/Dialog/DialogFullScreen";

export const DialogFullScreenDemo = () => {
    const [toggleDialog, setToggleDialog] = useState<boolean>(false);

    const handleOpenDialog = (): void => {
        setToggleDialog((prev) => !prev);
    };

    return (
        <div>
            <Button
                onClick={handleOpenDialog}
                data-testid={"Button-openFullScreenDialog-A"}
                type="button"
                variant="contained"
            >
                Open fullScreenDialog A
            </Button>
            <DialogFullScreen
                openDialog={toggleDialog}
                setToggleDialog={setToggleDialog}
                title={"Dialog A"}
            ></DialogFullScreen>
        </div>
    );
};
