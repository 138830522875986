import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { TimeModelsPath, Url } from "src/api/paths";
import {
    Type_com_post_timeModel,
    Type_com_put_timeModel,
} from "src/api/tms-commons/timeModels/types";

export const indexTimeModels = (): GetReturnType => {
    return get(`${Url.COMMONS}${TimeModelsPath.TIME_MODELS}`);
};

export const createTimeModel = (
    data: Type_com_post_timeModel,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${TimeModelsPath.TIME_MODELS}`);
};

export const showTimeModel = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${TimeModelsPath.TIME_MODELS}/${id}`);
};

export const updateTimeModel = (
    id: number,
    data: Type_com_put_timeModel,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${TimeModelsPath.TIME_MODELS}/${id}`);
};

export const deleteTimeModel = (id: number): RemoveReturnType => {
    return remove(`${Url.COMMONS}${TimeModelsPath.TIME_MODELS}/${id}`);
};

export const statusTimeModel = (
    id: number,
    data: Type_com_put_timeModel,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${TimeModelsPath.TIME_MODELS}/${id}`);
};

export const selectListTimeModels = (): GetReturnType => {
    return get(`${Url.COMMONS}${TimeModelsPath.SELECT_LIST}`);
};
