import { ToggleButtonProps } from "@mui/material";
import React, { forwardRef } from "react";

import { Styled_ToggleButton } from "./ToggleButton.style";

// Forward the ref to the underlying DOM element
export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
    ({ children, ...props }, ref) => {
        return (
            <Styled_ToggleButton {...props} ref={ref}>
                {children}
            </Styled_ToggleButton>
        );
    },
);

ToggleButton.displayName = "ToggleButton";
