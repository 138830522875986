import { Typography } from "@mui/material";

type Type_StartDateTimeEndDateTimeCellProps = {
    startDate: string | null;
    endDate: string | null;
};

export const StartDateTimeEndDateTimeCell = ({
    startDate,
    endDate,
}: Type_StartDateTimeEndDateTimeCellProps) => {
    return (
        <Typography>
            {startDate === endDate
                ? `${startDate}`
                : `${startDate} → ${endDate}`}
        </Typography>
    );
};
