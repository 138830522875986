import { useMutation, useQuery, useQueryClient } from "react-query";

import { PlatformSettingKeys } from "src/api/tms-commons/keys";
import {
    formatterPutPlatformSetting,
    formatterShowPlatformSetting,
} from "src/api/tms-commons/platformSettings/formatters";
import {
    showPlatformSetting,
    updatePlatformSetting,
} from "src/api/tms-commons/platformSettings/services";
import { Type_show_platformSettings } from "src/api/tms-commons/platformSettings/types";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useShowPlatformSetting = (id: number) => {
    return useQuery(
        [PlatformSettingKeys.SHOW, id],
        () => showPlatformSetting(id),
        {
            onError: (err) => {
                return err;
            },
            select: (data) => {
                if (!data.success || !data?.data?.data) {
                    throw new Error(
                        "Wrong format data: useShowPlatformSetting",
                    );
                }

                return formatterShowPlatformSetting(data.data.data);
            },
            enabled: !!id,
            refetchOnWindowFocus: false,
        },
    );
};

export const mutationUpdatePlatformSetting = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PlatformSettings",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Form.Error");

    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (platformSetting: Type_show_platformSettings) =>
            updatePlatformSetting(
                platformSetting.id,
                formatterPutPlatformSetting(platformSetting),
            ),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdatePlatformSetting",
                );
            }

            const id: number = data.data.data.id;

            await queryClient.invalidateQueries({
                queryKey: [PlatformSettingKeys.SHOW, id],
            });

            addSuccess({
                description: fmt("ToastSuccess"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr(FORM_ERR_FMT.DUPLICATE),
            });
            return err;
        },
    });
};
