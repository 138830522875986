import { post, PostReturnType, Type_RequestConfig } from "src/api/fetch";
import { PrintPath, Url } from "src/api/paths";
import { Type_prt_post_print } from "src/api/tms-print/print/types";

export const downloadPrint = (
    settings: Type_prt_post_print,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(settings, `${Url.PRINT}${PrintPath.PRINT}`, requestConfig, {
        responseType: "arraybuffer",
    });
};
