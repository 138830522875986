import { Stack, SxProps, Theme } from "@mui/material";

import { Avatar } from "src/components/Components_Common";

import {
    Styled_Chip,
    Styled_Stack_MainContainer,
    Styled_TypographyEmail,
    Styled_TypographyName,
} from "./UserSmallCard.style";

type Type_Props_UserSmallCard = {
    id: number;
    canHover?: boolean;
    selected?: boolean;
    badgeLabel?: string;
    actionsChildren?: React.ReactElement;
    firstName?: string;
    lastName?: string;
    email?: string;
    sx?: SxProps<Theme> | undefined;
};

export const UserSmallCard = ({
    id,
    canHover = false,
    firstName = "",
    lastName = "",
    email = "",
    badgeLabel,
    actionsChildren,
    selected,
    sx = {},
}: Type_Props_UserSmallCard) => {
    const name: string = `${firstName} ${lastName}`;

    return (
        <Styled_Stack_MainContainer
            data-testid={`UserSmallCard-${id}`}
            sx={sx}
            ownerState={{ canHover: canHover }}
        >
            <Avatar
                name={name}
                data-testid={`UserSmallCard-Avatar-${id}`}
                sx={{ fontSize: "16px" }}
            />
            <Stack display="flex" alignSelf="center" flexGrow="1">
                <Stack
                    flexDirection="row"
                    flexWrap="nowrap"
                    display="flex"
                    flexGrow="1"
                    gap={1}
                >
                    <Styled_TypographyName
                        variant="body1Medium"
                        ownerState={{ selected }}
                        data-testid={`UserSmallCard-Name-${id}`}
                    >
                        {name}
                        {badgeLabel && (
                            <Styled_Chip
                                label={badgeLabel}
                                data-testid={`UserSmallCard-Chip-${id}`}
                            />
                        )}
                    </Styled_TypographyName>
                </Stack>
                {email && (
                    <Styled_TypographyEmail
                        variant="body3"
                        data-testid={`UserSmallCard-Email-${id}`}
                    >
                        {email}
                    </Styled_TypographyEmail>
                )}
            </Stack>
            {actionsChildren && actionsChildren}
        </Styled_Stack_MainContainer>
    );
};
