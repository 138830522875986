import { Stack, Typography } from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";

import {
    Spinner,
    TMC_Button,
    TMC_TextFieldPassword_Deprecated,
    TMC_Tooltip,
} from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Type_requirements } from "src/pages/Pages_Common/Page_ChangePassword/Page_ChangePassword.container";
import { Styled_Form_ChangePassword } from "src/pages/Pages_Common/Page_ChangePassword/Page_ChangePassword.style";
import { COLORS } from "src/theme/stylesheet";

type Type_ChangePassword_Step_1Props = {
    formik: any;
    requirements: Type_requirements;
    isRequirementsComplete: boolean;
};

export const ChangePassword_Step_1 = ({
    formik,
    requirements,
    isRequirementsComplete,
}: Type_ChangePassword_Step_1Props) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("ChangePassword");

    return (
        <>
            <Typography variant="h1" data-testid="ChangePassword-Title">
                {fmt("Step1.Title")}
            </Typography>
            <Typography variant="body1">{fmt("Step1.Message")}</Typography>
            <FormikProvider value={formik}>
                <Styled_Form_ChangePassword
                    onSubmit={formik.handleSubmit}
                    data-testid="ChangePassword-form"
                >
                    <TMC_TextFieldPassword_Deprecated
                        data-testid="ChangePassword-password"
                        {...formik.getFieldProps("password")}
                        label={fmt(`Step1.Form.Labels.Password`)}
                        error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                        }
                    />
                    <Stack gap={1}>
                        <Typography
                            color={
                                requirements.MinimumLength
                                    ? "success.main"
                                    : "error.main"
                            }
                        >
                            {requirements.MinimumLength ? (
                                <Icon
                                    variant="light"
                                    icon="circle-check"
                                    style={{ paddingRight: "4px" }}
                                />
                            ) : (
                                <Icon
                                    variant="solid"
                                    icon="circle-xmark"
                                    style={{ paddingRight: "4px" }}
                                />
                            )}
                            {fmt(`Step1.Form.Requirements.MinimumLength`)}
                        </Typography>
                        <Typography
                            color={
                                requirements.Alphanumeric
                                    ? "success.main"
                                    : "error.main"
                            }
                        >
                            {requirements.Alphanumeric ? (
                                <Icon
                                    variant="light"
                                    icon="circle-check"
                                    style={{ paddingRight: "4px" }}
                                />
                            ) : (
                                <Icon
                                    variant="solid"
                                    icon="circle-xmark"
                                    style={{ paddingRight: "4px" }}
                                />
                            )}
                            {fmt(`Step1.Form.Requirements.Alphanumeric`)}
                        </Typography>
                        <Typography
                            color={
                                formik.values?.password.length &&
                                requirements.MustBeSame
                                    ? "success.main"
                                    : "error.main"
                            }
                        >
                            {formik.values?.password.length &&
                            requirements.MustBeSame ? (
                                <Icon
                                    variant="light"
                                    icon="circle-check"
                                    style={{ paddingRight: "4px" }}
                                />
                            ) : (
                                <Icon
                                    variant="solid"
                                    icon="circle-xmark"
                                    style={{ paddingRight: "4px" }}
                                />
                            )}
                            {fmt(`Step1.Form.Requirements.MustBeSame`)}
                        </Typography>
                    </Stack>
                    <TMC_TextFieldPassword_Deprecated
                        data-testid="ChangePassword-passwordConfirm"
                        {...formik.getFieldProps("passwordConfirm")}
                        label={fmt(`Step1.Form.Labels.PasswordConfirm`)}
                        error={
                            formik.touched.passwordConfirm &&
                            Boolean(formik.errors.passwordConfirm)
                        }
                    />
                    <TMC_Tooltip title={"TODO"}>
                        <Typography
                            data-testid="ChangePassword-information"
                            variant={"body3Underline"}
                            color={COLORS.blue700}
                        >
                            <Icon
                                variant="light"
                                icon="circle-question"
                                style={{ paddingRight: "4px" }}
                            />
                            {fmt(`Step1.Form.Actions.CtaInformation`)}
                        </Typography>
                    </TMC_Tooltip>
                    <TMC_Button
                        data-testid="ChangePassword-Submit-btn"
                        type="submit"
                        variant="contained"
                        disabled={
                            formik.isSubmitting || !isRequirementsComplete
                        }
                        sx={{ marginTop: "20px" }}
                    >
                        {formik.isSubmitting ? (
                            <Spinner style="button" />
                        ) : (
                            fmt(`Step1.Form.Actions.Submit`)
                        )}
                    </TMC_Button>
                </Styled_Form_ChangePassword>
            </FormikProvider>
        </>
    );
};
