import { Stack, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import React, { ReactElement } from "react";

import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { Avatar } from "src/components/Components_Common/Avatar/Avatar";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { displayFormattedDateTime } from "src/utils/date";

export type Type_permissions = {
    canShow?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
};

type Type_Props_EditorFooter = {
    fullName: string;
    createdAt: Dayjs;
    dataTestIdRef?: string;
    isLoading?: boolean;
    permissions?: Type_permissions;
    formId?: string;
    editable?: boolean;
};

export const EditorFooter = ({
    fullName,
    createdAt,
    isLoading = false,
    permissions,
    formId,
    editable,
    ...restProps
}: Type_Props_EditorFooter): ReactElement => {
    const CAN_EDIT_NOTE = permissions?.canEdit;
    const dataTestIdRef = restProps.dataTestIdRef
        ? restProps.dataTestIdRef
        : `RichText-Editor-Footer`;

    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    return (
        <Stack mt={1} gap={1} data-testid={dataTestIdRef}>
            {CAN_EDIT_NOTE && formId && editable && (
                <Stack direction="row" justifyContent="end">
                    <TMC_Button
                        form={formId}
                        data-testid={`${dataTestIdRef}-Submit`}
                        color="primary"
                        type="submit"
                        size="small"
                        disabled={isLoading}
                        startIcon={
                            !isLoading && (
                                <Icon
                                    variant="solid"
                                    icon="check"
                                    fontSize="small"
                                />
                            )
                        }
                    >
                        {isLoading ? (
                            <Icon variant="solid" icon="spinner-third" spin />
                        ) : (
                            fmtActions("Save")
                        )}
                    </TMC_Button>
                </Stack>
            )}

            <Stack alignItems={"center"} spacing={2} direction="row">
                <Avatar
                    data-testid={`${dataTestIdRef}-Avatar`}
                    name={fullName}
                    sx={{
                        width: 20,
                        height: 20,
                        fontSize: "10px",
                    }}
                />
                <Typography
                    variant="body3Medium"
                    data-testid={`${dataTestIdRef}-FullName`}
                >
                    {fullName}
                </Typography>
                <Typography
                    variant="body3"
                    color="secondary"
                    data-testid={`${dataTestIdRef}-DateCreated`}
                >
                    {displayFormattedDateTime(createdAt)}
                </Typography>
            </Stack>
        </Stack>
    );
};
