import { usePlanningContext } from "src/contexts/planning";

import { DaysOffDialog } from "./DaysOffDialog";
import { KeyDateDialog } from "./KeyDateDialog";

export type Type_DialogParamsRightClick = {
    open: boolean;
    action?: "create" | "update";
    data?: { [key: string]: any } | null;
};

// Here we declare all the dialogs related to planning
export const PlanningDialogsRightClick = () => {
    const { dialogs } = usePlanningContext();

    return (
        <>
            {dialogs.models.daysOff && <DaysOffDialog />}
            {dialogs.models.keyDate && <KeyDateDialog />}
        </>
    );
};
