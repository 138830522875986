import { useState } from "react";

import {
    mutationDeleteResourceModel,
    mutationStatusResourceModel,
    useIndexResourceModels,
} from "src/api/tms-commons/resourceModels";
import { Type_index_resourceModel } from "src/api/tms-commons/resourceModels/types";
import { Page_ResourceModelsComponent } from "src/pages/Pages_Teamoty/Configuration/Page_ResourceModels/Page_ResourceModels.component";

export type Type_modalDeleteResourceModel = {
    isOpen: boolean;
    props?: Type_index_resourceModel;
};

export const Page_ResourceModelsContainer = () => {
    const { isFetching, data } = useIndexResourceModels() || {};

    const [modalDeleteResourceModel, setModalDeleteResourceModel] =
        useState<Type_modalDeleteResourceModel>({
            isOpen: false,
        });

    const { mutateAsync: mutateDeleteResourceModel } =
        mutationDeleteResourceModel() || {};

    const removeResourceModel = async (id: number) => {
        await mutateDeleteResourceModel(id);
    };

    const { mutate: mutationResourceModelStatus } =
        mutationStatusResourceModel() || {};

    const changeStatus = async (
        id: number,
        resourceModel: Type_index_resourceModel,
    ) => {
        mutationResourceModelStatus({
            id,
            enabled: !resourceModel.enabled,
        });
    };

    return (
        <Page_ResourceModelsComponent
            isFetching={isFetching}
            resourceModels={data || []}
            deleteResourceModel={removeResourceModel}
            changeStatus={changeStatus}
            modalDeleteResourceModel={modalDeleteResourceModel}
            setModalDeleteResourceModel={setModalDeleteResourceModel}
        />
    );
};
