import {
    Card,
    CardContent,
    CardMedia,
    CardMediaProps,
    CardProps,
    Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon } from "src/components/Components_Common/Icon/Icon";

export const Styled_ViewCardIcon = styled(Icon)(({ theme }: any) => ({
    color: theme.palette.card.selected,
    top: theme.spacing(2),
    left: theme.spacing(2),
    position: "absolute",
    zIndex: 1,
}));

interface Type_Props_StyledViewCard extends CardProps {
    ownerState?: {
        selected?: boolean;
    };
}

export const Styled_ViewCard = styled(Card)<Type_Props_StyledViewCard>(
    ({ theme, ownerState }: any) => ({
        width: "320px",
        maxHeight: "100px",
        ...(ownerState?.selected && {
            outline: `2px solid ${theme.palette.card.selected}`,
            position: "relative",
        }),
    }),
);

export const Styled_ViewCardMedia = styled(CardMedia)<CardMediaProps>(
    ({ theme }: any) => ({
        width: "100px",
        height: "100px",
        borderRight: theme.border.default,
    }),
);

export const Styled_ViewCardContent = styled(CardContent)(({ theme }: any) => ({
    height: "100%",
    "&.MuiCardContent-root:last-child": { paddingBottom: theme.spacing(4) },
}));

export const Styled_ViewContainer = styled(Stack)(({ theme }: any) => ({
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    gap: theme.spacing(4),
}));
