import { DrawerProps } from "@mui/material";
import React, { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { RolesForm } from "src/forms/roles/RolesForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_RoleDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    roleIdToUpdate: number | null;
    action: Type_action;
    page: string;
};

export const RoleDrawer = ({
    open,
    onClose,
    roleIdToUpdate,
    action,
    page,
}: Type_Props_RoleDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Roles");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`RoleTypes-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"roles"}
            page={page}
        >
            <RolesForm
                onClose={onClose}
                roleIdToUpdate={
                    roleIdToUpdate === null ? undefined : roleIdToUpdate
                }
                action={action}
                setIsLoading={setIsLoading}
            />
        </DrawerGeneric>
    );
};
