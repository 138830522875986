import { taskAreaStatusEnum } from "@cimba-digital-construction-solution/utils/dist/tools/taskAreaStatus.enum";
import { Box, Stack } from "@mui/material";
import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import {
    Icon,
    Type_Props_Icon,
} from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_PlanningFilterTaskStatus = {
    name: string;
};

type Type_ItemChip = {
    label: string;
    value: string;
    icon: ReactElement<Type_Props_Icon>;
};

export const PlanningFilterTaskStatus = ({
    name,
}: Type_Props_PlanningFilterTaskStatus) => {
    const { control } = useFormContext();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.StatusConstraints",
    );

    const taskStatusList: Type_ItemChip[] = [
        {
            label: fmt("ToDo"),
            value: taskAreaStatusEnum.todo,
            icon: <Icon color="inherit" variant="light" icon="circle-dashed" />,
        },
        {
            label: fmt("InProgress"),
            value: taskAreaStatusEnum.doing,
            icon: (
                <Icon
                    color="inherit"
                    variant="light"
                    icon="circle-half-stroke"
                />
            ),
        },
        {
            label: fmt("Completed"),
            value: taskAreaStatusEnum.done,
            icon: <Icon color="inherit" variant="light" icon="circle-check" />,
        },
        {
            label: fmt("Delay"),
            value: taskAreaStatusEnum.late,
            icon: (
                <Icon
                    color="inherit"
                    variant="light"
                    icon="circle-exclamation"
                />
            ),
        },
    ];

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
                const handleChipClick = (taskStatusValue: string) => {
                    const updatedValue = value.includes(taskStatusValue)
                        ? value.filter((v: string) => v !== taskStatusValue)
                        : [...value, taskStatusValue];
                    onChange(updatedValue);
                };

                return (
                    <Stack
                        display="flex"
                        direction={"row"}
                        flexWrap={"wrap"}
                        gap={3}
                    >
                        {taskStatusList.map((taskStatus) => {
                            const isSelected = value.includes(taskStatus.value);

                            return (
                                <Box key={taskStatus.value}>
                                    <TMC_Chip
                                        data-testid={`taskStatus-${taskStatus.value}`}
                                        clickable
                                        label={taskStatus.label}
                                        icon={taskStatus.icon}
                                        color={
                                            isSelected ? "primary" : "default"
                                        }
                                        onClick={() =>
                                            handleChipClick(taskStatus.value)
                                        }
                                    />
                                </Box>
                            );
                        })}
                    </Stack>
                );
            }}
        />
    );
};
