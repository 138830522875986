import { COLORS } from "src/theme/stylesheet";

export type Type_permissionsSelectConfiguration = Type_project[];

export type Type_project = {
    name: string; // Scheduling, Reguly... etc
    categories: Type_subproject[];
};

export type Type_subproject = {
    key: string; // language key (ex: General)
    color: Type_color_hexadecimal; // (ex: #00FFD4)
    categories: Type_category[];
};

export type Type_color_hexadecimal = string;

export type Type_category = {
    key: string; // language key (ex: "Unit, CauseOfDelay ...")
    permissions: string[];
    categories: Type_permission[];
};

export type Type_permission = {
    key: string; // language key (ex: "Create, Show, Update... etc")
    permissions: string[]; // ["units_view", "units_create", "units_update"]
};

export const permissionsSelectConfiguration: Type_permissionsSelectConfiguration =
    [
        {
            name: "Global",
            categories: [
                {
                    key: "General",
                    color: COLORS.fluoOrange,
                    categories: [
                        {
                            key: "AreaTypes",
                            permissions: ["areaTypes_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "areaTypes_view",
                                        "areaTypes_create",
                                        "areaTypes_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["areaTypes_delete"],
                                },
                            ],
                        },
                        {
                            key: "CausesOfDelay",
                            permissions: ["causesOfDelay_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "causesOfDelay_view",
                                        "causesOfDelay_create",
                                        "causesOfDelay_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["causesOfDelay_delete"],
                                },
                            ],
                        },
                        {
                            key: "Companies",
                            permissions: ["companies_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "companies_view",
                                        "companies_create",
                                        "companies_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["companies_delete"],
                                },
                            ],
                        },
                        {
                            key: "LicenseTypes",
                            permissions: ["licenseTypes_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "licenseTypes_view",
                                        "licenseTypes_create",
                                        "licenseTypes_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["licenseTypes_delete"],
                                },
                            ],
                        },
                        {
                            key: "MetadataProjects",
                            permissions: ["metadataProjects_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "metadataProjects_view",
                                        "metadataProjects_create",
                                        "metadataProjects_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["metadataProjects_delete"],
                                },
                            ],
                        },
                        {
                            key: "MetadataUsers",
                            permissions: ["metadataUsers_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "metadataUsers_view",
                                        "metadataUsers_create",
                                        "metadataUsers_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["metadataUsers_delete"],
                                },
                            ],
                        },
                        {
                            key: "PriorityOfConstraints",
                            permissions: ["priorityOfConstraints_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "priorityOfConstraints_view",
                                        "priorityOfConstraints_create",
                                        "priorityOfConstraints_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: [
                                        "priorityOfConstraints_delete",
                                    ],
                                },
                            ],
                        },
                        {
                            key: "ProjectTypes",
                            permissions: ["projectTypes_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "projectTypes_view",
                                        "projectTypes_create",
                                        "projectTypes_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["projectTypes_delete"],
                                },
                            ],
                        },
                        {
                            key: "PunchlistItemCauses",
                            permissions: ["punchlistItemCauses_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "punchlistItemCauses_view",
                                        "punchlistItemCauses_create",
                                        "punchlistItemCauses_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["punchlistItemCauses_delete"],
                                },
                            ],
                        },
                        {
                            key: "ResourceFamilies",
                            permissions: ["resourceFamilies_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "resourceFamilies_view",
                                        "resourceFamilies_create",
                                        "resourceFamilies_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["resourceFamilies_delete"],
                                },
                            ],
                        },
                        {
                            key: "ResourceModels",
                            permissions: ["resourceModels_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "resourceModels_view",
                                        "resourceModels_create",
                                        "resourceModels_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["resourceTypes_delete"],
                                },
                            ],
                        },
                        {
                            key: "Roles",
                            permissions: ["roles_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "roles_view",
                                        "roles_create",
                                        "roles_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["roles_delete"],
                                },
                            ],
                        },
                        {
                            key: "TimeModels",
                            permissions: ["timeModels_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "timeModels_view",
                                        "timeModels_create",
                                        "timeModels_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["timeModels_delete"],
                                },
                            ],
                        },
                        {
                            key: "Units",
                            permissions: ["units_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "units_view",
                                        "units_create",
                                        "units_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["units_delete"],
                                },
                            ],
                        },
                        {
                            key: "UnitTypes",
                            permissions: ["unitTypes_index"],
                            categories: [
                                {
                                    key: "Edit",
                                    permissions: [
                                        "unitTypes_view",
                                        "unitTypes_create",
                                        "unitTypes_update",
                                    ],
                                },
                                {
                                    key: "Delete",
                                    permissions: ["unitTypes_delete"],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
