import { Stack, Typography } from "@mui/material";
import React from "react";

type Type_Props_SectionTitle = {
    title: string;
    optionalText?: string;
    contents?: string[];
    children?: React.ReactNode;
};

export const SectionTitle = ({
    title,
    contents,
    children,
}: Type_Props_SectionTitle) => {
    return (
        <>
            <Stack
                direction={"row"}
                flexWrap={"nowrap"}
                alignItems="center"
                justifyContent="space-between"
                marginBottom={2}
            >
                <Typography variant="h3">{title}</Typography>
                {children && children}
            </Stack>

            {!!contents?.length && (
                <Stack direction="column">
                    {contents?.map((content, index) => (
                        <Typography
                            key={index}
                            variant="body1"
                            marginBottom={index === contents.length - 1 ? 6 : 2}
                        >
                            {content}
                        </Typography>
                    ))}
                </Stack>
            )}
        </>
    );
};

export const SectionTitleWithOptional = ({
    title,
    optionalText,
}: Type_Props_SectionTitle) => {
    return (
        <Stack
            direction={"row"}
            flexWrap={"nowrap"}
            alignItems="center"
            gap={1}
            marginBottom={2}
        >
            <Typography variant="body1Bold">{title}</Typography>
            {optionalText && (
                <Typography variant="body3" color={"sectionTitle.contrastText"}>
                    {`· ${optionalText}`}
                </Typography>
            )}
        </Stack>
    );
};
