import { PeriodToggleEnum } from "@cimba-digital-construction-solution/utils/dist/filters/planningFilters";
import {
    Box,
    Divider,
    AppBar as MuiAppBar,
    Toolbar as MuiToolbar,
    Stack,
    Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useMemo, useState } from "react";

import {
    DropdownMenu,
    TMC_Button,
    TMC_ToggleButton,
} from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Tooltip } from "src/components/Components_Common/_MuiComponentsVariants/Tooltip/Tooltip.component";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { usePlanningContext } from "src/contexts/planning";
import { PrintSettingsDialog } from "src/dialogs/print/PrintSettingsDialog";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

////////////////////////////////////////////
// --- STYLES                             //
////////////////////////////////////////////

const Styled_AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ToolbarSection = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.text.primary,
    gap: theme.spacing(2),
}));

////////////////////////////////////////////
// --- Toolbar                             //
////////////////////////////////////////////

export const PlanningToolbar = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.Toolbar",
    );

    const [openPrintSettings, setOpenPrintSettings] = useState<boolean>(false);
    const { toggleTaskDoneStatus, enableTaskDone, dataFilters } =
        usePlanningContext();
    const { togglePaper } = useContextualDrawer();

    const theme = useTheme();
    const handleSequencesClick = () => {
        togglePaper("sequences", {}, true);
    };
    const handleFiltersClick = () => {
        togglePaper("planningFilters", {}, true);
    };

    const activeFilterCount = useMemo(() => {
        return [
            Number(dataFilters.period.type !== PeriodToggleEnum.FULL),
            dataFilters.notSelectedTrades.length,
            dataFilters.notSelectedCompanies.length,
            dataFilters.typesTasks.length,
            dataFilters.status.length,
        ].reduce((count, isActive) => count + Number(isActive > 0 ? 1 : 0), 0);
    }, [dataFilters]);

    const hasActiveFilters = !!activeFilterCount;

    const menuItems = [
        {
            id: "sequences",
            icon: {
                name: "light-tds-sequence",
                variant: "kit",
                fontSize: "small",
            },
            label: fmt("Sequences"),

            call: () => console.log("Sequences clicked"),
        },
        {
            id: "trades",
            icon: {
                name: "helmet-safety",
                variant: "light",
                fontSize: "small",
            },

            label: fmt("Trades"),
            call: () => console.log("Trades clicked"),
        },
        {
            id: "companies",
            icon: {
                name: "building",
                variant: "light",
                fontSize: "small",
            },
            label: fmt("Companies"),
            call: () => console.log("Companies clicked"),
        },
    ];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Styled_AppBar position="static" elevation={0}>
                <MuiToolbar variant="dense">
                    <ToolbarSection color={theme.palette.text.primary}>
                        <DropdownMenu
                            iconButton={{
                                sx: { color: theme.palette.text.primary },
                            }}
                            showHeader
                            id="group-by"
                            label={fmt("GroupBy")}
                            icon={
                                <Icon
                                    color={"inherit"}
                                    icon="rectangle-list"
                                    variant="light"
                                />
                            }
                            menuItems={menuItems}
                        />

                        <TMC_ToggleButton
                            data-testid={`planning-toolbar-filter-btn`}
                            aria-label="filter-btn"
                            value="filter"
                            selected={hasActiveFilters}
                            onClick={handleFiltersClick}
                        >
                            <Icon
                                sx={{ mr: 1 }}
                                fontSize="small"
                                icon="bars-filter"
                                variant="light"
                            />
                            <Typography variant="body2">
                                {hasActiveFilters && `(${activeFilterCount})`}{" "}
                                {fmt("Filters")}
                            </Typography>
                        </TMC_ToggleButton>

                        <IconButton
                            aria-label="sequence-btn"
                            color={"inherit"}
                            data-testid={`planning-toolbar-sequence-btn`}
                            onClick={handleSequencesClick}
                        >
                            <Icon
                                sx={{ mr: 1 }}
                                icon="light-tds-sequence"
                                variant="kit"
                            />
                            <Typography variant="body2">
                                {fmt("Sequences")}
                            </Typography>
                        </IconButton>

                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />

                        <TMC_ToggleButton
                            data-testid={`planning-toolbar-add-task-btn`}
                            value="add"
                            selected={false}
                            onChange={() => {}}
                        >
                            <Icon icon="add" variant="light" />
                        </TMC_ToggleButton>

                        <Tooltip
                            title={
                                enableTaskDone
                                    ? fmt("UndoMarkAsCompleted")
                                    : fmt("MarkAsCompleted")
                            }
                        >
                            <TMC_ToggleButton
                                data-testid={`planning-toolbar-mark-as-completed-btn`}
                                value="check"
                                selected={enableTaskDone}
                                onChange={toggleTaskDoneStatus}
                            >
                                <Icon icon="circle-check" variant="light" />
                            </TMC_ToggleButton>
                        </Tooltip>

                        <TMC_ToggleButton
                            data-testid={`planning-toolbar-thumbtack-btn`}
                            value="thumbtack"
                            selected={false}
                            onChange={() => {}}
                        >
                            <Icon icon="thumbtack" variant="light" />
                            <Icon
                                fontSize="small"
                                icon={"caret-down"}
                                variant={"solid"}
                            />
                        </TMC_ToggleButton>
                    </ToolbarSection>

                    <Box sx={{ flexGrow: 1 }} />

                    <ToolbarSection>
                        <IconButton
                            color={"inherit"}
                            aria-label="add-btn"
                            data-testid={`planning-toolbar-settings-btn`}
                            onClick={() => {
                                togglePaper("planningSettings", {}, true);
                            }}
                        >
                            <Icon variant="light" icon="sliders" />
                        </IconButton>

                        <TMC_Button
                            variant="outlined"
                            data-testid={`planning-toolbar-export-btn`}
                            startIcon={
                                <Icon
                                    variant="light"
                                    icon="arrow-down-to-bracket"
                                />
                            }
                            onClick={() => setOpenPrintSettings(true)}
                        >
                            {fmt("ExportPDF")}
                        </TMC_Button>
                    </ToolbarSection>
                </MuiToolbar>
            </Styled_AppBar>
            {openPrintSettings && (
                <PrintSettingsDialog
                    openDialog={openPrintSettings}
                    setToggleDialog={setOpenPrintSettings}
                />
            )}
        </Box>
    );
};
