import { Stack, Typography } from "@mui/material";
import React, { ComponentProps } from "react";

import { useSelectListResourceTypes } from "src/api/tms-projects/resourceTypes";
import { Type_selectList } from "src/api/types";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { COLORS } from "src/theme/stylesheet";

type Type_Props_AutocompleteResourceTypes = Omit<
    ComponentProps<typeof Autocomplete>,
    "options" | "isFetching"
> & {
    desc?: string;
};

export const AutocompleteResourceTypes = ({
    name,
    label,
    desc,
    ...props
}: Type_Props_AutocompleteResourceTypes) => {
    const { isFetching, data: resourceTypes } =
        useSelectListResourceTypes() || {};

    return (
        <Stack width={"100%"} flexDirection={"column"}>
            <Autocomplete
                name={name}
                label={label}
                isFetching={isFetching}
                options={(resourceTypes as Type_selectList[]) || []}
                {...props}
            />
            {desc && (
                <Typography variant={"body2"} color={COLORS.moon500}>
                    {desc}
                </Typography>
            )}
        </Stack>
    );
};
