import { styled } from "@mui/material/styles";
import Konva from "konva";
import React, {
    Dispatch,
    RefObject,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { Text } from "react-konva";
import { Html } from "react-konva-utils";
import { HtmlTransformAttrs } from "react-konva-utils/es/html";

const Styled_TextEditInput = styled("input")(() => ({
    border: "none",
    padding: "0px",
    margin: "0px",
    overflow: "hidden",
    outline: "none",
    resize: "none",
    visibility: "hidden",
}));

type Type_Props_TextEditInput = Konva.TextConfig & {
    refText: RefObject<Konva.Text>;
    type: string;
    readonly?: boolean;
    background?: string;
    onChange?: (value: string) => void;
    setEdited: Dispatch<SetStateAction<boolean>>;
    offsetY?: number;
};

const TextEditInput = ({
    type,
    refText,
    onChange,
    background,
    setEdited,
    offsetY = 0,
    offsetX = 0,
    style,
    textUnformatted,
    format,
}: Type_Props_TextEditInput) => {
    const refInput: RefObject<HTMLInputElement> =
        useRef<HTMLInputElement>(null);

    useEffect(() => {
        const textNode: Konva.Text | null = refText.current;
        const textarea: HTMLInputElement | null = refInput.current;

        const handleKeyDown = (e: KeyboardEvent): void => {
            if (textNode && textarea && e.key === "Enter") {
                let value: string = textarea.value;
                if (format) {
                    value = format(value);
                }
                textNode.text(value);
                onChange && onChange(textarea.value);
                setEdited(false);
            }

            if (e.key === "Escape") {
                setEdited(false);
            }

            e.stopPropagation();
        };

        const handleOutsideClick = (e: MouseEvent): void => {
            if (textarea && e.target !== textarea && textNode) {
                let value: string = textarea.value;
                if (format) {
                    value = format(value);
                }
                textNode?.text(value);
                onChange && onChange(textarea?.value);
                setEdited(false);
            }
        };

        if (textNode && textarea) {
            textarea.value = textUnformatted || textNode.text();
            textarea.style.width = textNode.width() - 1 + "px";
            textarea.style.height = textNode.height() - 1 + "px";
            textarea.style.fontSize = textNode.fontSize() + "px";
            textarea.style.lineHeight = textarea.style.height + "px";
            textarea.style.fontFamily = textNode.fontFamily();
            textarea.style.textAlign = textNode.align();
            textarea.style.color = textNode.fill();
            textarea.style.visibility = "visible";

            background && (textarea.style.background = background);

            textarea.focus();

            textarea.addEventListener("keydown", handleKeyDown);

            setTimeout((): void => {
                window.addEventListener("click", handleOutsideClick);
            });
        }

        return (): void => {
            if (textNode && textarea) {
                window.removeEventListener("keydown", handleKeyDown);
                window.removeEventListener("click", handleOutsideClick);
            }
        };
    }, [refText.current, refInput.current]);

    const textNode: Konva.Text | null = refText.current;

    return (
        <Html
            transformFunc={(attrs: HtmlTransformAttrs): HtmlTransformAttrs => ({
                ...attrs,
                y: attrs.y + (textNode ? textNode.y() : 0) - offsetY,
                x: attrs.x + (textNode ? textNode.x() : 0) - offsetX,
            })}
        >
            <Styled_TextEditInput ref={refInput} type={type} style={style} />
        </Html>
    );
};

export type Type_Props_TextEdit = Konva.TextConfig & {
    refText?: RefObject<Konva.Text>;
    text: string;
    textUnformatted?: string;
    type?: string;
    readonly?: boolean;
    background?: string;
    onChange?: (value: string) => void;
};

export const TextEdit = ({
    refText,
    type = "text",
    readonly = false,
    background,
    onChange,
    ...props
}: Type_Props_TextEdit) => {
    refText = refText ? refText : useRef<Konva.Text>(null);

    const [edited, setEdited] = useState<boolean>(false);

    return (
        <>
            <Text
                ref={refText}
                {...props}
                listening={!readonly}
                onDblClick={(e: Konva.KonvaEventObject<MouseEvent>): void => {
                    e.cancelBubble = true;
                    setEdited((prev: boolean): boolean => !prev);
                }}
            />
            {edited && (
                <TextEditInput
                    refText={refText}
                    type={type}
                    background={background}
                    setEdited={setEdited}
                    onChange={onChange}
                    textUnformatted={props?.textUnformatted || props.text}
                    format={props.format}
                    offsetY={props.editOffsetY}
                    offsetX={props.editOffsetX}
                    style={props.style}
                />
            )}
        </>
    );
};
