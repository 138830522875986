import { GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_projectType } from "src/api/tms-commons/projectTypes/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { ProjectTypeDrawer } from "src/drawers/projectTypes/ProjectTypeDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteProjectType } from "./Page_ProjectTypes.container";

export type Type_Props_PageProjectTypesComponent = {
    projectTypes: Type_index_projectType[];
    deleteProjectType: (id: number) => void;
    modalDeleteProjectType: Type_modalDeleteProjectType;
    setModalDeleteProjectType: Dispatch<
        SetStateAction<Type_modalDeleteProjectType>
    >;
    changeStatus: (id: number, projectType: Type_index_projectType) => void;
    isFetching: boolean;
};

export const Page_ProjectTypesComponent = ({
    projectTypes,
    deleteProjectType,
    modalDeleteProjectType,
    setModalDeleteProjectType,
    changeStatus,
    isFetching,
}: Type_Props_PageProjectTypesComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.ProjectTypes",
    );
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "projectTypes";

    const [selectedProjectType, setSelectedProjectType] = useState<
        number | null
    >(null);
    const [openProjectTypeDrawer, setOpenProjectTypeDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleProjectTypeEdit = (projectTypeId: number) => {
        setSelectedProjectType(projectTypeId);
        setAction("update");
        setOpenProjectTypeDrawer(true);
    };

    const handleProjectTypeCreate = () => {
        setSelectedProjectType(null);
        setAction("create");
        setOpenProjectTypeDrawer(true);
    };

    const handleProjectTypeDuplicate = (projectTypeId: number) => {
        setSelectedProjectType(projectTypeId);
        setAction("duplicate");
        setOpenProjectTypeDrawer(true);
    };

    const handleCloseProjectTypeDrawer = () => {
        setOpenProjectTypeDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            sortable: false,
        },
        {
            field: "areaTypesCount",
            headerName: fmtTableColumn("Use"),
            sortable: false,
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleProjectTypeEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) => {
        handleProjectTypeDuplicate(row.id);
    };

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row.id, row as Type_index_projectType);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteProjectType({
            isOpen: true,
            props: row as Type_index_projectType,
        });
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageProjectTypes-AddOne-btn"
                    onClick={() => handleProjectTypeCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={projectTypes}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleProjectTypeCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleProjectTypeEdit(id as number);
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />

            <ProjectTypeDrawer
                onClose={handleCloseProjectTypeDrawer}
                open={openProjectTypeDrawer}
                projectTypeIdToUpdate={selectedProjectType}
                action={action}
                page={page}
            />

            <ModalDelete
                open={modalDeleteProjectType.isOpen}
                onClose={() => setModalDeleteProjectType({ isOpen: false })}
                item={fmt("DataName")}
                validationString={modalDeleteProjectType?.props?.name as string}
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteProjectType({ isOpen: false });
                            deleteProjectType(
                                modalDeleteProjectType?.props?.id as number,
                            );
                        },
                    },
                }}
            />
        </>
    );
};
