import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { Url, ViewsPath } from "src/api/paths";
import {
    Type_prj_post_view,
    Type_prj_put_view,
} from "src/api/tms-projects/workspacesAndViews/views/types";

export const createView = (
    data: Type_prj_post_view,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.PROJECTS}${ViewsPath.VIEWS}`, requestConfig);
};

export const showView = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${ViewsPath.VIEWS}/${id}`, requestConfig);
};

export const updateView = (
    id: number,
    data: Type_prj_put_view,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(data, `${Url.PROJECTS}${ViewsPath.VIEWS}/${id}`, requestConfig);
};
