import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Alert } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_Stack_BackToLogin,
    Styled_Stack_ChildrenPasswordChanged,
} from "src/pages/Pages_Common/Page_ChangePassword/Page_ChangePassword.style";
import { COLORS } from "src/theme/stylesheet";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export const ChangePassword_Step_2 = () => {
    const navigate = useNavigate();
    const lang = getLocalStorageItem("language");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("ChangePassword");

    return (
        <Styled_Stack_ChildrenPasswordChanged>
            <Typography variant="h1">{fmt("Step2.Title")}</Typography>
            <Alert status="info">{fmt("Step2.Message")}</Alert>
            <Styled_Stack_BackToLogin
                sx={{ marginTop: "140px" }}
                onClick={() => navigate(`/${lang}/login`, { replace: true })}
            >
                <Icon
                    variant="solid"
                    icon="arrow-left"
                    sx={{ color: COLORS.blue700 }}
                />
                <Typography variant="body1Underline" color={COLORS.blue700}>
                    {fmt("Step2.BackToLogin")}
                </Typography>
            </Styled_Stack_BackToLogin>
        </Styled_Stack_ChildrenPasswordChanged>
    );
};
