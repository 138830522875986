export const FilePdf = async (fileUrl: string | ArrayBuffer) => {
    console.debug("Load convert pdf to png");

    // décodage du fichier PDF base64
    const pdfData = atob(fileUrl.toString().split(",")[1]);

    // canvas pour la conversion en png
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.setAttribute("className", "canv");

    // @ts-expect-error error affiché pour ce fichier particulier
    const pdfjsLib = await import("pdfjs-dist/build/pdf.mjs");

    pdfjsLib.GlobalWorkerOptions.workerSrc = await import(
        // @ts-expect-error error affiché pour ce fichier particulier
        "pdfjs-dist/build/pdf.worker.mjs"
    );

    // chargement du fichier PDF
    const loadingTask = pdfjsLib.getDocument({
        data: pdfData,
        disableFontFace: true,
        verbosity: 0,
    });
    const pdfDocument = await loadingTask.promise;

    // parcours dur
    if (pdfDocument.numPages) {
        const page = await pdfDocument.getPage(1);
        const viewport = page.getViewport({ scale: 1.5 });

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const render_context = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
        };

        await page.render(render_context as any).promise;
        fileUrl = canvas.toDataURL("image/png");

        console.debug("Load convert pdf to png ok");
    } else {
        // ERREUR
        console.error("Load convert pdf to png error");
    }
    return fileUrl;
};
