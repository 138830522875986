import { Box } from "@mui/material";

import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { MatrixSequence } from "src/components/Components_Scheduling/Matrix/MatrixSequence/MatrixSequence";

export type Type_sequenceForMatrixFullScreen = {
    id: number;
    label: string;
};

type Type_Props_MatrixSequenceFullScreen = Omit<
    Type_props_DialogFullScreen,
    "title"
> & { sequence?: Type_sequenceForMatrixFullScreen | null };

export const MatrixSequenceFullScreen = ({
    openDialog,
    setToggleDialog,
    sequence,
}: Type_Props_MatrixSequenceFullScreen) => {
    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={sequence?.label}
        >
            <Box sx={{ flexGrow: 1, mx: 4 }}>
                <MatrixSequence sequenceId={sequence?.id} />
            </Box>
        </DialogFullScreen>
    );
};
