import { SceneContext } from "konva/lib/Context";
import React from "react";
import { Group, Line, Rect } from "react-konva";

import {
    colorFlowBackground,
    heightFlowShape,
    paddingFlowText,
    sizeFlowGridX,
    sizeFlowGridY,
    widthFlowShapeStroke,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { Conv_typeFlowTask } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { getTaskProps } from "src/components/Components_Teamoty/Flow/tools/getTaskProps";
import { getTaskWidth } from "src/components/Components_Teamoty/Flow/tools/getTaskWidth";
import { invertColor } from "src/utils/colors";

import {
    components_shape_flow,
    Type_props_components_shape_flow,
} from "./FlowShape.const";
import { Type_Props_FlowShapeTaskBase } from "./FlowShape.type";
import { FlowShapeTextTask } from "./FlowShapeTextTask";
import { FlowShapeWorkMilestone } from "./FlowShapeWorkMilestone";

export const FlowShapeTaskLightBase = ({
    task,
    drag,
    fixedSize,
}: Type_Props_FlowShapeTaskBase) => {
    const color: string = invertColor(task.color);

    const padding: number = paddingFlowText;
    const width: number = getTaskWidth(task, fixedSize);
    const height: number = heightFlowShape;

    const typeShape: Type_props_components_shape_flow = Conv_typeFlowTask[
        task.type
    ] as Type_props_components_shape_flow;
    const shapeFlowFunction = components_shape_flow[typeShape];

    const [props, lines] = getTaskProps(typeShape, width, task);

    return (
        <Group>
            <Rect
                x={task.pt.x - sizeFlowGridX / 6}
                y={task.pt.y - sizeFlowGridY / 6}
                width={width + sizeFlowGridX / 3}
                height={height + sizeFlowGridY / 3}
            />

            {drag.dragging && (
                <FlowShapeWorkMilestone
                    shapeFlowFunction={shapeFlowFunction}
                    x={drag.pt.x}
                    y={drag.pt.y}
                    pt={task.pt}
                    width={width}
                    height={height}
                    opacity={0.5}
                    fill={task.color}
                    listening={false}
                />
            )}

            <Rect
                name="preview"
                x={task.pt.x}
                y={task.pt.y}
                width={width}
                height={height}
                fill={colorFlowBackground}
                listening={false}
            />

            <Group
                clipFunc={(ctx: SceneContext): void => {
                    ctx.beginPath();
                    ctx.moveTo(task.pt.x, task.pt.y);
                    ctx.lineTo(task.pt.x + width, task.pt.y);
                    ctx.lineTo(task.pt.x + width, task.pt.y + height);
                    ctx.lineTo(task.pt.x, task.pt.y + height);
                    ctx.closePath();
                }}
            >
                <Line
                    points={[
                        task.pt.x,
                        task.pt.y + height / 2,
                        task.pt.x + width,
                        task.pt.y + height / 2,
                    ]}
                    stroke={task.color}
                    strokeWidth={1}
                    listening={false}
                />

                {lines}

                <FlowShapeTextTask
                    code={task?.code}
                    name={task?.name}
                    fill={color}
                    background={colorFlowBackground}
                    maxWidth={width - 2 * padding}
                    height={height / 2}
                    x={task.pt.x + padding}
                    y={task.pt.y}
                    onChange={(value: string): void => {
                        console.log(value);
                    }}
                    editOffsetY={1}
                />

                {props}
            </Group>

            <FlowShapeWorkMilestone
                shapeFlowFunction={shapeFlowFunction}
                pt={task.pt}
                width={width}
                height={height}
                stroke={task.color}
                strokeWidth={widthFlowShapeStroke}
                listening={false}
            />
        </Group>
    );
};
