import { Select, styled } from "@mui/material";

export const Styled_Select = styled(Select)(({ theme }) => ({
    borderRadius: 0,
    ...theme.typography.body1Medium,
    height: "32px",
    justifySelf: "center",
    width: "100%",
    "&.MuiInputBase-root": {
        padding: 0,
        border: "none",
        boxShadow: "none",
    },
}));
