import { Grid, Stack } from "@mui/material";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { Input } from "src/components/Components_Common/forms/reactHookFormComponents/Input/Input";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SelectSequenceFolder } from "src/components/Components_Teamoty/forms/select/SelectSequenceFolder";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const SequenceContent = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Sequence");
    const form = useFormContext();

    return (
        <Stack padding={2} spacing={4} sx={{ paddingX: 4 }}>
            <Stack flexGrow={1}>
                <Grid container alignItems="center" rowSpacing={2.5}>
                    <Grid item xs={1} paddingInlineStart={3}>
                        <Icon
                            variant={"light"}
                            icon={"barcode"}
                            fontSize={"small"}
                            title={fmt("IconTitle.Code")}
                        />
                    </Grid>
                    <Grid item xs={11} paddingInlineStart={2}>
                        <Input
                            variant="standard"
                            placeholder={fmt("Form.CodePlaceholder")}
                            data-testid={"sequence-drawer-input-code"}
                            {...form.register("code")}
                        />
                    </Grid>
                    <Grid item xs={1} paddingInlineStart={3}>
                        <Icon
                            variant={"light"}
                            icon={"calendar-days"}
                            fontSize={"small"}
                            title={fmt("IconTitle.StartDate")}
                        />
                    </Grid>
                    <Grid item xs={11} paddingInlineStart={2}>
                        <DatePicker
                            variant="standard"
                            name={"earliestDate"}
                            sx={{ flex: 1, color: "inherit" }}
                            data-testid={"sequence-drawer-datePicker"}
                        />
                    </Grid>
                    <Grid item xs={1} paddingInlineStart={3}>
                        <Icon
                            variant={"light"}
                            icon={"folder"}
                            fontSize={"small"}
                            title={fmt("IconTitle.Folder")}
                        />
                    </Grid>
                    <Grid item xs={11} paddingInlineStart={2}>
                        <SelectSequenceFolder
                            name={"sequenceFolderId"}
                            data-testid={
                                "sequence-drawer-select-sequenceFolder"
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Switch
                            name={"proportionalSize"}
                            label={fmt("Form.ProportionalSize")}
                            labelPlacement={"start"}
                            size="small"
                            caption={fmt("Form.ProportionalSizeCaption")}
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            data-testid={
                                "sequence-drawer-switch-proportionalSize"
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Switch
                            name={"optimizeByArea"}
                            label={fmt("Form.OptimizeByArea")}
                            labelPlacement={"start"}
                            size="small"
                            caption={fmt("Form.OptimizeByAreaCaption")}
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            data-testid={
                                "sequence-drawer-switch-optimizeByArea"
                            }
                        />
                    </Grid>
                </Grid>
            </Stack>
        </Stack>
    );
};
