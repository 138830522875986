import React, { useMemo, useState } from "react";

import {
    mutationDeleteSubTrade,
    mutationStatusSubTrade,
} from "src/api/tms-projects/subTrades";
import { Type_index_subTrade } from "src/api/tms-projects/subTrades/types";
import {
    mutationDeleteTrade,
    mutationStatusTrade,
    useIndexTrades,
} from "src/api/tms-projects/trades";
import {
    Type_index_trade,
    Type_prj_put_trade,
} from "src/api/tms-projects/trades/types";
import { Page_TradesComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Trades/Page_Trades.component";

export type Type_modalDeleteTrade = {
    isOpen: boolean;
    props?: any;
};

export const Page_TradesContainer = () => {
    const { isFetching, data: trades } = useIndexTrades() || {};

    // STATUS
    const { mutate: mutationTradesStatus } = mutationStatusTrade() || {};

    const changeStatusTrade = async (trade: Type_index_trade) => {
        mutationTradesStatus({
            id: trade.id,
            enabled: !trade.enabled,
        } as Type_prj_put_trade);
    };

    const { mutate: mutationSubTradesStatus } = mutationStatusSubTrade() || {};

    const changeStatusSubTrade = async (subTrade: Type_index_subTrade) => {
        mutationSubTradesStatus({
            id: subTrade.id,
            enabled: !subTrade.enabled,
        });
    };

    // DELETE
    const [modalDelete, setModalDelete] = useState<Type_modalDeleteTrade>({
        isOpen: false,
    });

    const { mutateAsync: deleteSubTrade } = mutationDeleteSubTrade() || {};

    const { mutateAsync: deleteTrade } = mutationDeleteTrade() || {};

    const removeTrade = async (id: number) => {
        await deleteTrade(id);
    };

    const removeSubTrade = async (id: number) => {
        await deleteSubTrade(id);
    };

    const tradesColors = useMemo(
        () => trades?.map((trade: Type_index_trade) => trade.color) || [],
        [trades],
    );

    return (
        <Page_TradesComponent
            isFetching={isFetching}
            trades={trades || []}
            changeStatusTrade={changeStatusTrade}
            changeStatusSubTrade={changeStatusSubTrade}
            modalDelete={modalDelete}
            setModalDelete={setModalDelete}
            deleteTrade={removeTrade}
            deleteSubTrade={removeSubTrade}
            tradesColors={tradesColors}
        />
    );
};
