import { Grid, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";

import { Type_show_company } from "src/api/tms-commons/companies/types";
import { Type_get_me } from "src/api/tms-gateway/auth/types";
import { Avatar } from "src/components";
import { SettingsRowLine } from "src/components/Components_Common/SettingsRowLine/SettingsRowLine";
import { SettingsRowTitle } from "src/components/Components_Common/SettingsRowTitle/SettingsRowTitle";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_InfoRow = {
    label: string;
    value?: string;
};

// --- Utils functions for render
const displayValue = (value?: string): string => value || "-";

const renderInfoRow = (label: string, value?: string) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("MyAccount.Profile");
    return (
        <React.Fragment key={label + "" + Math.random().toFixed(1)}>
            <SettingsRowLine
                title={fmt(label)}
                dataTestId={`MyProfile-${label}`}
                value={displayValue(value)}
            />
        </React.Fragment>
    );
};

export type Type_Props_Page_MyProfileComponent = {
    user: Type_get_me | null;
    fullName: string;
    company?: Type_show_company;
    handleOpenDrawer: (step: string) => void;
};

export const Page_MyProfileComponent = ({
    user,
    fullName,
    company,
    handleOpenDrawer,
}: Type_Props_Page_MyProfileComponent) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("MyAccount.Profile");

    // ---- CONSTANTS
    const hiddenPassword: string = "············";

    const userInfos: Type_InfoRow[] = [
        {
            label: "Firstname",
            value: user?.firstName,
        },
        {
            label: "Lastname",
            value: user?.lastName,
        },
        {
            label: "Email",
            value: user?.email,
        },

        {
            label: "Phone",
            value: user?.phone,
        },
    ];

    const companyInfos: Type_InfoRow[] = [
        {
            label: "CompanyName",
            value: company?.name,
        },
        {
            label: "CompanyNationalID",
            value: company?.nationalId || "",
        },
        {
            label: "CompanyZIPCode",
            value: company?.postalCode,
        },
        {
            label: "CompanyCity",
            value: company?.city,
        },
        {
            label: "CompanyCountry",
            value: company?.country,
        },
    ];

    // ---- RENDER UTILS
    const renderInfos = (data: Type_InfoRow[]) =>
        data.map((item: Type_InfoRow) => renderInfoRow(item.label, item.value));

    return (
        <>
            <Stack rowGap={4} mt={6}>
                <Grid container rowGap={2}>
                    <SettingsRowTitle
                        title={fmt("Title.Informations")}
                        dataTestId="MyProfile-Edit-Informations"
                        handleClick={() => handleOpenDrawer("1")}
                    />
                    <Grid item xs={12}>
                        <Avatar
                            data-testid="MyProfile-Avatar"
                            name={fullName}
                            sx={{
                                width: 96,
                                height: 96,
                                textTransform: "uppercase",
                                fontSize: "26px",
                            }}
                        />
                    </Grid>
                    {renderInfos(userInfos)}
                </Grid>
                <Divider />
                <Grid container rowGap={2}>
                    <SettingsRowTitle
                        title={fmt("Title.Company")}
                        dataTestId="MyProfile-Edit-Company"
                        handleClick={() => handleOpenDrawer("2")}
                    />
                    {renderInfos(companyInfos)}
                </Grid>
                <Divider />
                <Grid container rowGap={2}>
                    <SettingsRowTitle
                        title={fmt("Title.Security")}
                        dataTestId="MyProfile-Edit-Password"
                        handleClick={() => handleOpenDrawer("3")}
                        action={"ChangePassword"}
                    />
                    {renderInfoRow("Password", hiddenPassword)}
                </Grid>
            </Stack>
        </>
    );
};
