import { Type_point } from "src/components/Components_Common/canvas/types";

export const diffPoint = (pt1: Type_point, pt2: Type_point): Type_point => {
    return {
        x: pt1.x - pt2.x,
        y: pt1.y - pt2.y,
    };
};

export const addPoint = (pt1: Type_point, pt2: Type_point): Type_point => {
    return {
        x: pt1.x + pt2.x,
        y: pt1.y + pt2.y,
    };
};
