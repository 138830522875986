import { Divider, Stack, Typography } from "@mui/material";
import {
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridValidRowModel,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import { useEffect, useState } from "react";

import {
    mutationCreateSequenceArea,
    mutationDeleteSequenceArea,
    mutationUpdateSequenceAreasForMatrix,
    useIndexSequenceAreasForMatrix,
} from "src/api/tms-scheduling/sequenceAreas";
import { Type_index_sequenceArea } from "src/api/tms-scheduling/sequenceAreas/types";
import { TMC_Button } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    getDifferentAttributes,
    Matrix,
} from "src/components/Components_Common/matrix/Matrix";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { MatrixSequenceAreasColumns } from "./MatrixSequenceAreasColumns";
import {
    formatAreasForTable,
    getMaxDepth,
    getMaxOrder,
    Type_Matrix_SequenceArea_Row,
} from "./MatrixSequenceAreasHelpers";

type Type_Props_TableSequenceAreas = {
    sequenceId: number;
};

export const MatrixSequenceAreas = ({
    sequenceId,
}: Type_Props_TableSequenceAreas) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Sequences.Table",
    );

    const [areas, setAreas] = useState<Type_Matrix_SequenceArea_Row[]>([]);
    const [maxDepth, setMaxDepth] = useState<number>(0);

    const [showSelected, setShowSelected] = useState<boolean>(false);
    const [manualOrder, setManualOrder] = useState<boolean>(false);

    // Fetch Sequence/Area Order
    const { data: sequenceAreasData, isFetching: isFetchingSequenceAreas } =
        useIndexSequenceAreasForMatrix(sequenceId, true);

    useEffect(() => {
        if (sequenceAreasData) {
            setMaxDepth(getMaxDepth(sequenceAreasData));
            setAreas(formatAreasForTable(sequenceAreasData));
        }
    }, [sequenceAreasData]);

    // Mutation to create a new Sequence Area Order
    const { mutateAsync: mutateCreateSequenceArea } =
        mutationCreateSequenceArea((seqArea: Type_index_sequenceArea) => {
            setAreas((prev) => {
                return prev.map((area) => {
                    if (area.id === seqArea.area_id) {
                        return {
                            ...area,
                            order: seqArea.order,
                            seqAreaId: seqArea.id,
                        };
                    }
                    return area;
                });
            });
        });

    // Mutation to update the order of an existing area in sequence
    const { mutateAsync: mutateUpdateSequenceArea } =
        mutationUpdateSequenceAreasForMatrix({
            callback: (seqArea: Type_index_sequenceArea) => {
                setAreas((prev) => {
                    return prev.map((area) => {
                        if (area.id === seqArea.area_id) {
                            return {
                                ...area,
                                order: seqArea.order,
                                seqAreaId: seqArea.id,
                            };
                        }
                        return area;
                    });
                });
            },
            showToast: false,
            shouldInvalidateQueries: false,
            withData: true,
        });

    const { mutateAsync: mutateDeleteSequenceArea } =
        mutationDeleteSequenceArea((id) => {
            setAreas((prev) => {
                return prev.map((area) => {
                    if (area.seqAreaId === id) {
                        return { ...area, seqAreaId: null, selected: false };
                    }
                    return area;
                });
            });
        });

    const areaSeqColumns = MatrixSequenceAreasColumns(maxDepth, manualOrder);

    /**
     * Function to handle row changes in the matrix sequence
     * @param { Type_Matrix_SequenceArea_Row[] } updatedRows
     * @param { string } columnKey
     * @param { boolean } autoOrder
     * @param { Type_Matrix_SequenceArea_Row[] } _areas
     * @returns {Promise<void>}
     */
    const onRowsChange = async (
        updatedRows: Type_Matrix_SequenceArea_Row[],
        columnKey: string,
        autoOrder: boolean = false,
        _areas: any[] = [],
    ): Promise<void> => {
        // Find the maximum order value in the areas
        let maxOrder = getMaxOrder(areas);

        // Update an existing sequence area with a specified order
        const updateSequenceArea = async (
            row: Type_Matrix_SequenceArea_Row,
            order: number,
        ) => {
            // Update the local state of areas with the new order
            setAreas((prev) => {
                return prev.map((area) => {
                    if (area.id === row.id) {
                        return {
                            ...area,
                            order: +order,
                        };
                    }
                    return area;
                });
            });

            // Update the sequence area in the backend if it exists
            if (row.seqAreaId) {
                await mutateUpdateSequenceArea({
                    id: row.seqAreaId,
                    data: { order: +order },
                });
            }
        };

        // Create a new sequence area with an optional order value
        const createSequenceArea = async (
            row: Type_Matrix_SequenceArea_Row,
            order?: number | null,
        ) => {
            // If order is specified, update the local state
            if (order) {
                setAreas((prev) => {
                    return prev.map((area) => {
                        if (area.id === row.id) {
                            return {
                                ...area,
                                order: +order,
                            };
                        }
                        return area;
                    });
                });
            }

            // Create a new sequence area in the backend
            await mutateCreateSequenceArea({
                area_id: row.id,
                sequence_id: sequenceId,
                ...(typeof order === "number" && !isNaN(order)
                    ? { order: +order }
                    : {}),
            });
        };

        // Process each updated row in the list
        for (const row of updatedRows) {
            const rowOrder = row.order;
            const seqAreaId = row.seqAreaId;

            const selectedColumn = columnKey === "selected";
            const orderColumn = columnKey === "order";

            // Handle selection column updates
            if (selectedColumn) {
                // If the row is selected
                if (row.selected) {
                    if (manualOrder || autoOrder) {
                        if (autoOrder && seqAreaId) {
                            maxOrder = maxOrder + 1;
                            updateSequenceArea(row, maxOrder);
                        } else {
                            createSequenceArea(row);
                        }
                    } else {
                        maxOrder = maxOrder + 1;
                        createSequenceArea(row, maxOrder);
                    }
                } else if (!row.selected) {
                    // If the row is unselected, adjust the order
                    if (rowOrder) {
                        row.order = null;
                        setAreas((prev) => {
                            return prev.map((area) => {
                                if (area.order && area.order > rowOrder) {
                                    area.order = +area.order - 1;
                                }
                                return area;
                            });
                        });

                        // Update the order of other areas on the backend
                        for (const _area of _areas) {
                            if (_area.order > rowOrder) {
                                await mutateUpdateSequenceArea({
                                    id: _area.seqAreaId,
                                    data: {
                                        order: +_area.order - 1,
                                    },
                                });
                            }
                        }
                    }

                    // Delete the sequence area if it exists
                    if (seqAreaId) {
                        await mutateDeleteSequenceArea(seqAreaId);
                    }
                }
            } else if (orderColumn && manualOrder) {
                // Handle order column updates with manual ordering
                maxOrder = getMaxOrder(_areas || []);
                if (rowOrder == null || isNaN(+rowOrder)) {
                    row.selected = false;
                } else {
                    row.selected = true;

                    const orderAlreadyExist = _areas.find(
                        (area) => area.order == rowOrder,
                    );

                    if (orderAlreadyExist) {
                        if (orderAlreadyExist.id === row.id) {
                            return;
                        }

                        // Check if current cell is empty or has an order
                        const prevOrder = _areas.find(
                            (area) => area.id === row.id,
                        ).order;

                        // Swap orders if the cell already contains an order
                        if (prevOrder && seqAreaId) {
                            updateSequenceArea(row, +rowOrder);
                            updateSequenceArea(orderAlreadyExist, prevOrder);
                        } else {
                            // Create and update the sequence area and order
                            createSequenceArea(row, +rowOrder);
                            for (const _area of areas) {
                                if (_area.order && _area.order >= rowOrder) {
                                    updateSequenceArea(_area, +_area.order + 1);
                                }
                            }
                        }
                    } else if (row.order) {
                        const currentArea = _areas.find(
                            (area) => area.id == row.id,
                        );

                        if (currentArea.order) {
                            // Adjust order if the current area order matches max order
                            if (currentArea.order == maxOrder) {
                                _areas.map((area) => {
                                    if (area.id === row.id) {
                                        row.order = maxOrder;
                                        area.order = maxOrder;
                                    }
                                    return area;
                                });
                            } else if (rowOrder > maxOrder) {
                                row.order = maxOrder;
                                const areaWithMaxOrder = _areas.findLast(
                                    (area) => area.order === maxOrder,
                                );
                                updateSequenceArea(row, maxOrder);
                                if (areaWithMaxOrder) {
                                    updateSequenceArea(
                                        areaWithMaxOrder,
                                        currentArea.order,
                                    );
                                }
                            }
                        } else {
                            // Set order to max + 1 if no current order exists
                            row.order = maxOrder + 1;
                            await mutateCreateSequenceArea({
                                area_id: row.id,
                                sequence_id: sequenceId,
                                order: +row.order,
                            });
                        }
                    }
                }
            }
        }
    };

    /**
     * Toggles the selection of areas.
     * If 'showSelected' is true, shows only selected areas, otherwise shows all areas.
     * @returns {void}
     */
    const toggleSelection = (): void => {
        setShowSelected(!showSelected);
    };

    /**
     * This function establishes an order for selected areas that do not already have one.
     * @returns {void}
     */
    const autoOrder = (): void => {
        const onlySelectedArea = areas.filter(
            (area) => area.selected && !area.order,
        );

        const _clonedAreas = _.cloneDeep(areas);

        onRowsChange(onlySelectedArea, "selected", true, _clonedAreas);
    };

    const CustomToolbar = () => {
        const { formatMessageWithPartialKey: fmtTable } =
            useCoreIntl("Table.Action");

        return (
            <GridToolbarContainer sx={{ mb: 3, px: 6 }}>
                <Stack
                    width="100%"
                    display={"flex"}
                    direction="row"
                    justifyContent={"space-between"}
                >
                    <Stack
                        display={"flex"}
                        direction="row"
                        alignItems={"center"}
                        spacing={2}
                    >
                        <GridToolbarQuickFilter
                            placeholder={fmtTable("SearchPlaceHolder")}
                            sx={(theme) => ({
                                borderBottom: theme.border.default,
                            })}
                        />
                        <GridToolbarFilterButton
                            slotProps={{
                                button: {
                                    variant: "text",
                                },
                            }}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton
                            data-testid={`AreaSequence_Matrix_${showSelected ? "hide" : "show"}_btn`}
                            onClick={toggleSelection}
                            size="small"
                        >
                            <Stack
                                spacing={1}
                                direction={"row"}
                                alignItems={"center"}
                            >
                                <Icon
                                    icon={showSelected ? "eye-slash" : "eye"}
                                    variant="light"
                                    fontSize="medium"
                                />
                            </Stack>
                        </IconButton>

                        {/* ManualSelection & AutoOrder */}
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            spacing={2}
                        >
                            <TMC_Button
                                data-testid="AreaSequence_Matrix_Selection_btn"
                                onClick={() => setManualOrder(!manualOrder)}
                                color={!manualOrder ? "primary" : "secondary"}
                            >
                                <Icon
                                    variant="light"
                                    icon={"arrow-up-right-dots"}
                                />
                            </TMC_Button>

                            <Divider
                                textAlign="center"
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />

                            <TMC_Button
                                data-testid="AreaSequence_Matrix_AutoOrder_btn"
                                onClick={() => autoOrder()}
                                variant="text"
                            >
                                <Stack
                                    direction="row"
                                    alignItems={"center"}
                                    color={"text.primary"}
                                    spacing={2}
                                >
                                    <Icon
                                        variant="light"
                                        icon={"wand-magic-sparkles"}
                                    />
                                    <Typography variant="body1">
                                        {fmt("AutoOrder")}
                                    </Typography>
                                </Stack>
                            </TMC_Button>
                        </Stack>
                    </Stack>
                </Stack>
            </GridToolbarContainer>
        );
    };

    const onRowUpdate = async (
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel,
    ) => {
        const diff = getDifferentAttributes(newRow, oldRow);
        const updatedRows = filteredAreasRows.findLast(
            (item) => item.id === diff.id,
        );

        const _areas = _.cloneDeep(areas);

        if (updatedRows) {
            if ("selected" in diff) {
                updatedRows.selected = diff.selected;
                await onRowsChange([updatedRows], "selected", false, _areas);
            }
            if ("order" in diff) {
                updatedRows.order = diff.order;
                await onRowsChange([updatedRows], "order", false, _areas);
            }
        }

        return newRow;
    };

    const filteredAreasRows = showSelected
        ? areas.filter(({ selected }) => selected)
        : areas;

    return (
        <Stack mt={5} spacing={5}>
            <Matrix
                loading={isFetchingSequenceAreas}
                processRowUpdate={onRowUpdate}
                columns={areaSeqColumns}
                rows={filteredAreasRows}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Stack>
    );
};
