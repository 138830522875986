import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";

export const usersAndCompaniesCategoriesList: Type_sidebar_configuration = {
    nameLabel: "UsersAndCompanies",
    baseUrl: "/users-and-companies",
    blocks: [
        {
            categories: [
                {
                    nameLabel: "Users",
                    urlName: "users",
                    permissionKeys: ["userInvitations_index", "users_index"],
                    variant: "light",
                    icon: "people-group",
                    contentType: "table",
                },
                {
                    nameLabel: "Companies",
                    urlName: "companies",
                    permissionKeys: ["companies_index"],
                    variant: "light",
                    icon: "building",
                    contentType: "table",
                },
            ],
        },
    ],
};
