import React, { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import IconSelectGeneric, {
    Type_Props_IconSelect as Type_Props_IconSelectGeneric,
} from "src/components/Components_Common/forms/IconSelect/IconSelect";

type Type_Props_IconSelect = Type_Props_IconSelectGeneric;

export const IconSelect = forwardRef(
    ({ name, ...props }: Type_Props_IconSelect, ref) => {
        const { formState, control } = useFormContext();

        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <IconSelectGeneric
                        {...props}
                        {...field}
                        inputRef={ref}
                        error={!!formState.errors[name]}
                    />
                )}
            />
        );
    },
);

IconSelect.displayName = "IconSelect";
