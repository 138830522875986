import React, { useState } from "react";

import {
    mutationDeleteTimeModel,
    mutationStatusTimeModel,
    useIndexTimeModels,
} from "src/api/tms-commons/timeModels";
import {
    Type_index_timeModel,
    Type_put_timeModel,
} from "src/api/tms-commons/timeModels/types";
import { Page_TimeModelsComponent } from "src/pages/Pages_Teamoty/Configuration/Page_TimeModels/Page_TimeModels.component";

export type Type_modalDeleteTimeModel = {
    isOpen: boolean;
    props?: Type_index_timeModel;
};

export const Page_TimeModelsContainer = () => {
    const { isFetching, data } = useIndexTimeModels() || {};

    // DELETE
    const [modalDeleteTimeModel, setModalDeleteTimeModel] =
        useState<Type_modalDeleteTimeModel>({
            isOpen: false,
        });

    const { mutate: useMutateDeleteTimeModel } =
        mutationDeleteTimeModel() || {};

    const removeTimeModel = (id: number) => {
        useMutateDeleteTimeModel(id);
    };

    // STATUS
    const { mutate: useMutationStatusTimeModel } =
        mutationStatusTimeModel() || {};

    const changeStatusTimeModel = (timeModel: Type_index_timeModel) => {
        useMutationStatusTimeModel({
            id: timeModel.id,
            enabled: !timeModel.enabled,
        } as Type_put_timeModel);
    };

    return (
        <Page_TimeModelsComponent
            isFetching={isFetching}
            timeModels={data || []}
            changeStatusTimeModel={changeStatusTimeModel}
            removeTimeModel={removeTimeModel}
            modalDeleteTimeModel={modalDeleteTimeModel}
            setModalDeleteTimeModel={setModalDeleteTimeModel}
        />
    );
};
