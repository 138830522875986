import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ResourceModelKeys } from "src/api/tms-commons/keys";
import {
    formatterCreateResourceModel,
    formatterIndexResourceModel,
    formatterIndexResourceModels,
    formatterSelectListResourceModels,
    formatterShowResourceModel,
    formatterStatusResourceModel,
    formatterUpdateResourceModel,
} from "src/api/tms-commons/resourceModels/formatters";
import {
    createResourceModel,
    deleteResourceModel,
    indexResourceModels,
    selectListResourceModels,
    showResourceModel,
    statusResourceModel,
    updateResourceModel,
} from "src/api/tms-commons/resourceModels/services";
import {
    Type_post_resourceModel,
    Type_put_resourceModel,
    Type_show_resourceModel,
} from "src/api/tms-commons/resourceModels/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexResourceModels = () => {
    return useQuery({
        queryKey: [ResourceModelKeys.INDEX],
        queryFn: indexResourceModels,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexResourceModels");
            }
            return formatterIndexResourceModels(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useShowResourceModel = (id: number) => {
    return useQuery(
        [ResourceModelKeys.SHOW, id],
        () => showResourceModel(id || 0),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Wrong format data: useShowResourceModel");
                }

                return formatterShowResourceModel(data.data.data);
            },
            onError: (err) => {
                return err;
            },
        },
    );
};

export const mutationCreateResourceModel = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceModel",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newResourceModel: Type_post_resourceModel) =>
            createResourceModel(formatterCreateResourceModel(newResourceModel)),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateResourceModel",
                );
            }

            const resourceModel = formatterIndexResourceModel(data.data.data);

            await queryClient.invalidateQueries([ResourceModelKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        resourceModel: resourceModel.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateResourceModel = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceModel",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (resourceModelToUpdate: Type_show_resourceModel) =>
            updateResourceModel(
                formatterUpdateResourceModel(resourceModelToUpdate),
                resourceModelToUpdate.id,
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateResourceModel",
                );
            }

            const resourceModel = formatterIndexResourceModel(data.data.data);

            await queryClient.invalidateQueries([ResourceModelKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        resourceModel: resourceModel.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusResourceModel = () => {
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: Type_put_resourceModel) => {
            return statusResourceModel(
                formatterStatusResourceModel(newStatus),
                newStatus.id,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusResourceModel",
                );
            }
            await queryClient.invalidateQueries([ResourceModelKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};

export const useSelectListResourceModels = () => {
    return useQuery({
        queryKey: [ResourceModelKeys.SELECT_LIST],
        queryFn: selectListResourceModels,
        refetchOnWindowFocus: false,
        select: (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useSelectListResourceModels",
                );
            }

            return formatterSelectListResourceModels(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const mutationDeleteResourceModel = () => {
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ResourceModel",
    );

    return useMutation({
        mutationFn: (id: number) => deleteResourceModel(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationDeleteResourceModel",
                );
            }

            await queryClient.invalidateQueries([ResourceModelKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
