import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import * as Yup from "yup";

import {
    mutationCreateSubProject,
    mutationUpdateSubProject,
    useShowSubProject,
} from "src/api/tms-projects/subProjects";
import {
    Type_post_subProject,
    Type_put_subProject,
} from "src/api/tms-projects/subProjects/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT, useManageErrors } from "src/configurations/errorsLabels";
import { useProject } from "src/contexts/project";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_SubProjectForm = {
    onClose: () => void;
    subProjectIdToUpdate: number | null;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_SubProject = Yup.lazy(() => {
    return Yup.object().shape({
        name: Yup.string()
            .trim()
            .required(FORM_ERR_FMT.REQUIRED)
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .trim(),
    });
});

export const SubProjectForm = ({
    onClose,
    subProjectIdToUpdate,
    action,
    setIsLoading,
}: Type_Props_SubProjectForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubProject");
    const { mutateAsync: mutateCreate } = mutationCreateSubProject() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateSubProject(
        subProjectIdToUpdate as number,
    );
    const { handleFormErrors } = useManageErrors();

    const { project } = useProject();

    const { isFetching, data } = useShowSubProject(
        project?.id as number,
        subProjectIdToUpdate as number,
    );

    const form = useTmcForm<Type_put_subProject | Type_post_subProject>({
        ...useFormDefaultConfig,
        defaultValues: {
            name: "",
        },
        values: {
            name: data?.name,
        },
        resolver: yupResolver<any>(Schema_SubProject),
        onClose,
        setIsLoading,
    });

    const handleSubmit = async (values: any) => {
        try {
            if (action === "update") {
                await mutateUpdate(values as Type_put_subProject);
            } else {
                delete values.id;
                await mutateCreate(values as Type_post_subProject);
            }

            // Fermeture du drawer
            onClose();
        } catch (e: any) {
            handleFormErrors(e, form);
            console.error("Error when creating or updating subproject");
        }
    };

    return (
        <TmcFormProvider form={form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"subProject"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <TextField
                        label={fmt("Name")}
                        data-testid="Project-SubProjects-Drawer-name"
                        {...form.register("name")}
                    />
                )}
            </form>
        </TmcFormProvider>
    );
};
