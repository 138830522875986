import { DrawerProps } from "@mui/material";

import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

import { PlanningFiltersForm } from "./PlanningFiltersForm";

type Type_Props_PlanningFiltersContextualDrawer = DrawerProps & {
    onClose: () => void;
};

export const PlanningFiltersContextualDrawer = ({
    onClose,
}: Type_Props_PlanningFiltersContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout id="planningFilters">
            <PlanningFiltersForm onClose={onClose} />
        </Styled_PaperContextualLayout>
    );
};
