import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ViewKeys, WorkspaceKeys } from "src/api/tms-projects/keys";
import {
    formatterIndexView,
    formatterShowView,
} from "src/api/tms-projects/workspacesAndViews/views/formatters";
import {
    createView,
    showView,
    updateView,
} from "src/api/tms-projects/workspacesAndViews/views/services";
import {
    Type_prj_post_view,
    Type_prj_put_view,
} from "src/api/tms-projects/workspacesAndViews/views/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const mutationCreateView = () => {
    const { requestConfig } = useProject();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.DefaultViews.Modal",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (view: Type_prj_post_view) =>
            createView(view, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationCreateView");
            }
            const formattedData = formatterIndexView(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        view: formattedData.name,
                    },
                }),
            });
            await queryClient.invalidateQueries([WorkspaceKeys.SHOW]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useShowView = (viewId?: number) => {
    const { requestConfig } = useProject();
    return useQuery(
        [ViewKeys.SHOW, viewId, requestConfig],
        () => showView(viewId as number, requestConfig),
        {
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Error format data: useShowView");
                }

                return formatterShowView(data.data.data);
            },
            enabled: !!requestConfig.projectId && !!viewId,
            refetchOnWindowFocus: false,
        },
    );
};

export const mutationUpdateView = (id?: number) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (view: Type_prj_put_view) =>
            updateView(id!, view, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationCreateView");
            }
            await queryClient.invalidateQueries([ViewKeys.SHOW]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};
