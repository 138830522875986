import { DrawerProps } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { CSSProperties } from "react";

import { TMC_Drawer } from "src/components/Components_Common/_MuiComponentsVariants";
import { DrawerAnchorType, useDrawer } from "src/contexts/drawer";

/*
    COMPOSANT DRAWER DE MATERIAL-UI
    Lien exemple: https://mui.com/material-ui/react-drawer/
    Lien API : https://mui.com/material-ui/api/drawer/
*/

export type DrawerComponentPropsType = {
    options: DrawerProps; // Tous les props natives du drawer de mui.
    children?: JSX.Element | null; // Element du DOM à afficher dans le drawer
    style?: CSSProperties;
};

export const DrawerComponent = ({
    children,
    options,
    style = { width: "auto", height: "auto", flexGrow: 1, overflow: "hidden" },
}: DrawerComponentPropsType) => {
    const { closeDrawer } = useDrawer();

    const toggleDrawer =
        (_anchor: DrawerAnchorType, _open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }
            closeDrawer();
        };

    return (
        <TMC_Drawer
            anchor={options?.anchor}
            open={options?.open}
            onClose={toggleDrawer(options?.anchor, false)}
            {...options}
        >
            <Box
                sx={style}
                //    onClick={toggleDrawer(options?.anchor, false)}
                //    onKeyDown={toggleDrawer(options?.anchor, false)}
            >
                {children}
            </Box>
        </TMC_Drawer>
    );
};
