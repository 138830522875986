import * as React from "react";
import { ComponentProps } from "react";

import { Type_Filter_License_Types } from "src/api/filters";
import { useSelectListLicenseTypes } from "src/api/tms-commons/licenseTypes";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteLicenseTypes = Omit<
    ComponentProps<typeof Autocomplete>,
    "options" | "isFetching"
> & {
    name: string;
    label: string;
    multiple?: boolean;
    filters?: Type_Filter_License_Types;
};

export const AutocompleteLicenseTypes = ({
    name,
    label,
    multiple,
    filters = {},
    ...props
}: Type_Props_AutocompleteLicenseTypes) => {
    const { data: licenseTypes, isFetching: isFetchingLicenseTypes } =
        useSelectListLicenseTypes(filters) || {};

    return (
        <Autocomplete
            name={name}
            label={label}
            multiple={multiple}
            defaultValue={multiple ? [] : null}
            options={licenseTypes || []}
            isFetching={isFetchingLicenseTypes}
            {...props}
        />
    );
};
