import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { LicenseKeys, ProjectKeys } from "src/api/tms-commons/keys";
import {
    formatterCreateLicense,
    formatterIndexLicense,
    formatterShowLicense,
    formatterUpdateLicense,
} from "src/api/tms-commons/licenses/formatters";
import {
    createLicense,
    deleteLicense,
    showLicense,
    updateLicense,
} from "src/api/tms-commons/licenses/services";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_index_license, Type_show_license } from "./types";

export const useShowLicense = (id: number) => {
    return useQuery({
        queryKey: [LicenseKeys.SHOW, id],
        enabled: !!id,
        queryFn: () => showLicense(id),
        refetchOnWindowFocus: false,
        select: (data): Type_show_license => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowLicense");
            }
            return formatterShowLicense(data.data.data);
        },
        onError: (err) => {
            console.log(err);
        },
    });
};

export const mutationUpdateLicense = (licenseIdToUpdate?: number) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Licenses");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (licenseToUpdate: Type_show_license) =>
            updateLicense(
                licenseIdToUpdate as number,
                formatterUpdateLicense(licenseToUpdate),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateLicense");
            }

            const newLicense: Type_index_license = formatterIndexLicense(
                data.data.data,
            );

            await queryClient.invalidateQueries([
                ProjectKeys.SHOW,
                requestConfig.projectId,
            ]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        license: newLicense.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCreateLicense = (projectIdToUpdate: number) => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Licenses");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (license: Type_show_license) =>
            createLicense(formatterCreateLicense(license), projectIdToUpdate),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateLicense");
            }

            await queryClient.invalidateQueries([
                ProjectKeys.SHOW,
                projectIdToUpdate,
            ]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        license: data.data.data.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteLicense = () => {
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Licenses");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (licenseIdToDelete: number) => {
            return deleteLicense(licenseIdToDelete);
        },
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteLicense");
            }

            await queryClient.invalidateQueries([
                ProjectKeys.SHOW,
                requestConfig.projectId,
            ]);

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};
