import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { TradeForm } from "src/forms/trades/TradeForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_TradeDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    tradeId: number | null;
    action: Type_action;
    page: string;
    tradesColors?: string[];
};

export const TradeDrawer = ({
    open,
    onClose,
    tradeId,
    action,
    page,
    tradesColors,
}: Type_Props_TradeDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Trades");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`Trade-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            isFormLoading={isLoading}
            formId={"trade"}
            page={page}
        >
            <TradeForm
                onClose={onClose}
                tradeId={tradeId as number}
                setIsLoading={setIsLoading}
                action={action}
                tradesColors={tradesColors}
            />
        </DrawerGeneric>
    );
};
