import { GridColDef, GridRowModel } from "@mui/x-data-grid-pro";
import React from "react";

import {
    ColorPickerChip,
    TMC_ColorPicker,
} from "src/components/Components_Common/ColorPicker/TMC_ColorPicker";

export const ColorPickerColumn = ({ field, ...props }: GridColDef) => {
    return {
        ...props,
        field,
        renderEditCell: (params: GridRowModel) => {
            return (
                <TMC_ColorPicker
                    id={params.id}
                    name={field}
                    isMatrix={true}
                    currentColor={params.value}
                    callbackColor={(color) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: color,
                        });
                        params.api.stopCellEditMode({
                            id: params.id,
                            field: params.field,
                        });
                    }}
                    onBlur={() => {
                        params.api.stopCellEditMode({
                            id: params.id,
                            field: params.field,
                        });
                    }}
                    sx={{ padding: 0 }}
                />
            );
        },
        renderCell: (params: GridRowModel) => {
            return (
                <ColorPickerChip
                    id={params.id}
                    color={params.value}
                    name={params.field}
                />
            );
        },
    };
};
