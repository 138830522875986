import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { LicensesTypesForm } from "src/forms/licensesTypes/LicensesTypesForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_LicenseTypeDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    numberId: number | null;
    action: Type_action;
};

export const LicenseTypeDrawer = ({
    open,
    onClose,
    numberId,
    action,
}: Type_Props_LicenseTypeDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.LicenseTypes",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`LicenseTypes-Drawer-${action}-Title`}
            title={
                action === "update" ? fmt("TitleUpdate") : fmt("TitleCreate")
            }
            formId={"licenseTypes"}
            isFormLoading={isLoading}
            page="licenseTypes"
        >
            <LicensesTypesForm
                setIsLoading={setIsLoading}
                onClose={onClose}
                updateId={numberId === null ? undefined : numberId}
                action={action}
            />
        </DrawerGeneric>
    );
};
