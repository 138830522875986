import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";

export const myAccountCategoriesList: Type_sidebar_configuration = {
    nameLabel: "MyAccount",
    baseUrl: "/my-account",
    blocks: [
        {
            categories: [
                {
                    nameLabel: "MyProfile",
                    urlName: "my-profile",
                    permissionKeys: [],
                    variant: "light",
                    icon: "user",
                    contentType: "section",
                },
            ],
        },
    ],
};
