import { CheckboxGroup as checkbox } from "src/components/Components_Common/forms/reactHookFormComponents/CheckboxGroup/CheckboxGroup";
import { DatePicker as date } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { RadioGroup as radio } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { Select as select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField as text } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";

export const FormComponents: any = {
    // inputMultiple,
    // inputPhone,
    date,
    text,
    // inputEmail,
    // inputNumber,
    // inputPassword,
    // inputPeriod,
    // inputStatic,
    // inputTime,
    // inputDateRange,
    // inputDateTime,
    // inputRegex,
    // inputDateTimeRange,
    // inputPasswordConfirm,
    select,
    // inputMultipleSelect,
    checkbox,
    radio,
    // inputAnchor,
    // inputButton,
    // inputSubmit,
    // inputCancel,
};
