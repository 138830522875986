import { Components } from "@mui/material";

export const MuiDateRangePickerDayTheme: Components["MuiDateRangePickerDay"] = {
    styleOverrides: {
        // TODO: should be removed when we found why that class doesn't exist on Mui default theme
        rangeIntervalDayHighlight: ({ theme }: any) => ({
            backgroundColor: theme.palette.primary.light,
        }),
    },
};
