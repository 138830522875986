import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    Box,
    Checkbox,
} from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

import { useSelectListProjectCompanies } from "src/api/tms-projects/projectCompanies";
import { Type_selectList } from "src/api/types";
import {
    TMC_Chip,
    TMC_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants";
import { Icon } from "src/components/Components_Common/Icon/Icon";

type Type_Props_AutocompleteCompanies = {
    name: string;
    label: string;
};

export const AutocompleteCompanies = ({
    name,
    label,
}: Type_Props_AutocompleteCompanies) => {
    const formik: any = useFormikContext();
    const icon: React.ReactElement = <Icon variant="light" icon="square" />;
    const checkedIcon: React.ReactElement = (
        <Icon variant="solid" icon="square-check" />
    );

    const { isFetching: isFetchingCompanies, data: companies } =
        useSelectListProjectCompanies() || {};

    return (
        <Autocomplete
            data-testid={`autocomplete-companies-${name}`}
            id={`autocomplete-companies-${name}`}
            multiple
            loading={isFetchingCompanies}
            options={(companies as unknown as Type_selectList[]) || []}
            onChange={(e, value: any[]) => formik.setFieldValue(name, value)}
            value={formik.values[name] || []}
            disableCloseOnSelect
            isOptionEqualToValue={(option, selectedValues): boolean =>
                option?.id === selectedValues.id
            }
            getOptionLabel={(option) => option?.name}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TMC_TextField
                    {...params}
                    name={name}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        "data-testid": `autocomplete-companies-${name}-input`,
                    }}
                    error={formik.touched[name] && Boolean(formik.errors[name])}
                    helperText={formik.touched[name] && formik.errors[name]}
                />
            )}
            renderOption={(props, option) => (
                <li
                    {...props}
                    key={option.id}
                    data-testid={`autocomplete-companies-${name}-input-${option.name}`}
                >
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                            !!formik?.values[name]?.find(
                                (company: any): boolean =>
                                    company?.id === option.id,
                            )
                        }
                    />
                    {option?.name}
                </li>
            )}
            renderTags={(
                tagValue: any[],
                getTagProps: AutocompleteRenderGetTagProps,
            ) =>
                tagValue.map((option, index: number) => (
                    <Box
                        key={index}
                        data-testid={
                            option.name &&
                            `AutocompleteCompanies-option-${option.name.replaceAll(
                                " ",
                                "_",
                            )}`
                        }
                    >
                        <TMC_Chip
                            label={option.name}
                            {...getTagProps({ index })}
                        />
                    </Box>
                ))
            }
            componentsProps={{
                clearIndicator: {
                    // @ts-expect-error data-testid are not on IconButtonProps
                    "data-testid": `autocomplete-companies-clearButton-${name}`,
                },
            }}
        />
    );
};
