import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { confirmMail } from "src/api/tms-users/auth/services";

import { Page_ConfirmMailComponent } from "./Page_ConfirmMail.component";

export const Page_ConfirmMailContainer = () => {
    const { id, hash } = useParams();

    useEffect(() => {
        const verifyMail = async () => {
            await confirmMail(id || "", hash || "");
        };

        verifyMail();
    }, []);

    return <Page_ConfirmMailComponent />;
};
