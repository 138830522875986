import { ChipTypeMap } from "@mui/material";
import React, { useState } from "react";

import { Type_selectList_company } from "src/api/tms-projects/projectCompanies/types";
import { useShowSubTrade } from "src/api/tms-projects/subTrades";
import { Type_selectList } from "src/api/types";
import {
    Autocomplete,
    Type_Props_Autocomplete,
} from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { MEDIUM_STALE_TIME } from "src/configurations/app";

type Type_Props_AutocompleteSubTradeCompanies<
    T extends Type_selectList,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    Type_Props_Autocomplete<
        T,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
    >,
    | "isFetching"
    | "multiple"
    | "options"
    | "getOptionLabel"
    | "renderTags"
    | "componentsProps"
> & {
    subTradeId?: number;
    loadDataOnOpen?: boolean;
};

export const AutocompleteSubTradeCompanies = ({
    subTradeId,
    name,
    label,
    loadDataOnOpen = true,
    ...props
}: Type_Props_AutocompleteSubTradeCompanies<
    Type_selectList_company,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
>) => {
    ///////////////////////////////
    /// States                  ///
    ///////////////////////////////

    const [open, setOpen] = useState<boolean>(false);
    const shouldLoadData = open || !loadDataOnOpen;

    ///////////////////////////////
    /// Queries                 ///
    ///////////////////////////////

    const { isFetching: isFetchingCompanies, data } = useShowSubTrade(
        subTradeId,
        shouldLoadData,
        MEDIUM_STALE_TIME,
    );

    return (
        <Autocomplete
            {...props}
            name={name}
            label={label}
            options={data?.companies ?? []}
            openOnFocus
            componentsProps={{
                clearIndicator: {
                    // @ts-expect-error data-testid are not on IconButtonProps
                    "data-testid": `autocomplete-companies-clearButton-${name}`,
                },
            }}
            // Controlled loading mode
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={isFetchingCompanies}
        />
    );
};
