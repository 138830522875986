import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
    formatterIndexCauseOfDelay,
    formatterIndexCausesOfDelay,
    formatterPostCauseOfDelay,
    formatterPutCauseOfDelay,
    formatterShowCauseOfDelay,
    formatterStatusCauseOfDelay,
} from "src/api/tms-commons/causesOfDelay/formatters";
import {
    createCauseOfDelay,
    deleteCauseOfDelay,
    indexCausesOfDelay,
    showCauseOfDelay,
    statusCauseOfDelay,
    updateCauseOfDelay,
} from "src/api/tms-commons/causesOfDelay/services";
import {
    Type_post_causeOfDelay,
    Type_put_causeOfDelay,
    Type_show_causeOfDelay,
} from "src/api/tms-commons/causesOfDelay/types";
import { CauseOfDelayKeys } from "src/api/tms-commons/keys";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexCausesOfDelay = () => {
    return useQuery({
        queryKey: [CauseOfDelayKeys.INDEX],
        queryFn: indexCausesOfDelay,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexCausesOfDelay");
            }
            return formatterIndexCausesOfDelay(data.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const mutationStatusCauseOfDelay = () => {
    const queryClient = useQueryClient();

    const { addWarning } = useToast();

    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: Type_put_causeOfDelay) => {
            return statusCauseOfDelay(
                formatterStatusCauseOfDelay(newStatus),
                newStatus.id as number,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusCauseOfDelay",
                );
            }
            await queryClient.invalidateQueries([CauseOfDelayKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCreateCauseOfDelay = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.CausesOfDelay",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const { addSuccess, addWarning } = useToast();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newCauseOfDelay: Type_post_causeOfDelay) =>
            createCauseOfDelay(formatterPostCauseOfDelay(newCauseOfDelay)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateCauseOfDelay",
                );
            }
            const causeOfDelay = formatterIndexCauseOfDelay(data.data.data);

            await queryClient.invalidateQueries([CauseOfDelayKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        cause: causeOfDelay.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateCauseOfDelay = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.CausesOfDelay",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    const { addSuccess, addWarning } = useToast();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (causeOfDelay: Type_show_causeOfDelay) =>
            updateCauseOfDelay(
                causeOfDelay.id,
                formatterPutCauseOfDelay(causeOfDelay),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateCauseOfDelay",
                );
            }
            const causeOfDelay = formatterIndexCauseOfDelay(data.data.data);

            await queryClient.invalidateQueries([CauseOfDelayKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        cause: causeOfDelay.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useShowCauseOfDelay = (id: number) => {
    return useQuery([CauseOfDelayKeys.SHOW, id], () => showCauseOfDelay(id), {
        select: (res) => {
            if (!res?.success || !res?.data?.data) {
                throw new Error("Wrong format data: useIndexCausesOfDelay");
            }

            return formatterShowCauseOfDelay(res.data.data);
        },
        onError: (err) => {
            return err;
        },
        enabled: !!id,
        refetchOnWindowFocus: false,
    });
};

export const mutationDeleteCauseOfDelay = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.CausesOfDelay",
    );

    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => deleteCauseOfDelay(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationDeleteCauseOfDelay",
                );
            }
            await queryClient.invalidateQueries([CauseOfDelayKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
