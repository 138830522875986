import { taskTypeEnum } from "@cimba-digital-construction-solution/utils/dist/tools/taskType.enum";

import { Type_RegularItem } from "src/components/Components_Common/forms/IconSelect/IconSelect";
import { Type_Props_Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_useTaskType = {
    iconKitVariant?: "light" | "solid";
};

export const useTaskType = ({
    iconKitVariant = "light",
}: Type_Props_useTaskType) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.TaskType",
    );

    const taskTypeList: Type_RegularItem[] = [
        {
            label: fmt("Work"),
            slug: taskTypeEnum.work,
            value: 1,
            icon: {
                variant: "kit",
                icon: `${iconKitVariant}-tds-task-work`,
            },
        },
        {
            label: fmt("Study"),
            slug: taskTypeEnum.study,
            value: 2,
            icon: {
                variant: "kit",
                icon: `${iconKitVariant}-tds-task-study`,
            },
        },
        {
            label: fmt("Waiting"),
            slug: taskTypeEnum.waiting,
            value: 3,
            icon: {
                variant: "kit",
                icon: `${iconKitVariant}-tds-task-waiting`,
            },
        },
        {
            label: fmt("Delivery"),
            slug: taskTypeEnum.delivery,
            value: 5,
            icon: {
                variant: "kit",
                icon: `${iconKitVariant}-tds-task-delivery`,
            },
        },
        {
            label: fmt("Milestone"),
            slug: taskTypeEnum.milestone,
            value: 4,
            icon: {
                variant: "kit",
                icon: `${iconKitVariant}-tds-task-milestone`,
            },
        },
        {
            label: fmt("Technical"),
            slug: taskTypeEnum.technical,
            value: 6,
            icon: {
                variant: "kit",
                icon: `${iconKitVariant}-tds-task-technical`,
            },
        },
    ];

    const getTaskTypeIcon = (
        type: number,
    ): { variant: string; icon: string } => {
        const taskType = taskTypeList.find((item) => item.value === type);
        return taskType?.icon as Type_Props_Icon;
    };

    return {
        taskTypeList,
        getTaskTypeIcon,
    };
};
