import * as React from "react";
import { useState } from "react";

import { SequenceNotes } from "src/forms/sequenceNotes/SequenceNotes";
import {
    Type_event_delete,
    Type_event_pert,
    useChannel,
} from "src/hooks/useChannel";
import {
    Styled_PaperContextualLayout,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_SequenceNotesContextualDrawer = {
    sequenceId: number;
};

export const SequenceNotesContextualDrawer = ({
    sequenceId: id,
}: Type_Props_SequenceNotesContextualDrawer) => {
    const { closePaper } = useContextualDrawer();
    const [sequenceId, setSequenceId] = useState<number>(id);

    useChannel({
        eventHandler: ({ event, data }) => {
            if (event === "openPert") {
                setSequenceId((data as Type_event_pert).id);
            }
        },
    });

    useChannel({
        eventHandler: ({ event, data: dataEvent }) => {
            if (event === "deleteSequence") {
                if ((dataEvent as Type_event_delete).id === sequenceId) {
                    closePaper("sequenceNotes");
                }
            }
        },
    });

    return (
        <Styled_PaperContextualLayout
            data-testid={`SequenceNotes-ContextualDrawer`}
        >
            <SequenceNotes sequenceId={sequenceId} />
        </Styled_PaperContextualLayout>
    );
};
