import { Group, Layer, Rect } from "react-konva";

import { Type_point } from "src/components/Components_Common/canvas/types";
import {
    colorFlowGrid,
    sizeFlowGridX,
    sizeFlowGridY,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { onGrid } from "src/components/Components_Teamoty/Flow/tools/onGrid";

import { Type_Props_FlowGrid } from "./FlowGrid.type";

export const FlowGrid = ({
    bounds,
    stageFlow,
    fill = colorFlowGrid,
    size = 2,
}: Type_Props_FlowGrid) => {
    const start: Type_point = onGrid({
        x: stageFlow.x / stageFlow.scale,
        y: stageFlow.y / stageFlow.scale,
    });

    const xx: number[] = Array.from(
        {
            length:
                Math.ceil(bounds.width / stageFlow.scale / sizeFlowGridX) + 1,
        },
        (v, i: number) => -start.x + i * sizeFlowGridX,
    );
    const yy: number[] = Array.from(
        {
            length:
                Math.ceil(bounds.height / stageFlow.scale / sizeFlowGridY) + 1,
        },
        (v, i: number) => -start.y + i * sizeFlowGridY,
    );

    const sizeReal: number = Math.min(2, size / stageFlow.scale);

    return (
        <Layer name="grid">
            {yy.map((y: number, indexY: number) => {
                {
                    return (
                        <Group key={"grid-" + indexY}>
                            {xx.map((x: number, indeX: number) => {
                                return (
                                    <Rect
                                        key={"grid-" + indexY + "-" + indeX}
                                        x={x}
                                        y={y}
                                        width={sizeReal}
                                        height={sizeReal}
                                        offsetX={sizeReal / 2}
                                        offsetY={sizeReal / 2}
                                        fill={fill}
                                        listening={false}
                                    />
                                );
                            })}
                        </Group>
                    );
                }
            })}
        </Layer>
    );
};
