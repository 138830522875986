import {
    Type_index_taskAreaNote,
    Type_post_taskAreaNote,
    Type_sch_index_taskAreaNote,
    Type_sch_post_taskAreaNote,
    Type_sch_put_taskAreaNote,
} from "src/api/tms-scheduling/taskAreaNotes/types";
import { Type_put_note } from "src/forms/notes/NoteForm";
import { formatDateTimeForFront } from "src/utils/date";

export const formatterIndexTaskAreaNotes = (
    taskAreaNotes: Type_sch_index_taskAreaNote[],
): Type_index_taskAreaNote[] => {
    return taskAreaNotes.map(formatterIndexTaskAreaNote);
};

export const formatterIndexTaskAreaNote = (
    taskAreaNote: Type_sch_index_taskAreaNote,
): Type_index_taskAreaNote => {
    const { date, ...rest } = taskAreaNote;

    return {
        ...rest,
        date: formatDateTimeForFront(date)!,
    };
};

export const formatterCreateTaskAreaNote = (
    taskAreaNote: Type_post_taskAreaNote,
): Type_sch_post_taskAreaNote => {
    return {
        text: taskAreaNote.text,
    };
};

export const formatterUpdateTaskAreaNote = (
    taskAreaNote: Type_put_note,
): Type_sch_put_taskAreaNote => {
    return {
        text: taskAreaNote.text,
    };
};
