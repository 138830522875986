import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

// **** MAIN CONTAINER
export const Styled_StackLoginMain = styled(Stack)(({ theme }: any) => ({
    flexDirection: "row",
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    height: "100vh",
    "@media (max-width: 1008px)": {
        padding: theme.spacing(2),
    },
}));

// **** CONTAINER DE GAUCHE
export const Styled_StackLoginLeft = styled(Stack)(() => ({
    flexDirection: "column",
    minWidth: "300px",
    width: "100%",
    "@media (max-width: 1008px)": {
        maxWidth: "100%",
    },
}));

export const Styled_StackHeaderLogin = styled(Stack)(() => ({
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80px",
}));

export const Styled_StackFooterLogin = styled(Stack)(() => ({
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "end",
}));

export const Styled_selectLangLogin = styled("select")(({ theme }: any) => ({
    padding: 0,
    border: "none",
    ...theme.typography.body3,
    backgroundImage: "none",
    paddingRight: "20px",
    cursor: "pointer",
    backgroundColor: "#f0f8ff00",
    zIndex: "50",
}));

export const Styled_optionSelectLangLogin = styled("option")(
    ({ theme }: any) => ({
        ...theme.typography.body3,
    }),
);

// **** CONTAINER DE DROITE
export const Styled_StackLoginRight = styled(Stack)(() => ({
    flexDirection: "column",
    maxWidth: "50%",
    width: "100%",
    overflow: "hidden",
    borderRadius: "16px",
    "@media (max-width: 1008px)": {
        display: "none",
    },
}));

export const Styled_imgLogin = styled("img")(() => ({
    width: "fit-content",
    maxWidth: "none",
    alignSelf: "center",
}));
