import { formatterMetadataUserToArray } from "src/api/tms-users/users/formatters";
import { USER_STATUS } from "src/api/tms-users/users/types";
import { Type_selectList } from "src/api/types";

import {
    Type_index_projectUser,
    Type_index_projectUserWithMeta,
    Type_post_projectUserInvitation,
    Type_prj_index_projectUser,
    Type_prj_index_projectUserWithMeta,
    Type_prj_post_projectUser,
    Type_prj_put_projectUser,
    Type_prj_select_projectUser,
    Type_prj_show_projectUser,
    Type_put_projectUser,
    Type_selectList_projectUser,
    Type_show_projectUser,
} from "./types";

export const formatterIndexProjectUsers = (
    projectUsers: Type_prj_index_projectUser[],
): Type_index_projectUser[] => {
    return projectUsers.map((projectUser: Type_prj_index_projectUser) => {
        return formatterIndexProjectUser(projectUser);
    });
};

export const formatterIndexProjectUser = (
    projectUser: Type_prj_index_projectUser,
): Type_index_projectUser => {
    return {
        id: projectUser.id,
        email: projectUser.user.email as string,
        name:
            projectUser.user.firstName && projectUser.user.lastName
                ? projectUser.user.firstName + " " + projectUser.user.lastName
                : "",
        company: projectUser.company,
        companyName: projectUser.company ? projectUser.company.name : "",
        role: projectUser.role,
        roleName: projectUser.role.name,
        subProject: projectUser.subProject,
        subProjectName:
            projectUser.subProject === null
                ? "All"
                : projectUser.subProject.name,
        status: projectUser.status,
        lastActionDate: projectUser.last_action_date,
        enabled: projectUser.enabled,
    };
};

export const formatterIndexProjectUsersWithMeta = (
    data: Type_prj_index_projectUserWithMeta,
): Type_index_projectUserWithMeta => {
    const projectUsers = formatterIndexProjectUsers(data?.data);
    return {
        projectUsers: projectUsers,
        total: data?.meta && data?.meta?.total,
    };
};

export const formatterSelectProjectUsers = (
    data: Type_prj_select_projectUser[],
): Type_selectList_projectUser[] => {
    return data.map((projectUser: Type_prj_select_projectUser) =>
        formatterProjectUser(projectUser),
    );
};

export const formatterProjectUser = (
    projectUser: Type_prj_select_projectUser,
): Type_selectList_projectUser => {
    return {
        id: projectUser.id,
        firstName: projectUser?.user?.firstName,
        lastName: projectUser?.user?.lastName,
        email: projectUser?.user?.email,
        metadata: formatterMetadataUserToArray(projectUser.user.metadata),
    };
};

export const formatterShowProjectUser = (
    projectUser: Type_prj_show_projectUser,
): Type_show_projectUser => {
    return {
        id: projectUser.id,
        firstName: projectUser.user.firstName || "_",
        lastName: projectUser.user.lastName || "_",
        email: projectUser.user.email,
        companyName: projectUser.company.name,
        phone: "Phone number",
        metadata: formatterMetadataUserToArray(projectUser.user.metadata),
    };
};
export const formatterAutoCompleteUser = (
    projectUsers: Type_selectList_projectUser[],
): Type_selectList[] => {
    return projectUsers.map((user: Type_selectList_projectUser) => {
        return {
            id: user.id,
            name: user.firstName + " " + user.lastName,
        };
    });
};

export const formatterCreateProjectUserInvitation = (
    data: Type_post_projectUserInvitation,
    userId: number,
): Type_prj_post_projectUser => {
    return {
        user_id: userId,
        status: USER_STATUS.INVITED,
        role_id: data.roleId,
        company_id: data.company,
    };
};

export const formatterUpdateProjectUser = (
    data: Type_put_projectUser,
): Type_prj_put_projectUser => {
    return {
        role_id: data.roleId,
        company_id: data.companyId,
        status: data.status,
        enabled: data.enabled,
    };
};
