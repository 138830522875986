import { Box, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import * as React from "react";
import {
    CSSProperties,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDrag, useDrop } from "react-dnd";

import { Type_RequestConfig } from "src/api/fetch";
import {
    mutationDeleteArea,
    mutationDuplicateArea,
    useIndexAreas,
} from "src/api/tms-projects/areas";
import {
    formatterDndArea,
    formatterSortArea,
} from "src/api/tms-projects/areas/formatters";
import { updateAreaOnDnd } from "src/api/tms-projects/areas/services";
import {
    Type_index_area,
    Type_select_area,
} from "src/api/tms-projects/areas/types";
import { Styled_AccordionChip } from "src/components/Components_Common/accordions/Accordion.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    DropdownMenu,
    LoadingBox,
    LoadingOrNoData,
} from "src/components/Components_Common/index";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { findValue } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreasCustom";
import { useProject } from "src/contexts/project";
import {
    Type_event_area,
    Type_event_message,
    useChannel,
} from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { COLORS } from "src/theme/stylesheet";

/* ****************************************************************************************************************** */
/* ****************************************************************************************************************** */

const ItemTypes = {
    ACCORDIONDND: "accordiondnd2",
};

/* ****************************************************************************************************************** */
/* ****************************************************************************************************************** */

const handleUpdateAreaOnDnd = async (
    id: number,
    sortOrder: number,
    requestConfig: Type_RequestConfig,
    parentId?: number | null,
): Promise<void> => {
    await updateAreaOnDnd(
        formatterDndArea({
            id: id,
            sortOrder: sortOrder,
            parentId: parentId,
        }),
        requestConfig,
    );
};

const handleUpdateAreaOnSort = async (
    id: number,
    sortOrder: number,
    requestConfig: Type_RequestConfig,
): Promise<void> => {
    await updateAreaOnDnd(
        formatterSortArea({
            id: id,
            sortOrder: sortOrder,
        }),
        requestConfig,
    );
};

function findTo(
    data: Type_index_area[],
    parentsId: number[],
): Type_index_area[] | undefined {
    if (parentsId.length) {
        const parent = parentsId.shift();

        const item: Type_index_area = data.filter(
            (item: Type_index_area) => item.id === parent,
        )[0];

        if (item) {
            return findTo(item.children, parentsId);
        }

        return undefined;
    }
    return data;
}

function recursiveMove(
    data: Type_index_area[],
    dragItem: DragItem,
    parentsId: number[],
    target: Type_index_area[],
    indexB: number,
    newParentId: number | undefined,
    requestConfig: Type_RequestConfig,
): void {
    if (dragItem?.parentsId.length) {
        const parent: number | undefined = dragItem.parentsId.shift();
        const item: Type_index_area | undefined = data.find(
            (item) => item.id == parent,
        );

        if (item) {
            recursiveMove(
                item.children,
                dragItem,
                parentsId,
                target,
                indexB,
                newParentId,
                requestConfig,
            );
        }

        return;
    }

    const item: Type_index_area = data.filter(
        (c: Type_index_area) => c.id === dragItem.id,
    )[0];
    const indexA: number = data.indexOf(item);

    data.splice(indexA, 1); // supprime l'élement de la liste de départ
    target.splice(indexB === -1 ? target.length : indexB, 0, item); // ajoute l'élement

    // console.log("update API remove", dragItem.id, indexB, newParentId);
    handleUpdateAreaOnDnd(dragItem.id, indexB, requestConfig, newParentId);
}

function move(
    data: Type_index_area[],
    dragItem: DragItem,
    parentsId: number[],
    indexB: number,
    requestConfig: Type_RequestConfig,
): void {
    const target = findTo(data, [...parentsId]);

    if (target) {
        recursiveMove(
            data,
            dragItem,
            parentsId,
            target,
            indexB,
            parentsId.pop(),
            requestConfig,
        );
    }
}

function sort(
    data: Type_index_area[],
    dragItem: DragItem,
    parentsId: number[],
    indexB: number,
    requestConfig: Type_RequestConfig,
): void {
    const target: Type_index_area[] | undefined = findTo(data, parentsId);

    if (target) {
        const item: Type_index_area = target.filter(
            (c: Type_index_area) => c.id === dragItem.id,
        )[0];
        const indexA: number = target.indexOf(item);

        target.splice(indexA, 1);
        target.splice(indexB === -1 ? target.length : indexB, 0, item);

        // console.log("update API sort", dragItem.id, indexB);
        handleUpdateAreaOnSort(dragItem.id, indexB, requestConfig);
    }
}

const equals = (a: number[], b: number[]) =>
    JSON.stringify(a) === JSON.stringify(b);

/* ****************************************************************************************************************** */
/* ****************************************************************************************************************** */

const styleOver: CSSProperties = {
    height: "7px",
    width: "100%",
    border: "none",
    zIndex: "100",
};

interface AccordionMoveDndProps {
    index: number;
    item: Type_index_area;
    moveData: any;
}

interface AccordionSummaryDnDProps {
    item: Type_index_area;
    menuItems: { label: ReactElement; call: (id: number) => void }[];
    moveData: any;
}

interface AccordionDnDProps {
    children: ReactNode;
    index: number;
    item: Type_index_area;
    moveData: any;
    menuItems: { label: ReactElement; call: (id: number) => void }[];
}

interface DragItem {
    // index: number
    parentsId: number[];
    id: number;
}

const AccordionMoveDnd: React.FC<AccordionMoveDndProps> = ({
    index,
    item,
    moveData,
}) => {
    // console.log('render move ', item.id)

    const [{ isOver }, drop] = useDrop<
        DragItem,
        void,
        {
            isOver: boolean;
        }
    >({
        // The type (or types) to accept - strings or symbols
        accept: ItemTypes.ACCORDIONDND,

        // Props to collect
        collect(monitor) {
            const itemDrag: DragItem = monitor.getItem();
            return {
                isOver:
                    monitor.isOver() &&
                    itemDrag.id !== item.id &&
                    item.parentsId !== undefined &&
                    item.parentsId.indexOf(itemDrag.id) === -1,
            };
        },

        canDrop(itemDrag: DragItem, monitor) {
            return (
                monitor.getItem().id !== item.id &&
                item.parentsId !== undefined &&
                item.parentsId.indexOf(itemDrag.id) === -1
            );
        },

        drop(itemDrag: DragItem, monitor) {
            const didDrop: boolean = monitor.didDrop();
            if (!didDrop) {
                moveData(itemDrag, index, item.parentsId);
            }
        },
    });

    return (
        <hr
            ref={drop}
            style={{
                ...styleOver,
                backgroundColor: isOver ? COLORS.blue100 : COLORS.white,
            }}
        />
    );
};

const AccordionSummaryDnD = ({
    item,
    menuItems,
    moveData,
}: AccordionSummaryDnDProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const ref = useRef<HTMLDivElement>(null);

    // console.log('render summary ', item.id)

    const [{ opacity }, drag, preview] = useDrag(
        {
            type: ItemTypes.ACCORDIONDND,

            item: () => {
                return { id: item.id, parentsId: item.parentsId };
            },

            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        },
        [item],
    );

    const [{ isOver }, drop] = useDrop<
        DragItem,
        void,
        {
            isOver: boolean;
        }
    >({
        accept: ItemTypes.ACCORDIONDND,

        canDrop(itemDrag: DragItem, monitor) {
            return (
                monitor.getItem().id !== item.id &&
                item.parentsId !== undefined &&
                item.parentsId.indexOf(itemDrag.id) === -1
            );
        },

        collect(monitor) {
            const itemDrag: DragItem = monitor.getItem();
            return {
                isOver:
                    monitor.isOver() &&
                    itemDrag.id !== item.id &&
                    item.parentsId !== undefined &&
                    item.parentsId.indexOf(itemDrag.id) === -1,
            };
        },

        drop(itemDrag: DragItem, monitor) {
            if (itemDrag.id === item.id) return;

            const didDrop: boolean = monitor.didDrop();
            if (!didDrop) {
                moveData(itemDrag, -1, [
                    ...(item.parentsId as number[]),
                    item.id,
                ]);
            }
        },
    });

    drop(preview(ref));

    return (
        <AccordionSummary
            ref={ref}
            aria-controls={`panel${item.id}-content`}
            sx={{
                flexDirection: "row-reverse",
                backgroundColor: isOver ? COLORS.blue100 : COLORS.white,
                opacity: opacity,
                boxShadow: "none",
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                },
            }}
            id={`panel${item.id}-header`}
            data-testid={`AccordionAreas-AccordionSummary-${item.id}`}
            expandIcon={
                <Icon
                    variant="solid"
                    icon="chevron-right"
                    style={{
                        opacity:
                            item.children && item.children.length > 0 ? 1 : 0,
                    }}
                />
            }
        >
            <Stack
                justifyContent={"center"}
                alignItems={"center"}
                marginRight={1}
                sx={{ cursor: "pointer" }}
                ref={drag}
            >
                <Icon
                    variant="solid"
                    icon="grip-dots-vertical"
                    style={{ color: COLORS.gray700 }}
                />
            </Stack>
            <Stack
                width={"100%"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Stack width={"100%"} direction="row" spacing={2}>
                    <Typography variant={"body1Medium"}>{item.name}</Typography>
                    {!item.show && (
                        <Styled_AccordionChip
                            label={
                                <Typography
                                    variant="body3"
                                    color={COLORS.grey600}
                                >
                                    {fmt("Accordions.Chip.Hidden")}
                                </Typography>
                            }
                            icon={
                                <>
                                    <Icon variant="light" icon="eye-slash" />
                                </>
                            }
                        />
                    )}
                    {!item.enabled && (
                        <Styled_AccordionChip
                            label={
                                <Typography
                                    variant="body3"
                                    color={COLORS.grey600}
                                >
                                    {fmt("Accordions.Chip.Disabled")}
                                </Typography>
                            }
                            icon={
                                <>
                                    <Icon
                                        variant="light"
                                        icon="circle-dashed"
                                    />
                                </>
                            }
                        />
                    )}
                </Stack>

                <DropdownMenu
                    menuItems={menuItems}
                    id={item.id}
                    props={item}
                    data-testid={`AccordionAreas-DropdownMenu-${item.id}`}
                />
            </Stack>
        </AccordionSummary>
    );
};

const AccordionDnD = ({
    children,
    index,
    item,
    menuItems,
    moveData,
}: AccordionDnDProps) => {
    return (
        <div>
            {index === 0 && (
                <AccordionMoveDnd
                    index={index}
                    item={item}
                    moveData={moveData}
                />
            )}

            <Accordion
                key={item.id}
                sx={(theme) => ({
                    border: theme.border.default,
                    borderLeft: `solid ${item.color} 4px`,
                    boxShadow: "none",
                })}
                disableGutters
            >
                <AccordionSummaryDnD
                    item={item}
                    moveData={moveData}
                    menuItems={menuItems}
                />
                {React.Children.count(children) !== 0 && (
                    <AccordionDetails
                        id={`accordion${item.id}-target`}
                        data-testid={`AccordionAreas-AccordionDetails-${item.id}`}
                    >
                        {children}
                    </AccordionDetails>
                )}
            </Accordion>

            <AccordionMoveDnd
                index={index + 1}
                item={item}
                moveData={moveData}
            />
        </div>
    );
};

/* ****************************************************************************************************************** */
/* ****************************************************************************************************************** */

function updateParentsId(
    data: Type_index_area[],
    parentsId: number[] = [],
): Type_index_area[] {
    data.map((datum, i) => {
        datum.parentsId = parentsId;
        datum.children = updateParentsId(datum.children, [
            ...parentsId,
            datum.id,
        ]);
        data[i] = { ...datum };
    });
    return [...data];
}

export const AccordionAreasDnd = ({ createNewArea }: any) => {
    // eslint-disable-next-line no-empty-pattern
    const { sendEvent } = useChannel({
        eventHandler: ({ event, data }: Type_event_message) => {
            const areaData = data as Type_event_area;
            if (event === "updateArea") {
                updateArea(areaData);
            }
            if (event === "addArea") {
                addNewArea(areaData as Type_index_area);
            }
        },
    });
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.SubprojectSettings.Areas",
    );
    const { requestConfig } = useProject();
    const [modalDeleteArea, setModalDeleteArea] = useState<{
        isOpen: boolean;
        props?: Type_select_area | { id: null; name: string };
    }>({ isOpen: false });

    // create area
    const addNewArea = (newArea: Type_index_area): void => {
        const addNewAreaRecursive = (area: Type_index_area): any => {
            if (area.id === newArea.parentId) {
                area.children = [...area.children, newArea];
            } else if (area.children && area.children.length > 0) {
                area.children = area.children.map((child: Type_index_area) =>
                    addNewAreaRecursive(child),
                );
            }
            return area;
        };
        if (newArea.parentId === null) {
            setData((prevAreas: Type_index_area[]) => [...prevAreas, newArea]);
        } else {
            const newAreas: Type_index_area[] = areas.map(
                (area: Type_index_area) => addNewAreaRecursive(area),
            );
            // set parentsId
            setData(updateParentsId(newAreas));
        }
    };

    // update area
    const updateArea = async (areaToUpdate: any) => {
        const updateAreaRecursive = (
            area: Type_index_area,
        ): Type_index_area => {
            if (area.id === areaToUpdate.id) {
                // Update the area with new information
                return { ...area, ...areaToUpdate };
            } else if (area.children && area.children.length > 0) {
                // Recursively search through nested children
                const updatedChildren: any =
                    area.children.map(updateAreaRecursive);

                // If any child has been updated, return the updated area
                return { ...area, children: updatedChildren };
            }
            // If the area is not found or updated, return null
            return area;
        };

        // Update the areas state with the updated area
        setData((prevAreas: Type_index_area[]): Type_index_area[] => {
            // Return the updated areas array or the previous areas array if no update occurred
            return prevAreas
                .map(updateAreaRecursive)
                .map((area: Type_index_area) => area);
        });
    };

    const deleteArea = async (id: number) => {
        const removeAreaById = (
            areas: Type_index_area[],
            idToRemove: number,
        ): Type_index_area[] => {
            return areas.filter((area) => {
                if (area.id === idToRemove) {
                    // Remove this area and all its children
                    return false;
                }
                if (area.children && area.children.length > 0) {
                    // Recursively remove the area from children
                    area.children = removeAreaById(area.children, idToRemove);
                }
                return true;
            });
        };

        setData(removeAreaById(areas, id));
    };

    const { openPaper } = useContextualDrawer();

    const menuItems = [
        {
            label: (
                <Typography variant="body1">
                    <Icon
                        variant="light"
                        icon="edit"
                        style={{ marginRight: "6px" }}
                    />
                    {fmt("Accordions.Actions.Edit")}
                </Typography>
            ),
            call: (id: number) => openPaper("area", { id }),
        },
        {
            label: (
                <Typography variant="body1">
                    <Icon
                        variant="regular"
                        icon="plus"
                        style={{ marginRight: "6px" }}
                    />
                    {fmt("Accordions.Actions.CreateInside")}
                </Typography>
            ),
            call: (id: number) => createNewArea(id),
        },
        {
            label: (
                <Typography variant="body1">
                    <Icon
                        variant="light"
                        icon="copy"
                        style={{ marginRight: "6px" }}
                    />
                    {fmt("Accordions.Actions.Duplicate")}
                </Typography>
            ),
            call: (id: number) => mutateDuplicateArea(id),
        },
        {
            label: (
                <Typography variant="body1">
                    <Icon
                        variant="light"
                        icon="eye-slash"
                        style={{ marginRight: "6px" }}
                    />
                    {fmt("Accordions.Actions.ToggleDisplay")}
                </Typography>
            ),
            call: (_id: number): void => {
                // const area: Type_index_area | undefined = areas.find(
                //     (area: Type_index_area): boolean => area.id == id,
                // );
                //   mutateVisibilityArea({ id: id, show: !area?.show });
            },
        },
        {
            label: (
                <Typography variant="body1">
                    <Icon
                        variant="light"
                        icon="circle-dashed"
                        style={{ marginRight: "6px" }}
                    />
                    {fmt("Accordions.Actions.ToggleStatus")}
                </Typography>
            ),
            call: (_id: number): void => {
                //  const area: Type_index_area | undefined = areas.find(
                //      (area: Type_index_area): boolean => area.id == id,
                //  );
                //   mutateVisibilityArea({ id, enabled: !area?.enabled });
            },
        },
        {
            label: (
                <Typography variant="body1" color={COLORS.red500}>
                    <Icon
                        variant="light"
                        icon="trash"
                        style={{ marginRight: "6px" }}
                    />
                    {fmt("Accordions.Actions.DeleteArea")}
                </Typography>
            ),
            call: (id: number) => {
                setModalDeleteArea({
                    isOpen: true,
                    props: findValue(areas as Type_select_area[], id, {
                        id: null,
                        name: "",
                    }),
                });
            },
        },
    ];

    const [areas, setData] = React.useState<Type_index_area[]>([]);

    const { mutateAsync: mutateDeleteArea } =
        mutationDeleteArea(deleteArea) || {};

    const { mutate: mutateDuplicateArea, isLoading: isLoadingDuplicate } =
        mutationDuplicateArea({ callback: addNewArea, showToast: true });

    /*  const { mutate: mutateVisibilityArea } = mutationUpdateArea(
        true,
        updateArea,
    );
*/
    const { isFetching: isFetchingAreas, data: fetchedAreas } =
        useIndexAreas() || {};

    useEffect(() => {
        if (fetchedAreas && !isFetchingAreas)
            setData(updateParentsId(fetchedAreas));
    }, [isFetchingAreas]);

    const moveData = useCallback(
        (dragItem: DragItem, index: number, parentsId: number[]) => {
            setData((prevData: Type_index_area[]) => {
                if (equals(dragItem.parentsId, parentsId)) {
                    sort(prevData, dragItem, parentsId, index, requestConfig);
                } else {
                    const parent = findValue(
                        prevData,
                        parentsId[parentsId.length - 1],
                        { id: null, name: "" },
                    );
                    sendEvent("updateArea", {
                        id: dragItem.id,
                        parentArea:
                            parent.id === null
                                ? null
                                : { id: parent.id, name: parent.name },
                    });
                    move(prevData, dragItem, parentsId, index, requestConfig);
                }

                return updateParentsId(prevData);
            });
        },
        [],
    );

    const renderAccordionChild = useCallback(
        (index: number, item: Type_index_area) => {
            return (
                <AccordionDnD
                    key={item.id}
                    index={index}
                    item={item}
                    menuItems={menuItems}
                    moveData={moveData}
                >
                    {item.children.map(
                        (child: Type_index_area, index: number) =>
                            renderAccordionChild(index, child),
                    )}
                </AccordionDnD>
            );
        },
        [areas],
    );

    return (
        <>
            {isLoadingDuplicate ? (
                <LoadingBox />
            ) : (
                !isLoadingDuplicate && (
                    <Box>
                        {areas.length <= 0 && (
                            <LoadingOrNoData
                                isFetching={isFetchingAreas || false}
                                dataName={"Area"}
                                action={() =>
                                    console.log("open drawer to add new area")
                                }
                            />
                        )}

                        {areas.map((datum: Type_index_area, index: number) =>
                            renderAccordionChild(index, datum),
                        )}
                        <ModalDelete
                            actions={{
                                onSubmit: {
                                    onClick: () => {
                                        setModalDeleteArea({ isOpen: false });
                                        mutateDeleteArea(
                                            modalDeleteArea?.props
                                                ?.id as number,
                                        );
                                    },
                                },
                            }}
                            item={"coucou"}
                            onClose={() =>
                                setModalDeleteArea({ isOpen: false })
                            }
                            open={modalDeleteArea.isOpen}
                            validationString={
                                modalDeleteArea?.props?.name as string
                            }
                        />
                    </Box>
                )
            )}
        </>
    );
};
