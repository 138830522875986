import React from "react";

import {
    Styled_AppBar,
    Styled_Toolbar,
} from "src/components/Components_Teamoty/TopBar/stylesheet";
import { TopBar_Left } from "src/components/Components_Teamoty/TopBar/TopBar_Left/TopBar_Left";

import { TopBar_Right } from "./TopBar_Right/TopBar_Right";

export const TopBar = () => {
    return (
        <Styled_AppBar elevation={0} position="static">
            <Styled_Toolbar>
                <TopBar_Left />
                <TopBar_Right />
            </Styled_Toolbar>
        </Styled_AppBar>
    );
};
