import Konva from "konva";
import React, { useCallback, useRef, useState } from "react";
import useMeasure from "react-use-measure";

import { useMeasureDefaultConfig } from "src/configurations/app";

import { colorFlowBackground } from "./Flow.const";
import {
    Type_changeFlowStage,
    Type_Flow,
    Type_State_FlowStage,
} from "./Flow.type";
import { FlowView, TypeActions } from "./FlowView";

const initialStateStageFlow: Type_State_FlowStage = {
    scale: 1,
    x: 0,
    y: 0,
};

const defaultPromiseResolve = () => Promise.resolve();

export const Flow = ({
    tasks,
    setTasks,
    onAddTask = defaultPromiseResolve,
    onUpdateTask = defaultPromiseResolve,
    onAddLink = defaultPromiseResolve,
    onDeleteLink = defaultPromiseResolve,
    onDeleteTask = defaultPromiseResolve,
    openTaskDrawer = defaultPromiseResolve,
    fixedSize,
}: Type_Flow) => {
    const [ref, bounds] = useMeasure(useMeasureDefaultConfig);

    const refView = useRef<Konva.Stage>(null);

    const [stageFlow, setStageFlow] = useState<Type_State_FlowStage>(
        initialStateStageFlow,
    );

    const changeStageFlow = useCallback(
        (change: Type_changeFlowStage): void => {
            setStageFlow((prev: Type_State_FlowStage) => ({
                ...prev,
                ...change,
            }));
        },
        [],
    );

    const onTaskChange = async (operation: TypeActions, props: any) => {
        switch (operation) {
            case TypeActions.ADD:
                return await onAddTask(props);

            case TypeActions.DELETE:
                return await onDeleteTask(props);

            case TypeActions.OPEN_DRAWER:
                return await openTaskDrawer(props);

            case TypeActions.EDIT:
                return await onUpdateTask(props);

            default:
                return Promise.resolve();
        }
    };

    const onLinkChange = async (operation: TypeActions, props: any) => {
        switch (operation) {
            case TypeActions.ADD:
                return await onAddLink(props);

            case TypeActions.DELETE:
                return await onDeleteLink(props);

            default:
                return Promise.resolve();
        }
    };

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: colorFlowBackground,
            }}
            ref={ref}
            data-testid="Flow-div-wrapper"
        >
            <FlowView
                refView={refView}
                bounds={bounds}
                tasks={tasks}
                setTasks={setTasks}
                stageFlow={stageFlow}
                setStageFlow={setStageFlow}
                changeStageFlow={changeStageFlow}
                onTaskChange={onTaskChange}
                onLinkChange={onLinkChange}
                fixedSize={fixedSize}
            />
        </div>
    );
};
