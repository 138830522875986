import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import {
    Type_Map_planningWorkforce,
    Type_planningWorkforceDay,
} from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import { Dispatch, SetStateAction } from "react";

import { Type_Props_PlanningMonthsDays } from "src/components/Components_Teamoty/Planning/Dates/PlanningDate.type";

import { Type_Map_planningQuantities } from "./PlanningWorkforce.type";

export const getQuantitiesBySubTrades = (
    dates: Type_Props_PlanningMonthsDays,
    workforce: Type_Map_planningWorkforce,
    setQuantities: Dispatch<SetStateAction<Type_Map_planningQuantities>>,
): void => {
    const newQuantities: Type_Map_planningQuantities = new Map<
        number,
        number[]
    >();

    let index: number = 0;
    dates.days.map((date: planningDateClass): void => {
        if (workforce.has(date.timestamp)) {
            const data: Type_planningWorkforceDay = workforce.get(
                date.timestamp,
            ) as Type_planningWorkforceDay;
            data.subTrades.forEach(
                (quantity: number, subTradeId: number): void => {
                    const oldQuantity: number[] = newQuantities.has(subTradeId)
                        ? (newQuantities.get(subTradeId) as number[])
                        : [];
                    oldQuantity[index] =
                        typeof oldQuantity[index] === "undefined"
                            ? quantity
                            : oldQuantity[index] + quantity;
                    newQuantities.set(subTradeId, oldQuantity);
                },
            );
        }
        index++;
    });

    setQuantities(newQuantities);
};
