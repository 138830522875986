import { FormControlLabel, Grid, Switch } from "@mui/material";
import React from "react";

import { TMC_Button } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { COLORS } from "src/theme/stylesheet";

export const InheritWhite = () => {
    return (
        <>
            {/* INHERIT WHITE CONTAINED */}
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                INHERIT WHITE CONTAINED
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    color="inheritWhite"
                    size="large"
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    color={"inheritWhite"}
                    size="large"
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                    disabled={true}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                TMC_Loading button
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <FormControlLabel
                    sx={{
                        display: "block",
                    }}
                    control={
                        <Switch
                            checked={false}
                            onChange={() => console.log("inheritWhite")}
                            name="loading"
                            color="primary"
                        />
                    }
                    label="Loading"
                />
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon btn
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon btn disabled
            </Grid>

            {/* MEDIUM */}
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            ></Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    color={"inheritWhite"}
                    size={"medium"}
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    color={"inheritWhite"}
                    size={"medium"}
                    disabled={true}
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                loading btn
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            ></Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon disabled{" "}
            </Grid>

            {/* SMALL */}
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            ></Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    color={"inheritWhite"}
                    size="small"
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    color={"inheritWhite"}
                    size="small"
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                    disabled={true}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                Loading btn
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            ></Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon disabled
            </Grid>

            {/* INHERIT WHITE GHOST */}

            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                INHERIT WHITE GHOST{" "}
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    variant={"ghost"}
                    color={"inheritWhite"}
                    size="large"
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    variant={"ghost"}
                    color={"inheritWhite"}
                    size="large"
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                    disabled={true}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                TMC_Loading button
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <FormControlLabel
                    sx={{
                        display: "block",
                    }}
                    control={
                        <Switch
                            checked={false}
                            onChange={() => console.log("inheritWhite")}
                            name="loading"
                            color="primary"
                        />
                    }
                    label="Loading"
                />
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon btn
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon btn disabled
            </Grid>

            {/* MEDIUM */}
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            ></Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    variant={"ghost"}
                    color={"inheritWhite"}
                    size={"medium"}
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    variant={"ghost"}
                    color={"inheritWhite"}
                    size={"medium"}
                    disabled={true}
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                loading btn
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            ></Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon disabled{" "}
            </Grid>

            {/* SMALL */}
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            ></Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    variant={"ghost"}
                    color={"inheritWhite"}
                    size="small"
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                <TMC_Button
                    variant={"ghost"}
                    color={"inheritWhite"}
                    size="small"
                    startIcon={<Icon variant="solid" icon="check" />}
                    endIcon={<Icon variant="solid" icon="check" />}
                    disabled={true}
                >
                    Button
                </TMC_Button>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                Loading btn
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            ></Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon
            </Grid>
            <Grid
                item
                xs={1}
                sx={{ backgroundColor: COLORS.teamotyBlue900, color: "white" }}
            >
                icon disabled
            </Grid>
        </>
    );
};
