import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

const Styled_StackNoPermission = styled(Stack)(({ theme }: any) => ({
    backgroundColor: COLORS.moon100,
    border: `1px dashed ${COLORS.moon300}`,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    gap: theme.spacing(2),
}));

export const NoPermission = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer");
    return (
        <Styled_StackNoPermission>
            <Icon variant="solid" icon="ban" sx={{ color: COLORS.moon500 }} />
            <Typography
                variant="body1Bold"
                color={COLORS.moon500}
                whiteSpace="pre-wrap"
            >
                {fmt("NoPermission")}
            </Typography>
        </Styled_StackNoPermission>
    );
};
