import React, { ComponentProps } from "react";

import { useSelectListDrawings } from "src/api/tms-projects/drawing";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteDrawings = Omit<
    ComponentProps<typeof Autocomplete>,
    "options" | "isFetching"
>;

export const AutocompleteDrawings = ({
    name,
    label,
    ...props
}: Type_Props_AutocompleteDrawings) => {
    const { isFetching, data: drawings } = useSelectListDrawings() || {};

    return (
        <Autocomplete
            name={name}
            label={label}
            isFetching={isFetching}
            options={drawings || []}
            {...props}
        />
    );
};
