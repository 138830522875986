import { Skeleton } from "@mui/material";
import * as React from "react";

import { useSelectListPapers } from "src/api/tms-print/tools";
import {
    Select,
    Type_Props_Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

type Type_Props_SelectPaperFormat = Omit<Type_Props_Select, "options">;

export const SelectPaperFormat = ({
    ...props
}: Type_Props_SelectPaperFormat) => {
    const { isFetching, data } = useSelectListPapers();

    return (
        <>
            {isFetching ? (
                <Skeleton variant={"rounded"} height={32} />
            ) : (
                <Select {...props} options={data!} />
            )}
        </>
    );
};
