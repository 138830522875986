import {
    GridCellParams,
    GridColDef,
    GridTreeNode,
    GridValidRowModel,
} from "@mui/x-data-grid-premium";
import React, { useMemo } from "react";

import {
    mutationUpdateAreaForMatrix,
    useIndexAreasForMatrix,
} from "src/api/tms-projects/areas";
import { useSelectListProjectAreaTypes } from "src/api/tms-projects/areaTypes";
import { useSelectListResources } from "src/api/tms-projects/resources";
import { useSelectListResourceTypes } from "src/api/tms-projects/resourceTypes";
import { formatSelectListToSelectOptionItem } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { ColorPickerColumn } from "src/components/Components_Common/matrix/components/ColorPickerColumn";
import { multiLanguesColumns } from "src/components/Components_Common/matrix/components/MultiLanguesColumn";
import { getDifferentAttributes } from "src/components/Components_Common/matrix/Matrix";
import { MatrixTree } from "src/components/Components_Common/matrix/MatrixTree";
import { SELECT_NONE_VALUE } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_MatrixAreas = {
    page: string;
};

export const MatrixAreas = ({ page }: Type_Props_MatrixAreas) => {
    //i18n
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { projectLanguages = [] } = useProject();

    // api
    const { data: fetchedAreasForMatrix = [], refetch: refetchForMatrix } =
        useIndexAreasForMatrix();
    const { mutateAsync: mutateUpdate } =
        mutationUpdateAreaForMatrix(false, false, true) || {};
    const { data: areaTypes = [] } = useSelectListProjectAreaTypes() || {};
    const { data: resources = [] } = useSelectListResources() || {};
    const { data: resourcesTypes = [] } = useSelectListResourceTypes() || {};

    // context
    const { checkPermission, user } = useUser();

    const editable = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    const columns: GridColDef[] = [
        ...multiLanguesColumns({
            field: "names",
            headerName: fmtTableColumn("Name"),
            languages: projectLanguages,
            editable: editable,
        }),
        ColorPickerColumn({
            field: "color",
            headerName: fmtTableColumn("Color"),
            editable: editable,
        }),
        {
            field: "areaTypeId",
            headerName: fmtTableColumn("AreaType"),
            type: "singleSelect",
            valueOptions: formatSelectListToSelectOptionItem(areaTypes),
            editable: editable,
            flex: 1,
        },
        {
            field: "resourceTypeId",
            headerName: fmtTableColumn("ResourceType"),
            type: "singleSelect",
            valueOptions: formatSelectListToSelectOptionItem(
                resourcesTypes,
                true,
            ),
            editable: editable,
            display: "flex",
            flex: 1,
        },
        {
            field: "resourceId",
            headerName: fmtTableColumn("Resource"),
            type: "singleSelect",
            valueOptions: (params) => {
                const resourceTypeId = params?.row?.resourceTypeId;

                if (!resourceTypeId)
                    return formatSelectListToSelectOptionItem(resources, true);

                return formatSelectListToSelectOptionItem(
                    resources.filter(
                        (resource) =>
                            resource.resourceType.id === resourceTypeId,
                    ) ?? [],
                    true,
                );
            },
            editable: editable,
            display: "flex",
            flex: 1,
        },
        {
            field: "permanentUse",
            type: "boolean",
            headerName: fmtTableColumn("PermanentUse"),
            display: "flex",
            flex: 1,
            editable: editable,
        },
    ];

    const refetchValues = async () => {
        return await refetchForMatrix();
    };

    //
    // Gestion d'édition des Cells
    //
    const editableConditions: Record<
        string,
        (params: GridCellParams<any, any, any, GridTreeNode>) => boolean
    > = {
        resourceId: (params) =>
            params.row.resourceTypeId &&
            params.row.resourceTypeId !== SELECT_NONE_VALUE,
    };

    const isCellEditable = (
        params: GridCellParams<any, any, any, GridTreeNode>,
    ): boolean => {
        const condition = editableConditions[params.field];

        return condition ? condition(params) : editable;
    };

    //
    // Gestion spécifique a MatrixAreas lors de l update
    //
    const processRowUpdateAdditonalsBehaviors = (
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel,
    ): GridValidRowModel => {
        const diff: GridValidRowModel = getDifferentAttributes(newRow, oldRow);
        if (
            (newRow?.resourceTypeId === SELECT_NONE_VALUE &&
                newRow?.resourceId > SELECT_NONE_VALUE) ||
            (diff.resourceTypeId && diff.resourceTypeId !== SELECT_NONE_VALUE)
        ) {
            diff.resourceId = SELECT_NONE_VALUE;
        }

        return diff;
    };

    return (
        <MatrixTree
            groupingCellComponent={() => <div />} // Permet d'enlever l'apparence native
            columns={columns}
            rows={fetchedAreasForMatrix}
            mutateUpdate={mutateUpdate}
            refetch={refetchValues}
            isCellEditable={isCellEditable}
            processRowUpdateAdditonalsBehaviors={
                processRowUpdateAdditonalsBehaviors
            }
        />
    );
};
