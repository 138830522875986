import React, { useState } from "react";

import { useShowSubProject } from "src/api/tms-projects/subProjects";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { useProject } from "src/contexts/project";
import { DefaultSettingsDrawer } from "src/drawers/defaultSettings/DefaultSettingsDrawer";
import { Page_DefaultSettingsComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_DefaultSettings/Page_DefaultSettings.component";

export const Page_DefaultSettingsContainer = () => {
    const { requestConfig } = useProject();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [selectedStep, setSelectedStep] = useState<"sequence" | "version">(
        "sequence",
    );

    const { isFetching, data } =
        useShowSubProject(
            requestConfig.projectId,
            requestConfig.subProjectId as number,
        ) || {};

    const openDrawer = (step: "sequence" | "version") => {
        setSelectedStep(step);
        setDrawerOpen(true);
    };

    const closeDrawer = () => setDrawerOpen(false);

    if (isFetching) return <FullSpinner />;

    if (!data) return null;

    return (
        <>
            <DefaultSettingsDrawer
                formId="defaultSettings"
                page="subProjects"
                onClose={closeDrawer}
                open={isDrawerOpen}
                step={selectedStep}
            />
            <Page_DefaultSettingsComponent
                subProject={data}
                openDrawer={openDrawer}
            />
        </>
    );
};
