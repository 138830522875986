import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { Url, WorkspacesPath } from "src/api/paths";
import { formatterCreateView } from "src/api/tms-projects/workspacesAndViews/views/formatters";
import { createView } from "src/api/tms-projects/workspacesAndViews/views/services";
import { formatterCreateWorkspace } from "src/api/tms-projects/workspacesAndViews/workspaces/formatters";
import {
    Type_default_workspace,
    Type_prj_post_workspace,
    Type_prj_put_workspace,
} from "src/api/tms-projects/workspacesAndViews/workspaces/types";

export const createWorkspace = (
    data: Type_prj_post_workspace,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.PROJECTS}${WorkspacesPath.WORKSPACES}`,
        requestConfig,
    );
};

export const showWorkspace = (
    type: string,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${WorkspacesPath.WORKSPACES}/${type}`,
        requestConfig,
    );
};

export const updateWorkspace = (
    data: Type_prj_put_workspace,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${WorkspacesPath.WORKSPACES}/${data?.id}`,
        requestConfig,
    );
};

export const createWorkspaceAndViewsFromConfig = async (
    requestConfig: Type_RequestConfig,
    defaultWorkspaces: Map<"project" | "subproject", any> | null,
    type?: string,
) => {
    // Get the right configuration based on project or subproject
    let workspaceConfig;
    if (requestConfig.subProjectId) {
        workspaceConfig = defaultWorkspaces?.get("subproject");
    } else {
        workspaceConfig = defaultWorkspaces?.get("project");
    }

    if (!workspaceConfig) {
        console.error("No workspace configuration found.");
        return;
    }

    const workspacesData: Type_default_workspace[] = Array.from(
        workspaceConfig.values(),
    );

    // Filter workspace data by type
    const filteredWorkspaces = type
        ? workspacesData.filter(
              (workspace: Type_default_workspace) => workspace.type === type,
          )
        : workspacesData;

    if (filteredWorkspaces.length === 0) {
        console.error("No matching workspace found for the given type.");
        return;
    }

    try {
        // Create workspaces
        const createdWorkspaces = await Promise.all(
            filteredWorkspaces.map((newWorkspace) =>
                createWorkspace(
                    formatterCreateWorkspace(newWorkspace),
                    requestConfig,
                ),
            ),
        );

        // Create views for each created workspace
        const createViewsPromises: any[] = [];
        createdWorkspaces.forEach((createdWorkspace, index) => {
            const workspaceViews = filteredWorkspaces[index].views;
            workspaceViews.forEach((view) => {
                createViewsPromises.push(
                    createView(
                        formatterCreateView(
                            view,
                            createdWorkspace.data?.data?.id,
                        ),
                        requestConfig,
                    ),
                );
            });
        });

        await Promise.all(createViewsPromises);

        console.log(
            "The workspace and its views have been created successfully.",
        );
    } catch (error) {
        console.error("Error creating workspaces and views:", error);
    }
};
