import { Box } from "@mui/material";
import React, { useState } from "react";

import { useShowSequence } from "src/api/tms-scheduling/sequences";
import { LoadingBox } from "src/components";
import { SequenceForm } from "src/forms/sequence/SequenceForm";
import {
    Type_event_delete,
    Type_event_pert,
    useChannel,
} from "src/hooks/useChannel";
import {
    Styled_PaperContextualLayout,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_props_SequenceContextualDrawer = {
    id: number;
};

export const SequenceContextualDrawer = ({
    id,
}: Type_props_SequenceContextualDrawer) => {
    const { closePaper } = useContextualDrawer();
    const [sequenceId, setSequenceId] = useState<number>(id);

    useChannel({
        eventHandler: ({ event, data }) => {
            if (event === "openPert") {
                setSequenceId((data as Type_event_pert).id);
            }
        },
    });

    useChannel({
        eventHandler: ({ event, data: dataEvent }) => {
            if (event === "deleteSequence") {
                if ((dataEvent as Type_event_delete).id === sequenceId) {
                    closePaper("sequence");
                }
            }
        },
    });

    const { data, isFetching } = useShowSequence(
        sequenceId,
        "sequenceContextualDrawer",
    );

    return (
        <Styled_PaperContextualLayout>
            {isFetching ? (
                <Box paddingTop={8}>
                    <LoadingBox />
                </Box>
            ) : (
                <SequenceForm id={sequenceId} data={data} />
            )}
        </Styled_PaperContextualLayout>
    );
};
