import { Stack, Typography } from "@mui/material";
import * as React from "react";

import { ProjectPath, Url } from "src/api/paths";
import { Type_selectList } from "src/api/types";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import {
    formatSelectListToSelectOptionItem,
    Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { TextFieldWithBlock } from "src/components/Components_Common/forms/reactHookFormComponents/TextFieldWithBlock/TextFieldWithBlock";
import { UploadImageRhf } from "src/components/Components_Common/forms/reactHookFormComponents/UploadImage/UploadImage";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getUrlName } from "src/utils/urlFormatter";

export type Type_Props_Tab_ProjectFormDetail = {
    projectTypes: any[];
    companies: Type_selectList[];
    projectId: number;
};

export const Tab_ProjectDetailForm = ({
    projectTypes,
    companies,
    projectId,
}: Type_Props_Tab_ProjectFormDetail) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Projects");

    return (
        <Stack gap={6}>
            <Typography variant="body1Bold">
                {fmt(`ProjectInformation`)}
            </Typography>
            <UploadImageRhf
                host={Url.COMMONS}
                service={ProjectPath.PROJECTS}
                imgType={"thumbnail"}
                id={projectId}
                name="thumbnail"
                withDescription={true}
            />
            <TextField
                data-testid="EditProject-DrawerContent-TabGlobal-name"
                name={"name"}
                label={fmt(`Name`)}
                variant={"outlined"}
            />
            <TextFieldWithBlock
                variant={"outlined"}
                data-testid="EditProject-DrawerContent-TabGlobal-slug"
                name="slug"
                label={fmt("URL")}
                block={getUrlName()}
                disabled={true}
                description={fmt("UrlCannotBeChanged")}
            />
            <Stack>
                <DatePicker
                    name={"startDate"}
                    label={fmt("StartDate")}
                    data-testid="EditProject-DrawerContent-TabGlobal-StartDate"
                />
            </Stack>
            <Select
                name={"companyId"}
                options={formatSelectListToSelectOptionItem(companies)}
                label={fmt("Company")}
                variant={"outlined"}
                data-testid="EditProject-DrawerContent-TabGlobal-Company"
            />
            <Stack direction="row" flexWrap="nowrap" gap={4} width="100%">
                <TextField
                    variant={"outlined"}
                    data-testid="EditProject-DrawerContent-TabGlobal-postCode"
                    name={"postCode"}
                    label={fmt(`PostCode`)}
                />
                <TextField
                    variant={"outlined"}
                    data-testid="EditProject-DrawerContent-TabGlobal-city"
                    name={"city"}
                    label={fmt(`City`)}
                />
                <TextField
                    variant={"outlined"}
                    data-testid="EditProject-DrawerContent-TabGlobal-country"
                    name={"country"}
                    label={fmt(`Country`)}
                />
            </Stack>
            <Autocomplete
                data-testid="EditProject-DrawerContent-TabGlobal-projectTypes"
                id="projectTypes"
                name="projectTypes"
                multiple
                options={projectTypes}
                renderInputProps={{
                    variant: "outlined",
                }}
            />
        </Stack>
    );
};
