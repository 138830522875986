import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    Type_RequestConfig,
} from "src/api/fetch";
import { PeoplePath, Url } from "src/api/paths";

import { Type_sch_post_people, Type_sch_put_people } from "./types";

export const selectPeople = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${PeoplePath.PEOPLE_LIST}`, requestConfig);
};

export const createPeople = (
    data: Type_sch_post_people,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.SCHEDULING}${PeoplePath.PEOPLE}`, requestConfig);
};

export const updatePeople = (
    id: number,
    data: Type_sch_put_people,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${PeoplePath.PEOPLE}/${id}`,
        requestConfig,
    );
};

export const showPeople = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${PeoplePath.PEOPLE}/${id}`, requestConfig);
};
