import * as React from "react";

import { useContextualDrawer } from "./Provider_ContextualDrawer";

export const Layout_ContextualDrawer = () => {
    const { value } = useContextualDrawer();

    return (
        <div
            style={{ display: "flex", flexDirection: "row-reverse" }}
            data-testid="Layout_ContextualDrawer-div"
        >
            {value.map((item, index) => {
                const Component = item.paper;
                return (
                    <Component
                        key={index}
                        {...item.props}
                        data-testid="Layout_ContextualDrawer-Component"
                    />
                );
            })}
        </div>
    );
};
