import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { TaskAreasPath, Url } from "src/api/paths";
import { Type_sch_post_peopleTaskArea } from "src/api/tms-scheduling/peopleTaskArea/types";

export const indexTaskAreaPeople = (
    requestConfig: Type_RequestConfig,
    signal?: AbortSignal,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${TaskAreasPath.TASK_AREA_PEOPLE}`,
        requestConfig,
        null,
        signal,
    );
};

export const createTaskAreaPeople = (
    data: Type_sch_post_peopleTaskArea,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${TaskAreasPath.TASK_AREA_PEOPLE}`,
        requestConfig,
    );
};

export const removeTaskAreaPeople = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${TaskAreasPath.TASK_AREA_PEOPLE}/${id}`,
        requestConfig,
    );
};
