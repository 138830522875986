import { Box, Divider } from "@mui/material";
import Menu from "@mui/material/Menu";
import * as React from "react";
import { useMemo, useState } from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Type_menuDropDown } from "src/components/Components_Common/Table/Table";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { CustomMenuItem } from "./CustomMenuItem";

export type Type_Props_DropdownMenuGeneric = Type_menuDropDown & {
    id: number | string;
    props?: any;
    menuItems?: Type_CustomMenuItem[];
    page: string;
};

type Type_CustomMenuItem_Icon = {
    name: string;
    variant: string;
    color?: string;
};

export type Type_CustomMenuItem = {
    label: string;
    icon: Type_CustomMenuItem_Icon;
    call: (props: any) => void;
    show?: (props: any) => any;
};

export const DropdownMenuGeneric = ({
    id,
    callbackEdit,
    callbackDuplicate,
    callbackStatus,
    callbackDelete,
    showEdit,
    showDuplicate,
    showStatus,
    showDelete,
    props,
    menuItems,
    page,
}: Type_Props_DropdownMenuGeneric) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtLists } = useCoreIntl("Lists");

    const { checkPermission, user } = useUser();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const CAN_CREATE_UPDATE = useMemo(
        () => checkPermission(page, "create"),
        [user],
    );
    const CAN_DELETE = useMemo(() => checkPermission(page, "delete"), [user]);

    return (
        <>
            <IconButton
                data-testid={`Menu_DropDownGeneric-${id}`}
                size={"small"}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Icon variant="solid" icon="ellipsis-vertical" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {/*DROPDOWN edit*/}
                {callbackEdit &&
                    CAN_CREATE_UPDATE &&
                    ((showEdit && showEdit(props)) || !showEdit) && (
                        <CustomMenuItem
                            keyName={`edit-${id}`}
                            label={fmtActions("Update")}
                            iconName="pen"
                            color="inherit"
                            variant="light"
                            props={props}
                            callback={callbackEdit}
                        ></CustomMenuItem>
                    )}

                {/*DROPDOWN duplicate*/}
                {callbackDuplicate &&
                    CAN_CREATE_UPDATE &&
                    ((showDuplicate && showDuplicate(props)) ||
                        !showDuplicate) && (
                        <CustomMenuItem
                            props={props}
                            keyName={`duplicate-${id}`}
                            label={fmtActions("Duplicate")}
                            iconName="copy"
                            color="inherit"
                            variant="solid"
                            callback={callbackDuplicate}
                        ></CustomMenuItem>
                    )}

                {/*DROPDOWN change status*/}
                {callbackStatus &&
                    CAN_CREATE_UPDATE &&
                    ((showStatus && showStatus(props)) || !showStatus) && (
                        <CustomMenuItem
                            keyName={`status-${id}`}
                            label={
                                props.enabled
                                    ? fmtLists("Actions.Disable")
                                    : fmtLists("Actions.Enable")
                            }
                            iconName="circle-dashed"
                            color="inherit"
                            variant="light"
                            props={props}
                            callback={callbackStatus}
                        ></CustomMenuItem>
                    )}

                {/*menuItems used when we want to add some options to the dropdown*/}
                {menuItems &&
                    menuItems.map(
                        (menuItem: Type_CustomMenuItem, index: number) => {
                            // call callback function to show item if exists
                            if (
                                !Object.prototype.hasOwnProperty.call(
                                    menuItem,
                                    "show",
                                ) ||
                                (menuItem.show && menuItem.show(props))
                            ) {
                                return (
                                    <CustomMenuItem
                                        key={"menuItems" + index}
                                        keyName={index.toString()}
                                        label={menuItem.label}
                                        iconName={menuItem.icon.name}
                                        color={
                                            menuItem.icon.color
                                                ? menuItem.icon.color
                                                : "inherit"
                                        }
                                        variant={menuItem.icon.variant}
                                        callback={() => menuItem.call(props)}
                                    />
                                );
                            }
                        },
                    )}

                {/*DROPDOWN delete*/}
                {callbackDelete &&
                    CAN_DELETE &&
                    ((showDelete && showDelete(props)) || !showDelete) && (
                        <Box>
                            {!menuItems &&
                            !callbackEdit &&
                            !callbackDuplicate &&
                            !callbackStatus ? (
                                <></>
                            ) : (
                                <Divider />
                            )}
                            <CustomMenuItem
                                keyName={`delete-${id}`}
                                label={fmtActions("Delete")}
                                iconName="radiation"
                                color={COLORS.red500}
                                variant="light"
                                props={props}
                                callback={callbackDelete}
                            ></CustomMenuItem>
                        </Box>
                    )}
            </Menu>
        </>
    );
};
