import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { TasksPath, Url } from "src/api/paths";
import {
    Type_sch_post_taskAreaNote,
    Type_sch_put_taskAreaNote,
} from "src/api/tms-scheduling/taskAreaNotes/types";

export const indexTaskAreaNotes = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.SCHEDULING}${TasksPath.TASK_AREA_NOTES}`, requestConfig);
};

export const createTaskAreaNote = (
    data: Type_sch_post_taskAreaNote,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${TasksPath.TASK_AREA_NOTES}`,
        requestConfig,
    );
};

export const updateTaskAreaNote = (
    id: number,
    data: Type_sch_put_taskAreaNote,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${TasksPath.TASK_AREA_NOTES}/${id}`,
        requestConfig,
    );
};

export const deleteTaskAreaNote = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${TasksPath.TASK_AREA_NOTES}/${id}`,
        requestConfig,
    );
};
