import { Type_sidebar_configuration } from "src/layouts/Layout_Sidebar/types";

// todo permission from back
export const logisticsSettingsCategoriesList: Type_sidebar_configuration = {
    nameLabel: "Logistics.Settings",
    baseUrl: "/projects/:projectSlug/logistics/settings",
    categories: [
        {
            nameLabel: "RequestTypes",
            urlName: "request-types",
            permissionKeys: ["resourceTypes_index"],
            variant: "regular",
            icon: "sitemap",
        },
        {
            nameLabel: "AssignManagers",
            urlName: "assign-manager",
            permissionKeys: ["resourceTypes_index"],
            variant: "regular",
            icon: "building",
        },
        {
            nameLabel: "AssignResources",
            urlName: "assign-resources",
            permissionKeys: ["resourceTypes_index"],
            variant: "regular",
            icon: "tag",
        },
    ],
};
