import {
    Backdrop,
    Box,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Group, Layer } from "react-konva";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import useMeasure from "react-use-measure";

import { mutationUpdateArea, useIndexAreas } from "src/api/tms-projects/areas";
import { Type_index_area } from "src/api/tms-projects/areas/types";
import { useImageDrawing, useShowDrawing } from "src/api/tms-projects/drawing";
import { formatterShowToSelect } from "src/api/tms-projects/drawing/formatters";
import { Type_show_drawing } from "src/api/tms-projects/drawing/types";
import { LoadingBox, TMC_Button } from "src/components";
import { Image as CanvasImage } from "src/components/Components_Common/canvas/Image";
import {
    Shape,
    Type_Shape,
} from "src/components/Components_Common/canvas/Shape";
import { Stage } from "src/components/Components_Common/canvas/Stage";
import { StageProvider } from "src/components/Components_Common/canvas/StageContext";
import { Type_point } from "src/components/Components_Common/canvas/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { DrawingAreaSelection } from "src/dialogs/DrawingAreaSelection";
import { AreaDrawer } from "src/drawers/areas/AreaDrawer";
import {
    DrawingDrawer as EditDrawingDrawer,
    Type_DrawingImageCrop,
} from "src/drawers/drawings/DrawingDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Header, Main, Section, Sidebar } from "src/layouts/Layout_FullWidth";
import { AreasTreeSidebar } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Drawings/Page_Drawing/AreasTreeSidebar";
import { HeaderDropDownMenu } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Drawings/Page_Drawing/HeaderDropDownMenu";
import { URL_TEAMOTY_PROJECT } from "src/router";
import {
    getAreasToDisplay,
    getFlattenAreas,
    getFlattenAreasIds,
} from "src/utils/area";
import { getMinCoordinate } from "src/utils/coordinates";

// TODO move to config or MuiTheme
const TextSizeScale = [8, 10, 12, 14, 16, 20, 24];

const getDefaultShapeCoordinate = (initPos: Type_point): Type_point[] => {
    return [
        initPos,
        { x: initPos.x + 100, y: initPos.y },
        { x: initPos.x + 100, y: initPos.y + 100 },
        { x: initPos.x, y: initPos.y + 100 },
    ];
};

const formatterAreaToShape = (
    area: Type_index_area,
    initialPosition: number,
    drawing: Type_show_drawing | null,
): Type_Shape => {
    const initPos: Type_point = { x: initialPosition * 110, y: 0 };

    return {
        index: area.id,
        label: {
            name: area.name,
            width: area.widthName ?? 90,
            rotation: area.rotationName ?? 0,
            pt: area.coordinateName ?? { x: initPos.x + 5, y: initPos.y + 5 },
            fontSize: drawing?.textSize
                ? TextSizeScale[drawing.textSize]
                : TextSizeScale[3],
        },
        color: area.color,
        pts: area.coordinates ?? getDefaultShapeCoordinate(initPos),
    };
};

export const Page_Drawing = () => {
    const navigate = useNavigate();
    const urlParams = useParams();
    const { formatMessageWithPartialKey: fmtCta } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Settings.Drawings.Drawing",
    );
    const [ref, bounds] = useMeasure();

    ////////////////////////////////////////////
    // ---- STATES                          ----
    ////////////////////////////////////////////
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState("");
    const [drawingImage, setDrawingImage] = useState<Type_DrawingImageCrop>({
        file: null,
        image: null,
        isDrawingImageUpdated: false,
    });

    const [areas, setAreas] = useState<Type_index_area[]>([]);
    const [selectedArea, setSelectedArea] = useState<number | null>(null);
    const [selectedAreas, setSelectedAreas] = useState<string[]>([]);

    const [openAreaDrawer, setOpenAreaDrawer] = useState(false);
    const [openAreaSelection, setOpenAreaSelection] = useState(false);
    const [openDrawingDrawer, setOpenDrawingDrawer] = useState(false);

    const [areaShapes, setAreaShapes] = useState<Type_Shape[]>([]);

    ////////////////////////////////////////////
    // ---- QUERIES AND MUTATIONS           ----
    ////////////////////////////////////////////

    // Get drawing data if we have an id
    const { isLoading: isLoadingShowDrawing, data: drawing } = useShowDrawing(
        urlParams?.drawingId as unknown as number,
    );

    // Load current drawing image if we have an drawingId
    const {
        data: drawingImageFromApi,
        isLoading: isLoadingImageDrawing,
        refetch: refecthImageDrawing,
    } = useImageDrawing(drawing || null);

    const {
        isFetching: isFetchingIndexAreas,
        refetch: refetchIndexAreas,
        data: fetchedAreas,
    } = useIndexAreas({}, !!drawing?.id) || {};

    useEffect(() => {
        if (fetchedAreas && !isFetchingIndexAreas) {
            if (drawing && drawing.id !== null) {
                const { areasIdsInPath, areasSelectedIds } = getFlattenAreasIds(
                    {
                        items: fetchedAreas,
                        drawing_id: drawing.id,
                    },
                );

                const newAreas = getAreasToDisplay(
                    fetchedAreas,
                    areasIdsInPath,
                );

                setAreas(newAreas);
                setSelectedAreas(areasSelectedIds);
            }
        }
    }, [isFetchingIndexAreas]);

    const { mutateAsync: mutateAsyncArea } = mutationUpdateArea(false);

    ////////////////////////////////////////////
    // ---- EFFECTS                         ----
    ////////////////////////////////////////////

    // When drawing change because data load from api
    // update state drawingImage.image
    useEffect(() => {
        if (drawingImageFromApi) {
            const img = new Image();
            img.src = drawingImageFromApi as string;
            img.onload = () => {
                setDrawingImage((prev) => ({
                    ...prev,
                    image: img,
                }));
            };
        }
    }, [drawingImageFromApi]);

    useEffect(() => {
        setIsLoading(isLoadingShowDrawing || isLoadingImageDrawing);
    }, [isLoadingShowDrawing, isLoadingImageDrawing]);

    useEffect(() => {
        if (drawing?.name) {
            setTitle(drawing?.name);
        }
    }, [drawing?.name]);

    useEffect(() => {
        if (!isFetchingIndexAreas) {
            // get flatten list
            const flattenAreas = getFlattenAreas({ items: areas });

            // transform area in shape
            const shapes: Type_Shape[] = [];
            let initialPosition = 0;
            flattenAreas.forEach((item) => {
                // filter areas by selected ones
                if (selectedAreas.includes(String(item.id))) {
                    shapes.push(
                        formatterAreaToShape(
                            item,
                            initialPosition,
                            drawing || null,
                        ),
                    );
                    initialPosition = initialPosition + 1;
                }
            });
            // update AreaShapes state
            setAreaShapes(shapes);
        }
    }, [areas]);

    ////////////////////////////////////////////
    // ---- FUNCTIONS                       ----
    ////////////////////////////////////////////

    const navigateToDrawings = () => {
        navigate(
            generatePath(
                `${URL_TEAMOTY_PROJECT}/settings/subproject/:subProjectId/drawings` as string,
                {
                    ...urlParams,
                },
            ),
        );
    };

    const openEditDrawingDrawer = () => {
        setOpenDrawingDrawer(true);
    };

    const handleCloseDrawingDrawer = () => {
        setOpenDrawingDrawer(false);

        URL.revokeObjectURL(drawingImageFromApi as string);

        refecthImageDrawing();
    };

    const handleCloseAreaDrawer = () => {
        setOpenAreaDrawer(false);
    };

    const handleAreaSelectionClose = async (hasChanged: boolean) => {
        hasChanged && refetchIndexAreas();
        setOpenAreaSelection(false);
    };
    const handleAreaSelectionOpen = () => {
        setOpenAreaSelection(true);
    };

    const handleShapeStateChange = (shape: Type_Shape) => {
        mutateAsyncArea({
            id: shape.index,
            data: {
                coordinates: shape.pts,
                coordinateName: shape.label.pt,
                ...(drawing && { drawing: formatterShowToSelect(drawing) }),
            },
        });
    };

    const handleAreaEdit = (areaId: number) => {
        setSelectedArea(areaId);
        setOpenAreaDrawer(true);
    };

    const handleAreaReset = async (areaId: number) => {
        // find corresponding shape
        const shapeReset = areaShapes.find(
            (shape: Type_Shape) => shape.index === areaId,
        );

        if (shapeReset) {
            // preserve first point
            const initPos = getMinCoordinate(shapeReset.pts);

            // reset state
            shapeReset.pts = getDefaultShapeCoordinate(initPos);
            shapeReset.label = {
                ...shapeReset.label,
                pt: { x: initPos.x + 5, y: initPos.y + 5 },
                rotation: 0,
                width: 90,
            };

            // Call api
            await mutateAsyncArea({
                id: shapeReset.index,
                data: {
                    coordinates: shapeReset.pts,
                    coordinateName: shapeReset.label.pt,
                    rotationName: shapeReset.label.rotation,
                    widthName: shapeReset.label.width,
                },
            });

            updateShape(shapeReset);
        }
    };

    const updateShape = (shapeToUpdate: Type_Shape) => {
        const shapeIndex = areaShapes.findIndex(
            (shape) => shape.index === shapeToUpdate.index,
        );
        if (shapeIndex !== -1) {
            areaShapes.splice(shapeIndex, 1, shapeToUpdate);
        }

        setAreaShapes([...areaShapes]);
    };

    return (
        <>
            <Header>
                <TMC_Button
                    variant="text"
                    onClick={navigateToDrawings}
                    data-testid="Page-Drawing-Navigation-Back"
                    startIcon={
                        <Icon
                            variant="solid"
                            icon="arrow-left"
                            fontSize="small"
                        />
                    }
                >
                    {fmtCta("Back")}
                </TMC_Button>
                <Typography variant="body1" flexGrow={1} sx={{ pl: 1 }}>
                    {title}
                </Typography>
                <HeaderDropDownMenu
                    openEditDrawingDrawer={openEditDrawingDrawer}
                />
            </Header>
            <Main>
                <Sidebar>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ py: 2 }}
                    >
                        <Typography variant="h5" color="text.secondary">
                            {fmt("Sidebar.Areas")}
                        </Typography>
                        <IconButton
                            data-testid="Page-Drawing-Sidebar-Add-Area"
                            onClick={handleAreaSelectionOpen}
                        >
                            <Icon
                                variant="solid"
                                icon="plus"
                                fontSize="small"
                            />
                        </IconButton>
                    </Box>
                    <LinearProgress
                        sx={{
                            visibility: `${
                                isFetchingIndexAreas ? "visible" : "hidden"
                            } `,
                        }}
                    />
                    {!isFetchingIndexAreas && !areas.length ? (
                        <Typography variant="body3">
                            {fmt("Sidebar.NoAreasYet")}
                        </Typography>
                    ) : (
                        <AreasTreeSidebar
                            areas={areas}
                            handleAreaEdit={handleAreaEdit}
                            handleAreaReset={handleAreaReset}
                            drawingId={drawing?.id as number}
                        />
                    )}
                </Sidebar>
                <Section ref={ref}>
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={isLoading}
                    >
                        <LoadingBox />
                    </Backdrop>
                    <StageProvider>
                        <Stage bounds={bounds}>
                            {drawingImage.image && (
                                <Layer>
                                    <CanvasImage
                                        src={drawingImage.image.src}
                                        grayscale={drawing?.grayscale}
                                    />
                                </Layer>
                            )}
                            <Layer>
                                {areaShapes?.length > 0 &&
                                    areaShapes.map((shape: Type_Shape) => (
                                        <Shape
                                            {...shape}
                                            key={shape.index}
                                            onStateChange={
                                                handleShapeStateChange
                                            }
                                            onDblClick={() =>
                                                handleAreaEdit(shape.index)
                                            }
                                        />
                                    ))}
                                <Group name="top" />
                            </Layer>
                        </Stage>
                    </StageProvider>
                </Section>
            </Main>

            {drawing && (
                <>
                    <DrawingAreaSelection
                        keepMounted
                        open={openAreaSelection}
                        handleClose={handleAreaSelectionClose}
                        maxWidth="sm"
                        drawingId={drawing?.id}
                        selectedAreas={selectedAreas}
                    />
                    <EditDrawingDrawer
                        drawing={drawing as Type_show_drawing}
                        drawingImage={drawingImage}
                        open={openDrawingDrawer}
                        onClose={handleCloseDrawingDrawer}
                        enabledCrop={false}
                    />
                    <AreaDrawer
                        areaIdToUpdate={selectedArea as number}
                        formId={"areas"}
                        page={"areas"}
                        onClose={handleCloseAreaDrawer}
                        open={openAreaDrawer}
                        action={"update"}
                    />
                </>
            )}
        </>
    );
};
