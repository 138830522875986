import { Button, Stack } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { TMC_Button } from "src/components";
import { Dialog } from "src/components/Components_Common/Dialog/Dialog";

export const DialogDemo = () => {
    const [open, setOpen] = useState<boolean>(false);
    const closeDialog = () => setOpen(false);
    const openDialog = () => setOpen(true);

    const [openCustom, setOpencustom] = useState<boolean>(false);
    const closeCustomDialog = () => setOpencustom(false);
    const openCustomDialog = () => setOpencustom(true);

    return (
        <div>
            <Button
                onClick={openDialog}
                data-testid={"Button-openDialog"}
                type="button"
                variant="contained"
            >
                Open Dialog
            </Button>
            <Dialog
                open={open}
                dialogTitle={"Dialog DEMO"}
                name={"demo"}
                onClose={closeDialog}
            >
                DEMO
            </Dialog>
            <Button
                onClick={openCustomDialog}
                data-testid={"Button-custom-openDialog"}
                type="button"
                variant="contained"
            >
                Open Dialog
            </Button>
            <Dialog
                open={openCustom}
                dialogTitle={"Custom Dialog DEMO"}
                name={"demo custom"}
                onClose={closeCustomDialog}
                actions={
                    <Stack flexDirection={"row"} gap={2}>
                        <TMC_Button
                            type="submit"
                            size="medium"
                            variant="outlined"
                            data-testid={`dialog-custom-back-btn`}
                        >
                            Back
                        </TMC_Button>
                        <TMC_Button
                            type="submit"
                            size="medium"
                            variant="contained"
                            data-testid={`dialog-custom-save-btn`}
                        >
                            Save
                        </TMC_Button>
                    </Stack>
                }
            >
                CUSTOM DEMO
            </Dialog>
        </div>
    );
};
