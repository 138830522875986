import { DevTool } from "@hookform/devtools";
import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { AutocompleteFreeSolo } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSolo/AutocompleteFreeSolo";
import { useFormDefaultConfig } from "src/configurations/app";

const options = ["OPC", "CDPGF", "MOE", "Gros-Œuvre", "CEA"];

type Type_MyFormValues = {
    termes: string[];
    termesWithoutLabel: string[];
};

export const ReactHookFormAutocompleteFreeSolo = () => {
    const form = useForm<Type_MyFormValues>({
        ...useFormDefaultConfig,
        defaultValues: {
            termes: options,
            termesWithoutLabel: options,
        },
    });

    return (
        <FormProvider {...form}>
            <form>
                <Stack spacing={2} sx={{ maxWidth: "400px", m: 4 }}>
                    <AutocompleteFreeSolo
                        options={options}
                        name="termes"
                        label={"Termes du bâtiment"}
                        renderInputProps={{ variant: "outlined" }}
                    />
                    <Typography variant="body1Bold">
                        sans Border et Label
                    </Typography>
                    <AutocompleteFreeSolo
                        options={options}
                        name="termesWithoutLabel"
                    />
                </Stack>
            </form>
            <DevTool control={form.control} />
        </FormProvider>
    );
};
