import React from "react";
import { Group } from "react-konva";

import { sizePlanningScroll } from "src/components/Components_Teamoty/Planning/Planning.const";
import {
    Enum_typeChart,
    Type_Props_PlanningWorkforceButtons,
} from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";
import { PlanningWorkforceButton } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforceButton";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const PlanningWorkforceButtons = ({
    x,
    y,
    buttons,
    currentChart,
    changeChart,
}: Type_Props_PlanningWorkforceButtons) => {
    const updateChart = (chart: Enum_typeChart): void => {
        changeChart(chart);
    };

    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Planning");

    return (
        <Group x={x} y={y}>
            {buttons.map((chart, index) => (
                <PlanningWorkforceButton
                    key={index}
                    x={index * sizePlanningScroll}
                    y={0}
                    width={chart.width}
                    height={chart.height}
                    pictogram={chart.pictogram}
                    title={fmt(chart.titleKey)}
                    selected={chart.typeChart === currentChart}
                    onChange={() => updateChart(chart.typeChart)}
                />
            ))}
        </Group>
    );
};
