import dayjs, { Dayjs } from "dayjs";
import React from "react";

import {
    displayFormattedDate,
    displayFormattedDateTime,
    getCurrentDateTimeFormat,
    getCurrentFormat,
} from "src/utils/date";

interface Props {
    date: Dayjs;
    language?: string;
}

export const FormattedDate = ({ date, language }: Props) => {
    // eslint-disable-next-line import/no-named-as-default-member
    const locale: string = dayjs.locale();
    const format: string = getCurrentFormat(language);
    const formatDateTime: string = getCurrentDateTimeFormat(language);

    const formattedDate: string = displayFormattedDate(date, language);
    const formattedDateTime: string = displayFormattedDateTime(date, language);
    return (
        <div>
            {locale} | {format} | {formatDateTime} | {formattedDate} |{" "}
            {formattedDateTime}
        </div>
    );
};
