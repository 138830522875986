import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Checkbox, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateCompany,
    useIndexCompanies,
} from "src/api/tms-commons/companies";
import { formatterIndexCompanies } from "src/api/tms-commons/companies/formatters";
import { searchCompany } from "src/api/tms-commons/companies/services";
import {
    Type_com_index_company,
    Type_index_company,
} from "src/api/tms-commons/companies/types";
import { mutationAddCompanyUser } from "src/api/tms-users/users";
import { TMC_Button } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Schema_AddCompanyUserForm = Yup.object().shape({
    company: Yup.object().required(FORM_ERR_FMT.REQUIRED),
    nationalId: Yup.string().nullable(),
    postalCode: Yup.string()
        .max(10, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    city: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
    country: Yup.string()
        .max(100, FORM_ERR_FMT.MAX_LENGTH)
        .required(FORM_ERR_FMT.REQUIRED),
});

type Type_Props_AddCompanyUserForm = {
    userId: number;
};

export const AddCompanyUserForm = ({
    userId,
}: Type_Props_AddCompanyUserForm) => {
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Teamoty.AddCompany");
    const [showSearch, setShowSearch] = useState<Type_index_company[]>([]);
    const [action, setAction] = useState<Type_action>("");
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);

    const { data: companiesList, isFetching: isFetchingCompaniesList } =
        useIndexCompanies() || {};

    const { mutateAsync: mutateUpdateUser, isLoading } =
        mutationAddCompanyUser(userId) || {};

    const { mutateAsync: mutateCreateCompany } = mutationCreateCompany() || {};

    const handleSubmit = async (values: any, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault();

        try {
            if (action === "create") {
                const searchResult = await searchCompany(
                    values.company.name,
                    values.company.id,
                );
                const companies = searchResult?.data?.data;

                if (
                    !confirm &&
                    Array.isArray(companies) &&
                    companies.length > 0
                ) {
                    setShowSearch(
                        formatterIndexCompanies(
                            companies as Type_com_index_company[],
                        ),
                    );
                    return;
                }

                const { data } = await mutateCreateCompany({
                    ...values,
                    name: values.company.name,
                });

                await mutateUpdateUser(data?.data?.id);
            } else if (action === "") {
                await mutateUpdateUser(values.company.id);
            }
        } catch (error) {
            console.error("Error in handleSubmit:", error);
        }
    };

    const form = useForm({
        defaultValues: {
            nationalId: "",
            postalCode: "",
            city: "",
            country: "",
        },
        resolver: yupResolver<any>(Schema_AddCompanyUserForm),
    });

    useEffect(() => {
        const company = form.watch("company");

        if (company && company.id) {
            // Cas où une société existante est sélectionnée
            setAction(""); // Réinitialise l'action
            setIsDisabled(true); // Désactive les champs
            form.setValue("nationalId", company.nationalId || "");
            form.setValue("postalCode", company?.postalCode || "");
            form.setValue("city", company?.city || "");
            form.setValue("country", company?.country || "");
        } else if (action === "create") {
            // Cas où on ajoute une nouvelle société
            setIsDisabled(false); // Active les champs
        }
    }, [form.watch("company"), action]);

    const handleAddNewValue = (value: any) => {
        // Cas où l'utilisateur veut ajouter une nouvelle société
        setAction("create");
        setIsDisabled(false); // Active les champs pour une nouvelle société
        form.setValue("company", { id: null, name: value.name });
        form.setValue("nationalId", "");
        form.setValue("postalCode", "");
        form.setValue("city", "");
        form.setValue("country", "");
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Stack gap={2} width={"100%"}>
                    <AutocompleteFreeSoloDialog
                        isFetching={isFetchingCompaniesList}
                        multiple={false}
                        options={companiesList?.companies || []}
                        name="company"
                        freeSolo={true}
                        onAddNewValue={(value) => {
                            handleAddNewValue(value);
                        }}
                        renderInputProps={{ variant: "outlined" }}
                        label={fmtLabels("FindCompany")}
                    />
                    <TextField
                        data-testid="AddCompanyForm-nationalId"
                        variant={"outlined"}
                        name="nationalId"
                        label={fmtLabels(`NationalId`)}
                        disabled={isDisabled}
                    />
                    <Typography variant={"body2"} color={"text.secondary"}>
                        {fmt("OptionalNationalId")}
                    </Typography>
                    <TextField
                        data-testid="AddCompanyForm-postalCode"
                        variant={"outlined"}
                        name="postalCode"
                        label={fmtLabels(`ZipCode`)}
                        disabled={isDisabled}
                    />
                    <TextField
                        data-testid="AddCompanyForm-city"
                        variant={"outlined"}
                        name="city"
                        label={fmtLabels(`City`)}
                        disabled={isDisabled}
                    />
                    <TextField
                        data-testid="AddCompanyForm-country"
                        variant={"outlined"}
                        name="country"
                        label={fmtLabels(`Country`)}
                        disabled={isDisabled}
                    />
                    {showSearch.length > 0 ? (
                        <>
                            <Alert severity="error">
                                {fmt("CompaniesSimilar")}
                                <ul>
                                    {showSearch.map((elem, index) => (
                                        <li key={index}>
                                            {elem.name +
                                                ", " +
                                                elem.city +
                                                ", " +
                                                elem.country}
                                        </li>
                                    ))}
                                </ul>
                            </Alert>
                            <Stack
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <Checkbox
                                    onClick={() => setConfirm(!confirm)}
                                    checked={confirm}
                                />
                                <Typography mt={2}>
                                    {fmt("ConfirmCompany")}
                                </Typography>
                            </Stack>
                        </>
                    ) : (
                        ""
                    )}
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"end"}
                        marginTop={2}
                    >
                        <TMC_Button
                            data-testid="AddCompanyForm-Submit-btn"
                            type="submit"
                            variant="contained"
                            startIcon={
                                <Icon variant={"solid"} icon={"check"} />
                            }
                            disabled={isLoading}
                        >
                            {fmt("CtaSave")}
                        </TMC_Button>
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
};
