import { debounce } from "@mui/material";
import { FormikConfig, FormikErrors, FormikValues, useFormik } from "formik";
import { useCallback, useEffect } from "react";

import { useDidMount } from "src/hooks/useDidMount";

type Type_Props_useFormikSaveOnChange = Omit<FormikConfig<any>, "onSubmit"> & {
    handleSubmit: (values: FormikValues) => void;
    debounceDelay?: number; // Delay in milliseconds
};

type Type_useFormikSaveOnChange = {
    handleQuit: () => void;
};

export function useFormikSaveOnChange({
    initialValues,
    validationSchema,
    handleSubmit,
    debounceDelay = 2000,
}: Type_Props_useFormikSaveOnChange) {
    const didMount = useDidMount();

    const formik = useFormik<any>({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
        // For on change submit
        // validateOnBlur: false,
        // validateOnChange: true,
        // validateOnMount: false,
        // TODO validation only on blur seems to not be working correctly
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
    });

    ////////////////////////////////////////////
    // ---- FUNCTIONS                       ----
    ////////////////////////////////////////////

    const submitFilteredData = useCallback(
        (values: FormikValues, errors: FormikErrors<any>) => {
            console.log("submitFilteredData called");

            //on filtre les champs ayant des erreurs
            const filteredData = Object.keys(values).reduce(
                (acc: FormikValues, key) => {
                    if (!Object.prototype.hasOwnProperty.call(errors, key)) {
                        acc[key] = values[key];
                    }
                    return acc;
                },
                {},
            );

            // on appelle la mutation en lui passant les valeurs à envoyer :
            console.debug("SUBMIT:", { filteredData });
            handleSubmit(filteredData);
        },
        [],
    );

    const debounceSubmitFilteredData = useCallback(
        debounce(
            (values: FormikValues, errors: FormikErrors<any>) =>
                submitFilteredData(values, errors),
            debounceDelay,
        ),
        [],
    );

    const handleQuit = () => {
        debounceSubmitFilteredData.clear();
        submitFilteredData(formik.values, formik.errors);
    };

    ////////////////////////////////////////////
    // ---- EFFECTS                         ----
    ////////////////////////////////////////////

    /**
     * Submit form on field change
     * Triggered on isValidating change instead of formik.values
     * because we have to wait until validation is complete
     */
    useEffect(() => {
        if (
            didMount &&
            !formik.isValidating && // wait for formik validation after value have changed
            formik.dirty // prevent submit form on first loading
        ) {
            console.debug("Call submit form", formik.values, formik.errors);
            submitFilteredData(formik.values, formik.errors); // for onBlur submit
            // debounceSubmitFilteredData(formik.values, formik.errors); // for onChange submit
        }
    }, [formik.isValidating, formik.dirty]);

    return {
        ...formik,
        handleQuit,
    } as typeof formik & Type_useFormikSaveOnChange;
}
