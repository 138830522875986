import {
    Box,
    Checkbox,
    Popover,
    Stack,
    TextFieldProps,
    Typography,
} from "@mui/material";
import {
    SimpleTreeView,
    TreeItem,
    TreeItemContentProps,
    TreeItemProps,
    useTreeItemState,
} from "@mui/x-tree-view";
import { clsx } from "clsx";
import React, {
    ChangeEvent,
    forwardRef,
    SyntheticEvent,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Type_sch_index_sequenceFolder } from "src/api/tms-scheduling/sequenceFolders/types";
import { Type_sch_index_sequence } from "src/api/tms-scheduling/sequences/types";
import { useIndexTasks } from "src/api/tms-scheduling/tasks";
import { Type_sch_index_task } from "src/api/tms-scheduling/tasks/types";
import { LoadingBox, TMC_TextField } from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

type Type_resetValue = { id: null; name: string };
type Type_resetValueMultiple = string[];

type Type_Tree_AutoComplete_Tasks = {
    sequenceFolder: Type_sch_index_sequenceFolder;
    sequences: {
        sequence: Type_sch_index_sequence;
        tasks: Type_sch_index_task[];
    }[];
};

type Type_Props_TaskIcon = {
    task: Type_sch_index_task;
};

const TaskIcon = ({ task }: Type_Props_TaskIcon) => {
    const taskTypeList: any[] = [
        {
            slug: "work",
            value: 1,
            icon: {
                variant: "kit",
                icon: "light-tds-task-work",
            },
        },
        {
            slug: "study",
            value: 2,
            icon: {
                variant: "kit",
                icon: "light-tds-task-study",
            },
        },

        {
            slug: "waiting",
            value: 3,
            icon: {
                variant: "kit",
                icon: "light-tds-task-waiting",
            },
        },
        {
            slug: "delivery",
            value: 5,
            icon: {
                variant: "kit",
                icon: "light-tds-task-delivery",
            },
        },

        {
            slug: "milestone",
            value: 4,
            icon: {
                variant: "kit",
                icon: "light-tds-task-milestone",
            },
        },
        {
            slug: "technical",
            value: 6,
            icon: {
                variant: "kit",
                icon: "light-tds-task-technical",
            },
        },
    ];

    const icon = taskTypeList.find(
        (taskType: any) => taskType.value === task.type,
    );

    if (icon) {
        return (
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                    backgroundColor: task.color || COLORS.moon950,
                    borderRadius: "3px",
                    width: "16px",
                    height: "16px",
                }}
            >
                <Icon
                    icon={icon.icon.icon}
                    variant={icon.icon.variant}
                    sx={{ color: invertColor(task.color || COLORS.moon950) }}
                />
            </Stack>
        );
    }
    return null;
};
const findValue = (
    arr: Type_sch_index_task[],
    id: number | null,
    resetValue: Type_resetValue,
): Type_resetValue | Type_sch_index_task => {
    if (id === null || id === undefined) return resetValue;
    const result: Type_resetValue | Type_sch_index_task =
        arr.find((task) => task.id === id) || resetValue;
    return result;
};

const groupedTasks = (
    tasks: Type_sch_index_task[],
): Type_Tree_AutoComplete_Tasks[] => {
    // Create an object to store the grouped tasks
    const groupedTasks: any = {};

    // Iterate through the tasks array
    tasks.forEach((task) => {
        const sequenceFolderId = task.sequence.sequenceFolder.id;
        const sequenceId = task.sequence.id;

        // Create sequence folder if it doesn't exist
        if (!groupedTasks[sequenceFolderId]) {
            groupedTasks[sequenceFolderId] = {
                sequenceFolder: task.sequence.sequenceFolder,
                sequences: {},
            };
        }

        // Create sequence if it doesn't exist
        if (!groupedTasks[sequenceFolderId].sequences[sequenceId]) {
            groupedTasks[sequenceFolderId].sequences[sequenceId] = {
                sequence: task.sequence,
                tasks: [],
            };
        }

        // Add task to the sequence
        groupedTasks[sequenceFolderId].sequences[sequenceId].tasks.push(task);
    });

    // Convert the grouped tasks object into an array
    const formattedTasks = Object.keys(groupedTasks).map((folderId) => {
        const folder = groupedTasks[folderId];
        const sequences = Object.keys(folder.sequences).map((sequenceId) => {
            return folder.sequences[sequenceId];
        });
        return { ...folder, sequences };
    });

    return formattedTasks;
};

/**
 * Component from MuiX doc
 * @see https://v6.mui.com/x/react-tree-view/#contentcomponent-prop
 */
const CustomContent = forwardRef(function CustomContent(
    props: TreeItemContentProps,
    ref,
) {
    const {
        classes,
        className,
        label,
        itemId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItemState(itemId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        preventSelection(event);
    };

    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleSelection(event);
    };

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div
                onClick={handleExpansionClick}
                className={classes.iconContainer}
            >
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});

/**
 * Component from MuiX doc
 * @see https://v6.mui.com/x/react-tree-view/#contentcomponent-prop
 */
const CustomTreeItem = forwardRef(function CustomTreeItem(
    props: TreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
});

type Type_Props_AutocompleteTasksCustom = TextFieldProps & {
    label: string;
    name: string;
    hideTaskId?: number;
    multiple?: boolean;
};

/**
 * @deprecated use AutocompleteTasks instead
 */
export const AutocompleteTasksCustom = ({
    label,
    name,
    hideTaskId,
    multiple = false,
}: Type_Props_AutocompleteTasksCustom) => {
    // i18n
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtPlaceholder } =
        useCoreIntl("Form.Placeholder");

    // state
    const [tasks, setTasks] = useState<Type_sch_index_task[]>([]);
    const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    // API
    const { isFetching } = useIndexTasks(setTasks) || {};

    // React hook form
    const { formState, control, getValues, setValue } = useFormContext();
    const resetValue: Type_resetValue | Type_resetValueMultiple = multiple
        ? []
        : { id: null, name: "" };

    // utils
    const groupedTasksData = useMemo(() => groupedTasks(tasks), [tasks]);

    useEffect((): void => {
        !isFetching &&
            !multiple &&
            setValue(
                name,
                findValue(
                    tasks,
                    getValues(name)?.id,
                    resetValue as Type_resetValue,
                ),
            );
        !isFetching && multiple && setValue(name, getValues(name));
    }, [isFetching]);

    const handleReset = (): void => {
        setValue(name, resetValue);
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const open: boolean = Boolean(anchorEl);
    const id: "task-autocomplete-popover" | undefined = open
        ? "task-autocomplete-popover"
        : undefined;

    const handleSelect = (
        _event: SyntheticEvent<Element, Event>,
        nodeId: any,
    ): void => {
        if (nodeId && !isNaN(parseInt(nodeId))) {
            if (!multiple) {
                const selectedTask = findValue(
                    tasks,
                    parseInt(nodeId),
                    resetValue as Type_resetValue,
                );
                setValue(name, selectedTask);
            }
            !multiple && handleClose();
        }
    };

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (event.target.checked) {
            setSelectedTasks((prev) => [...prev, event.target.value]);
        } else {
            setSelectedTasks((prev) => [
                ...prev.filter((id) => id !== event.target.value),
            ]);
        }
    };

    useEffect(() => {
        setValue(name, selectedTasks);
    }, [selectedTasks]);

    useEffect(() => {
        if (!groupedTasksData.length) return;

        const expandedSet = new Set<string>();

        groupedTasksData.forEach((sequenceFolder) => {
            let folderHasSelectedTask = false;

            sequenceFolder.sequences.forEach((sequence) => {
                const sequenceHasSelectedTask = sequence.tasks.some((task) =>
                    selectedTasks.includes(String(task.id)),
                );

                if (sequenceHasSelectedTask) {
                    folderHasSelectedTask = true;
                    expandedSet.add(`sequence_${sequence.sequence.id}`);
                }
            });

            if (folderHasSelectedTask) {
                expandedSet.add(
                    `sequenceFolder_${sequenceFolder.sequenceFolder.id}`,
                );
            }
        });
        setExpandedItems(Array.from(expandedSet));
    }, [open, selectedTasks]);

    const handleCheckGroup = (
        event: React.MouseEvent<HTMLElement>,
        action: "addAll" | "removeAll",
        sequenceFolderIndex: number,
        sequenceIndex?: number,
    ) => {
        event.stopPropagation();
        const sequenceFolder = groupedTasksData[sequenceFolderIndex];
        let tasksToCheck = [];

        if (sequenceIndex !== undefined) {
            // Récupération des tâches d'une séquence spécifique
            tasksToCheck = sequenceFolder.sequences[sequenceIndex]?.tasks || [];
        } else {
            // Récupération de toutes les tâches du sequenceFolder
            tasksToCheck = sequenceFolder.sequences.flatMap((seq) => seq.tasks);
        }

        const taskIdsToCheck = tasksToCheck.map((task) => task.id.toString());

        if (action === "addAll") {
            setSelectedTasks((prev) => [
                ...new Set([...prev, ...taskIdsToCheck]),
            ]);
        } else if (action === "removeAll") {
            setSelectedTasks((prev) =>
                prev.filter((id) => !taskIdsToCheck.includes(id)),
            );
        }
    };

    const handleExpandedItemsChange = (
        _event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedItems(itemIds);
    };

    return (
        <Stack flex={1}>
            <Controller
                control={control}
                name={name}
                render={({ field: { ref, value, ...field } }) => (
                    <TMC_TextField
                        onClick={handleClick}
                        label={label}
                        value={
                            multiple && value
                                ? !selectedTasks?.length
                                    ? fmtPlaceholder("SelectTasks")
                                    : `${selectedTasks?.length} ${fmtPlaceholder("TasksSelected")}`
                                : value && value.name
                                  ? value.name
                                  : ""
                        }
                        fullWidth
                        inputRef={ref}
                        inputProps={{ readOnly: true }}
                        {...field}
                        error={!!formState.errors[name]}
                        helperText={formState.errors[name]?.message as string}
                    />
                )}
            />
            {!multiple && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <button type="button" onClick={handleReset}>
                        <Typography variant={"buttonSmall"} sx={{ margin: 0 }}>
                            {fmtActions("Clear")}
                        </Typography>
                    </button>
                </div>
            )}
            <div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    sx={{ backgroundColor: "transparent", overflowY: "auto" }}
                >
                    <Box sx={{ minWidth: "600px", p: 2 }}>
                        {isFetching ? (
                            <LoadingBox />
                        ) : (
                            <SimpleTreeView
                                aria-label="Tree tasks"
                                slots={{
                                    expandIcon: MyExpandIcon,
                                    collapseIcon: MyCollapseIon,
                                }}
                                onSelectedItemsChange={handleSelect}
                                sx={{ scroll: "auto " }}
                                expandedItems={expandedItems}
                                onExpandedItemsChange={
                                    handleExpandedItemsChange
                                }
                            >
                                {groupedTasksData.map((folder, index) => (
                                    <SequenceFolderTreeItem
                                        key={index}
                                        folder={folder}
                                        multiple={multiple}
                                        hideTaskId={hideTaskId}
                                        handleCheck={handleCheck}
                                        selectedTasks={selectedTasks}
                                        handleCheckGroup={handleCheckGroup}
                                        indexSequenceFolder={index}
                                    />
                                ))}
                            </SimpleTreeView>
                        )}
                    </Box>
                </Popover>
            </div>
        </Stack>
    );
};

type Type_Props_SequenceFolderTreeView = {
    folder: Type_Tree_AutoComplete_Tasks;
    multiple: boolean;
    handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedTasks: string[];
    handleCheckGroup: (
        event: React.MouseEvent<HTMLElement>,
        action: "addAll" | "removeAll",
        sequenceFolderIndex: number,
        sequenceIndex?: number,
    ) => void;
    indexSequenceFolder: number;
    hideTaskId?: number;
};

const SequenceFolderTreeItem = ({
    folder,
    multiple,
    hideTaskId,
    handleCheck,
    selectedTasks,
    handleCheckGroup,
    indexSequenceFolder,
}: Type_Props_SequenceFolderTreeView) => {
    const [btnVisible, setBtnVisible] = useState<boolean>(false);

    return (
        <TreeItem
            key={`sequenceFolder_${folder.sequenceFolder.id}`}
            itemId={`sequenceFolder_${folder.sequenceFolder.id}`}
            slots={{ icon: MyCustomIcon }}
            onMouseOver={() => setBtnVisible(true)}
            onMouseLeave={() => setBtnVisible(false)}
            label={
                !multiple ? (
                    folder.sequenceFolder.name
                ) : (
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        spacing={2}
                        sx={{ height: "32px" }}
                    >
                        <Typography flexGrow={1}>
                            {folder.sequenceFolder.name}
                        </Typography>
                        <Box display={btnVisible ? "visible" : "none"}>
                            <IconButton
                                data-testid={`TasksTreeView-${folder.sequenceFolder.name}-check-all-children`}
                                onClick={(event) => {
                                    handleCheckGroup(
                                        event,
                                        "addAll",
                                        indexSequenceFolder,
                                    );
                                }}
                            >
                                <Icon variant="light" icon="check" />
                            </IconButton>
                            <IconButton
                                data-testid={`TasksTreeView-${folder.sequenceFolder.name}-uncheck-all-children`}
                                onClick={(event) => {
                                    handleCheckGroup(
                                        event,
                                        "removeAll",
                                        indexSequenceFolder,
                                    );
                                }}
                            >
                                <Icon variant="light" icon="xmark" />
                            </IconButton>
                        </Box>
                    </Stack>
                )
            }
        >
            {folder.sequences.map((sequence, index) => (
                <SequenceTreeItem
                    key={index}
                    sequence={sequence}
                    hideTaskId={hideTaskId}
                    multiple={multiple}
                    handleCheck={handleCheck}
                    selectedTasks={selectedTasks}
                    indexSequenceFolder={indexSequenceFolder}
                    indexSequence={index}
                    handleCheckGroup={handleCheckGroup}
                />
            ))}
        </TreeItem>
    );
};

type Type_Props_SequenceTreeItem = {
    sequence: {
        sequence: Type_sch_index_sequence;
        tasks: Type_sch_index_task[];
    };
    multiple: boolean;
    handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCheckGroup: (
        event: React.MouseEvent<HTMLElement>,
        action: "addAll" | "removeAll",
        sequenceFolderIndex: number,
        sequenceIndex?: number,
    ) => void;
    selectedTasks: string[];
    indexSequenceFolder: number;
    indexSequence: number;
    hideTaskId?: number;
};

const SequenceTreeItem = ({
    sequence,
    hideTaskId,
    multiple,
    handleCheck,
    selectedTasks,
    handleCheckGroup,
    indexSequenceFolder,
    indexSequence,
}: Type_Props_SequenceTreeItem) => {
    const [btnVisible, setBtnVisible] = useState<boolean>(false);

    return (
        <CustomTreeItem
            onMouseOver={() => setBtnVisible(true)}
            onMouseLeave={() => setBtnVisible(false)}
            key={`sequence_${sequence.sequence.id}`}
            itemId={`sequence_${sequence.sequence.id}`}
            label={
                !multiple ? (
                    sequence.sequence.name
                ) : (
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        spacing={2}
                        sx={{ height: "32px" }}
                    >
                        <Typography flexGrow={1}>
                            {sequence.sequence.name}
                        </Typography>
                        <Box display={btnVisible ? "visible" : "none"}>
                            <IconButton
                                data-testid={`TasksTreeView-${sequence.sequence.name}-check-all-children`}
                                onClick={(event) => {
                                    handleCheckGroup(
                                        event,
                                        "addAll",
                                        indexSequenceFolder,
                                        indexSequence,
                                    );
                                }}
                            >
                                <Icon variant="light" icon="check" />
                            </IconButton>
                            <IconButton
                                data-testid={`TasksTreeView-${sequence.sequence.name}-uncheck-all-children`}
                                onClick={(event) => {
                                    handleCheckGroup(
                                        event,
                                        "removeAll",
                                        indexSequenceFolder,
                                        indexSequence,
                                    );
                                }}
                            >
                                <Icon variant="light" icon="xmark" />
                            </IconButton>
                        </Box>
                    </Stack>
                )
            }
        >
            {sequence.tasks
                .filter((task) => task.id !== hideTaskId)
                .map((task) => (
                    <TaskItem
                        key={task.id}
                        task={task}
                        multiple={multiple}
                        handleCheck={handleCheck}
                        selectedTasks={selectedTasks}
                    />
                ))}
        </CustomTreeItem>
    );
};

type Type_Props_TaskItem = {
    task: Type_sch_index_task;
    handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedTasks: string[];
    multiple: boolean;
};

const TaskItem = ({
    task,
    multiple,
    handleCheck,
    selectedTasks,
}: Type_Props_TaskItem) => {
    const customOnClick = (
        event: React.MouseEvent<HTMLElement>,
        id: string,
    ) => {
        const target = event.target as HTMLInputElement;
        target.value = id;
        target.checked = !selectedTasks.includes(id);
        handleCheck(event as unknown as ChangeEvent<HTMLInputElement>);
    };

    return (
        <CustomTreeItem
            key={task.id}
            itemId={String(task.id)}
            label={
                !multiple ? (
                    task.name
                ) : (
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        spacing={2}
                        onClick={(e) => customOnClick(e, String(task.id))}
                    >
                        <Checkbox
                            data-testid={`TasksTreeView-${task.name}-checkbox`}
                            checked={
                                selectedTasks.indexOf(String(task.id)) !== -1
                            }
                            value={String(task.id)}
                            disableRipple
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            sx={{ padding: "4px 0" }}
                            onChange={handleCheck}
                        />
                        <Typography flexGrow={1}>{task.name}</Typography>
                    </Stack>
                )
            }
            slots={{
                icon: () => MyCustomTaskIcon(task),
            }}
        />
    );
};

const MyExpandIcon = () => {
    return <Icon variant="solid" icon="chevron-right" />;
};
const MyCollapseIon = () => {
    return <Icon variant="solid" icon="chevron-down" />;
};
const MyCustomIcon = () => {
    return <Icon icon="folder" variant="light" />;
};
const MyCustomTaskIcon = (task: Type_sch_index_task) => {
    return <TaskIcon task={task} />;
};
