import Konva from "konva";

// Fonction pour changer le curseur
export const changeCursor = (
    e: Konva.KonvaEventObject<MouseEvent>,
    cursorStyle: string,
): void => {
    e.cancelBubble = true;

    const container: HTMLDivElement | undefined = e.currentTarget
        .getStage()
        ?.getContent();

    if (container) container.style.cursor = cursorStyle;
};
