import Konva from "konva";
import { useCallback, useState } from "react";
import { Circle } from "react-konva";

import { mutationCreateTaskLink } from "src/api/tms-scheduling/taskLinks";
import { Type_sch_post_taskLink } from "src/api/tms-scheduling/taskLinks/types";
import { Type_point } from "src/components/Components_Common/canvas/types";
import { Enum_typeCircleFlow } from "src/components/Components_Teamoty/Flow/Flow.enum";
import { findTypeFlow } from "src/components/Components_Teamoty/Flow/tools/findTypeFlow";
import {
    borderDashStylePlanningTask,
    minPlanningDist,
    radiusPlanningCircle,
    radiusPlanningCircleOver,
    usePlanningTheme,
    widthPlanningCircleStroke,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import {
    color_shape_planning,
    Type_props_component_shape_planning,
} from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.const";
import { Conv_typePlanningTask } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.enum";
import {
    Type_planningTaskWithDate,
    Type_Props_PlanningShapeSelectedCircle,
} from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { usePlanningContext } from "src/contexts/planning";
import { useChannel } from "src/hooks/useChannel";

export const PlanningShapeCircle = ({
    x,
    y,

    taskX,
    taskY,

    task,
    type,

    selected,
    setSelected,
}: Type_Props_PlanningShapeSelectedCircle) => {
    const { defaultCursor } = usePlanningContext();
    const theme = usePlanningTheme();

    const [onPoint, setOnPoint] = useState<boolean>(false);

    const name: string = "circlePlanning";

    const getDistance = (p1: Type_point, p2: Type_point): number => {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    };

    const startLink = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;

        e.target.x(e.currentTarget.attrs.startX);
        e.target.y(e.currentTarget.attrs.startY);

        setSelected((prev) => {
            prev &&
                (prev.link = {
                    taskFromKey: task.key,
                    taskFrom: task,
                    typeFrom: type,
                    ptFrom: {
                        x: e.currentTarget.attrs.startX + taskX,
                        y: e.currentTarget.attrs.startY + taskY,
                    },
                    ptTo: {
                        x: e.currentTarget.attrs.startX + taskX,
                        y: e.currentTarget.attrs.startY + taskY,
                    },
                });
            return prev;
        });
    };

    const moveLink = (e: Konva.KonvaEventObject<DragEvent>): void => {
        e.cancelBubble = true;

        e.target.x(e.currentTarget.attrs.startX);
        e.target.y(e.currentTarget.attrs.startY);

        const shape: Konva.Shape = e.currentTarget as Konva.Shape;
        const stage: Konva.Stage | null = shape.getStage();
        const group: Konva.Group = shape
            .getParent()
            ?.getParent() as Konva.Group;

        if (stage && group) {
            let mousePos = group.getRelativePointerPosition() as Type_point;
            if (mousePos) {
                mousePos.x -= group.x();
                mousePos.y -= group.y();

                const layer: Konva.Layer | null = shape.getLayer();
                if (layer) {
                    const nodes = layer.find("." + name);
                    nodes.forEach((node): void => {
                        if (node.attrs.id === task.key) return;

                        const pt = {
                            x: node.attrs.taskX + node.attrs.x,
                            y: node.attrs.taskY + node.attrs.y,
                        };

                        const dist: number = getDistance(pt, {
                            x: mousePos.x,
                            y: mousePos.y,
                        });

                        if (dist < minPlanningDist) {
                            mousePos = pt;
                        }
                    });
                }

                setSelected((prev) => {
                    prev &&
                        prev.link &&
                        (prev.link.ptTo = {
                            x: mousePos.x,
                            y: mousePos.y,
                        });
                    return prev ? { ...prev } : prev;
                });
            }
        }
    };

    const { mutateAsync: createTaskLink } = mutationCreateTaskLink();
    const { sendEvent } = useChannel({});

    const endLink = useCallback(
        async (e: Konva.KonvaEventObject<DragEvent>) => {
            e.cancelBubble = true;

            e.target.x(e.currentTarget.attrs.startX);
            e.target.y(e.currentTarget.attrs.startY);

            const shape: Konva.Shape = e.currentTarget as Konva.Shape;
            const stage: Konva.Stage | null = shape.getStage();
            const group: Konva.Group = shape
                .getParent()
                ?.getParent() as Konva.Group;

            let typeTo: Enum_typeCircleFlow = Enum_typeCircleFlow.Start;

            if (stage) {
                let taskTo: Type_planningTaskWithDate | null = null;

                const mousePos =
                    group.getRelativePointerPosition() as Type_point;
                if (mousePos) {
                    mousePos.x -= group.x();
                    mousePos.y -= group.y();

                    const layer: Konva.Layer | null = shape.getLayer();
                    if (layer) {
                        const nodes = layer.find("." + name);
                        nodes.forEach((node): void => {
                            if (node.attrs.id === task.key) return;

                            const pt = {
                                x: node.attrs.taskX + node.attrs.x,
                                y: node.attrs.taskY + node.attrs.y,
                            };

                            const dist: number = getDistance(pt, {
                                x: mousePos.x,
                                y: mousePos.y,
                            });

                            if (dist < minPlanningDist) {
                                taskTo = node.attrs
                                    .task as Type_planningTaskWithDate;
                                typeTo = parseInt(node.attrs.type);
                            }
                        });
                    }
                }

                if (taskTo) {
                    await createTaskLink({
                        linkType: findTypeFlow(
                            selected?.link?.typeFrom,
                            typeTo,
                        ),

                        taskFrom_id: selected?.link?.taskFrom.id,
                        taskTo_id: (taskTo as Type_planningTaskWithDate).id,

                        areaFrom_id: selected?.link?.taskFrom?.areaId,
                        areaTo_id: (taskTo as Type_planningTaskWithDate).areaId,

                        taskAreaFrom_id: selected?.link?.taskFrom?.taskAreaId,
                        taskAreaTo_id: (taskTo as Type_planningTaskWithDate)
                            .taskAreaId,

                        callback: (prev: Type_sch_post_taskLink) => prev,
                    });
                }
            }

            setSelected((prev) => {
                prev && delete prev.link;
                return prev;
            });

            sendEvent("updatePlanning");
        },
        [selected],
    );

    const mouseOver = useCallback(
        (e: Konva.KonvaEventObject<DragEvent>): void => {
            changeCursor(e, "pointer");
            setOnPoint(true);
        },
        [],
    );

    const mouseOut = useCallback(
        (e: Konva.KonvaEventObject<DragEvent>): void => {
            changeCursor(e, defaultCursor);
            setOnPoint(false);
        },
        [],
    );

    // Calcule de la couleur à appliquer
    const typeShape: Type_props_component_shape_planning =
        Conv_typePlanningTask[task.type] as Type_props_component_shape_planning;
    const colorShapePlanningFunction = color_shape_planning[typeShape];
    const colors = colorShapePlanningFunction(task.color);

    return (
        <Circle
            id={task.key}
            type={type}
            name={name}
            x={x}
            y={y}
            radius={onPoint ? radiusPlanningCircleOver : radiusPlanningCircle}
            fill={
                selected && selected.taskKey == task.key
                    ? theme.colorPlanningCircleFill
                    : theme.transparent
            }
            stroke={
                selected && selected.taskKey == task.key
                    ? theme.colorPlanningSelected
                    : selected?.link
                      ? colors.textColor
                      : theme.transparent
            }
            strokeWidth={widthPlanningCircleStroke}
            startX={x}
            startY={y}
            taskX={taskX}
            taskY={taskY}
            task={task}
            hitStrokeWidth={5}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            draggable={true}
            onDragStart={startLink}
            onDragMove={moveLink}
            onDragEnd={endLink}
            listening={!!(selected && selected.taskKey == task.key)}
            dash={selected ? undefined : borderDashStylePlanningTask}
        />
    );
};
