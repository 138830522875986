import {
    get,
    GetReturnType,
    post,
    put,
    remove,
    Type_RequestConfig,
} from "src/api/fetch";
import { LanguagesPath, Url } from "src/api/paths";
import {
    Type_prj_post_language,
    Type_prj_put_language,
} from "src/api/tms-projects/languages/types";

export const indexLanguages = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${LanguagesPath.LANGUAGES}`, requestConfig);
};

export const createLanguage = (
    language: Type_prj_post_language,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return post(
        language,
        `${Url.PROJECTS}${LanguagesPath.LANGUAGES}`,
        requestConfig,
    );
};

export const updateLanguage = (
    language: Type_prj_put_language,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return put(
        language,
        `${Url.PROJECTS}${LanguagesPath.LANGUAGES}/${language.locale}`,
        requestConfig,
    );
};

export const deleteLanguage = (
    locale: string,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return remove(
        `${Url.PROJECTS}${LanguagesPath.LANGUAGES}/${locale}`,
        requestConfig,
    );
};
