import { Type_event_constraint } from "src/hooks/useChannel";
import { formatDateForBack, formatDateForFront } from "src/utils/date";

import {
    Type_index_constraint,
    Type_post_constraint,
    Type_sch_index_constraint,
    Type_sch_post_constraint,
    Type_sch_put_constraint,
} from "./types";

export const formatterIndexConstraint = (
    constraint: Type_sch_index_constraint,
): Type_index_constraint => {
    const { task_area_id, priority, date, ...rest } = constraint;
    return {
        ...rest,
        priority: priority.id,
        taskAreaId: task_area_id,
        date: formatDateForFront(date)!,
    };
};

export const formatterIndexConstraints = (
    constraints: Type_sch_index_constraint[],
): Type_index_constraint[] => {
    return constraints.map(formatterIndexConstraint);
};

export const formatterCreateConstraint = (
    constraint: Type_post_constraint,
): Type_sch_post_constraint => {
    const { subTradeId, companyId, date, ...rest } = constraint;

    return {
        ...rest,
        subTrade_id: subTradeId,
        company_id: companyId,
        date: formatDateForBack(date)!,
    };
};

export const formatterUpdateConstraint = (
    constraint: Type_index_constraint,
): Type_sch_put_constraint => {
    const { subTrade, company, date, ...rest } = constraint;

    return {
        ...rest,
        subTrade_id: subTrade?.id || null,
        company_id: company?.id || null,
        date: formatDateForBack(date),
    };
};

// output of this will be passed to sendEvent, cuz sendEvent that will stringify the data on receive, so we need to transform DayJs to string before passing it with sendEvent
export const formatterSendEventIndexConstraint = (
    constraint: Type_index_constraint,
): Type_event_constraint => {
    return {
        ...constraint,
        date: formatDateForBack(constraint?.date) as string,
    };
};

// we convert back the received data from sendEvent to get back to Dayjs format
export const formatterGetEventIndexConstraint = (
    constraint: Type_event_constraint,
): Type_index_constraint => {
    return {
        ...constraint,
        date: formatDateForFront(constraint.date),
    };
};
