import { Stack } from "@mui/material";

import { useIndexProgressHistory } from "src/api/tms-scheduling/progressHistory";
import { ItemChildrenProps } from "src/components/Components_Common/accordions/Accordion";
import { Empty } from "src/components/Components_Common/Empty/Empty";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { ProgressionHistory } from "./ProgressionHistory";

type Type_Props_ProgressionHistories = ItemChildrenProps & {
    taskAreaId: number;
    open: boolean;
};

export const ProgressionHistories = ({
    taskAreaId,
    open,
}: Type_Props_ProgressionHistories) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Progression",
    );

    /////////////////////////
    ///     Queries      ///
    /////////////////////////
    const { isLoading, data } = useIndexProgressHistory(
        taskAreaId,
        open,
        "accordionProgressionHistories",
        MEDIUM_STALE_TIME,
    );

    return isLoading ? (
        <FullSpinner />
    ) : (
        <Stack gap={2}>
            {data?.length ? (
                data.map((progression) => (
                    <ProgressionHistory
                        key={progression.id}
                        progression={progression}
                    />
                ))
            ) : (
                <Empty
                    actionBtnVariant="text"
                    message={fmt("EmptyMessage")}
                    onClick={() => {}}
                    dataTestIdRef="TaskProgression-empty"
                />
            )}
        </Stack>
    );
};
