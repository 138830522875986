import {
    Type_index_productType,
    Type_prj_index_productType,
    Type_prj_post_productType,
    Type_prj_put_productType,
    Type_prj_select_productType,
    Type_prj_show_productType,
    Type_select_productType,
    Type_show_productType,
} from "./types";

export const formatterIndexProductTypes = (
    productType: Type_prj_index_productType[],
): Type_index_productType[] => {
    return productType.map((productType: Type_prj_index_productType) =>
        formatterIndexProductType(productType),
    );
};
export const formatterIndexProductType = (
    productType: Type_prj_index_productType,
): Type_index_productType => {
    return {
        id: productType.id,
        name: productType.name,
        unit: productType.unit,
        enabled: productType.enabled,
    };
};

export const formatterShowProductType = (
    productType: Type_prj_show_productType,
): Type_show_productType => {
    return {
        id: productType.id,
        names: productType.names,
        enabled: productType.enabled,
        subProject: productType.subProject,
        unit: productType.unit,
    };
};

export const formatterPostProductType = (
    productType: Type_prj_post_productType,
): Type_prj_post_productType => {
    return {
        names: productType.names,
        unit_id: productType.unit_id,
    };
};

export const formatterPutProductType = (
    productType: Type_prj_put_productType,
): Type_prj_put_productType => {
    return {
        id: productType.id,
        names: productType.names,
        enabled: productType.enabled,
    };
};

export const formatterSelectListProductTypes = (
    productsTypes: Type_prj_select_productType[],
): Type_select_productType[] => {
    return productsTypes.map((data: Type_prj_select_productType) => ({
        id: data.id,
        name: data.name,
        unit: data.unit,
        enabled: data.enabled,
    }));
};
