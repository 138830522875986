import Konva from "konva";

import { Type_point } from "src/components/Components_Common/canvas/types";

export const flowShapeWaitingFunction = (
    pt: Type_point,
    width: number,
    height: number,
    context: Konva.Context,
    shape?: Konva.Shape,
): void => {
    const x: number = pt.x;
    const y: number = pt.y;

    context.beginPath();
    context.moveTo(x, y);
    context.lineTo(x + width, y);
    context.lineTo(x + width, y + height);
    context.lineTo(x, y + height);
    context.closePath();

    shape && context.fillStrokeShape(shape);
};

//     pt: Type_point,
//     width: number,
//     height: number,
//     context: Konva.Context,
//     shape?: Konva.Shape,
// ): void => {
//     const x: number = pt.x;
//     const y: number = pt.y;
//
//     context.beginPath();
//     context.arc(
//         x + width - height / 2,
//         y + height / 2,
//         height / 2,
//         (-1 * Math.PI) / 2,
//         Math.PI / 2,
//     );
//     context.lineTo(x + height / 2, y + height);
//     context.arc(
//         x + height / 2,
//         y + height / 2,
//         height / 2,
//         Math.PI / 2,
//         (3 * Math.PI) / 2,
//     );
//     context.closePath();
//
//     shape && context.fillStrokeShape(shape);
// };
