import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import * as Yup from "yup";

import {
    mutationCreateDayOff,
    mutationUpdateDayOff,
    useShowDayOff,
} from "src/api/tms-projects/daysOffs";
import {
    Type_post_dayOff,
    Type_show_dayOff,
} from "src/api/tms-projects/daysOffs/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { DatePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DatePicker/DatePicker";
import { InputMultiLanguages } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages";
import { inputMultiLanguagesSchema } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { AutocompleteCompanies } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteCompanies";
import { AutocompleteTrades } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteTrades";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getCurrentFormat, isSameOrBefore } from "src/utils/date";

type Type_Props_DayOffForm = {
    onClose: () => void;
    dayOffIdToUpdate?: number | null;
    onFormSubmit?: () => void;
    action: Type_action;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    defaultValues?: Type_post_dayOff;
    isOnPlanning?: boolean;
};

export const Schema_DayOff = Yup.object().shape({
    names: Yup.lazy(() => inputMultiLanguagesSchema()),
    startDate: Yup.date().required(FORM_ERR_FMT.REQUIRED),
    endDate: Yup.date()
        .nullable()
        .test(
            "startDate",
            FORM_ERR_FMT.DATE_END_AFTER_DATE_START,
            (endDate, testContext) => {
                if (endDate && testContext.parent.startDate) {
                    return isSameOrBefore(
                        testContext.parent.startDate,
                        endDate,
                    );
                }
                return true;
            },
        ),
    trades: Yup.array()
        .of(
            Yup.object().shape({
                id: Yup.number(),
                name: Yup.string(),
            }),
        )
        .nullable(),
    companies: Yup.array()
        .of(
            Yup.object().shape({
                id: Yup.number(),
                name: Yup.string(),
            }),
        )
        .nullable(),
});

export const DayOffForm = ({
    onClose,
    dayOffIdToUpdate,
    onFormSubmit,
    action,
    setIsLoading,
    defaultValues,
    isOnPlanning = false,
}: Type_Props_DayOffForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.DayOff");
    const { formatMessageWithPartialKey: fmtForm } = useCoreIntl("Form");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const { mutateAsync: mutateCreate } =
        mutationCreateDayOff(
            isOnPlanning && onFormSubmit ? onFormSubmit : undefined,
        ) || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateDayOff();

    const { isFetching, data } = useShowDayOff(dayOffIdToUpdate as number);

    const form = useTmcForm<Type_show_dayOff | Type_post_dayOff>({
        ...useFormDefaultConfig,
        defaultValues: {
            names: {},
            id: undefined,
            ...defaultValues,
        },
        values: data,
        resolver: yupResolver<any>(Schema_DayOff),
        onClose,
        setIsLoading,
    });

    const handleSubmit = async (
        values: Type_show_dayOff | Type_post_dayOff,
    ) => {
        try {
            if (action === "update") {
                await mutateUpdate(values as Type_show_dayOff);
            } else {
                await mutateCreate(values as Type_post_dayOff);
            }

            // Fermeture du drawer
            onClose();
        } catch (_e: any) {
            console.error("Error when creating or updating resource model");
        }
    };

    return (
        <TmcFormProvider form={form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"daysOff"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <>
                        <Stack rowGap={6}>
                            <InputMultiLanguages name="names" />
                            <Stack
                                direction="row"
                                flexWrap="nowrap"
                                justifyContent={"space-between"}
                                spacing={4}
                            >
                                <DatePicker
                                    name="startDate"
                                    label={fmtForm("Field.StartDate")}
                                    format={getCurrentFormat()}
                                    data-testid="Project-Settings-DayOff-Drawer-StartDate"
                                />
                                <DatePicker
                                    name="endDate"
                                    label={`${fmtForm(
                                        "Field.EndDate",
                                    )} - ${fmtForm("Optional")}`}
                                    format={getCurrentFormat()}
                                    data-testid="Project-Settings-DayOff-Drawer-EndDate"
                                />
                            </Stack>
                            <Typography variant="body1Bold">
                                {fmt("AdditionalInfo")}
                            </Typography>
                            <AutocompleteCompanies
                                name={"companies"}
                                label={fmtLabels("Companies")}
                                renderInputProps={{
                                    variant: "outlined",
                                }}
                            />
                            <AutocompleteTrades
                                name={"trades"}
                                label={fmtLabels("Trades")}
                                renderInputProps={{
                                    variant: "outlined",
                                }}
                            />
                        </Stack>
                    </>
                )}
            </form>
        </TmcFormProvider>
    );
};
