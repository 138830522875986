import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { MetadataUserKeys } from "src/api/tms-users/keys";
import {
    formatterCreateMetadataUser,
    formatterIndexMetadataUser,
    formatterIndexMetadataUsers,
    formatterIndexMetadataUsersForForm,
    formatterShowMetadataUser,
    formatterStatusMetadataUser,
    formatterUpdateMetadataUser,
} from "src/api/tms-users/metadata/formatters";
import {
    createMetadataUser,
    deleteMetadataUser,
    indexMetadataUsers,
    showMetadataUser,
    statusMetadataUser,
    updateMetadataUser,
} from "src/api/tms-users/metadata/services";
import {
    Type_post_metadataUser,
    Type_put_metadataUser,
    Type_show_metadataUser,
    Type_usr_index_metadataUser,
} from "src/api/tms-users/metadata/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexMetadataUsers = () => {
    return useQuery({
        queryKey: [MetadataUserKeys.INDEX],
        queryFn: indexMetadataUsers,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexMetadataUsers");
            }

            return formatterIndexMetadataUsers(
                data.data.data as Type_usr_index_metadataUser[],
            );
        },
        onError: (err) => {
            return err;
        },
    });
};
export const useIndexMetadataUsersForForm = () => {
    return useQuery({
        queryKey: [MetadataUserKeys.INDEX],
        queryFn: indexMetadataUsers,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useIndexMetadataUsersForForm",
                );
            }

            return formatterIndexMetadataUsersForForm(
                data.data.data as Type_usr_index_metadataUser[],
            );
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useShowMetadataUser = (id: number) => {
    return useQuery([MetadataUserKeys.SHOW, id], () => showMetadataUser(id), {
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowMetadataUser");
            }

            return formatterShowMetadataUser(data?.data?.data);
        },
        onError: (err) => {
            return err;
        },
        enabled: !!id,
    });
};

export const mutationCreateMetadataUser = (onError?: (errors: any) => void) => {
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.MetadataUser",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (newMetadataUsers: Type_post_metadataUser) =>
            createMetadataUser(formatterCreateMetadataUser(newMetadataUsers)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateMetadataUser",
                );
            }

            const metadataUser = formatterIndexMetadataUser(data?.data?.data);

            await queryClient.invalidateQueries([MetadataUserKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        metadata: metadataUser.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            if (err?.data?.errors && onError) {
                onError(err.data.errors);
            } else {
                addWarning({
                    description: fmtErr("GenericError"),
                });
            }
            return err;
        },
    });
};

export const mutationUpdateMetadataUser = () => {
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.MetadataUser",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (metadataUser: Type_show_metadataUser) =>
            updateMetadataUser(
                metadataUser.id,
                formatterUpdateMetadataUser(metadataUser),
            ),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationUpdateMetadataUser",
                );
            }

            const metadataUser = formatterIndexMetadataUser(data?.data?.data);

            await queryClient.invalidateQueries([MetadataUserKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        metadata: metadataUser.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusMetadataUser = () => {
    const queryClient = useQueryClient();

    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (newStatus: Type_put_metadataUser) => {
            return statusMetadataUser(
                formatterStatusMetadataUser(newStatus),
                newStatus.id as number,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationStatusMetadataUser",
                );
            }
            await queryClient.invalidateQueries([MetadataUserKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteMetadataUser = () => {
    const queryClient = useQueryClient();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.MetadataUser",
    );
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (id: number) => deleteMetadataUser(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationDeleteMetadataUser",
                );
            }
            await queryClient.invalidateQueries({
                queryKey: [MetadataUserKeys.INDEX],
            });

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
