import {
    Type_sch_index_tag,
    Type_sch_select_tag,
    Type_select_tag,
} from "./types";

export const formatterCreateTag = (
    tag: Type_sch_index_tag,
): Type_select_tag => {
    return {
        id: tag.id,
        name: tag.name,
    };
};

export const formatterSelectListTags = (
    tags: Type_sch_select_tag[],
): Type_select_tag[] => {
    return tags.map((tag: Type_sch_select_tag) => ({
        id: tag.id,
        name: tag.name,
    }));
};
