import { GridRowId, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import { Type_index_causeOfDelay } from "src/api/tms-commons/causesOfDelay/types";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { CauseOfDelayDrawer } from "src/drawers/causesOfDelay/CauseOfDelayDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteCauseOfDelay } from "./Page_CausesOfDelay.container";

export type Type_Props_PageCausesOfDelayComponent = {
    causesOfDelay: Type_index_causeOfDelay[];
    removeCauseOfDelay: (id: number) => void;
    changeStatus: (id: number, causeOfDelay: Type_index_causeOfDelay) => void;
    modalDeleteCauseOfDelay: Type_modalDeleteCauseOfDelay;
    setModalDeleteCauseOfDelay: Dispatch<
        SetStateAction<Type_modalDeleteCauseOfDelay>
    >;
    isFetching: boolean;
};

export const Page_CausesOfDelayComponent = ({
    causesOfDelay,
    removeCauseOfDelay,
    changeStatus,
    modalDeleteCauseOfDelay,
    setModalDeleteCauseOfDelay,
    isFetching,
}: Type_Props_PageCausesOfDelayComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.CausesOfDelay",
    );
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");

    const page = "causesOfDelay";

    const [selectedCauseOfDelay, setSelectedCauseOfDelay] = useState<
        number | null
    >(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [action, setAction] = useState<Type_action>("create");

    const handleCauseOfDelayCreate = () => {
        setSelectedCauseOfDelay(null);
        setAction("create");
        setOpenDrawer(true);
    };
    const handleCauseOfDelayEdit = (id: number) => {
        setSelectedCauseOfDelay(id);
        setAction("update");
        setOpenDrawer(true);
    };

    const handleCauseOfDelayDuplicate = (id: number) => {
        setSelectedCauseOfDelay(id);
        setAction("duplicate");
        setOpenDrawer(true);
    };
    const handleCloseCauseOfDelayDrawer = () => {
        setOpenDrawer(false);
    };

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Causes"),
            sortable: false,
        },
    ];

    const callbackEdit = (row: GridRowModel) => {
        handleCauseOfDelayEdit(row.id);
    };

    const callbackDuplicate = (row: GridRowModel) =>
        handleCauseOfDelayDuplicate(row.id);

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row.id, row as Type_index_causeOfDelay);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteCauseOfDelay({
            isOpen: true,
            props: row as Type_index_causeOfDelay,
        });
    };

    const actions = {
        onSubmit: {
            onClick: () => {
                setModalDeleteCauseOfDelay({ isOpen: false });
                removeCauseOfDelay(
                    modalDeleteCauseOfDelay?.props?.id as number,
                );
            },
        },
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageCausesOfDelay-AddOne-btn"
                    onClick={() => handleCauseOfDelayCreate()}
                    page={page}
                />
            </HeaderGrid>
            <Table
                rows={causesOfDelay}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: () => handleCauseOfDelayCreate(),
                }}
                onClick={(id: GridRowId) => {
                    handleCauseOfDelayEdit(parseInt(id as string));
                }}
                menuDropDown={{
                    callbackEdit,
                    callbackDuplicate,
                    callbackStatus,
                    callbackDelete,
                }}
                page={page}
                height={GRID_HEIGHT}
            />
            <CauseOfDelayDrawer
                open={openDrawer}
                action={action}
                causeOfDelayIdToUpdate={selectedCauseOfDelay}
                onClose={handleCloseCauseOfDelayDrawer}
                page={page}
            />

            <ModalDelete
                open={modalDeleteCauseOfDelay.isOpen}
                onClose={() => setModalDeleteCauseOfDelay({ isOpen: false })}
                item={fmt("DataName")}
                validationString={
                    modalDeleteCauseOfDelay?.props?.name as string
                }
                actions={actions}
            />
        </>
    );
};
