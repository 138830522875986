import { Box } from "@mui/material";
import { useState } from "react";

import { Flow } from "src/components/Components_Teamoty/Flow/Flow";
import {
    Enum_typeFlowLink,
    Enum_typeFlowTask,
} from "src/components/Components_Teamoty/Flow/Flow.enum";
import { Type_flow } from "src/components/Components_Teamoty/Flow/Flow.type";

// *********************************************************************************************************************
// *********************************************************************************************************************

const allTasks: Type_flow = {
    tasks: [
        {
            id: 1,
            type: Enum_typeFlowTask.Work,
            color: "#ffffff",
            name: "long task 1",
            code: "T1",
            duration: 1,
            areaQuantity: 1,
            team: 1,
            group: "texte avec un p",
            pt: {
                x: 70,
                y: 70,
            },
        },
        {
            id: 2,
            type: Enum_typeFlowTask.Work,
            color: "#46b4be",
            name: "task 2",
            duration: 2,
            areaQuantity: 1,
            team: 1,
            pt: {
                x: 210,
                y: 70,
            },
        },
        {
            id: 3,
            type: Enum_typeFlowTask.Study,
            color: "#11ff00",
            name: "Study 3",
            duration: 2.25,
            areaQuantity: 1,
            team: 1,
            group: "Grp A",
            pt: {
                x: 140,
                y: 140,
            },
        },
        {
            id: 4,
            type: Enum_typeFlowTask.Waiting,
            color: "#beaa46",
            name: "Waiting 4",
            duration: 4,
            areaQuantity: 1,
            team: 1,
            group: "Grp A",
            pt: {
                x: 140,
                y: 210,
            },
        },
        {
            id: 5,
            type: Enum_typeFlowTask.Milestone,
            color: "#27694d",
            name: "Milestone 5",
            duration: 5,
            areaQuantity: 1,
            team: 1,
            group: "Grp 2",
            pt: {
                x: 140,
                y: 280,
            },
        },
        {
            id: 6,
            type: Enum_typeFlowTask.Delivery,
            color: "#a846be",
            name: "Delivery 6",
            duration: 0.5,
            areaQuantity: 1,
            team: 1,
            group: "Grp 2",
            pt: {
                x: 350,
                y: 140,
            },
        },
        {
            id: 7,
            type: Enum_typeFlowTask.Technical,
            color: "#c69dce",
            name: "Technical 7",
            duration: 0.1,
            areaQuantity: 1,
            team: 1,
            pt: {
                x: 140,
                y: 350,
            },
        },
    ],

    links: [
        {
            id: 1,
            taskFrom: 1,
            taskTo: 2,
            quantity: 1,
            waitingDay: 1,
            type: Enum_typeFlowLink.FS,
        },
        {
            id: 2,
            taskFrom: 1,
            taskTo: 3,
            quantity: 2,
            waitingDay: 2,
            type: Enum_typeFlowLink.FS,
        },

        {
            id: 3,
            taskFrom: 3,
            taskTo: 4,
            quantity: 3,
            waitingDay: 3,
            type: Enum_typeFlowLink.FS,
        },

        // {
        //     id: 4,
        //     taskFrom: 3,
        //     taskTo: 5,
        //     quantity: 4,
        //     waitingDay: 4,
        //     type: Enum_typeFlowLink.FS,
        // },
        {
            id: 5,
            taskFrom: 3,
            taskTo: 6,
            quantity: 5,
            waitingDay: 5,
            type: Enum_typeFlowLink.FS,
        },
        {
            id: 6,
            taskFrom: 5,
            taskTo: 6,
            quantity: 6,
            waitingDay: 6,
            type: Enum_typeFlowLink.FS,
        },

        {
            id: 7,
            taskFrom: 6,
            taskTo: 7,
            quantity: 7,
            waitingDay: 7,
            type: Enum_typeFlowLink.FS,
        },

        // {
        //     id: 2,
        //     from: 1,
        //     to: 3,
        // }
    ],
};

export const KonvaFlow = () => {
    const [tasks, setTasks] = useState<Type_flow>(allTasks);

    return (
        <Box
            sx={{
                width: "90vw",
                height: "80vh",
                outline: "2px solid red",
            }}
        >
            <Flow tasks={tasks} setTasks={setTasks} fixedSize={false} />
        </Box>
    );
};
