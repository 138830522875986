import { usePlanningTheme } from "src/components/Components_Teamoty/Planning/Planning.const";
import { Type_planningShapeColorFunction } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";
import { invertColor } from "src/utils/colors";

export const planningShapeColorFunction = (
    color: string,
    isDone: boolean = false,
): Type_planningShapeColorFunction => {
    if (isDone) {
        const theme = usePlanningTheme();

        return {
            fill: theme.strokePlanningTaskDone,
            stroke: theme.strokePlanningTaskDone,
            textColor: theme.strokePlanningTaskDoneText,
            progress: theme.strokePlanningTaskDone,
        };
    }
    return {
        fill: color,
        stroke: color,
        textColor: invertColor(color),
        progress: invertColor(color),
    };
};
