import { useMutation, useQuery, useQueryClient } from "react-query";

import {
    formatterCreateFavouriteProject,
    formatterIndexFavouriteProjects,
} from "src/api/tms-commons/favouriteProjects/formatters";
import {
    addProjectToFavourites,
    indexFavouriteProjects,
    removeProjectFromFavourites,
} from "src/api/tms-commons/favouriteProjects/services";
import { FavouriteProjectKeys } from "src/api/tms-commons/keys";

export const useIndexFavouriteProjects = () => {
    return useQuery({
        queryKey: [FavouriteProjectKeys.INDEX],
        queryFn: indexFavouriteProjects,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexFavouriteProjects");
            }

            return formatterIndexFavouriteProjects(data.data.data);
        },
    });
};

export const mutationAddProjectToFavourites = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) =>
            addProjectToFavourites(formatterCreateFavouriteProject(id)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationAddProjectToFavourites",
                );
            }

            await queryClient.invalidateQueries([FavouriteProjectKeys.INDEX]);
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationRemoveProjectFromFavourites = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => removeProjectFromFavourites(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationRemoveProjectFromFavourites",
                );
            }
            await queryClient.invalidateQueries([FavouriteProjectKeys.INDEX]);
        },
        onError: (err: any) => {
            return err;
        },
    });
};
