import { useState } from "react";

import {
    mutationDeleteCauseOfDelay,
    mutationStatusCauseOfDelay,
    useIndexCausesOfDelay,
} from "src/api/tms-commons/causesOfDelay";
import { Type_index_causeOfDelay } from "src/api/tms-commons/causesOfDelay/types";

import { Page_CausesOfDelayComponent } from "./Page_CausesOfDelay.component";

export type Type_modalDeleteCauseOfDelay = {
    isOpen: boolean;
    props?: Type_index_causeOfDelay;
};

export const Page_CausesOfDelayContainer = () => {
    const [modalDeleteCauseOfDelay, setModalDeleteCauseOfDelay] =
        useState<Type_modalDeleteCauseOfDelay>({
            isOpen: false,
        });

    const { isFetching, data } = useIndexCausesOfDelay() || {};
    const { mutate: mutateCauseOfDelayStatus } =
        mutationStatusCauseOfDelay() || {};

    const { mutateAsync: mutateDeleteCauseOfDelay } =
        mutationDeleteCauseOfDelay() || {};

    const removeCauseOfDelay = async (id: number) => {
        await mutateDeleteCauseOfDelay(id);
    };

    const changeStatus = async (
        id: number,
        causeOfDelay: Type_index_causeOfDelay,
    ) => {
        mutateCauseOfDelayStatus({
            id,
            enabled: !causeOfDelay.enabled,
        });
    };

    return (
        <Page_CausesOfDelayComponent
            isFetching={isFetching}
            causesOfDelay={data || []}
            removeCauseOfDelay={removeCauseOfDelay}
            changeStatus={changeStatus}
            modalDeleteCauseOfDelay={modalDeleteCauseOfDelay}
            setModalDeleteCauseOfDelay={setModalDeleteCauseOfDelay}
        />
    );
};
