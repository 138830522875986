import Konva from "konva";
import React from "react";
import { Group } from "react-konva";

import { TextEdit } from "src/components/Components_Common/canvas/TextEdit";
import {
    fontFlowFamily,
    sizeFlowTaskName,
} from "src/components/Components_Teamoty/Flow/Flow.const";
import { separatorCodeTask } from "src/configurations/app";

export type Type_Props_FlowShapeTextTask = Konva.TextConfig & {
    maxWidth: number;
    height: number;
    background: string;
    name: string;
    code?: string;
    onChange?: (value: string) => void;
};

export const FlowShapeTextTask = ({
    maxWidth,
    code,
    name,
    background,
    onChange,
    ...props
}: Type_Props_FlowShapeTextTask) => {
    return (
        <Group x={props.x} y={props.y}>
            <TextEdit
                {...props}
                readonly
                x={20}
                y={-1}
                ellipsis={true}
                fontFamily={fontFlowFamily}
                fontStyle={"500"}
                fontSize={sizeFlowTaskName}
                lineHeight={1.2}
                wrap="word"
                width={maxWidth - 40}
                height={sizeFlowTaskName * 2.4}
                text={(code ? code + separatorCodeTask : "") + name}
                verticalAlign="middle"
                padding={0}
                background={background}
                onChange={onChange}
            />
        </Group>
    );
};
