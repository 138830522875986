import {
    Type_com_index_causeOfDelay,
    Type_com_post_causeOfDelay,
    Type_com_put_causeOfDelay,
    Type_com_show_causeOfDelay,
    Type_index_causeOfDelay,
    Type_post_causeOfDelay,
    Type_put_causeOfDelay,
    Type_show_causeOfDelay,
} from "src/api/tms-commons/causesOfDelay/types";

export const formatterIndexCausesOfDelay = (
    causesOfDelay: Type_com_index_causeOfDelay[],
): Type_index_causeOfDelay[] => {
    if (!causesOfDelay) return [];

    return causesOfDelay.map((causeOfDelay: Type_com_index_causeOfDelay) =>
        formatterIndexCauseOfDelay(causeOfDelay),
    );
};

export const formatterIndexCauseOfDelay = (
    causeOfDelay: Type_com_index_causeOfDelay,
) => {
    return {
        id: causeOfDelay.id,
        name: causeOfDelay.name,
        enabled: causeOfDelay.enabled,
    };
};

export const formatterShowCauseOfDelay = (
    causeOfDelay: Type_com_show_causeOfDelay,
): Type_show_causeOfDelay => {
    return {
        id: causeOfDelay.id,
        names: causeOfDelay.names,
        enabled: causeOfDelay.enabled,
    };
};

export const formatterStatusCauseOfDelay = (
    causeOfDelay: Type_put_causeOfDelay,
): Type_com_put_causeOfDelay => {
    return {
        enabled: causeOfDelay.enabled,
    };
};
export const formatterPutCauseOfDelay = (
    causeOfDelay: Type_show_causeOfDelay,
): Type_com_put_causeOfDelay => {
    return {
        names: causeOfDelay.names,
        enabled: causeOfDelay.enabled,
    };
};

export const formatterPostCauseOfDelay = (
    causeOfDelay: Type_post_causeOfDelay,
): Type_com_post_causeOfDelay => {
    return {
        names: causeOfDelay.names,
    };
};
