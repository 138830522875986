import { Stack, Typography } from "@mui/material";
import { UseTreeItem2LabelSlotProps } from "@mui/x-tree-view/useTreeItem2/useTreeItem2.types";
import { ReactNode } from "react";

import { Type_index_sequenceFolderTree } from "src/api/tms-scheduling/sequenceFolders/types";
import { mutationCreateSequence } from "src/api/tms-scheduling/sequences";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Styled_TreeItem2Label } from "src/drawers/sequences/SequencesContextualDrawer/SequencesTreeItem";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_SequencesTreeItemLabelFolder = UseTreeItem2LabelSlotProps & {
    item: Type_index_sequenceFolderTree;
    children?: ReactNode;
};

export const SequencesTreeItemLabelFolder = ({
    item,
    children,
    ...treeItemProps
}: Type_Props_SequencesTreeItemLabelFolder) => {
    const { openPaper } = useContextualDrawer();
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.SequenceFolders",
    );

    // Api Hooks
    const { mutateAsync, isLoading: isLoadingCreateSequence } =
        mutationCreateSequence(true);

    // Utils
    const handleCreate = async (id: number): Promise<void> => {
        // création nouvelle séquence
        const respSequence = await mutateAsync({
            sequenceFolderId: id,
        });
        const sequence = respSequence.data.data;

        //ouverture du drawer sequence et du PERT
        openPaper("sequence", { id: sequence.id }, true);
        sendEvent("openPert", {
            ...sequence,
            open: true,
        });
    };

    const handleClick = (
        event: React.MouseEvent,
        action: string,
        id: number,
    ) => {
        event.stopPropagation();
        action === "edit" &&
            sendEvent("openDialogFolder", {
                open: true,
                action: "update",
                data: { name: "createOrUpdateSequenceFolder", id: id },
            });
        if (action === "create") handleCreate(id);
    };

    return (
        <Styled_TreeItem2Label {...treeItemProps}>
            <Stack direction="row" alignItems="center" gap={1}>
                <Icon variant={"light"} icon={"folder"} fontSize={"small"} />
                <Typography variant="body2Medium">
                    {children} ({item.children.length})
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} className={"buttonGroup"}>
                <IconButton
                    onClick={(e) => handleClick(e, "edit", item.id)}
                    name={"editSequenceFolderBtn"}
                    type="button"
                    size="small"
                    title={fmt("EditFolder")}
                    data-testid="sequences-edit-sequenceFolder-btn"
                >
                    <Icon variant="light" icon="pen" fontSize="small" />
                </IconButton>
                <IconButton
                    disabled={isLoadingCreateSequence}
                    onClick={(e) => handleClick(e, "create", item.id)}
                    name={"createSequenceBtn"}
                    type="button"
                    size="small"
                    title={fmt("CreateSequence")}
                    data-testid="sequences-create-sequence-btn"
                >
                    <Icon variant="light" icon="plus" fontSize="small" />
                </IconButton>
            </Stack>
        </Styled_TreeItem2Label>
    );
};
