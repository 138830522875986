import { SyntheticEvent, useMemo, useState } from "react";
import { UseFormSetValue } from "react-hook-form";

import { Type_usr_post_role } from "src/api/tms-users/rolesAndPermissions/types";
import {
    permissionsSelectConfiguration,
    Type_category,
    Type_project,
    Type_subproject,
} from "src/configurations/permissionsSelect.configuration";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    PermissionsSelectionComponent,
    Type_Props_PermissionsSelectionComponent,
} from "./PermissionsSelection.component";
import { countSearchMatch } from "./utils";

type Type_Props_PermissionsSelectionContainer = {
    handleChange: UseFormSetValue<Type_usr_post_role>;
    values: string[];
};

export const PermissionsSelectionContainer = (
    props: Type_Props_PermissionsSelectionContainer,
) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Roles");

    const [search, setSearch] = useState<string>("");
    const [tabValue, setTabValue] = useState("0");

    const handleTabs = (event: SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    const handleSearch = (e: any) => setSearch(() => e.target.value);

    const permissionsData = useMemo(() => {
        const filter = search.toLowerCase().trim();

        if (filter)
            return permissionsSelectConfiguration.map(
                (projects: Type_project) => {
                    const tmpProjects: Type_project = {
                        ...projects,
                        categories: [],
                    };

                    projects.categories.map((subprojects: Type_subproject) => {
                        const tmpSubProjects: Type_subproject = {
                            ...subprojects,
                            categories: [],
                        };

                        subprojects.categories.map(
                            (subproject: Type_category) => {
                                if (
                                    fmt(`SubCategoriesLabels.${subproject.key}`)
                                        .toLowerCase()
                                        .includes(filter)
                                ) {
                                    tmpSubProjects.categories.push(subproject);
                                } else {
                                    const tmpCategories: Type_category = {
                                        ...subproject,
                                        categories: [],
                                    };

                                    for (
                                        let index = 0;
                                        index < subproject.categories.length;
                                        index++
                                    ) {
                                        if (
                                            fmt(
                                                `PermissionsLabels.${subproject.categories[index].key}`,
                                            )
                                                .toLowerCase()
                                                .includes(filter)
                                        ) {
                                            tmpCategories.categories =
                                                subproject.categories;
                                            break;
                                        }
                                    }
                                    if (tmpCategories.categories.length)
                                        tmpSubProjects.categories.push(
                                            tmpCategories,
                                        );
                                }
                            },
                        );
                        if (tmpSubProjects.categories.length)
                            tmpProjects.categories.push(tmpSubProjects);
                    });
                    return tmpProjects;
                },
            );
        return permissionsSelectConfiguration;
    }, [search]);

    const results = useMemo(
        () =>
            permissionsData.reduce((acc, values) => {
                let nbr = 0;
                nbr += countSearchMatch(values);
                return acc + nbr;
            }, 0),
        [permissionsData],
    );

    const toggleAllSubPermissions = (categories: Type_category[]) => {
        let newValues: string[] = [...props.values];
        const permissionsArray: string[] = [];

        categories.forEach((item) => {
            permissionsArray.push(...item.permissions);
            item.categories.forEach((category) => {
                permissionsArray.push(...category.permissions);
            });
        });

        if (
            permissionsArray.every((permission) =>
                props.values.includes(permission),
            )
        ) {
            newValues = props.values.filter(
                (permission) => !permissionsArray.includes(permission),
            );
        } else {
            permissionsArray.forEach((permission) => {
                if (!props.values.includes(permission))
                    newValues.push(permission);
            });
        }
        props.handleChange("permissions", newValues, { shouldValidate: true });
    };

    const componentProps: Type_Props_PermissionsSelectionComponent = {
        ...props,
        toggleAllSubPermissions,
        search,
        handleSearch,
        permissionsData,
        handleTabs,
        tabValue,
        results,
    };

    return <PermissionsSelectionComponent {...componentProps} />;
};
