import { TabPanel } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import { Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

export const Styled_DrawerHeader = styled(Grid)(({ theme }: any) => ({
    padding: theme.spacing(2, 2, 2, 10),
    flexDirection: "column",
    flexShrink: 0,
}));

export const ScrollableContent = styled(Stack)(() => ({
    flexDirection: "column",
    overflowY: "auto",
    flexGrow: 1,
}));

export const DrawerContent = styled(Stack)(({ theme }) => ({
    marginBottom: theme.spacing(5),
    padding: theme.spacing(0, 10),
}));

export const DrawerLabel = styled(Typography)(({ theme }) => ({
    "&.MuiTypography-root": {
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
}));

export const DrawerSticky = styled(Typography)(({ theme }) => ({
    "&.MuiTypography-root": {
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
        position: "sticky",
        top: 0,
        backgroundColor: `${theme.palette.background.paper}`,
        zIndex: "100",
    },
}));

export const Styled_DrawerFooter = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(3, 8, 5, 8),
    flexWrap: "nowrap",
    justifyContent: "end",
    flexDirection: "row",
    flexShrink: 0,
    width: "100%",
}));

//TABS
export const DrawerTabList = styled(TabList)(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    "&.MuiTabs-root, .MuiTabs-flexContainer, .MuiButtonBase-root": {
        minHeight: theme.spacing(10),
        maxHeight: theme.spacing(10),
        backgroundColor: theme.palette.background.paper,
        zIndex: 100,
    },
    position: "sticky",
    top: 0,
    span: {
        "&.MuiTabs-indicator": {
            zIndex: 100,
            backgroundColor: `${theme.palette.primary.main}`,
        },
    },
}));

export const DrawerTab = styled(Tab)(({ theme }: any) => ({
    minWidth: "0px",
    padding: "0px 0px",
    marginRight: theme.spacing(4),
    color: `${theme.palette.tertiary.contrastText}`,
    textTransform: "none",
    "&.Mui-selected": {
        color: `${theme.palette.primary.main}`,
    },
}));

export const Styled_TabPanelDrawerContent = styled(TabPanel)(() => ({
    "&.MuiTabPanel-root": {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));
