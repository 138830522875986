import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import {
    DrawerGeneric,
    Type_action,
} from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { PriorityOfConstraintForm } from "src/forms/priorityOfConstraints/PriorityOfConstraintForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_PriorityOfConstraintDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    action: Type_action;
    priorityIdToUpdate: number | null;
    page: string;
};

export const PriorityOfConstraintDrawer = ({
    open,
    onClose,
    action,
    priorityIdToUpdate,
    page,
}: Type_Props_PriorityOfConstraintDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PriorityOfConstraints",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`PriorityOfConstraint-Drawer-${action}-Title`}
            title={fmt("TitleCreate")}
            page={page}
            isFormLoading={isLoading}
            formId={"priorityOfConstraints"}
        >
            <PriorityOfConstraintForm
                onClose={onClose}
                action={action}
                priorityIdToUpdate={priorityIdToUpdate}
                setIsLoading={setIsLoading}
            />
        </DrawerGeneric>
    );
};
