import { Divider, InputLabel, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { useIndexTasks } from "src/api/tms-scheduling/tasks";
import { Type_index_task } from "src/api/tms-scheduling/tasks/types";
import { ColorPickerIcon } from "src/components/Components_Common/forms/reactHookFormComponents/ColorPicker/ColorPickerIcon";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_ColorPickerTask = {
    name: string;
    sequenceId?: number;
    currentTaskId?: number;
};

export const ColorPickerTask = ({
    name,
    sequenceId,
    currentTaskId,
    ...props
}: Type_Props_ColorPickerTask) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.Task.ColorPickerTask",
    );

    const [loadData, setLoadData] = useState<boolean>(false);
    const [tasks, setTasks] = useState<Type_index_task[]>([]);

    useIndexTasks(
        setTasks,
        {
            sequence_id: sequenceId,
        },
        loadData,
    );

    const { control } = useFormContext();
    const withTradeColor = useWatch({
        name: "withTradeColor",
        control,
    });
    const color = useWatch({
        name: "color",
        control,
    });
    const taskType = useWatch({
        name: "type",
        control,
    });

    const isTradeColorEnabled = useMemo(() => {
        const isWithTradeColorAvailable = shouldDisplay({
            name: "withTradeColor",
            taskType: taskType as number,
        });

        return isWithTradeColorAvailable && withTradeColor;
    }, [withTradeColor, taskType]);

    return (
        <ColorPickerIcon
            {...props}
            name={name}
            currentValue={color}
            selectedColors={tasks
                ?.filter(
                    (task) => task.id !== currentTaskId && !task.withTradeColor,
                )
                .map((task) => task?.color || "")}
            disabled={isTradeColorEnabled}
            handleIconClick={() => setLoadData(true)}
            header={
                <>
                    {shouldDisplay({
                        name: "withTradeColor",
                        taskType: taskType as number,
                    }) && (
                        <>
                            <Stack
                                direction="row"
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <InputLabel htmlFor="withTradeColor">
                                    <Typography variant="body2Medium">
                                        {fmt("UseTradeColor")}
                                    </Typography>
                                </InputLabel>
                                <Switch
                                    name="withTradeColor"
                                    data-testid="Form-Task-ColorPicker-Switch-WithTradeColor"
                                />
                            </Stack>
                            <Divider
                                sx={{ marginY: 2 }}
                                orientation="horizontal"
                            />
                        </>
                    )}
                </>
            }
        />
    );
};
