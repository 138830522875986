import { useState } from "react";

import {
    mutationDeletePunchlistItemCause,
    mutationStatusPunchlistItemCause,
    useIndexPunchlistItemCauses,
} from "src/api/tms-commons/punchlistItemCauses";
import {
    Type_index_punchlistItemCause,
    Type_put_punchlistItemCause,
} from "src/api/tms-commons/punchlistItemCauses/types";

import { Page_PunchlistItemCausesComponent } from "./Page_PunchlistItemCauses.component";

export type Type_modalDeletePunchlistItemCause = {
    isOpen: boolean;
    props?: Type_index_punchlistItemCause;
};

export const Page_PunchlistItemCausesContainer = () => {
    const { isFetching, data } = useIndexPunchlistItemCauses() || {};

    const [modalDeletePunchlistItemCause, setModalDeletePunchlistItemCause] =
        useState<Type_modalDeletePunchlistItemCause>({
            isOpen: false,
        });

    const { mutate: mutationPunchlistItemCauseStatus } =
        mutationStatusPunchlistItemCause() || {};

    const { mutateAsync: mutateDeletePunchlistItemCause } =
        mutationDeletePunchlistItemCause() || {};

    const removePunchListItemCause = async (id: number) => {
        await mutateDeletePunchlistItemCause(id);
    };

    const changeStatus = async (
        punchlistItemCause: Type_index_punchlistItemCause,
    ) => {
        mutationPunchlistItemCauseStatus({
            id: punchlistItemCause.id,
            enabled: !punchlistItemCause.enabled,
        } as Type_put_punchlistItemCause);
    };

    return (
        <Page_PunchlistItemCausesComponent
            isFetching={isFetching}
            punchlistItemCauses={data || []}
            removePunchListItemCause={removePunchListItemCause}
            changeStatus={changeStatus}
            modalDeletePunchlistItemCause={modalDeletePunchlistItemCause}
            setModalDeletePunchlistItemCause={setModalDeletePunchlistItemCause}
        />
    );
};
