import { Alert, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { TMC_Button } from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Styled_LoginContainer } from "src/pages/Pages_Common/Page_Identification/Page_Identification";

export const Page_ResetPasswordConfirm = () => {
    const { lang } = useParams();
    const navigate = useNavigate();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "ResetPasswordConfirm",
    );

    const navigateToLogin = (): void => {
        navigate(`/${lang}/login`, { replace: true });
    };

    return (
        <Styled_LoginContainer data-testid="ResetPasswordConfirm">
            <Stack alignItems={"start"}>
                <Typography variant="h1" marginBottom={2}>
                    {fmt("Title")}
                </Typography>
                <Alert severity={"info"} sx={{ marginBottom: 6 }}>
                    {fmt("Desc")}
                </Alert>
                <TMC_Button
                    type={"button"}
                    variant={"text"}
                    onClick={navigateToLogin}
                    startIcon={<Icon variant="solid" icon="arrow-left" />}
                >
                    {fmt("BackToLogin")}
                </TMC_Button>
            </Stack>
        </Styled_LoginContainer>
    );
};
