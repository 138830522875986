import Konva from "konva";
import type { RefObject } from "react";

import { Type_PlanningDrawer } from "src/components/Components_Teamoty/Planning/Drawer/PlanningDrawer.type";
import { Type_Props_PlanningZoom } from "src/components/Components_Teamoty/Planning/Zooms/PlanningZoom.type";

export type Type_Callback_PlanningScrollChange = (pos: number) => void;

export const initialPlanningBounds: Type_PlanningDrawer = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
};

export type Type_State_PlanningScroll = {
    pos: number;
    size: number;
    page: number;
    widthPage: number;
    x: number;
    y: number;
    width: number;
};

export const initialPlanningScroll: Type_State_PlanningScroll = {
    pos: 0,
    size: 0,
    page: 0,
    widthPage: 0,
    x: 0,
    y: 0,
    width: 0,
};

export type Type_Props_PlanningScroll = Type_State_PlanningScroll & {
    changePos: Type_Callback_PlanningScrollChange;
    variant?: string;
};

export type Type_Props_PlanningScrollCursor = {
    refCursor: RefObject<Konva.Shape>;
    x: number;
    y: number;
    width?: number;
    height?: number;
    onDragMove: (e: Konva.KonvaEventObject<DragEvent>) => void;
    variant?: string;
};

export type Type_Props_PlanningScrolls = {
    scrollX: Type_Props_PlanningScroll;
    scrollY: Type_Props_PlanningScroll;
    zoomX: Type_Props_PlanningZoom;
    zoomY?: Type_Props_PlanningZoom;
    gantt?: boolean;
};
