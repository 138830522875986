import { AutocompleteRenderGetTagProps, Box } from "@mui/material";
import React, { ComponentProps } from "react";

import { useSelectListProjectCompanies } from "src/api/tms-projects/projectCompanies";
import { Type_selectList } from "src/api/types";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteCompanies = Omit<
    ComponentProps<typeof Autocomplete>,
    | "isFetching"
    | "multiple"
    | "disableCloseOnSelect"
    | "options"
    | "getOptionLabel"
    | "isOptionEqualToValue"
    | "renderTags"
    | "componentsProps"
>;

export const AutocompleteCompanies = ({
    name,
    label,
    ...props
}: Type_Props_AutocompleteCompanies) => {
    const { isFetching: isFetchingCompanies, data: companies } =
        useSelectListProjectCompanies() || {};

    return (
        <Autocomplete
            {...props}
            isFetching={isFetchingCompanies}
            name={name}
            label={label}
            multiple
            disableCloseOnSelect
            options={(companies as unknown as Type_selectList[]) || []}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option?.name
            }
            isOptionEqualToValue={(option, selectedValues): boolean =>
                option?.id === selectedValues.id
            }
            renderTags={(
                tagValue: any[],
                getTagProps: AutocompleteRenderGetTagProps,
            ) =>
                tagValue.map((option, index: number) => {
                    // Erreur console si on spread avec key dedans
                    const { key, ...tagPropsWithoutKey } = getTagProps({
                        index,
                    });

                    return (
                        <Box key={index + key} sx={{ marginRight: 1 }}>
                            <TMC_Chip
                                label={option.name}
                                data-testid={`AutocompleteCompanies-${option.name.replaceAll(
                                    " ",
                                    "_",
                                )}`}
                                {...tagPropsWithoutKey}
                            />
                        </Box>
                    );
                })
            }
            componentsProps={{
                clearIndicator: {
                    // @ts-expect-error data-testid are not on IconButtonProps
                    "data-testid": `autocomplete-companies-clearButton-${name}`,
                },
            }}
        />
    );
};
