import { Typography } from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

import { logout } from "src/api/tms-gateway/auth/services";
import { Avatar, MenuTreeDropdown } from "src/components";
import { Type_MenuItems } from "src/components/Components_Common/MenuTree/MenuTree";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { URL_BASE } from "src/router";
import { COLORS } from "src/theme/stylesheet";
import { stringToColor } from "src/utils/colors";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import {
    Styled_CardHeaderCoverMenuDropDown,
    Styled_CardHeaderMenuAvatarDropdown,
    Styled_PopoverAvatar,
} from "./AvatarMenu.style";

type Type_AvatarMenuComponentProps = {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
};

export const AvatarMenu = memo(function AvatarMenuComponent({
    anchorEl,
    open,
    handleClose,
}: Type_AvatarMenuComponentProps) {
    const [companyName] = useState(""); //TODO ajouter le company name avec user.company.name

    const { formatMessageWithPartialKey: fmt } = useCoreIntl("TopBar.User");
    const urlParams = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { hasPermission, getFullName } = useUser();
    const fullName = getFullName();

    const basePath = useMemo(
        () => generatePath(`${URL_BASE}`, { ...urlParams }),
        [urlParams],
    );

    const navigateTo = useCallback(
        (path: string) => {
            navigate(`${basePath}${path}`);
            handleClose();
        },
        [basePath, navigate],
    );
    const lang: string = getLocalStorageItem("language");

    const { refetch } = useQuery("logout", logout, { enabled: false });

    const onLogout = useCallback(async () => {
        const { data } = await refetch();
        if (data?.success) {
            handleClose();
            navigate(`/${lang}/login`, { replace: true });
        }
    }, [handleClose, navigate, lang, refetch]);

    const MyProfileItems: Type_MenuItems[] = [
        {
            parentKey: null,
            childs: [
                {
                    key: "MyProfile.MyAccount",
                    label: fmt("MyProfile.MyAccount"),
                    action: () => {
                        handleClose();
                        navigate(`${basePath}/my-account/my-profile`);
                    },
                    selected: location.pathname.includes(
                        `my-account/my-profile`,
                    ),
                },
            ],
        },
    ];

    const GlobalSettings: Type_MenuItems[] = useMemo(() => {
        const items = [
            {
                key: "GlobalSettings.PlatformConfig",
                label: fmt("GlobalSettings.PlatformConfig"),
                hidden: !hasPermission("platformSettings_index"),
                action: () => navigateTo("/platform-configuration"),
                selected: location.pathname.includes(`/platform-configuration`),
            },
            {
                key: "GlobalSettings.UsersCompanies",
                label: fmt("GlobalSettings.UsersCompanies"),
                action: () => navigateTo("/users-and-companies/users"),
                selected: location.pathname.includes(
                    `/users-and-companies/users`,
                ),
                hidden:
                    !hasPermission("users_index") ||
                    !hasPermission("companies_index"),
            },
            {
                key: "GlobalSettings.Users",
                label: fmt("GlobalSettings.Users"),
                action: () => navigateTo("/users-and-companies/users"),
                selected: location.pathname.includes(
                    `/users-and-companies/users`,
                ),
                hidden:
                    !hasPermission("users_index") ||
                    hasPermission("companies_index"),
            },
            {
                key: "GlobalSettings.Companies",
                label: fmt("GlobalSettings.Companies"),
                action: () => navigateTo("/users-and-companies/companies"),
                selected: location.pathname.includes(
                    `/users-and-companies/companies`,
                ),
                hidden:
                    !hasPermission("companies_index") ||
                    hasPermission("users_index"),
            },
        ];

        return [
            {
                parentKey: null,
                childs: items,
            },
        ];
    }, [fmt, hasPermission, location.pathname, navigateTo]);

    const OtherItems: Type_MenuItems[] = [
        {
            parentKey: null,
            childs: [
                {
                    key: "MyProfile.LogOut",
                    label: fmt("MyProfile.LogOut"),
                    action: onLogout,
                },
            ],
        },
    ];
    return (
        <Styled_PopoverAvatar
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
            <Styled_CardHeaderCoverMenuDropDown
                mb={5}
                color={stringToColor(fullName)}
            >
                <Avatar
                    name={fullName || ""}
                    sx={{
                        width: 40,
                        height: 40,
                        letterSpacing: "1px",
                        position: "absolute",
                        bottom: "-17px",
                        left: "17px",
                    }}
                />
            </Styled_CardHeaderCoverMenuDropDown>

            <Styled_CardHeaderMenuAvatarDropdown
                data-testid={`AvatarDropdown-CardHeader-content-${fullName.replaceAll(
                    " ",
                    "_",
                )}`}
                title={fullName}
                subheader={
                    <>
                        <Typography
                            data-testid={"AvatarDropdown-CardHeader-company"}
                            sx={{ display: "block", color: COLORS.gray700 }}
                            variant="caption"
                        >
                            {companyName}
                        </Typography>
                        <Typography
                            data-testid={
                                "AvatarDropdown-CardHeader-lastConnection"
                            }
                            sx={{ color: COLORS.gray700 }}
                            variant="caption"
                        >
                            {fmt("LastConnection")}:{" "}
                            {/* TODO: need to add last connection field in back */}
                        </Typography>
                    </>
                }
            />

            <MenuTreeDropdown dividerTop items={MyProfileItems} />

            <MenuTreeDropdown dividerTop items={GlobalSettings} />

            {/*<Divider sx={{ mb: 2 }} />*/}

            {/*/!*<Box sx={{ my: 2 }} data-testid="ThemeSwitcher-container">*!/*/}
            {/*/!*    <ThemeSwitcher />*!/*/}
            {/*/!*</Box>*!/*/}

            <MenuTreeDropdown dividerTop items={OtherItems} />
        </Styled_PopoverAvatar>
    );
});
