import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import ImageUploading, {
    ErrorsType,
    ImageListType,
    ImageType,
} from "react-images-uploading";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import SVG_background from "./backgroundImg.svg";
import {
    Styled_ButtonUploadImage,
    Styled_ImgUploadImage,
    Styled_StackUploadImage,
} from "./UploadImage.style";

const AVAILABLE_MIME_TYPES: string[] = ["jpg", "jpeg", "png"];

const Styled_IconButton = styled(IconButton)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    border: theme.border.default,

    "&:hover": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.dark,
    },
}));

export type Type_UploadImageComponentProps = {
    value: ImageListType | string;
    onChange: (
        value: ImageListType,
        addUpdatedIndex?: number[] | undefined,
    ) => void;
    errors: ErrorsType;
    setErrors: React.Dispatch<React.SetStateAction<ErrorsType>>;
    limitResolutions?: {
        min?: number;
        max?: number;
    };
    withDescription?: boolean;
    img: string;
    deleteDefaultImg: () => void;
};

export const UploadImageComponent = ({
    value: images = [],
    onChange,
    limitResolutions = {
        min: 540,
        max: 2550,
    },
    withDescription = false,
    setErrors,
    errors,
    img,
    deleteDefaultImg,
}: Type_UploadImageComponentProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Components.UploadImage",
    );

    const handleImageUpload = async (
        imageList: ImageListType,
        addUpdatedIndex?: number[] | undefined,
    ) => {
        const filteredImages = await Promise.all(
            imageList.map(async (image) => {
                const imgDataUrl = new Image();
                imgDataUrl.src = image.data_url;
                await imgDataUrl.decode();

                const imageWidth = imgDataUrl.width;
                const imageHeight = imgDataUrl.height;

                if (
                    limitResolutions?.min &&
                    (imageHeight < limitResolutions.min ||
                        imageWidth < limitResolutions.min)
                ) {
                    setErrors({ resolution: true });
                    return null;
                }
                if (
                    limitResolutions?.max &&
                    (imageHeight > limitResolutions.max ||
                        imageWidth > limitResolutions.max)
                ) {
                    setErrors({ resolution: true });
                    return null;
                }
                return image;
            }),
        );

        const filteredNonNullImages = filteredImages.filter(
            (image) => image !== null,
        );

        onChange(filteredNonNullImages as ImageType[], addUpdatedIndex);
    };

    // We have an image to display
    if (img) {
        return (
            <Stack direction="row" flexWrap="nowrap" gap={2}>
                <Styled_StackUploadImage
                    style={{
                        backgroundImage: `url(${SVG_background})`,
                    }}
                >
                    <Styled_ImgUploadImage
                        style={{
                            backgroundImage: `url(${img})`,
                        }}
                    >
                        <Styled_IconButton
                            color={"secondary"}
                            onClick={deleteDefaultImg}
                            size={"small"}
                            type="button"
                        >
                            <Icon variant="light" icon="trash" />
                        </Styled_IconButton>
                    </Styled_ImgUploadImage>
                </Styled_StackUploadImage>
                {withDescription && (
                    <Description_UploadImage
                        limitResolutions={limitResolutions}
                    />
                )}
            </Stack>
        );
    }

    // Show empty state if no image is provided
    return (
        <Stack direction="column" flexWrap="nowrap">
            <Stack direction="row" flexWrap="nowrap" gap={2}>
                <ImageUploading
                    value={images as ImageListType}
                    onChange={handleImageUpload}
                    dataURLKey="data_url"
                    acceptType={AVAILABLE_MIME_TYPES}
                    resolutionType="more"
                    resolutionHeight={limitResolutions?.min}
                    resolutionWidth={limitResolutions?.min}
                    onError={(errors: ErrorsType) => setErrors(errors)}
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        dragProps,
                    }) => {
                        return (
                            <Styled_StackUploadImage
                                data-testid="Upload-image-form"
                                {...dragProps}
                                style={{
                                    backgroundImage: `url(${SVG_background})`,
                                }}
                            >
                                {images === null ||
                                !images.length ||
                                images[0] === null ||
                                typeof images[0] === "string" ? (
                                    <Styled_ButtonUploadImage
                                        onClick={onImageUpload}
                                        type="button"
                                    >
                                        <Typography
                                            variant="body3"
                                            data-testid="UploadImage-DragNDrop"
                                        >
                                            {fmt("DragAndDrop")}
                                        </Typography>
                                        <Typography
                                            data-testid="UploadImage-SelectFile"
                                            variant="body3Underline"
                                            color={COLORS.blue600}
                                        >
                                            {fmt("SelectFile")}
                                        </Typography>
                                    </Styled_ButtonUploadImage>
                                ) : (
                                    imageList.map(
                                        (image: ImageType, index: number) => (
                                            <Styled_ImgUploadImage
                                                key={index}
                                                style={{
                                                    backgroundImage: `url(${image.data_url})`,
                                                }}
                                            >
                                                <Styled_IconButton
                                                    color={"secondary"}
                                                    onClick={() =>
                                                        onImageRemove(index)
                                                    }
                                                    size={"small"}
                                                    type="button"
                                                    data-testid="UploadImage-removeBtn"
                                                >
                                                    <Icon
                                                        variant="light"
                                                        icon="trash"
                                                    />
                                                </Styled_IconButton>
                                            </Styled_ImgUploadImage>
                                        ),
                                    )
                                )}
                            </Styled_StackUploadImage>
                        );
                    }}
                </ImageUploading>
                {withDescription && (
                    <Description_UploadImage
                        limitResolutions={limitResolutions}
                    />
                )}
            </Stack>
            {errors && (
                <TMC_FormHelperText
                    helperText={
                        errors?.acceptType
                            ? FORM_ERR_FMT.FORMAT_FILE
                            : FORM_ERR_FMT.RESOLUTION
                    }
                    inputName={"uploadImage"}
                />
            )}
        </Stack>
    );
};

type Type_Description_UploadImageProps = {
    limitResolutions?: {
        min?: number;
        max?: number;
    };
};

export const Description_UploadImage = ({
    limitResolutions,
}: Type_Description_UploadImageProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Components.UploadImage",
    );

    return (
        <Stack>
            <Typography variant="body3" color={COLORS.moon500}>
                {fmt("FormatDescription")}
            </Typography>
            {!!limitResolutions?.min && (
                <Typography variant="body3" color={COLORS.moon500}>
                    {fmt("FormatMinResolution", {
                        values: {
                            min: limitResolutions.min,
                        },
                    })}
                </Typography>
            )}
            {!!limitResolutions?.max && (
                <Typography variant="body3" color={COLORS.moon500}>
                    {fmt("FormatMaxResolution", {
                        values: {
                            max: limitResolutions.max,
                        },
                    })}
                </Typography>
            )}
        </Stack>
    );
};
