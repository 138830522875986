import { get, GetReturnType, post, PostReturnType } from "src/api/fetch";
import { AuthPath, Url } from "src/api/paths";
import {
    Type_gtw_post_register,
    Type_post_login,
} from "src/api/tms-gateway/auth/types";

export const login = (data: Type_post_login): PostReturnType => {
    return post(data, `${Url.GATEWAY}${AuthPath.LOGIN}`);
};
export const logout = (): PostReturnType => {
    return post({}, `${Url.GATEWAY}${AuthPath.LOGOUT}`);
};

export const getCurrentUser = (): GetReturnType => {
    return get(`${Url.GATEWAY}${AuthPath.ME}`);
};

export const register = (data: Type_gtw_post_register): PostReturnType => {
    return post(data, `${Url.GATEWAY}${AuthPath.REGISTER}`);
};
