import { Components } from "@mui/material";

export const MuiTextFieldTheme: Components["MuiTextField"] = {
    defaultProps: {
        InputLabelProps: { shrink: true }, // Permet d'afficher label et placeholder en meme temps,
        autoComplete: "off",
        size: "small",
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
            ...theme.typography.body1Medium,
        }),
    },
};
