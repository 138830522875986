import { Box, SelectProps, Stack, styled, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";

import { useIndexPriorityOfConstraints } from "src/api/tms-commons/priorityOfConstraints";
import { formatterItemPriorityOfConstraints } from "src/api/tms-commons/priorityOfConstraints/formatters";
import { Spinner } from "src/components/Components_Common";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Type_ItemChip } from "src/components/Components_Common/forms/ChipSelect/ChipSelect";
import { ChipSelect } from "src/components/Components_Common/forms/reactHookFormComponents/ChipSelect/ChipSelect";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { LONG_STALE_TIME } from "src/configurations/app";
import { DEFAULT_COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

export const Styled_ColorBox = styled(Box)(({ theme }) => ({
    width: 24,
    height: 24,
    borderRadius: theme.shape.borderRadiusSmall,
    border: theme.border.default,
}));

type Type_Props_ChipSelectPriority = SelectProps & {
    name?: string;
} & Type_ReadonlyComponent;

/**
 * @deprecated must be refactored, can be use in forms
 * Ce composant mélange les besoins d'un composant de formulaire avec celui d'un composant d'affichage
 */
export const ChipSelectPriority = ({
    variant,
    readonly = false,
    disabled,
    value,
    name = "",
    ...props
}: Type_Props_ChipSelectPriority) => {
    /////////////////////////////////////
    ///         Queries               ///
    /////////////////////////////////////

    const { isFetching, data = [] } =
        useIndexPriorityOfConstraints(LONG_STALE_TIME) || {};

    // Memoize the list of priorities
    const listPriorities = useMemo(
        () => formatterItemPriorityOfConstraints(data),
        [data],
    );

    // Memoize the getPriority function
    const getPriority = useCallback(() => {
        const priority = listPriorities.find((item) => item.value === value);

        if (priority) {
            return {
                color: priority.chipColor || DEFAULT_COLORS.chipPriority,
                label: priority.label,
            };
        }
        return { color: DEFAULT_COLORS.chipPriority, label: "" };
    }, [value, listPriorities]);

    const priority = useMemo(() => getPriority(), [getPriority]);

    const renderPriorityOption = useCallback((option: Type_ItemChip) => {
        return (
            <Stack direction="row" width="100%" alignItems="center" gap={4}>
                <Styled_ColorBox
                    sx={{
                        backgroundColor:
                            option?.chipColor || DEFAULT_COLORS.chipPriority,
                    }}
                />
                <Typography variant="body1" color="text.primary">
                    {option.label}
                </Typography>
            </Stack>
        );
    }, []);

    if (readonly)
        return (
            <TMC_Chip
                height={24}
                backgroundColor={priority.color}
                colorText={invertColor(priority.color)}
                label={priority.label}
            />
        );

    if (isFetching) return <Spinner size="small" />;

    return (
        <ChipSelect
            items={listPriorities}
            renderOption={renderPriorityOption}
            variant={variant || "standard"}
            disabled={disabled}
            ownerState={{
                bgColor: priority.color,
                textColor: invertColor(priority.color),
            }}
            name={name}
            {...props}
        />
    );
};
