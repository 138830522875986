import { ListItemIcon, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";

export type Type_Props_CustomMenuItem = {
    keyName: string;
    label: string;
    iconName: string;
    color: string;
    variant: string;
    props?: any;
    callback: (props: any) => void;
};

export const CustomMenuItem = ({
    keyName,
    label,
    iconName,
    color,
    variant,
    props,
    callback,
}: Type_Props_CustomMenuItem) => {
    const handleClose = (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
        event.stopPropagation();
    };

    return (
        <MenuItem
            onClick={(event) => {
                handleClose(event);
                callback(props);
            }}
            key={keyName}
            sx={{
                width: "320px",
                paddingX: 4,
            }}
        >
            <ListItemIcon>
                <Icon
                    data-testid={`CustomMenuItem-icon-${keyName}`}
                    variant={variant}
                    icon={iconName}
                    sx={{
                        color: { color },
                        paddingRight: 1,
                        width: "24px",
                    }}
                />
            </ListItemIcon>
            <Typography
                data-testid={`CustomMenuItem-label-${keyName}`}
                variant="body1"
                color={color}
            >
                {label}
            </Typography>
        </MenuItem>
    );
};
