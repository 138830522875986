import { Box, BoxProps } from "@mui/material";
import { ReactNode } from "react";

import { Type_Direction, useResizable } from "src/hooks/useResizable";
import { Styled_Box } from "src/layouts/LayoutDashboard/ResizableDrawer/ResizableDrawer.style";

export type Type_Props_ResizableDrawer = BoxProps & {
    children: ReactNode;
    name: string;
    direction: Type_Direction;
    toolbar?: ReactNode;
};

export const ResizableDrawer = ({
    children,
    name,
    direction,
    toolbar,
    ...props
}: Type_Props_ResizableDrawer) => {
    const [ref] = useResizable(name, direction);

    return (
        <Styled_Box ref={ref} direction={direction} {...props}>
            {toolbar && (
                <Box sx={{ p: 2 }} className="ResizableDrawerToolbar">
                    {toolbar}
                </Box>
            )}
            {children}
            <div id="icon-resize" className={`resizer--${direction}_v`} />
        </Styled_Box>
    );
};
