import { Components } from "@mui/material";

export const MuiIconButtonTheme: Components["MuiIconButton"] = {
    styleOverrides: {
        root: ({ theme }: any) => ({
            textTransform: "inherit",
            fontWeight: 500,
            borderRadius: theme.shape.borderRadiusLarge,

            "&.MuiButton-sizeLarge": {
                height: 40,
                fontSize: 16,
            },
            "&.MuiButton-sizeMedium": {
                height: 32,
                fontSize: 14,
            },
            "&.MuiButton-sizeSmall": {
                height: 24,
                fontSize: 12,
            },
        }),
    },
};
