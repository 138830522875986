import { TooltipProps, Typography } from "@mui/material";

import { COLORS } from "src/theme/stylesheet";

import { Styled_Tooltip } from "./Tooltip.style";

export const Tooltip = ({ children, title, ...restProps }: TooltipProps) => {
    return (
        <Styled_Tooltip
            placement="bottom-start"
            {...restProps}
            title={
                <Typography variant="body3" color={COLORS.moon100}>
                    {title}
                </Typography>
            }
        >
            {children}
        </Styled_Tooltip>
    );
};
