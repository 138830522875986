import { debounce } from "@mui/material";
import Konva from "konva";
import React, {
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Circle,
    Group,
    Shape as K_Shape,
    Line,
    Text,
    Transformer,
} from "react-konva";
import { Portal } from "react-konva-utils";

import { useDidMount } from "src/hooks/useDidMount";

import { useStageContext } from "./StageContext";
import { Type_point } from "./types";

export type Type_ShapeLabel = {
    name: string;
    width: number;
    rotation: number;
    pt: Type_point;
    fontSize?: number;
};

export type Type_Shape = {
    index: number;
    label: Type_ShapeLabel;
    color: string;
    pts: Type_point[];
};

type Type_Props_Shape = Type_Shape &
    Konva.ShapeConfig & {
        onStateChange?: (shape: Type_Shape) => void;
    };

// Gestion d'une zone multi point
export const Shape = ({
    index: who,
    label,
    color,
    pts,
    onStateChange,
    ...shapeProps
}: Type_Props_Shape) => {
    const {
        isSelecting: stageIsSelecting,
        stageScale,
        changeSelecting,
    } = useStageContext();
    const didMount = useDidMount();

    // ---------------------------------
    //  CONFIG
    // ---------------------------------

    // taille min de la zone texte
    const minWidthText: number = 10;

    // distance du magnetisme
    const confDistanceMagnet: number = 15;

    // ---------------------------------
    //  COLORS
    // ---------------------------------

    const strokeColor = "black";
    const addPointCircleColor = "blue";
    const selectedLineColor = "red";
    const selectedCornerCircleColor = "red";
    const opacity = 0.5;
    const opacityHover = 0.7;

    ////////////////////////////////////////////
    // ---- REFERENCE                       ----
    ////////////////////////////////////////////
    // reférence pour la gestion de la zone texte
    const shapeRef: RefObject<Konva.Text> = useRef<Konva.Text>(null);
    const trRef: RefObject<Konva.Transformer> = useRef<Konva.Transformer>(null);

    ////////////////////////////////////////////
    // ---- STATES                          ----
    ////////////////////////////////////////////

    // Initializing didMount as false
    const [isSelected, setIsSelected] = useState(who === stageIsSelecting);
    const [isTextSelected, setIsTextSelected] = useState(false);

    // data du composant
    const [state, setState] = useState({
        isDragging: false,
        isCircle: {
            x: 0,
            y: 0,
            i: 0,
            isVisible: false,
        },
        label: label,
        pts: pts,
    });

    ////////////////////////////////////////////
    // ---- EFFECTS                         ----
    ////////////////////////////////////////////

    useEffect(() => {
        setIsSelected(who === stageIsSelecting);
        who !== stageIsSelecting && setIsTextSelected(false);
    }, [stageIsSelecting]);

    useEffect(() => {
        // did mount is important to prevent component to call onStateChange when load shape.pts
        if (didMount) {
            debounceOnStateChange(state);
        }
    }, [state.pts, state.label]);

    // debounceOnStateChange should useCallback to preserve timer between renders
    const debounceOnStateChange = useCallback(
        debounce((state) => {
            if (onStateChange) {
                onStateChange({
                    index: who,
                    color,
                    label: state.label,
                    pts: state.pts,
                });
            }
        }, 3000), // debounce 3sec
        [onStateChange],
    );

    // ---------------------------------
    //  FUNCTIONS
    // ---------------------------------

    // distance entre 2 points
    const getDistance = (p1: Type_point, p2: Type_point): number => {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    };

    // point sur une ligne
    const previewPoint = (linesPt: number[], mousePos: any): any => {
        const i = 0;

        if (linesPt[i] === linesPt[i + 2]) {
            return { x: linesPt[i], y: mousePos.y };
        }

        const a: number =
            (linesPt[i + 1] - linesPt[i + 3]) / (linesPt[i] - linesPt[i + 2]);
        const b: number = linesPt[i + 3] - a * linesPt[i + 2];
        const x: number = (mousePos.y - b) / a;
        const y: number = a * mousePos.x + b;

        if (Math.abs(a) < 1) {
            return { x: mousePos.x, y: y };
        }

        return { x: x, y: mousePos.y };
    };

    // mise en forme des point pour Line
    const linesPt: number[][] = [];
    state.pts.forEach((pt: Type_point, index: number) => {
        linesPt[index] = [];
        linesPt[index].push(pt.x);
        linesPt[index].push(pt.y);
        if (index) {
            linesPt[index - 1].push(pt.x);
            linesPt[index - 1].push(pt.y);
        }
    });
    linesPt[state.pts.length - 1].push(linesPt[0][0]);
    linesPt[state.pts.length - 1].push(linesPt[0][1]);

    // mise en place des références croisée
    if (isSelected && trRef.current && shapeRef.current) {
        trRef.current.nodes([shapeRef.current]);
        const layer = trRef.current.getLayer();
        if (layer) {
            layer.batchDraw();
        }
    }

    // SHAPE ----------------------------

    const shapeDrawFunction = (context: Konva.Context, shape: Konva.Shape) => {
        context.beginPath(); // commence le dessin
        state.pts.forEach((pt: Type_point, index: number) => {
            index
                ? context.lineTo(pt.x, pt.y) // je trace une line entre le point précedent et celui ci
                : context.moveTo(pt.x, pt.y); // premier point je déplace le curseur
        });
        context.closePath(); // on arrête le dessin
        // (!) Konva specific method, it is very important
        context.fillStrokeShape(shape);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const shapeDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
        setState((prev) => ({
            ...prev,
            isDragging: true,
        }));
    };
    const shapeDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
        // Konva déplace une forme par rapport à une position et les points sont relatifs à cette position
        // ici, on préfère déplacer les point par rapport à leur position dans le canvas directement

        const newPts = [...state.pts];

        newPts.forEach((pt: Type_point): void => {
            pt.x += e.target.x();
            pt.y += e.target.y();
        });

        const newLabel = { ...state.label };
        newLabel.pt.x += e.target.x();
        newLabel.pt.y += e.target.y();

        setState((prev) => ({
            ...prev,
            isDragging: false,
            pts: newPts,
            label: newLabel,
        }));

        e.target.x(0);
        e.target.y(0);
    };

    const shapeMouseOver = (e: Konva.KonvaEventObject<MouseEvent>) => {
        e.target.opacity(opacityHover);
    };
    const shapeMouseOut = (e: Konva.KonvaEventObject<MouseEvent>) => {
        e.target.opacity(opacity);
    };

    // LINE ----------------------------

    const lineOnClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;

        const newPts = [...state.pts];

        newPts.splice(state.isCircle.i + 1, 0, {
            x: state.isCircle.x,
            y: state.isCircle.y,
        });

        setState((prev) => ({
            ...prev,
            pts: newPts,
        }));
    };
    const lineOnMouseOut = (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        e: Konva.KonvaEventObject<MouseEvent>,
    ) => {
        setState((prev) => ({
            ...prev,
            isCircle: {
                x: 0,
                y: 0,
                i: 0,
                isVisible: false,
            },
        }));
    };
    const lineOnMouseMove = (
        e: Konva.KonvaEventObject<MouseEvent>,
        linePt: number[],
        index: number,
    ) => {
        e.cancelBubble = true;

        const line: Konva.Line = e.currentTarget as Konva.Line;
        const stage: Konva.Stage | null = line.getStage();

        if (stage) {
            const mousePos = stage.getRelativePointerPosition();
            if (mousePos) {
                const pt = previewPoint(linePt, mousePos);

                setState((prev) => ({
                    ...prev,
                    isCircle: {
                        x: pt.x,
                        y: pt.y,
                        i: index,
                        isVisible: true,
                    },
                }));
            }
        }
    };

    // CIRCLE SHAPE POINTS ----------------

    const shapePointDoubleClick = (
        e: Konva.KonvaEventObject<MouseEvent>,
        index: number,
    ) => {
        e.cancelBubble = true;
        const newPts = [...state.pts];
        if (newPts.length > 3) {
            newPts.splice(index, 1);
            setState((prev) => ({
                ...prev,
                pts: newPts,
            }));
        }
    };

    const shapePointDragStart = () => {
        setState((prev) => ({
            ...prev,
            isCircle: {
                x: 0,
                y: 0,
                i: 0,
                isVisible: false,
            },
        }));
    };
    const shapePointDragEnd = (
        e: Konva.KonvaEventObject<MouseEvent>,
        index: number,
    ) => {
        const newPts = [...state.pts];
        newPts[index].x = e.target.x();
        newPts[index].y = e.target.y();
        setState((prev) => ({
            ...prev,
            pts: newPts,
        }));
    };

    const shapePointDragMove = (
        e: Konva.KonvaEventObject<MouseEvent>,
        index: number,
    ) => {
        const distanceMagnet: number =
            stageScale > 1
                ? confDistanceMagnet / stageScale
                : confDistanceMagnet;

        const newPts = [...state.pts];
        let x: number = e.target.x();
        let y: number = e.target.y();

        // -----
        let other: Type_point = { x: 0, y: 0 };
        let distance: number = Number.MAX_SAFE_INTEGER;

        let otherX: number = 0;
        let otherY: number = 0;
        let distanceX: number = Number.MAX_SAFE_INTEGER;
        let distanceY: number = Number.MAX_SAFE_INTEGER;

        const layer: Konva.Layer | null = e.target.getLayer();
        if (layer) {
            const nodes = layer.find(".shape"); // on récupère toute les formes ayant un name='shape'
            nodes.forEach((node) => {
                if (node.attrs.id !== who.toString()) {
                    // pour toutes les autres formes
                    node.attrs.pts.forEach((pt: Type_point): void => {
                        const dist: number = getDistance(
                            {
                                x: pt.x,
                                y: pt.y,
                            },
                            {
                                x: x,
                                y: y,
                            },
                        );

                        // par rapport à un point
                        if (dist < distance) {
                            distance = dist;
                            other = {
                                x: pt.x,
                                y: pt.y,
                            };
                        }

                        // par rapport à l'abscisse d'un autre point
                        if (Math.abs(pt.x - x) < distanceX) {
                            otherX = pt.x;
                            distanceX = Math.abs(pt.x - x);
                        }
                        // par rapport à l'ordonnée d'un autre point
                        if (Math.abs(pt.y - y) < distanceY) {
                            otherY = pt.y;
                            distanceY = Math.abs(pt.y - y);
                        }
                    });
                }
            });
        }

        if (distance < distanceMagnet) {
            // je touche un point d'une autre forme = je colle
            x = other.x;
            y = other.y;
        } else {
            // je compare la distance en parcourant les points de la forme courante
            newPts.forEach((pt: any, i: number): void => {
                if (i === index) return; // j'ignore le point actuellement sélectionné

                if (Math.abs(pt.x - x) < distanceX) {
                    otherX = pt.x;
                    distanceX = Math.abs(pt.x - x);
                }

                if (Math.abs(pt.y - y) < distanceY) {
                    otherY = pt.y;
                    distanceY = Math.abs(pt.y - y);
                }
            });

            if (distanceX < distanceMagnet) {
                x = otherX;
            }

            if (distanceY < distanceMagnet) {
                y = otherY;
            }
        }

        newPts[index].x = x;
        newPts[index].y = y;

        setState((prev) => ({
            ...prev,
            pts: newPts,
        }));

        e.target.x(x);
        e.target.y(y);
    };

    return (
        <Portal selector=".top" enabled={isSelected}>
            <Group>
                <K_Shape
                    sceneFunc={shapeDrawFunction}
                    id={who.toString()}
                    pts={state.pts}
                    key={who * 30000}
                    name="shape"
                    fill={color}
                    stroke={strokeColor}
                    strokeWidth={1 / stageScale}
                    opacity={opacity}
                    perfectDrawEnabled={false} // https://konvajs.org/docs/performance/Disable_Perfect_Draw.html
                    draggable={isSelected}
                    lineJoin="round"
                    onClick={(e) => {
                        e.cancelBubble = true;
                        changeSelecting(who);
                    }}
                    onDragStart={shapeDragStart}
                    onDragEnd={shapeDragEnd}
                    onMouseOver={shapeMouseOver}
                    onMouseOut={shapeMouseOut}
                    {...shapeProps}
                />

                {state.isCircle.isVisible && (
                    <Circle
                        key={who * 50000}
                        x={state.isCircle.x}
                        y={state.isCircle.y}
                        radius={6 / stageScale}
                        fill={addPointCircleColor}
                    />
                )}

                {isSelected &&
                    !state.isDragging &&
                    linesPt.map((linePt: number[], index: number) => (
                        <Line
                            key={who * 20000 + index}
                            points={linePt}
                            stroke={selectedLineColor}
                            strokeWidth={3 / stageScale}
                            hitStrokeWidth={5}
                            onClick={lineOnClick}
                            onMouseOut={lineOnMouseOut}
                            onMouseMove={(e) =>
                                lineOnMouseMove(e, linePt, index)
                            }
                        />
                    ))}

                {isSelected &&
                    !state.isDragging &&
                    state.pts.map((pt: Type_point, index: number) => (
                        // On dessine tous les points des extrémités des lignes
                        <Circle
                            key={who * 10000 + index}
                            x={pt.x}
                            y={pt.y}
                            radius={4 / stageScale}
                            fill={selectedCornerCircleColor}
                            draggable={isSelected}
                            hitStrokeWidth={10}
                            onClick={(
                                e: Konva.KonvaEventObject<MouseEvent>,
                            ) => {
                                e.cancelBubble = true;
                            }}
                            onMouseOver={(
                                e: Konva.KonvaEventObject<MouseEvent>,
                            ) => {
                                e.currentTarget.to({
                                    fill: addPointCircleColor,
                                    radius: 6 / stageScale,
                                });
                            }}
                            onMouseOut={(
                                e: Konva.KonvaEventObject<MouseEvent>,
                            ) => {
                                e.currentTarget.to({
                                    fill: selectedCornerCircleColor,
                                    radius: 4 / stageScale,
                                });
                            }}
                            onDblClick={(
                                e: Konva.KonvaEventObject<MouseEvent>,
                            ) => shapePointDoubleClick(e, index)}
                            onDragStart={shapePointDragStart}
                            onDragEnd={(
                                e: Konva.KonvaEventObject<MouseEvent>,
                            ) => shapePointDragEnd(e, index)}
                            onDragMove={(
                                e: Konva.KonvaEventObject<MouseEvent>,
                            ) => {
                                shapePointDragMove(e, index);
                            }}
                        />
                    ))}

                {!state.isDragging && (
                    <Text
                        key={who * 60000}
                        ref={shapeRef}
                        text={state.label.name}
                        fontSize={state.label.fontSize ?? 20}
                        x={state.label.pt.x}
                        y={state.label.pt.y}
                        rotation={state.label.rotation}
                        width={state.label.width}
                        draggable={isSelected}
                        onClick={(e: Konva.KonvaEventObject<MouseEvent>) => {
                            e.cancelBubble = true;
                            changeSelecting(who);
                            setIsTextSelected(true);
                        }}
                        onDragEnd={(e) => {
                            const newLabel = { ...state.label };
                            newLabel.pt.x = e.target.x();
                            newLabel.pt.y = e.target.y();

                            setState((prev) => ({
                                ...prev,
                                label: newLabel,
                            }));
                        }}
                        onTransform={(e) => {
                            const width = Math.max(
                                e.target.width() * e.target.scaleX(),
                                minWidthText,
                            );
                            e.target.width(width);
                            e.target.scaleX(1);
                            e.target.scaleY(1);
                        }}
                        onTransformEnd={(e) => {
                            const width = Math.max(
                                e.target.width() * e.target.scaleX(),
                                minWidthText,
                            );

                            const newLabel = { ...state.label };
                            newLabel.width = width;
                            newLabel.rotation = e.target.rotation();

                            setState((prev) => ({
                                ...prev,
                                label: newLabel,
                            }));
                        }}
                    />
                )}
            </Group>
            {isTextSelected && !state.isDragging && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    padding={5}
                    rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
                    rotationSnapTolerance={15}
                    enabledAnchors={["middle-left", "middle-right"]}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (Math.abs(newBox.width) < minWidthText) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Portal>
    );
    // }
};
