import { Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import {
    useCloseTFE,
    useGetPunchlists,
    useGetRoadblocks,
    useGetTasksAreasReport,
    useOpenTFE,
} from "src/api/tms-tfe";
import { LoadingBox } from "src/components/Components_Common";
import { PieGraph } from "src/components/Components_Teamoty/Dashboard/highCharts/PieGraph";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const Graphs = (): ReactElement => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Dashboard.Graphs",
    );

    const { data: token } = useOpenTFE();

    // AllTasksChart
    const { data: AllTasksItems, isFetched: AllTasksAreFetched } =
        useGetTasksAreasReport(token);

    // PunchlistItemChart
    const { data: PunchlistItems, isFetched: PunchlistAreFetched } =
        useGetPunchlists(token);

    // RoadblocksChart
    const { data: RoadblocksItems, isFetched: RoadblocksAreFetched } =
        useGetRoadblocks(token);

    useCloseTFE(
        token,
        AllTasksAreFetched && PunchlistAreFetched && RoadblocksAreFetched,
    );

    return (
        <>
            <Typography variant="h3">{fmt("Overview")}</Typography>
            <Stack spacing={2} direction="row">
                {AllTasksItems ? (
                    <PieGraph
                        data={AllTasksItems}
                        fmt={fmt}
                        title={"AllTasks"}
                    />
                ) : (
                    <LoadingBox />
                )}

                {PunchlistItems ? (
                    <PieGraph
                        data={PunchlistItems}
                        fmt={fmt}
                        title={"Punchlist"}
                    />
                ) : (
                    <LoadingBox />
                )}

                {RoadblocksItems ? (
                    <PieGraph
                        data={RoadblocksItems}
                        fmt={fmt}
                        title={"Roadblocks"}
                    />
                ) : (
                    <LoadingBox />
                )}
            </Stack>
        </>
    );
};
