import { Grid, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

import { Curve } from "./Curve";
import { Graphs } from "./Graphs";

const widgets = {
    graphs: Graphs,
    curve: Curve,
};

export const DashboardCard = ({ widget }: { widget: string }) => {
    const Component = widgets[widget as keyof typeof widgets];

    const theme = useTheme();

    return (
        <Grid
            item
            xs={12}
            md={6}
            xl={4}
            sx={{ backgroundColor: theme.palette.moon[100] }}
        >
            <Box sx={{ backgroundColor: theme.palette.common.white }}>
                <Component data-testid="DashboardCard-Component" />
            </Box>
        </Grid>
    );
};
