import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { RoleKeys } from "src/api/tms-users/keys";
import {
    filterPermissions,
    formatterIndexRole,
    formatterIndexRoles,
    formatterSelectListRoles,
    formatterShowRole,
    formatterUpdateRole,
} from "src/api/tms-users/rolesAndPermissions/formatters";
import {
    createRole,
    deleteRole,
    indexRoles,
    selectListRoles,
    showRole,
    statusRole,
    updateRole,
} from "src/api/tms-users/rolesAndPermissions/services";
import {
    Type_show_role,
    Type_usr_post_role,
    Type_usr_put_role,
} from "src/api/tms-users/rolesAndPermissions/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexRoles = () => {
    return useQuery({
        queryKey: [RoleKeys.INDEX],
        queryFn: indexRoles,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexRoles");
            }
            return formatterIndexRoles(data.data.data);
        },
    });
};

export const mutationStatusRole = () => {
    const queryClient = useQueryClient();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (newStatus: Type_usr_put_role) => {
            return statusRole({
                id: newStatus.id,
                enabled: newStatus.enabled,
            });
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusRole");
            }
            await queryClient.invalidateQueries([RoleKeys.INDEX]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCreateRole = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Roles");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newRole: Type_usr_post_role) =>
            createRole({
                ...newRole,
                permissions: filterPermissions(newRole.permissions),
            }),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateRole");
            }
            const role = formatterIndexRole(data.data.data);

            await queryClient.invalidateQueries([RoleKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        role: role.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateRole = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Roles");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (roleToUpdate: Type_show_role) => {
            return updateRole(formatterUpdateRole(roleToUpdate));
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateRole");
            }
            const role = formatterIndexRole(data.data.data);

            await queryClient.invalidateQueries([RoleKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        role: role.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useSelectListRoles = () => {
    return useQuery({
        queryKey: [RoleKeys.SELECT_LIST],
        queryFn: selectListRoles,
        refetchOnWindowFocus: false,
        select: (res) => {
            if (!res?.success || !res?.data?.data) {
                throw new Error("Wrong format data: useSelectListRoles");
            }
            return formatterSelectListRoles(res.data.data);
        },
        onError: (err) => {
            return err;
        },
    });
};

export const useShowRole = (id: number) => {
    return useQuery([RoleKeys.SHOW, id], () => showRole(id), {
        select: (res) => {
            if (!res?.success || !res?.data?.data) {
                throw new Error("Wrong format data: useShowRole");
            }
            return formatterShowRole(res.data.data);
        },
        onError: (err) => {
            return err;
        },
        enabled: !!id,
        refetchOnWindowFocus: false,
    });
};

export const mutationDeleteRole = () => {
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Roles");

    return useMutation({
        mutationFn: (id: number) => deleteRole(id),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteRole");
            }
            await queryClient.invalidateQueries([RoleKeys.INDEX]);

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError", {}),
            });
            return err;
        },
    });
};
