import {
    Type_com_index_projectType,
    Type_com_post_projectType,
    Type_com_put_projectType,
    Type_com_show_projectType,
    Type_index_projectType,
    Type_show_projectType,
} from "src/api/tms-commons/projectTypes/types";
import { Type_api_selectList, Type_selectList } from "src/api/types";

export const formatterIndexProjectTypes = (
    projectTypes: Type_com_index_projectType[],
): Type_index_projectType[] => {
    if (!projectTypes) return [];
    return projectTypes.map((projectType: Type_com_index_projectType) =>
        formatterIndexProjectType(projectType),
    );
};

export const formatterIndexProjectType = (
    projectType: Type_com_index_projectType,
): Type_index_projectType => {
    return {
        id: projectType.id,
        enabled: projectType.enabled,
        name: projectType.name,
        areaTypesCount: projectType.areaTypesCount,
    };
};

export const formatterShowProjectType = (
    projectType: Type_com_show_projectType,
): Type_show_projectType => {
    return {
        id: projectType.id,
        names: projectType.names,
        areaTypes: projectType.areaTypes.map((areaType: any) => areaType.id),
        enabled: projectType.enabled,
    };
};

export const formatterPutProjectType = (
    projectType: Type_show_projectType,
): Type_com_put_projectType => {
    return {
        id: projectType.id,
        names: projectType.names,
        areaTypes: projectType.areaTypes,
    };
};

export const formatterPostProjectType = (
    projectType: Type_show_projectType,
): Type_com_post_projectType => {
    return {
        names: projectType.names,
        areaTypes: projectType.areaTypes,
    };
};

export const formatterSelectListProjectTypes = (
    projectTypes: Type_api_selectList[],
): Type_selectList[] => {
    if (!projectTypes) return [];
    return projectTypes.map((projectType: Type_api_selectList) => ({
        id: projectType.id,
        name: projectType.name,
    }));
};
