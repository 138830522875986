import { styled, Typography } from "@mui/material";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

export const Styled_FormHelperMessage = styled("span")(() => ({
    display: "flex",
    alignItems: "center",
    color: COLORS.warning,
}));

type Type_Props_FormHelperMessage = {
    message: string;
    isRtlLang?: boolean;
    inputName: string;
};

export const FormHelperMessage = ({
    message,
    isRtlLang,
    inputName,
}: Type_Props_FormHelperMessage) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Form.Error");

    return (
        <Styled_FormHelperMessage
            data-testid={`FormHelperText-${message}-${inputName}`}
        >
            <Icon
                variant="light"
                icon="circle-xmark"
                sx={(theme) =>
                    isRtlLang
                        ? { paddingLeft: theme.spacing(2) }
                        : { paddingRight: theme.spacing(2) }
                }
            />
            <Typography component={"span"} variant={"body2"}>
                {fmt(message)}
            </Typography>
        </Styled_FormHelperMessage>
    );
};
