import { Stack, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { ReactElement, useState } from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { TMC_Chip } from "src/components/Components_Common/Chip/Chip";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { Type_event_message, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////
///     STYLED                             ///
//////////////////////////////////////////////

const Styled_Toolbar = styled(Toolbar)(() => ({
    width: "100%",
    justifyContent: "space-between",
    minHeight: 24,
    "&.MuiToolbar-root": {
        padding: 0,
    },
}));

const Styled_ToolbarStack = styled(Stack)(() => ({
    alignItems: "center",
}));

Styled_ToolbarStack.defaultProps = {
    direction: "row",
};

const Styled_FlagContent = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
}));

//////////////////////////////////////////////
///     TYPES                              ///
//////////////////////////////////////////////

type Type_taskIcon = {
    [key: number]: ReactElement;
};

export const taskIcon: Type_taskIcon = {
    1: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-work"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    2: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-study"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    3: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-waiting"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    4: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-milestone"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    5: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-delivery"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
    6: (
        <Icon
            variant={"kit"}
            icon={"solid-tds-task-technical"}
            fontSize={"large"}
            color={"inherit"}
        />
    ),
};

type Type_Props_HeaderToolbar = {
    onClose: () => void;
    areaId?: number;
    taskId?: number;
    taskAreaId?: number;
    sequenceId?: number;
    showFlagObservations?: boolean;
    notes?: "taskNotes" | "sequenceNotes" | "taskAreaNotes";
    notesCount?: number;
    dataTestIdRef?: string;
    onRefresh?: () => void;
    children?: ReactElement;
};

export const HeaderToolbar = ({
    onClose,
    areaId,
    taskId,
    taskAreaId,
    sequenceId,
    notesCount,
    notes,
    showFlagObservations = false,
    dataTestIdRef = "Toolbar-Header",
    onRefresh,
    children,
}: Type_Props_HeaderToolbar) => {
    const { openPaper } = useContextualDrawer();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Header",
    );

    ///////////////////////////////////////
    ///            States               ///
    ///////////////////////////////////////
    const [observationsCount, setObservationsCount] = useState<number>(0);
    const [notesCounting, setNotesCount] = useState<number>(notesCount || 0);

    useChannel({
        eventHandler: ({ event, data }: Type_event_message) => {
            const count = data as number;
            if (event === "setFlagObservations") {
                if (observationsCount !== count) {
                    setObservationsCount(count);
                }
            }
            if (event === "changeFlagObservations") {
                // change flag count with delta (+/-)
                setObservationsCount((old) => old + count);
            }
            if (event === "updateNotesCount") {
                // change flag count with delta (+/-)
                setNotesCount((old) => old + count);
            }
        },
    });

    const handleEditNotes = (): void => {
        notes &&
            openPaper(notes, {
                taskId: taskId,
                sequenceId: sequenceId,
                taskAreaId: taskAreaId,
                areaId: areaId,
                focus: true,
            });
    };

    return (
        <Styled_Toolbar variant="dense">
            <Styled_ToolbarStack spacing={1}>{children}</Styled_ToolbarStack>
            <Styled_ToolbarStack sx={{ paddingX: 2 }}>
                {showFlagObservations && observationsCount > 0 && (
                    <TMC_Chip
                        data-testid={`${dataTestIdRef}-Observations-Chip`}
                        backgroundColor={"error.main"}
                        colorText="white"
                        size={"small"}
                        title={fmt("Tooltip.Observations")}
                        label={
                            <Styled_FlagContent>
                                <Icon
                                    variant="solid"
                                    icon="flag"
                                    fontSize="small"
                                />
                                <Typography variant="body2">
                                    {observationsCount}
                                </Typography>
                            </Styled_FlagContent>
                        }
                    />
                )}
                {notes && (
                    <>
                        <IconButton
                            data-testid={`${dataTestIdRef}-Notes-Edit`}
                            color={"inherit"}
                            size={"small"}
                            title={fmt("Tooltip.Notes")}
                            onClick={handleEditNotes}
                        >
                            <Icon
                                variant={"light"}
                                icon={"sticky-note"}
                                fontSize={"small"}
                            />
                            {notesCounting > 0 && (
                                <Typography
                                    data-testid={`${dataTestIdRef}-Notes-Count`}
                                    variant="body3"
                                    pl={2}
                                >
                                    {notesCounting}
                                </Typography>
                            )}
                        </IconButton>
                    </>
                )}
                {!!onRefresh && (
                    <IconButton
                        data-testid={`${dataTestIdRef}-Reload-Icon`}
                        color={"inherit"}
                        onClick={onRefresh}
                        size={"small"}
                        title={fmt("Tooltip.Reload")}
                    >
                        <Icon
                            variant={"light"}
                            icon={"rotate"}
                            fontSize={"small"}
                        />
                    </IconButton>
                )}
                <IconButton
                    data-testid={`${dataTestIdRef}-Close-Icon`}
                    color={"inherit"}
                    onClick={onClose}
                    size={"small"}
                    title={fmt("Tooltip.Close")}
                >
                    <Icon variant={"solid"} icon={"xmark"} fontSize={"small"} />
                </IconButton>
            </Styled_ToolbarStack>
        </Styled_Toolbar>
    );
};
