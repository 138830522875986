import { Box, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";

import { APP_BAR_HEIGHT } from "src/components/Components_Teamoty/TopBar/stylesheet";
import { Type_Direction } from "src/hooks/useResizable";
import { Type_Props_ResizableDrawer } from "src/layouts/LayoutDashboard/ResizableDrawer/ResizableDrawer";
import { COLORS } from "src/theme/stylesheet";

export const Styled_Box = styled(Box)<Omit<Type_Props_ResizableDrawer, "name">>(
    ({ theme, direction }: { theme: Theme; direction: Type_Direction }) => {
        let positioningProperties = {};

        if (direction === "top") {
            positioningProperties = {
                top: `${APP_BAR_HEIGHT}px`,
                minHeight: "25%",
                height: "8rem",
                width: "100%",
                maxHeight: "100vh",
                "& .ResizableDrawerToolbar": {
                    borderTop: theme.border.default,
                    borderBottom: theme.border.default,
                },
            };
        } else if (direction === "bottom") {
            positioningProperties = {
                bottom: "0px",
                minHeight: "25%",
                height: "8rem",
                width: "100%",
                maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
                "& .ResizableDrawerToolbar": {
                    borderTop: theme.border.default,
                    borderBottom: theme.border.default,
                },
            };
        } else if (direction === "left") {
            positioningProperties = {
                left: "0px",
                top: 0,
                height: "100%",
                minWidth: "25%",
                maxWidth: "100vw",
                "& .ResizableDrawerToolbar": {
                    borderLeft: theme.border.default,
                    borderRight: theme.border.default,
                },
            };
        } else if (direction === "right") {
            positioningProperties = {
                right: "0px",
                top: 0,
                height: "100%",
                minWidth: "25%",
                maxWidth: "100vw",
                "& .ResizableDrawerToolbar": {
                    borderLeft: theme.border.default,
                    borderRight: theme.border.default,
                },
            };
        }
        return {
            position: "fixed",
            zIndex: 99,
            backgroundColor: theme.palette.background.default,
            ...positioningProperties,
            "& > #icon-resize": {
                background: "transparent",
                position: "absolute",
            },
            "&:hover > #icon-resize": {
                background: COLORS.black,
            },

            ".resizer--right_v": {
                cursor: "col-resize",
                top: "50%",
                transform: "translate(50%, -50%)",
                height: `${theme.spacing(8)}`,
                width: "0.25rem",
                left: "0",
            },

            ".resizer--left_v": {
                cursor: "col-resize",
                top: "50%",
                transform: "translate(50%, -50%)",
                height: `${theme.spacing(8)}`,
                width: "0.25rem",
                right: "0",
            },

            ".resizer--bottom_v": {
                cursor: "row-resize",
                top: "0",
                left: "50%",
                transform: "translate(-50%, 50%)",
                height: `${theme.spacing(1)}`,
                width: `${theme.spacing(16)}`,
            },

            ".resizer--top_v": {
                cursor: "row-resize",
                bottom: "0",
                left: "50%",
                transform: "translate(-50%, 50%)",
                height: `${theme.spacing(1)}`,
                width: `${theme.spacing(16)}`,
            },
        };
    },
);
