import { defaultPrintSettings } from "src/api/tms-print/print/types";
import {
    Enum_typeView,
    Type_default_view,
} from "src/api/tms-projects/workspacesAndViews/views/types";

export const getDefaultViews = (): Type_default_view[] => {
    return [
        {
            name: "View.Dashboard",
            order: 1,
            data: {
                version: 1,
                type: Enum_typeView.dashboard,
                settings: { widgets: [] },
            },
        },
        {
            name: "View.Planning",
            order: 2,
            data: {
                version: 1,
                type: Enum_typeView.planning,
                settings: {
                    print: defaultPrintSettings,
                },
            },
        },
        {
            name: "View.Gantt",
            order: 3,
            data: {
                version: 1,
                type: Enum_typeView.gantt,
                settings: {
                    print: defaultPrintSettings,
                },
            },
        },
        {
            name: "View.Workflow",
            order: 4,
            data: {
                version: 1,
                type: Enum_typeView.workflow,
                settings: { widgets: [] },
            },
        },
        {
            name: "View.AreaMap",
            order: 5,
            data: { version: 1, type: Enum_typeView.areamap, settings: {} },
        },
        {
            name: "View.Grid",
            order: 6,
            data: { version: 1, type: Enum_typeView.grid, settings: {} },
        },
    ];
};
