import Konva from "konva";
import React from "react";
import { Shape } from "react-konva";

import { Type_Props_FlowShapeWorkMilestone } from "./FlowShape.type";

export const FlowShapeWorkMilestone = ({
    pt,
    shapeFlowFunction,
    ...props
}: Type_Props_FlowShapeWorkMilestone) => {
    return (
        <Shape
            sceneFunc={(context: Konva.Context, shape: Konva.Shape): void => {
                shapeFlowFunction(
                    pt,
                    props.width as number,
                    props.height as number,
                    context,
                    shape,
                );
            }}
            {...props}
        />
    );
};
