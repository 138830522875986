import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { getParamsJoin, Type_Params } from "src/api/filters";
import { SequenceArea, Url } from "src/api/paths";

import { Type_sch_post_sequenceArea } from "./types";

export const indexSequenceAreas = (
    requestConfig: Type_RequestConfig,
    params: Type_Params = {},
): GetReturnType => {
    const urlParams: string = getParamsJoin(params);

    return get(
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}${urlParams}`,
        requestConfig,
    );
};

export const createSequenceArea = (
    data: Type_sch_post_sequenceArea,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}`,
        requestConfig,
    );
};

export const updateSequenceArea = (
    data: any,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data.data,
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}/${data?.id}`,
        requestConfig,
    );
};

export const deleteSequenceArea = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${SequenceArea.SEQUENCE_AREAS}/${id}`,
        requestConfig,
    );
};
