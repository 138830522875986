export type Type_Filter = {
    enabled?: boolean | undefined;
    global?: boolean | undefined;
    "sub-project"?: number | undefined; //here we use exactly the syntax for the filter in the API call. There is no camelCase and we need quotes
    sub_project_id?: number;
};

export type Type_Filter_Projects = Type_Filter & {
    slug?: string;
};

export type Type_Filter_Areas = Type_Filter & {
    drawing_id?: number;
    drawing_id_and_available?: number;
};

export type Type_Filter_License_Types = Type_Filter & {
    license_type_mode_id?: number;
};

export type Type_Filter_Sequence = Type_Filter & {
    sequence_id?: number;
    sequence_number?: number;
};

export type Type_Filter_Task = Type_Filter_Sequence & {
    task_id?: number;
    task_number?: number;
};

export type Type_Filter_ProgressHistory = Type_Filter & {
    area_id?: number;
    task_id?: number;
    user_id?: number;
};

export const getFilterJoin = (filters: Type_Filter): string => {
    const filterJoin: string[] = [];
    Object.entries(filters).forEach((entry) => {
        const [key, value] = entry;
        filterJoin.push(`filter[${key}]=${value}`);
    });

    return `${filterJoin.length ? "?" : ""}${filterJoin.join("&")}`;
};

export type Type_Params = {
    forMatrix?: boolean;
};

export const getParamsJoin = (
    params: Type_Params,
    urlParams: string = "",
): string => {
    const paramsJoin: string[] = [];
    Object.entries(params).forEach((entry) => {
        const [key, value] = entry;
        paramsJoin.push(`${key}=${value}`);
    });

    return `${urlParams.length ? "&" : "?"}${paramsJoin.join("&")}`;
};
