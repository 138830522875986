import { formatterSelectListArea } from "src/api/tms-projects/areas/formatters";
import { Enum_typeFlowLink } from "src/components/Components_Teamoty/Flow/Flow.enum";
import {
    Type_flowLink,
    Type_Props_Link,
} from "src/components/Components_Teamoty/Flow/Flow.type";

import {
    Type_index_taskLink,
    Type_Link_By_Direction,
    Type_post_taskLink,
    Type_put_taskLink,
    Type_sch_index_taskLink,
    Type_sch_post_taskLink,
    Type_sch_put_taskLink,
    Type_sch_select_taskLinkTask,
    Type_sch_show_taskLink,
    Type_select_taskLinkTask,
    Type_show_taskLink,
} from "./types";

export const formatterTasksLinksToFlow = (
    links: Type_index_taskLink[],
): Type_flowLink[] | null => {
    if (!links) return null;
    return links.map(formatterTaskLinkToFlow);
};

export const formatterTaskLinkToFlow = (
    link: Type_index_taskLink,
): Type_flowLink => {
    return {
        id: link.id,
        taskFrom: link.taskFrom?.id,
        taskTo: link.taskTo?.id,
        areaTo: link.areaTo?.id,
        areaFrom: link.areaFrom?.id,
        quantity: link.quantity,
        waitingDay: link.waitingDay,
        type: link.linkType as Enum_typeFlowLink,
    };
};

export const formatterLinksByDirection = (
    links: Type_index_taskLink[] | null,
    taskParentId: number,
    areaParentId?: number,
): Type_Link_By_Direction => {
    const myLink: Type_Link_By_Direction = { TO: [], FROM: [] };
    links?.forEach((link) => {
        if (link.taskTo?.id === taskParentId) {
            if (!areaParentId || link.areaTo?.id === areaParentId) {
                myLink.FROM.push(link);
            }
        } else {
            if (!areaParentId || link.areaFrom?.id === areaParentId) {
                myLink.TO.push(link);
            }
        }
    });
    return myLink;
};

export const formatterIndexTaskLink = (
    taskLink: Type_sch_index_taskLink,
): Type_index_taskLink => {
    return {
        id: taskLink.id,
        linkType: taskLink.linkType,
        dayMode: taskLink.dayMode,
        quantity: taskLink.quantity,
        waitingDay: taskLink.waitingDay,
        taskFrom: formatterSelectTaskLinkTask(taskLink.taskFrom),
        taskTo: formatterSelectTaskLinkTask(taskLink.taskTo),
        areaFrom: taskLink.areaFrom
            ? formatterSelectListArea(taskLink.areaFrom)
            : null,
        areaTo: taskLink.areaTo
            ? formatterSelectListArea(taskLink.areaTo)
            : null,
        taskAreaFromId: taskLink.taskAreaFrom_id,
        taskAreaToId: taskLink.taskAreaTo_id,
    };
};

export const formatterIndexTaskLinks = (
    taskLinks: Type_sch_index_taskLink[],
): Type_index_taskLink[] => {
    return taskLinks.map(formatterIndexTaskLink);
};

export const formatterShowTaskLink = (
    taskLink: Type_sch_show_taskLink,
): Type_show_taskLink => {
    return {
        id: taskLink.id,
        linkType: taskLink.linkType,
        dayMode: taskLink.dayMode,
        quantity: taskLink.quantity,
        waitingDay: taskLink.waitingDay,
        taskFrom: formatterSelectTaskLinkTask(taskLink.taskFrom),
        taskTo: formatterSelectTaskLinkTask(taskLink.taskTo),
        areaFrom: taskLink.areaFrom
            ? formatterSelectListArea(taskLink.areaFrom)
            : null,
        areaTo: taskLink.areaTo
            ? formatterSelectListArea(taskLink.areaTo)
            : null,
        taskAreaFromId: taskLink.taskAreaFrom_id,
        taskAreaToId: taskLink.taskAreaTo_id,
    };
};

export const formatterCreateTaskLink = (
    taskLink: Type_post_taskLink,
): Type_sch_post_taskLink => {
    const {
        taskTo,
        taskFrom,
        areaTo,
        areaFrom,
        taskAreaFromId,
        taskAreaToId,
        ...restTaskLink
    } = taskLink;

    return {
        ...restTaskLink,
        ...(!!taskLink.taskTo?.id !== null && { taskTo_id: taskTo?.id }),
        ...(!!taskLink.taskFrom?.id && { taskFrom_id: taskFrom?.id }),
        ...(!!areaTo?.id && { areaTo_id: areaTo?.id }),
        ...(!!areaFrom?.id && { areaFrom_id: areaFrom?.id }),
        ...(!!taskAreaToId && { taskAreaTo_id: taskAreaToId }),
        ...(!!taskAreaFromId && { taskAreaFrom_id: taskAreaFromId }),
    };
};

export const formatterUpdateTaskLink = (
    taskLink: Type_put_taskLink,
): Type_sch_put_taskLink => {
    const {
        taskTo,
        taskFrom,
        areaTo,
        areaFrom,
        taskAreaToId,
        taskAreaFromId,
        ...restTaskLink
    } = taskLink;

    const isValidId = (id: any): boolean =>
        typeof id === "number" && !isNaN(id);

    return {
        ...restTaskLink,
        ...(taskTo && isValidId(taskTo.id) && { taskTo_id: taskTo.id }),
        ...(taskFrom && isValidId(taskFrom.id) && { taskFrom_id: taskFrom.id }),
        ...(areaTo && isValidId(areaTo.id)
            ? { areaTo_id: areaTo.id }
            : { areaTo_id: null }),
        ...(areaFrom && isValidId(areaFrom.id)
            ? { areaFrom_id: areaFrom.id }
            : { areaFrom_id: null }),
        taskAreaTo_id: taskAreaToId,
        taskAreaFrom_id: taskAreaFromId,
    };
};

export const formatterTypePropsLinkToPostTaskLink = ({
    taskTo,
    taskFrom,
    type,
}: Type_Props_Link): Type_post_taskLink => {
    return {
        taskFrom: { id: taskFrom },
        taskTo: { id: taskTo },
        linkType: type,
    };
};

export const formatterSelectTaskLinkTask = (
    data: Type_sch_select_taskLinkTask,
): Type_select_taskLinkTask => {
    return {
        id: data.id,
        code: data.code,
        name: data.name,
        sequence: data.sequence,
    };
};
