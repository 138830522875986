import Box from "@mui/material/Box";
import LinearProgress, {
    LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number; step: number },
) {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {props.step ? `${Math.round(props.value)}%` : ""}
                </Typography>
            </Box>
        </Box>
    );
}

type Type_LinearWithValueLabel = {
    step: number;
    value: number;
};

export const LinearWithValueLabel = ({
    step,
    value,
}: Type_LinearWithValueLabel) => {
    return (
        <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel
                value={(value / step) * 100 || 0}
                step={step}
            />
        </Box>
    );
};
