import { Stack, TextFieldProps } from "@mui/material";
import React, { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { FormHelperMessage } from "src/components/Components_Common/_MuiComponentsVariants/FormHelperText/FormHelperMessage";
import {
    Type_DataTestIdComponent,
    Type_ReadonlyComponent,
} from "src/components/Components_Common/types";

type Type_Props_TextField = TextFieldProps &
    Type_ReadonlyComponent &
    Type_DataTestIdComponent & {
        name: string;
        endAdornment?: Element;
    };

export const TextField = forwardRef<HTMLInputElement, Type_Props_TextField>(
    ({ name, ...props }, ref) => {
        const { formState, control } = useFormContext();

        return (
            <Stack>
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { ref: inputRef, ...rest } }) => (
                        <TMC_TextField
                            {...props}
                            {...rest}
                            inputRef={ref || inputRef}
                            data-testid={`TextFieldInput-${name}`}
                            error={!!formState.errors[name]}
                            helperText={
                                formState.errors[name] && (
                                    <FormHelperMessage
                                        inputName={name}
                                        message={
                                            formState?.errors[name]
                                                ?.message as string
                                        }
                                    />
                                )
                            }
                        />
                    )}
                />
            </Stack>
        );
    },
);

TextField.displayName = "TextField";
