import { Components } from "@mui/material";

export const MuiDialogTitleTheme: Components["MuiDialogTitle"] = {
    styleOverrides: {
        root: () => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }),
    },
};
