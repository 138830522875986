import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactElement } from "react";

export const Styled_DashboardHeader = styled(Stack)(({ theme }) => ({
    height: "240px",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
}));

export const HeaderDashboard = (): ReactElement => {
    const theme = useTheme();

    return (
        <Grid
            item
            xs={12}
            sx={{ backgroundColor: theme.palette.background.paperSecondary }}
        >
            <Styled_DashboardHeader>
                <Typography>Tuesday, July 9 2024</Typography>
                <Typography>
                    <strong>Hello, Mr bond</strong>
                </Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Typography>
                        <strong>2 days ago</strong> last checking
                    </Typography>
                    <Typography>
                        <strong>88</strong> tasks with delay
                    </Typography>
                    <Typography>
                        <strong>88</strong> estimated delay
                    </Typography>
                    <Typography>
                        <strong>4,53%</strong> global progression
                    </Typography>
                </Stack>
            </Styled_DashboardHeader>
        </Grid>
    );
};
