import { DrawerProps } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { ProjectLanguagesForm } from "src/forms/projectLanguages/ProjectLanguagesForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_Props_ProjectLanguageDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    page: string;
};

export const ProjectLanguagesDrawer = ({
    open,
    onClose,
    page,
}: Type_Props_ProjectLanguageDrawer) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectLanguages",
    );
    const [isLoading, setIsLoading] = useState(false);

    return (
        <DrawerGeneric
            open={open}
            onClose={onClose}
            dataTestIdTitle={`ProjectLanguages-Drawer-Title`}
            title={fmt("Title")}
            page={page}
            isFormLoading={isLoading}
            formId={"projectLanguages"}
        >
            <ProjectLanguagesForm
                onClose={onClose}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
            />
        </DrawerGeneric>
    );
};
