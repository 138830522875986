import { TreeItem2Label } from "@mui/x-tree-view";
import { UseTreeItem2LabelSlotProps } from "@mui/x-tree-view/useTreeItem2/useTreeItem2.types";

import { Type_index_sequenceFolderTree_task } from "src/api/tms-scheduling/sequenceFolders/types";
import { TaskBadge } from "src/components/Components_Teamoty/badges/TaskBadge";

type Type_Props_SequencesTreeItemLabelTask = Omit<
    UseTreeItem2LabelSlotProps,
    "children"
> & {
    item: Type_index_sequenceFolderTree_task;
};

export const SequencesTreeItemLabelTask = ({
    item,
    ...treeItemProps
}: Type_Props_SequencesTreeItemLabelTask) => {
    return (
        <TreeItem2Label
            {...treeItemProps}
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <TaskBadge task={item} />
        </TreeItem2Label>
    );
};
