import { Grid } from "@mui/material";
import React from "react";

import { Type_SpinnerComponentProps } from "src/components/Components_Common/Spinner/Spinner";

import { Spinner } from "..";

export const LoadingBox = ({
    style,
}: Omit<Type_SpinnerComponentProps, "props">) => {
    return (
        <Grid
            item
            flexGrow={1}
            container
            justifyContent="center"
            alignItems="center"
            height="100%"
            data-testid={"LoadingBox-Container"}
        >
            <Spinner style={style} />
        </Grid>
    );
};
