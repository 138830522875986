import { GridColDef, GridRowModel } from "@mui/x-data-grid-premium";
import React from "react";
import { QueryObserverResult } from "react-query";

import { singleSelectColumn } from "src/components/Components_Common/matrix/components/SingleSelectColumn";
import { Type_Props_MemoizedDataGridPro } from "src/components/Components_Common/matrix/Matrix";
import { MatrixTree } from "src/components/Components_Common/matrix/MatrixTree";

const rows: GridRowModel[] = [
    {
        pathName: "Zone1",
        name: "Zone 1",
        color: "red",
        areaType: "Batiment",
        id: 1,
        path: [1],
    },
    {
        pathName: "Zone1/Zone1.1",
        name: "Zone 1.1",
        color: "green",
        areaType: "Appartement",
        id: 2,
        path: [1, 2],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.1",
        name: "Zone 1.1.1",
        color: "blue",
        areaType: "Chambre",
        id: 3,
        path: [1, 2, 3],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.2",
        name: "Zone 1.1.2",
        color: "blue",
        areaType: "Salle de bain",
        id: 4,
        path: [1, 2, 4],
    },
    {
        pathName: "Zone1/Zone1.1/Zone1.1.3",
        name: "Zone 1.1.3",
        color: "blue",
        areaType: "Salon",
        id: 5,
        path: [1, 2, 5],
    },
    {
        pathName: "Zone1/Zone1.2",
        name: "Zone 1.2",
        color: "white",
        areaType: "Appartement",
        id: 6,
        path: [1, 6],
    },
];

const columnsDefault: GridColDef[] = [
    {
        field: "color",
        headerName: "Color éditable",
        flex: 1,
        editable: true,
    },
    {
        field: "areaType",
        headerName: "Area type éditable",
        flex: 1,
        editable: true,
    },
];

const columnsWithAreaNameEditable: GridColDef[] = [
    {
        field: "name",
        headerName: "Area editable",
        flex: 1,
        editable: true,
    },
    {
        field: "color",
        headerName: "Color editable",
        flex: 1,
        editable: true,
    },
    singleSelectColumn({
        field: "areaType",
        headerName: "AreaType Composant SingleSelectColumn",
        type: "singleSelect",
        valueOptions: ["Appartement", "Chambre", "Salle de bain", "Salon"],
        editable: true,
        flex: 1,
    }),
];

export const MatrixTreeDemo = () => {
    const mockMutateUpdate: Type_Props_MemoizedDataGridPro["mutateUpdate"] =
        async () => {
            return Promise.resolve({
                data: {},
            });
        };

    const mockRefetch: any = async () => {
        return Promise.resolve({
            data: [
                { id: 1, name: "Row 1" },
                { id: 2, name: "Row 2" },
            ],
            error: null,
            status: "success",
            isLoading: false,
            isError: false,
            isSuccess: true,
            isFetching: false,
            failureCount: 0,
            refetch: () => Promise.resolve({} as QueryObserverResult<any, any>),
        });
    };

    return (
        <>
            <MatrixTree
                mutateUpdate={mockMutateUpdate}
                refetch={mockRefetch}
                getTreeDataPath={(row: any) => row.pathName.split("/")}
                groupingHeaderName={"Areas non éditables"}
                columns={columnsDefault}
                rows={rows}
            />
            <MatrixTree
                mutateUpdate={mockMutateUpdate}
                refetch={mockRefetch}
                groupingCellComponent={() => <></>}
                columns={columnsWithAreaNameEditable}
                rows={rows}
            />
            <MatrixTree
                mutateUpdate={mockMutateUpdate}
                refetch={mockRefetch}
                columns={columnsWithAreaNameEditable}
                rows={rows}
            />
        </>
    );
};
