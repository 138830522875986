import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { ConstraintKeys } from "src/api/tms-scheduling/keys";
import { updateTaskAreaIdOnShowCache } from "src/api/tms-scheduling/taskArea";
import { NO_STALE_TIME, Type_stale_times } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getDate } from "src/utils/date";

import {
    formatterCreateConstraint,
    formatterIndexConstraint,
    formatterIndexConstraints,
    formatterSendEventIndexConstraint,
    formatterUpdateConstraint,
} from "./formatters";
import {
    createConstraint,
    deleteConstraint,
    indexConstraints,
    updateConstraint,
} from "./services";
import {
    ConstraintStatus,
    Type_defautlConstraintParams,
    Type_index_constraint,
    Type_sch_index_constraint,
} from "./types";

export const useIndexConstraints = (
    constraintConfig: Type_RequestConfig,
    staleTime: Type_stale_times = NO_STALE_TIME,
) => {
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...constraintConfig };

    return useQuery({
        queryKey: [ConstraintKeys.INDEX, config],
        queryFn: ({ signal }) => indexConstraints(config, signal),
        refetchOnWindowFocus: false,
        select: (data) => {
            const constraints: Type_sch_index_constraint[] = data?.data?.data;
            return formatterIndexConstraints(constraints);
        },
        enabled:
            !!config.projectId &&
            !!config.subProjectId &&
            !!config.versionId &&
            !!constraintConfig.taskAreaId,
        staleTime: staleTime,
    });
};

export const mutationCreateDefaultConstraint = (
    callback: (data: Type_index_constraint) => void,
    constraintConfig: Type_RequestConfig,
) => {
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    const { formatMessageWithPartialKey: fmtGeneric } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations",
    );
    const { addSuccess, addWarning } = useToast();
    const config = { ...requestConfig, ...constraintConfig };
    const queryClient = useQueryClient();

    const defaultConstraint = {
        description: fmt("DefaultDescription"),
        date: getDate().add(7, "days"),
        subTradeId: null,
        companyId: null,
        status: ConstraintStatus.TO_DO,
    };
    return useMutation({
        mutationFn: (constraintParams: Type_defautlConstraintParams) => {
            return createConstraint(
                formatterCreateConstraint({
                    ...defaultConstraint,
                    ...constraintParams,
                }),
                config,
            );
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateDefaultConstraint",
                );
            }
            const formattedData = formatterIndexConstraint(data?.data?.data);
            callback(formattedData);

            // si taskAreaId est null: cas de création de la premiers observation (c.a.d premiere modification de taskarea)
            // mis à jour l'id de taaskArea avec setQueryData
            if (!config.taskAreaId && !!formattedData.taskAreaId) {
                updateTaskAreaIdOnShowCache(
                    formattedData.taskAreaId,
                    constraintConfig.taskId!,
                    constraintConfig.areaId!,
                    requestConfig,
                    queryClient,
                );
            }

            // pour eviter de faire plusieurs appels api non-necessaires on envoie un event avec le nouveau objet à ajouter dans la liste.
            // sendEvent will stringify the data, so we need to transform DayJs to string
            sendEvent(
                "addObservation",
                formatterSendEventIndexConstraint(formattedData),
            );
            sendEvent("changeFlagObservations", 1);
            addSuccess({
                description: fmt("SuccessCreate"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtGeneric("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateConstraint = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Form.Error");
    const { addWarning } = useToast();
    const { sendEvent } = useChannel({});
    return useMutation({
        mutationFn: (constraint: Type_index_constraint) =>
            updateConstraint(
                formatterUpdateConstraint(constraint),
                constraint.id,
                requestConfig,
            ),
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateConstraint");
            }
            const formattedConstraint = formatterIndexConstraint(
                data?.data?.data,
            );
            sendEvent(
                "updateObservation",
                formatterSendEventIndexConstraint(formattedConstraint),
            );
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("ErrorGeneric"),
            });
            return err;
        },
    });
};

export const mutationDeleteConstraint = (callback: () => void) => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Form.Error");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Observations",
    );
    const { addWarning, addSuccess } = useToast();

    return useMutation({
        mutationFn: (constraintId: number) => {
            return deleteConstraint(constraintId, requestConfig);
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationDeleteConstraint");
            }
            callback();
            addSuccess({
                description: fmt("SuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("ErrorGeneric"),
            });
            return err;
        },
    });
};
