import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { FavouriteProjectPath, Url } from "src/api/paths";

import { Type_com_post_favouriteProject } from "./types";

export const addProjectToFavourites = (
    data: Type_com_post_favouriteProject,
): PostReturnType => {
    return post(
        data,
        `${Url.COMMONS}${FavouriteProjectPath.FAVOURITE_PROJECTS}`,
    );
};

export const indexFavouriteProjects = (): GetReturnType => {
    return get(`${Url.COMMONS}${FavouriteProjectPath.FAVOURITE_PROJECTS}`);
};

export const removeProjectFromFavourites = (id: number): RemoveReturnType => {
    return remove(
        `${Url.COMMONS}${FavouriteProjectPath.FAVOURITE_PROJECTS}/${id}`,
    );
};
