import * as React from "react";

import { TaskNotes } from "src/forms/taskNotes/TaskNotes";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

//////////////////////////////////////////////

type Type_Props_TaskNotesContextualDrawer = {
    taskId?: number;
};

export const TaskNotesContextualDrawer = ({
    taskId,
}: Type_Props_TaskNotesContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout
            data-testid={`TaskNotes-ContextualDrawer`}
        >
            <TaskNotes taskId={taskId} />
        </Styled_PaperContextualLayout>
    );
};
