import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { PeopleTaskKeys } from "src/api/tms-scheduling/keys";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterFormPeopleTask,
    formatterIndexPeopleTasks,
} from "./formatter";
import {
    createPeopleTask,
    indexPeopleTask,
    removePeopleTask,
} from "./services";
import { Type_post_peopleTask } from "./types";

export const useIndexPeopleTask = (taskConfig: Type_RequestConfig) => {
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { addWarning } = useToast();
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskConfig };

    const isEnabled: boolean =
        !!config.projectId &&
        !!config.subProjectId &&
        !!config.versionId &&
        !!config.taskId;

    return useQuery({
        queryKey: [PeopleTaskKeys.INDEX, config],
        queryFn: ({ signal }) => indexPeopleTask(config, signal),
        enabled: isEnabled,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterIndexPeopleTasks(data.data.data);
        },
        onError: (err) => {
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
        staleTime: MEDIUM_STALE_TIME,
    });
};

export const mutationCreatePeopleTask = (taskConfig: Type_RequestConfig) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.TaskPeople");
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { addWarning, addSuccess } = useToast();
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskConfig };
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_post_peopleTask) =>
            createPeopleTask(formatterFormPeopleTask(data), config),
        onSuccess: async (data: any) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreatePeopleTask");
            }

            await queryClient.invalidateQueries([PeopleTaskKeys.INDEX, config]);
            addSuccess({
                description: fmt("ToastSuccessCreate"),
            });
            return data;
        },
        onError: (err: any): void => {
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};

export const mutationDeletePeopleTask = (taskConfig: Type_RequestConfig) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.TaskPeople");
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { addWarning, addSuccess } = useToast();
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskConfig };

    return useMutation({
        mutationFn: (id: number) => removePeopleTask(id, requestConfig),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationDeletePeopleTask");
            }

            await queryClient.invalidateQueries([PeopleTaskKeys.INDEX, config]);
            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
            return data;
        },
        onError: (err: any) => {
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
    });
};
