import { Typography } from "@mui/material";
import React, { ReactElement } from "react";

export const Curve = (): ReactElement => {
    return (
        <>
            <Typography>
                <strong>Curve</strong>
            </Typography>
            <Typography>
                Description Description Description Description Description
                Description Description Description Description Description
                Description Description Description Description Description
                Description Description Description Description Description
                Description Description Description Description Description
                Description Description Description Description Description
                Description Description Description Description Description
                Description Description Description Description Description
                Description Description Description Description Description
                Description Description Description
            </Typography>
        </>
    );
};
