import { ProductTypesTaskForm } from "src/forms/productTypes/ProductTypesTaskForm";
import { Styled_PaperContextualLayout } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

type Type_Props_ProductTypeTaskContextualDrawer = {
    onClose: () => void;
    id: number;
    focus: boolean;
};

export const ProductTypeTaskContextualDrawer = ({
    onClose,
    id,
    focus,
}: Type_Props_ProductTypeTaskContextualDrawer) => {
    return (
        <Styled_PaperContextualLayout id="productType">
            <ProductTypesTaskForm onClose={onClose} id={id} focus={focus} />
        </Styled_PaperContextualLayout>
    );
};
