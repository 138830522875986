import { Box, Pagination, styled } from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Document, DocumentProps, Page, PageProps, pdfjs } from "react-pdf";
import { PageCallback } from "react-pdf/dist/cjs/shared/types";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url,
).toString();

const PAGINATION_HEIGHT = 50;

const Styled_Main = styled(Box)(({ theme }) => ({
    alignContent: "center",
    "& .react-pdf__Document": {
        padding: "20px",
    },
    "& .react-pdf__Page__canvas": {
        margin: "auto",
    },
    "& .react-pdf__Page": {
        backgroundColor: theme.palette.background.section + " !important",
    },
    overflow: "auto",
}));

type Type_Props_PdfViewer = {
    file?: string;
    width: number;
    height: number;
    documentProps?: Omit<DocumentProps, "file" | "onLoadSuccess">;
    pageProps?: Omit<PageProps, "width" | "height" | "pageNumber">;
};

export const PdfViewer = ({
    file,
    width,
    height,
    documentProps,
    pageProps,
}: Type_Props_PdfViewer) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    const [page, setPage] = useState<PageCallback | null>(null);
    const [propsWidthAndHeight, setPropsWidthAndHeight] = useState<{
        width?: number;
        height?: number;
    }>();

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setPageNumber(value);
    };

    const onDocumentLoadSuccess = ({
        numPages,
    }: {
        numPages: number;
    }): void => {
        setNumPages(numPages);
    };

    // calculate page dimensions
    const processPageDimensions = (page: PageCallback) => {
        if (!page || !width) return;

        // factor to set page dimensions
        const factorPage = page.originalHeight / page.originalWidth;

        // factor to set screen dimensions
        const factorScreen = height / width;

        // chose between page and screen dimensions
        const dimensionToSet =
            factorPage < factorScreen
                ? {
                      width: width,
                  }
                : {
                      height: height - PAGINATION_HEIGHT,
                  };

        setPropsWidthAndHeight(dimensionToSet);
    };

    // change page dimensions when page is loaded
    const onPageLoadSuccess = (pageCallback: PageCallback) => {
        setPage(pageCallback);
        processPageDimensions(pageCallback);
    };

    const canvasRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (file) {
            // on file change, reset pagination
            setPageNumber(1);
        }
    }, [file]);

    // resize dialogue
    useEffect(() => {
        page && processPageDimensions(page);
    }, [width, height]);

    return (
        <Styled_Main data-testid="PdfViewer">
            <Document
                {...documentProps}
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                inputRef={inputRef}
            >
                <Page
                    {...pageProps}
                    pageNumber={pageNumber}
                    {...propsWidthAndHeight}
                    canvasRef={canvasRef}
                    onLoadSuccess={onPageLoadSuccess}
                />
            </Document>
            {numPages && (
                <Pagination
                    count={numPages}
                    page={pageNumber}
                    onChange={handlePageChange}
                    shape="rounded"
                    sx={{ display: "flex", justifyContent: "center", m: 2 }}
                />
            )}
        </Styled_Main>
    );
};
