import { GridPaginationModel } from "@mui/x-data-grid";
import { GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-premium";
import React, { Dispatch, SetStateAction, useState } from "react";

import {
    DEFAULT_PAGINATION_MODEL,
    PAGE_SIZE_OPTIONS,
} from "src/api/pagination";
import { Type_index_user } from "src/api/tms-users/users/types";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { HeaderGrid } from "src/components/Components_Common/Table/HeaderGrid/HeaderGrid";
import {
    GRID_HEIGHT,
    Table,
    Type_column,
} from "src/components/Components_Common/Table/Table";
import { AddButton } from "src/components/Components_Teamoty/AddButton/AddButton";
import { UserActivityCell } from "src/components/Components_Teamoty/cells/UserActivityCell/UserActivityCell";
import { UserNameCell } from "src/components/Components_Teamoty/cells/UserNameCell/UserNameCell";
import { UserInvitationDrawer } from "src/drawers/users/UserInvitationDrawer";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import { Type_modalDeleteUser } from "./Page_Users.container";

export type Type_Props_PageUsersComponent = {
    users: Type_index_user[];
    changeStatus: (id: number, user: any) => void;
    removeUser: (id: number) => void;
    modalDeleteUser: Type_modalDeleteUser;
    setModalDeleteUser: Dispatch<SetStateAction<Type_modalDeleteUser>>;
    isFetching: boolean;
    pagination: GridPaginationModel;
    setPagination: Dispatch<SetStateAction<GridPaginationModel>>;
    rowCount: number;
};

export const Page_UsersComponent = ({
    isFetching,
    users,
    changeStatus,
    removeUser,
    modalDeleteUser,
    setModalDeleteUser,
    pagination,
    setPagination,
    rowCount,
}: Type_Props_PageUsersComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Teamoty.Users");
    const { formatMessageWithPartialKey: fmtTableColumn } =
        useCoreIntl("Table.Column");
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    const [openUserDrawer, setOpenUserDrawer] = useState(false);

    const toggleUserDrawer = () => {
        setOpenUserDrawer(!openUserDrawer);
    };
    const pageName = "users";

    const gridColumns: Type_column[] = [
        {
            field: "name",
            headerName: fmtTableColumn("Name"),
            renderCell: (props: GridRenderCellParams<any, any, any>) => {
                return (
                    <UserNameCell
                        id={props.row.id}
                        enabled={props.row.enabled}
                        email={props.row.email}
                        name={props.row.name}
                    />
                );
            },
        },
        {
            field: "company",
            headerName: fmtTableColumn("Company"),
        },
        {
            field: "role",
            headerName: fmtTableColumn("Role"),
        },
        {
            field: "activity",
            headerName: fmtTableColumn("Activity"),
            sortable: false,
            renderCell: (props: GridRenderCellParams<any, any, any>) => {
                return <UserActivityCell props={props} />;
            },
        },
    ];

    const callbackStatus = (row: GridRowModel) => {
        changeStatus(row.id, row as Type_index_user);
    };

    const callbackDelete = (row: GridRowModel) => {
        setModalDeleteUser({
            isOpen: true,
            props: row as Type_index_user,
        });
    };

    return (
        <>
            <HeaderGrid>
                <AddButton
                    data-testid="PageUsers-AddOne-btn"
                    onClick={toggleUserDrawer}
                    page={pageName}
                    label={fmtActions("Invite")}
                />
            </HeaderGrid>
            <Table
                rows={users}
                columns={gridColumns}
                noDataConfig={{
                    isFetching: isFetching,
                    dataName: fmt("DataName"),
                    action: toggleUserDrawer,
                }}
                menuDropDown={{
                    callbackDelete,
                    callbackStatus,
                }}
                page={pageName}
                height={GRID_HEIGHT}
                pagination
                {...users}
                loading={isFetching}
                paginationMode={"server"}
                paginationModel={pagination}
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                initialState={{
                    pagination: DEFAULT_PAGINATION_MODEL,
                }}
                onPaginationModelChange={setPagination}
                rowCount={rowCount}
                hideFooter={false}
            />
            <UserInvitationDrawer
                page={pageName}
                open={openUserDrawer}
                onClose={toggleUserDrawer}
            />
            <ModalDelete
                open={modalDeleteUser.isOpen}
                onClose={() => setModalDeleteUser({ isOpen: false })}
                item={fmt("DataName")}
                validationString={
                    modalDeleteUser?.props?.name !== ""
                        ? (modalDeleteUser?.props?.name as string)
                        : (modalDeleteUser?.props?.email as string)
                }
                actions={{
                    onSubmit: {
                        onClick: () => {
                            setModalDeleteUser({
                                isOpen: false,
                            });
                            removeUser(modalDeleteUser?.props?.id as number);
                        },
                    },
                }}
            />
        </>
    );
};
