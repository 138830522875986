import { useQuery } from "react-query";

import { ToolsKeys } from "src/api/tms-print/keys";
import {
    formatterPaperFormatToOptionItem,
    formatterTimelineToOptionItem,
} from "src/api/tms-print/tools/formatters";
import {
    selectListPapers,
    selectListTimelines,
} from "src/api/tms-print/tools/services";
import { LONG_STALE_TIME } from "src/configurations/app";

export const useSelectListTimelines = () => {
    return useQuery({
        queryKey: [ToolsKeys.SELECT_LIST_TIMELINES],
        queryFn: selectListTimelines,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListTimelines");
            }
            return formatterTimelineToOptionItem(data.data.data);
        },
        onError: (err) => {
            return err;
        },
        staleTime: LONG_STALE_TIME,
    });
};

export const useSelectListPapers = () => {
    return useQuery({
        queryKey: [ToolsKeys.SELECT_LIST_PAPERS],
        queryFn: selectListPapers,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useSelectListPapers");
            }
            return formatterPaperFormatToOptionItem(data.data.data);
        },
        onError: (err) => {
            return err;
        },
        staleTime: LONG_STALE_TIME,
    });
};
