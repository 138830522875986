import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "formik";

export const Styled_Stack_ChildrenChangePassword = styled(Stack)(() => ({
    maxWidth: "400px",
    width: "100%",
    justifyContent: "center",
}));

export const Styled_Form_ChangePassword = styled(Form)(({ theme }: any) => ({
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    gap: theme.spacing(2),
}));

export const Styled_Stack_ChildrenPasswordChanged = styled(Stack)(
    ({ theme }: any) => ({
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        gap: theme.spacing(6),
    }),
);

export const Styled_Stack_BackToLogin = styled(Stack)(({ theme }: any) => ({
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 4),
    cursor: "pointer",
}));
