import Konva from "konva";
import React, { useEffect, useRef } from "react";
import { Image as K_Image } from "react-konva";
import { useImage } from "react-konva-utils";

type Type_Props_Image = {
    src: string;
    x?: number;
    y?: number;
    grayscale?: boolean;
};

export const Image = ({
    src,
    x = 0,
    y = 0,
    grayscale = false,
}: Type_Props_Image) => {
    const nodeImage = useRef(null);
    const [image] = useImage(src);

    // when image is loaded we need to cache the shape
    useEffect(() => {
        if (image && nodeImage.current) {
            // you many need to reapply cache on some props changes like shadow, stroke, etc.
            // @ts-expect-error cache exist
            nodeImage.current.cache();
        }
    }, [image]);

    return (
        <>
            {image && (
                <K_Image
                    x={x}
                    y={y}
                    image={image}
                    ref={nodeImage}
                    filters={grayscale ? [Konva.Filters.Grayscale] : []}
                />
            )}
        </>
    );
};
