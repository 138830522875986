import { Grid } from "@mui/material";
import { Key } from "react";

import { Enum_typeView } from "src/api/tms-projects/workspacesAndViews/views/types";
import { DashboardCard } from "src/components/Components_Teamoty/Dashboard/DashboardCard";
import { HeaderDashboard } from "src/components/Components_Teamoty/Dashboard/HeaderDashboard";
import { useWorkspaceViewContext } from "src/contexts/workspaces";

export const ViewDashboard = () => {
    const { currentView } = useWorkspaceViewContext();

    if (!currentView || currentView.data.type !== Enum_typeView.dashboard) {
        return null;
    }

    return (
        <Grid container spacing={4} padding={4}>
            <HeaderDashboard />
            {currentView.data.settings.widgets.map(
                (widget: any, index: Key | null | undefined) => {
                    return <DashboardCard widget={widget} key={index} />;
                },
            )}
        </Grid>
    );
};
