import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import * as React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { COLORS } from "src/theme/stylesheet";

export type Type_CheckboxGroupOptionItem = {
    name: string;
    id: number | string;
};

export type Type_Props_CheckboxGroup = {
    name: string;
    options: Type_CheckboxGroupOptionItem[];
    label?: string;
};

export const CheckboxGroup = ({
    name,
    label,
    options,
}: Type_Props_CheckboxGroup) => {
    const { control, formState } = useFormContext();

    const checkboxIds = useWatch({ control, name }) || [];

    const handleChange = (onChange: any, value: number | string) => {
        const newArray = [...checkboxIds];
        const item = value;

        //Ensure array isnt empty
        if (newArray.length > 0) {
            //Attempt to find an item in array with matching id
            const index = newArray.findIndex((x) => x === item);

            // If theres no match add item to the array
            if (index === -1) {
                newArray.push(item);
            } else {
                //If there is a match and the value is empty, remove the item from the array
                newArray.splice(index, 1);
            }
        } else {
            //If the array is empty, add the item to the array
            newArray.push(item);
        }

        //Overwrite existing array with newArray}
        onChange(newArray);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, value, onChange, ...inputProps } }) => (
                <Box width={"100%"} data-testid={`CheckboxGroup-${name}`}>
                    {label && (
                        <Typography
                            variant="body2"
                            color="secondary"
                            marginBottom={2}
                            data-testid="CheckboxGroup-Title"
                        >
                            {label}
                        </Typography>
                    )}
                    {formState.errors[name] && (
                        <TMC_FormHelperText
                            helperText={
                                formState?.errors[name]?.message as string
                            }
                            inputName={name}
                        />
                    )}
                    <FormGroup>
                        {options.map((option) => (
                            <FormControlLabel
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        color: formState?.errors[name]
                                            ? COLORS.warning
                                            : "",
                                    },
                                }}
                                control={
                                    <Checkbox
                                        checked={value?.some(
                                            (checked: number) => {
                                                return checked === option.id;
                                            },
                                        )}
                                        {...inputProps}
                                        inputRef={ref}
                                        onChange={() =>
                                            handleChange(onChange, option.id)
                                        }
                                        data-testid={`check-${name}-${option.id}`}
                                    />
                                }
                                label={option.name}
                                key={option.id}
                                data-testid={`CheckboxGroup-label-${name}-${option.id}`}
                            />
                        ))}
                    </FormGroup>
                </Box>
            )}
        />
    );
};
