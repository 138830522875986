import {
    Type_get_me,
    Type_gtw_get_me,
    Type_gtw_post_login,
    Type_gtw_post_register,
    Type_post_login,
    Type_post_register,
} from "src/api/tms-gateway/auth/types";
import { USER_STATUS } from "src/api/tms-users/users/types";

export const formatterCurrentUser = (
    user: Type_gtw_get_me,
): null | Type_get_me => {
    if (!user) return null;
    const { company_id, role, ...rest } = user;
    const permissions =
        role?.permissions?.map((p: { id: number; name: string }) => p.name) ||
        [];
    return {
        ...rest,
        companyId: company_id as number,
        role,
        permissions,
    };
};

export const formatterLogin = (
    credentials: Type_post_login,
): Type_gtw_post_login => {
    return {
        email: credentials.email,
        password: credentials.password,
    };
};

export const formatterRegister = (
    credentials: Type_post_register,
): Type_gtw_post_register => {
    return {
        email: credentials.email,
        password: credentials.password,
        status: USER_STATUS.VALIDATED,
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        phone: credentials.phone,
        metadata: credentials.metadata,
        company_id: credentials.companyId && credentials.companyId,
        role_id: credentials.roleId,
    };
};
