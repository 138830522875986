import * as React from "react";
import { useCallback } from "react";
import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { SubProjectsPath, Url } from "src/api/paths";
import { getImageQuery } from "src/api/storage";
import { SubProjectKeys } from "src/api/tms-projects/keys";
import {
    formatterCreateSubProject,
    formatterIndexSubProject,
    formatterIndexSubProjects,
    formatterSelectListSubProjects,
    formatterShowSubProject,
    formatterShowSubProjectWithCurrentWorkspace,
    formatterUpdateDefaultSettings,
    formatterUpdatePdfSettings,
    formatterUpdateSubProject,
} from "src/api/tms-projects/subProjects/formatters";
import {
    createSubProject,
    currentWorkspaceSubProject,
    deleteSubProject,
    indexSubProjects,
    selectListSubProjects,
    showSubProject,
    statusSubProject,
    updateSubProject,
} from "src/api/tms-projects/subProjects/services";
import {
    Type_post_subProject,
    Type_put_default_subProject,
    Type_put_subProject,
    Type_selectList_subProject,
} from "src/api/tms-projects/subProjects/types";
import { Type_PdfImagePosition } from "src/api/types";
import { SMALL_STALE_TIME } from "src/configurations/app";
import { useManageErrors } from "src/configurations/errorsLabels";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { setLocalStorageItem } from "src/utils/localStorageServices";

export const useIndexSubProjects = () => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [SubProjectKeys.INDEX, requestConfig],
        queryFn: () => indexSubProjects(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useIndexSubProjects");
            }

            return formatterIndexSubProjects(data.data.data);
        },
        enabled: !!requestConfig?.projectId,
        onError: (err) => {
            return err;
        },
    });
};

export const useSelectListSubProjects = () => {
    const { requestConfig } = useProject();

    const config = {
        projectId: requestConfig.projectId,
    };

    return useQuery({
        queryKey: [SubProjectKeys.SELECT_LIST, config],
        queryFn: () => selectListSubProjects(config),
        refetchOnWindowFocus: false,
        select: useCallback((data: any): Type_selectList_subProject[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useSelectListSubProjects");
            }
            return formatterSelectListSubProjects(data.data.data);
        }, []),
        enabled: !!requestConfig.projectId,
        staleTime: SMALL_STALE_TIME,
        onError: (err) => {
            return err;
        },
    });
};

export const useShowSubProject = (
    projectId: number | undefined,
    subProjectId: number,
) => {
    return useQuery({
        queryKey: [SubProjectKeys.SHOW, projectId, subProjectId],
        queryFn: () =>
            showSubProject(subProjectId, { projectId, subProjectId }),
        enabled: !!projectId && !!subProjectId,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useShowSubProject");
            }

            return formatterShowSubProject(data.data.data);
        },
    });
};

export const useSubprojectPdfPicture = (
    subProjectId: number | undefined,
    position: Type_PdfImagePosition,
    updatedAt: number | undefined,
): UseQueryResult => {
    const { requestConfig } = useProject();
    return getImageQuery(
        subProjectId as number,
        Url.PROJECTS,
        SubProjectsPath.SUB_PROJECTS,
        "picture",
        `position=${position}&t=${updatedAt}`,
        !!subProjectId,
        requestConfig,
    );
};

export const mutationCreateSubProject = () => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubProject");
    const { addSuccess } = useToast();
    const { generateToastErrsFromBack } = useManageErrors();

    return useMutation({
        mutationFn: (newSubProject: Type_post_subProject) =>
            createSubProject(
                formatterCreateSubProject(newSubProject),
                requestConfig,
            ),
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationCreateSubProject");
            }

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });

            const formattedData = formatterIndexSubProject(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        subProject: formattedData.name,
                    },
                }),
            });
        },
        onError: (err: any) => {
            generateToastErrsFromBack(err, false);
            return err;
        },
    });
};

export const mutationUpdateSubProject = (subProjectId: number) => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubProject");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (subProjectToUpdate: Type_put_subProject) =>
            updateSubProject(
                formatterUpdateSubProject(subProjectToUpdate),
                subProjectId,
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Error format data: mutationUpdateSubProject");
            }

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        subProject: data.data.data.name,
                    },
                }),
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateSubProject", err);
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateSubProjectPdfSettings = (id: number) => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.SubProject");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (subProjectToUpdate: Type_put_subProject) => {
            const data = await formatterUpdatePdfSettings(subProjectToUpdate);
            return updateSubProject(data, id, requestConfig);
        },
        onSuccess: async (data) => {
            // on utilise values pour recuperer les infos des rightPicture et leftPicture vu qu'ils ne sont pas inclus dans data response
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationUpdateSubProject");
            }
            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        subProject: data.data.data.name,
                    },
                }),
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [
                    SubProjectKeys.SHOW,
                    requestConfig.projectId,
                    requestConfig.subProjectId,
                ],
            });

            // Invalide images
            await queryClient.invalidateQueries({
                queryKey: [
                    Url.PROJECTS,
                    SubProjectsPath.SUB_PROJECTS,
                    id,
                    "picture",
                    "position=left",
                ],
            });
            await queryClient.invalidateQueries({
                queryKey: [
                    Url.PROJECTS,
                    SubProjectsPath.SUB_PROJECTS,
                    id,
                    "picture",
                    "position=right",
                ],
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateDefaultSettings = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.DefaultSettings",
    );

    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addSuccess } = useToast();
    return useMutation({
        mutationFn: (defaultSettingsToUpdate: Type_put_default_subProject) =>
            updateSubProject(
                formatterUpdateDefaultSettings(defaultSettingsToUpdate),
                defaultSettingsToUpdate.id,
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error(
                    "Error format data: mutationUpdateDefaultSettings",
                );
            }

            addSuccess({
                description: fmt("Toast.SuccessUpdate"),
            });

            await queryClient.invalidateQueries({
                queryKey: [
                    SubProjectKeys.SHOW,
                    requestConfig.projectId,
                    requestConfig.subProjectId,
                ],
            });
        },
    });
};

export const mutationStatusSubProject = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (newStatus: any) => {
            return statusSubProject(
                {
                    enabled: newStatus.enabled,
                },
                newStatus.id as number,
                requestConfig,
            );
        },
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: mutationStatusSubProject");
            }

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useDeleteSubProject = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteSubProject(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Error format data: mutationDeleteSubProject");
            }

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.INDEX, requestConfig],
            });

            await queryClient.invalidateQueries({
                queryKey: [SubProjectKeys.SELECT_LIST, requestConfig],
            });
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};

export const useCurrentWorkspaceSubProject = (
    projectId: number,
    enabled: boolean,
    subProjectId?: number,
) => {
    const requestConfig: Type_RequestConfig = { projectId: projectId };
    if (subProjectId) {
        requestConfig.subProjectId = subProjectId;
    }
    return useQuery({
        queryKey: [SubProjectKeys.CURRENT_WORKSPACE, requestConfig],
        queryFn: () => currentWorkspaceSubProject(requestConfig),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Error format data: useCurrentWorkspaceSubProject",
                );
            }

            const subProjectDataId = data.data.data.id;
            if (subProjectDataId) {
                const localStorageKey: string = `current-subProject-${projectId}`;
                setLocalStorageItem(localStorageKey, subProjectDataId);
            }

            return formatterShowSubProjectWithCurrentWorkspace(data.data.data);
        },
        enabled: !!projectId && enabled,
    });
};
