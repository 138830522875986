import { useState } from "react";

import {
    mutationStatusDayOff,
    useDeleteDayOff,
    useIndexDaysOff,
} from "src/api/tms-projects/daysOffs";
import {
    Type_index_dayOff,
    Type_prj_putStatus_dayOff,
} from "src/api/tms-projects/daysOffs/types";
import { Page_DaysOffComponent } from "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_DaysOff/Page_DaysOff.component";

export type Type_modalDeleteDayOff = {
    isOpen: boolean;
    props?: Type_index_dayOff;
};

export const Page_DaysOffContainer = () => {
    // get context for project and subproject
    const { isFetching, data } = useIndexDaysOff() || {};

    // STATUS
    const { mutate: mutationChangeStatus } = mutationStatusDayOff() || {};

    const changeStatus = async (
        id: number,
        dayOff: Type_prj_putStatus_dayOff,
    ) => {
        mutationChangeStatus({
            id,
            enabled: !dayOff.enabled,
        });
    };

    // DELETE
    const [modalDeleteDayOff, setModalDeleteDayOff] =
        useState<Type_modalDeleteDayOff>({
            isOpen: false,
        });

    const { mutateAsync: mutateDeleteDayOff } = useDeleteDayOff() || {};
    const removeDayOff = async (id: number) => {
        await mutateDeleteDayOff(id);
    };

    return (
        <Page_DaysOffComponent
            isFetching={isFetching}
            daysOff={data ?? []}
            changeStatus={changeStatus}
            deleteDayOff={removeDayOff}
            modalDeleteDayOff={modalDeleteDayOff}
            setModalDeleteDayOff={setModalDeleteDayOff}
        />
    );
};
