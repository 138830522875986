import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import React from "react";

import { Type_index_area } from "src/api/tms-projects/areas/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { AreaColor } from "src/components/Components_Teamoty/trees/AreasTreeView";

const StyleTreeItem = styled(TreeItem)(() => ({
    "& .MuiTreeItem-label .actions": {
        opacity: 0,
    },
    "& .MuiTreeItem-label:hover .actions": {
        opacity: 1,
    },
}));

type Type_Props_AreasTreeSidebar = {
    areas: Type_index_area[];
    handleAreaEdit: (areaId: number) => void;
    handleAreaReset: (areaId: number) => void;
    drawingId: number;
};

type Type_Props_renderAreaItem = {
    area: Type_index_area;
    handleAreaEdit: (areaId: number) => void;
    handleAreaReset: (areaId: number) => void;
    drawingId: number;
};

const renderAreaItem = ({
    area,
    handleAreaEdit,
    handleAreaReset,
    drawingId,
}: Type_Props_renderAreaItem) => {
    const onAreaEdit = (event: React.MouseEvent, areaId: number) => {
        event.stopPropagation();
        handleAreaEdit(areaId);
    };

    const onAreaReset = (event: React.MouseEvent, areaId: number) => {
        event.stopPropagation();
        handleAreaReset(areaId);
    };

    return (
        <StyleTreeItem
            key={`AreasTreeSidebar-${area.id}`}
            itemId={`AreasTreeSidebar-${area.id}`}
            data-testid={`AreasTreeSidebar-${area.name}`}
            label={
                <Stack
                    direction={"row"}
                    alignItems="center"
                    spacing={2}
                    width={"100%"}
                >
                    <AreaColor backgroundColor={area.color} />
                    <Typography>{area.name}</Typography>
                    {area.drawing !== null && area.drawing.id === drawingId && (
                        <Stack
                            className="actions"
                            direction={"row-reverse"}
                            alignItems="center"
                            spacing={1}
                            flexGrow={1}
                        >
                            <IconButton
                                size={"small"}
                                aria-label="reset"
                                onClick={(event) => onAreaReset(event, area.id)}
                                data-testid={`AreasTreeSidebar-${area.name}-btn-reset`}
                            >
                                <Icon
                                    variant="solid"
                                    icon="arrows-rotate"
                                    fontSize="small"
                                />
                            </IconButton>
                            <IconButton
                                size={"small"}
                                aria-label="edit"
                                onClick={(event) => onAreaEdit(event, area.id)}
                                data-testid={`AreasTreeSidebar-${area.name}-btn-edit`}
                            >
                                <Icon
                                    variant="solid"
                                    icon="pen"
                                    fontSize="small"
                                />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            }
        >
            {area.children?.map((item: Type_index_area) =>
                renderAreaItem({
                    area: item,
                    handleAreaEdit,
                    handleAreaReset,
                    drawingId,
                }),
            )}
        </StyleTreeItem>
    );
};

export const AreasTreeSidebar = ({
    areas,
    handleAreaEdit,
    handleAreaReset,
    drawingId,
}: Type_Props_AreasTreeSidebar) => {
    return (
        <SimpleTreeView
            aria-label="Aria tree sidebar"
            slots={{
                expandIcon: MyExpandIcon,
                collapseIcon: MyCollapseIon,
            }}
            sx={{
                maxHeight: "70vh",
                flexGrow: 1,
                overflowY: "auto",
            }}
        >
            {areas.map((area) =>
                renderAreaItem({
                    area,
                    handleAreaEdit,
                    handleAreaReset,
                    drawingId,
                }),
            )}
        </SimpleTreeView>
    );
};

const MyExpandIcon = () => {
    return <Icon variant="solid" icon="chevron-right" />;
};

const MyCollapseIon = () => {
    return <Icon variant="solid" icon="chevron-down" />;
};
