import React, { ComponentProps } from "react";

import { useSelectListProjectAreaTypes } from "src/api/tms-projects/areaTypes";
import { Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";

type Type_Props_AutocompleteProjectAreaTypes = Omit<
    ComponentProps<typeof Autocomplete>,
    "options" | "isFetching"
>;

export const AutocompleteProjectAreaTypes = ({
    name,
    label,
    ...props
}: Type_Props_AutocompleteProjectAreaTypes) => {
    const { isFetching, data } = useSelectListProjectAreaTypes() || {};

    return (
        <Autocomplete
            name={name}
            label={label}
            isFetching={isFetching}
            options={data || []}
            {...props}
        />
    );
};
