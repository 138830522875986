import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, InputAdornment, Stack, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import {
    mutationCreateTaskLink,
    mutationDeleteTaskLink,
    mutationUpdateTaskLink,
    useShowTaskLink,
} from "src/api/tms-scheduling/taskLinks";
import {
    LINK_DIRECTION,
    Type_post_taskLink,
    Type_put_taskLink,
} from "src/api/tms-scheduling/taskLinks/types";
import { LoadingBox, TMC_Button } from "src/components";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { ModalDelete } from "src/components/Components_Common/ModalDelete/ModalDelete";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { AutocompleteAreas } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteAreas";
import { AutocompleteTasks } from "src/components/Components_Teamoty/autocompletesRhf/AutocompleteTasks";
import { SelectLinkType } from "src/components/Components_Teamoty/forms/select/SelectLinkType";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_LinkForm = {
    handleClose: () => void;
    linkIdToUpdate?: number;
    direction?: LINK_DIRECTION;
    defaultTaskForAutocomplete?: { id: number; name: string };
};

type Type_modalDeleteLink = {
    isOpen: boolean;
};

const linkValidationSchema = Yup.object().shape({
    linkType: Yup.number().min(1).max(4).required(FORM_ERR_FMT.REQUIRED),
    dayMode: Yup.number().min(1).max(2),
    quantity: Yup.number().min(0),
    waitingDay: Yup.number().min(0),
    taskFrom: Yup.mixed().required(FORM_ERR_FMT.REQUIRED),
    taskTo: Yup.mixed().required(FORM_ERR_FMT.REQUIRED),
    areaFrom: Yup.mixed().nullable(),
    areaTo: Yup.mixed().nullable(),
});

export const LinkForm = ({
    handleClose,
    linkIdToUpdate,
    direction,
    defaultTaskForAutocomplete,
}: Type_Props_LinkForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Planning.Modal.Link.Form",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    const { data, isFetching } = useShowTaskLink(linkIdToUpdate);
    const { mutateAsync: mutateCreate, isLoading: isLoadingCreate } =
        mutationCreateTaskLink();
    const { mutateAsync: mutateUpdate, isLoading: isLoadingUpdate } =
        mutationUpdateTaskLink();
    const { mutateAsync: mutateDelete, isLoading: isLoadingDelete } =
        mutationDeleteTaskLink();

    const isLoading = useMemo(() => {
        return isLoadingCreate || isLoadingUpdate;
    }, [isLoadingCreate, isLoadingUpdate]);

    const theme = useTheme();

    const initialValues = {
        linkType: 1,
        dayMode: 1,
        quantity: 0,
        waitingDay: 0,
        areaFrom: undefined,
        areaTo: undefined,
        taskFrom:
            direction === LINK_DIRECTION.TO
                ? defaultTaskForAutocomplete
                : undefined,
        taskTo:
            direction === LINK_DIRECTION.FROM
                ? defaultTaskForAutocomplete
                : undefined,
        taskAreaFromId: null,
        taskAreaToId: null,
    };

    const form = useForm<Type_put_taskLink | Type_post_taskLink>({
        ...useFormDefaultConfig,
        defaultValues: linkIdToUpdate ? data : initialValues,
        values: data as Type_put_taskLink | Type_post_taskLink,
        mode: "onSubmit",
        resolver: yupResolver<any>(linkValidationSchema),
    });

    const handleSubmit = (
        values: Type_put_taskLink | Type_post_taskLink,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();

        try {
            if (linkIdToUpdate) {
                if (
                    data?.taskTo.id !== values.taskTo?.id ||
                    data?.areaTo?.id !== values.areaTo?.id
                ) {
                    values.taskAreaToId = null;
                }
                if (
                    data?.taskFrom.id !== values.taskFrom?.id ||
                    data?.areaFrom?.id !== values.areaFrom?.id
                ) {
                    values.taskAreaFromId = null;
                }
                mutateUpdate(values as Type_put_taskLink);
            } else {
                mutateCreate(values as Type_post_taskLink);
            }
            handleClose();
        } catch (e: any) {
            console.error(e);
        }
    };

    const [modalDeleteLink, setModalDeleteLink] =
        useState<Type_modalDeleteLink>({
            isOpen: false,
        });

    const handleDelete = () => {
        if (linkIdToUpdate) {
            mutateDelete(linkIdToUpdate);
            setModalDeleteLink({
                isOpen: false,
            });
            handleClose();
        }
    };

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    {isFetching ? (
                        <Box sx={{ height: "520px" }}>
                            <FullSpinner />
                        </Box>
                    ) : (
                        <>
                            <Grid container spacing={theme.spacing(2)}>
                                <Grid xs={12}>
                                    <AutocompleteTasks
                                        data-testid="From-TaskLink-autocomplete-task"
                                        name="taskFrom"
                                        label={fmt("OriginTask")}
                                        getOptionDisabled={(option) => {
                                            const hideId = data?.taskTo?.id;
                                            return option.id === hideId;
                                        }}
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                        disabled={
                                            !linkIdToUpdate &&
                                            direction === LINK_DIRECTION.TO
                                        }
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <AutocompleteTasks
                                        data-testid="To-TaskLink-autocomplete-task"
                                        name="taskTo"
                                        label={fmt("TargetTask")}
                                        getOptionDisabled={(option) => {
                                            const hideId = data?.taskFrom?.id;
                                            return option.id === hideId;
                                        }}
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                        disabled={
                                            !linkIdToUpdate &&
                                            direction === LINK_DIRECTION.FROM
                                        }
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <SelectLinkType
                                        name="linkType"
                                        label={fmt("LinkType")}
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <TextField
                                        name="quantity"
                                        label={fmt("TaskConstraint")}
                                        variant="outlined"
                                        type="number"
                                        data-testid="Form-TaskLink-quantity"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {fmt("Unit")}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <TextField
                                        name="waitingDay"
                                        label={fmt("WaitingDays")}
                                        variant={"outlined"}
                                        type="number"
                                        data-testid="Form-TaskLink-waitingDay"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {fmt("Day")}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} py={theme.spacing(4)}>
                                    <Divider textAlign="left">
                                        {fmt("Optional")}
                                    </Divider>
                                </Grid>
                                <Grid xs={12}>
                                    <AutocompleteAreas
                                        label={fmt("OriginArea")}
                                        name="areaFrom"
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <AutocompleteAreas
                                        label={fmt("TargetArea")}
                                        name="areaTo"
                                        renderInputProps={{
                                            variant: "outlined",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Stack
                                direction={"row-reverse"}
                                justifyContent={"space-between"}
                                pt={theme.spacing(5)}
                            >
                                <TMC_Button
                                    type="submit"
                                    variant="contained"
                                    data-testid="From-TaskLink-submit-btn"
                                    disabled={isLoading}
                                    startIcon={
                                        isLoading ? (
                                            <LoadingBox />
                                        ) : (
                                            <Icon
                                                variant="solid"
                                                icon="check"
                                            />
                                        )
                                    }
                                >
                                    {linkIdToUpdate
                                        ? fmtActions("Update")
                                        : fmtActions("Create")}
                                </TMC_Button>
                                {linkIdToUpdate && (
                                    <TMC_Button
                                        type="button"
                                        variant="outlined"
                                        color={"error"}
                                        data-testid="From-TaskLink-delete-btn"
                                        startIcon={
                                            <Icon
                                                icon="trash"
                                                variant="light"
                                                fontSize={"small"}
                                            />
                                        }
                                        onClick={() =>
                                            setModalDeleteLink({
                                                isOpen: true,
                                            })
                                        }
                                    >
                                        {fmtActions("Delete")}
                                    </TMC_Button>
                                )}
                            </Stack>
                        </>
                    )}
                </form>
            </FormProvider>
            <ModalDelete
                open={modalDeleteLink.isOpen}
                onClose={() => setModalDeleteLink({ isOpen: false })}
                validationString={fmtActions("Confirm")}
                item={fmt("Link")}
                actions={{
                    onSubmit: {
                        isLoading: isLoadingDelete,
                        onClick: () => handleDelete(),
                    },
                }}
            />
        </>
    );
};
