import { StackProps } from "@mui/material";
import { ReactNode } from "react";

import { Styled_StackContentDrawer } from "src/components/Components_Common/Drawer/Drawer.style";

type Type_Props_Drawer_Content = StackProps & {
    children: ReactNode;
};

export const Content = ({ children, ...props }: Type_Props_Drawer_Content) => {
    return (
        <Styled_StackContentDrawer {...props} flexGrow={1}>
            {children}
        </Styled_StackContentDrawer>
    );
};
