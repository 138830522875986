import { ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

export const Styled_ToggleButtonGroup = styled(ToggleButtonGroup)(
    ({ theme }) => ({
        "&.MuiToggleButtonGroup-root": {
            gap: theme.spacing(2),
            textTransform: "none",
            marginTop: theme.spacing(2),
            "&.MuiButtonBase-root, .MuiToggleButton-root": {
                height: "80px",
                width: "98px",
                border: `1px solid ${COLORS.moon200}`,
                borderRadius: theme.shape.borderRadius,
                background: COLORS.white,
                color: COLORS.dark,
                padding: 2,
                overflow: "hidden",
                boxShadow: `0px 0px 1px 1px ${COLORS.moon200}`,
                "& p": {
                    fontSize: "16px",
                    textAlign: "center",
                    marginTop: 0,
                },
                "& .MuiIcon-root": {
                    width: "30px",
                    height: "30px",
                },
                "&.Mui-selected": {
                    "& .MuiTypography-body1": {
                        color: COLORS.teamotyBlue500,
                    },
                    color: COLORS.teamotyBlue500,
                    border: `1px solid ${COLORS.teamotyBlue500}`,
                    boxShadow: `0px 0px 1px 1px ${COLORS.teamotyBlue500}`,
                },
            },
        },
    }),
);
