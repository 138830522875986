import { useQuery } from "react-query";

import { TfeModelKeys } from "src/api/tms-commons/keys";
import { formatterPieChart } from "src/api/tms-tfe/formatters";
import { useProject } from "src/contexts/project";

import {
    closeTFE,
    getPingTFE,
    getPunchlists,
    getRoadblocks,
    getTasksAreasReport,
    openTFE,
} from "./services";

export const useOpenTFE = () => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [TfeModelKeys.OPEN, requestConfig],
        queryFn: () => openTFE(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.token) {
                throw new Error("Wrong format data: openTFE");
            }
            return data.data.token;
        },
        onError: (err) => {
            console.log("err : ", err);
            return err;
        },
    });
};

export const usePingTFE = () => {
    return useQuery({
        queryKey: [TfeModelKeys.PING],
        queryFn: getPingTFE,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: getPingTFE");
            }
            return data.data.data;
        },
        onError: (err) => {
            console.log("err : ", err);
            return err;
        },
    });
};

export const useCloseTFE = (token: string, enabled: boolean = true) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [TfeModelKeys.CLOSE, { ...requestConfig, token }],
        queryFn: () => closeTFE({ ...requestConfig, token }),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: closeTFE");
            }
        },
        onError: (err) => {
            console.log("err : ", err);
            return err;
        },
        enabled: !!token && !!requestConfig.projectId && enabled,
    });
};

export const useGetTasksAreasReport = (token: string) => {
    const { requestConfig } = useProject();

    const requestConfigWithToken = { ...requestConfig, token };

    return useQuery({
        queryKey: [TfeModelKeys.REPORT, requestConfigWithToken],
        queryFn: () => getTasksAreasReport(requestConfigWithToken),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: getTasksAreasReport");
            }
            return formatterPieChart(data.data.data);
        },
        enabled:
            !!token &&
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId,
    });
};

export const useGetPunchlists = (token: string) => {
    const { requestConfig } = useProject();

    const requestConfigWithToken = { ...requestConfig, token };

    return useQuery({
        queryKey: [TfeModelKeys.PUNCHLIST, requestConfigWithToken],
        queryFn: () => getPunchlists(requestConfigWithToken),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: getPunchlists");
            }
            return formatterPieChart(data.data.data);
        },
        enabled:
            !!token &&
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId,
    });
};

export const useGetRoadblocks = (token: string) => {
    const { requestConfig } = useProject();

    const requestConfigWithToken = { ...requestConfig, token };

    return useQuery({
        queryKey: [TfeModelKeys.ROADBLOCK, requestConfigWithToken],
        queryFn: () => getRoadblocks(requestConfigWithToken),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: getRoadblocks");
            }
            return formatterPieChart(data.data.data);
        },
        enabled:
            !!token &&
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!requestConfig.versionId,
    });
};
