import { Components } from "@mui/material";

export const MuiChipTheme: Components["MuiChip"] = {
    styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
            minWidth: 24,
            borderRadius: theme.shape.borderRadiusChip,
        }),
        label: ({ theme }: { theme: any }) => ({
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            ...theme.typography.body2,
        }),
        icon: () => ({
            marginRight: 0,
        }),
        deleteIcon: ({ theme }: { theme: any }) => ({
            marginInlineStart: theme.spacing(1),
        }),
    },
    defaultProps: {
        size: "small",
    },
};
