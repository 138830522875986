import { Box, BoxProps, FormControlLabel, styled } from "@mui/material";

type Type_Styled_BoxProps = {
    ownerState: {
        format?: "rounded" | "standard" | undefined;
    };
};

export const Styled_Box = styled(Box)<Type_Styled_BoxProps & BoxProps>(
    ({ theme }) => ({
        padding: theme.spacing(2),
    }),
);

export const Styled_FormControlLabel = styled(FormControlLabel)(
    ({ theme }) => ({
        flexDirection: "row-reverse",
        margin: 0,
        width: "100%",
        justifyContent: "space-between",
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary,
        },
    }),
);
