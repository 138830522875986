import React, { ReactNode, useState } from "react";

import { CategoryCollapseComponent } from "./CategoryCollapse.component";

type Type_CategoryCollapseContainerProps = {
    onClick: () => void;
    label: string;
    children: ReactNode;
    color: string;
    digitValue: number;
};

export const CategoryCollapseContainer = ({
    onClick,
    label,
    children,
    color,
    digitValue,
}: Type_CategoryCollapseContainerProps) => {
    const [checked, setChecked] = useState<boolean>(true);

    const handleCollapse = () => {
        setChecked((prev) => !prev);
    };

    return (
        <CategoryCollapseComponent
            checked={checked}
            handleCollapse={handleCollapse}
            label={label}
            onClick={onClick}
            color={color}
            digitValue={digitValue}
        >
            {children}
        </CategoryCollapseComponent>
    );
};
