import { Dispatch, SetStateAction } from "react";
import { RectReadOnly } from "react-use-measure";

import { Type_PlanningDrawer } from "src/components/Components_Teamoty/Planning/Drawer/PlanningDrawer.type";
import {
    heightPlanningDate,
    heightPlanningMonth,
    marginPlanningElements,
    sizePlanningScroll,
    widthPlanningButton,
    zoomXLevels,
    zoomYLevels,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import {
    Type_PlanningPositionsStorage,
    Type_State_PlanningDrawerSizes,
    Type_state_PlanningViewFilters,
} from "src/components/Components_Teamoty/Planning/Planning.type";
import { Type_State_PlanningScroll } from "src/components/Components_Teamoty/Planning/Scrolls/PlanningScroll.type";
import { Enum_typeChart } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";
import {
    initialPlanningZoomX,
    initialPlanningZoomY,
    Type_PlanningZoom,
} from "src/components/Components_Teamoty/Planning/Zooms/PlanningZoom.type";

// -----------------------------------------------------------------------------------------------------------------

export const updateBounds = (
    bounds: RectReadOnly,
    filters: Type_state_PlanningViewFilters,
    drawerSizes: Type_State_PlanningDrawerSizes,
    setBoundsPlanning: Dispatch<SetStateAction<Type_PlanningDrawer>>,
    setWorkforceCurve: Dispatch<SetStateAction<Type_PlanningDrawer>>,
    setWorkforceSubTradesTab: Dispatch<SetStateAction<Type_PlanningDrawer>>,
    setWorkforceCompaniesTab: Dispatch<SetStateAction<Type_PlanningDrawer>>,
    setZoomX: Dispatch<SetStateAction<Type_PlanningZoom>>,
    setZoomY: Dispatch<SetStateAction<Type_PlanningZoom>> | undefined,
    setScrollX: Dispatch<SetStateAction<Type_State_PlanningScroll>>,
    setScrollY: Dispatch<SetStateAction<Type_State_PlanningScroll>>,
    setAreas: Dispatch<SetStateAction<Type_PlanningDrawer>>,
    setColsData: Dispatch<SetStateAction<Type_PlanningDrawer>>,
    getLocalStorage: () => Type_PlanningPositionsStorage,
): void => {
    const heightWorkforceCurve =
        filters.workforceChart === Enum_typeChart.withWorkforceCurve
            ? drawerSizes.heightWorkforceCurve
            : 0;
    const heightWorkforceSubTradesTab =
        filters.workforceChart === Enum_typeChart.withWorkforceSubTradesTab
            ? drawerSizes.heightWorkforceSubTradesTab
            : 0;
    const heightWorkforceCompaniesTab =
        filters.workforceChart === Enum_typeChart.withWorkforceCompaniesTab
            ? drawerSizes.heightWorkforceCompaniesTab
            : 0;

    const widthAreas: number = drawerSizes.widthAreas;
    const widthColsData: number = drawerSizes.widthColsData;

    const widthZoomX = widthPlanningButton * zoomXLevels.length;

    setZoomX((prev: Type_PlanningZoom): Type_PlanningZoom => {
        prev.x = bounds.width - widthZoomX;
        prev.y = bounds.height - sizePlanningScroll;
        prev.pos = getLocalStorage().zoomX || prev.pos;
        prev.width = widthZoomX;
        return { ...prev };
    });

    const widthZoomY = setZoomY
        ? sizePlanningScroll * zoomYLevels.length + 2
        : 0;

    const zoomPosX: number =
        getLocalStorage().zoomX || initialPlanningZoomX.pos;
    const zoomPosY: number =
        getLocalStorage().zoomY || initialPlanningZoomY.pos;

    setZoomY &&
        setZoomY((prev: Type_PlanningZoom): Type_PlanningZoom => {
            prev.x = bounds.width - widthZoomX - widthZoomY;
            prev.y = bounds.height - sizePlanningScroll;
            prev.pos = getLocalStorage().zoomY || prev.pos;
            prev.width = widthZoomY;
            return { ...prev };
        });

    setAreas((prevBoundsArea: Type_PlanningDrawer): Type_PlanningDrawer => {
        const offset = prevBoundsArea.x + widthAreas + widthColsData;

        setWorkforceCurve((prev: Type_PlanningDrawer): Type_PlanningDrawer => {
            prev.x = offset;
            prev.y = bounds.height - heightWorkforceCurve - sizePlanningScroll;
            prev.width = bounds.width - prev.x - sizePlanningScroll - 1;
            prev.height = heightWorkforceCurve + marginPlanningElements;
            prev.widthLegend = offset;
            return { ...prev };
        });

        setWorkforceSubTradesTab(
            (prev: Type_PlanningDrawer): Type_PlanningDrawer => {
                prev.x = 1;
                prev.y =
                    bounds.height - heightWorkforceCurve - sizePlanningScroll;
                prev.y =
                    bounds.height -
                    heightWorkforceCurve -
                    heightWorkforceSubTradesTab -
                    sizePlanningScroll;
                prev.width = bounds.width - sizePlanningScroll - 1;
                prev.height =
                    heightWorkforceSubTradesTab + marginPlanningElements;
                prev.widthLegend = offset;
                return { ...prev };
            },
        );

        setWorkforceCompaniesTab(
            (prev: Type_PlanningDrawer): Type_PlanningDrawer => {
                prev.x = 1;
                prev.y =
                    bounds.height -
                    heightWorkforceCurve -
                    heightWorkforceSubTradesTab -
                    heightWorkforceCompaniesTab -
                    sizePlanningScroll;
                prev.width = bounds.width - sizePlanningScroll - 1;
                prev.height =
                    heightWorkforceCompaniesTab + marginPlanningElements;
                prev.widthLegend = offset;
                return { ...prev };
            },
        );

        setScrollX(
            (prev: Type_State_PlanningScroll): Type_State_PlanningScroll => {
                prev.x = offset;
                prev.y = bounds.height - sizePlanningScroll;
                prev.widthPage = bounds.width - prev.x;
                prev.width = prev.widthPage - widthZoomX - widthZoomY - 1;

                prev.page = Math.min(prev.size, prev.widthPage / zoomPosX);

                prev.pos = getLocalStorage().scrollX || prev.pos;
                prev.pos = Math.min(
                    Math.max(0, prev.pos),
                    prev.size - prev.page,
                );

                setBoundsPlanning(
                    (prevBounds: Type_PlanningDrawer): Type_PlanningDrawer => ({
                        ...prevBounds,
                        x: prev.x,
                        width: prev.widthPage,
                    }),
                );

                return { ...prev };
            },
        );
        prevBoundsArea.width = widthAreas;

        return prevBoundsArea;
    });

    setScrollY((prev: Type_State_PlanningScroll): Type_State_PlanningScroll => {
        prev.y = heightPlanningDate + heightPlanningMonth + 1;
        // heightWorkforceCurve +
        // heightWorkforceSubTradesTab +
        // heightWorkforceCompaniesTab +
        // heightPlanningTitle +
        // marginPlanningElements;
        prev.x = bounds.width - sizePlanningScroll;
        prev.widthPage =
            bounds.height -
            prev.y -
            sizePlanningScroll -
            heightWorkforceCurve -
            heightWorkforceSubTradesTab -
            heightWorkforceCompaniesTab;
        prev.width = prev.widthPage;

        prev.page = Math.min(prev.size, prev.widthPage / zoomPosY);

        prev.pos = getLocalStorage().scrollY || prev.pos;
        prev.pos = Math.min(Math.max(0, prev.pos), prev.size - prev.page);

        setBoundsPlanning(
            (prevBounds: Type_PlanningDrawer): Type_PlanningDrawer => ({
                ...prevBounds,
                y: prev.y,
                height: prev.widthPage,
            }),
        );

        setAreas((prevBounds: Type_PlanningDrawer): Type_PlanningDrawer => {
            setColsData(
                (prevColsBounds: Type_PlanningDrawer): Type_PlanningDrawer => ({
                    ...prevColsBounds,
                    x: prevBounds.x + widthAreas - 1,
                    y: prev.y,
                    width: widthColsData,
                    height: prev.widthPage,
                }),
            );

            return {
                ...prevBounds,
                y: prev.y,
                height: prev.widthPage,
            };
        });
        return { ...prev };
    });
};
