import { Grid } from "@mui/material";
import * as React from "react";
import { ReactElement } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";

import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Section } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export const advancedInitialData = {
    checking: false,
    follow: false,
};

export const advancedSchema = Yup.object().shape({
    checking: Yup.boolean(),
    follow: Yup.boolean(),
});

type Type_Section = { name: string; component: ReactElement };

type Type_Props_Advanced = Type_ReadonlyComponent;

export const Advanced = ({ readonly }: Type_Props_Advanced) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.Advanced",
    );

    const { control } = useFormContext(); // retrieve those props

    const taskType = useWatch({
        name: "type",
        control: control,
    });

    const sections: Type_Section[] = [
        {
            name: "checking",
            component: (
                <Switch
                    name="checking"
                    label={fmt("Checking")}
                    labelPlacement="end"
                    data-testid={"Form-Switch-checking"}
                    readonly={readonly}
                />
            ),
        },
        {
            name: "follow",
            component: (
                <Switch
                    name="follow"
                    label={fmt("Follow")}
                    labelPlacement="end"
                    data-testid={"Form-Switch-follow"}
                    readonly={readonly}
                />
            ),
        },
    ];

    const renderSection = ({ name, component }: Type_Section) => {
        if (shouldDisplay({ name, taskType: taskType as number })) {
            return (
                <Grid item xs={12} key={name}>
                    {component}
                </Grid>
            );
        }
        return null;
    };

    return (
        <Section>
            <Grid container spacing={2}>
                {sections.map(renderSection)}
            </Grid>
        </Section>
    );
};
