import { DialogProps } from "@mui/material";
import React, { useState } from "react";

import { Dialog } from "src/components/Components_Common/Dialog/Dialog";
import { ResourceForm } from "src/forms/resource/ResourceForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_ResourceCreateModal = {
    resourceName: string;
    createResource?: (resourceId: number) => Promise<void>;
    onClose: () => void;
    page?: string;
} & DialogProps;

export const ResourceDialog = ({
    resourceName,
    onClose,
    createResource,
    open,
    page,
}: Type_Props_ResourceCreateModal) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Resource");
    const formId = "resourceDialog";
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Dialog
            name={formId}
            open={open}
            dialogTitle={fmt("TitleCreate")}
            onClose={onClose}
            page={page}
            isLoading={isLoading}
        >
            <ResourceForm
                formId={formId}
                onClose={onClose}
                createResource={createResource}
                resourceIdToUpdate={null}
                action={"create"}
                setIsLoading={setIsLoading}
                newResourceName={resourceName}
            />
        </Dialog>
    );
};
