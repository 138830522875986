import { yupResolver } from "@hookform/resolvers/yup";
import { Divider } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { mutationCreatePunchlist } from "src/api/tms-scheduling/punchlist";
import {
    Type_index_punchlist,
    Type_post_punchlist,
} from "src/api/tms-scheduling/punchlist/types";
import { languagesList } from "src/assets/translations";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import {
    Content,
    Form,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

import { TaskAreaPunchlistFormContent } from "./TaskAreaPunchlistFormContent";
import { TaskAreaPunchlistFormHeader } from "./TaskAreaPunchlistFormHeader";

const validationSchema = Yup.lazy(() => {
    return Yup.object().shape({
        names: Yup.object().shape(
            Object.entries(languagesList).reduce((acc: any, [languageCode]) => {
                acc[languageCode] = Yup.string().nullable();
                return acc;
            }, {}),
        ),
        earliestDate: Yup.date().required(FORM_ERR_FMT.REQUIRED),
        duration: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        punchlistCauseId: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        workforce: Yup.number().required(FORM_ERR_FMT.REQUIRED),
        team: Yup.number(),
        pin: Yup.boolean(),
    });
});

type Type_Props_TaskAreaPunchlistForm = {
    data: Type_index_punchlist;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
    formId?: string;
};

export const TaskAreaPunchlistForm = ({
    data,
    setIsLoading,
    onClose,
    formId = "taskPunchlistDialog",
}: Type_Props_TaskAreaPunchlistForm) => {
    const [localData] = useState(data);
    const { mutateAsync, isLoading } = mutationCreatePunchlist();

    const form = useForm({
        ...useFormDefaultConfig,
        values: localData,
        mode: "onSubmit",
        resolver: yupResolver<any>(validationSchema),
    });

    const handleSubmit = async (
        values: Type_post_punchlist,
        e?: React.BaseSyntheticEvent,
    ): Promise<void> => {
        e?.preventDefault();
        try {
            await mutateAsync(values);
            onClose();
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    return (
        <FormProvider {...form}>
            <Form id={formId} onSubmit={form.handleSubmit(handleSubmit)}>
                <TaskAreaPunchlistFormHeader onClose={onClose} />
                <Content data-testid="TaskAreaPunchlistForm-Content">
                    <TaskAreaPunchlistFormContent />
                </Content>
            </Form>
            <Divider variant="fullWidth" orientation="horizontal" />
        </FormProvider>
    );
};
