import { Box, Stack } from "@mui/material";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import { mutationPrint } from "src/api/tms-print/print";
import { formatterOrientationToRadioItem } from "src/api/tms-print/print/formatters";
import {
    defaultPrintSettings,
    orientation,
    Type_post_print,
} from "src/api/tms-print/print/types";
import { TMC_Button } from "src/components";
import { RadioGroup } from "src/components/Components_Common/forms/reactHookFormComponents/RadioGroup/RadioGroup";
import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { PdfViewer } from "src/components/Components_Common/PdfViewer/PdfViewer";
import { SelectPaperFormat } from "src/components/Components_Teamoty/forms/select/SelectPaperFormat";
import { SelectPrintTimelineType } from "src/components/Components_Teamoty/forms/select/SelectPrintTimelineType";
import { useFormDefaultConfig } from "src/configurations/app";

export const PrintDemo = () => {
    const { mutate, data: dataMutate } = mutationPrint();
    const form = useForm<Type_post_print>({
        ...useFormDefaultConfig,
        defaultValues: {
            ...defaultPrintSettings,
            withDaysOff: true,
            withWeekend: true,
        },
    });

    const handleSubmit = (
        values: Type_post_print,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        mutate({ settings: values, shouldDownloadDirectly: false });
    };

    return (
        <>
            <Stack
                gap={4}
                direction="column"
                sx={{ width: 200, margin: "auto" }}
            >
                <FormProvider {...form}>
                    <form
                        onSubmit={form.handleSubmit(handleSubmit)}
                        id={"roles"}
                    >
                        <Stack
                            gap={2}
                            direction="column"
                            sx={{ width: 200, margin: "auto" }}
                        >
                            <SelectPaperFormat name={"paperFormat"} />
                            <RadioGroup
                                name={"orientation"}
                                options={formatterOrientationToRadioItem(
                                    Object.values(orientation),
                                )}
                            />
                            <SelectPrintTimelineType name={"timelineType"} />
                            <Switch
                                name="withWeekend"
                                label={"WithWeekend"}
                                data-testid="MetadataUser-DrawerContent-filterable"
                            />
                            <Switch
                                name="withDaysOff"
                                label={"WithDaysOff"}
                                data-testid="MetadataUser-DrawerContent-filterable"
                            />
                            <TMC_Button type="submit">Call Print</TMC_Button>
                        </Stack>
                    </form>
                </FormProvider>
            </Stack>

            <Box
                sx={{
                    minHeight: 200,
                    width: "80%",
                    border: "2px solid black",
                    marginX: "auto",
                    marginTop: 10,
                }}
            >
                <PdfViewer
                    file={dataMutate?.data}
                    width={400}
                    height={200}
                    documentProps={{
                        noData: 'No pdf file, click on "Call Print"',
                    }}
                />
            </Box>
        </>
    );
};
