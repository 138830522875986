import { useState } from "react";

import {
    mutationDeleteMetadataUser,
    mutationStatusMetadataUser,
    useIndexMetadataUsers,
} from "src/api/tms-users/metadata";
import {
    Type_index_metadataUser,
    Type_put_metadataUser,
} from "src/api/tms-users/metadata/types";

import { Page_MetadataUsersComponent } from "./Page_MetadataUsers.component";

export type Type_modalDeleteMetadataUser = {
    isOpen: boolean;
    props?: Type_index_metadataUser;
};

export const Page_MetadataUsersContainer = () => {
    const { isFetching, data: metadataUsers } = useIndexMetadataUsers() || {};

    const [modalDeleteMetadataUser, setModalDeleteMetadataUser] =
        useState<Type_modalDeleteMetadataUser>({
            isOpen: false,
        });

    const { mutate: mutateDeleteMetadataUser } =
        mutationDeleteMetadataUser() || {};

    const removeMetadataUser = (id: number) => {
        mutateDeleteMetadataUser(id);
    };

    const { mutate: mutationMetadataUsersStatus } =
        mutationStatusMetadataUser() || {};

    const useChangeStatus = (metadataUser: Type_index_metadataUser) => {
        mutationMetadataUsersStatus({
            id: metadataUser.id,
            enabled: !metadataUser.enabled,
        } as Type_put_metadataUser);
    };

    return (
        <Page_MetadataUsersComponent
            isFetching={isFetching}
            metadataUsers={metadataUsers || []}
            changeStatus={useChangeStatus}
            deleteMetadataUser={removeMetadataUser}
            modalDeleteMetadataUser={modalDeleteMetadataUser}
            setModalDeleteMetadataUser={setModalDeleteMetadataUser}
        />
    );
};
