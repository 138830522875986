import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
    formatterIndexDayOff,
    formatterIndexDaysOff,
    formatterPostDayOff,
    formatterPutDayOff,
    formatterShowDayOff,
} from "src/api/tms-projects/daysOffs/formatters";
import {
    createDayOff,
    deleteDayOff,
    indexDaysOff,
    showDayOff,
    statusDayOff,
    updateDayOff,
} from "src/api/tms-projects/daysOffs/services";
import {
    Type_post_dayOff,
    Type_prj_putStatus_dayOff,
    Type_show_dayOff,
} from "src/api/tms-projects/daysOffs/types";
import { DayOffKeys } from "src/api/tms-projects/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexDaysOff = () => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [DayOffKeys.INDEX, requestConfig],
        queryFn: () => indexDaysOff(requestConfig),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexDaysOff");
            }

            return formatterIndexDaysOff(data.data.data);
        },
        enabled: !!requestConfig.projectId,
    });
};

export const mutationCreateDayOff = (callback?: () => void) => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.DayOff");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_post_dayOff) =>
            createDayOff(formatterPostDayOff(data), requestConfig),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateDayOff");
            }

            const formattedData = formatterIndexDayOff(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        dayOff: formattedData.name,
                    },
                }),
            });
            if (typeof callback === "function") {
                callback();
            } else {
                await queryClient.invalidateQueries([
                    DayOffKeys.INDEX,
                    requestConfig,
                ]);
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateDayOff = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.DayOff");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (dataToUpdate: Type_show_dayOff) =>
            updateDayOff(formatterPutDayOff(dataToUpdate), requestConfig),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateDayOff");
            }

            const formattedData = formatterIndexDayOff(data.data.data);

            addSuccess({
                description: fmt("ToastSuccessUpdate", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        dayOff: formattedData.name,
                    },
                }),
            });

            await queryClient.invalidateQueries([
                DayOffKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusDayOff = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: Type_prj_putStatus_dayOff) => {
            return statusDayOff(data, requestConfig);
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusDayOff");
            }

            await queryClient.invalidateQueries([
                DayOffKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const useShowDayOff = (dayOffIdToUpdate: number) => {
    const { requestConfig, isLoading: isLoadingRequestConfig } = useProject();
    return useQuery(
        [DayOffKeys.SHOW, dayOffIdToUpdate, requestConfig],
        () => showDayOff(requestConfig, dayOffIdToUpdate),
        {
            select: (data) => {
                if (!data?.success || !data?.data?.data) {
                    throw new Error("Wrong format data: useShowDayOff");
                }

                return formatterShowDayOff(data.data.data);
            },
            onError: (err) => {
                console.error(err);
            },
            enabled: !!dayOffIdToUpdate && !isLoadingRequestConfig,
            refetchOnWindowFocus: false,
        },
    );
};

export const useDeleteDayOff = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => deleteDayOff(id, requestConfig),
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteDayOff");
            }

            await queryClient.invalidateQueries([
                DayOffKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            console.error(err);
            return err;
        },
    });
};
