import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Form, FormProvider, useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { useSelectListProjectUsers } from "src/api/tms-projects/projectUsers";
import { Type_selectList_projectUser } from "src/api/tms-projects/projectUsers/types";
import {
    PeopleTaskAreaKeys,
    PeopleTaskKeys,
} from "src/api/tms-scheduling/keys";
import {
    mutationCreatePeople,
    mutationUpdatePeople,
    useSelectPeople,
} from "src/api/tms-scheduling/people";
import { Type_select_people } from "src/api/tms-scheduling/people/types";
import {
    Type_index_peopleTask,
    Type_post_peopleTask,
} from "src/api/tms-scheduling/peopleTask/types";
import { Type_post_peopleTaskArea } from "src/api/tms-scheduling/peopleTaskArea/types";
import {
    DropdownMenu,
    Empty,
    Spinner,
    TMC_Button,
    UserSmallCard,
} from "src/components";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { AutocompletePeopleTasks } from "src/components/Components_Teamoty/autocompletesRhf/AutocompletePeopleTask";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { HeaderToolbar } from "src/layouts/Layout_ContextualDrawer/components/HeaderToolbar";
import { Header } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { hasKey } from "src/utils/object";
import { sortData, sortDscData } from "src/utils/sortData";

import { Styled_Stack_buttonsGroup } from "./PeopleTaskForm.style";

export type Type_createNewPeople = {
    id: number;
    firstName: string;
    lastName: string;
    email?: string;
};

type Type_Props_PeopleTaskForm = {
    onClose: () => void;
    focus: boolean;
    peopleTask?: Type_index_peopleTask[];
    isFetchingPeopleTask?: boolean;
    createTaskPerson: (
        data: Type_post_peopleTaskArea | Type_post_peopleTask,
    ) => Promise<any>;
    isCreatingTaskPerson: boolean;
    removeTaskPerson: (id: number) => Promise<any>;
    invalidateQueryAfterCreate: (
        | Type_RequestConfig
        | PeopleTaskAreaKeys
        | PeopleTaskKeys
    )[];
};

export const PeopleTaskForm = ({
    onClose,
    focus,
    peopleTask,
    isFetchingPeopleTask,
    createTaskPerson,
    isCreatingTaskPerson,
    removeTaskPerson,
    invalidateQueryAfterCreate,
}: Type_Props_PeopleTaskForm) => {
    const { sendEvent } = useChannel({});
    const queryClient = useQueryClient();
    const { addSuccess } = useToast();

    ///////////////////////////////////////
    ///            i18n                 ///
    ///////////////////////////////////////

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerPeople",
    );
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtExt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.PeopleTask",
    );

    ///////////////////////////////////////
    ///            State                ///
    ///////////////////////////////////////

    const [peopleTasks, setPeopleTasks] = useState<Type_index_peopleTask[]>([]);
    const [people, setPeople] = useState<Type_select_people[]>([]);

    const updatePeopleTaskToState = (
        peopleTaskUpdated: Type_index_peopleTask,
    ) => {
        setPeopleTasks((old) =>
            old.map((people) =>
                people.id !== peopleTaskUpdated.id
                    ? people
                    : {
                          ...people,
                          ...peopleTaskUpdated,
                          peopleTaskId: people.peopleTaskId,
                      },
            ),
        );
    };

    const updatePeopleToState = (peopleUpdated: Type_select_people) => {
        setPeople((old) =>
            old.map((people) =>
                people.id !== peopleUpdated.id ? people : peopleUpdated,
            ),
        );
    };

    const updatePeopleFromDialog = (peopleUpdated: Type_select_people) => {
        updatePeopleToState(peopleUpdated);
        updatePeopleTaskToState(peopleUpdated as Type_index_peopleTask);
    };

    ///////////////////////////////////////
    ///         Queries                 ///
    ///////////////////////////////////////

    const {
        isFetching: isFetchingSelectProjectUsers,
        data: projectUsersFetched = [],
    } = useSelectListProjectUsers();

    const { data: peopleFetched, isFetching: isFetchingSelectPeople } =
        useSelectPeople();

    const { mutateAsync: createPeople, isLoading: isCreatingPeople } =
        mutationCreatePeople();

    const { mutateAsync: updatePeople } = mutationUpdatePeople(
        (peopleUpdated: Type_select_people) =>
            updatePeopleFromDialog(peopleUpdated),
    );

    const isLoading: boolean = useMemo(
        () => isCreatingTaskPerson || isCreatingPeople,
        [isCreatingTaskPerson, isCreatingPeople],
    );

    ///////////////////////////////////////
    ///         Effects                 ///
    ///////////////////////////////////////

    useEffect(() => {
        if (!isFetchingPeopleTask) {
            peopleTask?.length && setPeopleTasks(peopleTask);
        }
    }, [isFetchingPeopleTask]);

    useEffect(() => {
        if (peopleFetched?.length && !isFetchingSelectPeople)
            setPeople(peopleFetched);
    }, [isFetchingSelectPeople]);

    ///////////////////////////////////////
    ///         React hook form         ///
    ///////////////////////////////////////

    const form = useForm<any>({
        defaultValues: {
            peopleTasks: [],
        },
    });

    const selectedPeople = useWatch({
        control: form.control,
        name: "peopleTasks",
    });

    ///////////////////////////////////////
    ///           Utils                 ///
    ///////////////////////////////////////

    const peopleNotAssigned: Type_select_people[] = useMemo(() => {
        const peopleTasksIds = peopleTasks?.map((user) => user.id);
        return sortData(
            people.filter((user) => !peopleTasksIds?.includes(user.id)),
            "firstName",
        );
    }, [people, peopleTasks]);

    const filterProjectUsersWithoutPeople = (
        projectUsersFetched: Type_selectList_projectUser[],
        people: Type_select_people[],
    ) => {
        const notAssignedIds = people?.map((user) => user.userId);
        return projectUsersFetched.filter(
            (user) => !notAssignedIds?.includes(user.id),
        );
    };

    const assignableUsers: Type_selectList_projectUser[] = useMemo(
        () =>
            sortDscData(
                sortData(
                    [
                        ...(peopleNotAssigned?.length
                            ? (peopleNotAssigned?.map((person) => ({
                                  ...person,
                                  group: person.userId
                                      ? fmt("Platform")
                                      : fmt("Other"),
                              })) as Type_select_people[])
                            : []),
                        ...(projectUsersFetched?.length
                            ? (filterProjectUsersWithoutPeople(
                                  projectUsersFetched,
                                  people,
                              )?.map((person) => ({
                                  ...person,
                                  group: fmt("Platform"),
                              })) as Type_selectList_projectUser[])
                            : []),
                    ],
                    "firstName",
                ),
                "group",
            ),
        [projectUsersFetched, peopleTasks, people],
    );

    const sx = {
        "&:hover .buttonGroup-peopleTask": {
            visibility: "visible",
        },
    };

    const addNewPeopleToTask = async () => {
        const promises: Promise<any>[] = [];
        for (const newPeopleTask of selectedPeople) {
            const promise = (async () => {
                let idNewPeopleTask = newPeopleTask.id;

                if (!("userId" in newPeopleTask)) {
                    const { data: resp } = await createPeople({
                        user_id: newPeopleTask.id,
                    });
                    idNewPeopleTask = resp.data.id;
                }

                await createTaskPerson({
                    peopleId: idNewPeopleTask,
                });
            })();

            promises.push(promise);
        }

        await Promise.all(promises);

        await queryClient.invalidateQueries(invalidateQueryAfterCreate);
        addSuccess({
            description: fmt("ToastSuccessCreate"),
        });

        form.reset({ peopleTasks: [] });
    };

    const createNewPeople = async (data: Type_createNewPeople) => {
        const { data: resp } = await createPeople(
            hasKey(data, "userId")
                ? {
                      firstName: data.firstName,
                      lastName: data.lastName,
                      email: data.email,
                  }
                : { user_id: data.id },
        );

        if (resp?.data) {
            await createTaskPerson({
                peopleId: resp.data.id,
            });
            if (invalidateQueryAfterCreate) {
                await queryClient.invalidateQueries([
                    invalidateQueryAfterCreate,
                ]);
            }
        }
    };

    const addNewPersonToTask = async (idNewPerson: number) => {
        await createTaskPerson({
            peopleId: idNewPerson,
        });
        if (invalidateQueryAfterCreate) {
            await queryClient.invalidateQueries(invalidateQueryAfterCreate);
        }
    };

    const removePersonToTask = async (peopleTaskId: number) => {
        await removeTaskPerson(peopleTaskId);
    };

    const sortedPeopleTasks: Type_index_peopleTask[] = useMemo(() => {
        return sortData(peopleTasks, "firstName");
    }, [peopleTasks]);

    ///////////////////////////////////////
    ///         Menus DropDown          ///
    ///////////////////////////////////////

    const menuItemsAssigned = useMemo(
        () => [
            {
                label: fmtActions("Remove"),
                icon: {
                    name: "empty-set",
                    variant: "regular",
                },
                call: (_id: number, peopleTask: Type_index_peopleTask) =>
                    removePersonToTask(peopleTask.peopleTaskId),
            },
        ],
        [],
    );

    const menuItemsNotAssigned = useMemo(
        () => [
            {
                label: fmtActions("Assign"),
                icon: {
                    name: "plus",
                    variant: "solid",
                },
                call: (id: number) => {
                    addNewPersonToTask(id);
                },
            },
        ],
        [],
    );

    const menuCanEdit = useMemo(
        () => [
            {
                label: fmtActions("Update"),
                icon: {
                    name: "pen",
                    variant: "regular",
                },
                call: (_id: number, user: any) =>
                    sendEvent("updatePeople", {
                        open: true,
                        action: "update",
                        id: user.id,
                    }),
            },
        ],
        [],
    );

    return (
        <FormProvider {...form}>
            <Form>
                <Header alignItems={"stretch"}>
                    <HeaderToolbar onClose={onClose} />
                    <Typography sx={{ paddingInlineStart: 2 }}>
                        {fmt("Title")}
                    </Typography>
                </Header>
            </Form>
            <Stack
                gap={2}
                display={"flex"}
                flexGrow={1}
                sx={{ paddingX: 4, paddingBottom: 2, overflowY: "auto" }}
            >
                <Stack spacing={2} direction="row" sx={{ paddingTop: 2 }}>
                    <AutocompletePeopleTasks
                        autoFocus={focus}
                        name="peopleTasks"
                        data={assignableUsers}
                        placeholder={fmt("Placeholder")}
                        createNewPeople={createNewPeople}
                        updatePeople={updatePeople}
                    />
                    <TMC_Button
                        data-testid={`people-task-form-add-new-people-task-btn`}
                        variant="contained"
                        onClick={addNewPeopleToTask}
                        disabled={!selectedPeople?.length || isLoading}
                        style={{ height: "auto" }}
                    >
                        {isLoading ? <Spinner /> : fmtActions("Assign")}
                    </TMC_Button>
                </Stack>
                {isFetchingSelectProjectUsers || isFetchingPeopleTask ? (
                    <FullSpinner />
                ) : !people?.length && !peopleTasks?.length ? (
                    <Box>
                        <Empty
                            message={fmt("EmptyPeopleTaskMessage")}
                            icon={true}
                            dataTestIdRef={
                                "EmptyPeopleTask-contextualDrawer-no-data"
                            }
                        />
                    </Box>
                ) : (
                    <Stack>
                        <Typography
                            variant="h5"
                            color="text.secondary"
                            sx={{ paddingY: 2 }}
                            data-testid="people-task-form-subtitle-assigned"
                        >
                            {`${fmt("Assigned")} (${peopleTasks?.length})`}
                        </Typography>
                        <Stack gap={2}>
                            {sortedPeopleTasks?.length ? (
                                sortedPeopleTasks.map(
                                    (personTask: Type_index_peopleTask) => (
                                        <UserSmallCard
                                            canHover
                                            key={`people-task-form-people-assigned-${personTask.id}`}
                                            id={personTask.id}
                                            firstName={personTask?.firstName}
                                            lastName={personTask?.lastName}
                                            email={personTask?.email}
                                            badgeLabel={
                                                !hasKey(personTask, "userId") ||
                                                personTask?.userId
                                                    ? undefined
                                                    : fmtExt("Ext")
                                            }
                                            actionsChildren={
                                                <Styled_Stack_buttonsGroup
                                                    className={
                                                        "buttonGroup-peopleTask"
                                                    }
                                                >
                                                    <DropdownMenu
                                                        menuItems={[
                                                            ...menuItemsAssigned,
                                                            ...(personTask?.userId
                                                                ? []
                                                                : menuCanEdit),
                                                        ]}
                                                        props={personTask}
                                                        id={
                                                            personTask.peopleTaskId
                                                        }
                                                    />
                                                </Styled_Stack_buttonsGroup>
                                            }
                                            sx={sx}
                                        />
                                    ),
                                )
                            ) : (
                                <Box>
                                    <Empty
                                        dataTestIdRef={
                                            "EmptyPeopleTask-contextualDrawer-no-assigned"
                                        }
                                        message={fmt("EmptyPeopleTaskMessage")}
                                    />
                                </Box>
                            )}
                        </Stack>
                        {!!peopleNotAssigned?.length && (
                            <Typography
                                variant="h5"
                                color="text.secondary"
                                sx={{ paddingY: 2 }}
                                data-testid="people-task-form-subtitle-not-assigned"
                            >
                                {`${fmt("NotAssigned")} (${peopleNotAssigned?.length})`}
                            </Typography>
                        )}
                        <Stack gap={2}>
                            {peopleNotAssigned?.map(
                                (person: Type_select_people) => (
                                    <UserSmallCard
                                        canHover
                                        key={`person-${person.id}`}
                                        id={person.id}
                                        firstName={person?.firstName}
                                        lastName={person?.lastName}
                                        email={person?.email}
                                        badgeLabel={
                                            !hasKey(person, "userId") ||
                                            person?.userId
                                                ? undefined
                                                : fmtExt("Ext")
                                        }
                                        actionsChildren={
                                            <Styled_Stack_buttonsGroup
                                                className={
                                                    "buttonGroup-peopleTask"
                                                }
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        addNewPersonToTask(
                                                            person.id,
                                                        )
                                                    }
                                                    disabled={isLoading}
                                                >
                                                    <Icon
                                                        variant={"solid"}
                                                        icon={"plus"}
                                                    />
                                                </IconButton>
                                                <DropdownMenu
                                                    menuItems={[
                                                        ...menuItemsNotAssigned,
                                                        ...(person?.userId
                                                            ? []
                                                            : menuCanEdit),
                                                    ]}
                                                    id={person.id}
                                                    props={person}
                                                />
                                            </Styled_Stack_buttonsGroup>
                                        }
                                        sx={sx}
                                    />
                                ),
                            )}
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </FormProvider>
    );
};
