import Konva from "konva";
import React, { useCallback, useState } from "react";
import { Group, Label, Path, Rect, Tag, Text } from "react-konva";

import {
    fontSizeButton,
    sizePlanningButtonPictogram,
    sizePlanningScroll,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { Type_Props_PlanningWorkforceButton } from "src/components/Components_Teamoty/Planning/Workforce/PlanningWorkforce.type";
import { usePlanningContext } from "src/contexts/planning";

export const PlanningWorkforceButton = ({
    x,
    y,
    width,
    height,
    onChange,
    selected,
    pictogram,
    title,
}: Type_Props_PlanningWorkforceButton) => {
    const { defaultCursor } = usePlanningContext();
    const theme = usePlanningTheme();

    const [showTooltip, setShowTooltip] = useState(false);

    const maxValue = Math.max(width, height);
    const offsetX = (maxValue - width) / 2;
    const offsetY = (maxValue - height) / 2;

    const fillColor = selected
        ? theme.colorPlanningSelected
        : theme.transparent;
    const textColor = selected
        ? theme.colorPlanningButtonTextSelected
        : theme.colorPlanningButtonText;

    const tooltipX = x - 20 < 0 ? x : x - 40;
    const tooltipY = y - 20;

    const handleMouseEvents = useCallback(
        (
            e: Konva.KonvaEventObject<MouseEvent>,
            cursor: string,
            show: boolean,
        ) => {
            changeCursor(e, cursor);

            if (show) {
                const timer = setTimeout(() => {
                    setShowTooltip(true);
                }, 500); // Delay of 500ms for showing the tooltip
                return () => clearTimeout(timer);
            } else {
                setShowTooltip(false); // Close immediately when hiding
            }
        },
        [],
    );

    return (
        <Group
            x={x}
            y={y}
            onClick={() => {
                onChange();
            }}
            onMouseOver={(e) => handleMouseEvents(e, "pointer", true)}
            onMouseOut={(e) => handleMouseEvents(e, defaultCursor, false)}
        >
            <Rect
                width={sizePlanningScroll}
                height={sizePlanningScroll}
                fill={fillColor}
            />

            <Path
                scaleX={sizePlanningButtonPictogram / maxValue}
                scaleY={sizePlanningButtonPictogram / maxValue}
                x={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                y={(sizePlanningScroll - sizePlanningButtonPictogram) / 2}
                offsetX={-offsetX}
                offsetY={-offsetY}
                fill={textColor}
                data={pictogram}
            />

            <Label visible={showTooltip} x={tooltipX} y={tooltipY}>
                <Tag fill={"white"} cornerRadius={2} />
                <Text
                    text={title}
                    fill={"black"}
                    padding={5}
                    fontSize={fontSizeButton}
                />
            </Label>
        </Group>
    );
};
