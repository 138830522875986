import {
    FormControlLabel,
    RadioGroup as MuiRadioGroup,
    Radio,
    RadioGroupProps,
    Typography,
} from "@mui/material";
import { FormControlLabelProps } from "@mui/material/FormControlLabel/FormControlLabel";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TMC_FormHelperText } from "src/components/Components_Common/_MuiComponentsVariants";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";

export type Type_RadioGroupItem = Omit<FormControlLabelProps, "control"> & {
    value: string | number;
    label?: string;
};

type Type_Props_RadioGroup = RadioGroupProps &
    Type_ReadonlyComponent & {
        name: string;
        options: Type_RadioGroupItem[];
        label?: string;
    };

export const RadioGroup = ({
    label,
    name,
    options,
    readonly,
    ...props
}: Type_Props_RadioGroup) => {
    const { control, formState } = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <MuiRadioGroup
                        {...field}
                        {...props}
                        data-testid={`radioGroup-${name}`}
                    >
                        {label && (
                            <Typography
                                variant="body2"
                                color="secondary"
                                marginBottom={2}
                                data-testid="radioGroup-Title"
                            >
                                {label}
                            </Typography>
                        )}
                        {options.map(({ value, label, ...item }) => (
                            <FormControlLabel
                                {...item}
                                key={value}
                                value={value}
                                data-testid={`radio-${name}-${value}`}
                                label={label}
                                control={
                                    <Radio
                                        data-testid={`radio-check-${name}-${value}`}
                                        size="small"
                                    />
                                }
                                disabled={readonly || item.disabled}
                            />
                        ))}
                    </MuiRadioGroup>
                )}
            />
            {formState?.errors[name] && (
                <TMC_FormHelperText
                    helperText={formState?.errors[name]?.message as string}
                    inputName={name}
                    sx={{ marginBottom: 2 }}
                />
            )}
        </>
    );
};
