import { Modal, Typography } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";

import { Header, TMC_Button } from "src/components";
import {
    Styled_FormDrawer,
    Styled_MainStack,
    Styled_StackActionsDrawerReverse,
    Styled_StackContentDrawer,
} from "src/components/Components_Common/Drawer/Drawer.style";
import {
    AcceptedFormat,
    InputFile,
    Type_Resource,
} from "src/components/Components_Common/forms/InputFile";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { BoxModal } from "./ImportModal.style";
export type Type_Props_ImportModal_Component = {
    title: string;
    isLoading: boolean;
    errors?: any | null | undefined;
    acceptedFormats: AcceptedFormat[];
    onClose: () => void;
    onSubmit: (sheet: any) => void;
    onAbort?: () => void;
};

export const errorsDisplay = (_errors: any) => {
    const errors = _errors?.data?.data || _errors?.data?.errors;
    if (errors) {
        return (
            <div>
                {Object.entries(errors).map(([line, errorArr]: any, index) => (
                    <div key={index}>
                        <Typography color={COLORS.warning} variant="body1Bold">
                            {line}:{" "}
                        </Typography>
                        {errorArr.map((errorObj: any, errorIndex: any) => (
                            <ul key={errorIndex}>
                                {typeof errorObj == "object" &&
                                    Object.entries(errorObj).map(
                                        (
                                            [fieldName, messages]: any,
                                            nestedIndex,
                                        ) => (
                                            <li key={nestedIndex}>
                                                {Array.isArray(messages) ? (
                                                    // If messages is an array, display each message
                                                    messages.map(
                                                        (
                                                            message: string,
                                                            messageIndex: any,
                                                        ) => (
                                                            <Typography
                                                                variant="body1"
                                                                color={
                                                                    COLORS.warning
                                                                }
                                                                key={
                                                                    messageIndex
                                                                }
                                                            >
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                {fieldName}:{" "}
                                                                {message}{" "}
                                                            </Typography>
                                                        ),
                                                    )
                                                ) : (
                                                    // If messages is not an array, display it directly
                                                    <Typography
                                                        variant="body1"
                                                        color={COLORS.warning}
                                                    >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        {fieldName}: {messages}{" "}
                                                    </Typography>
                                                )}
                                            </li>
                                        ),
                                    )}
                                {typeof errorObj == "string" && (
                                    <li key={errorIndex}>
                                        <Typography
                                            variant="body1"
                                            color={COLORS.warning}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {errorObj}
                                        </Typography>
                                    </li>
                                )}
                                {Array.isArray(errorObj) && // Check if errorObj is an array
                                    errorObj.map(
                                        (
                                            error: string,
                                            nestedIndex: number, // If errorObj is an array, map over it
                                        ) => (
                                            <li key={nestedIndex}>
                                                <Typography
                                                    variant="body1"
                                                    color={COLORS.warning}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {error}
                                                </Typography>
                                            </li>
                                        ),
                                    )}
                            </ul>
                        ))}
                        <br />
                    </div>
                ))}
            </div>
        );
    }
};

export const ImportModal = ({
    acceptedFormats,
    isLoading,
    errors,
    title,
    onAbort = () => {},
    onSubmit,
    onClose,
}: Type_Props_ImportModal_Component) => {
    const defaultSheet = {
        file: null,
        errors: false,
    };
    const [sheet, setSheet] = useState<Type_Resource>(defaultSheet);
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Actions");

    const handleClose = () => {
        setSheet(defaultSheet);
        onAbort();
        onClose();
    };

    useEffect(() => {
        // abort all state each time we load a new file
        onAbort();
    }, [sheet.file]);

    useEffect(() => {
        if (errors) {
            setSheet((prev) => {
                return {
                    ...prev,
                    errors: true,
                };
            });
        }
    }, [errors]);

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-import-file"
            aria-describedby="modal-import-files"
        >
            <BoxModal>
                <Styled_MainStack sx={{ borderRadius: 3, pb: 4 }}>
                    <Header onClose={handleClose} title={title} />

                    <Formik initialValues={{}} onSubmit={() => onSubmit(sheet)}>
                        <Styled_FormDrawer>
                            <Styled_StackContentDrawer gap={4}>
                                <InputFile
                                    acceptFormats={acceptedFormats}
                                    state={sheet}
                                    setState={setSheet}
                                    data-testid="Import-Modal-Input-File"
                                />

                                {sheet.file && errors && errorsDisplay(errors)}
                            </Styled_StackContentDrawer>

                            <Styled_StackActionsDrawerReverse>
                                <TMC_Button
                                    data-testid="Impor-File-Drawer-Submit"
                                    color="primary"
                                    type="submit"
                                    disabled={
                                        isLoading ||
                                        sheet.file == null ||
                                        sheet.errors
                                    }
                                    sx={{ minWidth: 200 }}
                                    endIcon={
                                        !isLoading && (
                                            <Icon
                                                variant="light"
                                                icon="arrow-down-to-bracket"
                                            />
                                        )
                                    }
                                >
                                    {isLoading ? (
                                        <Icon
                                            variant="solid"
                                            icon="spinner-third"
                                            spin
                                        />
                                    ) : (
                                        fmt("Import")
                                    )}
                                </TMC_Button>
                            </Styled_StackActionsDrawerReverse>
                        </Styled_FormDrawer>
                    </Formik>
                </Styled_MainStack>
            </BoxModal>
        </Modal>
    );
};
