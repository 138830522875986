import { FormikValues } from "formik";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import {
    formatterNewPassword,
    formatterResetPassword,
} from "src/api/tms-users/auth/formatters";
import {
    changeCurrentPassword,
    checkCguValidation,
    newPassword,
    resetPassword,
} from "src/api/tms-users/auth/services";
import {
    Type_post_newPassword,
    Type_post_resetPassword,
} from "src/api/tms-users/auth/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const mutationCheckCGU = () => {
    return useMutation({
        mutationFn: () => checkCguValidation(),
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationResetPassword = () => {
    const navigate = useNavigate();
    const { lang } = useParams();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_post_resetPassword) =>
            resetPassword(formatterResetPassword(data)),
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useResetPassword");
            }

            navigate(`/${lang}/reset-password-confirm`, { replace: true });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationNewPassword = () => {
    const navigate = useNavigate();
    const { lang } = useParams();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_post_newPassword) =>
            newPassword(formatterNewPassword(data)),
        onSuccess: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useResetPassword");
            }

            navigate(`/${lang}/new-password-confirm`, { replace: true });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationChangeCurrentPassword = () => {
    return useMutation({
        mutationFn: (data: FormikValues) => changeCurrentPassword(data),
    });
};
