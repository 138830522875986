import { Stack, TextField } from "@mui/material";
import { FormikProvider, FormikValues } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { ColorPicker, TMC_Button } from "src/components";
import { MultiLanguages } from "src/components/Components_Common/forms";
import { AutocompleteCompanies } from "src/components/Components_Teamoty/autocompletes/AutocompleteCompanies";
import { useFormikSaveOnChange } from "src/hooks/useFormikSaveOnChange";

const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address"),
    password: Yup.string().min(8, "Must be 8 characters or more"),
    companies: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            name: Yup.string(),
        }),
    ),
    color: Yup.string(),
});

type Type_MyFormValues = {
    names: any;
    email: string;
    password: string;
    companies: any[];
    color: string;
};

const area1 = {
    id: 1,
    names: {
        en_GB: "Zone 1",
        fr_FR: "Zone 1",
    },
    color: "#FFFFB0",
    sortOrder: 1,
    parentArea: {
        id: 7,
        name: "",
    },
    areaType: {
        id: 1,
        name: "Housing",
    },
    drawing: {
        id: 4,
        name: "Sélection_284.png",
        enabled: true,
    },
    scheduling: true,
    resourceType: null,
    resource: null,
    permanentUse: false,
    show: true,
};

const area2 = {
    id: 2,
    names: {
        en_GB: "Zone 2",
        fr_FR: "Zone 2",
    },
    color: "#9845B0",
    sortOrder: 2,
    parentArea: {
        id: 7,
        name: "",
    },
    areaType: {
        id: 1,
        name: "Housing",
    },
    drawing: {
        id: 4,
        name: "Sélection_284.png",
        enabled: true,
    },
    scheduling: true,
    resourceType: null,
    resource: null,
    permanentUse: false,
    show: true,
};

export const FormikHandleChangeForm = () => {
    const handleSubmit = (values: FormikValues) => {
        console.log("====> handleSubmit: should call API", values);
    };

    const [data, setData] = useState<Type_MyFormValues>({
        names: {},
        email: "",
        password: "",
        companies: [],
        color: "",
    });

    const formik = useFormikSaveOnChange({
        handleSubmit,
        initialValues: data,
        enableReinitialize: true,
        validationSchema,
    });

    return (
        <>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2} sx={{ maxWidth: "400px", m: 4 }}>
                        <Stack direction="row" spacing={2}>
                            Change form initialValues :
                            <TMC_Button
                                color="secondary"
                                onClick={() => {
                                    formik.handleQuit(); // clear previous data debounce ans for submit current data
                                    setData((prevState) => ({
                                        ...prevState,
                                        names: { ...area1.names },
                                        color: area1.color,
                                    }));
                                }}
                            >
                                Area 1
                            </TMC_Button>
                            <TMC_Button
                                color="secondary"
                                onClick={() => {
                                    formik.handleQuit(); // clear previous data debounce ans for submit current data
                                    setData((prevState) => ({
                                        ...prevState,
                                        names: { ...area2.names },
                                        color: area2.color,
                                    }));
                                }}
                            >
                                Area 2
                            </TMC_Button>
                        </Stack>
                        <MultiLanguages
                            name="names"
                            values={formik.values}
                            handleChange={formik.handleChange}
                            errors={formik.errors}
                        />
                        <ColorPicker
                            data-testid="Areas-Drawer-AddArea-color"
                            name="color"
                            label={"Color"}
                            fullWidth
                        />
                        <Stack direction="row" spacing={2}>
                            <TextField
                                type="email"
                                {...formik.getFieldProps("email")}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email &&
                                    (formik.errors.email as string)
                                }
                            />
                            <TextField
                                type="password"
                                {...formik.getFieldProps("password")}
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                                helperText={
                                    formik.touched.password &&
                                    (formik.errors.password as string)
                                }
                            />
                        </Stack>
                        <AutocompleteCompanies
                            name={"companies"}
                            label={"Société"}
                        />
                    </Stack>
                </form>

                <div>{JSON.stringify(formik)}</div>
            </FormikProvider>
        </>
    );
};
