import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter_License_Types } from "src/api/filters";
import { LicenseTypesPath, Url } from "src/api/paths";
import {
    Type_com_post_licenseType,
    Type_com_put_licenseType,
} from "src/api/tms-commons/licenseTypes/types";

export const indexLicenseTypes = (): GetReturnType => {
    return get(`${Url.COMMONS}${LicenseTypesPath.LICENSE_TYPES}`);
};

export const selectListLicenseTypes = (
    filters: Type_Filter_License_Types = {},
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(
        `${Url.COMMONS}${LicenseTypesPath.LICENSE_TYPES_SELECT_LIST}${urlParams}`,
    );
};

export const showLicenseType = (id: number): GetReturnType => {
    return get(`${Url.COMMONS}${LicenseTypesPath.LICENSE_TYPES}/${id}`);
};

export const createLicenseType = (
    data: Type_com_post_licenseType,
): PostReturnType => {
    return post(data, `${Url.COMMONS}${LicenseTypesPath.LICENSE_TYPES}`);
};

export const indexLicenseTypesModes = (): GetReturnType => {
    return get(`${Url.COMMONS}${LicenseTypesPath.LICENSE_TYPES_MODES}`);
};

export const updateLicenseType = (
    id: number,
    data: Type_com_put_licenseType,
): PostReturnType => {
    return put(data, `${Url.COMMONS}${LicenseTypesPath.LICENSE_TYPES}/${id}`);
};

export const deleteLicenseType = (id: number): RemoveReturnType => {
    return remove(`${Url.COMMONS}${LicenseTypesPath.LICENSE_TYPES}/${id}`);
};

export const statusLicenseType = (
    id: number,
    data: Type_com_put_licenseType,
): PutReturnType => {
    return put(data, `${Url.COMMONS}${LicenseTypesPath.LICENSE_TYPES}/${id}`);
};
