// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface Styled_ChipProps extends ChipProps {
    ownerState?: {
        chipBackgroundColor?: string;
        chipTextColor?: string;
        highlighted?: boolean;
        tradeCode?: boolean;
        tradeLabel?: boolean;
        isTag?: boolean;
    };
}

export const Styled_Chip = styled(Chip)<Styled_ChipProps>(
    ({ theme, ownerState }) => ({
        "&.MuiChip-root": {
            padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
            ...(ownerState?.tradeCode && {
                borderRadius: `2px 0 0 2px`,
            }),
            ...(ownerState?.tradeLabel && {
                borderRadius: `0 2px 2px 0`,
            }),
            ...(ownerState?.isTag && {
                margin: `0px`,
            }),
            ...(ownerState?.chipBackgroundColor && {
                backgroundColor: ownerState?.chipBackgroundColor,
            }),
            "& .MuiChip-label": {
                ...(ownerState?.tradeCode && {
                    textTransform: "uppercase",
                }),
                ...(ownerState?.chipTextColor && {
                    color: ownerState?.chipTextColor,
                }),
            },
            "& .MuiSvgIcon-root": {
                transform: `translateX(${theme.spacing(2)})`,
            },
        },
    }),
);
export const Styled_ResourceChip = styled(Chip)<Styled_ChipProps>(
    ({ theme, ownerState }) => ({
        "&.MuiChip-root": {
            padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
            ...(ownerState?.chipBackgroundColor && {
                backgroundColor: ownerState?.chipBackgroundColor,
            }),
            "& .MuiChip-label": {
                textTransform: "uppercase",
                ...(ownerState?.chipTextColor && {
                    color: ownerState?.chipTextColor,
                }),
            },
            "& .MuiSvgIcon-root": {
                ...(ownerState?.chipTextColor && {
                    color: ownerState?.chipTextColor,
                }),
            },
        },
    }),
);

export const Styled_ColorChip = styled(Chip)<Styled_ChipProps>(
    ({ theme, ownerState }) => ({
        border: theme.border.alpha,
        "&.MuiChip-root": {
            width: "24px",
            height: "24px",
            ...(ownerState?.chipBackgroundColor && {
                backgroundColor: ownerState?.chipBackgroundColor,
            }),
        },
    }),
);
