import {
    PeriodToggleEnum,
    Type_PlanningFilters,
} from "@cimba-digital-construction-solution/utils/dist/filters/planningFilters";
import dayjs from "dayjs";

import { Type_PlanningFilterForm } from "./types";

export const formatterPlanningFilters = (
    values: Type_PlanningFilterForm,
): Type_PlanningFilters => {
    const formattedPeriod = (() => {
        if (values.period.type === PeriodToggleEnum.TIME_RANGE) {
            return {
                ...values.period,
                startDate: values.period.dateRange[0].valueOf(),
                endDate: values.period.dateRange[1].valueOf(),
            };
        }

        return values.period;
    })();

    return {
        ...values,
        period: formattedPeriod,
    };
};

export const parsePlanningFilters = (
    values: Type_PlanningFilters,
): Type_PlanningFilterForm => {
    const parsedPeriod = (() => {
        if (values.period.type === PeriodToggleEnum.TIME_RANGE) {
            return {
                ...values.period,
                dateRange: [
                    dayjs(values.period.startDate),
                    dayjs(values.period.endDate),
                ],
            };
        }

        return values.period;
    })();

    return {
        ...values,
        period: parsedPeriod,
    };
};
