import {
    Type_index_sequenceNote,
    Type_post_sequenceNote,
    Type_sch_index_sequenceNote,
    Type_sch_post_sequenceNote,
    Type_sch_put_sequenceNote,
} from "src/api/tms-scheduling/sequenceNotes/types";
import { Type_index_taskNote } from "src/api/tms-scheduling/taskNotes/types";
import { Type_put_note } from "src/forms/notes/NoteForm";
import { formatDateTimeForFront } from "src/utils/date";

export const formatterIndexSequenceNotes = (
    notes: Type_sch_index_sequenceNote[],
): Type_index_taskNote[] => {
    return notes.map(formatterIndexSequenceNote);
};

export const formatterIndexSequenceNote = (
    sequenceNote: Type_sch_index_sequenceNote,
): Type_index_sequenceNote => {
    const { date, ...rest } = sequenceNote;

    return {
        ...rest,
        date: formatDateTimeForFront(date)!,
    };
};

export const formatterCreateSequenceNote = (
    sequenceNote: Type_post_sequenceNote,
): Type_sch_post_sequenceNote => {
    return {
        text: sequenceNote.text,
    };
};

export const formatterUpdateSequenceNote = (
    SequenceNote: Type_put_note,
): Type_sch_put_sequenceNote => {
    return {
        text: SequenceNote.text,
    };
};
