import { AxiosRequestConfig } from "axios";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { AuthKeys } from "src/api/tms-gateway/keys";
import { UserKeys } from "src/api/tms-users/keys";
import {
    formatterAddCompanyUser,
    formatterCreateUserInvitation,
    formatterIndexUser,
    formatterIndexUsersWithMeta,
    formatterUpdateUser,
} from "src/api/tms-users/users/formatters";
import {
    checkToken,
    createUser,
    deleteUser,
    indexUsers,
    updateUser,
} from "src/api/tms-users/users/services";
import {
    Type_post_userInvitation,
    Type_put_user,
} from "src/api/tms-users/users/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { URL_BASE } from "src/router";
import { redirectToIdentification } from "src/utils/urlFormatter";

export const useIndexUsers = (requestParams?: AxiosRequestConfig) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [UserKeys.INDEX, requestConfig, requestParams],
        queryFn: ({ signal }) =>
            indexUsers(requestConfig, requestParams, signal),
        refetchOnWindowFocus: false,
        select: (res) => {
            if (!res?.success || !res?.data) {
                throw new Error("Wrong format data: useIndexUsers");
            }
            return formatterIndexUsersWithMeta(res.data);
        },
    });
};

export const mutationStatusUser = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (newStatus: any) => {
            return updateUser(newStatus.id, {
                enabled: newStatus.enabled,
            });
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationStatusUser");
            }

            await queryClient.invalidateQueries([
                UserKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateUser = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.UserInformation",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (user: Type_put_user) => {
            return updateUser(user.id, formatterUpdateUser(user));
        },
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateUser");
            }

            await queryClient.invalidateQueries([AuthKeys.ME]);

            addSuccess({
                description: fmt("ToastSuccess"),
            });
        },
        onError: (err: any) => {
            console.log(err);
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCreateUserInvitation = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.UserInvitation",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (user: Type_post_userInvitation) => {
            return createUser(formatterCreateUserInvitation(user));
        },
        onSuccess: async (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: mutationCreateUserInvitation",
                );
            }

            const newUser = formatterIndexUser(data.data.data);
            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        user: newUser.email,
                    },
                }),
            });
            await queryClient.invalidateQueries([
                UserKeys.INDEX,
                requestConfig,
            ]);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteUser = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.UserInvitation",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();

    return useMutation({
        mutationFn: (id: number) => {
            return deleteUser(id);
        },
        onSuccess: async (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationDeleteUser");
            }

            await queryClient.invalidateQueries([
                UserKeys.INDEX,
                requestConfig,
            ]);

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationAddCompanyUser = (userId: number) => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const urlParams = useParams();

    return useMutation({
        mutationFn: (companyId: number) => {
            return updateUser(userId, formatterAddCompanyUser(companyId));
        },
        onSuccess: async (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationAddCompanyUser");
            }
            await queryClient.invalidateQueries([AuthKeys.ME]);

            navigate(generatePath(`${URL_BASE}/projects`, { ...urlParams }), {
                replace: true,
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationCheckToken = () => {
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (token: string) => {
            return checkToken(token);
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            redirectToIdentification();
            return err;
        },
    });
};
