import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_StackCGU = styled(Stack)(({ theme }: any) => ({
    overflow: "auto",
    height: "65vh",
    [theme.breakpoints.up("lg")]: {
        width: "45vw",
    },
    [theme.breakpoints.down("lg")]: {
        width: "90vw",
    },
    margin: "auto",
}));
