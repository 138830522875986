import { useMutation, useQuery } from "react-query";

import { ProgressHistoryKeys } from "src/api/tms-scheduling/keys";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

import {
    formatterCreateProgressHistory,
    formatterIndexProgressHistories,
    formatterShowProgressHistory,
    formatterUpdateProgressHistory,
} from "./formatters";
import {
    createProgressHistory,
    deleteProgressHistory,
    indexProgressHistory,
    showProgressHistory,
    updateProgressHistory,
} from "./services";
import {
    Type_post_progressHistory,
    Type_put_progressHistory,
    Type_sch_index_progressHistory,
    Type_sch_show_progressHistory,
    Type_show_progressHistory,
} from "./types";

export const useShowProgressHistory = (id: number) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [ProgressHistoryKeys.SHOW, requestConfig, id],
        queryFn: () => showProgressHistory(id, requestConfig),
        refetchOnWindowFocus: false,
        select: (data): Type_show_progressHistory => {
            const progressHistory = data?.data
                ?.data as Type_sch_show_progressHistory;
            return formatterShowProgressHistory(progressHistory);
        },
        enabled: !!requestConfig.projectId && !!id,
    });
};

export const useIndexProgressHistory = (
    taskAreaId: number,
    enabled?: boolean,
    uniqueId?: string,
    staleTime?: number,
) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [
            ProgressHistoryKeys.INDEX,
            requestConfig,
            taskAreaId,
            uniqueId,
            requestConfig,
        ],
        queryFn: () => indexProgressHistory({ ...requestConfig, taskAreaId }),
        refetchOnWindowFocus: false,
        select: (data) => {
            const progressHistories: Type_sch_index_progressHistory[] =
                data.data.data;
            return formatterIndexProgressHistories(progressHistories);
        },
        enabled: enabled && !!taskAreaId,
        staleTime: staleTime,
    });
};

export const mutationCreateProgressHistory = () => {
    const { requestConfig } = useProject();
    return useMutation({
        mutationFn: (newProgressHistory: Type_post_progressHistory) =>
            createProgressHistory(
                formatterCreateProgressHistory(newProgressHistory),
                requestConfig,
            ),
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationUpdateProgressHistory = (
    callback?: (ProgressHistory: any) => void,
) => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Form.Error");
    const { addWarning } = useToast();
    return useMutation({
        mutationFn: (progressHistory: Type_put_progressHistory) => {
            return updateProgressHistory(
                formatterUpdateProgressHistory(progressHistory),
                progressHistory.id,
                requestConfig,
            );
        },
        onSuccess: (
            resp,
            _progressHistoryToUpdate: Type_put_progressHistory,
        ) => {
            const progressHistory: Type_sch_show_progressHistory =
                resp.data.data;
            if (callback) {
                callback(formatterShowProgressHistory(progressHistory));
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("ErrorGeneric"),
            });
            return err;
        },
    });
};

export const mutationDeleteProgressHistory = (
    callback?: (id: number) => void,
) => {
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    return useMutation({
        mutationFn: (id: number) => deleteProgressHistory(id, requestConfig),
        onSuccess: (_data, id) => {
            if (callback) {
                callback(id);
            }
        },
        onError: (err: any) => {
            addWarning({ description: fmtError("GenericError", {}) });
            console.error(fmtError("GenericError", {}), err);
            return err;
        },
    });
};
