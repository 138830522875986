import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { useWatch } from "react-hook-form";
import * as Yup from "yup";

import { mutationUpdatePlatformSetting } from "src/api/tms-commons/platformSettings";
import { Type_show_platformSettings } from "src/api/tms-commons/platformSettings/types";
import { languages as languagesList } from "src/assets/translations";
import { LoadingBox } from "src/components";
import { AutocompleteFreeSolo } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSolo/AutocompleteFreeSolo";
import {
    Select,
    Type_SelectOptionItem,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useFormDefaultConfig } from "src/configurations/app";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_PlatformSettingsForm = {
    onClose: () => void;
    platformSetting?: Type_show_platformSettings;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    isFetching: boolean;
};

const Schema_PlatformSettings = Yup.lazy(() => {
    return Yup.object().shape({
        defaultLanguage: Yup.string().trim().required(FORM_ERR_FMT.REQUIRED),
        emailAddresses: Yup.array()
            .min(1, FORM_ERR_FMT.REQUIRED)
            .of(Yup.string().trim().email(FORM_ERR_FMT.MUST_BE_EMAIL))
            .required(FORM_ERR_FMT.REQUIRED),
    });
});

export const PlatformSettingsForm = ({
    onClose,
    platformSetting,
    setIsLoading,
    isFetching,
}: Type_Props_PlatformSettingsForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PlatformSettings",
    );

    const { mutateAsync: mutateUpdate } = mutationUpdatePlatformSetting() || {};

    const languages: Type_SelectOptionItem[] = Object.entries(
        languagesList,
    ).map(([key, value]) => {
        return { value: key, label: value as string };
    });

    const form = useTmcForm<Type_show_platformSettings>({
        ...useFormDefaultConfig,
        defaultValues: {
            emailAddresses: [],
            defaultLanguage: undefined,
        },
        values: platformSetting,
        resolver: yupResolver<any>(Schema_PlatformSettings),
        onClose,
        setIsLoading,
    });

    const handleSubmit = async (values: any) => {
        try {
            mutateUpdate(values as Type_show_platformSettings);
            onClose();
        } catch (e: any) {
            console.error("HANDLE SUBMIT ERROR", e);
        }
    };

    const currentEmailAddresses = useWatch({
        control: form.control,
        name: "emailAddresses",
    });

    return (
        <TmcFormProvider form={form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={"platformSettings"}
            >
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack spacing={4}>
                        <Typography variant="h3">
                            {fmt(`EmailAddress`)}
                        </Typography>
                        <AutocompleteFreeSolo
                            options={currentEmailAddresses}
                            name="emailAddresses"
                            renderInputProps={{ variant: "outlined" }}
                        />
                        <Typography variant="h3">
                            {fmt(`DefaultLanguage`)}
                        </Typography>
                        <Select
                            name="defaultLanguage"
                            options={languages}
                            data-testid="PlateFormSettings-defaultLanguage"
                        />
                    </Stack>
                )}
            </form>
        </TmcFormProvider>
    );
};
