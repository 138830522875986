import { typeMilestones } from "@cimba-digital-construction-solution/utils/dist/decoder/const";
import { Vector2d } from "konva/lib/types";

import { verticalOffsetFactorPlanningTask } from "src/components/Components_Teamoty/Planning/Planning.const";
import {
    Type_Props_getCoordinates,
    Type_Props_getX,
} from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

export const getX = ({ task, widthDate, height }: Type_Props_getX): number => {
    return Math.round(
        task.beginDate.pos +
            task.beginOffset * widthDate -
            (typeMilestones.includes(task.type) ? height / 2 : 0),
    );
};

export const getCoordinates = ({
    task,
    widthDate,
    line,
    heightArea,
    height,
}: Type_Props_getCoordinates): Vector2d => {
    const verticalOffset: number =
        heightArea / verticalOffsetFactorPlanningTask;

    const y: number = Math.round(line.pos + verticalOffset);

    return { x: getX({ task, widthDate, height }), y };
};
