import {
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
} from "@mui/material";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import React, { Dispatch, ReactElement, SetStateAction } from "react";

import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_dialogParamsDeprecated = {
    open: boolean;
    action?: "create" | "update";
    id?: null | number;
};

type Type_Props_DialogGeneric = DialogProps & {
    dialogTitle: string;
    setDialogParams: Dispatch<SetStateAction<Type_dialogParamsDeprecated>>;
    formId: string;
    isFormLoading: boolean;
    children: ReactElement;
};

/**
 * @deprecated use src/components/Components_Common/Dialog/Dialog instead
 */
export const DialogGeneric = ({
    dialogTitle,
    setDialogParams,
    formId,
    isFormLoading,
    children,
    ...props
}: Type_Props_DialogGeneric) => {
    if (!props.open) return null;
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const handleClose = (
        event?: any,
        reason?: "backdropClick" | "escapeKeyDown",
    ): void => {
        if (reason !== "backdropClick") {
            setDialogParams((prevParams: Type_dialogParamsDeprecated) => ({
                ...prevParams,
                open: false,
            }));
        }
        props.onClose?.(event, reason || "backdropClick");
    };

    return (
        <MuiDialog
            {...props}
            onClose={handleClose}
            data-testid={`dialog-${formId}`}
        >
            <MuiDialogTitle
                data-testid={`dialog-${formId}-title`}
                variant={"h3"}
            >
                {dialogTitle}
                <IconButton
                    size="small"
                    onClick={handleClose}
                    data-testid={"dialog-close-btn"}
                    title={fmtActions("Close")}
                >
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent sx={{ marginBlockStart: 0 }}>
                {children}
            </MuiDialogContent>
            <MuiDialogActions>
                <TMC_Button
                    form={formId}
                    type="submit"
                    size="medium"
                    variant="contained"
                    disabled={isFormLoading}
                    startIcon={
                        !isFormLoading && <Icon variant="solid" icon="check" />
                    }
                    data-testid={`dialog-${formId}-save-btn`}
                >
                    {isFormLoading ? (
                        <Icon variant="solid" icon="spinner-third" spin />
                    ) : (
                        fmtActions("Save")
                    )}
                </TMC_Button>
            </MuiDialogActions>
        </MuiDialog>
    );
};
