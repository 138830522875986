import { Backdrop, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useMeasure from "react-use-measure";

import { useShowView } from "src/api/tms-projects/workspacesAndViews/views";
import { LoadingBox, Spinner } from "src/components";
import {
    DialogFullScreen,
    Type_props_DialogFullScreen,
} from "src/components/Components_Common/Dialog/DialogFullScreen";
import { PdfViewer } from "src/components/Components_Common/PdfViewer/PdfViewer";
import { PrintSettingsForm } from "src/forms/print/PrintSettingsForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_Layout = styled("div")({
    display: "flex",
    width: "100%",
    height: "100%",
});

const Styled_Preview = styled("div")(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.section,
    position: "relative", // Useful for backdrop position
    overflow: "hidden",
}));

const Styled_Sidebar = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "400px",
    padding: theme.spacing(2, 4, 4, 4),
    overflow: "auto",
}));

const Styled_Backdrop = styled(Backdrop)(({ theme }) => ({
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
}));

type Type_Props_PrintSettingDialog = Omit<Type_props_DialogFullScreen, "title">;

export const PrintSettingsDialog = ({
    openDialog,
    setToggleDialog,
}: Type_Props_PrintSettingDialog) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Dialog.Print");
    const { view: viewIdParam } = useParams();
    const [refDiv, bounds] = useMeasure();

    const viewId: number = Number(viewIdParam);

    const { isFetching, data: view } = useShowView(viewId);

    const [generatedFile, setGeneratedFile] = useState<string | undefined>(
        undefined,
    );
    const [isGeneratingFile, setIsGeneratingFile] = useState<boolean>(false);

    return (
        <DialogFullScreen
            openDialog={openDialog}
            setToggleDialog={setToggleDialog}
            title={fmt("Title")}
        >
            <Styled_Layout data-testid="Print-Layout">
                {isFetching && <LoadingBox />}
                {!isFetching && view && (
                    <>
                        <Styled_Preview
                            data-testid="Print-Preview"
                            ref={refDiv}
                        >
                            {generatedFile && (
                                <PdfViewer
                                    file={generatedFile}
                                    width={bounds.width - 40}
                                    height={bounds.height - 40}
                                    documentProps={{
                                        noData: "",
                                    }}
                                />
                            )}
                            <Styled_Backdrop open={isGeneratingFile}>
                                <Spinner />
                                <Typography>
                                    {fmt("BackdropLoadingMessage")}
                                </Typography>
                            </Styled_Backdrop>
                        </Styled_Preview>
                        <Styled_Sidebar data-testid="Print-Form">
                            <PrintSettingsForm
                                view={view}
                                setGeneratedFile={setGeneratedFile}
                                setIsGeneratingFile={setIsGeneratingFile}
                            />
                        </Styled_Sidebar>
                    </>
                )}
            </Styled_Layout>
        </DialogFullScreen>
    );
};
