import type { EditorOptions } from "@tiptap/core";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Color } from "@tiptap/extension-color";
import { Document } from "@tiptap/extension-document";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { FontFamily } from "@tiptap/extension-font-family";
import { Gapcursor } from "@tiptap/extension-gapcursor";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Highlight } from "@tiptap/extension-highlight";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { Link } from "@tiptap/extension-link";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Strike } from "@tiptap/extension-strike";
import { TaskItem } from "@tiptap/extension-task-item";
import { TaskList } from "@tiptap/extension-task-list";
import { Text } from "@tiptap/extension-text";
import { TextAlign } from "@tiptap/extension-text-align";
import { TextStyle } from "@tiptap/extension-text-style";
import { Underline } from "@tiptap/extension-underline";
import {
    FontSize,
    HeadingWithAnchor,
    LinkBubbleMenuHandler,
    ResizableImage,
} from "mui-tiptap";
import { useMemo } from "react";

export type UseExtensionsOptions = {
    /** Placeholder hint to show in the text input area before a user types a message. */
    placeholder?: string;
};

const CustomLinkExtension = Link.extend({
    inclusive: false,
});
/**
 * A hook for providing a default set of useful extensions for the MUI-Tiptap
 * editor.
 */
export default function useExtensions({
    placeholder,
}: UseExtensionsOptions = {}): EditorOptions["extensions"] {
    return useMemo(() => {
        return [
            BulletList,
            CodeBlock,
            Document,
            HardBreak,
            ListItem,
            OrderedList,
            Paragraph,
            Text,
            Bold,
            Blockquote,
            Code,
            Italic,
            Underline,
            Strike,
            CustomLinkExtension.configure({
                // autolink is generally useful for changing text into links if they
                // appear to be URLs (like someone types in literally "example.com"),
                // though it comes with the caveat that if you then *remove* the link
                // from the text, and then add a space or newline directly after the
                // text, autolink will turn the text back into a link again. Not ideal,
                // but probably still overall worth having autolink enabled, and that's
                // how a lot of other tools behave as well.
                autolink: true,
                linkOnPaste: true,
                openOnClick: false,
            }),
            LinkBubbleMenuHandler,

            // Extensions
            Gapcursor,
            HeadingWithAnchor,
            TextAlign.configure({
                types: ["heading", "paragraph", "image"],
            }),
            TextStyle,
            Color,
            FontFamily,
            FontSize,
            Highlight.configure({ multicolor: true }),
            HorizontalRule,

            ResizableImage,
            Dropcursor,
            TaskList,
            TaskItem.configure({
                nested: true,
            }),

            Placeholder.configure({
                placeholder,
            }),

            History,
        ];
    }, [placeholder]);
}
