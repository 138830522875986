import {
    DialogActions,
    DialogProps,
    IconButton,
    InputLabel,
    Box as MuiBox,
    Dialog as MuiDialog,
    DialogContent as MuiDialogContent,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import {
    TMC_Button,
    TMC_TextField,
} from "src/components/Components_Common/_MuiComponentsVariants";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

type Type_ActionModalDelete = {
    onClick: () => void;
    isLoading?: boolean;
    disabled?: boolean;
};

type Type_ConfirmDelete = {
    validationFieldValue: string;
    setValidationFieldValue: React.Dispatch<React.SetStateAction<string>>;
    validationString?: string;
    descriptionString?: string;
};

export const DELETE = "Delete";

export type Type_Props_ModalDelete = DialogProps & {
    onClose: () => void;
    item: string;
    actions: {
        onSubmit: Type_ActionModalDelete;
    };
    validationString: string;
    descriptionString?: string;
};

export const ModalDelete = ({
    onClose,
    actions,
    item,
    validationString,
    descriptionString,
    ...restProps
}: Type_Props_ModalDelete) => {
    const [validationFieldValue, setValidationFieldValue] =
        useState<string>("");
    useEffect(() => {
        if (!restProps.open) setValidationFieldValue("");
    }, [restProps.open]);
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("ModalDelete");

    return (
        <MuiDialog {...restProps}>
            <Stack alignItems={"end"} marginTop={1} marginRight={1}>
                <IconButton
                    data-testid="ModalDelete-CloseButton"
                    size="small"
                    onClick={onClose}
                    sx={{
                        color: COLORS.dark,
                    }}
                >
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            </Stack>
            <MuiDialogContent sx={{ marginTop: 0, paddingY: 0 }}>
                <Typography
                    variant="h3"
                    marginLeft={4}
                    marginBottom={2}
                    data-testid={"ModalDelete-Title"}
                >
                    {fmt("TitleDelete", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            slug: item,
                        },
                    })}
                </Typography>
                <Form
                    descriptionString={descriptionString}
                    validationString={validationString}
                    validationFieldValue={validationFieldValue}
                    setValidationFieldValue={setValidationFieldValue}
                />
            </MuiDialogContent>
            <DialogActions sx={{ paddingX: 10, paddingBottom: 4 }}>
                <TMC_Button
                    data-testid="ModalDelete-DeleteButton"
                    color="error"
                    onClick={
                        validationString?.length
                            ? validationString === validationFieldValue
                                ? actions.onSubmit.onClick
                                : () => console.log("error")
                            : actions.onSubmit.onClick
                    }
                    startIcon={<Icon variant="solid" icon="check" />}
                    disabled={
                        validationString != validationFieldValue ||
                        actions.onSubmit.disabled ||
                        actions.onSubmit.isLoading
                    }
                >
                    {fmt("CtaDelete")}
                </TMC_Button>
            </DialogActions>
        </MuiDialog>
    );
};

const Form = ({
    descriptionString,
    validationString,
    validationFieldValue,
    setValidationFieldValue,
}: Type_ConfirmDelete) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("ModalDelete");

    return (
        <MuiBox sx={{ padding: 4, paddingBottom: 0 }}>
            <Stack spacing={3}>
                <Typography variant="body1">
                    {fmt("Description", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            slug: descriptionString
                                ? descriptionString
                                : validationString,
                        },
                    })}
                </Typography>
                <InputLabel>
                    <Typography variant="body1" color="text.primary">
                        {fmt("Confirm", {
                            values: {
                                b: (chunks: string) => <b>{chunks}</b>,
                                slug: validationString,
                            },
                        })}
                    </Typography>
                </InputLabel>
                <TMC_TextField
                    data-testid={`ModalDelete-TextField`}
                    placeholder={validationString}
                    value={validationFieldValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValidationFieldValue(event.target.value);
                    }}
                />
            </Stack>
        </MuiBox>
    );
};
