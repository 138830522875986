import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { mutationRegister } from "src/api/tms-gateway/auth";
import { Type_post_register } from "src/api/tms-gateway/auth/types";
import { useIndexMetadataUsersForForm } from "src/api/tms-users/metadata";
import { Type_checkToken_user } from "src/api/tms-users/users/types";
import { TMC_Button } from "src/components";
import { PasswordRequirements } from "src/components/Components_Common/forms/PasswordRequirements";
import { MetadataFormController } from "src/components/Components_Common/forms/reactHookFormComponents/MetadataFormController/MetadataFormController";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { TextFieldPassword } from "src/components/Components_Common/forms/reactHookFormComponents/TextFieldPassword/TextFieldPassword";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Register_Footer } from "src/pages/Pages_Common/Page_Register/stylesheet";
import { redirectToIdentification } from "src/utils/urlFormatter";

const Schema_RegisterForm = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .required(FORM_ERR_FMT.REQUIRED)
        .max(255, FORM_ERR_FMT.MAX_LENGTH),
    lastName: Yup.string()
        .trim()
        .required(FORM_ERR_FMT.REQUIRED)
        .max(255, FORM_ERR_FMT.MAX_LENGTH),
    phone: Yup.string().trim().max(255, FORM_ERR_FMT.MAX_LENGTH).optional(),
    password: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .min(16)
        .matches(/[0-9]/)
        .matches(/[a-zA-Z]/)
        .matches(/^\S+$/, FORM_ERR_FMT.NO_WHITESPACE),
    passwordConfirm: Yup.string()
        .required(FORM_ERR_FMT.REQUIRED)
        .oneOf([Yup.ref("password")], FORM_ERR_FMT.PASSWORD_MISMATCH),
});

type Type_Props_RegisterForm = {
    tokenData: Type_checkToken_user;
};

export const RegisterForm = ({ tokenData }: Type_Props_RegisterForm) => {
    const [, setRequirementsComplete] = useState(false);
    const { data: metadataUsers } = useIndexMetadataUsersForForm() || {};
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Teamoty.Register.Form",
    );
    const { formatMessageWithPartialKey: fmtCta } =
        useCoreIntl("Teamoty.Register");

    const { mutateAsync: mutateRegister, isLoading } = mutationRegister();

    const handleSubmit = async (
        values: Type_post_register,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        try {
            await mutateRegister(values);
            redirectToIdentification();
        } catch (e) {
            console.log("error", e);
        }
    };

    const form = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            phone: "",
            password: "",
            passwordConfirm: "",
            email: tokenData!.email,
            company_id: tokenData!.companyId,
            role_id: tokenData!.roleId,
            metadata: null,
        },
        resolver: yupResolver<any>(Schema_RegisterForm),
    });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"register"}>
                <Stack gap={4}>
                    <Typography variant={"h5"}>
                        {fmt("Sections.Information")}
                    </Typography>
                    <TextField
                        variant={"outlined"}
                        name="email"
                        label={fmt("Labels.Email")}
                        dataTestId={"RegisterForm-email"}
                        disabled
                    />
                    <TextField
                        variant={"outlined"}
                        name="firstName"
                        label={fmt("Labels.FirstName")}
                        dataTestId={"RegisterForm-firstName"}
                    />
                    <TextField
                        variant={"outlined"}
                        name="lastName"
                        label={fmt("Labels.LastName")}
                        dataTestId={"RegisterForm-lastName"}
                    />
                    <TextField
                        variant={"outlined"}
                        name="phone"
                        label={fmt("Labels.Phone")}
                        dataTestId={"RegisterForm-phone"}
                    />
                    <Typography variant={"h5"}>
                        {fmt("Sections.Password")}
                    </Typography>
                    <TextFieldPassword
                        name="password"
                        label={fmt("Labels.Password")}
                        dataTestId="RegisterForm-password"
                    />
                    <PasswordRequirements
                        password={form.watch("password")}
                        passwordConfirm={form.watch("passwordConfirm")}
                        setIsRequirementsComplete={setRequirementsComplete}
                    />
                    <TextFieldPassword
                        name="passwordConfirm"
                        label={fmt("Labels.PasswordConfirm")}
                        dataTestId="RegisterForm-confirmPassword"
                    />
                    <Typography variant={"h5"}>
                        {fmt("Sections.Metadata")}
                    </Typography>
                    <MetadataFormController metadata={metadataUsers || []} />
                </Stack>
                <Register_Footer>
                    <TMC_Button
                        type={"submit"}
                        variant={"contained"}
                        data-testid={"RegisterForm-submitBtn"}
                        form={"register"}
                        disabled={isLoading}
                    >
                        {fmtCta("CtaCreate")}
                    </TMC_Button>
                </Register_Footer>
            </form>
        </FormProvider>
    );
};
