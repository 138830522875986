import { planningDateClass } from "@cimba-digital-construction-solution/utils/dist/class/planningDateClass";
import React from "react";

import { heightPlanningDate } from "src/components/Components_Teamoty/Planning/Planning.const";

import { Type_Props_PlanningMonthsDays } from "./PlanningDate.type";
import { PlanningDateLine } from "./PlanningDateLine";
import { PlanningQuarter } from "./PlanningQuarter";

export const getQuarters = (
    dates: Type_Props_PlanningMonthsDays,
    widthDate: number,
): [Array<React.JSX.Element>, Array<React.JSX.Element>] => {
    const elmDates: Array<React.JSX.Element> = [];
    const elmDatesLine: Array<React.JSX.Element> = [];

    let lastDate: planningDateClass = dates.days[0];
    let width: number = 0;
    dates.days.map((date: planningDateClass, index: number): void => {
        if (lastDate && lastDate.quarter != date.quarter) {
            elmDates.push(
                <PlanningQuarter
                    key={"d" + index}
                    height={heightPlanningDate}
                    width={width + widthDate}
                    date={lastDate}
                />,
            );
            elmDatesLine.push(
                <PlanningDateLine
                    key={"dl" + index}
                    height={heightPlanningDate}
                    width={width}
                    date={lastDate}
                />,
            );
            lastDate = date;
            width = 0;
        } else {
            width += widthDate;
        }
    });

    if (width) {
        elmDates.push(
            <PlanningQuarter
                key={"d" + dates.days.length}
                height={heightPlanningDate}
                width={width}
                date={lastDate}
            />,
        );
        elmDatesLine.push(
            <PlanningDateLine
                key={"dl" + dates.days.length}
                height={heightPlanningDate}
                width={width}
                date={lastDate}
            />,
        );
    }

    return [elmDates, elmDatesLine];
};
