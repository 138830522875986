import { Collapse, Typography } from "@mui/material";
import { ReactNode } from "react";

import { Digit } from "src/components/Components_Common";
import { Icon } from "src/components/Components_Common/Icon/Icon";

import {
    Styled_BoxCategoryCollapse,
    Styled_StackCategoryCollapse,
    Styled_StackLabelCategoryCollapse,
} from "./CategoryCollapse.style";

type Type_CategoryCollapseComponentProps = {
    checked: boolean;
    handleCollapse: () => void;
    label: string;
    onClick: () => void;
    children: ReactNode;
    color: string;
    digitValue: number;
};

export const CategoryCollapseComponent = ({
    checked,
    handleCollapse,
    label,
    onClick,
    children,
    color,
    digitValue,
}: Type_CategoryCollapseComponentProps) => {
    const labelLowerCase: string = label.toLowerCase();

    return (
        <Styled_BoxCategoryCollapse ownerState={{ color }}>
            <Styled_StackCategoryCollapse>
                <Styled_StackLabelCategoryCollapse
                    onClick={handleCollapse}
                    columnGap={1}
                >
                    <Icon
                        variant="solid"
                        icon={checked ? "chevron-down" : "chevron-up"}
                    />
                    <Typography
                        color={color}
                        variant="body1Bold"
                        data-testid={`CategoryCollapse-Title-digit`}
                    >
                        {label}
                    </Typography>
                    {!!digitValue && (
                        <Digit
                            value={digitValue}
                            isFocus={false}
                            data-testid={`CategoryCollapse-digit`}
                        />
                    )}
                </Styled_StackLabelCategoryCollapse>
                <Typography
                    data-testid="CategoryCollapse-selectAllInCategory"
                    variant="body3Underline"
                    onClick={onClick}
                    color={color}
                >
                    All {labelLowerCase}
                </Typography>
            </Styled_StackCategoryCollapse>
            <Collapse in={checked}>{children}</Collapse>
        </Styled_BoxCategoryCollapse>
    );
};
