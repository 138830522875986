import { Type_planningProps } from "src/components/Components_Teamoty/Planning/Dates/PlanningDates";
import { Type_planningContext } from "src/contexts/planning";

type Type_id = { id: number };

const createActionHandler =
    (action: string) =>
    <T extends Type_id>(_context: Type_planningContext, _props: T) =>
        console.log(action);

const actionsPlanningAreas = {
    editArea: createActionHandler("Edit area"),
    duplicateArea: createActionHandler("Duplicate area"),
    hideArea: createActionHandler("Hide area"),
    statusArea: createActionHandler("Change status area"),
    insertAboveArea: createActionHandler("Insert above area"),
    insertBelowArea: createActionHandler("Insert below area"),
};

const actionsPlanningTimeline = {
    addAKeyDate: (context: Type_planningContext, props: Type_planningProps) => {
        const { toggleDialog } = context;
        toggleDialog("keyDate", props);
    },
    addADayOff: (context: Type_planningContext, props: Type_planningProps) => {
        const { toggleDialog } = context;
        toggleDialog("daysOff", props);
    },
    editAKeyDate: (
        context: Type_planningContext,
        props: Type_planningProps,
    ) => {
        const { toggleDialog } = context;
        toggleDialog("keyDate", props);
    },
};

const actionsPlanningEmptyGrid = {
    createNewTask: createActionHandler("Create new task"),
};

export const actions = {
    ...actionsPlanningAreas,
    ...actionsPlanningTimeline,
    ...actionsPlanningEmptyGrid,
};
