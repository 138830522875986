import * as React from "react";
import { ReactNode } from "react";

import { formatterLanguages } from "src/api/tms-projects/languages/formatters";
import { languagesList as languagesListDefault } from "src/assets/translations";
import { InputMultiLanguagesComponent } from "src/components/Components_Common/forms/reactHookFormComponents/InputMultiLanguages/InputMultiLanguages.component";
import { DEFAULT_LANGUAGE } from "src/contexts/languages";
import { useProject } from "src/contexts/project";

export type Type_Props_InputMultiLanguages = {
    name: string;
    label?: string | ReactNode;
};

export const InputMultiLanguages = ({
    ...props
}: Type_Props_InputMultiLanguages) => {
    const {
        isLoading,
        requestConfig,
        projectLanguages,
        projectDefaultLanguage,
    } = useProject();

    return (
        <>
            {!isLoading && !requestConfig.projectId ? (
                <InputMultiLanguagesComponent
                    {...props}
                    languagesList={languagesListDefault}
                    defaultLanguage={DEFAULT_LANGUAGE}
                />
            ) : (
                <InputMultiLanguagesComponent
                    {...props}
                    languagesList={formatterLanguages(projectLanguages!)}
                    defaultLanguage={projectDefaultLanguage!}
                />
            )}
        </>
    );
};
