import { PeriodToggleEnum } from "@cimba-digital-construction-solution/utils/dist/filters/planningFilters";
import {
    Box,
    Grid,
    styled,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { DateRangePicker } from "src/components/Components_Common/forms/reactHookFormComponents/DateRangePicker/DateRangePicker";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { SelectPrintTimelineType } from "src/components/Components_Teamoty/forms/select/SelectPrintTimelineType";
import { useCoreIntl } from "src/hooks/useCoreIntl";

const Styled_ToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "&.MuiToggleButtonGroup-root": {
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadiusLarge,
        "& .MuiToggleButton-root": {
            border: "none",
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.text.primary,
            fontSize: 14,
            height: 36,
            margin: theme.spacing(1),
            textWrap: "nowrap",
            "&.Mui-selected": {
                backgroundColor: theme.palette.common.blue,
                borderRadius: theme.shape.borderRadius,
                color: theme.palette.common.white,
            },
            "&.MuiToggleButton-root": {
                borderRadius: theme.shape.borderRadius,
            },
        },
    },
})) as typeof ToggleButtonGroup;

type Type_Props_PlanningFilterPeriod = {
    name: string;
};

export const PlanningFilterPeriod = ({
    name,
}: Type_Props_PlanningFilterPeriod) => {
    const { setValue, unregister } = useFormContext();

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerFilters.Period",
    );

    const period = useWatch({
        name,
    });

    const [selectedPeriod, setSelectedPeriod] = useState<PeriodToggleEnum>(
        PeriodToggleEnum.FULL,
    );

    useEffect(() => {
        setSelectedPeriod(period.type);
    }, [period.type]);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        period: PeriodToggleEnum,
    ) => {
        setValue("period.type", period);
        setSelectedPeriod(period);

        // Unregister fields when switching periods
        if (period !== PeriodToggleEnum.TIME_RANGE) {
            unregister(`${name}.dateRange`);
        }

        if (period !== PeriodToggleEnum.DYNAMIC_PERIODE) {
            unregister(`${name}.from_number`);
            unregister(`${name}.to_number`);
            unregister(`${name}.from_timeframe`);
            unregister(`${name}.to_timeframe`);
        }
    };

    return (
        <>
            <Styled_ToggleButtonGroup
                sx={{
                    mb: 3,
                }}
                fullWidth
                color="primary"
                value={selectedPeriod}
                exclusive
                onChange={handleChange}
                aria-label="period"
            >
                <ToggleButton value={PeriodToggleEnum.FULL}>
                    {fmt("Full")}
                </ToggleButton>
                <ToggleButton value={PeriodToggleEnum.TIME_RANGE}>
                    {fmt("TimeRange")}
                </ToggleButton>
                <ToggleButton value={PeriodToggleEnum.DYNAMIC_PERIODE}>
                    {fmt("DynamicPeriod")}
                </ToggleButton>
            </Styled_ToggleButtonGroup>
            {selectedPeriod === PeriodToggleEnum.TIME_RANGE && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DateRangePicker
                            name={`${name}.dateRange`}
                            startLabel={fmt("StartDate")}
                            endLabel={fmt("EndDate")}
                            data-testid="Planning-filter-timeRange-dateRange"
                        />
                    </Grid>
                </Grid>
            )}
            {selectedPeriod === PeriodToggleEnum.DYNAMIC_PERIODE && (
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                display={"flex"}
                                alignItems={"center"}
                            >
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        {fmt("From")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        type="number"
                                        name={`${name}.to_number`}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <SelectPrintTimelineType
                                        name={`${name}.to_timeframe`}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                display={"flex"}
                                alignItems={"center"}
                            >
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        {fmt("To")}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                    display={"flex"}
                                    alignItems={"center"}
                                >
                                    <TextField
                                        type="number"
                                        name={`${name}.from_number`}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <SelectPrintTimelineType
                                        name={`${name}.from_timeframe`}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};
