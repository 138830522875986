import { ListItemIcon, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import React, { ReactElement, useState } from "react";

import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon as TMC_Icon } from "src/components/Components_Common/Icon/Icon";

export type Type_ActionMenu_Item = {
    key: string;
    label: (params: any) => string;
    action?: (params: any) => void;
    color?: string;
    icon?: ReactElement;
    show?: boolean;
    disabled?: (params: any) => boolean;
};

type Type_DataGridDropDown_Props = {
    name: string;
    params: GridRenderCellParams;
    items: Type_ActionMenu_Item[];
};

export const DataGridDropDown = ({
    params,
    items,
    name,
}: Type_DataGridDropDown_Props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                data-testid={`ellipsis-${name}-dropdown`}
                size="small"
                onClick={handleClick}
            >
                <TMC_Icon
                    icon="ellipsis-vertical"
                    variant="solid"
                    fontSize="small"
                />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items
                    .filter((item) => item.show === undefined || item.show)
                    .map((item, index) => {
                        return (
                            <MenuItem
                                disabled={item.disabled?.(params)}
                                data-testid={`dropdown-item-${name}-${item.key}`}
                                key={index}
                                onClick={() => {
                                    handleClose();
                                    item.action?.(params);
                                }}
                            >
                                <ListItemIcon sx={{ color: item.color }}>
                                    {item.icon}
                                </ListItemIcon>
                                <Typography color={"inherit"} variant="body1">
                                    {item.label(params)}
                                </Typography>
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
};
