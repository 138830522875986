import { Components } from "@mui/material";

import { COLORS } from "src/theme/stylesheet";

export const MuiSwitchTheme: Components["MuiSwitch"] = {
    styleOverrides: {
        root: {
            width: 40,
            height: 24,
            padding: 0,
            "& .MuiSwitch-switchBase": {
                padding: 0,
                margin: 2,
                transitionDuration: "300ms",
                "&.Mui-checked": {
                    transform: "translateX(16px)",
                    color: "#fff",
                    "& + .MuiSwitch-track": {
                        opacity: 1,
                        border: 0,
                        backgroundColor: COLORS.teamotyBlue500,
                    },
                    "&.Mui-disabled + .MuiSwitch-track": {
                        opacity: 0.5,
                    },
                    "& .MuiSwitch-thumb": {
                        transitionDuration: "100ms",
                        boxShadow: "0px 0px 2px 0px rgba(25, 49, 84, 0.20)",
                        backgroundColor: COLORS.teamotyBlue500,
                        border: `6px solid ${COLORS.white}`,
                        boxSizing: "border-box",
                        width: 20,
                        height: 20,
                    },
                },
            },
            "& .MuiSwitch-thumb": {
                transitionDuration: "100ms",
                boxShadow: "0px 0px 2px 0px rgba(25, 49, 84, 0.20)",
                backgroundColor: COLORS.white,
                boxSizing: "border-box",
                width: 20,
                height: 20,
            },
            "& .MuiSwitch-track": {
                boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset",
                backgroundColor: COLORS.moon200,
                borderRadius: 26 / 2,
                opacity: 1,
            },
        },
    },
};
