import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ProjectTypeKeys } from "src/api/tms-commons/keys";
import {
    formatterIndexProjectType,
    formatterIndexProjectTypes,
    formatterPostProjectType,
    formatterPutProjectType,
    formatterSelectListProjectTypes,
    formatterShowProjectType,
} from "src/api/tms-commons/projectTypes/formatters";
import {
    createProjectType,
    deleteProjectType,
    indexProjectTypes,
    selectListProjectTypes,
    showProjectType,
    statusProjectType,
    updateProjectType,
} from "src/api/tms-commons/projectTypes/services";
import {
    Type_index_projectType,
    Type_show_projectType,
} from "src/api/tms-commons/projectTypes/types";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexProjectTypes = () => {
    return useQuery({
        queryKey: [ProjectTypeKeys.INDEX],
        queryFn: indexProjectTypes,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (data.success) {
                return formatterIndexProjectTypes(data?.data?.data);
            }
        },
    });
};

export const mutationCreateProjectType = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectTypes",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newProjectType: Type_show_projectType) => {
            return createProjectType(formatterPostProjectType(newProjectType));
        },
        onSuccess: async (data) => {
            if (data.success) {
                const projectType = formatterIndexProjectType(data?.data?.data);

                await queryClient.invalidateQueries(ProjectTypeKeys.INDEX);

                addSuccess({
                    description: fmt("ToastSuccessCreate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            projectType: projectType.name,
                        },
                    }),
                });
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useShowProjectType = (projectTypeIdToUpdate: number) => {
    return useQuery({
        queryKey: [ProjectTypeKeys.SHOW, projectTypeIdToUpdate],
        queryFn: () => showProjectType(projectTypeIdToUpdate),
        enabled: !!projectTypeIdToUpdate,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: useShowMetadataProject");
            }

            return formatterShowProjectType(data.data.data);
        },
        onError: (err) => {
            console.error(err);
        },
    });
};

export const mutationUpdateProjectType = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectTypes",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (projectTypeToUpdate: Type_show_projectType) => {
            return updateProjectType(
                formatterPutProjectType(projectTypeToUpdate),
            );
        },
        onSuccess: async (data) => {
            if (data.success) {
                const projectType = formatterIndexProjectType(data?.data?.data);

                await queryClient.invalidateQueries(ProjectTypeKeys.INDEX);

                addSuccess({
                    description: fmt("ToastSuccessCreate", {
                        values: {
                            b: (chunks: string) => <b>{chunks}</b>,
                            projectType: projectType.name,
                        },
                    }),
                });
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteProjectType = () => {
    const queryClient = useQueryClient();
    const { addSuccess, addWarning } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectTypes",
    );
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (id: number) => deleteProjectType(id),
        onSuccess: async () => {
            await queryClient.invalidateQueries(ProjectTypeKeys.INDEX);

            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationStatusProjectType = () => {
    const queryClient = useQueryClient();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addWarning } = useToast();

    return useMutation({
        mutationFn: (
            projectType: Pick<Type_index_projectType, "enabled" | "id">,
        ) => {
            return statusProjectType(
                {
                    enabled: projectType.enabled,
                },
                projectType.id,
            );
        },
        onSuccess: async (data) => {
            if (data?.success) {
                await queryClient.invalidateQueries(ProjectTypeKeys.INDEX);
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const useSelectListProjectTypes = () => {
    return useQuery({
        queryKey: [ProjectTypeKeys.SELECT_LIST],
        queryFn: selectListProjectTypes,
        refetchOnWindowFocus: false,
        select: (data) => {
            return formatterSelectListProjectTypes(data?.data?.data);
        },
    });
};
