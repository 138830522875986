// Penser à bien importer celui de @mui/material/styles, et non juste celui de @mui/styles
import { FormControlProps } from "@mui/base";
import { FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "src/theme/stylesheet";

interface Styled_RoundedFormControlProps extends FormControlProps {
    ownerState?: {
        error?: boolean;
        disabled?: boolean;
        focused?: boolean;
        isRtl?: boolean;
        isLtrInput?: boolean;
    };
}

// Utilisé pour le TextField (TMC_TextFieldWithUnit) de format "rounded"
export const Styled_RoundedFormControl = styled(
    FormControl,
)<Styled_RoundedFormControlProps>(({ theme, ownerState }) => ({
    border: `1px solid ${COLORS.gray1}`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadiusLarge,
    boxShadow: "0px 1px 1px rgba(25, 49, 84, 0.1)",
    ...(ownerState?.error && {
        borderColor: COLORS.inputAlert,
    }),
    ...(ownerState?.disabled && {
        backgroundColor: COLORS.grayDisabled,
    }),
    ...(ownerState?.focused &&
        !ownerState?.error && {
            borderColor: COLORS.electricBlue,
        }),
    direction: ownerState?.isRtl ? "rtl" : "ltr",
    "& input": {
        direction: ownerState?.isLtrInput ? "ltr" : "rtl",
    },
}));
