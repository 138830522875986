import { Skeleton } from "@mui/material";

import { useSelectListSequenceFolders } from "src/api/tms-scheduling/sequenceFolders";
import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";

export const SelectSequenceFolder = ({ name, ...props }: any) => {
    const { data, isFetching } = useSelectListSequenceFolders();

    return (
        <>
            {isFetching && <Skeleton variant="rounded" height={32} />}
            {!isFetching && (
                <Select
                    name={name}
                    options={data}
                    {...props}
                    variant={"standard"}
                />
            )}
        </>
    );
};
