import { componentsTaskPlanning } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.const";
import { Conv_typePlanningTask } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.enum";
import { Type_Props_PlanningShape } from "src/components/Components_Teamoty/Planning/Shape/PlanningShape.type";

export const PlanningShape = ({
    task,
    line,
    widthDate,
    heightArea,

    selected,
    setSelected,

    getBeginDate,
    getEndDate,
}: Type_Props_PlanningShape) => {
    const typeShape = Conv_typePlanningTask[
        task.type
    ] as keyof typeof componentsTaskPlanning;

    const SpecificShape = componentsTaskPlanning[typeShape];

    return (
        <SpecificShape
            task={task}
            line={line}
            heightArea={heightArea}
            widthDate={widthDate}
            selected={selected}
            setSelected={setSelected}
            getBeginDate={getBeginDate}
            getEndDate={getEndDate}
        />
    );
};
