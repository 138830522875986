import { Typography } from "@mui/material";
import React from "react";

import { NewPasswordForm } from "src/forms/password/NewPasswordForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Styled_LoginContainer } from "src/pages/Pages_Common/Page_Identification/Page_Identification";

export const Page_NewPassword = () => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("NewPassword");

    return (
        <Styled_LoginContainer data-testid="NewPassword-form">
            <Typography variant="h1" marginBottom={4}>
                {fmt("Title")}
            </Typography>
            <Typography variant="body1" marginBottom={4}>
                {fmt("Desc")}
            </Typography>
            <NewPasswordForm />
        </Styled_LoginContainer>
    );
};
