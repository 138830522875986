import { Components } from "@mui/material";

export const MuiIconTheme: Components["MuiIcon"] = {
    styleOverrides: {
        root: () => ({
            "&.MuiIcon-fontSizeLarge": {
                minWidth: "24px",
                fontSize: "22px",
            },
            "&.MuiIcon-fontSizeMedium": {
                minWidth: "20px",
                fontSize: "18px",
            },
            "&.MuiIcon-fontSizeSmall": {
                minWidth: "16px",
                fontSize: "13px !important",
            },
        }),
    },
};
