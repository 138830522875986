import {
    DialogProps,
    Dialog as MuiDialog,
    DialogContent as MuiDialogContent,
    Stack,
    useTheme,
} from "@mui/material";
import React from "react";

import { LINK_DIRECTION } from "src/api/tms-scheduling/taskLinks/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { LinkForm } from "src/forms/link/LinkForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_Link = DialogProps & {
    handleClose: () => void;
    linkId?: number;
    direction?: LINK_DIRECTION;
    defaultTaskForAutocomplete?: { id: number; name: string };
};

export const Link = ({
    handleClose,
    linkId,
    direction,
    defaultTaskForAutocomplete,
    ...props
}: Type_Props_Link) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Planning.Modal.Link",
    );

    const theme = useTheme();

    const closeDialog = (reason: string) => {
        if (reason !== "backdropClick") {
            handleClose();
        }
    };

    return (
        <MuiDialog
            {...props}
            onClose={(_event, reason) => {
                closeDialog(reason);
            }}
            fullWidth={true}
            sx={{
                ".MuiDialog-paper": {
                    maxWidth: "640px",
                    borderRadius: theme.shape.borderRadiusSmall,
                },
            }}
        >
            <Stack
                direction={"row"}
                justifyContent={"end"}
                p={theme.spacing(2)}
            >
                <IconButton
                    size="small"
                    onClick={handleClose}
                    data-testid={"Link-Dialog-Close-btn"}
                    title={fmtActions("Close")}
                    color={"inherit"}
                >
                    <Icon variant="light" icon="xmark" />
                </IconButton>
            </Stack>
            <Stack
                direction={"row"}
                data-testid={"Link-Dialog-Title"}
                px={theme.spacing(10)}
                pb={theme.spacing(4)}
            >
                <SectionTitle
                    title={linkId ? fmt("Title.Update") : fmt("Title.Create")}
                />
            </Stack>
            <MuiDialogContent
                sx={{
                    paddingX: theme.spacing(10),
                    paddingTop: 0,
                    paddingBottom: theme.spacing(5),
                    margin: 0,
                }}
            >
                <LinkForm
                    handleClose={handleClose}
                    linkIdToUpdate={linkId}
                    direction={direction}
                    defaultTaskForAutocomplete={defaultTaskForAutocomplete}
                />
            </MuiDialogContent>
        </MuiDialog>
    );
};
