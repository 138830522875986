import { Box, Typography, useTheme } from "@mui/material";
import Highcharts from "highcharts";
import { default as HighchartsReact } from "highcharts-react-official";
import React, { ReactElement } from "react";

import { Type_pieChart } from "src/api/tms-tfe/types";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

export type Type_Props_PieGraph = {
    data: Type_pieChart;
    fmt: (key: string) => string;
    title: string;
};

export const PieGraph = ({
    data,
    fmt,
    title,
}: Type_Props_PieGraph): ReactElement => {
    const theme = useTheme();

    const options = {
        chart: {
            type: "pie",
            margin: [-150, 0, -80, 0],
        },
        title: {
            text: null,
            style: {
                opacity: 0,
            },
        },
        subtitle: {
            text: `${fmt("Total")}<br/><strong>${data.total} ${fmt(`Item.${title}`)}</strong>`,
            align: "center",
            verticalAlign: "middle",
            y: -20,
            style: {
                fontSize: "16px",
                fontWeight: "normal",
                color: "#000",
            },
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            headerFormat: null,
            pointFormat: `{point.name}: <b>{point.percentage:.0f}%</b>`,
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: "pointer",
                borderRadius: 4,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    distance: -10,
                    style: {
                        fontSize: "10px",
                    },
                    format: "{point.percentage:.0f}%",
                },
                showInLegend: true,
            },
        },
        series: [
            {
                name: { title },
                colorByPoint: true,
                innerSize: "75%",
                data: data.series.map((item) => ({
                    name: fmt(item.name),
                    y: item.y,
                    color: theme?.chart?.[item.name],
                })),
            },
        ],
    };

    return (
        <Box sx={{ width: "100%" }} data-testid={`PieGraph-${title}-container`}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                    variant="h3"
                    component="div"
                    data-testid={`PieGraph-${title}-title`}
                >
                    {fmt(`Title.${title}`)}
                    <IconButton
                        data-testid={`PieGraph-${title}-access-to`}
                        size="small"
                        onClick={() => {}}
                    >
                        <Icon
                            fontSize="small"
                            variant="solid"
                            icon="arrow-up-right-from-square"
                        />
                    </IconButton>
                </Typography>
            </Box>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                data-testid={`PieGraph-${title}-chart`}
            />
        </Box>
    );
};
