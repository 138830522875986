import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_RequestConfig } from "src/api/fetch";
import { TaskNoteKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskNote,
    formatterIndexTaskNotes,
    formatterUpdateTaskNote,
} from "src/api/tms-scheduling/taskNotes/formatters";
import {
    createTaskNote,
    deleteTaskNote,
    indexTaskNotes,
    updateTaskNote,
} from "src/api/tms-scheduling/taskNotes/services";
import {
    Type_post_taskNote,
    Type_sch_index_taskNote,
} from "src/api/tms-scheduling/taskNotes/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { Type_put_note } from "src/forms/notes/NoteForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskNotes = (taskConfig: Type_RequestConfig) => {
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskConfig };

    return useQuery({
        queryKey: [TaskNoteKeys.INDEX, config],
        queryFn: () => indexTaskNotes(config),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: useIndexTaskNotes");
            }
            return formatterIndexTaskNotes(
                data.data.data as Type_sch_index_taskNote[],
            );
        },
        enabled:
            !!config.projectId &&
            !!config.subProjectId &&
            !!config.versionId &&
            !!config.taskId,
    });
};

export const mutationCreateTaskNote = (taskConfig: Type_RequestConfig) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const config = { ...requestConfig, ...taskConfig };

    return useMutation({
        mutationFn: (newTaskNote: Type_post_taskNote) => {
            return createTaskNote(formatterCreateTaskNote(newTaskNote), config);
        },
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationCreateTaskNote");
            }
            await queryClient.invalidateQueries([TaskNoteKeys.INDEX, config]);
            addSuccess({
                description: fmt("ToastSuccessCreate"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationUpdateTaskNote = () => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();

    return useMutation({
        mutationFn: (updatedTaskNote: Type_put_note) =>
            updateTaskNote(
                updatedTaskNote.id,
                formatterUpdateTaskNote(updatedTaskNote),
                requestConfig,
            ),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateTaskNote");
            }
            addSuccess({
                description: fmt("ToastSuccessUpdate"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};

export const mutationDeleteTaskNote = (taskConfig: Type_RequestConfig) => {
    const queryClient = useQueryClient();
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Notes");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const config = { ...requestConfig, ...taskConfig };

    return useMutation({
        mutationFn: (taskNoteId: number) =>
            deleteTaskNote(taskNoteId, requestConfig),
        onSuccess: async (data) => {
            if (!data.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationDeleteTaskNote");
            }
            await queryClient.invalidateQueries([TaskNoteKeys.INDEX, config]);
            addSuccess({
                description: fmt("ToastSuccessDelete"),
            });
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            return err;
        },
    });
};
