import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { ProductTypesPath, Url } from "src/api/paths";
import { useProject } from "src/contexts/project";

import { Type_prj_post_productType, Type_prj_put_productType } from "./types";

export const indexProductTypes = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ProductTypesPath.PRODUCT_TYPES}`,
        requestConfig,
    );
};

export const createProductType = (
    data: Type_prj_post_productType,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(
        data,
        `${Url.PROJECTS}${ProductTypesPath.PRODUCT_TYPES}`,
        requestConfig,
    );
};

export const updateProductType = (
    data: Type_prj_put_productType,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return put(
        data,
        `${Url.PROJECTS}${ProductTypesPath.PRODUCT_TYPES}/${data.id}`,
        requestConfig,
    );
};

export const showProductType = (
    id: number,
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.PROJECTS}${ProductTypesPath.PRODUCT_TYPES}/${id}`,
        requestConfig,
    );
};

export const deleteProductType = (id: number): RemoveReturnType => {
    const { requestConfig } = useProject();
    return remove(
        `${Url.PROJECTS}${ProductTypesPath.PRODUCT_TYPES}/${id}`,
        requestConfig,
    );
};

export const selectListProductTypes = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(`${Url.PROJECTS}${ProductTypesPath.SELECT_LIST}`, requestConfig);
};
