import { Components } from "@mui/material";

import { COLORS } from "src/theme/stylesheet";

export const MuiDialogContentTheme: Components["MuiDialogContent"] = {
    styleOverrides: {
        root: ({ theme }: any) => ({
            ...theme.typography.body1,
            color: COLORS.moon900,
            padding: theme.spacing(4, 6),
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(4),
        }),
    },
};
