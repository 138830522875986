import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Styled_ImagePreviewSm = styled(Stack)(({ theme }) => ({
    width: "48px",
    height: "48px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: theme.shape.borderRadius,
}));

export const Styled_StackUploadImage = styled(Stack)(() => ({
    width: "120px",
    height: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
}));

export const Styled_ButtonUploadImage = styled("button")(({ theme }: any) => ({
    width: "120px",
    height: "120px",
    justifyContent: "center",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: theme.border.dashed,
    borderRadius: theme.shape.borderRadiusLarge,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    ":hover": {
        border: `1px dashed ${theme.palette.primary.dark}`,
    },
    whiteSpace: "pre-wrap",
}));

export const Styled_ImgUploadImage = styled("div")(({ theme }: any) => ({
    width: "120px",
    height: "120px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: theme.shape.borderRadiusLarge,
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    gap: 2,
}));
