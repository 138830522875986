import { styled } from "@mui/material/styles";
import { DataGridPremium } from "@mui/x-data-grid-premium";

export const Styled_Matrix = styled(DataGridPremium)(({ theme }) => ({
    ".MuiDataGrid-cell--editable": {
        color: theme.palette.text.primary,
    },
    ".MuiDataGrid-cell:not(.MuiDataGrid-cell--editable), MuiDataGrid-cell.Mui-selected:not(.MuiDataGrid-cell--editable)":
        {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.text.disabled,
        },
    ".MuiDataGrid-cell.Teamoty-dataGrid-cell-hidden": {
        backgroundColor: theme.palette.moon[50],
        color: theme.palette.moon[50],
        ".MuiSvgIcon-root": {
            opacity: 0,
        },
        "&.Mui-selected:hover": {
            opacity: 0,
        },
    },
}));
