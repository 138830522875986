import React from "react";
import { Rect } from "react-konva";

import { usePlanningTheme } from "src/components/Components_Teamoty/Planning/Planning.const";

import { Type_Props_PlanningDate } from "./PlanningDate.type";

export const PlanningWeekBackground = ({
    height,
    width,
    date,
}: Type_Props_PlanningDate) => {
    const theme = usePlanningTheme();

    return (
        <Rect
            x={date.pos}
            width={width + 1}
            height={height}
            fill={
                date.isWorkingDay
                    ? date.workDay
                        ? "transparent"
                        : theme.colorPlanningDateWeekend
                    : theme.colorPlanningDateDayOff
            }
        />
    );
};
