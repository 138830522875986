import {
    Type_com_index_areaType,
    Type_com_post_areaType,
    Type_com_put_areaType,
    Type_com_show_areaType,
    Type_index_areaType,
    Type_post_areaType,
    Type_show_areaType,
} from "src/api/tms-commons/areaTypes/types";
import { Type_api_selectList, Type_selectList } from "src/api/types";

export const formatterIndexAreaTypes = (
    areaTypes: Type_com_index_areaType[],
): Type_index_areaType[] => {
    if (!areaTypes) return [];

    return areaTypes.map((areaType: Type_com_index_areaType) =>
        formatterIndexAreaType(areaType),
    );
};

export const formatterIndexAreaType = (
    areaType: Type_com_index_areaType,
): Type_index_areaType => {
    return {
        id: areaType.id,
        enabled: areaType.enabled,
        name: areaType.name,
        projectTypesCount: areaType.projectTypesCount,
    };
};

export const formatterShowAreaType = (
    areaType: Type_com_show_areaType,
): Type_show_areaType => {
    return {
        id: areaType.id,
        names: areaType.names,
        projectTypes: areaType.projectTypes,
        projectTypesCount: areaType.projectTypesCount,
        enabled: areaType.enabled,
    };
};

export const formatterUpdateAreaType = (
    areaType: Type_show_areaType,
): Type_com_put_areaType => {
    return {
        id: areaType.id,
        names: areaType.names,
        enabled: areaType.enabled,
        projectTypes: areaType.projectTypes.map((p: any) => p.id),
    };
};
export const formatterCreateAreaType = (
    areaType: Type_post_areaType,
): Type_com_post_areaType => {
    return {
        names: areaType.names,
        projectTypes: areaType.projectTypes.map((p: any) => p.id),
    };
};

export const formatterSelectListAreaTypes = (
    areaTypes: Type_api_selectList[],
): Type_selectList[] => {
    if (!areaTypes) return [];
    return areaTypes.map((areaType: Type_api_selectList) => ({
        id: areaType.id,
        name: areaType.name,
    }));
};
