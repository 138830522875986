import {
    Type_com_index_priorityOfConstraint,
    Type_com_post_priorityOfConstraint,
    Type_com_put_priorityOfConstraint,
    Type_com_show_priorityOfConstraint,
    Type_index_priorityOfConstraint,
    Type_post_priorityOfConstraint,
    Type_put_priorityOfConstraint,
    Type_show_priorityOfConstraint,
} from "src/api/tms-commons/priorityOfConstraints/types";
import { Type_ItemChip } from "src/components/Components_Common/forms/ChipSelect/ChipSelect";

export const formatterIndexPriorityOfConstraints = (
    priorityOfConstraints: Type_com_index_priorityOfConstraint[],
): Type_index_priorityOfConstraint[] => {
    if (!priorityOfConstraints) return [];

    return priorityOfConstraints.map(
        (priorityOfConstraint: Type_com_index_priorityOfConstraint) =>
            formatterIndexPriorityOfConstraint(priorityOfConstraint),
    );
};

export const formatterIndexPriorityOfConstraint = (
    priorityOfConstraint: Type_com_index_priorityOfConstraint,
): Type_index_priorityOfConstraint => {
    return {
        id: priorityOfConstraint.id,
        name: priorityOfConstraint.name,
        color: priorityOfConstraint.color,
        sortOrder: priorityOfConstraint.sortOrder,
        enabled: priorityOfConstraint.enabled,
    };
};

export const formatterShowPriorityOfConstraint = (
    priorityOfConstraint: Type_com_show_priorityOfConstraint,
): Type_show_priorityOfConstraint => {
    return priorityOfConstraint;
};

export const formatterStatusPriorityOfConstraint = (
    priorityOfConstraint: Type_put_priorityOfConstraint,
): Type_com_put_priorityOfConstraint => {
    return {
        enabled: priorityOfConstraint.enabled,
    };
};

export const formatterUpdatePriorityOfConstraint = (
    priorityOfConstraint: Type_show_priorityOfConstraint,
): Type_com_put_priorityOfConstraint => {
    return {
        names: priorityOfConstraint.names,
        sortOrder: priorityOfConstraint.sortOrder,
        color: priorityOfConstraint.color,
        enabled: priorityOfConstraint.enabled,
    };
};

export const formatterPostPriorityOfConstraint = (
    priorityOfConstraint: Type_post_priorityOfConstraint,
): Type_com_post_priorityOfConstraint => {
    return {
        names: priorityOfConstraint.names,
        sortOrder: priorityOfConstraint.sortOrder,
        color: priorityOfConstraint.color,
    };
};

export const formatterItemPriorityOfConstraint = (
    priorityOfConstraint: Type_index_priorityOfConstraint,
): Type_ItemChip => {
    return {
        label: priorityOfConstraint.name,
        value: priorityOfConstraint.id,
        chipColor: priorityOfConstraint.color,
    };
};

export const formatterItemPriorityOfConstraints = (
    priorityOfConstraints: Type_index_priorityOfConstraint[],
): Type_ItemChip[] =>
    priorityOfConstraints.map(formatterItemPriorityOfConstraint);
