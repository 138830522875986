import { Avatar as MuiAvatar } from "@mui/material";
import { ComponentProps } from "react";

import { COLORS } from "src/theme/stylesheet";
import { stringToColor } from "src/utils/colors";

type Type_AvatarCell = ComponentProps<typeof MuiAvatar> & {
    name: string;
    enabled?: boolean;
};
export const Avatar = ({ name, enabled = true, ...props }: Type_AvatarCell) => {
    const stringInitials = (string: any): string => {
        return string
            .match(/(\b\S)?/g)
            .join("")
            .match(/(^\S|\S$)?/g)
            .join("");
    };

    const stringAvatar = (
        name: string,
        props: ComponentProps<typeof MuiAvatar>,
        enabled: boolean,
    ) => {
        const bgColor = enabled ? stringToColor(name) : COLORS.gray500;
        return {
            ...props,
            sx: {
                bgcolor: bgColor,
                ...props.sx,
            },
            children: stringInitials(name),
        };
    };

    return <MuiAvatar {...stringAvatar(name, props, enabled)} alt={name} />;
};
