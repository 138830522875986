import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

// mettre à jour setupTests.js dès qu'il y a un changement
export enum FORM_ERR_FMT {
    "REQUIRED" = "Required",
    "REQUIRED_ALL" = "RequiredAll",
    "MAX_LENGTH" = "MaxLength",
    "MIN_LENGTH" = "MinLength",
    "DATE_END_AFTER_DATE_START" = "DateEndAfterDateStart",
    "DUPLICATE" = "Duplicate",
    "TOO_LONG" = "TooLong",
    "MUST_BE_POSITIF" = "MustBePositif",
    "MUST_BE_NUMBER" = "MustBeNumber",
    "MUST_BE_EMAIL" = "MustBeEmail",
    "INTEGER" = "Integer",
    "FORMAT_FILE" = "FormatFile",
    "FORMAT_NAME_VALUE" = "FormatNameValue",
    "FORMAT_KEY_VALUE" = "FormatKeyValue",
    "RESOLUTION" = "Resolution",
    "WRONG_DATE" = "WrongDate",
    "ALREADY_EXISTS" = "AlreadyExists",
    "MIN_SELECT" = "MinSelect",
    "PASSWORD_MISMATCH" = "PasswordMismatch",
    "NO_WHITESPACE" = "NoWhitespace",
}

export const ERRS_MSG_FROM_BACK: Record<string, FORM_ERR_FMT> = {
    "The name has already been taken.": FORM_ERR_FMT.ALREADY_EXISTS,
    // Ajouter ici les autres erreurs backend avec la key correspondante
};

export const useManageErrors = () => {
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmtFormErr } =
        useCoreIntl("Form.Error");

    // Fonction générique pour extraire les erreurs backend et appliquer les mappages nécessaires
    const errorsBackFmtKeys = (err: any): string[] => {
        if (err?.data?.errors && typeof err.data.errors === "object") {
            return Object.entries(err.data.errors)
                .flatMap(([_field, messages]) =>
                    Array.isArray(messages)
                        ? messages.map(
                              (msg: string) => ERRS_MSG_FROM_BACK[msg] || null,
                          )
                        : [],
                )
                .filter(Boolean);
        }
        return [];
    };

    // Fonction pour obtenir les clés des erreurs et les formater avec useCoreIntl
    const getErrorsKeys = (err: any): string[] => {
        return errorsBackFmtKeys(err).map((msg: string) => fmtFormErr(msg));
    };

    // Génère les toasts pour les erreurs backend ou un message générique
    const generateToastErrsFromBack = (
        err: any,
        onlyGeneric: boolean = false,
    ) => {
        if (onlyGeneric) {
            addWarning({ description: fmtErr("GenericError") });
            return err;
        }

        const errorMsgs = getErrorsKeys(err);

        if (errorMsgs.length) {
            errorMsgs.forEach((msg) => addWarning({ description: msg }));
        } else {
            addWarning({ description: fmtErr("GenericError") });
        }
        return err;
    };

    // Gère les erreurs de validation et les applique aux champs de react-hook-form
    const handleFormErrors = (e: any, form: any) => {
        if (e?.data?.errors && typeof e.data.errors === "object") {
            Object.entries(e.data.errors).forEach(([field, messages]) => {
                if (Array.isArray(messages)) {
                    messages.forEach((msg: string) => {
                        const formattedMessage =
                            ERRS_MSG_FROM_BACK[msg] || fmtErr("GenericError");
                        form.setError(field, {
                            type: "manual",
                            message: formattedMessage,
                        });
                    });
                }
            });
        }
    };

    return {
        getErrorsKeys,
        generateToastErrsFromBack,
        handleFormErrors,
    };
};
