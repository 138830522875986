import { Chip, Stack, StackProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TypographyProps } from "@mui/system";

export const Styled_TypographyEmail = styled(Typography)(({ theme }) => ({
    float: "inline-start",
    color: theme?.palette?.text?.secondary,
}));

interface Type_Styled_TypographyName extends TypographyProps {
    ownerState?: {
        selected?: boolean;
    };
}

export const Styled_TypographyName = styled(
    Typography,
)<Type_Styled_TypographyName>(({ theme, ownerState }) => ({
    ...(ownerState?.selected && {
        color: theme?.palette?.primary?.main,
    }),
}));

interface Type_Styled_Stack_MainContainer extends StackProps {
    ownerState?: {
        selected?: boolean;
        canHover?: boolean;
    };
}

export const Styled_Stack_MainContainer = styled(
    Stack,
)<Type_Styled_Stack_MainContainer>(({ theme }) => ({
    flexDirection: "row",
    flexWrap: "nowrap",
    display: "flex",
    gap: theme.spacing(2),
}));

export const Styled_Chip = styled(Chip)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    maxHeight: "24px",
    color: "badge.default",
}));
