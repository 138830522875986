import { Box, DrawerProps, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

import { useShowProjectUser } from "src/api/tms-projects/projectUsers";
import { Avatar, LoadingBox, TMC_Button } from "src/components";
import { DrawerGeneric } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { SectionTitle } from "src/components/Components_Common/SectionTitle";
import { Styled_UserInformationHeader } from "src/drawers/projectUsers/stylesheet";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { stringToColor } from "src/utils/colors";

export type Type_Props_InformationDrawer = DrawerProps & {
    open: boolean;
    onClose: () => void;
    userId: number;
    page: string;
};
export const InformationDrawer = ({
    open,
    onClose,
    userId,
    page,
}: Type_Props_InformationDrawer) => {
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.ProjectUserInformation",
    );

    return (
        <DrawerGeneric
            onClose={onClose}
            open={open}
            title={fmt("Title")}
            page={page}
            customFooter={
                <TMC_Button
                    data-testid="DrawerInformation-Edit-btn"
                    variant="text"
                    onClick={() => console.log("Edit coming soon")}
                    disabled={true}
                >
                    {fmtActions("Edit")}
                </TMC_Button>
            }
        >
            <InformationDrawerContent userId={userId} />
        </DrawerGeneric>
    );
};

const InformationDrawerContent = ({ userId }: { userId: number }) => {
    const { isFetching, data } = useShowProjectUser(userId);

    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const fields = [
        { label: fmtLabels("LastName"), value: data?.lastName },
        { label: fmtLabels("FirstName"), value: data?.firstName },
        { label: fmtLabels("Email"), value: data?.email },
        { label: fmtLabels("Phone"), value: data?.phone },
        { label: fmtLabels("Company"), value: data?.companyName },
        { label: fmtLabels("Password"), value: "············" },
    ];

    return (
        <>
            {isFetching || !data ? (
                <LoadingBox />
            ) : (
                <>
                    <InformationDrawerHeader
                        fullName={`${data.firstName + " " + data.lastName}`}
                    />
                    <SectionTitle title={fmtLabels("Profile")} />
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={2}
                        paddingBottom={4}
                    >
                        {fields.map((field, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={4} md={3}>
                                    <Typography
                                        variant="body1"
                                        color={"moon.500"}
                                    >
                                        {field.label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} md={9}>
                                    <Typography variant="body1">
                                        {field.value}
                                    </Typography>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>

                    <SectionTitle title={fmtLabels("Metadata")} />
                    {data?.metadata && data?.metadata.length > 0 && (
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={2}
                            paddingBottom={4}
                        >
                            {data?.metadata.map(
                                (
                                    metadatum: { [key: string]: string },
                                    index: number,
                                ) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={4} md={3}>
                                            <Typography
                                                variant="body1"
                                                color={"moon.500"}
                                            >
                                                {metadatum.label}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} md={9}>
                                            <Typography variant="body1">
                                                {metadatum.value}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                ),
                            )}
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

const InformationDrawerHeader = ({ fullName }: { fullName: string }) => {
    const theme = useTheme();

    return (
        <Box height={160} width={"100%"} mt={4}>
            <Styled_UserInformationHeader color={stringToColor(fullName)}>
                <Avatar
                    name={fullName}
                    sx={{
                        width: 96,
                        height: 96,
                        ...theme.typography.avatar,
                        position: "absolute",
                        top: theme.spacing(9),
                        border: `4px ${theme.palette.background.paper} solid`,
                    }}
                />
            </Styled_UserInformationHeader>
        </Box>
    );
};
