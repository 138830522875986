import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { validateCgu } from "src/api/tms-users/auth/services";
import { getLocalStorageItem } from "src/utils/localStorageServices";

import { Page_CGUComponent } from "./Page_CGU.component";

export const Page_CGUContainer = () => {
    const [checked, setChecked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useLocation();
    const navigate = useNavigate();

    const approveCGU = async () => {
        try {
            setIsLoading(true);
            const lang = getLocalStorageItem("language") || "en_GB";
            await validateCgu();

            // redirige vers la page initialement voulue, ou vers l'accueil.
            navigate(history?.state?.fromTo || `/${lang}/projects`, {
                replace: true,
            });
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    };

    const handleCheck = () => setChecked((prev: boolean) => !prev);

    return (
        <Page_CGUComponent
            approveCGU={approveCGU}
            checked={checked}
            handleCheck={handleCheck}
            isLoading={isLoading}
        />
    );
};
