import React, { useMemo } from "react";

import { languages as languagesList } from "src/assets/translations";
import { MenuTreeDropdown } from "src/components/Components_Common";
import { Type_MenuItems } from "src/components/Components_Common/MenuTree/MenuTree";
import { Styled_PopoverLanguage } from "src/components/Components_Teamoty/TopBar/TopBar_Right/LanguageMenu/LanguageMenu.style";
import { useLanguage } from "src/contexts/languages";

type Type_LanguageMenuComponentProps = {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
};
export const LanguageMenu = ({
    anchorEl,
    open,
    handleClose,
}: Type_LanguageMenuComponentProps) => {
    const { switchLanguage, language, languages } = useLanguage();

    const items: Type_MenuItems[] = useMemo(() => {
        return [
            {
                parentKey: null,
                childs: Object.entries(languages).map(([key, name]) => ({
                    key: String(key),
                    label: String(languagesList[name]),
                    action: () => {
                        handleClose();
                        switchLanguage(name);
                    },
                    selected: language === name,
                })),
            },
        ];
    }, [languages, language, switchLanguage]);

    return (
        <Styled_PopoverLanguage
            anchorEl={anchorEl}
            id="language-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
            <MenuTreeDropdown items={items} />
        </Styled_PopoverLanguage>
    );
};
