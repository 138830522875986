import * as React from "react";

import { captionPosition } from "src/api/tms-print/print/types";
import {
    Select,
    Type_Props_Select,
} from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_SelectCaptionPosition = Omit<Type_Props_Select, "options">;

export const SelectCaptionPosition = ({
    ...props
}: Type_Props_SelectCaptionPosition) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Form.Field.CaptionPositions",
    );

    const options = Object.values(captionPosition).map((value) => ({
        value: value,
        label: fmt(value),
    }));

    return <Select {...props} options={options} />;
};
