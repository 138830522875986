import { Stack, Typography } from "@mui/material";
import React from "react";

import { Icon } from "src/components/Components_Common/Icon/Icon";
import { LoadingBox } from "src/components/Components_Common/index";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { Styled_StackNoData } from "./LoadingOrNoData.style";

type Type_Props_LoadingOrNoData = {
    isFetching: boolean;
    dataName: string;
    action?: any;
    hasPermission?: boolean;
    noDataFound?: boolean;
    dataTestIdRef?: string;
};

export const LoadingOrNoData = ({
    isFetching,
    dataName,
    action,
    hasPermission,
    noDataFound = false,
    dataTestIdRef,
}: Type_Props_LoadingOrNoData) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Components.LoadingOrNoData",
    );

    if (isFetching) {
        return (
            <Stack
                height="100%"
                data-testid={`Loading-Container-${dataTestIdRef}`}
            >
                <LoadingBox />
            </Stack>
        );
    }

    return (
        <Styled_StackNoData
            gap={4}
            data-testid={`NoData-Container-${dataTestIdRef}`}
        >
            <Icon
                variant="solid"
                icon="empty-set"
                sx={{ color: COLORS.moon500 }}
            />

            <Typography
                variant="body1Bold"
                color={COLORS.moon500}
                whiteSpace="pre-wrap"
            >
                {noDataFound
                    ? fmt("NoDataFoundTitle")
                    : fmt("MissingDataTitle")}
            </Typography>
            {hasPermission && (
                <Typography
                    variant="body1"
                    color={COLORS.moon500}
                    whiteSpace="pre-wrap"
                >
                    {noDataFound
                        ? fmt("NoDataFoundMessage", {
                              values: {
                                  Typography: (chunks: string) => (
                                      <Typography
                                          variant="body1Underline"
                                          color={COLORS.blue700}
                                          sx={{ cursor: "pointer" }}
                                          onClick={action}
                                          data-testid={`NoDataFoundMessage-${dataTestIdRef}-btn`}
                                      >
                                          {chunks}
                                      </Typography>
                                  ),
                                  dataName: dataName,
                              },
                          })
                        : fmt("MissingDataMessage", {
                              values: {
                                  Typography: (chunks: string) => (
                                      <Typography
                                          variant="body1Underline"
                                          color={COLORS.blue700}
                                          sx={{ cursor: "pointer" }}
                                          onClick={action}
                                          data-testid={`MissingDataMessage-${dataTestIdRef}-btn`}
                                      >
                                          {chunks}
                                      </Typography>
                                  ),
                                  dataName: dataName,
                              },
                          })}
                </Typography>
            )}
        </Styled_StackNoData>
    );
};
