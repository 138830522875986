import { Grid } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as Yup from "yup";

import { Switch } from "src/components/Components_Common/forms/reactHookFormComponents/Switch/Switch";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { SelectBreakLevel } from "src/components/Components_Teamoty/forms/select/SelectBreakLevel";
import { shouldDisplay } from "src/forms/tasks/utils";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { Section } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export const automaticOptimizationInitialData = {
    flow: false,
    flowRhythmBreak: false,
    flowRhythmLevel: 0,
    areaOptimization: false,
};
export const automaticOptimizationSchema = Yup.object().shape({
    flow: Yup.boolean(),
    flowRhythmLevel: Yup.number(),
    areaOptimization: Yup.boolean(),
});

type Type_Props_AutomaticOptimizationForm = Type_ReadonlyComponent & {
    sequenceId?: number;
};
export const AutomaticOptimizationForm = ({
    sequenceId,
    readonly,
}: Type_Props_AutomaticOptimizationForm) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.AutomaticOptimization",
    );

    const { control } = useFormContext(); // retrieve those props

    const flow = useWatch({
        control,
        name: "flow",
    });
    const flowRhythmBreak = useWatch({
        control,
        name: "flowRhythmBreak",
    });
    const taskType = useWatch({
        name: "type",
        control: control,
    });

    const isFlowAvailable = useMemo(() => {
        const shouldDisplayFlow = shouldDisplay({
            name: "flow",
            taskType: taskType as number,
        });

        return shouldDisplayFlow && flow;
    }, [flow, taskType]);

    return (
        <Section>
            <Grid container spacing={2}>
                {shouldDisplay({
                    name: "flow",
                    taskType: taskType as number,
                }) && (
                    <Grid item xs={12}>
                        <Switch
                            name="flow"
                            label={fmt("OptimizedFlow")}
                            labelPlacement="end"
                            data-testid={"Form-Switch-flow"}
                            readonly={readonly}
                        />
                    </Grid>
                )}
                {isFlowAvailable && (
                    <>
                        <Grid item xs={12}>
                            <Switch
                                name="flowRhythmBreak"
                                label={fmt("BreakRhythm")}
                                labelPlacement="end"
                                data-testid={"Form-Switch-flowRhythmBreak"}
                                readonly={readonly}
                            />
                        </Grid>
                        {flowRhythmBreak && (
                            <Grid item xs={12}>
                                <SelectBreakLevel
                                    name="flowRhythmLevel"
                                    label={fmt("BreakLevel")}
                                    size="small"
                                    sequenceId={sequenceId}
                                    data-testid={"Form-Select-flowRhythmLevel"}
                                    autoSelectFirstItem={true}
                                    readonly={readonly}
                                />
                            </Grid>
                        )}
                    </>
                )}
                <Grid item xs={12}>
                    <Switch
                        name="areaOptimization"
                        label={fmt("AutoOptimization")}
                        labelPlacement="end"
                        data-testid={"Form-Switch-areaOptimization"}
                        readonly={readonly}
                    />
                </Grid>
            </Grid>
        </Section>
    );
};
