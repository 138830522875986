import { Tooltip } from "@mui/material";
import React, { memo } from "react";

import { Avatar } from "src/components/Components_Common";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_AvatarMenuButton = {
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const AvatarMenuButton = memo(function AvatarMenuButton({
    handleClick,
}: Type_Props_AvatarMenuButton) {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("TopBar.User");
    const { getFullName } = useUser();

    return (
        <Tooltip title={fmt("Entry")}>
            <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                sx={{ p: 0, m: 0 }}
                data-testid="AvatarMenu-open-btn"
                onClick={handleClick}
                aria-controls={"account-menu"}
            >
                <Avatar
                    name={getFullName()}
                    sx={{
                        width: 32,
                        height: 32,
                        fontSize: "12px",
                        letterSpacing: "1px",
                    }}
                />
            </IconButton>
        </Tooltip>
    );
});
