import { Components } from "@mui/material";

import { COLORS } from "src/theme/stylesheet";

export const MuiTooltipTheme: Components["MuiTooltip"] = {
    defaultProps: { disableInteractive: true },
    styleOverrides: {
        tooltip: ({ theme }: any) => ({
            backgroundColor: COLORS.moon800,
            borderRadius: theme.shape.borderRadiusSmall,
            padding: theme.spacing(2, 4),
            maxWidth: theme.spacing(60),
        }),
    },
};
