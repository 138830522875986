import DoneIcon from "@mui/icons-material/Done";
import { TabList } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, InputAdornment, Stack, Typography } from "@mui/material";
import React, { memo, SyntheticEvent, useMemo } from "react";
import { useFormContext, UseFormSetValue } from "react-hook-form";

import { Type_usr_post_role } from "src/api/tms-users/rolesAndPermissions/types";
import {
    CollapseBox,
    Digit,
    TMC_Badge,
    TMC_FormHelperText,
    TMC_TextField,
} from "src/components/Components_Common";
import { DrawerLabel } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    Type_category,
    Type_permission,
    Type_permissionsSelectConfiguration,
    Type_project,
    Type_subproject,
} from "src/configurations/permissionsSelect.configuration";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { COLORS } from "src/theme/stylesheet";

import { CategoryCollapseContainer } from "./CategoryCollapse/CategoryCollapse.container";
import {
    Styled_TabButton,
    Styled_TabPanelPermissionSelection,
} from "./PermissionSelection.style";
import {
    areAllElementsInArray,
    countSelectedIndex,
    countSelectedIndexByCategory,
    handlePermissions,
    toggleStringFromArray,
} from "./utils";

export type Type_Props_PermissionsSelectionComponent = {
    handleChange: UseFormSetValue<Type_usr_post_role>;
    values: string[];
    search: string;
    handleSearch: (e: any) => void;
    permissionsData: Type_permissionsSelectConfiguration;
    handleTabs: (event: SyntheticEvent, newValue: string) => void;
    tabValue: string;
    results: number;
    toggleAllSubPermissions: (categories: Type_category[]) => void;
};

export const PermissionsSelectionComponent = ({
    handleChange,
    values,
    search,
    handleSearch,
    handleTabs,
    tabValue,
    permissionsData,
    results,
    toggleAllSubPermissions,
}: Type_Props_PermissionsSelectionComponent) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Roles");
    const { formState } = useFormContext();

    return (
        <Stack direction="column" rowGap={1}>
            <DrawerLabel
                variant="h3"
                data-testid={"PermissionsSelection-Title"}
            >
                {fmt("Title")}
            </DrawerLabel>
            <TMC_TextField
                data-testid="PermissionsSelection-SearchBar"
                value={search}
                onChange={handleSearch}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon
                                variant="solid"
                                icon="search"
                                sx={{ color: COLORS.moon900 }}
                            />
                        </InputAdornment>
                    ),
                }}
            />
            {search && (
                <Typography
                    variant="body3"
                    color={COLORS.moon600}
                    data-testid="PermissionsSelection-Result"
                >
                    {results > 1
                        ? fmt("Results", {
                              values: {
                                  results: results,
                              },
                          })
                        : fmt("Result", {
                              values: {
                                  results: results,
                              },
                          })}
                </Typography>
            )}
            <TabContext value={tabValue}>
                <MemoizedHeaderTabs
                    permissionsData={permissionsData}
                    handleTabs={handleTabs}
                    values={values}
                    tabValue={tabValue}
                    search={search}
                />
                {formState.errors["permissions"] && (
                    <TMC_FormHelperText
                        helperText={
                            formState?.errors["permissions"]?.message as string
                        }
                        inputName={"permissions"}
                    />
                )}
                {permissionsData.map((data: Type_project, index: number) => (
                    <Styled_TabPanelPermissionSelection
                        value={String(index)}
                        key={data.name}
                        className={`${formState.errors["permissions"] ? "requiredText" : ""} ${search ? "searchText" : ""}`}
                    >
                        <Stack gap={4}>
                            {data.categories.map(
                                (category: Type_subproject) => {
                                    return (
                                        <MemoizedContentTab
                                            key={category.key}
                                            values={values}
                                            category={category}
                                            handleChange={handleChange}
                                            toggleAllSubPermissions={
                                                toggleAllSubPermissions
                                            }
                                            search={search}
                                        />
                                    );
                                },
                            )}
                        </Stack>
                    </Styled_TabPanelPermissionSelection>
                ))}
            </TabContext>
        </Stack>
    );
};

/***
 *
 * TABS - HEADER
 *
 ***/

type Type_HeaderTabs = {
    permissionsData: Type_permissionsSelectConfiguration;
    handleTabs: (event: SyntheticEvent, newValue: string) => void;
    tabValue: string;
    values: string[];
    search: string;
};

const HeaderTabs = ({
    permissionsData,
    handleTabs,
    values,
    tabValue,
    search,
}: Type_HeaderTabs) => {
    return (
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 1 }}>
            <TabList onChange={handleTabs}>
                {permissionsData.map((project: Type_project, index: number) => {
                    const digitValue = useMemo(
                        () => countSelectedIndex(project, values),
                        [values, search],
                    );

                    return (
                        <Styled_TabButton
                            data-testid={`PermissionsSelection-Tab-${project.name}`}
                            key={project.name}
                            label={
                                <Stack
                                    gap={2}
                                    flexWrap="nowrap"
                                    direction="row"
                                    alignItems="center"
                                    data-testid={`PermissionsSelection-digit-Title-${project.name}`}
                                >
                                    {project.name}
                                    <Digit
                                        data-testid={`PermissionsSelection-digit-${project.name}`}
                                        value={digitValue}
                                        isFocus={String(index) === tabValue}
                                    />
                                </Stack>
                            }
                            value={String(index)}
                        />
                    );
                })}
            </TabList>
        </Box>
    );
};

const MemoizedHeaderTabs = memo(HeaderTabs);

/***
 *
 * TABS - CONTENT
 *
 ***/

type Type_ContentTab = {
    values: string[];
    category: Type_subproject;
    handleChange: UseFormSetValue<Type_usr_post_role>;
    toggleAllSubPermissions: (categories: Type_category[]) => void;
    search: string;
};

const ContentTab = ({
    values,
    category,
    handleChange,
    toggleAllSubPermissions,
    search,
}: Type_ContentTab) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.Roles");

    const digitValue: number = countSelectedIndexByCategory(
        category.categories,
        values,
    );

    return (
        <Stack data-testid="CategoryPermissions-Collapse-Container">
            <CategoryCollapseContainer
                label={fmt(`CategoriesLabels.${category.key}`)}
                onClick={() => toggleAllSubPermissions(category.categories)}
                color={category.color}
                digitValue={digitValue}
            >
                <Stack
                    gap={2}
                    sx={{ paddingTop: 2 }}
                    data-testid={`CategoriesLabels-${category.key}`}
                >
                    {category.categories.map((subCategory: Type_category) => {
                        const isHighlighted =
                            search.length > 1
                                ? fmt(`SubCategoriesLabels.${subCategory.key}`)
                                      .toLowerCase()
                                      .includes(search.trim().toLowerCase())
                                : false;

                        return (
                            <CollapseBox
                                name={subCategory.key}
                                key={subCategory.key}
                                label={fmt(
                                    `SubCategoriesLabels.${subCategory.key}`,
                                )}
                                value={areAllElementsInArray(
                                    values,
                                    subCategory.permissions,
                                )}
                                callback={() => {
                                    handleChange(
                                        "permissions",
                                        handlePermissions(
                                            values,
                                            subCategory.permissions,
                                        ),
                                        { shouldValidate: true },
                                    );
                                }}
                                highlighted={isHighlighted}
                            >
                                {!!subCategory.categories.length && (
                                    <Stack
                                        columnGap={2}
                                        flexDirection="row"
                                        paddingTop={2}
                                    >
                                        {subCategory.categories.map(
                                            (permission: Type_permission) => {
                                                const isHighlighted =
                                                    search.length > 1
                                                        ? permission.key
                                                              .toLowerCase()
                                                              .includes(
                                                                  search
                                                                      .trim()
                                                                      .toLowerCase(),
                                                              )
                                                        : false;

                                                return (
                                                    <TMC_Badge
                                                        data-testid={`${subCategory.key}-${permission.key}-Chip`}
                                                        key={permission.key}
                                                        clickable
                                                        label={fmt(
                                                            `PermissionsLabels.${permission.key}`,
                                                        )}
                                                        onClick={() => {
                                                            handleChange(
                                                                "permissions",
                                                                toggleStringFromArray(
                                                                    values,
                                                                    permission.permissions,
                                                                ),
                                                                {
                                                                    shouldValidate:
                                                                        true,
                                                                },
                                                            );
                                                        }}
                                                        backgroundColor={
                                                            isHighlighted
                                                                ? COLORS.yellowPermission
                                                                : COLORS.white
                                                        }
                                                        icon={
                                                            areAllElementsInArray(
                                                                values,
                                                                permission.permissions,
                                                            ) ? (
                                                                <DoneIcon />
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    />
                                                );
                                            },
                                        )}
                                    </Stack>
                                )}
                            </CollapseBox>
                        );
                    })}
                </Stack>
            </CategoryCollapseContainer>
        </Stack>
    );
};

const MemoizedContentTab = memo(ContentTab);
