import { capitalize } from "lodash";

import {
    Type_index_metadataUser,
    Type_metadataUsers_For_Form,
    Type_post_metadataUser,
    Type_put_metadataUser,
    Type_show_metadataUser,
    Type_usr_index_metadataUser,
    Type_usr_post_metadataUser,
    Type_usr_put_metadataUser,
    Type_usr_show_metadataUser,
} from "src/api/tms-users/metadata/types";
import { metadataWithValues } from "src/configurations/metadataTypes";

export const formatterIndexMetadataUsers = (
    metadataUsers: Type_usr_index_metadataUser[],
): Type_index_metadataUser[] => {
    if (!metadataUsers) return [];
    return metadataUsers.map((metadataUser: Type_usr_index_metadataUser) =>
        formatterIndexMetadataUser(metadataUser),
    );
};

export const formatterIndexMetadataUser = (
    metadataUser: Type_usr_index_metadataUser,
) => {
    return {
        id: metadataUser.id,
        name: metadataUser.name,
        key: metadataUser.key,
        metadataType: capitalize(metadataUser.metadataType),
        values: metadataUser.values,
        enabled: metadataUser.enabled,
        sort: metadataUser.sort,
        forFilter: metadataUser.forFilter,
    };
};

export const formatterShowMetadataUser = (
    metadataUser: Type_usr_show_metadataUser,
): Type_show_metadataUser => {
    return {
        id: metadataUser.id,
        names: metadataUser.names,
        key: metadataUser.key,
        metadataType: metadataUser.metadataType,
        forFilter: metadataUser.forFilter,
        values: metadataUser.values,
        sort: metadataUser.sort,
    };
};

export const formatterCreateMetadataUser = (
    metadataUser: Type_post_metadataUser,
): Type_usr_post_metadataUser => {
    return {
        names: metadataUser.names,
        key: metadataUser.key,
        metadataType: metadataUser?.metadataType,
        forFilter: metadataUser.forFilter,
        sort: metadataUser.sort,
        values: metadataWithValues.includes(metadataUser?.metadataType)
            ? metadataUser.values
            : [],
    };
};

export const formatterUpdateMetadataUser = (
    metadataUser: Type_show_metadataUser,
): Type_usr_put_metadataUser => {
    return {
        names: metadataUser.names,
        key: metadataUser.key,
        metadataType: metadataUser.metadataType,
        values:
            metadataUser.metadataType &&
            metadataWithValues.includes(metadataUser.metadataType)
                ? metadataUser.values
                : [],
        forFilter: metadataUser.forFilter,
        sort: metadataUser.sort,
    };
};

export const formatterIndexMetadataUsersForForm = (
    metadataUsers: Type_usr_index_metadataUser[],
): Type_metadataUsers_For_Form[] => {
    if (!metadataUsers) return [];
    return metadataUsers.map((metadataUser: Type_usr_index_metadataUser) => ({
        id: metadataUser.id,
        name: metadataUser.key,
        label: metadataUser.name,
        metadataType: metadataUser.metadataType,
        values: metadataUser.values,
        enabled: metadataUser.enabled,
        sort: metadataUser.sort,
        forFilter: metadataUser.forFilter,
    }));
};

export const formatterStatusMetadataUser = (
    metadataUser: Type_put_metadataUser,
): Type_usr_put_metadataUser => {
    return {
        enabled: metadataUser.enabled,
    };
};
