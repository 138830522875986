//////////////////////////////////
/// Panel component            ///
//////////////////////////////////

import { Box } from "@mui/material";
import { createRef, ReactElement, useEffect } from "react";

type Type_Props_Panel = {
    children: ReactElement | string;
    width?: number;
    setWidth?: (width: number) => void;
    height?: number;
    setHeight?: (height: number) => void;
};

/**
 * Panel component
 *
 * Resizable panel with state
 */
export const Panel = ({
    children,
    width,
    setWidth,
    height,
    setHeight,
    ...props
}: Type_Props_Panel) => {
    const panelRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (panelRef.current && setWidth) {
            if (!width) {
                setWidth(panelRef.current?.clientWidth);
                return;
            }

            panelRef.current.style.width = `${width}px`;
        }
    }, [panelRef, width, setWidth]);

    useEffect(() => {
        if (panelRef.current && setHeight) {
            if (!height) {
                setHeight(panelRef.current?.clientHeight);
                return;
            }

            panelRef.current.style.height = `${height}px`;
        }
    }, [panelRef, height, setHeight]);

    return (
        <Box {...props} ref={panelRef}>
            {children}
        </Box>
    );
};
