import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Panel } from "src/layouts/Layout_Resizable/Panel";

// Layouts

export const ParentLayout = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "hidden",
}));

export const Styled_ContainerX = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    overflow: "hidden",
}));
export const Styled_ContainerY = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
}));

export const Styled_Center = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "hidden",
    userSelect: "none",
}));

export const Styled_Right = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
}));

export const Styled_Bottom = styled("div")(() => ({
    marginTop: -20,
}));

export const Styled_BottomPanel = styled(Panel)(() => ({
    display: "flex",
    flexDirection: "row",
}));

// Dividers

export const Styled_HorizontalDividerHitBox = styled("div")(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: "20px",
    bottom: "-20px",

    borderTop: theme.border.default,

    cursor: "row-resize",
    userDrag: "none",
    userSelect: "none",

    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
}));
export const Styled_HorizontalDivider = styled("div")(({ theme }) => ({
    width: 80,
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.common.black,
}));
