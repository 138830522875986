import {
    get,
    GetReturnType,
    post,
    PostReturnType,
    put,
    PutReturnType,
    remove,
    RemoveReturnType,
    Type_RequestConfig,
} from "src/api/fetch";
import { getFilterJoin, Type_Filter_Sequence } from "src/api/filters";
import { SequencePath, Url } from "src/api/paths";
import {
    Type_sch_post_sequenceNote,
    Type_sch_put_sequenceNote,
} from "src/api/tms-scheduling/sequenceNotes/types";

export const indexSequenceNotes = (
    requestConfig: Type_RequestConfig,
    filters: Type_Filter_Sequence = {},
): GetReturnType => {
    const urlParams: string = getFilterJoin(filters);

    return get(
        `${Url.SCHEDULING}${SequencePath.NOTES}${urlParams}`,
        requestConfig,
    );
};

export const createSequenceNote = (
    data: Type_sch_post_sequenceNote,
    requestConfig: Type_RequestConfig,
): PostReturnType => {
    return post(data, `${Url.SCHEDULING}${SequencePath.NOTES}`, requestConfig);
};

export const updateSequenceNote = (
    id: number,
    data: Type_sch_put_sequenceNote,
    requestConfig: Type_RequestConfig,
): PutReturnType => {
    return put(
        data,
        `${Url.SCHEDULING}${SequencePath.NOTES}/${id}`,
        requestConfig,
    );
};

export const deleteSequenceNote = (
    id: number,
    requestConfig: Type_RequestConfig,
): RemoveReturnType => {
    return remove(
        `${Url.SCHEDULING}${SequencePath.NOTES}/${id}`,
        requestConfig,
    );
};
