import MenuItem from "@mui/material/MenuItem";
import { GridSingleSelectColDef } from "@mui/x-data-grid-premium";
import * as React from "react";

import { TMC_TextField } from "src/components/Components_Common/_MuiComponentsVariants";
import { SELECT_NONE_VALUE } from "src/configurations/app";

type OptionWithLabel = { value: number; label: string };

type Type_Props_singleSelectColumn = {
    hasNoneOption?: boolean;
} & GridSingleSelectColDef;

export const singleSelectColumn = ({
    field,
    valueOptions,
    hasNoneOption,
    ...props
}: Type_Props_singleSelectColumn) => {
    return {
        ...props,
        field,
        renderEditCell: (params: any) => {
            const options: any[] =
                typeof valueOptions === "function"
                    ? valueOptions(params)
                    : (valueOptions as OptionWithLabel[]);

            return (
                <TMC_TextField
                    variant="standard"
                    select
                    // Utiliser la valeur actuelle (l'id) pour sélectionner la bonne option
                    value={params.row?.[field]}
                    onChange={(event) => {
                        const newValue = parseInt(event.target.value);

                        // Utiliser setEditCellValue pour mettre à jour la cellule temporairement
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: newValue,
                        });
                    }}
                >
                    {hasNoneOption && (
                        <MenuItem
                            key={"empty"}
                            value={SELECT_NONE_VALUE}
                            data-testid={`Select-MenuItem-${field}-empty`}
                        >
                            &nbsp;
                        </MenuItem>
                    )}
                    {options.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            data-testid={`Select-MenuItem-${field}-${option.value}`}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </TMC_TextField>
            );
        },
        valueFormatter: (value: any) => {
            const options: OptionWithLabel[] =
                typeof valueOptions === "function"
                    ? (valueOptions(value) as OptionWithLabel[])
                    : (valueOptions as OptionWithLabel[]);

            const selectedOption = options?.find(
                (option) => option?.value === value,
            );

            return selectedOption ? selectedOption?.label : "";
        },
        // valueSetter pour enregistrer la valeur (id) après l'édition de la cellule
        valueSetter: (value: number, params: { [key: string]: any }) => {
            return {
                ...params,
                [field]: value,
            };
        },
    };
};
