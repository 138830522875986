import { useQuery } from "react-query";

import { ProjectKeys } from "src/api/tms-commons/keys";
import { formatterOpenProject } from "src/api/tms-projects/projects/formatters";
import { openProject } from "src/api/tms-projects/projects/services";

export const useOpenProject = (slug?: string, subProjectId?: string) => {
    const slugLowerCase: string = slug?.toLocaleLowerCase() || "";

    return useQuery({
        queryKey: [ProjectKeys.OPEN, slugLowerCase, subProjectId],
        queryFn: () => openProject(slugLowerCase, subProjectId),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data: any) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Error format data: useOpenProject");
            }
            return formatterOpenProject(data.data.data);
        },
        enabled: !!slug && slug.length > 0,
    });
};
