import Konva from "konva";
import React, { useState } from "react";
import { Arrow, Circle } from "react-konva";
import { Html } from "react-konva-utils";

import { LINK_DIRECTION } from "src/api/tms-scheduling/taskLinks/types";
import { Enum_typeFlowLink } from "src/components/Components_Teamoty/Flow/Flow.enum";
import {
    radiusPlanningLink,
    usePlanningTheme,
    widthPlanninglink,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import { changeCursor } from "src/components/Components_Teamoty/Planning/tools/changeCursor";
import { getCoordinates } from "src/components/Components_Teamoty/Planning/tools/getCoordinates";
import { getHeight } from "src/components/Components_Teamoty/Planning/tools/getHeight";
import { getWidth } from "src/components/Components_Teamoty/Planning/tools/getWidth";
import { usePlanningContext } from "src/contexts/planning";
import { Link } from "src/dialogs/Link";

import { Type_Props_PlanningLink } from "./PlanningLink.type";

export const PlanningLink = ({
    id,
    modeId,
    taskLineFrom,
    taskLineTo,

    widthDate,
    heightArea,
}: Type_Props_PlanningLink) => {
    const { defaultCursor } = usePlanningContext();
    const theme = usePlanningTheme();
    const { enableTaskDone } = usePlanningContext();

    // task FROM
    const { x: fromXConst } = getCoordinates({
        task: taskLineFrom.task,
        widthDate,
        line: taskLineFrom.line,
        heightArea,
        height: getHeight({ task: taskLineFrom.task, heightArea }),
    });
    const widthFrom = getWidth({ task: taskLineFrom.task, widthDate });

    // Task To
    const { x: toXConst } = getCoordinates({
        task: taskLineTo.task,
        widthDate,
        line: taskLineTo.line,
        heightArea,
        height: getHeight({ task: taskLineTo.task, heightArea }),
    });
    const widthTo = getWidth({ task: taskLineTo.task, widthDate });

    // Change with mode
    let fromX = fromXConst;
    let toX = toXConst;

    switch (modeId) {
        case Enum_typeFlowLink.SS:
            break;

        case Enum_typeFlowLink.SF:
            toX += widthFrom;
            break;

        case Enum_typeFlowLink.FF:
            fromX += widthFrom;
            toX += widthTo;
            break;

        default:
            fromX += widthFrom;
            break;
    }

    const defaultStyleProps: Konva.ShapeConfig = {
        radius: radiusPlanningLink,
        fill: theme.fillPlanningLink,
        stroke: theme.fillPlanningLink,
        strokeWidth: widthPlanninglink,
    };

    const hoverStyleProps: Konva.ShapeConfig = {
        radius: radiusPlanningLink + 1,
        fill: theme.fillPlanningLinkOver,
        stroke: theme.fillPlanningLinkOver,
        strokeWidth: widthPlanninglink + 1,
    };

    const [styleProps, setStyleProps] =
        useState<Konva.ShapeConfig>(defaultStyleProps);

    const handleMouseOver = (e: Konva.KonvaEventObject<MouseEvent>): void => {
        changeCursor(e, "pointer");
        setStyleProps(hoverStyleProps);
    };

    const handleMouseOut = (e: Konva.KonvaEventObject<MouseEvent>): void => {
        setStyleProps(open ? hoverStyleProps : defaultStyleProps);
        changeCursor(e, defaultCursor);
    };

    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setStyleProps(defaultStyleProps);
    };

    return (
        <>
            <Circle
                x={fromX}
                y={taskLineFrom.line.pos + heightArea / 2}
                radius={styleProps.radius}
                fill={styleProps.fill}
            />
            <Arrow
                points={[
                    fromX,
                    taskLineFrom.line.pos + heightArea / 2,
                    toX,
                    taskLineTo.line.pos + heightArea / 2,
                ]}
                fill={styleProps.fill}
                stroke={styleProps.stroke}
                strokeWidth={styleProps.strokeWidth}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                hitStrokeWidth={10}
                onClick={handleOpen}
                listening={!enableTaskDone}
            />
            <Html>
                <Link
                    handleClose={handleClose}
                    linkId={id}
                    open={open}
                    direction={LINK_DIRECTION.TO}
                />
            </Html>
        </>
    );
};
