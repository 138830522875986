import { Box } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import useMeasure from "react-use-measure";

import { Planning } from "src/components/Components_Teamoty/Planning/Planning";
import { Type_State_IsLoading } from "src/components/Components_Teamoty/views/ViewPlanning/ViewPlanning";

export const PlanningDemo = () => {
    const [refDiv, bounds] = useMeasure();

    const [isLoading, setIsLoading] = useState<Type_State_IsLoading>({
        loading: true,
        withPlanning: false,
    });

    return (
        <Box
            ref={refDiv}
            sx={{
                width: "90vw",
                height: "80vh",
                outline: "2px solid red",
            }}
        >
            <Planning
                bounds={bounds}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        </Box>
    );
};
