import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Type_show_task } from "src/api/tms-scheduling/tasks/types";
import { SequenceBadgeIcon } from "src/components/Components_Teamoty/badges/SequenceBadgeIcon";
import { HeaderToolbar } from "src/components/Components_Teamoty/contextualDrawers/HeaderToolbar/HeaderToolbar";
import { NameForm } from "src/forms/tasks/NameForm/NameForm";
import { shouldDisplay } from "src/forms/tasks/utils";
import {
    Header,
    useContextualDrawer,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";
import { invertColor } from "src/utils/colors";

type Type_Props_HeaderForm = {
    data: Type_show_task;
    onClose: () => void;
    refresh: () => void;
    readonly: boolean;
};

export const HeaderForm = ({
    data,
    onClose,
    refresh,
    readonly,
}: Type_Props_HeaderForm) => {
    const { openPaper } = useContextualDrawer();

    const { control } = useFormContext();

    ///////////////////////////////////////
    ///         Task header color       ///
    ///////////////////////////////////////

    const taskColor = useWatch({
        name: "color",
        control: control,
    });

    const withTradeColor = useWatch({
        name: "withTradeColor",
        control: control,
    });

    const subTrade = useWatch({
        name: "subTrade",
        control: control,
    });

    const taskType = useWatch({
        name: "type",
        control: control,
    });

    const headerBackgroundColor = useMemo(() => {
        const isWithTradeColorEnabled = shouldDisplay({
            name: "withTradeColor",
            taskType: Number(taskType),
        });

        return withTradeColor && subTrade && isWithTradeColorEnabled
            ? subTrade.trade?.color
            : taskColor;
    }, [withTradeColor, subTrade, taskColor, taskType]);

    const handleClickSequence = (): void => {
        openPaper("sequence", { id: data.sequence.id }, true);
    };

    return (
        <Header
            data-testid="DrawerHeader"
            ownerState={{ borderBottom: true }}
            sx={{
                backgroundColor: headerBackgroundColor,
                color: invertColor(headerBackgroundColor || "#FFF"),
            }}
        >
            <HeaderToolbar
                dataTestIdRef="Task-Header"
                onClose={onClose}
                notes={"taskNotes"}
                taskId={data?.id}
                notesCount={data?.notesCount}
                onRefresh={refresh}
            >
                {data.sequence && (
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <SequenceBadgeIcon
                            color={data.sequence?.color as string}
                        />
                        <Typography
                            data-testid={"Toolbar-Header-Sequence-Name"}
                            onClick={handleClickSequence}
                            color={"inherit"}
                            variant="body3Medium"
                            sx={{
                                ":hover": {
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            {data.sequence?.name}
                        </Typography>
                    </Stack>
                )}
            </HeaderToolbar>
            <NameForm
                sequenceId={data?.sequence?.id}
                id={data.id}
                headerBackgroundColor={headerBackgroundColor}
                readonly={readonly}
            />
        </Header>
    );
};
