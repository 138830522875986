import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";

import { Select } from "src/components/Components_Common/forms/reactHookFormComponents/Select/Select";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { Type_ReadonlyComponent } from "src/components/Components_Common/types";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export enum Enum_typeWaitingDaysModel {
    WorkingDays = 1,
    CalendarDays = 2,
}

export const waitingDaysFormInitialData = {
    waitingDay: undefined,
    waitingDayMode: undefined,
};

export const waitingDaysFormSchema = Yup.object().shape({
    waitingDay: Yup.number()
        .typeError(FORM_ERR_FMT.MUST_BE_NUMBER)
        .max(99999999.99)
        .nullable()
        .when("waitingDayMode", (waitingDayMode, schema) => {
            return waitingDayMode !== null && waitingDayMode !== undefined
                ? schema.required()
                : schema;
        }),
    waitingDayMode: Yup.number().nullable(),
});

type Type_Props_WaitingDaysTask = Type_ReadonlyComponent;

export const WaitingDaysTask = ({ readonly }: Type_Props_WaitingDaysTask) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.WaitingDays",
    );
    const { formState } = useFormContext(); // retrieve those props

    return (
        <Grid container py={3} spacing={4}>
            <Grid item xs={6}>
                <TextField
                    error={!!formState.errors["waitingDay"]}
                    name="waitingDay"
                    label={fmt("WaitingDays")}
                    type="number"
                    inputProps={{ min: 0 }}
                    data-testid="Form-WaitingDayTask-Days"
                    readonly={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    name="waitingDayMode"
                    error={!!formState.errors["waitingDayMode"]}
                    label={fmt("WaitingDaysModel")}
                    data-testid="Form-WaitingDayTask-DaysMode"
                    options={[
                        {
                            value: Enum_typeWaitingDaysModel.WorkingDays,
                            label: fmt("DaysModel.WorkingDays"),
                        },
                        {
                            value: Enum_typeWaitingDaysModel.CalendarDays,
                            label: fmt("DaysModel.CalendarDays"),
                        },
                    ]}
                    readonly={readonly}
                />
            </Grid>
        </Grid>
    );
};
