import { Grid, OutlinedInputProps } from "@mui/material";
import { useFormikContext } from "formik";
import { Dispatch, SetStateAction } from "react";

import { TMC_TextFieldWithUnit_Deprecated } from "src/components/Components_Common/_MuiComponentsVariants";
import { Type_Props_ColorPickerContainer } from "src/components/Components_Common/ColorPicker/ColorPicker.container";
import {
    Styled_ButtonColorGrid,
    Styled_Popover,
    Styled_StackColorAdornment,
} from "src/components/Components_Common/ColorPicker/ColorPicker.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { COLOR_PICKER_COLORS } from "src/theme/stylesheet";
import { invertColor } from "src/utils/colors";

type Type_Props_ColorPickerComponent = Type_Props_ColorPickerContainer & {
    setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
    open: boolean;
    anchorEl: HTMLButtonElement | null;
    handleClose: () => void;
    handleClick: (event: any) => void;
};
export const ColorPickerComponent = ({
    name,
    setAnchorEl,
    open,
    anchorEl,
    handleClose,
    handleClick,
    format,
    selectedColors,
    ...restProps
}: Type_Props_ColorPickerComponent & OutlinedInputProps) => {
    const formik = useFormikContext<any>();

    const ColorCircle = () => {
        return (
            <Styled_StackColorAdornment
                style={{ backgroundColor: formik?.values[name] }}
            />
        );
    };

    const ColorPopover = () => {
        const handleColorPickerClick = (color: string): void => {
            formik.setFieldValue(name, color);
            setAnchorEl(null);
        };

        return (
            <Styled_Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Grid container spacing={1} columns={8}>
                    {COLOR_PICKER_COLORS.map((color: string, index: number) => {
                        return (
                            <Grid item xs={1} key={index}>
                                <Styled_ButtonColorGrid
                                    onClick={() =>
                                        handleColorPickerClick(color)
                                    }
                                    ownerState={{ fullPaletteColor: color }}
                                    data-testid={`ColorPicker-${name}-${color}`}
                                >
                                    {(color ===
                                        formik?.values[name]?.colorValue && (
                                        <Icon
                                            variant="solid"
                                            icon="check"
                                            sx={{
                                                color: invertColor(color),
                                            }}
                                            data-testid={`ColorPicker-${name}-${color}-check`}
                                        />
                                    )) ||
                                        (selectedColors?.includes(color) && (
                                            <Icon
                                                variant="solid"
                                                icon="close"
                                                sx={{
                                                    color: invertColor(color),
                                                }}
                                                data-testid={`ColorPicker-${name}-${color}-close`}
                                            />
                                        ))}
                                </Styled_ButtonColorGrid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Styled_Popover>
        );
    };

    return (
        <>
            <TMC_TextFieldWithUnit_Deprecated
                {...restProps}
                name={name}
                format={format}
                value={formik?.values[name]}
                onClick={handleClick}
                unit={<ColorCircle />}
                positionAdornment={"start"}
                inputProps={{ readOnly: true }}
                error={formik.touched[name] && Boolean(formik.errors[name])}
                helperText={
                    formik.touched[name] && (formik.errors[name] as string)
                }
                sx={{ cursor: "pointer", ...restProps.sx }}
            />
            <ColorPopover />
        </>
    );
};
