import { Box, CardActionArea, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

import { Type_default_view } from "src/api/tms-projects/workspacesAndViews/views/types";
import defaultCover from "src/assets/images/defaultWorkspaceCover.png";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import {
    Styled_ViewCard,
    Styled_ViewCardContent,
    Styled_ViewCardIcon,
    Styled_ViewCardMedia,
} from "src/layouts/Layout_Workspace/Views/stylesheet";

type Type_Props_ViewCard = {
    view: Type_default_view;
    selectedView: Type_default_view | null;
    setSelectedView: Dispatch<SetStateAction<Type_default_view | null>>;
};

export const ViewCard = ({
    view,
    selectedView,
    setSelectedView,
}: Type_Props_ViewCard) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Workspace");

    const handleClick = () => {
        if (view) setSelectedView(view);
    };

    return (
        <Styled_ViewCard
            ownerState={{ selected: view.name === selectedView?.name }}
            data-testid={`ViewCard-${view.name}`}
        >
            {view.name === selectedView?.name ? (
                <Styled_ViewCardIcon variant="solid" icon="circle-check" />
            ) : null}
            <CardActionArea onClick={handleClick}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Styled_ViewCardMedia
                        component="img"
                        image={defaultCover as string}
                    />
                    <Styled_ViewCardContent>
                        <Typography variant="h3" component="div">
                            {fmt(view.name + ".Name")}
                        </Typography>
                        <Typography variant="body3" color="text.secondary">
                            {fmt(view.name + ".Description")}
                        </Typography>
                    </Styled_ViewCardContent>
                </Box>
            </CardActionArea>
        </Styled_ViewCard>
    );
};
