import { Type_planningArea } from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import React, { useEffect, useState } from "react";
import { Group, Line } from "react-konva";

import { PlanningPlanningDrawerResizerY } from "src/components/Components_Teamoty/Planning/Drawer/PlanningPlanningDrawerResizerY";
import {
    heightPlanningTitle,
    offsetPlanningArea,
    usePlanningTheme,
} from "src/components/Components_Teamoty/Planning/Planning.const";
import {
    Type_Map_PlanningColsDataSize,
    Type_PlanningColDataSize,
} from "src/components/Components_Teamoty/Planning/Planning.type";
import { PlanningScrollX } from "src/components/Components_Teamoty/Planning/Scrolls/PlanningScrollX";

import { PlanningColDataTitle } from "./PlanningColDataTitle";
import {
    Type_Map_PlanningColData,
    Type_PlanningColData,
    Type_Props_PlanningColsData,
} from "./PlanningColsData.type";

export const PlanningColsData = ({
    areasOffset,
    cols,
    colsData,
    x,
    y,
    yScroll,
    width,
    height,
    heightArea,
    changeColsDataSize,
    onResize,
    onChangeValueColData,
}: Type_Props_PlanningColsData) => {
    const theme = usePlanningTheme();
    const [areas, setAreas] = useState<Type_Map_PlanningColData>(
        new Map<string, Type_PlanningColData>(),
    );

    const [scrollPos, setScrollPos] = useState<number>(0);

    useEffect((): void => {
        const newAreas: Type_Map_PlanningColData = new Map<
            string,
            Type_PlanningColData
        >();
        const recursiveArea = (
            areas: Type_planningArea[],
            x: number = 0,
            y: number = 0,
        ): void => {
            areas.map((area: Type_planningArea): void => {
                const offset: number = area.offset
                    ? area.offset * heightArea
                    : 0;

                if (
                    y - areasOffset.offset + area.size * heightArea > 0 &&
                    y - areasOffset.offset < height
                ) {
                    if (area?.areas) {
                        newAreas.set(area.key + ".0", {
                            pos: y,
                            height: heightArea,
                        });
                    } else if (area?.close) {
                        newAreas.set(area.key + ".1", {
                            pos: y,
                            height: offset,
                        });
                    }

                    if (!area?.close) {
                        newAreas.set(area.key + ".1", {
                            pos: area?.areas ? y + heightArea : y,
                            height: area?.areas ? offset - heightArea : offset,
                        });
                    }
                }

                if (area?.areas && !area?.close) {
                    recursiveArea(
                        area.areas,
                        x + offsetPlanningArea,
                        y + offset,
                    );
                }

                y += area.size * heightArea;
            });
        };

        recursiveArea(areasOffset.areas);

        setAreas(newAreas);
    }, [areasOffset, heightArea]);

    const offsetY = Math.round(areasOffset.offset);
    const elmColTitle: Array<React.JSX.Element> = [];
    const elmCol: Array<React.JSX.Element> = [];

    let widthFull = 0;
    let colX: number = 0;
    cols.forEach((col: Type_PlanningColDataSize, index: string): void => {
        if (col.show) {
            widthFull += col.width;

            elmColTitle.push(
                <PlanningColDataTitle
                    key={"cdt" + index}
                    y={0 - heightPlanningTitle}
                    x={colX - scrollPos}
                    width={col.width}
                    height={heightPlanningTitle}
                    text={col.title}
                />,
            );

            elmCol.push(
                <col.component
                    key={"kc" + index}
                    areas={areas}
                    colsData={colsData}
                    x={colX}
                    width={col.width}
                    height={height}
                    onChangeValueColData={onChangeValueColData}
                />,
            );

            colX + col.width > scrollPos + 5 &&
                colX + col.width < scrollPos + width - 5 &&
                elmCol.push(
                    <PlanningPlanningDrawerResizerY
                        key={"kcr" + index}
                        x={x + 1 + colX - scrollPos}
                        y={y}
                        width={col.width}
                        height={height}
                        minSize={50}
                        onResize={(size: number): void => {
                            changeColsDataSize(
                                (
                                    prev: Type_Map_PlanningColsDataSize,
                                ): Type_Map_PlanningColsDataSize => {
                                    const newPrev = new Map(prev);
                                    newPrev &&
                                        ((
                                            newPrev.get(
                                                index,
                                            ) as Type_PlanningColDataSize
                                        ).width = size);
                                    return newPrev;
                                },
                            );
                        }}
                    />,
                );

            colX += col.width;
            elmCol.push(
                <Line
                    key={"kl" + index}
                    points={[colX, offsetY, colX, height + offsetY]}
                    stroke={theme.colorPlanningLine}
                    strokeWidth={1}
                />,
            );
        }
    });

    areas.forEach((area: Type_PlanningColData, index: string): void => {
        elmCol.push(
            <Line
                key={"ly" + index}
                points={[0, area.pos, widthFull, area.pos]}
                stroke={theme.colorPlanningLine}
                strokeWidth={1}
            />,
        );
    });

    return (
        <Group x={x}>
            <Group
                y={y}
                clipX={0}
                clipY={-heightPlanningTitle - 0.5}
                clipWidth={width}
                clipHeight={height + heightPlanningTitle + 1}
            >
                <Line
                    points={[0, 0, width, 0]}
                    stroke={theme.colorPlanningLine}
                    strokeWidth={1}
                />

                <Line
                    points={[0, height, width, height]}
                    stroke={theme.colorPlanningLine}
                    strokeWidth={1}
                />

                <Group
                    clipX={0}
                    clipY={-0.5}
                    clipWidth={width}
                    clipHeight={height}
                >
                    <Group offsetY={offsetY}>
                        <Group offsetX={scrollPos}>{elmCol}</Group>
                    </Group>
                </Group>

                {elmColTitle}

                <PlanningPlanningDrawerResizerY
                    x={x + 2}
                    y={y}
                    width={width}
                    height={height}
                    minSize={1}
                    onResize={onResize}
                    showResizer={true}
                />
            </Group>

            <PlanningScrollX
                pos={scrollPos}
                size={widthFull}
                page={Math.min(width, widthFull)}
                widthPage={0}
                x={0}
                y={yScroll}
                width={width}
                changePos={(pos: number): void => {
                    setScrollPos(pos);
                }}
            />
        </Group>
    );
};
