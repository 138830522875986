import { useNavigate } from "react-router-dom";

import { Divider } from "src/components";
import { InputButton } from "src/components/Components_Common/Form/Form_Components/Form_CTAs";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { getLocalStorageItem } from "src/utils/localStorageServices";

export const Page_ChangePasswordExpireComponent = () => {
    const navigate = useNavigate();
    const { formatMessageWithPartialKey } = useCoreIntl("ChangePasswordExpire");
    const language = getLocalStorageItem("language");

    const goHome = () => {
        navigate(`/${language}/login`, { replace: true });
    };

    const goForgetPassword = () => {
        navigate(`/${language}/forget-password`, { replace: true });
    };

    return (
        <div className="bg-gray-200 flex justify-center items-center w-full h-screen">
            <div className="block-login flex justify-center w-full">
                <div className="p-8 rounded-md shadow-md">
                    <h1 className="w-full text-center">
                        {formatMessageWithPartialKey("Title")}
                    </h1>
                    <Divider className="my-6" />
                    <p>{formatMessageWithPartialKey("Message")}</p>
                    <Divider className="my-6" />
                    <InputButton
                        label={formatMessageWithPartialKey("CtaHome")}
                        props={{
                            onClick: goHome,
                        }}
                    />
                    <InputButton
                        label={formatMessageWithPartialKey("CtaForgetPassword")}
                        props={{
                            onClick: goForgetPassword,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
