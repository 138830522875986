import { MenuList, Stack, styled } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export const SPACING_SIDEBAR_PADDING: number = 4;

export const Styled_StackSideBar = styled(Stack)(({ theme }) => ({
    paddingLeft: theme.spacing(SPACING_SIDEBAR_PADDING),
    paddingRight: theme.spacing(SPACING_SIDEBAR_PADDING),
}));

export const Styled_MenuListSidebar = styled(MenuList)(({ theme }) => ({
    "&.MuiList-root": {
        padding: theme.spacing(0, 0, 6, 0),
    },
    li: {
        marginBottom: 0,
    },
}));

export const Styled_MenuItem = styled(MenuItem)(({ theme }) => ({
    "&&.Mui-selected": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        "& >.MuiListItemText-root": {
            ...theme.typography.body1Medium,
        },
    },
}));
