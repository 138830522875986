import { yupResolver } from "@hookform/resolvers/yup";
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Dayjs } from "dayjs";
import React, { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { EditorRhf } from "src/components/Components_Common/forms/reactHookFormComponents/Editor/Editor";
import { EditorFooter } from "src/components/Components_Common/richText/EditorFooter";
import { useFormDefaultConfig } from "src/configurations/app";
import {
    Schema_Note,
    Type_modalDeleteNote,
    Type_permissionsNote,
} from "src/forms/notes/NotesForm";
import { getDate } from "src/utils/date";

export type Type_index_note = {
    id: number;
    text: string;
    date: Dayjs;
    author: Type_author;
    enabled: boolean;
};

export type Type_put_note = {
    id: number;
    text: string;
    date?: Dayjs;
};

export type Type_post_note = {
    text: string;
    date?: Dayjs;
};

export type Type_author = {
    firstName: string;
    lastName: string;
    email: string;
};

const Styled_Box = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: `${theme.palette.background.paper}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.boxShadow.note,
}));

type Type_Props_NoteForm = {
    apiRef: string;
    index: number;
    note: Type_index_note | null;
    editable: boolean;
    handleSubmit: (values: any, e?: React.BaseSyntheticEvent) => void;
    selectedIndexNote: number | null;
    setSelectedIndexNote: (value: number | null) => void;
    getFullName?: () => string;
    setShowCreateNote?: (value: boolean) => void;
    setModalDeleteNote?: Dispatch<SetStateAction<Type_modalDeleteNote>>;
    permissions?: Type_permissionsNote;
    isLoading?: boolean;
};

export const NoteForm = ({
    apiRef,
    index,
    note,
    editable,
    handleSubmit,
    setSelectedIndexNote,
    getFullName,
    setShowCreateNote,
    setModalDeleteNote,
    permissions,
    isLoading,
}: Type_Props_NoteForm) => {
    const form = useForm<any>({
        ...useFormDefaultConfig,
        values: {
            note: note,
        },
        resolver: yupResolver<any>(Schema_Note),
    });

    const formValues = form.getValues("note");
    const formId = `${apiRef}-${index}`;

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit(handleSubmit)}
                id={`${apiRef}-${index}`}
            >
                <Styled_Box onClick={() => setSelectedIndexNote(index)}>
                    {/* new note */}
                    {index === -1 && (
                        <EditorRhf
                            dataTestIdRef={`${apiRef}-Drawer-NewNote`}
                            editable={editable}
                            name={"note"}
                            footer={
                                <EditorFooter
                                    dataTestIdRef={`${apiRef}-Drawer-NewNote-Footer`}
                                    fullName={getFullName ? getFullName() : ""}
                                    createdAt={getDate()}
                                    permissions={permissions}
                                    isLoading={isLoading}
                                    formId={formId}
                                    editable={editable}
                                />
                            }
                            hideRichText={() => {
                                form.setValue("note", {
                                    text: null,
                                });
                                if (setShowCreateNote) {
                                    setShowCreateNote(false);
                                }
                            }}
                        />
                    )}
                    {index !== -1 && (
                        <EditorRhf
                            dataTestIdRef={`${apiRef}-Drawer-${formValues.id}`}
                            editable={editable}
                            name={"note"}
                            footer={
                                <EditorFooter
                                    dataTestIdRef={`${apiRef}-Drawer-${formValues.id}-Footer`}
                                    fullName={`${formValues.author.firstName || ""} ${
                                        formValues.author.lastName || ""
                                    }`}
                                    createdAt={formValues.date}
                                    permissions={permissions}
                                    isLoading={isLoading}
                                    formId={formId}
                                    editable={editable}
                                />
                            }
                            hideRichText={
                                permissions?.canDelete
                                    ? () => {
                                          if (setModalDeleteNote) {
                                              setModalDeleteNote({
                                                  isOpen: true,
                                                  id: formValues.id,
                                              });
                                          }
                                      }
                                    : undefined
                            }
                        />
                    )}
                </Styled_Box>
            </form>
        </FormProvider>
    );
};
