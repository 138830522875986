import { useMutation, useQuery, useQueryClient } from "react-query";

import { Type_Filter_Sequence } from "src/api/filters";
import { TaskKeys } from "src/api/tms-scheduling/keys";
import {
    formatterShowTask,
    formatterTasksForMatrix,
    formatterTasksToFlow,
    formatterUpdateTask,
} from "src/api/tms-scheduling/tasks/formatters";
import {
    createTask,
    indexTasks,
    removeTask,
    showTask,
    updateTask,
} from "src/api/tms-scheduling/tasks/services";
import {
    Type_put_task,
    Type_sch_index_task,
    Type_sch_post_task,
    Type_show_task,
} from "src/api/tms-scheduling/tasks/types";
import { MEDIUM_STALE_TIME } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTasks = (
    callback: any,
    filters: Type_Filter_Sequence = {},
    enabled: boolean = true,
    uniqueId?: string,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskKeys.INDEX, requestConfig, filters, uniqueId],
        queryFn: () => indexTasks(requestConfig, filters),
        refetchOnWindowFocus: false,
        onSuccess: (data): void => {
            callback(data.data.data as Type_sch_index_task[]);
        },
        enabled: !!requestConfig.projectId && enabled,
    });
};

export const useIndexTasksForFlow = (
    filters: Type_Filter_Sequence = {},
    enabled: boolean = true,
    uniqueId?: string,
) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskKeys.INDEX, requestConfig, filters, uniqueId],
        queryFn: () => indexTasks(requestConfig, filters),
        refetchOnWindowFocus: false,
        select: (data) => {
            const tasks: Type_sch_index_task[] = data.data.data;
            return formatterTasksToFlow(tasks);
        },
        enabled: !!requestConfig.projectId && enabled,
    });
};

export const useIndexTasksForMatrix = (filters: Type_Filter_Sequence = {}) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskKeys.INDEX, requestConfig, filters],
        queryFn: () => indexTasks(requestConfig, filters, { forMatrix: true }),
        refetchOnWindowFocus: false,
        select: (data) => {
            if (data.success) {
                return formatterTasksForMatrix(data.data.data);
            }
        },
        enabled: !!requestConfig.projectId && !!filters.sequence_id,
    });
};

export const useShowTask = (id: number) => {
    const { requestConfig } = useProject();
    return useQuery({
        queryKey: [TaskKeys.SHOW, id, requestConfig],
        queryFn: ({ signal }) => showTask(id, requestConfig, signal),
        refetchOnWindowFocus: false,
        select: (data): Type_show_task => {
            return formatterShowTask(data?.data?.data);
        },
        enabled: !!requestConfig.projectId && !!id,
        staleTime: MEDIUM_STALE_TIME,
    });
};

type Type_Props_mutationCreateTask = {
    onSuccess?: () => void | Promise<void>;
};

export const mutationCreateTask = (
    props: Type_Props_mutationCreateTask = {},
) => {
    const { onSuccess = () => {} } = props;
    const { requestConfig } = useProject();
    const { add } = useToast();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (data: Type_sch_post_task) => {
            return createTask(data, requestConfig);
        },
        onSuccess: onSuccess,
        onError: (err: any): void => {
            console.debug("ERROR mutationCreateTask", err);
            add({
                type: "warning",
                description: fmt("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationUpdateTask = () => {
    const { requestConfig } = useProject();
    const { add } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (task: Type_put_task) => {
            return updateTask(formatterUpdateTask(task), requestConfig);
        },
        onSuccess: (data, variables) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error("Wrong format data: mutationUpdateTask");
            }
            if (data && data?.success) {
                queryClient.setQueryData(
                    [TaskKeys.SHOW, variables.id, requestConfig],
                    (oldData) => {
                        if (!oldData) return data;
                        // Must have the same props as the 'success' function of the response useShowTask
                        return {
                            ...oldData,
                            ...data,
                        };
                    },
                );
            }
        },
        onError: (err: any) => {
            console.debug("ERROR mutationUpdateTask", err);
            add({
                type: "warning",
                description: fmtErrors("GenericError", {}),
            });
            return err;
        },
    });
};

export const mutationDeleteTask = () => {
    const { formatMessageWithPartialKey: fmtError } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const { sendEvent } = useChannel({});
    return useMutation({
        mutationFn: (id: number) => removeTask(id, requestConfig),
        onSuccess: (data) => {
            if (data?.success) {
                sendEvent("removeTask");
            }
        },
        onError: (err: any) => {
            console.error(fmtError("GenericError", {}), err);

            return err;
        },
    });
};
