import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import * as Yup from "yup";

import {
    mutationCreateCompany,
    mutationUpdateCompany,
    useShowCompany,
} from "src/api/tms-commons/companies";
import { formatterIndexCompanies } from "src/api/tms-commons/companies/formatters";
import { searchCompany } from "src/api/tms-commons/companies/services";
import {
    Type_com_index_company,
    Type_index_company,
    Type_show_company,
} from "src/api/tms-commons/companies/types";
import { LoadingBox } from "src/components";
import { Type_action } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric";
import { TextField } from "src/components/Components_Common/forms/reactHookFormComponents/TextField/TextField";
import { FORM_ERR_FMT } from "src/configurations/errorsLabels";
import { TmcFormProvider, useTmcForm } from "src/hooks/tests/useTmcForm";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_CompanyForm = {
    onClose: () => void;
    action: Type_action;
    companyIdToUpdate: number | null;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Schema_CompanyForm = Yup.lazy(() => {
    return Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().trim().required(FORM_ERR_FMT.REQUIRED),
        nationalId: Yup.string().nullable(),
        postalCode: Yup.string()
            .trim()
            .max(10, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        city: Yup.string()
            .trim()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
        country: Yup.string()
            .trim()
            .max(100, FORM_ERR_FMT.MAX_LENGTH)
            .required(FORM_ERR_FMT.REQUIRED),
    });
});

export const CompanyForm = ({
    onClose,
    action,
    companyIdToUpdate,
    setIsLoading,
}: Type_Props_CompanyForm) => {
    const { formatMessageWithPartialKey: fmt } =
        useCoreIntl("Drawer.Companies");
    const [showSearch, setShowSearch] = useState<Type_index_company[]>([]);
    const [confirm, setConfirm] = useState<boolean>(false);

    const { mutateAsync: mutateCreate } = mutationCreateCompany() || {};
    const { mutateAsync: mutateUpdate } = mutationUpdateCompany() || {};
    const { data, isFetching } = useShowCompany(companyIdToUpdate as number);

    const handleSubmit = async (values: any) => {
        searchCompany(values.name, values.id)
            .then((data) => {
                if (
                    !confirm &&
                    Array.isArray(data.data.data) &&
                    data.data.data.length > 0
                ) {
                    setShowSearch(
                        formatterIndexCompanies(
                            data?.data?.data as Type_com_index_company[],
                        ),
                    );
                } else {
                    try {
                        if (action === "create") {
                            mutateCreate(values as Type_show_company);
                        } else if (action === "update") {
                            mutateUpdate(values as Type_show_company);
                        }
                    } catch (_e: any) {
                        console.error("Error when creating or updating ");
                    }
                    onClose();
                }
            })
            .catch((error) => console.log(error));
    };

    const form = useTmcForm({
        defaultValues: {
            name: "",
            nationalId: "",
            postalCode: "",
            city: "",
            country: "",
        },
        values: data,
        resolver: yupResolver<any>(Schema_CompanyForm),
        onClose,
        setIsLoading,
    });

    return (
        <TmcFormProvider form={form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} id={"companies"}>
                {isFetching ? (
                    <LoadingBox />
                ) : (
                    <Stack gap={6}>
                        <TextField
                            variant={"outlined"}
                            name="name"
                            label={fmt(`CompanyLabel`)}
                        />
                        <TextField
                            variant={"outlined"}
                            name="nationalId"
                            label={fmt(`NationalId`)}
                        />
                        <TextField
                            variant={"outlined"}
                            name="postalCode"
                            label={fmt(`ZipCode`)}
                        />
                        <TextField
                            variant={"outlined"}
                            name="city"
                            label={fmt(`CityLabel`)}
                        />
                        <TextField
                            variant={"outlined"}
                            name="country"
                            label={fmt(`CountryLabel`)}
                        />
                        {showSearch.length > 0 ? (
                            <>
                                <Alert severity="error">
                                    {fmt("CompaniesSimilar")}
                                    <ul>
                                        {showSearch.map((elem, index) => (
                                            <li key={index}>
                                                {elem.name +
                                                    ", " +
                                                    elem.city +
                                                    ", " +
                                                    elem.country}
                                            </li>
                                        ))}
                                    </ul>
                                </Alert>
                                <Stack direction="row">
                                    <Checkbox
                                        onClick={() => setConfirm(!confirm)}
                                        checked={confirm}
                                    />
                                    <Typography mt={2}>
                                        {fmt("ConfirmCompany")}
                                    </Typography>
                                </Stack>
                            </>
                        ) : (
                            ""
                        )}
                    </Stack>
                )}
            </form>
        </TmcFormProvider>
    );
};
