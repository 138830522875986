import { useState } from "react";

import {
    mutationDeleteUnit,
    mutationStatusUnit,
    useIndexUnits,
} from "src/api/tms-commons/units";
import {
    Type_index_unit,
    Type_put_unit,
} from "src/api/tms-commons/units/types";

import { Page_UnitsComponent } from "./Page_Units.component";

export type Type_modalDeleteUnit = {
    isOpen: boolean;
    id?: number | null;
    props?: Type_index_unit;
};

export const Page_UnitsContainer = () => {
    const { isFetching, data: units } = useIndexUnits() || {};
    const [modalDeleteUnit, setModalDeleteUnit] =
        useState<Type_modalDeleteUnit>({
            isOpen: false,
        });

    const { mutate: mutateDeleteUnit } = mutationDeleteUnit() || {};

    const removeUnit = (id: number) => {
        mutateDeleteUnit(id);
    };

    const { mutate: mutationUnitStatus } = mutationStatusUnit() || {};
    const changeStatus = (unit: Type_index_unit) => {
        mutationUnitStatus({
            id: unit.id,
            enabled: !unit.enabled,
        } as Type_put_unit);
    };

    return (
        <Page_UnitsComponent
            isFetching={isFetching}
            removeUnit={removeUnit}
            units={units || []}
            changeStatus={changeStatus}
            modalDeleteUnit={modalDeleteUnit}
            setModalDeleteUnit={setModalDeleteUnit}
        />
    );
};
