import {
    PeriodToggleEnum,
    Type_PlanningDataPeriodsFull,
} from "@cimba-digital-construction-solution/utils/dist/filters/planningFilters";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import { TMC_Button } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { usePlanningContext } from "src/contexts/planning";
import { useProject } from "src/contexts/project";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { HeaderToolbar } from "src/layouts/Layout_ContextualDrawer/components/HeaderToolbar";
import {
    Content,
    Header,
} from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

import { formatterPlanningFilters, parsePlanningFilters } from "./formatters";
import { PlanningFiltersFormAccordionList } from "./PlanningFiltersFormAccordionList";
import { Type_PlanningFilterForm } from "./types";

const planningFiltersValidationSchema = Yup.object().shape({
    period: Yup.mixed().default({
        type: PeriodToggleEnum.FULL,
    }),
    notSelectedCompanies: Yup.array().ensure(),
    notSelectedTrades: Yup.array().ensure(),
    companies: Yup.array().ensure(),
    trades: Yup.array().ensure(),
    typesTasks: Yup.array().ensure(),
    status: Yup.array().ensure(),
});

type Type_Props_PlanningFiltersForm = {
    onClose: () => void;
};

const Styled_Form = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%", // Ensures the form takes full height
}));

export const PlanningFiltersForm = ({
    onClose,
}: Type_Props_PlanningFiltersForm) => {
    const { project, subProject } = useProject();
    const { dataFilters, setDataFilters } = usePlanningContext();
    const { formatMessageWithPartialKey: fmtAction } = useCoreIntl("Actions");
    const { sendEvent } = useChannel({});

    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Drawer.PlanningFilters",
    );

    const form = useForm<any>({
        defaultValues: {
            period: {
                type: PeriodToggleEnum.FULL,
            } as Type_PlanningDataPeriodsFull,
            notSelectedTrades: [],
            notSelectedCompanies: [],
            typesTasks: [],
            status: [],
        },
        mode: "onSubmit",
        resolver: yupResolver<any>(planningFiltersValidationSchema),
    });

    useEffect(() => {
        if (project && subProject) {
            form.reset();
        }
    }, [project, subProject]);

    useEffect(() => {
        form.reset(parsePlanningFilters(dataFilters), {
            keepDefaultValues: true,
        });
    }, []);

    const handleSubmit = async (
        values: Type_PlanningFilterForm,
        e?: React.BaseSyntheticEvent,
    ) => {
        e?.preventDefault();
        const planningFilters = formatterPlanningFilters(values);
        setDataFilters((prev) => {
            return {
                ...prev,
                ...planningFilters,
            };
        });
        sendEvent("updatePlanningFilters");
    };

    return (
        <FormProvider {...form}>
            <Styled_Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Header>
                    <HeaderToolbar onClose={onClose} />
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        px={2}
                    >
                        <Typography variant="h3">{fmt("Title")}</Typography>
                        <IconButton
                            onClick={() =>
                                form.reset({}, { keepDefaultValues: true })
                            }
                            aria-label="filter-btn"
                            color={"primary"}
                            data-testid={`planning-filters-clean-btn`}
                        >
                            <Icon
                                sx={{ mr: 1 }}
                                fontSize="small"
                                icon="arrow-rotate-left"
                                variant="solid"
                            />
                            <Typography variant="body2Medium">
                                {fmt("ClearAll")}
                            </Typography>
                        </IconButton>
                    </Stack>
                </Header>

                <Content>
                    <PlanningFiltersFormAccordionList />
                </Content>

                {/* Footer - Fixed position */}
                <Box
                    sx={{
                        textAlign: "right",
                        p: 2,
                    }}
                >
                    <TMC_Button
                        data-testid="Form-PlanningFilters-Submit-Btn"
                        color="primary"
                        type="submit"
                        disabled={!form.formState.isValid}
                    >
                        <Icon sx={{ mr: 1 }} variant="solid" icon="check" />
                        {fmtAction(`Apply`)}
                    </TMC_Button>
                </Box>
            </Styled_Form>
        </FormProvider>
    );
};
