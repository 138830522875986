import { Type_planningArea } from "@cimba-digital-construction-solution/utils/dist/decoder/types";
import { Vector2d } from "konva/lib/types";
import { Dispatch, SetStateAction } from "react";

import { Type_Props_PlanningAreasOffset } from "src/components/Components_Teamoty/Planning/Areas/PlanningArea.type";
import { Type_State_PlanningScroll } from "src/components/Components_Teamoty/Planning/Scrolls/PlanningScroll.type";

export const updateAreasOffset = (
    areasActif: Array<Type_planningArea>,
    scrollY: Type_State_PlanningScroll,
    heightArea: number,
    setCursorHighlight: Dispatch<SetStateAction<Vector2d>>,
    setAreasOffset: Dispatch<SetStateAction<Type_Props_PlanningAreasOffset>>,
): void => {
    setCursorHighlight({ x: -1, y: -1 });

    setAreasOffset(
        (
            prev: Type_Props_PlanningAreasOffset,
        ): Type_Props_PlanningAreasOffset => {
            const filterArea = (
                position: {
                    offset: number;
                    show: number;
                },
                areas: Type_planningArea[],
            ): Type_planningArea[] => {
                return areas.filter((a: Type_planningArea) => {
                    let isShow: boolean = true;
                    if (
                        position.offset - position.show > 0 &&
                        position.offset - position.show >= a.size
                    ) {
                        isShow = false;
                        position.offset -= a.size;
                    } else if (position.show > scrollY.page) {
                        isShow = false;
                    } else {
                        position.show += a.size - position.offset;
                    }

                    return isShow;
                });
            };

            const position: {
                offset: number;
                show: number;
            } = {
                offset: scrollY.pos,
                show: 0,
            };

            prev.areas = filterArea(position, areasActif);

            prev.lines = [];
            let offset: number = -scrollY.pos * heightArea;
            const walkAreas = (areas: Type_planningArea[]): void => {
                areas.forEach((a: Type_planningArea): void => {
                    if (a.offset) {
                        for (let index: number = 0; index < a.offset; index++) {
                            prev.lines.push({
                                area: a,
                                pos: Math.round(offset),
                                index: index,
                            });
                            offset += heightArea;
                        }
                    }
                    if (a.areas && !a.close) {
                        walkAreas(a.areas);
                    }
                });
            };

            walkAreas(areasActif);

            // prev.areas = areasActif.filter((a: Type_planningArea) => {
            //     console.log(offset, show, a.size)
            //     let hidden = true
            //     if (offset > 0 && offset >= a.size) {
            //         hidden = false
            //         offset -= a.size
            //     } else if (show > scrollY.page) {
            //         hidden = false
            //     } else {
            //         show = a.size - offset
            //     }
            //     return hidden
            // })

            prev.offset = position.offset * heightArea;

            return { ...prev };
        },
    );
};
