import { get, GetReturnType, Type_RequestConfig } from "src/api/fetch";
import { AreaPath, Url } from "src/api/paths";

export const selectListAreaWorkLevels = (
    requestConfig: Type_RequestConfig,
): GetReturnType => {
    return get(
        `${Url.SCHEDULING}${AreaPath.AREAS_SELECT_LIST_WORK_LEVELS}`,
        requestConfig,
    );
};
