import { MuiCancellableEvent } from "@mui/base/utils/MuiCancellableEvent";
import {
    RichTreeView,
    TreeItem2,
    TreeItem2Props,
    UseTreeItem2ContentSlotOwnProps,
    useTreeItem2Utils,
} from "@mui/x-tree-view";
import React, {
    forwardRef,
    Ref,
    SyntheticEvent,
    useEffect,
    useState,
} from "react";

import {
    Type_index_sequenceFolderTree,
    Type_sequenceFolderTree_item,
} from "src/api/tms-scheduling/sequenceFolders/types";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "src/utils/localStorageServices";

type Type_Props_TMC_RichTreeView = {
    items: Type_index_sequenceFolderTree[];
    getItemId: (item: Type_sequenceFolderTree_item) => string;
    localStorageKey: string;
    CustomTreeItem?: any;
};

const DefaultTreeItem: React.ForwardRefExoticComponent<
    TreeItem2Props & React.RefAttributes<HTMLLIElement>
> = forwardRef(function MyTreeItem(
    props: TreeItem2Props,
    ref: Ref<HTMLLIElement>,
): JSX.Element {
    const { interactions } = useTreeItem2Utils({
        itemId: props.itemId,
        children: props.children,
    });

    const handleContentClick: UseTreeItem2ContentSlotOwnProps["onClick"] = (
        event: React.MouseEvent<Element, MouseEvent> & MuiCancellableEvent,
    ): void => {
        event.defaultMuiPrevented = true;
        interactions.handleSelection(event);
    };

    const handleIconContainerClick = (event: React.MouseEvent): void => {
        interactions.handleExpansion(event);
    };

    return (
        <TreeItem2
            {...props}
            ref={ref}
            slotProps={{
                content: { onClick: handleContentClick },
                iconContainer: { onClick: handleIconContainerClick },
            }}
        />
    );
});

const MyExpandIcon = () => {
    return (
        <Icon
            variant="solid"
            icon="caret-down"
            fontSize="small"
            data-testid="expand-treeItem-btn"
        />
    );
};

const MyCollapseIcon = () => {
    return (
        <Icon
            variant="solid"
            icon="caret-up"
            fontSize="small"
            data-testid="collapse-treeItem-btn"
        />
    );
};

export const TMC_RichTreeView = ({
    items,
    getItemId,
    localStorageKey,
    CustomTreeItem,
}: Type_Props_TMC_RichTreeView) => {
    const [expandedItems, setExpandedItems] = useState<string[]>(
        getLocalStorageItem(localStorageKey, true),
    );

    const handleExpandedItemsChange = (
        event: SyntheticEvent,
        itemIds: string[],
    ): void => {
        setExpandedItems(itemIds);
    };

    useEffect((): void => {
        setLocalStorageItem(localStorageKey, expandedItems);
    }, [expandedItems]);

    return (
        <RichTreeView
            items={items || []}
            getItemId={getItemId}
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
            itemChildrenIndentation={16}
            slots={{
                item: CustomTreeItem ? CustomTreeItem : DefaultTreeItem,
                expandIcon: MyExpandIcon,
                collapseIcon: MyCollapseIcon,
            }}
        />
    );
};
