import { FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useState } from "react";

import { TMC_Button, TMC_LoadingButton } from "src/components";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { Icon } from "src/components/Components_Common/Icon/Icon";

export const Success = () => {
    const [loading, setLoading] = useState(true);

    function handleClick() {
        setLoading(true);
    }

    return (
        <>
            {/* SUCCESS */}
            <Grid item xs={1}>
                SUCCESS
            </Grid>
            <Grid item xs={1}>
                <TMC_Button color="success" size="large">
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_Button color="success" size="large" disabled={true}>
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_LoadingButton
                    size="large"
                    onClick={handleClick}
                    loading={loading}
                    color="success"
                >
                    <span>Button</span>
                </TMC_LoadingButton>
            </Grid>
            <Grid item xs={1}>
                <FormControlLabel
                    sx={{
                        display: "block",
                    }}
                    control={
                        <Switch
                            checked={loading}
                            onChange={() => setLoading(!loading)}
                            name="loading"
                            color="primary"
                        />
                    }
                    label="Loading"
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton size="large" color="success">
                    <Icon variant="solid" icon="pen" fontSize={"large"} />
                </IconButton>
            </Grid>
            <Grid item xs={1}>
                <IconButton size="large" color="success" disabled={true}>
                    <Icon variant="solid" icon="pen" fontSize={"large"} />
                </IconButton>
            </Grid>

            {/* MEDIUM */}
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <TMC_Button color="success">Button</TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_Button color="success" disabled={true}>
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_LoadingButton
                    onClick={handleClick}
                    loading={loading}
                    color="success"
                >
                    <span>Button</span>
                </TMC_LoadingButton>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <IconButton color="success">
                    <Icon variant="solid" icon="pen" />
                </IconButton>
            </Grid>
            <Grid item xs={1}>
                <IconButton color="success" disabled={true}>
                    <Icon variant="solid" icon="pen" />
                </IconButton>
            </Grid>

            {/* SMALL */}
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <TMC_Button color="success" size="small">
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_Button color="success" size="small" disabled={true}>
                    Button
                </TMC_Button>
            </Grid>
            <Grid item xs={1}>
                <TMC_LoadingButton
                    size="small"
                    onClick={handleClick}
                    loading={loading}
                    color="success"
                    disabled={true}
                >
                    <span>Disabled</span>
                </TMC_LoadingButton>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <IconButton size="small" color="success">
                    <Icon variant="solid" icon="pen" fontSize="small" />
                </IconButton>
            </Grid>
            <Grid item xs={1}>
                <IconButton size="small" color="success" disabled={true}>
                    <Icon variant="solid" icon="pen" fontSize="small" />
                </IconButton>
            </Grid>
        </>
    );
};
