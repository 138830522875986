import {
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
} from "@mui/material";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import React, { ReactElement, useMemo } from "react";

import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { IconButton } from "src/components/Components_Common/_MuiComponentsVariants/IconButton/IconButton";
import { DrawerContent } from "src/components/Components_Common/DrawerGeneric/DrawerGeneric.style";
import { Icon } from "src/components/Components_Common/Icon/Icon";
import { NoPermission } from "src/components/Components_Common/NoPermission/NoPermission";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export type Type_DialogParams = {
    open: boolean;
    action?: "create" | "update";
    data?: any;
};

export type Type_DialogConfig = {
    [key: string]: {
        props: Type_Props_Dialog;
    };
};

export type Type_Props_Dialog = DialogProps & {
    dialogTitle?: string;
    name: string;
    isLoading?: boolean;
    actions?: ReactElement;
    page?: string;
    disablePermissionCheck?: boolean;
    clean?: boolean;
    actionText?: string;
};

export const Dialog = ({
    dialogTitle = "",
    isLoading,
    children,
    actions,
    name,
    page,
    actionText,
    clean = false,
    ...props
}: Type_Props_Dialog) => {
    if (!props.open) return null;

    const { checkPermission, user } = useUser();
    const isAuthorized = useMemo(() => {
        return (
            !page ||
            checkPermission(page, "create") ||
            checkPermission(page, "update")
        );
    }, [page, user]);

    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    const handleClose = (
        event?: any,
        reason?: "backdropClick" | "escapeKeyDown",
    ): void => {
        if (reason !== "backdropClick") {
            props?.onClose?.(event, reason || "backdropClick");
        }
    };

    return (
        <MuiDialog
            {...props}
            onClose={handleClose}
            data-testid={`dialog-${name}`}
        >
            {!clean && (
                <MuiDialogTitle
                    data-testid={`dialog-${name}-title`}
                    variant={"h3"}
                >
                    {dialogTitle}
                    <IconButton
                        size="small"
                        onClick={handleClose}
                        data-testid={`dialog-${name}-close-btn`}
                        title={fmtActions("Close")}
                    >
                        <Icon variant="light" icon="xmark" />
                    </IconButton>
                </MuiDialogTitle>
            )}
            {!isAuthorized ? (
                <DrawerContent>
                    <NoPermission />
                </DrawerContent>
            ) : (
                <>
                    <MuiDialogContent
                        sx={{
                            marginBlockStart: 0,
                            padding: clean ? 0 : undefined,
                        }}
                    >
                        {children}
                    </MuiDialogContent>
                    <MuiDialogActions>
                        {actions || (
                            <TMC_Button
                                form={name}
                                type="submit"
                                size="medium"
                                variant="contained"
                                disabled={isLoading}
                                startIcon={
                                    !isLoading && (
                                        <Icon variant="solid" icon="check" />
                                    )
                                }
                                data-testid={`dialog-${name}-save-btn`}
                            >
                                {isLoading ? (
                                    <Icon
                                        variant="solid"
                                        icon="spinner-third"
                                        spin
                                    />
                                ) : (
                                    actionText ?? fmtActions("Save")
                                )}
                            </TMC_Button>
                        )}
                    </MuiDialogActions>
                </>
            )}
        </MuiDialog>
    );
};
