import { ChipTypeMap, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useSelectListProductTypes } from "src/api/tms-projects/productTypes";
import { Type_select_productType } from "src/api/tms-projects/productTypes/types";
import {
    DialogGeneric,
    Type_dialogParamsDeprecated,
} from "src/components/Components_Common/Dialog/DialogGeneric";
import { Type_Props_Autocomplete } from "src/components/Components_Common/forms/reactHookFormComponents/Autocomplete/Autocomplete";
import { AutocompleteFreeSoloDialog } from "src/components/Components_Common/forms/reactHookFormComponents/AutocompleteFreeSoloDialog/AutocompleteFreeSoloDialog";
import { Type_createNewProduct } from "src/forms/productTypes/ProductTypesTaskForm";
import { ProductTypeTaskFormDialog } from "src/forms/productTypes/ProductTypesTaskFormDialog";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_AutocompleteProductTypes<
    T extends Type_select_productType,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> = Omit<
    Type_Props_Autocomplete<
        T,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
    >,
    | "options"
    | "open"
    | "onOpen"
    | "onClose"
    | "loading"
    | "isFetching"
    | "multiple"
> & {
    name: string;
    label?: string;
    multiple?: boolean;
    createNewProduct: (data: Type_createNewProduct) => Promise<void>;
    disabledOptions?: number[];
};

export const AutocompleteProductTypes = ({
    name,
    label,
    disabledOptions = [],
    createNewProduct,
    ...restProps
}: Type_Props_AutocompleteProductTypes<
    Type_select_productType,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
>) => {
    const { formatMessageWithPartialKey: fmtAction } = useCoreIntl("Actions");
    const { formatMessageWithPartialKey: fmtLabels } = useCoreIntl("Labels");

    const [dialogParams, setDialogParams] =
        useState<Type_dialogParamsDeprecated>({
            open: false,
            action: "create",
            id: null,
        });
    const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
    const [productTypes, setProductTypes] = useState<Type_select_productType[]>(
        [],
    );
    const [active, setIsActive] = useState<boolean>(false);
    const { data, isFetching } = useSelectListProductTypes(active) || {};

    const [newProductTypeName, setNewProductTypeName] = useState<string>("");

    useEffect(() => {
        if (data) {
            setProductTypes(data);
        }
    }, [data]);

    const closeDialog = () => {
        setDialogParams((prev) => ({ ...prev, open: false }));
        setNewProductTypeName("");
    };
    const openDialog = () =>
        setDialogParams((prev) => ({ ...prev, open: true }));

    const formId = "addOrUpdateProductTypeTask";
    const title = `${fmtAction("New")} ${fmtLabels("Product")}`;

    return (
        <Stack width={"100%"} flexDirection={"column"}>
            <AutocompleteFreeSoloDialog
                name={name}
                disableCloseOnSelect
                options={productTypes || []}
                onOpen={() => {
                    setIsActive(true);
                }}
                label={label}
                loading={isFetching}
                getOptionDisabled={(option) =>
                    disabledOptions?.includes(+option.id)
                }
                onAddNewValue={(value) => {
                    setNewProductTypeName(value.name);
                    openDialog();
                }}
                {...restProps}
            />

            <DialogGeneric
                open={dialogParams.open}
                formId={formId}
                dialogTitle={title}
                isFormLoading={isFormLoading}
                setDialogParams={setDialogParams}
                onClose={() => setNewProductTypeName("")}
            >
                <ProductTypeTaskFormDialog
                    setIsFormLoading={setIsFormLoading}
                    dialogParams={dialogParams}
                    formId={formId}
                    onClose={closeDialog}
                    createNewProduct={createNewProduct}
                    productTaskValue={newProductTypeName}
                />
            </DialogGeneric>
        </Stack>
    );
};
