import { Box, Button, Stack } from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";
import useMeasure from "react-use-measure";

import { useMeasureDefaultConfig } from "src/configurations/app";
import { Layout_Resizable } from "src/layouts/Layout_Resizable/Layout_Resizable";

const Drawer = () => {
    return (
        <Box
            sx={(theme) => ({
                backgroundColor: theme.palette.common.violet,
                border: "2px solid #990",
                width: 400,
            })}
        >
            THIS IS MY RIGHT CONTENT
        </Box>
    );
};

export const LayoutResizableDemo = () => {
    const [rightPanel, setRightPanel] = React.useState<ReactNode[]>([]);

    const [centerRef, centerBounds] = useMeasure(useMeasureDefaultConfig);
    const [bottomRef, bottomBounds] = useMeasure(useMeasureDefaultConfig);

    const [showBottomPanel, setShowBottomPanel] = React.useState(false);

    return (
        <>
            <Stack gap={2} direction="row" margin={2} justifyContent="center">
                <Button
                    variant="contained"
                    onClick={() => {
                        // eslint-disable-next-line react/jsx-key
                        setShowBottomPanel((prev) => !prev);
                    }}
                >
                    Show/Hide Bottom section
                </Button>

                <Button
                    variant="contained"
                    onClick={() => {
                        // eslint-disable-next-line react/jsx-key
                        setRightPanel((prev) => [...prev, <Drawer />]);
                    }}
                >
                    Open Drawer
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setRightPanel((prev) => prev.splice(1));
                    }}
                >
                    Remove Drawer
                </Button>
            </Stack>
            <Stack gap={2} justifyContent="center">
                <div>{`CENTER => Width: ${centerBounds.width} | Height: ${centerBounds.height}`}</div>
                <div>{`BOTTOM => Width: ${bottomBounds.width} | Height: ${bottomBounds.height}`}</div>
            </Stack>

            <Box
                sx={() => ({
                    width: "90vw",
                    height: "60vh",

                    marginX: "auto",
                    marginBottom: 20,

                    display: "flex",
                    flexDirection: "column",
                })}
            >
                <Layout_Resizable
                    rightPanel={<>{rightPanel?.map((item) => item)}</>}
                    bottomPanel={
                        showBottomPanel ? (
                            <img
                                src="https://fastly.picsum.photos/id/42/3000/1000.jpg?hmac=XstM4C0BKTz3R6n9dwx4CSrlWCo_sv43LZdB0_u6FBU"
                                alt="image 3000"
                                width={bottomBounds.width}
                                height={bottomBounds.height}
                            />
                        ) : undefined
                    }
                    centerRef={centerRef}
                    bottomRef={bottomRef}
                >
                    <img
                        src="https://fastly.picsum.photos/id/724/3000/2000.jpg?hmac=eDhgQVTF6BzAWOxFdk_CRaZzG2eCKadOztw4w0FgLms"
                        alt="image 3000"
                        width={centerBounds.width}
                        height={centerBounds.height}
                    />
                </Layout_Resizable>
            </Box>
        </>
    );
};
